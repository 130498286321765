import * as d3 from 'd3';
import ChartRow from './ChartRow';
import GraphicRowBarGroup from '../graphicRowBarGroup/GraphicRowBarGroup';
import { DEFAULT_CHART_ROW_BAR_GROUP_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartRowBarGroup extends ChartRow {
    params = DEFAULT_CHART_ROW_BAR_GROUP_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicRowBarGroup(this.graphicSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicRowBarGroup: {
                ...this.params.graphicRowBarGroup,
                ...params.graphicRowBarGroup
            }
        };
        this.bar.setParams({
            ...this.params.graphicRowBarGroup,
            // barType: this.params.barType,
            // barWidth: this.params.barWidth,
            // barPadding: this.params.barPadding,
            // barR: this.params.barR,
            colors: this.params.colors,
            highlightTarget: this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId,
            highlightGroupId: this.params.highlightGroupId,
        });
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const yLabel = dataset.yLabels[_i];
                const y = yScale(yLabel) || 0; // 這裡的 x 是對應 x座標軸的位置，群組內的位置在圖形裡面再做計算
                return {
                    ..._d,
                    id: `${itemLabel}_${yLabel}`,
                    itemIndex: i,
                    itemLabel,
                    yIndex: _i,
                    yLabel,
                    x: xScale(_d.value) || 0,
                    y
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeYDataMap({ renderData, yLabels }) {
        let YDataMap = new Map();
        if (!renderData.length) {
            return YDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = YDataMap.get(yLabels[_i]) ?? [];
                xData.push(_d);
                YDataMap.set(yLabels[_i], xData);
            });
        });
        return YDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        itemLabels.forEach((d, i) => {
            ItemDataMap.set(d, renderData[i]);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, yLabels, xScale, yScale }) {
        this.bar.setDataset({
            data: renderData,
            yLabels,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisHeight: this.axisHeight,
            zeroX: xScale(0),
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        // // 非顯示全部圓點時 highlight圓點
        // if (!this.params.dot && this.params.highlightTarget !== 'none') {
        //   const dotData: GraphicRenderDatum[] = eventData[0]
        //     ? eventData[0].groupData.map(d => {
        //       return {
        //         ...d,
        //         id: d.itemLabel, // itemLabel相同則用update的
        //         x: xScale(d.Date)!,
        //         y: yScale(d.value)!
        //       }
        //     })
        //     : []
        //   this.dot!.setDataset({
        //     data: dotData,
        //     itemLabels: this.dataset.itemLabels
        //   })
        //   this.dot!.render()
        // }
    }
}
