import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import data from './data';
export default {
    props: {
        module: ChartBubbleGroup,
        params: {
            // colors: ['#587791', '#efc57f', '#608d8c', '#5a5a5a', '#de6868'],
            types: ['keyword', 'people', 'location', 'group', 'brand'],
            collisionSpacing: 2,
        },
        data
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
    }
};
