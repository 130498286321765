import { GraphicColumnBarGroup } from '@bpchart/d3-modules/graphicColumnBarGroup';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_COLUMN_BAR_GROUP_PARAMS } from '@bpchart/d3-modules/graphicColumnBarGroup';
export default {
    props: {
        module: GraphicColumnBarGroup,
        params: DEFAULT_GRAPHIC_COLUMN_BAR_GROUP_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
