import * as d3 from 'd3';
import { DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS } from './defaults';
import { makeColorScale } from '../moduleUtils';
// const _d3: any = d3
const makeItemDataMap = (data, itemLabels) => {
    const ItemDataMap = new Map();
    itemLabels.forEach((d, i) => {
        ItemDataMap.set(d, data[i]);
    });
    return ItemDataMap;
};
const makeXDataMap = (data, xLabels) => {
    const XDataMap = new Map();
    data.forEach((d, i) => {
        d.forEach((_d, _i) => {
            const xData = XDataMap.get(xLabels[_i]) ?? [];
            xData.push(_d);
            XDataMap.set(xLabels[_i], xData);
        });
    });
    return XDataMap;
};
const makeGraphicData = (XDataMap, xLabels, axisHeight) => {
    return xLabels.map((d, i) => {
        const xData = XDataMap.get(d) ?? [];
        let data = [];
        let graphicY = axisHeight;
        for (let i = 0; i < xData.length; i++) {
            const currentDatum = xData[i];
            const height = axisHeight - currentDatum.y;
            graphicY = graphicY - height; // 從前一筆資料的y起開始疊加
            data.push({
                ...currentDatum,
                graphicX: currentDatum.x,
                graphicY,
                height
            });
        }
        return data;
    });
};
// const makeColorScale = (itemLabels: string[], colors: string[]) => {
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
// const makeBarScale = (barWidth: number, itemLabels: string[], params: Params) => {
//   const barHalfWidth = barWidth! / 2
//   const barGroupWidth = barWidth * itemLabels.length + params.barPadding! * itemLabels.length
//   return d3.scalePoint()
//     .domain(itemLabels)
//     .range([-barGroupWidth / 2 + barHalfWidth, barGroupWidth / 2 - barHalfWidth])
// }
const makeCallbackData = ({ datum, XDataMap, ItemDataMap, event }) => {
    return {
        groupData: XDataMap.get(datum.xLabel),
        itemData: ItemDataMap.get(datum.itemLabel),
        datum,
        xLabel: datum.xLabel,
        xIndex: datum.xIndex,
        itemLabel: datum.itemLabel,
        itemIndex: datum.itemIndex,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
// const calcAxisWidth = (data: Datum[][]) => {
//   if (data[0]) {
//     // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
//     let minX = Infinity
//     let maxX = 0
//     data.forEach(d => {
//       d.forEach(_d => {
//         if (_d.x < minX) {
//           minX = _d.x
//         }
//       })
//       if (d[d.length - 1].x > maxX) {
//         maxX = d[d.length - 1].x
//       }
//     })
//     return maxX - minX
//   }
//   return 0
// }
// const calcAxisHeight = (data: Datum[][]) => {
//   if (data[0] && data[0][0]) {
//     // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
//     let minValue = Infinity
//     let minValueY = Infinity
//     data.forEach(d => {
//       d.forEach(_d => {
//         if (_d.value < minValue) {
//           minValue = _d.value
//           minValueY = _d.y
//         }
//       })
//     })
//     return minValueY
//   }
//   return 0
// }
const calcBarWidth = ({ axisWidth, groupAmount, barGroupPadding = 0 }) => {
    const width = axisWidth / groupAmount - barGroupPadding;
    return width > 1 ? width : 1;
};
const calcDelayDuration = (barAmount, totalDuration = 400) => {
    return totalDuration / barAmount;
};
export default class GraphicColumnBar {
    selection;
    params = DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        xLabels: [],
        axisWidth: 0,
        // axisHeight: 0
        zeroY: 0
    };
    graphicData = [];
    graphicGroupSelection = undefined;
    graphicBarSelection = undefined;
    defsSelection;
    itemLabels = [];
    colorScale;
    ItemDataMap = new Map();
    XDataMap = new Map();
    // private axisHeight = 0
    axisWidth = 0;
    barWidth = 0;
    delayDuration = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
        this.defsSelection = selection.append('defs');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.itemLabels = [];
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = this.dataset.itemLabels;
        this.ItemDataMap = makeItemDataMap(this.dataset.data, this.dataset.itemLabels);
        this.XDataMap = makeXDataMap(this.dataset.data, this.dataset.xLabels);
        this.delayDuration = calcDelayDuration(this.dataset.xLabels.length);
    }
    render() {
        // 未 setDataset 則不執行
        if (!this.itemLabels.length) {
            return;
        }
        this.colorScale = makeColorScale(this.itemLabels, this.params.colors);
        // this.setLinearGradient({
        //   defsSelection: this.defsSelection!,
        //   itemLabels: this.itemLabels,
        //   colorScale: this.colorScale!
        // })
        // if (this.dataset.axisHeight) {
        //   this.axisHeight = this.dataset.axisHeight
        // } else {
        //   // 如無設定就取最小值，這樣只是圖不會爆炸但畫出來可能會有問題（不算bug但如果如y的起點不同的話 - 比如y的起點可能為0，就不會由起點開始畫）
        //   this.axisHeight = calcAxisHeight(this.dataset.data)
        // }
        // if (this.dataset.axisWidth) {
        //   this.axisWidth = this.dataset.axisWidth
        // } else {
        //   this.axisWidth = calcAxisWidth(this.dataset.data)
        // }
        // this.axisHeight = this.dataset.axisHeight
        this.axisWidth = this.dataset.axisWidth;
        this.barWidth = this.params.barWidth ? this.params.barWidth
            : calcBarWidth({
                axisWidth: this.axisWidth,
                groupAmount: this.dataset.xLabels.length,
                barGroupPadding: this.params.barGroupPadding ?? 0
            });
        // 群組內的 bar 比例尺
        // const itemScale: d3.ScalePoint<string> = makeBarScale(this.barWidth, this.dataset.itemLabels, this.params)
        // 繪圖
        this.graphicData = makeGraphicData(this.XDataMap, this.dataset.xLabels, this.dataset.zeroY);
        // console.log(this.graphicData)
        this.renderGraphic({
            selection: this.selection,
            graphicData: this.graphicData,
            xLabels: this.dataset.xLabels,
            colorScale: this.colorScale,
            // itemScale,
            params: this.params
        });
        // 綁定事件
        this.graphicBarSelection
            .on('click', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                XDataMap: this.XDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i, n) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                XDataMap: this.XDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'group') {
                    this.highlight(this.graphicBarSelection, callbackData.xLabel, undefined, undefined);
                }
                else if (this.params.highlightTarget === 'item') {
                    this.highlight(this.graphicBarSelection, undefined, callbackData.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(this.graphicBarSelection, undefined, undefined, callbackData.datum && callbackData.datum.id);
                }
            }
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                XDataMap: this.XDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                this.removeHighlight(this.graphicBarSelection);
            }
            const callbackData = makeCallbackData({
                datum: d,
                XDataMap: this.XDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.initHighlight();
            this.mouseoutCallback(callbackData);
        });
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    remove() {
        this.selection.remove();
    }
    // private setLinearGradient ({ defsSelection, itemLabels, colorScale }: {
    //   defsSelection: d3.Selection<SVGDefsElement, Datum, any, any>
    //   itemLabels: string[]
    //   colorScale: d3.ScaleOrdinal<string, string>
    // }) {
    //   const linearGradientUpdate = defsSelection!
    //       .selectAll<SVGLinearGradientElement, string>('linearGradient')
    //       .data(itemLabels, d => d)
    //     const linearGradientEnter = linearGradientUpdate
    //       .enter()
    //       .append('linearGradient')
    //       .attr('x1', '0%')
    //       .attr('x2', '0%')
    //       .attr('y1', '0%')
    //       .attr('y2', '100%')
    //       .attr('spreadMethod', 'pad')
    //     linearGradientUpdate.merge(linearGradientEnter)
    //       .attr('id', (d, i) => `bpchart__lineargradient__${d}`)
    //       .html((d, i) => `
    //         <stop offset="0%"   stop-color="${colorScale(d)}" stop-opacity="1"/>
    //         <stop offset="100%" stop-color="${colorScale(d)}" stop-opacity="0"/>
    //       `)
    //     linearGradientUpdate.exit().remove()
    // }
    renderGraphic({ selection, graphicData, xLabels, colorScale, params }) {
        if (this.barWidth <= 0) {
            return;
        }
        const update = selection
            .selectAll('g.bpchart__bar-g')
            .data(graphicData, (d, i) => (d[0] && d[0].id) ? d[0].id : xLabels[i]);
        const enter = update.enter()
            .append('g')
            .classed('bpchart__bar-g', true)
            .attr('cursor', 'pointer');
        update.exit().remove();
        this.graphicGroupSelection = update.merge(enter);
        enter
            .attr('transform', (d, i) => `translate(${d[0] ? d[0].graphicX : 0}, ${0})`);
        update
            .transition()
            .duration(200)
            .attr('transform', (d, i) => `translate(${d[0] ? d[0].graphicX : 0}, ${0})`);
        const barHalfWidth = this.barWidth / 2;
        this.graphicGroupSelection
            .each((d, i, g) => {
            const barUpdate = d3.select(g[i])
                .selectAll('g')
                .data(d, _d => _d.id);
            const barEnter = barUpdate
                .enter()
                .append('g')
                .classed('bpchart__bar', true);
            barEnter
                .append('rect')
                .attr('rx', params.barR == true ? barHalfWidth
                : params.barR == false ? 0
                    : typeof params.barR == 'number' ? params.barR
                        : 0)
                .attr('transform', `translate(${-barHalfWidth}, 0)`)
                .attr('x', d => 0)
                .attr('y', d => this.dataset.zeroY)
                .attr('width', this.barWidth)
                .attr('height', d => 0);
            const rect = barUpdate.merge(barEnter)
                .select('rect');
            rect
                .attr('fill', d => colorScale(d.itemLabel))
                .transition()
                .duration(this.params.enterDuration)
                .ease(d3.easeElastic)
                .delay((d, i) => d.xIndex * this.delayDuration)
                .attr('transform', `translate(${-barHalfWidth}, 0)`)
                .attr('x', d => 0)
                .attr('y', d => d.graphicY)
                .attr('width', this.barWidth)
                .attr('height', d => d.height)
                .on('end', () => this.initHighlight());
            barUpdate.exit().remove();
        });
        this.graphicBarSelection = this.graphicGroupSelection.selectAll('g.bpchart__bar');
    }
    initHighlight() {
        if (!this.graphicBarSelection) {
            return;
        }
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId || this.params.highlightGroupId) {
            this.highlight(this.graphicBarSelection, this.params.highlightGroupId, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    highlight(graphicBarSelection, groupId, itemId, datumId) {
        if (!graphicBarSelection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .flat()
                .filter(d => d.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        if (groupId) {
            const _ids = this.dataset.data
                .flat()
                .filter(d => d.xLabel === groupId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        graphicBarSelection
            .each((d, i, n) => {
            if (ids.includes(d.id)) {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(graphicBarSelection) {
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId || this.params.highlightGroupId) {
            this.highlight(graphicBarSelection, this.params.highlightGroupId, this.params.highlightItemId, this.params.highlightDatumId);
        }
        else {
            graphicBarSelection
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }
}
