import { makeAxisTimeScale, makeAxisLinearScale } from '@bpchart/d3-modules/moduleUtils';
const filteredData = [
    [
        {
            "date": "2022-05-16 00:00:00",
            "value": 13,
            "id": "文章總量_05-16",
            "xIndex": 0,
            "xLabel": "05-16",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-15T16:00:00.000Z"
        },
        {
            "date": "2022-05-17 00:00:00",
            "value": 52,
            "id": "文章總量_05-17",
            "xIndex": 1,
            "xLabel": "05-17",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-16T16:00:00.000Z"
        },
        {
            "date": "2022-05-18 00:00:00",
            "value": 53,
            "id": "文章總量_05-18",
            "xIndex": 2,
            "xLabel": "05-18",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-17T16:00:00.000Z"
        },
        {
            "date": "2022-05-19 00:00:00",
            "value": 69,
            "id": "文章總量_05-19",
            "xIndex": 3,
            "xLabel": "05-19",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-18T16:00:00.000Z"
        },
        {
            "date": "2022-05-20 00:00:00",
            "value": 40,
            "id": "文章總量_05-20",
            "xIndex": 4,
            "xLabel": "05-20",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-19T16:00:00.000Z"
        },
        {
            "date": "2022-05-21 00:00:00",
            "value": 18,
            "id": "文章總量_05-21",
            "xIndex": 5,
            "xLabel": "05-21",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-20T16:00:00.000Z"
        },
        {
            "date": "2022-05-22 00:00:00",
            "value": 16,
            "id": "文章總量_05-22",
            "xIndex": 6,
            "xLabel": "05-22",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-21T16:00:00.000Z"
        },
        {
            "date": "2022-05-23 00:00:00",
            "value": 35,
            "id": "文章總量_05-23",
            "xIndex": 7,
            "xLabel": "05-23",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-22T16:00:00.000Z"
        },
        {
            "date": "2022-05-24 00:00:00",
            "value": 85,
            "id": "文章總量_05-24",
            "xIndex": 8,
            "xLabel": "05-24",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-23T16:00:00.000Z"
        },
        {
            "date": "2022-05-25 00:00:00",
            "value": 72,
            "id": "文章總量_05-25",
            "xIndex": 9,
            "xLabel": "05-25",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-24T16:00:00.000Z"
        },
        {
            "date": "2022-05-26 00:00:00",
            "value": 29,
            "id": "文章總量_05-26",
            "xIndex": 10,
            "xLabel": "05-26",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-25T16:00:00.000Z"
        },
        {
            "date": "2022-05-27 00:00:00",
            "value": 30,
            "id": "文章總量_05-27",
            "xIndex": 11,
            "xLabel": "05-27",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-26T16:00:00.000Z"
        },
        {
            "date": "2022-05-28 00:00:00",
            "value": 20,
            "id": "文章總量_05-28",
            "xIndex": 12,
            "xLabel": "05-28",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-27T16:00:00.000Z"
        },
        {
            "date": "2022-05-29 00:00:00",
            "value": 12,
            "id": "文章總量_05-29",
            "xIndex": 13,
            "xLabel": "05-29",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-28T16:00:00.000Z"
        },
        {
            "date": "2022-05-30 00:00:00",
            "value": 21,
            "id": "文章總量_05-30",
            "xIndex": 14,
            "xLabel": "05-30",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-29T16:00:00.000Z"
        },
        {
            "date": "2022-05-31 00:00:00",
            "value": 21,
            "id": "文章總量_05-31",
            "xIndex": 15,
            "xLabel": "05-31",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-30T16:00:00.000Z"
        },
        {
            "date": "2022-06-01 00:00:00",
            "value": 24,
            "id": "文章總量_06-01",
            "xIndex": 16,
            "xLabel": "06-01",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-05-31T16:00:00.000Z"
        },
        {
            "date": "2022-06-02 00:00:00",
            "value": 59,
            "id": "文章總量_06-02",
            "xIndex": 17,
            "xLabel": "06-02",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-01T16:00:00.000Z"
        },
        {
            "date": "2022-06-03 00:00:00",
            "value": 17,
            "id": "文章總量_06-03",
            "xIndex": 18,
            "xLabel": "06-03",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-02T16:00:00.000Z"
        },
        {
            "date": "2022-06-04 00:00:00",
            "value": 6,
            "id": "文章總量_06-04",
            "xIndex": 19,
            "xLabel": "06-04",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-03T16:00:00.000Z"
        },
        {
            "date": "2022-06-05 00:00:00",
            "value": 5,
            "id": "文章總量_06-05",
            "xIndex": 20,
            "xLabel": "06-05",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-04T16:00:00.000Z"
        },
        {
            "date": "2022-06-06 00:00:00",
            "value": 66,
            "id": "文章總量_06-06",
            "xIndex": 21,
            "xLabel": "06-06",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-05T16:00:00.000Z"
        },
        {
            "date": "2022-06-07 00:00:00",
            "value": 27,
            "id": "文章總量_06-07",
            "xIndex": 22,
            "xLabel": "06-07",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-06T16:00:00.000Z"
        },
        {
            "date": "2022-06-08 00:00:00",
            "value": 13,
            "id": "文章總量_06-08",
            "xIndex": 23,
            "xLabel": "06-08",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-07T16:00:00.000Z"
        },
        {
            "date": "2022-06-09 00:00:00",
            "value": 19,
            "id": "文章總量_06-09",
            "xIndex": 24,
            "xLabel": "06-09",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-08T16:00:00.000Z"
        },
        {
            "date": "2022-06-10 00:00:00",
            "value": 25,
            "id": "文章總量_06-10",
            "xIndex": 25,
            "xLabel": "06-10",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-09T16:00:00.000Z"
        },
        {
            "date": "2022-06-11 00:00:00",
            "value": 8,
            "id": "文章總量_06-11",
            "xIndex": 26,
            "xLabel": "06-11",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-10T16:00:00.000Z"
        },
        {
            "date": "2022-06-12 00:00:00",
            "value": 21,
            "id": "文章總量_06-12",
            "xIndex": 27,
            "xLabel": "06-12",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-11T16:00:00.000Z"
        },
        {
            "date": "2022-06-13 00:00:00",
            "value": 25,
            "id": "文章總量_06-13",
            "xIndex": 28,
            "xLabel": "06-13",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-12T16:00:00.000Z"
        },
        {
            "date": "2022-06-14 00:00:00",
            "value": 27,
            "id": "文章總量_06-14",
            "xIndex": 29,
            "xLabel": "06-14",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-13T16:00:00.000Z"
        },
        {
            "date": "2022-06-15 00:00:00",
            "value": 43,
            "id": "文章總量_06-15",
            "xIndex": 30,
            "xLabel": "06-15",
            "itemIndex": 0,
            "itemLabel": "文章總量",
            "Date": "2022-06-14T16:00:00.000Z"
        }
    ],
    [
        {
            "date": "2022-05-16 00:00:00",
            "value": 3,
            "id": "正面_05-16",
            "xIndex": 0,
            "xLabel": "05-16",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-15T16:00:00.000Z"
        },
        {
            "date": "2022-05-17 00:00:00",
            "value": 3,
            "id": "正面_05-17",
            "xIndex": 1,
            "xLabel": "05-17",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-16T16:00:00.000Z"
        },
        {
            "date": "2022-05-18 00:00:00",
            "value": 3,
            "id": "正面_05-18",
            "xIndex": 2,
            "xLabel": "05-18",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-17T16:00:00.000Z"
        },
        {
            "date": "2022-05-19 00:00:00",
            "value": 16,
            "id": "正面_05-19",
            "xIndex": 3,
            "xLabel": "05-19",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-18T16:00:00.000Z"
        },
        {
            "date": "2022-05-20 00:00:00",
            "value": 4,
            "id": "正面_05-20",
            "xIndex": 4,
            "xLabel": "05-20",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-19T16:00:00.000Z"
        },
        {
            "date": "2022-05-21 00:00:00",
            "value": 2,
            "id": "正面_05-21",
            "xIndex": 5,
            "xLabel": "05-21",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-20T16:00:00.000Z"
        },
        {
            "date": "2022-05-22 00:00:00",
            "value": 1,
            "id": "正面_05-22",
            "xIndex": 6,
            "xLabel": "05-22",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-21T16:00:00.000Z"
        },
        {
            "date": "2022-05-23 00:00:00",
            "value": 8,
            "id": "正面_05-23",
            "xIndex": 7,
            "xLabel": "05-23",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-22T16:00:00.000Z"
        },
        {
            "date": "2022-05-24 00:00:00",
            "value": 2,
            "id": "正面_05-24",
            "xIndex": 8,
            "xLabel": "05-24",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-23T16:00:00.000Z"
        },
        {
            "date": "2022-05-25 00:00:00",
            "value": 3,
            "id": "正面_05-25",
            "xIndex": 9,
            "xLabel": "05-25",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-24T16:00:00.000Z"
        },
        {
            "date": "2022-05-26 00:00:00",
            "value": 2,
            "id": "正面_05-26",
            "xIndex": 10,
            "xLabel": "05-26",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-25T16:00:00.000Z"
        },
        {
            "date": "2022-05-27 00:00:00",
            "value": 6,
            "id": "正面_05-27",
            "xIndex": 11,
            "xLabel": "05-27",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-26T16:00:00.000Z"
        },
        {
            "date": "2022-05-28 00:00:00",
            "value": 0,
            "id": "正面_05-28",
            "xIndex": 12,
            "xLabel": "05-28",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-27T16:00:00.000Z"
        },
        {
            "date": "2022-05-29 00:00:00",
            "value": 1,
            "id": "正面_05-29",
            "xIndex": 13,
            "xLabel": "05-29",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-28T16:00:00.000Z"
        },
        {
            "date": "2022-05-30 00:00:00",
            "value": 0,
            "id": "正面_05-30",
            "xIndex": 14,
            "xLabel": "05-30",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-29T16:00:00.000Z"
        },
        {
            "date": "2022-05-31 00:00:00",
            "value": 2,
            "id": "正面_05-31",
            "xIndex": 15,
            "xLabel": "05-31",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-30T16:00:00.000Z"
        },
        {
            "date": "2022-06-01 00:00:00",
            "value": 4,
            "id": "正面_06-01",
            "xIndex": 16,
            "xLabel": "06-01",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-05-31T16:00:00.000Z"
        },
        {
            "date": "2022-06-02 00:00:00",
            "value": 0,
            "id": "正面_06-02",
            "xIndex": 17,
            "xLabel": "06-02",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-01T16:00:00.000Z"
        },
        {
            "date": "2022-06-03 00:00:00",
            "value": 1,
            "id": "正面_06-03",
            "xIndex": 18,
            "xLabel": "06-03",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-02T16:00:00.000Z"
        },
        {
            "date": "2022-06-04 00:00:00",
            "value": 0,
            "id": "正面_06-04",
            "xIndex": 19,
            "xLabel": "06-04",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-03T16:00:00.000Z"
        },
        {
            "date": "2022-06-05 00:00:00",
            "value": 0,
            "id": "正面_06-05",
            "xIndex": 20,
            "xLabel": "06-05",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-04T16:00:00.000Z"
        },
        {
            "date": "2022-06-06 00:00:00",
            "value": 0,
            "id": "正面_06-06",
            "xIndex": 21,
            "xLabel": "06-06",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-05T16:00:00.000Z"
        },
        {
            "date": "2022-06-07 00:00:00",
            "value": 1,
            "id": "正面_06-07",
            "xIndex": 22,
            "xLabel": "06-07",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-06T16:00:00.000Z"
        },
        {
            "date": "2022-06-08 00:00:00",
            "value": 3,
            "id": "正面_06-08",
            "xIndex": 23,
            "xLabel": "06-08",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-07T16:00:00.000Z"
        },
        {
            "date": "2022-06-09 00:00:00",
            "value": 2,
            "id": "正面_06-09",
            "xIndex": 24,
            "xLabel": "06-09",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-08T16:00:00.000Z"
        },
        {
            "date": "2022-06-10 00:00:00",
            "value": 12,
            "id": "正面_06-10",
            "xIndex": 25,
            "xLabel": "06-10",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-09T16:00:00.000Z"
        },
        {
            "date": "2022-06-11 00:00:00",
            "value": 1,
            "id": "正面_06-11",
            "xIndex": 26,
            "xLabel": "06-11",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-10T16:00:00.000Z"
        },
        {
            "date": "2022-06-12 00:00:00",
            "value": 1,
            "id": "正面_06-12",
            "xIndex": 27,
            "xLabel": "06-12",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-11T16:00:00.000Z"
        },
        {
            "date": "2022-06-13 00:00:00",
            "value": 5,
            "id": "正面_06-13",
            "xIndex": 28,
            "xLabel": "06-13",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-12T16:00:00.000Z"
        },
        {
            "date": "2022-06-14 00:00:00",
            "value": 2,
            "id": "正面_06-14",
            "xIndex": 29,
            "xLabel": "06-14",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-13T16:00:00.000Z"
        },
        {
            "date": "2022-06-15 00:00:00",
            "value": 18,
            "id": "正面_06-15",
            "xIndex": 30,
            "xLabel": "06-15",
            "itemIndex": 1,
            "itemLabel": "正面",
            "Date": "2022-06-14T16:00:00.000Z"
        }
    ],
    [
        {
            "date": "2022-05-16 00:00:00",
            "value": 8,
            "id": "中立_05-16",
            "xIndex": 0,
            "xLabel": "05-16",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-15T16:00:00.000Z"
        },
        {
            "date": "2022-05-17 00:00:00",
            "value": 30,
            "id": "中立_05-17",
            "xIndex": 1,
            "xLabel": "05-17",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-16T16:00:00.000Z"
        },
        {
            "date": "2022-05-18 00:00:00",
            "value": 27,
            "id": "中立_05-18",
            "xIndex": 2,
            "xLabel": "05-18",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-17T16:00:00.000Z"
        },
        {
            "date": "2022-05-19 00:00:00",
            "value": 26,
            "id": "中立_05-19",
            "xIndex": 3,
            "xLabel": "05-19",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-18T16:00:00.000Z"
        },
        {
            "date": "2022-05-20 00:00:00",
            "value": 24,
            "id": "中立_05-20",
            "xIndex": 4,
            "xLabel": "05-20",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-19T16:00:00.000Z"
        },
        {
            "date": "2022-05-21 00:00:00",
            "value": 6,
            "id": "中立_05-21",
            "xIndex": 5,
            "xLabel": "05-21",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-20T16:00:00.000Z"
        },
        {
            "date": "2022-05-22 00:00:00",
            "value": 7,
            "id": "中立_05-22",
            "xIndex": 6,
            "xLabel": "05-22",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-21T16:00:00.000Z"
        },
        {
            "date": "2022-05-23 00:00:00",
            "value": 18,
            "id": "中立_05-23",
            "xIndex": 7,
            "xLabel": "05-23",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-22T16:00:00.000Z"
        },
        {
            "date": "2022-05-24 00:00:00",
            "value": 37,
            "id": "中立_05-24",
            "xIndex": 8,
            "xLabel": "05-24",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-23T16:00:00.000Z"
        },
        {
            "date": "2022-05-25 00:00:00",
            "value": 40,
            "id": "中立_05-25",
            "xIndex": 9,
            "xLabel": "05-25",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-24T16:00:00.000Z"
        },
        {
            "date": "2022-05-26 00:00:00",
            "value": 19,
            "id": "中立_05-26",
            "xIndex": 10,
            "xLabel": "05-26",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-25T16:00:00.000Z"
        },
        {
            "date": "2022-05-27 00:00:00",
            "value": 19,
            "id": "中立_05-27",
            "xIndex": 11,
            "xLabel": "05-27",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-26T16:00:00.000Z"
        },
        {
            "date": "2022-05-28 00:00:00",
            "value": 11,
            "id": "中立_05-28",
            "xIndex": 12,
            "xLabel": "05-28",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-27T16:00:00.000Z"
        },
        {
            "date": "2022-05-29 00:00:00",
            "value": 7,
            "id": "中立_05-29",
            "xIndex": 13,
            "xLabel": "05-29",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-28T16:00:00.000Z"
        },
        {
            "date": "2022-05-30 00:00:00",
            "value": 13,
            "id": "中立_05-30",
            "xIndex": 14,
            "xLabel": "05-30",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-29T16:00:00.000Z"
        },
        {
            "date": "2022-05-31 00:00:00",
            "value": 15,
            "id": "中立_05-31",
            "xIndex": 15,
            "xLabel": "05-31",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-30T16:00:00.000Z"
        },
        {
            "date": "2022-06-01 00:00:00",
            "value": 12,
            "id": "中立_06-01",
            "xIndex": 16,
            "xLabel": "06-01",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-05-31T16:00:00.000Z"
        },
        {
            "date": "2022-06-02 00:00:00",
            "value": 30,
            "id": "中立_06-02",
            "xIndex": 17,
            "xLabel": "06-02",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-01T16:00:00.000Z"
        },
        {
            "date": "2022-06-03 00:00:00",
            "value": 4,
            "id": "中立_06-03",
            "xIndex": 18,
            "xLabel": "06-03",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-02T16:00:00.000Z"
        },
        {
            "date": "2022-06-04 00:00:00",
            "value": 6,
            "id": "中立_06-04",
            "xIndex": 19,
            "xLabel": "06-04",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-03T16:00:00.000Z"
        },
        {
            "date": "2022-06-05 00:00:00",
            "value": 1,
            "id": "中立_06-05",
            "xIndex": 20,
            "xLabel": "06-05",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-04T16:00:00.000Z"
        },
        {
            "date": "2022-06-06 00:00:00",
            "value": 28,
            "id": "中立_06-06",
            "xIndex": 21,
            "xLabel": "06-06",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-05T16:00:00.000Z"
        },
        {
            "date": "2022-06-07 00:00:00",
            "value": 20,
            "id": "中立_06-07",
            "xIndex": 22,
            "xLabel": "06-07",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-06T16:00:00.000Z"
        },
        {
            "date": "2022-06-08 00:00:00",
            "value": 8,
            "id": "中立_06-08",
            "xIndex": 23,
            "xLabel": "06-08",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-07T16:00:00.000Z"
        },
        {
            "date": "2022-06-09 00:00:00",
            "value": 12,
            "id": "中立_06-09",
            "xIndex": 24,
            "xLabel": "06-09",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-08T16:00:00.000Z"
        },
        {
            "date": "2022-06-10 00:00:00",
            "value": 10,
            "id": "中立_06-10",
            "xIndex": 25,
            "xLabel": "06-10",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-09T16:00:00.000Z"
        },
        {
            "date": "2022-06-11 00:00:00",
            "value": 4,
            "id": "中立_06-11",
            "xIndex": 26,
            "xLabel": "06-11",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-10T16:00:00.000Z"
        },
        {
            "date": "2022-06-12 00:00:00",
            "value": 19,
            "id": "中立_06-12",
            "xIndex": 27,
            "xLabel": "06-12",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-11T16:00:00.000Z"
        },
        {
            "date": "2022-06-13 00:00:00",
            "value": 16,
            "id": "中立_06-13",
            "xIndex": 28,
            "xLabel": "06-13",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-12T16:00:00.000Z"
        },
        {
            "date": "2022-06-14 00:00:00",
            "value": 14,
            "id": "中立_06-14",
            "xIndex": 29,
            "xLabel": "06-14",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-13T16:00:00.000Z"
        },
        {
            "date": "2022-06-15 00:00:00",
            "value": 18,
            "id": "中立_06-15",
            "xIndex": 30,
            "xLabel": "06-15",
            "itemIndex": 2,
            "itemLabel": "中立",
            "Date": "2022-06-14T16:00:00.000Z"
        }
    ],
    [
        {
            "date": "2022-05-16 00:00:00",
            "value": 2,
            "id": "負面_05-16",
            "xIndex": 0,
            "xLabel": "05-16",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-15T16:00:00.000Z"
        },
        {
            "date": "2022-05-17 00:00:00",
            "value": 19,
            "id": "負面_05-17",
            "xIndex": 1,
            "xLabel": "05-17",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-16T16:00:00.000Z"
        },
        {
            "date": "2022-05-18 00:00:00",
            "value": 23,
            "id": "負面_05-18",
            "xIndex": 2,
            "xLabel": "05-18",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-17T16:00:00.000Z"
        },
        {
            "date": "2022-05-19 00:00:00",
            "value": 27,
            "id": "負面_05-19",
            "xIndex": 3,
            "xLabel": "05-19",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-18T16:00:00.000Z"
        },
        {
            "date": "2022-05-20 00:00:00",
            "value": 12,
            "id": "負面_05-20",
            "xIndex": 4,
            "xLabel": "05-20",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-19T16:00:00.000Z"
        },
        {
            "date": "2022-05-21 00:00:00",
            "value": 10,
            "id": "負面_05-21",
            "xIndex": 5,
            "xLabel": "05-21",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-20T16:00:00.000Z"
        },
        {
            "date": "2022-05-22 00:00:00",
            "value": 8,
            "id": "負面_05-22",
            "xIndex": 6,
            "xLabel": "05-22",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-21T16:00:00.000Z"
        },
        {
            "date": "2022-05-23 00:00:00",
            "value": 9,
            "id": "負面_05-23",
            "xIndex": 7,
            "xLabel": "05-23",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-22T16:00:00.000Z"
        },
        {
            "date": "2022-05-24 00:00:00",
            "value": 46,
            "id": "負面_05-24",
            "xIndex": 8,
            "xLabel": "05-24",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-23T16:00:00.000Z"
        },
        {
            "date": "2022-05-25 00:00:00",
            "value": 29,
            "id": "負面_05-25",
            "xIndex": 9,
            "xLabel": "05-25",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-24T16:00:00.000Z"
        },
        {
            "date": "2022-05-26 00:00:00",
            "value": 8,
            "id": "負面_05-26",
            "xIndex": 10,
            "xLabel": "05-26",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-25T16:00:00.000Z"
        },
        {
            "date": "2022-05-27 00:00:00",
            "value": 5,
            "id": "負面_05-27",
            "xIndex": 11,
            "xLabel": "05-27",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-26T16:00:00.000Z"
        },
        {
            "date": "2022-05-28 00:00:00",
            "value": 9,
            "id": "負面_05-28",
            "xIndex": 12,
            "xLabel": "05-28",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-27T16:00:00.000Z"
        },
        {
            "date": "2022-05-29 00:00:00",
            "value": 4,
            "id": "負面_05-29",
            "xIndex": 13,
            "xLabel": "05-29",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-28T16:00:00.000Z"
        },
        {
            "date": "2022-05-30 00:00:00",
            "value": 8,
            "id": "負面_05-30",
            "xIndex": 14,
            "xLabel": "05-30",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-29T16:00:00.000Z"
        },
        {
            "date": "2022-05-31 00:00:00",
            "value": 4,
            "id": "負面_05-31",
            "xIndex": 15,
            "xLabel": "05-31",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-30T16:00:00.000Z"
        },
        {
            "date": "2022-06-01 00:00:00",
            "value": 8,
            "id": "負面_06-01",
            "xIndex": 16,
            "xLabel": "06-01",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-05-31T16:00:00.000Z"
        },
        {
            "date": "2022-06-02 00:00:00",
            "value": 29,
            "id": "負面_06-02",
            "xIndex": 17,
            "xLabel": "06-02",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-01T16:00:00.000Z"
        },
        {
            "date": "2022-06-03 00:00:00",
            "value": 12,
            "id": "負面_06-03",
            "xIndex": 18,
            "xLabel": "06-03",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-02T16:00:00.000Z"
        },
        {
            "date": "2022-06-04 00:00:00",
            "value": 0,
            "id": "負面_06-04",
            "xIndex": 19,
            "xLabel": "06-04",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-03T16:00:00.000Z"
        },
        {
            "date": "2022-06-05 00:00:00",
            "value": 4,
            "id": "負面_06-05",
            "xIndex": 20,
            "xLabel": "06-05",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-04T16:00:00.000Z"
        },
        {
            "date": "2022-06-06 00:00:00",
            "value": 38,
            "id": "負面_06-06",
            "xIndex": 21,
            "xLabel": "06-06",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-05T16:00:00.000Z"
        },
        {
            "date": "2022-06-07 00:00:00",
            "value": 6,
            "id": "負面_06-07",
            "xIndex": 22,
            "xLabel": "06-07",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-06T16:00:00.000Z"
        },
        {
            "date": "2022-06-08 00:00:00",
            "value": 2,
            "id": "負面_06-08",
            "xIndex": 23,
            "xLabel": "06-08",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-07T16:00:00.000Z"
        },
        {
            "date": "2022-06-09 00:00:00",
            "value": 5,
            "id": "負面_06-09",
            "xIndex": 24,
            "xLabel": "06-09",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-08T16:00:00.000Z"
        },
        {
            "date": "2022-06-10 00:00:00",
            "value": 3,
            "id": "負面_06-10",
            "xIndex": 25,
            "xLabel": "06-10",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-09T16:00:00.000Z"
        },
        {
            "date": "2022-06-11 00:00:00",
            "value": 3,
            "id": "負面_06-11",
            "xIndex": 26,
            "xLabel": "06-11",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-10T16:00:00.000Z"
        },
        {
            "date": "2022-06-12 00:00:00",
            "value": 1,
            "id": "負面_06-12",
            "xIndex": 27,
            "xLabel": "06-12",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-11T16:00:00.000Z"
        },
        {
            "date": "2022-06-13 00:00:00",
            "value": 4,
            "id": "負面_06-13",
            "xIndex": 28,
            "xLabel": "06-13",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-12T16:00:00.000Z"
        },
        {
            "date": "2022-06-14 00:00:00",
            "value": 11,
            "id": "負面_06-14",
            "xIndex": 29,
            "xLabel": "06-14",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-13T16:00:00.000Z"
        },
        {
            "date": "2022-06-15 00:00:00",
            "value": 7,
            "id": "負面_06-15",
            "xIndex": 30,
            "xLabel": "06-15",
            "itemIndex": 3,
            "itemLabel": "負面",
            "Date": "2022-06-14T16:00:00.000Z"
        }
    ]
];
const FilteredDateList = filteredData[0].map(d => new Date(d.date));
const axisWidth = 660;
const axisHeight = 400;
const filteredMaxValue = 85;
export default {
    xScale: makeAxisTimeScale(FilteredDateList, axisWidth, 0.5),
    yScale: makeAxisLinearScale({
        maxValue: filteredMaxValue,
        minValue: 0,
        axisWidth: axisHeight,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
        reverse: true
    }),
    maxValue: filteredMaxValue,
    dateAmount: FilteredDateList.length
};
