import * as d3 from 'd3';
import { EDGE_ARROW_COLOR, EDGE_LABEL_COLOR, EDGE_HIGHLIGHT_COLOR } from './const';
import { randomText } from '../utils';
// 建立箭頭 marker
const createArrowMarker = (defs, params) => {
    defs
        .append('marker')
        .attr('id', params && params.id ? params.id : 'chartEdgeArrow')
        .attr('refX', 0)
        .attr('refY', 0)
        .attr('viewBox', '-5 -5 10 10') // x y with height
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M-5,-5L5,0L-5,5') // 線的尾端在三角形的中心點
        .attr('fill', params && params.color ? params.color : EDGE_ARROW_COLOR)
        .attr('stroke', '#ffffff');
};
const callDotStyle = (selection) => {
    selection
        .attr('cx', 0) // 圓形的中心點
        .attr('cy', 0) // 圓形的中心點
        .attr('r', 3)
        .attr('fill', '#ffffff')
        .attr('stroke', EDGE_ARROW_COLOR);
};
// 建立圓點 marker
const createDotMarker = (defs, params) => {
    defs
        .append('marker')
        .attr('id', params && params.id ? params.id : 'chartEdgeDot')
        .attr('refX', 0)
        .attr('refY', 0)
        .attr('viewBox', '-4 -4 8 8') // x y with height
        .attr('markerWidth', 8)
        .attr('markerHeight', 8)
        .attr('orient', 'auto')
        .append('circle')
        .attr('cx', 0) // 圓形的中心點
        .attr('cy', 0) // 圓形的中心點
        .attr('r', 3)
        .attr('fill', '#ffffff')
        .attr('stroke', params && params.color ? params.color : EDGE_ARROW_COLOR);
};
export default class Edge {
    selection;
    params;
    data = [];
    // private renderData: Array<EdgeDatum> = []
    // selections
    // private defs: d3.Selection<any, any, any, any>
    // private edgesG: d3.Selection<any, any, any, any> | undefined
    edgesArrowG;
    edgesLabelG;
    edgesCountTagG; // 筆數tag
    edgesArrowHighlightG; // highlight時複製到這邊
    edgesLabelHighlightG; // highlight時複製到這邊
    edgesCountTagHighlightG; // highlight時複製到這邊
    edgesCountData = [];
    StartEdgesCountMap = new Map(); // 線條起點筆數對應 <nodeId, number>
    EndEdgesCountMap = new Map(); // 線條起點筆數對應 <nodeId, number>
    dotMarkerId = `dotMarker_${randomText()}`;
    dotHighlightMarkerId = `dotHighlightMarker_${randomText()}`;
    arrowMarkerId = `arrowMarker_${randomText()}`;
    arrowHighlightMarkerId = `arrowHighlightMarker_${randomText()}`;
    constructor(el, params) {
        this.selection = el;
        this.params = params;
        // defs
        const defs = this.selection.append('defs');
        createDotMarker(defs, {
            id: this.dotMarkerId,
            color: EDGE_ARROW_COLOR
        });
        createDotMarker(defs, {
            id: this.dotHighlightMarkerId,
            color: EDGE_HIGHLIGHT_COLOR
        });
        createArrowMarker(defs, {
            id: this.arrowMarkerId,
            color: EDGE_ARROW_COLOR
        });
        createArrowMarker(defs, {
            id: this.arrowHighlightMarkerId,
            color: EDGE_HIGHLIGHT_COLOR
        });
        // g
        this.edgesArrowG = this.selection.append('g');
        this.edgesLabelG = this.selection.append('g');
        this.edgesCountTagG = this.selection.append('g');
        this.edgesArrowHighlightG = this.selection.append('g').classed('edgesArrowHighlightG', true);
        this.edgesLabelHighlightG = this.selection.append('g').classed('edgesLabelHighlightG', true);
        this.edgesCountTagHighlightG = this.selection.append('g').classed('edgesCountTagHighlightG', true);
    }
    render() {
        // console.log(this.renderData)
        // console.log('StartEdgesCountMap', this.StartEdgesCountMap)
        // console.log('EndEdgesCountMap', this.EndEdgesCountMap)
        // 箭頭
        this.renderArrows(this.data);
        // 標籤
        this.renderLabels(this.data);
        // 筆數
        this.renderCountTags(this.edgesCountData);
    }
    setData(data) {
        this.data = data;
        // -- edgeCountData --
        this.edgesCountData = [];
        this.data.forEach(d => {
            // 外圍線流入或流出線分別有超過1條則顯示數字
            if (d.startCount > 1) {
                this.edgesCountData.push({
                    edgeId: d.id,
                    type: 'start',
                    nodeId: d.startNodeId,
                    count: d.startCount,
                    x: d.startCountX,
                    y: d.startCountY
                });
            }
            if (d.endCount > 1) {
                this.edgesCountData.push({
                    edgeId: d.id,
                    type: 'end',
                    nodeId: d.endNodeId,
                    count: d.endCount,
                    x: d.endCountX,
                    y: d.endCountY
                });
            }
        });
    }
    remove() {
        this.selection.selectAll('*').remove();
    }
    select() {
        return this.selection;
    }
    setParams(params) {
        if (params.highlightIds) {
            if (params.highlightIds.length) {
                this.highlight(params.highlightIds);
            }
            else {
                this.removeHighlight();
            }
        }
    }
    renderArrows(renderData) {
        const update = this.edgesArrowG
            .selectAll('path')
            .data(renderData, (d) => d.id);
        const enter = update.enter()
            .append('path')
            .attr('stroke', EDGE_ARROW_COLOR)
            .attr('fill', 'none')
            .attr('marker-start', `url(#${this.dotMarkerId})`)
            .attr('marker-end', `url(#${this.arrowMarkerId})`);
        update.merge(enter)
            .style("opacity", 0)
            .attr('d', d => d.pathD)
            .transition()
            .style("opacity", 1);
        update.exit().remove();
    }
    renderLabels(renderData) {
        const update = this.edgesLabelG
            .selectAll('g')
            .data(renderData, (d) => d.id);
        const enter = update.enter()
            .append('g');
        // circle
        enter
            .filter(d => d.labelDotShow === true)
            .append('circle')
            .call(callDotStyle);
        // text
        enter
            .append('text')
            // .text(d => d.label)
            .attr('fill', EDGE_LABEL_COLOR)
            .attr('font-size', 12);
        const textG = update.merge(enter)
            .attr('transform', d => `translate(${d.labelX}, ${d.labelY})`);
        textG.select('text')
            .text(d => d.label)
            .attr('text-anchor', d => d.labelTextAnchor)
            // .attr('x', d => d.labelTextAnchor === 'start' ? 10
            //   : d.labelTextAnchor === 'end' ? -10
            //   : 0)
            .attr('x', d => d.labelTransformX)
            .attr('y', d => d.labelTransformY)
            .style('dominant-baseline', d => d.labelDominantBaseline);
        update.exit().remove();
    }
    renderCountTags(edgesCountData) {
        const update = this.edgesCountTagG
            .selectAll('g')
            .data(edgesCountData, (d) => d.edgeId);
        const enter = update.enter()
            .append('g');
        enter
            .append('circle')
            .attr('stroke', EDGE_ARROW_COLOR)
            .attr('fill', '#ffffff')
            .attr('rx', 0)
            .attr('ry', 0)
            .attr('r', 7);
        enter
            .append('text')
            .attr('font-size', 10)
            .attr('fill', EDGE_ARROW_COLOR)
            .attr('text-anchor', 'middle')
            .style('dominant-baseline', 'middle');
        const g = update.merge(enter)
            .attr('transform', d => `translate(${d.x}, ${d.y})`);
        g.select('text')
            .text(d => d.count);
        update.exit().remove();
    }
    highlight(ids) {
        // 複製到 dom-node到 edgesArrowHighlightG後標色
        this.edgesArrowG
            .selectAll('path')
            .filter((d) => ids.includes(d.id))
            .each((datum, i, group) => {
            this.edgesArrowHighlightG
                .node()
                .appendChild(d3.select(group[i]).clone(true).node());
        });
        this.edgesArrowHighlightG
            .selectAll('path')
            .attr('marker-start', `url(#${this.dotHighlightMarkerId})`)
            .attr('marker-end', `url(#${this.arrowHighlightMarkerId})`)
            .style('stroke', EDGE_HIGHLIGHT_COLOR)
            .transition()
            .style("opacity", 1);
        // 複製到 dom-node到 edgesLabelHighlightG後標色
        this.edgesLabelG
            .selectAll('g')
            .filter((d) => ids.includes(d.id))
            .each((datum, i, group) => {
            this.edgesLabelHighlightG
                .node()
                .appendChild(d3.select(group[i]).clone(true).node());
        });
        this.edgesLabelHighlightG
            .selectAll('circle')
            .style('stroke', EDGE_HIGHLIGHT_COLOR);
        this.edgesLabelHighlightG
            .selectAll('text')
            .style('fill', EDGE_HIGHLIGHT_COLOR);
        // 複製到 dom-node到 edgesCountTagHighlightG後標色
        this.edgesCountTagG
            .selectAll('g')
            .filter((d) => ids.includes(d.edgeId))
            .each((datum, i, group) => {
            this.edgesCountTagHighlightG
                .node()
                .appendChild(d3.select(group[i]).clone(true).node());
        });
        this.edgesCountTagHighlightG
            .selectAll('circle')
            .style('stroke', EDGE_HIGHLIGHT_COLOR);
        this.edgesCountTagHighlightG
            .selectAll('text')
            .style('fill', EDGE_HIGHLIGHT_COLOR);
    }
    removeHighlight() {
        // this.edgesArrowG!
        //   .selectAll('path')
        //   .style('stroke', null)
        //   .attr('marker-end', 'url(#chartDirectedTreeEdgeArrow)')
        // this.edgesLabelG!
        //   .selectAll('circle')
        //   .style('stroke', null)
        // this.edgesLabelG!
        //   .selectAll('text')
        //   .style('fill', null)
        // this.edgesCountTagG!
        //   .selectAll('circle')
        //   .style('stroke', null)
        // this.edgesCountTagG!
        //   .selectAll('text')
        //   .style('fill', null)
        this.edgesArrowHighlightG.html('');
        this.edgesLabelHighlightG.html('');
        this.edgesCountTagHighlightG.html('');
    }
}
