import * as d3 from 'd3';
import { formatCommaNumber, formatPercentage } from '../utils';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '../defaults';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { DEFAULT_LABEL_ARC_PIE_PARAMS } from './defaults';
import { makeColorScale, makeArc } from '../moduleUtils';
const makeItemDataMap = (data) => {
    const ItemDataMap = new Map();
    data.forEach(d => {
        const itemData = ItemDataMap.get(d.data.itemLabel) ?? [];
        itemData.push(d);
        ItemDataMap.set(d.data.itemLabel, itemData);
    });
    return ItemDataMap;
};
const makeRenderData = (dataset, arc, centroid) => {
    return dataset.data.map((d, i) => {
        const [_x, _y] = arc.centroid(d);
        return {
            ...d,
            arcIndex: i,
            arcLabel: dataset.arcLabels[i],
            x: _x * centroid,
            y: _y * centroid
        };
    });
};
const makeCallbackData = ({ dataset, datum, ItemDataMap, event }) => {
    return {
        dataset,
        groupData: [datum],
        itemData: ItemDataMap.get(datum.data.itemLabel),
        datum,
        itemLabel: datum.data.itemLabel,
        itemIndex: datum.data.itemIndex,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
export default class LabelArc {
    params = DEFAULT_LABEL_ARC_PIE_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        arcLabels: [],
        axisWidth: 0
    };
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    renderData = [];
    //   private scaleOfDefault: number = 1 // 相對預設高度的縮放比例
    colorScale;
    //   private radiusScale?: d3.ScaleLinear<number, number> // 0~1 => 半徑（為1時等於場景高度的一半）
    gSelection;
    //   private middleSelection: d3.Selection<SVGGElement, any, any, any>
    labelSelection = undefined;
    // private shorterSideWidth: number = DEFAULT_CHART_HEIGHT // 長或寬較短的邊長
    arc;
    arcMouseover;
    // private colors: string[] = []
    ItemDataMap = new Map;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    // private tooltip: TooltipFollowing | undefined
    constructor(selection, params) {
        this.selection = selection;
        this.gSelection = selection.append('g');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        // this.setColorScale(this.data, this.params)
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
        // this.setScaledParams(this.params, this.radiusScale, this.scaleOfDefault)
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.ItemDataMap = makeItemDataMap(dataset.data);
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
    }
    render() {
        // 弧產生器 (d3.arc())
        this.arc = makeArc({
            axisWidth: this.dataset.axisWidth,
            // innerRadius: this.params.innerRadius!,
            innerRadius: 0,
            outerRadius: this.params.outerRadius,
            padAngle: 0,
            cornerRadius: 0
        });
        const renderData = makeRenderData(this.dataset, this.arc, this.params.centroid);
        // console.log(renderData)
        this.renderLabel(this.gSelection, renderData, this.arc);
        this.labelSelection && this.setDonutEvent(this.labelSelection);
        // this.initHighlight()
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    initHighlight() {
        this.removeHighlight();
        if (this.params.highlightItemId || this.params.highlightDatumId) {
            this.highlight(this.labelSelection, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    highlight(labelSelection, itemId, datumId) {
        if (!labelSelection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .filter(d => d.data.itemLabel === itemId)
                .map(d => d.data.id);
            ids = ids.concat(_ids);
        }
        labelSelection.each((d, i, n) => {
            const segment = d3.select(n[i]);
            if (ids.includes(d.id)) {
                segment
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                segment
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight() {
        if (!this.labelSelection) {
            return;
        }
        this.labelSelection
            .transition()
            .duration(200)
            .style('opacity', 1);
    }
    // 繪製圓餅圖
    renderLabel(selection, data, arc) {
        // console.log(data)
        // let update = this.gSelection.selectAll('g').data(pieData)
        let update = selection
            .selectAll('text')
            .data(data, d => d.id);
        let enter = update.enter()
            .append('text')
            .classed('bpchart__text', true);
        let exit = update.exit();
        enter
            .append('text');
        this.labelSelection = update.merge(enter);
        this.labelSelection
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .style('dominant-baseline', 'middle')
            // .style('pointer-events', 'none')
            .style('cursor', d => this.params.highlightTarget && this.params.highlightTarget != 'none'
            ? 'pointer'
            : 'none')
            // .text((d, i) => d.arcLabel)
            .text(d => this.params.text(d))
            .attr('font-size', this.params.fontSize)
            .attr('fill', (d, i) => {
            return this.params.solidColor ?? this.colorScale(d.data.itemLabel);
        })
            .transition()
            .attr('transform', (d) => {
            return 'translate(' + d.x + ',' + d.y + ')';
        })
            .on('end', () => this.initHighlight());
        exit.remove();
        // 如無新增資料則不用等動畫
        // if (enter.size() == 0) {
        //   this.initHighlight()
        // }
    }
    // // 用動畫重繪圖形
    // private transitionDonut (labelSelection: d3.Selection<SVGPathElement, Datum, any, any>, arc: d3.Arc<any, d3.DefaultArcObject>) {
    //   labelSelection!
    //     .attr('fill', (d, i) => {
    //       return this.colorScale!(d.itemLabel)
    //     })
    //     .transition()
    //     .attr('d', (d, i) => {
    //       return arc!(d as any)
    //     })
    // }
    // private renderDonutText (labelSelection: d3.Selection<SVGGElement, Datum, any, any>): void {
    //   labelSelection
    //     .each((d, i, n) => {
    //       const textUpdate = d3.select(n[i]).selectAll('text').data([d] as any)
    //       const textEnter = textUpdate.enter()
    //         .append('text')
    //         .attr('font-weight', 'bold')
    //         .style('text-anchor', 'middle')
    //         .style('dominant-baseline', 'middle')
    //         .style('pointer-events', 'none')
    //       textUpdate.merge(textEnter)
    //         .text((d: any) => {
    //           if (this.params.text && typeof this.params.text === 'function') {
    //             return this.params.text(d)
    //           }
    //           return ''
    //         })
    //         .transition()
    //         .attr('transform', (d) => {
    //           // let percent = Number(d.value) / this.sumValue * 100
    //           let x = this.arc!.centroid(d as any)[0] * this.params!.centroid
    //           let y = this.arc!.centroid(d as any)[1] * this.params!.centroid
    //           // const percent = d.data.percent
    //           // if(percent < 6){
    //           //   x *= 1.2;
    //           //   y *= 1.2;
    //           // }
    //           return 'translate(' + x + ',' + y+ ')'
    //         })
    //         .attr('font-size', this.params!.fontSize)
    //         .attr('fill', (d) => {
    //           if (!this.params?.color) {
    //             return '#000000'
    //           }
    //           if (typeof this.params.color === 'string') {
    //             return this.params.color
    //           } else if (typeof this.params.color === 'function') {
    //             return this.params.color(d as Datum, this.colorScale!)
    //           } else {
    //             return '#000000'
    //           }
    //         })
    //         // (d) => this.colorScale!((d as any).data.label)
    //       textUpdate.exit().remove()
    //     })
    // }
    setDonutEvent = (labelSelection) => {
        labelSelection
            .on('mouseover', (d, i, all) => {
            d3.event.stopPropagation();
            // const segment = d3.select(all[i])
            // 區塊放大動畫
            // this.onBlockMouseover(segment, d)
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'item') {
                    this.highlight(labelSelection, d.data.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(labelSelection, undefined, d.data.id);
                }
            }
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // if (this.tooltip) {
            //   this.tooltip.setDatum(callbackData)
            // }
            // callback
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i, all) => {
            d3.event.stopPropagation();
            // const segment = d3.select(all[i])
            // 取消區塊放大動畫
            // this.onBlockMousemove(segment as d3.Selection<SVGGElement, any, any, any>)
            // if (this.tooltip) {
            //   this.tooltip.setDatum({
            //     x: d3.event.clientX,
            //     y: d3.event.clientY
            //   })
            // }
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i, all) => {
            d3.event.stopPropagation();
            // 取消區塊放大動畫
            // this.onBlockMouseout()
            // this.removeHighlight()
            this.initHighlight();
            // if (this.tooltip) {
            //   this.tooltip.remove()
            // }
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.mouseoutCallback(callbackData);
        })
            .on('click', (d, i, all) => {
            d3.event.stopPropagation();
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.clickCallback(callbackData);
        });
    };
}
