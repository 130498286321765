import { GraphicRowBarLayout } from '@bpchart/d3-modules/graphicRowBarLayout';
import dataset from './dataset';
export default {
    props: {
        module: GraphicRowBarLayout,
        dataset,
        params: {
            groupLayout: [
                {
                    stackAmount: 3,
                    barWidth: 10
                },
                {
                    stackAmount: 1
                }
            ]
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
