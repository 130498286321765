export default [
    {
        "x1": 7.051159274193548,
        "x2": 7.051159274193548,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 21.153477822580644,
        "x2": 21.153477822580644,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 35.255796370967744,
        "x2": 35.255796370967744,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 49.35811491935485,
        "x2": 49.35811491935485,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 63.460433467741936,
        "x2": 63.460433467741936,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 77.56275201612902,
        "x2": 77.56275201612902,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 91.66507056451614,
        "x2": 91.66507056451614,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 105.76738911290323,
        "x2": 105.76738911290323,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 119.86970766129032,
        "x2": 119.86970766129032,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 133.9720262096774,
        "x2": 133.9720262096774,
        "y1": 0,
        "y2": 400,
        "active": true
    },
    {
        "x1": 148.0743447580645,
        "x2": 148.0743447580645,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 162.17666330645162,
        "x2": 162.17666330645162,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 176.27898185483875,
        "x2": 176.27898185483875,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 190.3813004032258,
        "x2": 190.3813004032258,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 204.4836189516129,
        "x2": 204.4836189516129,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 218.5859375,
        "x2": 218.5859375,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 232.6882560483871,
        "x2": 232.6882560483871,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 246.7905745967742,
        "x2": 246.7905745967742,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 260.8928931451613,
        "x2": 260.8928931451613,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 274.9952116935484,
        "x2": 274.9952116935484,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 289.0975302419355,
        "x2": 289.0975302419355,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 303.19984879032256,
        "x2": 303.19984879032256,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 317.3021673387097,
        "x2": 317.3021673387097,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 331.4044858870968,
        "x2": 331.4044858870968,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 345.5068044354839,
        "x2": 345.5068044354839,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 359.609122983871,
        "x2": 359.609122983871,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 373.71144153225805,
        "x2": 373.71144153225805,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 387.8137600806452,
        "x2": 387.8137600806452,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 401.91607862903226,
        "x2": 401.91607862903226,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 416.0183971774194,
        "x2": 416.0183971774194,
        "y1": 0,
        "y2": 400,
        "active": false
    },
    {
        "x1": 430.12071572580646,
        "x2": 430.12071572580646,
        "y1": 0,
        "y2": 400,
        "active": false
    }
];
