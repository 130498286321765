// @ts-ignore
import to_old_format from '@/utils/to_old_format.js';
const response5 = {
    "cache": {
        "RECAL": true,
        "_CACHE_CHECK_TIME": 0,
        "_CACHE_LOAD_TIME": 0.025512933731079,
        "cache_ver": null
    },
    "code": 200,
    "d3": {
        "edges": [],
        "nodes": [
            {
                "capital_amount": 199800000,
                "company_name": "今武投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "23060958",
                    "89944066",
                    "53653514"
                ],
                "managers": [],
                "paidup_capital_amount": 199800000,
                "public_issue": null,
                "public_shares": 19980000,
                "representative_name": "廖文忠",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "順豐投資股份有限公司",
                        "ROL_for_search": "順豐投資股份有限公司",
                        "ROL_uniID": "84306342",
                        "name": "廖文忠",
                        "sno": "0001",
                        "stocks": 1980000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "僑信投資股份有限公司",
                        "ROL_for_search": "僑信投資股份有限公司",
                        "ROL_uniID": "23528960",
                        "name": "林聰明",
                        "sno": "0002",
                        "stocks": 1000,
                        "title": "董事"
                    },
                    {
                        "ROL": "今日股份有限公司",
                        "ROL_for_search": "今日股份有限公司",
                        "ROL_uniID": "15044527",
                        "name": "蔡青月",
                        "sno": "0003",
                        "stocks": 770000,
                        "title": "董事"
                    },
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "劉學堯",
                        "sno": "0004",
                        "stocks": 240000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "12531756",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "順豐投資股份有限公司",
                        "percentage": 9.9099099099099,
                        "uniID": "84306342"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "僑信投資股份有限公司",
                        "percentage": 0.005005005005005,
                        "uniID": "23528960"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "今日股份有限公司",
                        "percentage": 3.8538538538539,
                        "uniID": "15044527"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 1.2012012012012,
                        "uniID": "84250826"
                    }
                ]
            },
            {
                "capital_amount": 199800000,
                "company_name": "正利時投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "84449165",
                    "23528960",
                    "15044527",
                    "84306342",
                    "15816880",
                    "84250826",
                    "89944066",
                    "22470602",
                    "23641772"
                ],
                "managers": [],
                "paidup_capital_amount": 199800000,
                "public_issue": null,
                "public_shares": 19980000,
                "representative_name": "林聰明",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "15044527"
                    ],
                    [
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "林聰明",
                        "sno": "0001",
                        "stocks": 50000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "陳治煌",
                        "sno": "0002",
                        "stocks": 49000,
                        "title": "董事"
                    },
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "劉學堯",
                        "sno": "0003",
                        "stocks": 230000,
                        "title": "董事"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "鄭美雲",
                        "sno": "0004",
                        "stocks": 49000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "86924853",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 0.25025025025025,
                        "uniID": "84250826"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 0.24524524524525,
                        "uniID": "23641772"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 1.1511511511512,
                        "uniID": "15816880"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.24524524524525,
                        "uniID": "84449165"
                    }
                ]
            },
            {
                "capital_amount": 198000000,
                "company_name": "豐年華投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "86924853",
                    "12652575",
                    "23528960",
                    "15044527",
                    "15816880",
                    "84250826",
                    "89944066",
                    "23641772"
                ],
                "managers": [],
                "paidup_capital_amount": 198000000,
                "public_issue": null,
                "public_shares": 19800000,
                "representative_name": "倪思為",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "倪思為",
                        "sno": "0001",
                        "stocks": 100000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "程建中",
                        "sno": "0002",
                        "stocks": 1096000,
                        "title": "董事"
                    },
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "陳俊宏",
                        "sno": "0003",
                        "stocks": 1000,
                        "title": "董事"
                    },
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "陳美貞",
                        "sno": "0004",
                        "stocks": 1000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "84449165",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 0.50505050505051,
                        "uniID": "84250826"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 5.5353535353535,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 0.0050505050505051,
                        "uniID": "89944066"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 0.0050505050505051,
                        "uniID": "23641772"
                    }
                ]
            },
            {
                "capital_amount": 6000000000,
                "company_name": "第一華僑大飯店股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "15415013"
                ],
                "managers": [],
                "paidup_capital_amount": 4999983460,
                "public_issue": "上市",
                "public_shares": 499998346,
                "representative_name": "顧安生",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "15415013",
                        "11587957"
                    ],
                    [
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013",
                        "11587957"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "上准工程股份有限公司",
                        "ROL_for_search": "上准工程股份有限公司",
                        "ROL_uniID": "12652575",
                        "name": "顧安生",
                        "sno": "0001",
                        "stocks": 38820,
                        "title": "董事長"
                    },
                    {
                        "ROL": "萬華企業股份有限公司",
                        "ROL_for_search": "萬華企業股份有限公司",
                        "ROL_uniID": "15415013",
                        "name": "梁鈺汶",
                        "sno": "0002",
                        "stocks": 99000503,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "李協鴻",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "葛志雄",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "周秀琴",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "獨立董事"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "11587957",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "上准工程股份有限公司",
                        "percentage": 0.007764025683397,
                        "uniID": "12652575"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "萬華企業股份有限公司",
                        "percentage": 19.800166098949,
                        "uniID": "15415013"
                    }
                ]
            },
            {
                "capital_amount": 4500000000,
                "company_name": "萬華企業股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "11587957"
                ],
                "managers": [],
                "paidup_capital_amount": 4499678380,
                "public_issue": "上市",
                "public_shares": 449967838,
                "representative_name": "蔡茂昌",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "15415013"
                    ],
                    [
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575",
                        "11587957",
                        "15415013"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575",
                        "11587957",
                        "15415013"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "今日股份有限公司",
                        "ROL_for_search": "今日股份有限公司",
                        "ROL_uniID": "15044527",
                        "name": "蔡茂昌",
                        "sno": "0001",
                        "stocks": 89809699,
                        "title": "董事長"
                    },
                    {
                        "ROL": "財團法人徐德榮社會福利慈善事業基金會",
                        "ROL_for_search": "財團法人徐德榮社會福利慈善事業基金會",
                        "name": "吳雅珍",
                        "sno": "0002",
                        "stocks": 1870,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "徐昌隆",
                        "sno": "0003",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "唐肇津",
                        "sno": "0004",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "張若虎",
                        "sno": "0005",
                        "stocks": 0,
                        "title": "獨立董事"
                    },
                    {
                        "ROL": "第一華僑大飯店股份有限公司",
                        "ROL_uniID": "11587957",
                        "contribution": 89809699,
                        "edge_type": "大股東",
                        "is_big": true,
                        "name": "第一華僑大飯店股份有限公司",
                        "stocks": 89809699
                    },
                    {
                        "ROL": "禪豐投資股份有限公司",
                        "ROL_uniID": "53653514",
                        "contribution": 52258768,
                        "edge_type": "大股東",
                        "is_big": true,
                        "name": "禪豐投資股份有限公司",
                        "stocks": 52258768
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "15415013",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "今日股份有限公司",
                        "percentage": 19.959137390615,
                        "uniID": "15044527"
                    },
                    {
                        "edge_types": [
                            "大股東"
                        ],
                        "name": "第一華僑大飯店股份有限公司",
                        "percentage": 19.959137390615,
                        "uniID": "11587957"
                    },
                    {
                        "edge_types": [
                            "大股東"
                        ],
                        "name": "禪豐投資股份有限公司",
                        "percentage": 11.613889613151,
                        "uniID": "53653514"
                    }
                ]
            },
            {
                "capital_amount": 499500000,
                "company_name": "僑信投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "12531756",
                    "84250826",
                    "22470602"
                ],
                "managers": [],
                "paidup_capital_amount": 499500000,
                "public_issue": null,
                "public_shares": 49950000,
                "representative_name": "劉學堯",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "劉學堯",
                        "sno": "0001",
                        "stocks": 1755000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "張瑞珊",
                        "sno": "0002",
                        "stocks": 5000,
                        "title": "董事"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "程建中",
                        "sno": "0003",
                        "stocks": 7500,
                        "title": "董事"
                    },
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "倪思為",
                        "sno": "0004",
                        "stocks": 5000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "23528960",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 3.5135135135135,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 0.01001001001001,
                        "uniID": "89944066"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.015015015015015,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 0.01001001001001,
                        "uniID": "84250826"
                    }
                ]
            },
            {
                "Substantial-beneficiary": [],
                "capital_amount": 256630000,
                "company_name": "今日股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "12531756",
                    "15415013",
                    "53653514"
                ],
                "managers": [],
                "paidup_capital_amount": 256630000,
                "public_issue": null,
                "public_shares": 25663000,
                "representative_name": "蔣光霞",
                "role": "法人",
                "roles_related_to_root": [
                    "root"
                ],
                "shareholders": [
                    {
                        "ROL": "順豐投資股份有限公司",
                        "ROL_for_search": "順豐投資股份有限公司",
                        "ROL_uniID": "84306342",
                        "name": "蔣光霞",
                        "sno": "0001",
                        "stocks": 1117,
                        "title": "董事長"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "陳美貞",
                        "sno": "0002",
                        "stocks": 484557,
                        "title": "董事"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "陳采芬",
                        "sno": "0003",
                        "stocks": 1266,
                        "title": "董事"
                    },
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "徐麗珠",
                        "sno": "0004",
                        "stocks": 1644038,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "15044527",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "順豐投資股份有限公司",
                        "percentage": 0.0043525698476406,
                        "uniID": "84306342"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 1.8881541518918,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.0049331722713634,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 6.4062580368624,
                        "uniID": "84250826"
                    }
                ]
            },
            {
                "capital_amount": 199800000,
                "company_name": "文華投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 199800000,
                "public_issue": null,
                "public_shares": 19980000,
                "representative_name": "程建中",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "23060958"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "23060958"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "順豐投資股份有限公司",
                        "ROL_for_search": "順豐投資股份有限公司",
                        "ROL_uniID": "84306342",
                        "name": "程建中",
                        "sno": "0001",
                        "stocks": 370000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "劉學堯",
                        "sno": "0002",
                        "stocks": 3000,
                        "title": "董事"
                    },
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "翁新凱",
                        "sno": "0003",
                        "stocks": 148000,
                        "title": "董事"
                    },
                    {
                        "ROL": "今武投資股份有限公司",
                        "ROL_for_search": "今武投資股份有限公司",
                        "ROL_uniID": "12531756",
                        "name": "周美珍",
                        "sno": "0004",
                        "stocks": 7510000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "23060958",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "順豐投資股份有限公司",
                        "percentage": 1.8518518518519,
                        "uniID": "84306342"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 0.015015015015015,
                        "uniID": "84250826"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 0.74074074074074,
                        "uniID": "89944066"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "今武投資股份有限公司",
                        "percentage": 37.587587587588,
                        "uniID": "12531756"
                    }
                ]
            },
            {
                "capital_amount": 198010000,
                "company_name": "順豐投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "23060958",
                    "12531756",
                    "15044527"
                ],
                "managers": [],
                "paidup_capital_amount": 198010000,
                "public_issue": null,
                "public_shares": 19801000,
                "representative_name": "程建中",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "程建中",
                        "sno": "0001",
                        "stocks": 50000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "劉學堯",
                        "sno": "0002",
                        "stocks": 50000,
                        "title": "董事"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "徐麗珠",
                        "sno": "0003",
                        "stocks": 1000,
                        "title": "董事"
                    },
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "陳美貞",
                        "sno": "0004",
                        "stocks": 50000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "84306342",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 0.25251249936872,
                        "uniID": "89944066"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 0.25251249936872,
                        "uniID": "23641772"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 0.0050502499873744,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 0.25251249936872,
                        "uniID": "15816880"
                    }
                ]
            },
            {
                "capital_amount": 10000000,
                "company_name": "上准工程股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "11587957"
                ],
                "managers": [],
                "paidup_capital_amount": 2500000,
                "public_issue": null,
                "public_shares": 250000,
                "representative_name": "劉學堯",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12652575"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "12652575"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "劉學堯",
                        "sno": "0001",
                        "stocks": 23000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "冷麗麗",
                        "sno": "0002",
                        "stocks": 23000,
                        "title": "董事"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "林聰明",
                        "sno": "0003",
                        "stocks": 23000,
                        "title": "董事"
                    },
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "鄭雅惠",
                        "sno": "0004",
                        "stocks": 23000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "12652575",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 9.2,
                        "uniID": "84250826"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 9.2,
                        "uniID": "23641772"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 9.2,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 9.2,
                        "uniID": "15816880"
                    }
                ]
            },
            {
                "capital_amount": 397600000,
                "company_name": "藝豐影業股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "86924853",
                    "84306342",
                    "12652575",
                    "84250826",
                    "23641772",
                    "53653514"
                ],
                "managers": [],
                "paidup_capital_amount": 397600000,
                "public_issue": null,
                "public_shares": 39760000,
                "representative_name": "徐碧麗",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "徐碧麗",
                        "sno": "0001",
                        "stocks": 2680000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "林瑞珠",
                        "sno": "0002",
                        "stocks": 220000,
                        "title": "董事"
                    },
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "劉學堯",
                        "sno": "0003",
                        "stocks": 500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "黃美珠",
                        "sno": "0004",
                        "stocks": 100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "15816880",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 6.7404426559356,
                        "uniID": "23641772"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.5533199195171,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 1.2575452716298,
                        "uniID": "89944066"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 0.25150905432596,
                        "uniID": "86924853"
                    }
                ]
            },
            {
                "capital_amount": 198030000,
                "company_name": "盛智投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "12531756",
                    "86924853",
                    "84449165",
                    "23060958",
                    "23528960",
                    "15044527",
                    "12652575",
                    "89944066"
                ],
                "managers": [],
                "paidup_capital_amount": 198030000,
                "public_issue": null,
                "public_shares": 19803000,
                "representative_name": "陳宗澤",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "陳宗澤",
                        "sno": "0001",
                        "stocks": 10000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "陳美貞",
                        "sno": "0002",
                        "stocks": 40000,
                        "title": "董事"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "劉學堯",
                        "sno": "0003",
                        "stocks": 50000,
                        "title": "董事"
                    },
                    {
                        "ROL": "僑信投資股份有限公司",
                        "ROL_for_search": "僑信投資股份有限公司",
                        "ROL_uniID": "23528960",
                        "name": "陳美寶",
                        "sno": "0004",
                        "stocks": 1000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "84250826",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.050497399383932,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 0.20198959753573,
                        "uniID": "15816880"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 0.25248699691966,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "僑信投資股份有限公司",
                        "percentage": 0.0050497399383932,
                        "uniID": "23528960"
                    }
                ]
            },
            {
                "capital_amount": 180000000,
                "company_name": "利明投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "84449165",
                    "23060958",
                    "23528960",
                    "84306342",
                    "15816880",
                    "23641772"
                ],
                "managers": [],
                "paidup_capital_amount": 132132000,
                "public_issue": null,
                "public_shares": 13213200,
                "representative_name": "徐麗珠",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "盛智投資股份有限公司",
                        "ROL_for_search": "盛智投資股份有限公司",
                        "ROL_uniID": "84250826",
                        "name": "徐麗珠",
                        "sno": "0001",
                        "stocks": 336937,
                        "title": "董事長"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "程建中",
                        "sno": "0002",
                        "stocks": 516000,
                        "title": "董事"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "林聰明",
                        "sno": "0003",
                        "stocks": 536937,
                        "title": "董事"
                    },
                    {
                        "ROL": "今武投資股份有限公司",
                        "ROL_for_search": "今武投資股份有限公司",
                        "ROL_uniID": "12531756",
                        "name": "劉學堯",
                        "sno": "0004",
                        "stocks": 1190167,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "89944066",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "盛智投資股份有限公司",
                        "percentage": 2.5500030272758,
                        "uniID": "84250826"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 3.9051857233675,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 4.06364090455,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "今武投資股份有限公司",
                        "percentage": 9.0074092574093,
                        "uniID": "12531756"
                    }
                ]
            },
            {
                "capital_amount": 6000000,
                "company_name": "港九香滿樓餐廳股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [],
                "managers": [],
                "paidup_capital_amount": 6000000,
                "public_issue": null,
                "public_shares": 600000,
                "representative_name": "陳采蘋",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960",
                        "22470602"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960",
                        "22470602"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "僑信投資股份有限公司",
                        "ROL_for_search": "僑信投資股份有限公司",
                        "ROL_uniID": "23528960",
                        "name": "陳采蘋",
                        "sno": "0001",
                        "stocks": 55000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "吳春芳",
                        "sno": "0002",
                        "stocks": 42000,
                        "title": "董事"
                    },
                    {
                        "ROL": "",
                        "ROL_for_search": "",
                        "name": "卓瑞琴",
                        "sno": "0003",
                        "stocks": 7000,
                        "title": "董事"
                    },
                    {
                        "ROL": "萬佳投資股份有限公司",
                        "ROL_for_search": "萬佳投資股份有限公司",
                        "ROL_uniID": "23641772",
                        "name": "林聰明",
                        "sno": "0004",
                        "stocks": 100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "22470602",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "僑信投資股份有限公司",
                        "percentage": 9.1666666666667,
                        "uniID": "23528960"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 7,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "萬佳投資股份有限公司",
                        "percentage": 16.666666666667,
                        "uniID": "23641772"
                    }
                ]
            },
            {
                "capital_amount": 198000000,
                "company_name": "萬佳投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "86924853",
                    "12652575",
                    "84449165",
                    "84306342",
                    "15816880",
                    "22470602"
                ],
                "managers": [],
                "paidup_capital_amount": 198000000,
                "public_issue": null,
                "public_shares": 19800000,
                "representative_name": "郭錫卿",
                "role": "法人",
                "roles_related_to_root": [
                    "up",
                    "down"
                ],
                "routes": [
                    [
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "84250826",
                        "86924853",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84250826",
                        "84449165",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "84250826",
                        "23528960",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "12531756",
                        "89944066",
                        "15816880",
                        "84306342",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "84449165",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84449165",
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "84306342",
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "15816880",
                        "86924853",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ],
                    [
                        "86924853",
                        "15816880",
                        "84306342",
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "15044527"
                    ]
                ],
                "routes_to_downs": [
                    [
                        "12531756",
                        "89944066",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "84250826",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "84250826",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "86924853",
                        "23641772"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "郭錫卿",
                        "sno": "0001",
                        "stocks": 33000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "正利時投資股份有限公司",
                        "ROL_for_search": "正利時投資股份有限公司",
                        "ROL_uniID": "86924853",
                        "name": "張紹玲",
                        "sno": "0002",
                        "stocks": 220000,
                        "title": "董事"
                    },
                    {
                        "ROL": "豐年華投資股份有限公司",
                        "ROL_for_search": "豐年華投資股份有限公司",
                        "ROL_uniID": "84449165",
                        "name": "鄭雪芳",
                        "sno": "0003",
                        "stocks": 12000,
                        "title": "董事"
                    },
                    {
                        "ROL": "利明投資股份有限公司",
                        "ROL_for_search": "利明投資股份有限公司",
                        "ROL_uniID": "89944066",
                        "name": "莊來發",
                        "sno": "0004",
                        "stocks": 18740,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "23641772",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 0.16666666666667,
                        "uniID": "15816880"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "正利時投資股份有限公司",
                        "percentage": 1.1111111111111,
                        "uniID": "86924853"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "豐年華投資股份有限公司",
                        "percentage": 0.060606060606061,
                        "uniID": "84449165"
                    },
                    {
                        "edge_types": [
                            "監察人"
                        ],
                        "name": "利明投資股份有限公司",
                        "percentage": 0.094646464646465,
                        "uniID": "89944066"
                    }
                ]
            },
            {
                "capital_amount": 1010000000,
                "company_name": "禪豐投資股份有限公司",
                "company_operate": "營業中",
                "company_state": "核准設立",
                "downs": [
                    "15415013"
                ],
                "managers": [],
                "paidup_capital_amount": 1010000000,
                "public_issue": null,
                "public_shares": 101000000,
                "representative_name": "陳美貞",
                "role": "法人",
                "roles_related_to_root": [
                    "down"
                ],
                "routes_to_downs": [
                    [
                        "53653514"
                    ],
                    [
                        "12531756",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "84449165",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "86924853",
                        "23641772",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "86924853",
                        "23641772",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23528960",
                        "84250826",
                        "84449165",
                        "23641772",
                        "86924853",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "86924853",
                        "23528960",
                        "84250826",
                        "84449165",
                        "15816880",
                        "53653514"
                    ],
                    [
                        "12531756",
                        "89944066",
                        "23641772",
                        "84449165",
                        "23528960",
                        "84250826",
                        "86924853",
                        "15816880",
                        "53653514"
                    ]
                ],
                "shareholders": [
                    {
                        "ROL": "藝豐影業股份有限公司",
                        "ROL_for_search": "藝豐影業股份有限公司",
                        "ROL_uniID": "15816880",
                        "name": "陳美貞",
                        "sno": "0001",
                        "stocks": 1900000,
                        "title": "董事長"
                    },
                    {
                        "ROL": "今武投資股份有限公司",
                        "ROL_for_search": "今武投資股份有限公司",
                        "ROL_uniID": "12531756",
                        "name": "王秀瓊",
                        "sno": "0002",
                        "stocks": 49500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "今日股份有限公司",
                        "ROL_for_search": "今日股份有限公司",
                        "ROL_uniID": "15044527",
                        "name": "呂瑞梅",
                        "sno": "0003",
                        "stocks": 49500000,
                        "title": "董事"
                    },
                    {
                        "ROL": "美心西餐廳股份有限公司",
                        "ROL_for_search": "美心西餐廳股份有限公司",
                        "ROL_uniID": "11458601",
                        "name": "劉學堯",
                        "sno": "0004",
                        "stocks": 100000,
                        "title": "監察人"
                    }
                ],
                "stock_type": "股份有限公司",
                "uniID": "53653514",
                "ups": [
                    {
                        "edge_types": [
                            "董事長"
                        ],
                        "name": "藝豐影業股份有限公司",
                        "percentage": 1.8811881188119,
                        "uniID": "15816880"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "今武投資股份有限公司",
                        "percentage": 49.009900990099,
                        "uniID": "12531756"
                    },
                    {
                        "edge_types": [
                            "董事"
                        ],
                        "name": "今日股份有限公司",
                        "percentage": 49.009900990099,
                        "uniID": "15044527"
                    }
                ]
            }
        ]
    },
    "success": true
};
export default to_old_format(response5);
