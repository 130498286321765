export default {
    "data": [
        [
            {
                "value": 55,
                "id": "進口_2016",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 48.04374999999999,
                "y": 322.5925925925926
            },
            {
                "value": 80,
                "id": "進口_2017",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 1,
                "xLabel": "2017",
                "x": 144.13125,
                "y": 287.40740740740745
            },
            {
                "value": 50,
                "id": "進口_2018",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 2,
                "xLabel": "2018",
                "x": 240.21875,
                "y": 329.6296296296296
            },
            {
                "value": 110,
                "id": "進口_2019",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 3,
                "xLabel": "2019",
                "x": 336.30625000000003,
                "y": 245.18518518518516
            },
            {
                "value": 150,
                "id": "進口_2020",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 4,
                "xLabel": "2020",
                "x": 432.39375,
                "y": 188.88888888888889
            }
        ],
        [
            {
                "value": 35,
                "id": "出口_2016",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 48.04374999999999,
                "y": 350.74074074074076
            },
            {
                "value": 40,
                "id": "出口_2017",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 1,
                "xLabel": "2017",
                "x": 144.13125,
                "y": 343.7037037037037
            },
            {
                "value": 15,
                "id": "出口_2018",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 2,
                "xLabel": "2018",
                "x": 240.21875,
                "y": 378.88888888888886
            },
            {
                "value": 65,
                "id": "出口_2019",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 3,
                "xLabel": "2019",
                "x": 336.30625000000003,
                "y": 308.51851851851853
            },
            {
                "value": 120,
                "id": "出口_2020",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 4,
                "xLabel": "2020",
                "x": 432.39375,
                "y": 231.1111111111111
            }
        ]
    ],
    "itemLabels": [
        "進口",
        "出口"
    ],
    "axisWidth": 480.4375,
    "zeroY": 400
};
