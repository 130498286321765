import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import ChartDateHistogram from './ChartDateHistogram';
import TooltipAside from '../tooltip/TooltipAside';
// import LegendBottom from '../legend/LegendBottom'
// import { dateDiff, addDays, dateToStr, parseLocalDate } from '@bpchart/utils'
var LineType;
(function (LineType) {
    LineType["line"] = "line";
    LineType["area"] = "area";
    LineType["gradientArea"] = "gradientArea"; // 漸層背景
})(LineType || (LineType = {}));
const defaultLineCurve = 'curveMonotoneX';
export default class ChartDateHistogramLine extends ChartDateHistogram {
    params = {
        xLabel: 'x',
        yLabel: 'y',
        colors: DEFAULT_COLORS,
        backgroundColor: '#fafafa',
        timeFormat: '%Y-%m-%d',
        tickWidth: 80,
        groupInfo: [],
        zoom: true,
        padding: {
            top: 40,
            right: 40,
            bottom: 50,
            left: 50
        },
        lineType: LineType.gradientArea,
        lineCurve: defaultLineCurve,
        tooltipHtml: (data) => {
            return `
        <div></div>
      `;
        }
    };
    lineSelection = undefined;
    areaSelection = undefined;
    // 點選擇器上層群組
    dotMainSelection = undefined;
    // 點選擇器群組（依資料分群）
    dotGroupSelection = undefined;
    // private dotsSelection: (d3.Selection<SVGCircleElement, RenderDatum, SVGGElement, any> | undefined) = undefined
    dateLabelGroupSelection = undefined;
    dateLabelsSelection = undefined;
    // 垂直苗準線選擇器上層群組
    yLineMainSelection = undefined;
    // 垂直苗準線選擇器
    yLineSelection = undefined;
    tooltip = undefined;
    activedGroupId = '';
    // 紀住托曳資訊
    dragstartX = -1;
    constructor(selection, params) {
        super(selection, params);
        this.params = {
            ...this.params,
            ...params
        };
        const D = d3;
        if (this.params.lineCurve && D[this.params.lineCurve] !== undefined) {
            this.params.lineCurve = this.params.lineCurve;
        }
        if (this.params.tooltipHtml != null) {
            this.params.tooltipHtml = this.params.tooltipHtml;
        }
        // 設定漸層底色
        if (this.params.lineType === LineType.gradientArea) {
            for (const i in this.params.groupInfo) {
                const color = this.params.colors[Number(i)];
                selection
                    .append('defs')
                    .html(`
              <linearGradient id="gradient__datescalelines-${this.params.groupInfo[Number(i)].id}"
                      x1="0%" y1="0%"
                      x2="0%" y2="100%"
                      spreadMethod="pad">
                <stop offset="0%"   stop-color="${color}" stop-opacity="1"/>
                <stop offset="100%" stop-color="${color}" stop-opacity="0"/>
              </linearGradient>
          `);
            }
        }
        // tooltip
        if (this.params.tooltipHtml !== undefined) {
            this.tooltip = new TooltipAside(this.selection, {
                type: 'white',
                yLine: false,
                alignCenter: false,
                templateHtml: this.params.tooltipHtml
            });
        }
        this.yLineMainSelection = this.coverSelection
            .append('g')
            .classed('bpchart__yline-main', true);
        this.dotMainSelection = this.coverSelection
            .append('g')
            .classed('bpchart__dot-main', true);
    }
    setRangeEvent(xRangeScale) {
        this.selection
            .on('mouseover', (d) => {
            const rangeData = this.createRangeEventData(xRangeScale, d3.event);
            this.tooltip.setDatum({
                data: rangeData,
                // x: rangeData.clientX,
                x: rangeData.clientX - rangeData.offsetX + this.params.padding.left + this.xScale(rangeData.Date),
                y: rangeData.clientY
            });
            this.mouseoverCallback(rangeData);
            this.activeDot(rangeData.dateIndex);
            this.activeDateLabel({
                date: rangeData.date,
                event: d3.event,
            });
            this.activeYLine(rangeData.dateIndex);
        })
            .on('mousedown', (d) => {
            this.dragstartX = d3.event.clientX;
        })
            .on('mousemove', (d) => {
            const rangeData = this.createRangeEventData(xRangeScale, d3.event);
            this.tooltip.setDatum({
                data: rangeData,
                // x: rangeData.clientX,
                x: rangeData.clientX - rangeData.offsetX + this.params.padding.left + this.xScale(rangeData.Date),
                y: rangeData.clientY
            });
            this.mousemoveCallback(rangeData);
            this.activeDot(rangeData.dateIndex);
            this.activeDateLabel({
                date: rangeData.date,
                event: d3.event,
            });
            this.activeYLine(rangeData.dateIndex);
        })
            .on('mouseup', (d) => {
            // @Q@ mouse up 沒作用不知道為什麼
            // console.log('mouseup')
        })
            .on('mouseout', (d) => {
            // console.log('mouseout')
            const rangeData = this.createRangeEventData(xRangeScale, d3.event);
            if (this.tooltip) {
                this.tooltip.remove();
            }
            this.mouseoutCallback(rangeData);
            if (this.FilteredDateList.length > 1) {
                this.activeDot(-1); // 移除（只有一筆資料不移除繼續顯示圓點）
            }
            this.activeDateLabel({
                date: undefined,
                event: d3.event,
            });
            this.activeYLine(undefined);
        });
        // const drag = d3.drag()
        //   .on('start', (d) => {
        //     console.log('dragstart', d3.event)
        //     d3.event.stopPropagation();
        //   })
        //   .on('drag', (d) => {
        //     console.log('drag', d3.event)
        //     d3.event.stopPropagation();
        //   })
        //   .on('end', (d) => {
        //     console.log('end', d3.event)
        //     d3.event.stopPropagation();
        //   })
        // this.selection!.call(drag)
        // this.selection!.call(drag())
        // function drag (): d3.DragBehavior<Element, unknown, unknown> {
        // return d3.drag()
        //   .on('start', (d) => {
        //     console.log('dragstart', d)
        //   })
        // }
    }
    renderGraphic({ graphicSelection, id, data, xScale, yScale }) {
        this.renderLines({ graphicSelection, id, data, xScale, yScale });
        if (this.params.lineType === LineType.area
            || this.params.lineType === LineType.gradientArea) {
            this.renderAreas({ graphicSelection, id, data, xScale, yScale });
        }
        // 如果只有一筆資料則畫圓點上去
        if (data.length <= 1) {
            this.activeDot(0);
        }
    }
    renderCover({ coverSelection, data, xScale, yScale }) {
        // 標示線
        if (data.length) {
            this.renderYLine({
                selection: this.yLineMainSelection,
                data: data[0],
                xScale
            });
        }
        // 標示點群組
        const coverUpdate = this.dotMainSelection
            .selectAll('g.bpchart__dot-group')
            .data(data);
        this.dotGroupSelection = coverUpdate
            .enter()
            .append('g')
            .classed('bpchart__dot-group', true)
            .merge(coverUpdate);
        coverUpdate.exit().remove();
        this.dotGroupSelection.each((d, i, all) => {
            this.renderDot({
                dotGroupSelection: d3.select(all[i]),
                id: this.filteredGroupInfo[i].id,
                data: data[i],
                xScale,
                yScale
            });
        });
        // 標示日期群組
        const dateLabelGroupUpdate = coverSelection
            .selectAll('g.bpchart__date-label-group')
            .data(this.chartStyleData);
        this.dateLabelGroupSelection = dateLabelGroupUpdate
            .enter()
            .append('g')
            .classed('bpchart__date-label-group', true)
            .attr('text-anchor', 'middle')
            .merge(dateLabelGroupUpdate)
            .attr("transform", d => "translate(" + d.padding.left + "," + (d.padding.top + d.axisHeight) + ")");
        dateLabelGroupUpdate.exit().remove();
        this.renderDateLabels({
            dateLabelGroupSelection: this.dateLabelGroupSelection,
            // data: this.FilteredDateList!,
            data: this.filteredRenderData[0],
            xScale
        });
    }
    onZoomWheel(filterStartDate, event) {
        // console.log({ x, y, k }, dateDiff)
        // console.log(filterStartDate)
        // return
        if (!this.filterConfig) {
            return;
        }
        // -- 移除目前畫面上 mouseover時的顯示資訊 --
        if (this.tooltip) {
            this.tooltip.remove();
        }
        if (this.FilteredDateList.length > 1) {
            this.activeDot(-1); // 移除（只有一筆資料不移除繼續顯示圓點）
        }
        this.activeDateLabel({
            date: undefined,
            event: d3.event,
        });
        this.activeYLine(undefined);
        // 如和前一次資料不同則移動
        if (filterStartDate != this.filteredRenderData[0][0].date) {
            // filter
            this.filter({
                startDate: filterStartDate,
                endDate: this.filterConfig.endDate,
                filterItems: this.filterConfig.filterItems
            });
            const eventCallback = this.createRangeEventData(undefined, undefined);
            // console.log(eventCallback)
            this.zoomCallback(eventCallback);
            // reset k
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
            this.render();
        }
    }
    // 托曳
    onZoomMouse(event) {
        if (!this.filterConfig || !this.filteredRenderData || !this.filteredRenderData[0]) {
            return;
        }
        // -- 移除目前畫面上 mouseover時的顯示資訊 --
        if (this.tooltip) {
            this.tooltip.remove();
        }
        if (this.FilteredDateList.length > 1) {
            this.activeDot(-1); // 移除（只有一筆資料不移除繼續顯示圓點）
        }
        this.activeDateLabel({
            date: undefined,
            event: d3.event,
        });
        this.activeYLine(undefined);
        // -- 托曳 --
        const dragDistance = event.sourceEvent.clientX - this.dragstartX;
        // console.log('dragDistance', dragDistance)
        if (dragDistance == 0) {
            return;
        }
        const filteredStartData = this.filteredRenderData[0][0];
        const filteredEndData = this.filteredRenderData[0][this.filteredRenderData[0].length - 1];
        let startIndex = 0;
        let endIndex = 0;
        // 往右
        if (dragDistance > 0) {
            endIndex = this.filterStartIndex + this.xRangeScale(filteredEndData._x - dragDistance);
            startIndex = endIndex - (this.filteredRenderData[0].length - 1); // 範圍內資料筆數不變
            if (startIndex < 0) {
                startIndex = 0;
                endIndex = (this.filteredRenderData[0].length - 1);
            }
        }
        // 往左
        else if (dragDistance < 0) {
            startIndex = this.filterStartIndex + this.xRangeScale(filteredStartData._x - dragDistance);
            endIndex = startIndex + (this.filteredRenderData[0].length - 1);
            if (endIndex > (this.renderData[0].length - 1)) {
                endIndex = (this.renderData[0].length - 1);
                startIndex = endIndex - (this.filteredRenderData[0].length - 1);
            }
        }
        // console.log(startIndex, endIndex)
        // 如和前一次資料不同則移動
        if (this.renderData[0][startIndex].date != this.filteredRenderData[0][0].date) {
            this.dragstartX = event.sourceEvent.clientX; // 下一次的起始位置
            // filter
            this.filter({
                startDate: this.renderData[0][startIndex].date,
                endDate: this.renderData[0][endIndex].date,
                filterItems: this.filterConfig.filterItems
            });
            const eventCallback = this.createRangeEventData(undefined, undefined);
            // console.log(eventCallback)
            this.zoomCallback(eventCallback);
        }
    }
    // 標示點
    renderDot({ dotGroupSelection, id, data, xScale, yScale }) {
        const dotUpdate = dotGroupSelection
            .selectAll(`circle.bpchart__dot`)
            .data(data);
        const dotEnter = dotUpdate.enter()
            .append('circle')
            .classed(`bpchart__dot`, true)
            .attr('r', 4)
            .attr('opacity', 0)
            .attr('stroke-width', d => {
            if (d.incomplete !== undefined && d.incomplete === true) {
                return 2;
            }
            else {
                return 1;
            }
        })
            .style('cursor', 'pointer')
            .on('mouseover', d => {
            this.activedGroupId = d._groupId;
        })
            .on('mousemove', d => {
            this.activedGroupId = d._groupId;
        })
            .on('mouseout', d => {
            this.activedGroupId = '';
        })
            .on('click', d => {
            const eventData = this.createRangeEventData(this.xRangeScale, d3.event);
            this.clickCallback(eventData);
            if (this.tooltip) {
                this.tooltip.remove();
            }
        });
        dotUpdate.exit().remove();
        dotEnter.merge(dotUpdate)
            .attr('cx', d => this.params.padding.left + d._x)
            .attr('cy', d => this.params.padding.top + yScale(d.value))
            .attr('fill', d => {
            if (d.incomplete !== undefined && d.incomplete === true) {
                return '#ffffff';
            }
            else {
                return this.colorScale(id);
            }
        })
            .attr('stroke', d => {
            if (d.incomplete !== undefined && d.incomplete === true) {
                return this.colorScale(id);
            }
            else {
                return '#ffffff';
            }
        });
    }
    activeDot(dateIndex) {
        this.dotGroupSelection
            .each((d, i, all) => {
            d3.select(all[i]).selectAll('circle')
                .attr('opacity', (d) => {
                if (d._dateIndex === dateIndex) {
                    return 1;
                }
                return 0;
            });
        });
    }
    // 標示日期
    renderDateLabels({ dateLabelGroupSelection, data, xScale }) {
        const dateUpdate = dateLabelGroupSelection
            .selectAll('text.bpchart__date-label')
            .data(data);
        const dateEnter = dateUpdate
            .enter()
            .append('text')
            .classed('bpchart__date-label', true)
            .style('font-family', 'sans-serif')
            .style('font-size', '12px')
            .style('cursor', 'pointer')
            .attr('fill', '#DE6868')
            .attr('opacity', 0);
        this.dateLabelsSelection = dateEnter.merge(dateUpdate)
            .attr("transform", (d, i) => {
            return `translate(${xScale(d._Date)}, 35)`;
        })
            .text(d => this.timeFormatter(d._Date))
            .on('click', d => {
            const eventData = this.createRangeEventData(this.xRangeScale, d3.event);
            this.clickCallback(eventData);
        });
        // .on('mouseover', d => {
        //   this.activeDateLabel(d)
        // })
        // .on('mouseout', d => {
        //   this.activeDateLabel(undefined)
        // })
        dateUpdate.exit().remove();
    }
    // 顯示目前的日期標籤
    activeDateLabel({ date, event }) {
        // 顯示目前的日期標籤
        this.dateLabelsSelection
            .attr('opacity', (d) => {
            if (d.date === date) {
                return 1;
            }
            return 0;
        });
        // 座標軸x座標
        const activeDateData = date ? this.FilteredDateDataMap.get(date) : undefined;
        const activeX = activeDateData && activeDateData[0] ? activeDateData[0]._x : -1;
        this.xAxisSelection
            .selectAll('g.tick')
            .attr('opacity', (d) => {
            if (date === undefined) {
                return 1;
            }
            // 前後範圍內的標籤隱藏
            const x = this.xScale(d) || 0;
            if (Math.abs((x - activeX)) < 50) {
                return 0;
            }
            return 1;
        });
    }
    // private renderYLine (offsetX: number | undefined) {
    //   let data: number[] = []
    //   if (offsetX !== undefined) {
    //     if (offsetX < this.params.padding.left) {
    //       offsetX = this.params.padding.left
    //     }
    //     if (offsetX > this.axisWidth + this.params.padding.left) {
    //       offsetX = this.axisWidth + this.params.padding.left
    //     }
    //     data = [offsetX]
    //   }
    //   const update = this.selection
    //     .selectAll('.bpchart__yline')
    //     .data(data)
    //   this.yLineSelection = update
    //     .enter()
    //     .append('line')
    //     .classed('bpchart__yline', true)
    //     // .style('stroke', '#E4E7ED')
    //     .style('stroke', '#606060')
    //     .style('stroke-width', 1)
    //     .style('pointer-events', 'none')
    //     .merge(update as any)
    //     .attr('x1', d => d)
    //     .attr('y1', this.params.padding.top)
    //     .attr('x2', d => d)
    //     .attr('y2', this.params.padding.top + this.axisHeight)
    //   update.exit().remove()
    // }
    renderYLine({ selection, data, xScale }) {
        const update = selection
            .selectAll('.bpchart__yline')
            .data(data);
        this.yLineSelection = update
            .enter()
            .append('line')
            .classed('bpchart__yline', true)
            // .style('stroke', '#E4E7ED')
            .style('stroke', '#606060')
            .style('stroke-width', 1)
            .style('pointer-events', 'none')
            .attr('opacity', 0)
            .merge(update)
            .attr('x1', d => this.params.padding.left + d._x)
            .attr('y1', this.params.padding.top)
            .attr('x2', d => this.params.padding.left + d._x)
            .attr('y2', this.params.padding.top + this.axisHeight);
        update.exit().remove();
    }
    activeYLine(dateIndex) {
        this.yLineSelection
            .attr('opacity', (d) => {
            if (d._dateIndex === dateIndex) {
                return 1;
            }
            return 0;
        });
    }
    renderLines({ graphicSelection, id, data, xScale, yScale }) {
        const D = d3;
        const linePath = d3.line()
            .x((d) => {
            return d._x;
        })
            .y((d) => {
            const y = yScale(d.value);
            return y;
        })
            .curve(D[this.params.lineCurve]);
        const update = graphicSelection
            .selectAll(`path.bpchart__datescalelines-path`)
            .data([data]);
        const enter = update.enter()
            .append('path')
            .classed(`bpchart__datescalelines-path`, true)
            .attr("transform", "translate(" + this.params.padding.left + "," + this.params.padding.top + ")")
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .style('pointer-events', 'none');
        update.exit();
        this.lineSelection = update.merge(enter)
            .attr("stroke", this.colorScale(id))
            .attr("d", (d) => {
            return linePath(d);
        });
        // this.lineSelection
        //   .attr("d", (d) => {
        //     return linePath(d)
        //   })
    }
    renderAreas({ graphicSelection, id, data, xScale, yScale }) {
        const D = d3;
        const lineArea = d3.area()
            .x((d) => {
            return d._x;
        })
            .y0(this.axisHeight)
            .y1((d) => {
            const y = yScale(d.value);
            return y;
        })
            .curve(D[this.params.lineCurve]);
        const update = graphicSelection
            .selectAll(`path.bpchart__datescalelines-area`)
            .data([data]);
        const enter = update.enter()
            .append('path')
            .classed(`bpchart__datescalelines-area`, true)
            .attr("transform", "translate(" + this.params.padding.left + "," + this.params.padding.top + ")")
            .style('pointer-events', 'none');
        this.areaSelection = update.merge(enter);
        if (this.params.lineType === LineType.area) {
            this.areaSelection
                .attr("fill", this.colorScale(id))
                .attr("opacity", 0.5);
        }
        else if (this.params.lineType === LineType.gradientArea) {
            this.areaSelection
                .style('fill', `url(#gradient__datescalelines-${id})`)
                .attr("opacity", 0.5);
        }
        update.exit();
        this.areaSelection
            .attr("d", (d) => {
            return lineArea(d);
        });
    }
    // 取得滑鼠hover事件的資料
    createRangeEventData(xRangeScale, event) {
        if (!event || !xRangeScale) {
            return {
                data: this.filteredRenderData,
                dateData: undefined,
                selectedData: undefined,
                date: undefined,
                Date: undefined,
                groupId: '',
                dateIndex: -1,
                clientX: -1,
                clientY: -1,
                offsetX: -1,
                offsetY: -1
            };
        }
        // 座標軸x座標
        const offsetX = Number(event.offsetX) - this.params.padding.left;
        // 資料索引
        const dateIndex = Number(xRangeScale(offsetX) ?? 0);
        const dateData = this.FilteredDateDataMap.get(this.filteredRenderData[0][dateIndex].date);
        let selectedData = undefined;
        if (this.activedGroupId) {
            // selectedData = dateData[this.activedDataIndex]
            selectedData = dateData.find(d => d._groupId === this.activedGroupId);
        }
        // console.log({
        //   data: this.filteredRenderData!,
        //   dateData,
        //   selectedData,
        //   date: this.filteredRenderData![0][dateIndex].date,
        //   Date: this.filteredRenderData![0][dateIndex]._Date,
        //   groupId: this.activedGroupId,
        //   dateIndex,
        //   clientX: d3.event.clientX,
        //   clientY: d3.event.clientY,
        //   offsetX: d3.event.offsetX,
        //   offsetY: d3.event.offsetY
        // })
        return {
            data: this.filteredRenderData,
            dateData,
            // dateFilteredData,
            selectedData,
            date: this.filteredRenderData[0][dateIndex].date,
            Date: this.filteredRenderData[0][dateIndex]._Date,
            groupId: this.activedGroupId,
            dateIndex,
            clientX: d3.event.clientX,
            clientY: d3.event.clientY,
            offsetX: d3.event.offsetX,
            offsetY: d3.event.offsetY
        };
    }
}
