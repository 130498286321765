import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_GRAPHIC_ARC_PIE_PARAMS } from '../graphicArcPie';
import { DEFAULT_LABEL_ARC_PIE_PARAMS } from '../labelArcPie';
export const DEFAULT_CHART_ARC_PARAMS = {
    padding: DEFAULT_PADDING,
    tooltipFollowing: {
        templateHtml: (data) => {
            return `
      <div>
        <div>${data.datum.data.arcLabel}</div>
        <div>${data.datum.value}</div>
      </div>
    `;
        },
    },
};
export const DEFAULT_CHART_ARC_PIE_PARAMS = {
    ...DEFAULT_CHART_ARC_PARAMS,
    graphicArcPie: DEFAULT_GRAPHIC_ARC_PIE_PARAMS,
    labelArcPie: DEFAULT_LABEL_ARC_PIE_PARAMS,
    startAngle: 0,
    endAngle: Math.PI * 2,
    colors: DEFAULT_COLORS,
    enterDuration: 800,
    highlightTarget: 'datum',
    highlightDatumId: '',
    highlightItemId: '',
    centerText: (d, eventName, t) => [],
    centerTextAttrs: [],
    centerTextStyles: []
};
