import * as d3 from 'd3';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { NODE_TEXT_SIZE, NODE_TEXT_LEFT, NODE_TEXT_TOP, NODE_HEIGHT_MIN, NODE_RADIUS_DEFAULT, NODE_RADIUS_ROUND, NODE_TOP, NODE_WIDTH_DEFAULT, NODE_TAG_SIZE, NODE_TAG_TOP_DEFAULT, NODE_TAG_TEXT, NODE_TAG_STEP, NODE_HIGHLIGHT_COLOR } from './const';
import ButtonExpandIcon from '../button/ButtonExpandIcon';
// export type NodeUpdateConfig = {
//   highlightIds: string[];
//   highlightStartId: string;
// }
export default class Node {
    selection;
    params = {
        nodeTypeConfig: {},
        styleConfig: {},
        nodeTagConfig: {}
    };
    data = [];
    renderData = [];
    nodesG;
    nodesRect;
    nodesText;
    nodesIconsG;
    tooltip = undefined;
    StyleConfig = new Map(); // style對應表
    TypeStyleMap = new Map(); // type對應style
    TagInfoMap = new Map(); // tag對應tagInfo
    mouseoverCallback;
    mousemoveCallback;
    mouseoutCallback;
    clickCallback;
    toggleCallback;
    // private ButtonExpandIcon: ButtonExpandIcon
    constructor(el, params) {
        this.selection = el;
        this.params = params;
        if (this.params.styleConfig) {
            Object.keys(this.params.styleConfig).forEach(key => {
                this.StyleConfig.set(key, this.params.styleConfig[key]);
            });
        }
        if (this.params.nodeTypeConfig) {
            Object.keys(this.params.nodeTypeConfig).forEach(type => {
                const nodeRectStyleKey = this.params.nodeTypeConfig[type].rect;
                const nodeTextStyleKey = this.params.nodeTypeConfig[type].text;
                this.TypeStyleMap.set(type, {
                    rect: this.StyleConfig.get(nodeRectStyleKey) || '',
                    text: this.StyleConfig.get(nodeTextStyleKey) || ''
                });
            });
        }
        if (this.params.nodeTagConfig) {
            Object.keys(this.params.nodeTagConfig).forEach(key => {
                this.TagInfoMap.set(key, this.params.nodeTagConfig[key]);
            });
        }
        this.tooltip = new TooltipFollowing(this.selection, {
            offsetX: 20,
            offsetY: 10,
            templateHtml: (data) => `
        <div>${data}</div>
      `,
            insideBoxMode: true,
            type: 'black'
        });
    }
    setData(data) {
        this.renderData = data.map((d) => {
            // let _d: NodeRenderData = d
            // _d._style = this.TypeStyleMap.get(_d.nodeType) || { rect: '', text: '' }
            // _d._tags = _d.tags.map(tag => this.TagInfoMap.get(tag) || {
            //   label: '',
            //   backgroundColor: '',
            //   textColor: '',
            //   tooltip: ''
            // })
            // // rect的相對Y座標
            // _d._rectTop = d.labels.length === 1 ? NODE_TOP : - d.height / 2
            // // text的相對Y座標
            // _d._rectTextTop = NODE_TEXT_TOP - (d.labels.length - 1) * NODE_TEXT_SIZE / 2
            // // tag的相對Y座標
            // _d._tagTop = NODE_TAG_TOP_DEFAULT + (d.labels.length - 1) * NODE_TEXT_SIZE / 2
            return {
                index: d.index,
                id: d.id,
                uniID: d.uniID,
                label: d.label,
                labels: d.labels,
                nodeType: d.nodeType,
                x: d.x,
                y: d.y,
                width: d.width,
                height: d.height,
                tags: d.tags,
                expandable: d.expandable,
                isExpanded: d.isExpanded,
                sourceData: d.sourceData,
                _style: this.TypeStyleMap.get(d.nodeType) || { rect: '', text: '' },
                _tags: d.tags.map((tag) => this.TagInfoMap.get(tag) || {
                    label: '',
                    backgroundColor: '',
                    textColor: '',
                    tooltip: ''
                }),
                // rect的相對Y座標
                _rectTop: d.labels.length === 1 ? NODE_TOP : -d.height / 2,
                // text的相對Y座標
                _rectTextTop: NODE_TEXT_TOP - (d.labels.length - 1) * NODE_TEXT_SIZE / 2,
                // tag的相對Y座標
                _tagTop: NODE_TAG_TOP_DEFAULT + (d.labels.length - 1) * NODE_TEXT_SIZE / 2
            };
        });
    }
    render() {
        // -- g --
        const update = this.selection
            .selectAll('g.nodes__NODE_g')
            // @ts-ignore
            .data(this.renderData, d => d.id);
        const enter = update.enter()
            .append('g')
            .classed('nodes__NODE_g', true)
            .style('cursor', () => {
            return this.clickCallback
                // || this.mousemoveCallback
                // || this.mouseoutCallback
                // || this.mouseoverCallback
                ? 'pointer' : 'default';
        });
        // 新增節點方塊
        this.appendGContent(enter.append('g'));
        // 新增展開按鈕
        if (this.params.expand) {
            this.appendButtonExpandIcon(enter);
        }
        enter
            .attr('transform', (d) => `translate(${d.x}, ${0})`)
            .transition()
            .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
        update
            .transition()
            .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
        this.nodesG = update.merge(enter);
        // .transition()
        // .attr('transform', (d: NodeRenderData) => `translate(${d.x}, ${d.y})`)
        // this.appendGContent(this.nodesG!)
        update.exit().remove();
    }
    remove() {
        this.selection.selectAll('*').remove();
    }
    select() {
        return this.selection;
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        else if (actionName === 'toggle') {
            this.toggleCallback = callback;
        }
        return this;
    }
    setParams(params) {
        if (params.highlightIds?.length && params.highlightStartId) {
            this.highlight(params.highlightIds, params.highlightStartId);
        }
        else {
            this.removeHighlight();
        }
    }
    appendGContent(enterG) {
        // rect (enter)
        enterG.append('rect')
            .classed('nodes-rect', true)
            .attr('rx', d => NODE_RADIUS_DEFAULT)
            .attr('ry', d => NODE_RADIUS_DEFAULT)
            .attr('y', d => d._rectTop)
            .attr('width', d => d.width || NODE_WIDTH_DEFAULT)
            .attr('height', d => d.height)
            // .attr('style', d => this.params.styleConfig[d._style.rect])
            .attr('style', d => d._style.rect);
        // tag (enter)
        enterG.each((datum, index, groups) => {
            const g = d3.select(groups[index]);
            const tagG = g
                .selectAll('g')
                .data(datum.tags)
                .enter()
                .append('g')
                .attr('transform', (d, i) => `translate(${NODE_TEXT_LEFT + NODE_TAG_STEP * i}, ${datum._tagTop})`);
            tagG
                .append('rect')
                .attr('width', NODE_TAG_SIZE)
                .attr('height', NODE_TAG_SIZE)
                .attr('rx', 4)
                .attr('ry', 4)
                .attr('fill', (d) => this.params.nodeTagConfig[d] && this.params.nodeTagConfig[d].backgroundColor);
            tagG
                .append('text')
                .text((d) => this.params.nodeTagConfig[d] && this.params.nodeTagConfig[d].label)
                .attr('font-size', NODE_TAG_TEXT)
                .attr('fill', (d) => this.params.nodeTagConfig[d] && this.params.nodeTagConfig[d].textColor)
                .attr('x', NODE_TAG_SIZE / 2)
                .attr('y', NODE_TAG_SIZE / 2 + 1)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle');
            this.handleTagHover(tagG);
        });
        // text (enter)
        const textEnter = enterG.append('text')
            .text(d => '')
            .attr('transform', d => `translate(${NODE_TEXT_LEFT}, ${d._rectTextTop})`)
            .attr('font-size', NODE_TEXT_SIZE)
            // .attr('style', d => this.params.styleConfig[d._style.text])
            .attr('style', d => d._style.text);
        // tspan
        textEnter.each((datum, index, groups) => {
            const t = d3.select(groups[index])
                .selectAll('tspan')
                .data(datum.labels);
            t
                .enter()
                .append('tspan')
                .attr('x', 0)
                .attr('y', (d, i) => i * NODE_TEXT_SIZE)
                .text(d => d);
        });
        if (this.clickCallback) {
            enterG
                .on('click', d => {
                this.clickCallback({
                    data: d,
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            });
        }
        if (this.mouseoverCallback) {
            enterG
                .on('mouseover', d => {
                this.mouseoverCallback({
                    data: d,
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            });
        }
        if (this.mousemoveCallback) {
            enterG
                .on('mousemove', d => {
                this.mousemoveCallback({
                    data: d,
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            });
        }
        if (this.mouseoutCallback) {
            enterG
                .on('mouseout', d => {
                this.mouseoutCallback({
                    data: d,
                    x: d3.event.clientX,
                    y: d3.event.clientY
                });
            });
        }
    }
    appendButtonExpandIcon(enter) {
        // 展開按鈕
        enter.each((d, i, g) => {
            const nodeG = d3.select(g[i]);
            if (d.expandable) {
                const buttonExpandIcon = new ButtonExpandIcon(nodeG, {
                    expanded: d.isExpanded,
                    x: d.width / 2,
                    y: -d.height / 2
                });
                buttonExpandIcon.render();
                buttonExpandIcon
                    .on('click', buttonData => {
                    this.toggleCallback({
                        data: d,
                        x: d3.event.clientX,
                        y: d3.event.clientY,
                        isExpanded: buttonData.expanded
                    });
                });
            }
        });
    }
    highlight(ids, startId) {
        if (!this.nodesG) {
            return;
        }
        const nodesRect = this.nodesG
            .selectAll('rect.nodes-rect');
        nodesRect
            .filter((d) => ids.includes(d.id))
            .style('stroke', NODE_HIGHLIGHT_COLOR);
        nodesRect
            .filter((d) => d.id === startId)
            .style('stroke-width', '3px');
    }
    removeHighlight() {
        if (!this.nodesG) {
            return;
        }
        this.nodesG
            .selectAll('rect.nodes-rect')
            .attr('style', (d) => d._style.rect);
    }
    handleTagHover(tagG) {
        tagG
            .style('cursor', 'pointer')
            .on('mouseover', d => {
            const x = d3.event.clientX;
            const y = d3.event.clientY;
            this.tooltip.setDatum({
                data: this.params.nodeTagConfig[d] && this.params.nodeTagConfig[d].tooltip,
                x,
                y
            });
        })
            .on('mousemove', d => {
            const x = d3.event.clientX;
            const y = d3.event.clientY;
            this.tooltip.setDatum({
                x,
                y
            });
        })
            .on('mouseout', d => {
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
        });
    }
}
