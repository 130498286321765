import * as d3 from 'd3';
import ChartRow from './ChartRowDiverging';
import GraphicRowBarDiverging from '../graphicRowBarDiverging/GraphicRowBarDiverging';
import { DEFAULT_CHART_ROW_BAR_DIVERGING_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartRowBarPNGroup extends ChartRow {
    params = DEFAULT_CHART_ROW_BAR_DIVERGING_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicRowBarDiverging(this.graphicSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicRowBarDiverging: {
                ...this.params.graphicRowBarDiverging,
                ...params.graphicRowBarDiverging
            }
        };
        this.bar.setParams({
            ...this.params.graphicRowBarDiverging,
            // barType: this.params.barType,
            // barWidth: this.params.barWidth,
            // barPadding: this.params.barPadding,
            // barR: this.params.barR,
            colors: this.params.colors,
            highlightTarget: this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId,
            highlightGroupId: this.params.highlightGroupId,
        });
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    makeGraphicRenderData({ dataset, zeroX1, zeroX2, x1Scale, x2Scale, y1Scale, y2Scale }) {
        console.log(zeroX1, zeroX2);
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const yLabel = dataset.yLabels[_i];
                const y = i == 0
                    ? y1Scale(yLabel) || 0 // 這裡的 y 是對應 y座標軸的位置，群組內的位置在圖形裡面再做計算
                    : y2Scale(yLabel) || 0;
                return {
                    ..._d,
                    id: `${itemLabel}_${yLabel}`,
                    itemIndex: i,
                    itemLabel,
                    yIndex: _i,
                    yLabel,
                    x: i == 0
                        ? x1Scale(_d.value) || 0
                        : zeroX2 + x2Scale(_d.value) || 0,
                    y
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeYDataMap({ renderData, yLabels }) {
        let YDataMap = new Map();
        if (!renderData.length) {
            return YDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const yData = YDataMap.get(yLabels[_i]) ?? [];
                yData.push(_d);
                YDataMap.set(yLabels[_i], yData);
            });
        });
        return YDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        itemLabels.forEach((d, i) => {
            ItemDataMap.set(d, renderData[i]);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, yLabels, zeroX1, zeroX2, x1Scale, x2Scale, y1Scale, y2Scale }) {
        this.bar.setDataset({
            data: renderData,
            yLabels,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisHeight: this.axisHeight,
            // zeroX1: x1Scale(0)!,
            // zeroX2: x2Scale(0)!
            zeroX1,
            zeroX2
            // zeroX1: 0,
            // zeroX2: 0
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCoverHighlight({ eventData, x1Scale, x2Scale, y1Scale, y2Scale, padding }) {
        // // 非顯示全部圓點時 highlight圓點
        // if (!this.params.dot && this.params.highlightTarget !== 'none') {
        //   const dotData: GraphicRenderDatum[] = eventData[0]
        //     ? eventData[0].groupData.map(d => {
        //       return {
        //         ...d,
        //         id: d.itemLabel, // itemLabel相同則用update的
        //         x: xScale(d.Date)!,
        //         y: yScale(d.value)!
        //       }
        //     })
        //     : []
        //   this.dot!.setDataset({
        //     data: dotData,
        //     itemLabels: this.dataset.itemLabels
        //   })
        //   this.dot!.render()
        // }
    }
}
