import { DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_ROW_BAR_STACK_PARAMS = {
    // barType: 'rect',
    barWidth: 0,
    // barPadding: 1,
    barGroupPadding: 1,
    barR: false,
    colors: DEFAULT_COLORS,
    //   axisWidth: 0,
    //   axisHeight: 0,
    highlightTarget: 'item',
    highlightGroupId: '',
    highlightItemId: '',
    highlightDatumId: '',
    enterDuration: 800
};
