import * as d3 from 'd3';
import { formatCommaNumber, formatPercentage } from '../utils';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '../defaults';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { DEFAULT_CHART_ARC_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
const getShorterSideWidth = (width, height, padding) => {
    const axisWidth = width - padding.left - padding.right;
    const axisHeight = height - padding.top - padding.bottom;
    return axisWidth < axisHeight ? axisWidth : axisHeight;
};
const makeGraphicData = (data, itemLabels, arcLabels) => {
    return data.map((d, i) => {
        const itemLabel = d.itemLabel ?? defaultItemLabel; // 如資料裡無itemLabel則給一個預設值
        return {
            ...d,
            arcIndex: i,
            arcLabel: arcLabels[i],
            itemIndex: itemLabels.indexOf(itemLabel),
            itemLabel,
            id: arcLabels[i],
        };
    });
};
export default class GraphicArcPie {
    params = DEFAULT_CHART_ARC_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        arcLabels: []
    };
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    graphicGSelection;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    graphicData = [];
    itemLabels = [];
    arcLabels = [];
    shorterSideWidth = 0;
    middleX = 0;
    middleY = 0;
    tooltip;
    constructor(selection, params) {
        this.selection = selection;
        this.graphicGSelection = selection.append('g');
        // // 中間資訊svg（圖層較高）
        // this.middleSelection = selection.append('g')
    }
    setParams(params) {
        // this.params = {
        //   ...this.params,
        //   ...params
        // }
        this.initGraphic(params);
        this.shorterSideWidth = getShorterSideWidth(this.width, this.height, this.params.padding);
        if (this.params.tooltipFollowing) {
            this.tooltip = new TooltipFollowing(this.selection, {
                templateHtml: this.params.tooltipFollowing.templateHtml,
                type: this.params.tooltipFollowing.type ?? 'white',
                insideBoxMode: this.params.tooltipFollowing.insideBoxMode ?? true,
            });
        }
        else {
            if (this.tooltip) {
                this.tooltip.remove();
                this.tooltip = undefined;
            }
        }
    }
    resize({ width, height } = { width: this.width, height: this.height }) {
        this.width = width;
        this.height = height;
        this.shorterSideWidth = getShorterSideWidth(this.width, this.height, this.params.padding);
        const middleX = this.width / 2; // 元素起始座標為置中
        const middleY = this.height / 2;
        if (middleX !== this.middleX || middleY !== this.middleY) {
            this.middleX = middleX;
            this.middleY = middleY;
            this.graphicGSelection
                .transition()
                .attr('transform', `translate(${this.middleX}, ${this.middleY})`);
        }
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = this.dataset.itemLabels && this.dataset.itemLabels.length
            ? this.dataset.itemLabels
            : [defaultItemLabel];
        this.arcLabels = this.dataset.arcLabels;
        this.graphicData = makeGraphicData(this.dataset.data, this.itemLabels, this.arcLabels);
    }
    render() {
        this.setGraphicData({
            graphicData: this.graphicData,
            itemLabels: this.itemLabels,
            arcLabels: this.arcLabels,
            shorterSideWidth: this.shorterSideWidth
        });
        this.renderGraphic();
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    showTooltip(eventData, event) {
        if (!this.tooltip) {
            return;
        }
        if (event && eventData) {
            this.tooltip.setDatum({
                data: eventData,
                x: d3.event.clientX,
                // x: eventData.clientX - eventData.offsetX + this.params.padding!.left,
                y: d3.event.clientY
            });
        }
        else {
            this.tooltip.remove();
        }
    }
}
