import * as d3 from 'd3';
import ChartDirectedDagre from './ChartDirectedDagre';
export default class ChartDirectedDagreDbs extends ChartDirectedDagre {
    constructor(el, params) {
        super(el, params);
    }
    renderNode(nodesG, NodesMap) {
        nodesG.each((d, i, all) => {
            let g = d3.select(all[i]);
            const node = NodesMap.get(d);
            // console.log('node', node)
            // 取得 node寬度
            let halfRectWidth = 100;
            try {
                halfRectWidth = Number(g.select('rect').attr('width')) / 2;
            }
            catch (e) {
                console.error(e);
            }
            const infoUpdate = g.selectAll('g.dbs__nodeinfo')
                .data([{
                    uniID: d,
                    date_approved: node?.data.date_approved ?? '',
                    public_issue: node?.data.public_issue ?? '',
                    stock_code_from_publics: node?.data.stock_code_from_publics ?? '',
                }]);
            const infoEnter = infoUpdate.enter()
                .append('g')
                .classed('dbs__nodeinfo', true);
            infoUpdate.exit().remove();
            const info = infoUpdate.merge(infoEnter);
            const topText = infoEnter.append('text')
                .classed('dbs__nodeinfo__toptext', true)
                .attr('font-size', '14px');
            topText.append('tspan')
                .classed('dbs__nodeinfo__uniID', true)
                .attr('fill', '#303133');
            topText.append('tspan')
                .classed('dbs__nodeinfo__publicIssue', true)
                .attr('fill', '#67c23a');
            infoEnter.append('text')
                .classed('dbs__nodeinfo__dateApproved', true)
                .attr('font-size', '14px')
                .attr('fill', '#303133')
                .attr('dominant-baseline', 'hanging');
            info.select('text.dbs__nodeinfo__toptext')
                .attr('transform', `translate(${-halfRectWidth}, -24)`);
            info.select('tspan.dbs__nodeinfo__uniID')
                .text(d => {
                if (isNaN(Number(d.uniID))) {
                    return '';
                }
                return d.uniID + (d.public_issue && d.stock_code_from_publics ? ' ． ' : '');
            });
            info.select('tspan.dbs__nodeinfo__publicIssue')
                .text(d => d.public_issue && d.stock_code_from_publics ? `${d.public_issue}(${d.stock_code_from_publics})` : '');
            info.select('text.dbs__nodeinfo__dateApproved')
                .text(d => d.date_approved)
                .attr('transform', `translate(${-halfRectWidth}, 24)`);
        });
    }
}
