import * as d3 from 'd3';
export default class Legend {
    _selection = undefined;
    // private _renderData: (RenderData | undefined) = undefined
    params;
    data = [];
    containerSelection = undefined;
    colorScale;
    dataTypes;
    colors;
    constructor(selection, params) {
        this._selection = selection;
        this._selection.style('position', 'relative');
        this.params = params;
        // const update = this._selection
        //   .selectAll<HTMLUListElement, any>('.bpchart___typedescription')
        //   .data(['temp'])
        // const enter = update.enter()
        //   .append('ul')
        //   .classed('bpchart___typedescription', true)
        // this.containerSelection = update.merge(enter)
        //   ?.style('position', 'absolute')
        //   .style('bottom', `20px`)
        //   .style('width', '100%')
        //   .style('display', 'flex')
        //   .style('justify-content', 'center')
        // update.exit().remove()
        if (this._selection.selectAll('ul').size()) {
            this._selection.selectAll('ul').remove();
        }
        this.containerSelection = this._selection.append('ul').style('position', 'absolute')
            .style('bottom', `20px`)
            .style('width', '100%')
            .style('display', 'flex')
            .style('justify-content', 'center');
        this.dataTypes = params.dataTypes;
        this.colors = params.colors;
        this.colorScale = d3.scaleOrdinal()
            .domain(this.dataTypes)
            .range(this.colors);
    }
    // get renderData (): (RenderData | undefined) {
    //   return this._renderData!
    // }
    get selection() {
        return this._selection;
    }
    select() {
        return this.selection;
    }
    remove() {
        this._selection.remove();
    }
    setData(data) {
        if (data == null) {
            // 如果無輸入參數則顯示所有設定項目
            this.data = this.colors.map((d, i) => {
                return {
                    dataType: this.dataTypes[i],
                    label: this.dataTypes[i]
                };
            });
        }
        else {
            this.data = data;
        }
        // this.render()
    }
    render() {
        this.renderLegend(this.containerSelection, this.data);
    }
}
