export default {
    "nodes": [
        { "id": "0", "group": "1" },
        { "id": "1", "group": "2" },
        { "id": "2", "group": "2" },
        { "id": "3", "group": "2" },
        { "id": "4", "group": "2" },
        { "id": "5", "group": "3" },
        { "id": "6", "group": "3" },
        { "id": "7", "group": "3" },
        { "id": "8", "group": "3" }
    ],
    "links": [
        { "source": "0", "target": "1", "id": "0->1" },
        { "source": "0", "target": "2", "id": "0->2" },
        { "source": "0", "target": "3", "id": "0->3" },
        { "source": "0", "target": "4", "id": "0->4" },
        { "source": "1", "target": "5", "id": "1->5" },
        { "source": "2", "target": "6", "id": "2->6" },
        { "source": "3", "target": "7", "id": "3->7" },
        { "source": "4", "target": "8", "id": "4->8" },
        { "source": "1", "target": "8", "id": "1->8" },
        { "source": "2", "target": "5", "id": "2->5" },
        { "source": "3", "target": "6", "id": "3->6" },
        { "source": "4", "target": "7", "id": "4->7" }
    ]
};
