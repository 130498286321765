// api/frontstage/dashboard-data/medical_and_epidemic_prevention-pandemic_taiwan_trends （全台趨勢(疫情發展累計)）
// let y1Data = [data.payload.record.map(d => {
//     return {
//         value: Number(d.confirmed)
//     }
// })]
// let y2Data = [data.payload.record.map(d => {
//     return {
//         value: Number(d.death)
//     }
// })]
// let xDates = data.payload.record.map(d => {
//     return d.label
// })
const y1Data = [
    [
        {
            "value": 18005
        },
        {
            "value": 18041
        },
        {
            "value": 18109
        },
        {
            "value": 18238
        },
        {
            "value": 18325
        },
        {
            "value": 18376
        },
        {
            "value": 18411
        },
        {
            "value": 18503
        },
        {
            "value": 18566
        },
        {
            "value": 18634
        },
        {
            "value": 18681
        },
        {
            "value": 18735
        },
        {
            "value": 18790
        },
        {
            "value": 18850
        },
        {
            "value": 18903
        },
        {
            "value": 18958
        },
        {
            "value": 19029
        },
        {
            "value": 19101
        },
        {
            "value": 19144
        },
        {
            "value": 19192
        },
        {
            "value": 19239
        },
        {
            "value": 19293
        },
        {
            "value": 19376
        },
        {
            "value": 19455
        },
        {
            "value": 19515
        },
        {
            "value": 19567
        },
        {
            "value": 19621
        },
        {
            "value": 19666
        },
        {
            "value": 19732
        },
        {
            "value": 19797
        },
        {
            "value": 19864
        },
        {
            "value": 19937
        },
        {
            "value": 20007
        },
        {
            "value": 20056
        },
        {
            "value": 20100
        },
        {
            "value": 20156
        },
        {
            "value": 20236
        },
        {
            "value": 20304
        },
        {
            "value": 20373
        },
        {
            "value": 20433
        },
        {
            "value": 20489
        },
        {
            "value": 20533
        },
        {
            "value": 20582
        },
        {
            "value": 20653
        },
        {
            "value": 20717
        },
        {
            "value": 20797
        },
        {
            "value": 20840
        },
        {
            "value": 20869
        },
        {
            "value": 20922
        },
        {
            "value": 20999
        },
        {
            "value": 21081
        },
        {
            "value": 21163
        },
        {
            "value": 21225
        },
        {
            "value": 21288
        },
        {
            "value": 21363
        },
        {
            "value": 21402
        },
        {
            "value": 21492
        },
        {
            "value": 21583
        },
        {
            "value": 21658
        },
        {
            "value": 21784
        },
        {
            "value": 21905
        },
        {
            "value": 22003
        },
        {
            "value": 22091
        },
        {
            "value": 22188
        },
        {
            "value": 22327
        },
        {
            "value": 22463
        },
        {
            "value": 22566
        },
        {
            "value": 22769
        },
        {
            "value": 22896
        },
        {
            "value": 22992
        },
        {
            "value": 23155
        },
        {
            "value": 23394
        },
        {
            "value": 23629
        },
        {
            "value": 24033
        },
        {
            "value": 24313
        },
        {
            "value": 24588
        },
        {
            "value": 24866
        },
        {
            "value": 25225
        },
        {
            "value": 25756
        },
        {
            "value": 26263
        },
        {
            "value": 26836
        },
        {
            "value": 27410
        },
        {
            "value": 28040
        },
        {
            "value": 28666
        },
        {
            "value": 29593
        },
        {
            "value": 30574
        },
        {
            "value": 31857
        },
        {
            "value": 33205
        },
        {
            "value": 34507
        },
        {
            "value": 35983
        },
        {
            "value": 37710
        },
        {
            "value": 40186
        },
        {
            "value": 43243
        },
        {
            "value": 47100
        },
        {
            "value": 51298
        },
        {
            "value": 56468
        },
        {
            "value": 61686
        },
        {
            "value": 68022
        },
        {
            "value": 76938
        },
        {
            "value": 88446
        },
        {
            "value": 100753
        },
        {
            "value": 115883
        },
        {
            "value": 132955
        },
        {
            "value": 132955
        },
        {
            "value": 173942
        },
        {
            "value": 202418
        },
        {
            "value": 232402
        },
        {
            "value": 268569
        },
        {
            "value": 268569
        },
        {
            "value": 268569
        },
        {
            "value": 397504
        },
        {
            "value": 448323
        },
        {
            "value": 505455
        },
        {
            "value": 570870
        },
        {
            "value": 570870
        },
        {
            "value": 699824
        },
        {
            "value": 768543
        },
        {
            "value": 830257
        },
        {
            "value": 896059
        },
        {
            "value": 981141
        },
        {
            "value": 1070561
        },
        {
            "value": 1156291
        },
        {
            "value": 1240897
        },
        {
            "value": 1320371
        },
        {
            "value": 1386640
        },
        {
            "value": 1469019
        },
        {
            "value": 1558380
        },
        {
            "value": 1640271
        },
        {
            "value": 1735067
        },
        {
            "value": 1815703
        },
        {
            "value": 1892272
        },
        {
            "value": 1952355
        },
        {
            "value": 2032983
        },
        {
            "value": 2121231
        },
        {
            "value": 2198161
        },
        {
            "value": 2274666
        },
        {
            "value": 2342794
        },
        {
            "value": 2404871
        },
        {
            "value": 2457881
        },
        {
            "value": 2540871
        },
        {
            "value": 2620941
        },
        {
            "value": 2693787
        },
        {
            "value": 2762080
        },
        {
            "value": 2841696
        },
        {
            "value": 2892263
        },
        {
            "value": 2937363
        },
        {
            "value": 3003501
        },
        {
            "value": 3072432
        },
        {
            "value": 3135565
        },
        {
            "value": 3190787
        },
        {
            "value": 3244453
        },
        {
            "value": 3295076
        },
        {
            "value": 3330695
        },
        {
            "value": 3387061
        },
        {
            "value": 3439279
        },
        {
            "value": 3487599
        },
        {
            "value": 3533335
        },
        {
            "value": 3573703
        },
        {
            "value": 3613345
        },
        {
            "value": 3641921
        },
        {
            "value": 3686338
        },
        {
            "value": 3728363
        },
        {
            "value": 3767283
        },
        {
            "value": 3803049
        },
        {
            "value": 3837856
        },
        {
            "value": 3870528
        },
        {
            "value": 3893643
        },
        {
            "value": 3929627
        },
        {
            "value": 3964188
        },
        {
            "value": 3995621
        },
        {
            "value": 4026067
        },
        {
            "value": 4054189
        },
        {
            "value": 4082028
        },
        {
            "value": 4101156
        },
        {
            "value": 4132429
        },
        {
            "value": 4162290
        },
        {
            "value": 4189929
        },
        {
            "value": 4215200
        },
        {
            "value": 4240466
        },
        {
            "value": 4264788
        }
    ]
];
const y2Data = [
    [
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 851
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 852
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 853
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 854
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 856
        },
        {
            "value": 858
        },
        {
            "value": 860
        },
        {
            "value": 862
        },
        {
            "value": 865
        },
        {
            "value": 868
        },
        {
            "value": 868
        },
        {
            "value": 876
        },
        {
            "value": 881
        },
        {
            "value": 886
        },
        {
            "value": 896
        },
        {
            "value": 896
        },
        {
            "value": 896
        },
        {
            "value": 931
        },
        {
            "value": 943
        },
        {
            "value": 951
        },
        {
            "value": 968
        },
        {
            "value": 968
        },
        {
            "value": 1049
        },
        {
            "value": 1068
        },
        {
            "value": 1097
        },
        {
            "value": 1135
        },
        {
            "value": 1176
        },
        {
            "value": 1235
        },
        {
            "value": 1284
        },
        {
            "value": 1343
        },
        {
            "value": 1396
        },
        {
            "value": 1436
        },
        {
            "value": 1478
        },
        {
            "value": 1554
        },
        {
            "value": 1658
        },
        {
            "value": 1784
        },
        {
            "value": 1947
        },
        {
            "value": 2056
        },
        {
            "value": 2165
        },
        {
            "value": 2255
        },
        {
            "value": 2377
        },
        {
            "value": 2521
        },
        {
            "value": 2663
        },
        {
            "value": 2663
        },
        {
            "value": 2939
        },
        {
            "value": 3090
        },
        {
            "value": 3214
        },
        {
            "value": 3373
        },
        {
            "value": 3584
        },
        {
            "value": 3797
        },
        {
            "value": 4008
        },
        {
            "value": 4171
        },
        {
            "value": 4280
        },
        {
            "value": 4403
        },
        {
            "value": 4546
        },
        {
            "value": 4714
        },
        {
            "value": 4868
        },
        {
            "value": 5049
        },
        {
            "value": 5221
        },
        {
            "value": 5365
        },
        {
            "value": 5480
        },
        {
            "value": 5651
        },
        {
            "value": 5817
        },
        {
            "value": 5969
        },
        {
            "value": 6120
        },
        {
            "value": 6254
        },
        {
            "value": 6345
        },
        {
            "value": 6448
        },
        {
            "value": 6533
        },
        {
            "value": 6651
        },
        {
            "value": 6772
        },
        {
            "value": 6868
        },
        {
            "value": 6956
        },
        {
            "value": 7025
        },
        {
            "value": 7128
        },
        {
            "value": 7223
        },
        {
            "value": 7328
        },
        {
            "value": 7459
        },
        {
            "value": 7553
        },
        {
            "value": 7624
        },
        {
            "value": 7720
        },
        {
            "value": 7780
        },
        {
            "value": 7829
        },
        {
            "value": 7917
        },
        {
            "value": 8031
        },
        {
            "value": 8103
        },
        {
            "value": 8176
        }
    ]
];
export default {
    y1Data,
    y2Data,
    y1ItemLabels: ['確診數'],
    y2ItemLabels: ['死亡數'],
    xDates: [
        "2022-01-19",
        "2022-01-20",
        "2022-01-21",
        "2022-01-22",
        "2022-01-23",
        "2022-01-24",
        "2022-01-25",
        "2022-01-26",
        "2022-01-27",
        "2022-01-28",
        "2022-01-29",
        "2022-01-30",
        "2022-01-31",
        "2022-02-01",
        "2022-02-02",
        "2022-02-03",
        "2022-02-04",
        "2022-02-05",
        "2022-02-06",
        "2022-02-07",
        "2022-02-08",
        "2022-02-09",
        "2022-02-10",
        "2022-02-11",
        "2022-02-12",
        "2022-02-13",
        "2022-02-14",
        "2022-02-15",
        "2022-02-16",
        "2022-02-17",
        "2022-02-18",
        "2022-02-19",
        "2022-02-20",
        "2022-02-21",
        "2022-02-22",
        "2022-02-23",
        "2022-02-24",
        "2022-02-25",
        "2022-02-26",
        "2022-02-27",
        "2022-02-28",
        "2022-03-01",
        "2022-03-02",
        "2022-03-03",
        "2022-03-04",
        "2022-03-05",
        "2022-03-06",
        "2022-03-07",
        "2022-03-08",
        "2022-03-09",
        "2022-03-10",
        "2022-03-11",
        "2022-03-12",
        "2022-03-13",
        "2022-03-14",
        "2022-03-15",
        "2022-03-16",
        "2022-03-17",
        "2022-03-18",
        "2022-03-19",
        "2022-03-20",
        "2022-03-21",
        "2022-03-22",
        "2022-03-23",
        "2022-03-24",
        "2022-03-25",
        "2022-03-26",
        "2022-03-27",
        "2022-03-28",
        "2022-03-29",
        "2022-03-30",
        "2022-03-31",
        "2022-04-01",
        "2022-04-02",
        "2022-04-03",
        "2022-04-04",
        "2022-04-05",
        "2022-04-06",
        "2022-04-07",
        "2022-04-08",
        "2022-04-09",
        "2022-04-10",
        "2022-04-11",
        "2022-04-12",
        "2022-04-13",
        "2022-04-14",
        "2022-04-15",
        "2022-04-16",
        "2022-04-17",
        "2022-04-18",
        "2022-04-19",
        "2022-04-20",
        "2022-04-21",
        "2022-04-22",
        "2022-04-23",
        "2022-04-24",
        "2022-04-25",
        "2022-04-26",
        "2022-04-27",
        "2022-04-28",
        "2022-04-29",
        "2022-04-30",
        "2022-05-01",
        "2022-05-02",
        "2022-05-03",
        "2022-05-04",
        "2022-05-05",
        "2022-05-06",
        "2022-05-07",
        "2022-05-08",
        "2022-05-09",
        "2022-05-10",
        "2022-05-11",
        "2022-05-12",
        "2022-05-13",
        "2022-05-14",
        "2022-05-15",
        "2022-05-16",
        "2022-05-17",
        "2022-05-18",
        "2022-05-19",
        "2022-05-20",
        "2022-05-21",
        "2022-05-22",
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27",
        "2022-05-28",
        "2022-05-29",
        "2022-05-30",
        "2022-05-31",
        "2022-06-01",
        "2022-06-02",
        "2022-06-03",
        "2022-06-04",
        "2022-06-05",
        "2022-06-06",
        "2022-06-07",
        "2022-06-08",
        "2022-06-09",
        "2022-06-10",
        "2022-06-11",
        "2022-06-12",
        "2022-06-13",
        "2022-06-14",
        "2022-06-15",
        "2022-06-16",
        "2022-06-17",
        "2022-06-18",
        "2022-06-19",
        "2022-06-20",
        "2022-06-21",
        "2022-06-22",
        "2022-06-23",
        "2022-06-24",
        "2022-06-25",
        "2022-06-26",
        "2022-06-27",
        "2022-06-28",
        "2022-06-29",
        "2022-06-30",
        "2022-07-01",
        "2022-07-02",
        "2022-07-03",
        "2022-07-04",
        "2022-07-05",
        "2022-07-06",
        "2022-07-07",
        "2022-07-08",
        "2022-07-09",
        "2022-07-10",
        "2022-07-11",
        "2022-07-12",
        "2022-07-13",
        "2022-07-14",
        "2022-07-15",
        "2022-07-16",
        "2022-07-17"
    ]
};
