export const debounce = (callback, waitFor = 250) => {
    let timeout;
    return (...args) => {
        let result;
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = callback(...args);
        }, waitFor);
        return result;
    };
};
// 捲動畫面觸發事件
export function addScrollTrigger(dom, callback, screenPercent = 0.5) {
    let active = true;
    const triggerEvent = () => {
        const screenHeight = window.innerHeight || 0;
        const triggerHeight = screenHeight * screenPercent;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const domTop = dom.offsetTop || 0;
        // 觸發
        if (domTop - scrollTop <= triggerHeight) {
            active = false;
            window.document.removeEventListener('scroll', triggerEvent);
            callback();
        }
    };
    window.document.addEventListener('scroll', triggerEvent);
    setTimeout(() => {
        if (active) {
            triggerEvent();
        }
    });
}
// 加上千分位 ,
export function formatCommaNumber(num = 0) {
    try {
        let parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
    catch (e) {
        throw new Error('[bp_chart: utils.ts] formatCommaNumber error');
    }
}
// 百分比數字
export function formatPercentage(num, decimal = 0, unit = '%') {
    try {
        num = Number(num);
        if (typeof (num) !== 'number') {
            num = 0;
        }
        num = num.toFixed(decimal);
        return num.toString() + unit;
    }
    catch (e) {
        throw new Error('[bp_chart: utils.ts] formatPercentage error');
    }
}
// 取得24小時內的日期時間
export function get24HourDateTimeRange() {
    const dateTimeToString = (date) => {
        return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + " " + date.getHours() + ":30";
    };
    let Today = new Date();
    let yesterday = new Date().setDate(Today.getDate() - 1);
    let Yesterday = new Date(yesterday);
    return {
        startDate: dateTimeToString(Yesterday),
        endDate: dateTimeToString(Today)
    };
}
export function ajax(option) {
    if (!option.url) {
        return;
    }
    let xhr = window.XMLHttpRequest &&
        (window.location.protocol !== 'file:' ||
            !window.ActiveXObject) ?
        function () {
            return new XMLHttpRequest();
        } :
        function () {
            try {
                return new ActiveXObject('Microsoft.XMLHTTP');
            }
            catch (e) {
                throw new Error('XMLHttpRequest not supported');
            }
        };
    option.type = option.type || 'GET';
    option.header = option.header ?? {
        // 'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json;charset=UTF-8'
    };
    option.success = option.success || function () { };
    option.error = option.error || function () { };
    let request = xhr();
    request.onreadystatechange = function () {
        if (request.readyState === 4) {
            if (request.status === 200) {
                let response = null;
                try {
                    response = JSON.parse(request.response || request.responseText); // @Q@ 之前寫request.response，可是用mock.js發現要用request.responseText，待接真實api後確認
                }
                catch (e) { }
                option.success.call(response, response);
            }
            else {
                option.error.call(request, request);
            }
        }
    };
    let body = '';
    let url = option.url;
    if (option.data) {
        if (option.type === 'POST') {
            body = JSON.stringify(option.data);
        }
        else {
            let paramStr = '';
            Object.keys(option.data).forEach(key => {
                if (paramStr) {
                    paramStr += '&';
                }
                paramStr += `${key}=${option.data[key]}`;
            });
            url = `${option.url}?${paramStr}`;
        }
    }
    request.open(option.type, url);
    for (let name in option.header) {
        request.setRequestHeader(name, option.header[name]);
    }
    request.send(body);
}
export function goToChoose() {
    if (!window) {
        return false;
    }
    // window.location.href='https://choose.blueplanet.com.tw'
    window.open('https://choose.blueplanet.com.tw');
}
export function getPageHref() {
    // 使用iframe，取得外層網頁網址
    if (window.document.referrer)
        return window.document.referrer;
    // 本身網頁網址
    else
        return window.location.href;
}
// 取得網址參數
export function decodeUrlQuery(str) {
    str = decodeURIComponent(str);
    if (str.substring(0, 1) === '?') {
        str = str.substring(1, str.length);
    }
    var arrString = str.split('&');
    var obj = {};
    arrString.forEach(function (val) {
        var o = val.split('=');
        obj[o[0]] = o[1];
    });
    return obj;
}
// DOMContentLoaded事件
export function onDocumentReady(f) {
    if (!window) {
        return f();
    }
    var d = window.document;
    var ie = !!(window.attachEvent && !window.opera);
    var wk = /webkit\/(\d+)/i.test(navigator.userAgent) && (RegExp.$1 < 525);
    var fn = [];
    var run = function () { for (var i = 0; i < fn.length; i++)
        fn[i](); };
    if (!ie && !wk && d.addEventListener)
        return d.addEventListener('DOMContentLoaded', f, false);
    if (fn.push(f) > 1)
        return;
    if (ie)
        (function () {
            try {
                d.documentElement.doScroll('left');
                run();
            }
            catch (err) {
                setTimeout(arguments.callee, 0);
            }
        })();
    else if (wk)
        var t = setInterval(function () {
            if (/^(loaded|complete)$/.test(d.readyState))
                clearInterval(t), run();
        }, 0);
}
// 取亂數
export function randomText(length = 5) {
    // timestamp + 英文/數字 的亂數
    return `${String(Number(new Date()))}${Math.random().toString(36).substr(2, length)}`;
}
// 日期天數差異
export function dateDiff(Date1, Date2) {
    return Math.abs(Date1 - Date2) / 1000 / 60 / 60 / 24; // 把相差的毫秒數轉換為天數
}
export function addDays(_Date, num) {
    return new Date(new Date(_Date).setDate(_Date.getDate() + num));
}
export function dateToStr(datetime) {
    let dateTime = new Date(datetime);
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1; //js從0開始取
    let date = dateTime.getDate();
    // let hour: any = dateTime.getHours()
    // let minutes: any = dateTime.getMinutes()
    // let second: any = dateTime.getSeconds()
    if (month < 10) {
        month = "0" + month;
    }
    if (date < 10) {
        date = "0" + date;
    }
    // if(hour < 10) {
    //   hour = "0" + hour
    // }
    // if(minutes < 10) {
    //   minutes = "0" + minutes
    // }
    // if(second < 10) {
    //   second = "0" + second 
    // }
    // return year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":" + second
    return year + "-" + month + "-" + date;
}
// 字串轉日期（避免瀏覽器轉換為UTC相對時間）
// export function parseLocalDate (date: string): Date {
//   // 當日期字串不包含時間 e.g. 2021-01-01
//   if (date.length < 14) {
//     return new Date(new Date(date).setHours(0,0,0,0))
//   }
//   return new Date(date)
// }
// 取得文字寬度
export function measureTextWidth(text, size = 10) {
    const context = document.createElement("canvas").getContext("2d");
    let width = context?.measureText(text)?.width ?? 0;
    return width * size / 10; // 以10為基準
}
// 取得標籤文字換行文字及寬度
export function getTextLines(text, textSize, fixedWidth) {
    let textWidth = measureTextWidth(text, textSize);
    let lines = [];
    // 需換行時
    if (textWidth > fixedWidth) {
        textWidth = fixedWidth;
        let lineText = ''; // 目前行的文字
        for (let i = 0; i < text.length; i++) {
            lineText += text[i];
            if (measureTextWidth(lineText, textSize) > fixedWidth) {
                lines.push(lineText.slice(0, lineText.length - 1)); // 多加一行
                lineText = text[i];
            }
        }
        lines.push(lineText);
    }
    // 單行
    else {
        lines = [text];
    }
    return {
        textWidth,
        lines
    };
}
