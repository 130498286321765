import { makeAxisTimeScale, makeAxisLinearScale } from '@bpchart/d3-modules/moduleUtils';
import { parseLocalDate } from '@bpchart/d3-modules/d3Utils';
const filteredDateList = ['2020-12-31', '2021-01-01', '2021-01-02', '2021-01-03', '2021-01-04', '2021-01-05', '2021-01-06', '2021-01-07', '2021-01-08', '2021-01-09', '2021-01-10', '2021-01-11', '2021-01-12', '2021-01-13', '2021-01-14', '2021-01-15', '2021-01-16', '2021-01-17', '2021-01-18', '2021-01-19', '2021-01-20', '2021-01-21', '2021-01-22', '2021-01-23', '2021-01-24', '2021-01-25', '2021-01-26', '2021-01-27', '2021-01-28', '2021-01-29', '2021-01-30'];
const FilteredDateList = filteredDateList.map(d => parseLocalDate(d));
export default {
    xScale: makeAxisTimeScale(FilteredDateList, 520, 0.5),
    yScale: makeAxisLinearScale({ maxValue: 0, minValue: 10, axisWidth: 410, reverse: true }),
    "yLabels": [
        "ptt [Gossiping]",
        "ptt [C_Chat]",
        "youtube [Muse木棉花-TW]",
        "dcard [有趣]",
        "ptt [Marginalman]",
        "news [LINE TODAY]",
        "news [奇摩新聞]",
        "dcard [閒聊]",
        "news [NOWnews今日新聞]",
        "dcard [梗圖]"
    ],
    "labelSeq": [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9
    ],
    "rowAmount": 10,
    "dateAmount": 31
};
