export default {
    "data": [
        [
            {
                "value": 55
            },
            {
                "value": 80
            },
            {
                "value": 50
            },
            {
                "value": 110
            },
            {
                "value": 150
            }
        ],
        [
            {
                "value": 35
            },
            {
                "value": 40
            },
            {
                "value": 15
            },
            {
                "value": 65
            },
            {
                "value": 120
            }
        ]
    ],
    "xLabels": [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    "itemLabels": [
        "進口",
        "出口"
    ]
};
