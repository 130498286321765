export default {
    data: [
        {
            "data": {
                "value": 120,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "讚"
            },
            "index": 0,
            "value": 120,
            "startAngle": 0,
            "endAngle": 2.7417535885874558,
            "padAngle": 0
        },
        {
            "data": {
                "value": 55,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "大心"
            },
            "index": 1,
            "value": 55,
            "startAngle": 2.7417535885874558,
            "endAngle": 3.998390650023373,
            "padAngle": 0
        },
        {
            "data": {
                "value": 45,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "哈"
            },
            "index": 2,
            "value": 45,
            "startAngle": 3.998390650023373,
            "endAngle": 5.026548245743669,
            "padAngle": 0
        },
        {
            "data": {
                "value": 30,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "哇"
            },
            "index": 3,
            "value": 30,
            "startAngle": 5.026548245743669,
            "endAngle": 5.711986642890533,
            "padAngle": 0
        },
        {
            "data": {
                "value": 13,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "嗚"
            },
            "index": 4,
            "value": 13,
            "startAngle": 5.711986642890533,
            "endAngle": 6.009009948320841,
            "padAngle": 0
        },
        {
            "data": {
                "value": 12,
                "itemIndex": 0,
                "itemLabel": "__DEFAULT__",
                "id": "怒"
            },
            "index": 5,
            "value": 12,
            "startAngle": 6.009009948320841,
            "endAngle": 6.283185307179587,
            "padAngle": 0
        }
    ],
    itemLabels: ['正面情緒', '中立情緒', '負面情緒'],
    arcLabels: ['讚', '大心', '哈', '哇', '嗚', '怒'],
    axisWidth: 500
};
