import * as d3 from 'd3';
import SliderDateScaleRange from './SliderDateRange';
export default class SliderDateScaleRangeLine extends SliderDateScaleRange {
    lineSelection = undefined;
    constructor(selection, params) {
        super(selection, params);
    }
    renderGraphic({ backgroundSelection, data, xScale, yScale }) {
        const linePath = d3.line()
            .x((d) => {
            const x = xScale(d._Date);
            return x;
        })
            .y((d) => {
            const y = yScale(d.value);
            return y;
        })
            .curve(d3.curveMonotoneX);
        const update = backgroundSelection
            .selectAll(`path.bpchart__slider-path`)
            .data([data]);
        const enter = update.enter()
            .append('path')
            .classed(`bpchart__slider-path`, true);
        update.exit();
        this.lineSelection = update.merge(enter);
        this.lineSelection
            // .attr("transform", "translate(" + this.padding.left + "," + this.padding.top + ")")
            .attr("d", (d) => {
            return linePath(d);
        })
            .attr("fill", "none")
            .attr("stroke-width", 1)
            .attr("stroke", '#587791')
            .attr('opacity', 0.6);
    }
}
