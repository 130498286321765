import * as d3 from 'd3';
import ChartDateRank from './ChartDateRank';
export default class ChartDateRankBubbles extends ChartDateRank {
    circleGSelection;
    constructor(el, params) {
        super(el, params);
    }
    renderRowGraphic({ itemSelection, itemRenderData, itemContainerDatum, colorScale }) {
        const gUpdate = itemSelection
            .selectAll("g")
            .data(itemRenderData, d => d.id);
        const gEnter = gUpdate
            .enter()
            .append('g')
            .classed('bpchart__circle-g', true);
        const g = gEnter.merge(gUpdate);
        g.exit().remove();
        gEnter
            .append("circle");
        const circle = g.select('circle')
            .attr("fill", (d) => colorScale(d.itemLabel))
            .style('cursor', this.params.highlightTarget != 'none' ? 'pointer' : 'default');
        circle
            .transition()
            .duration(200)
            .attr("r", (rd, ri) => rd.r)
            .attr("opacity", d => {
            if (itemContainerDatum.isVisible && d.isVisible) {
                return 0.7;
            }
            return 0;
        })
            .attr("transform", (xd, xi) => `translate(${xd.x}, 0)`);
        this.setBubbleEvent(g);
    }
    initHighlight() {
        this.circleGSelection = this.graphicSelection.selectAll('g.bpchart__circle-g');
        // highlight
        if (this.params.highlightItemId || this.params.highlightDatumId) {
            this.highlight(this.circleGSelection, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    setBubbleEvent(selection) {
        selection
            .on('mouseover', (d, i, g) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'group') {
                    this.highlight(this.circleGSelection, callbackData.xLabel, undefined, undefined);
                }
                else if (this.params.highlightTarget === 'item') {
                    this.highlight(this.circleGSelection, undefined, callbackData.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(this.circleGSelection, undefined, undefined, callbackData.datum && callbackData.datum.id);
                }
            }
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i, g) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'group') {
                    this.highlight(this.circleGSelection, callbackData.xLabel, undefined, undefined);
                }
                else if (this.params.highlightTarget === 'item') {
                    this.highlight(this.circleGSelection, undefined, callbackData.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(this.circleGSelection, undefined, undefined, callbackData.datum && callbackData.datum.id);
                }
            }
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i, g) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                this.removeHighlight(this.circleGSelection);
            }
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        })
            .on('click', (d, i, g) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.clickCallback(callbackData);
        });
    }
    highlight(selection, groupId, itemId, datumId) {
        if (!selection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.graphicRenderData
                .flat()
                .filter(d => d.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        if (groupId) {
            const _ids = this.graphicRenderData
                .flat()
                .filter(d => d.xLabel === groupId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        selection
            .each((d, i, n) => {
            if (ids.includes(d.id)) {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(selection) {
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId) {
            this.highlight(selection, this.params.highlightDatumId, this.params.highlightItemId);
        }
        else if (!this.params.highlightDatumId) {
            selection
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }
}
