import { ChartArcPie } from '@bpchart/d3-modules/chartArc';
import dataset2 from './dataset2';
export default {
    props: {
        module: ChartArcPie,
        dataset: dataset2,
        params: {
            graphicArcPie: {
                outerMouseoverRadius: 1,
                outerRadius: 0.95,
                innerRadius: 0.7,
                padAngle: 0.02,
                cornerRadius: 0
            },
            centerText: (data, eventName, t = 1) => {
                if (eventName === 'mouseover') {
                    return [data.arcLabel, data.datum.value];
                }
                return [
                    '情緒',
                    // 用動畫(t:0->1)呈現總數
                    Math.round(data.dataset.data.reduce((prev, current) => prev + current.value, 0) * t)
                ];
            },
            centerTextStyles: [
                {
                    'font-weight': 'bold',
                    'text-anchor': 'middle',
                    'pointer-events': 'none',
                    'dominant-baseline': 'middle',
                    'font-size': 64,
                    'fill': '#000',
                },
                {
                    'pointer-events': 'none',
                    'dominant-baseline': 'middle',
                    'text-anchor': 'middle',
                    'font-size': 28,
                    fill: 'rgb(96, 98, 102)'
                }
            ],
            centerTextAttrs: [
                {
                    transform: 'translate(0, -12)'
                },
                {
                    transform: 'translate(0, 48)'
                }
            ]
        }
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {},
        enterDuration: {}
    }
};
