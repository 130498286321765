import { DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_ROW_BAR_PARAMS = {
    //   barType: 'rect',
    barWidth: 0,
    barPadding: 1,
    barR: false,
    colors: DEFAULT_COLORS,
    // axisWidth: 0,
    // axisHeight: 0,
    highlightTarget: 'datum',
    highlightDatumId: '',
    enterDuration: 800
};
