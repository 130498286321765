import { DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_AXIS_COLUMN_PARAMS = {
    xLabel: 'x',
    yLabel: 'y',
    xLabelAnchor: 'start',
    yLabelAnchor: 'end',
    xLabelOffset: [0, 0],
    yLabelOffset: [0, 0],
    xTickPadding: 20,
    yTickPadding: 20,
    xTickLine: false,
    yTickLine: true,
    xTickLineDasharray: 'none',
    yTickLineDasharray: 'none',
    yTickFormat: ',.0f',
    yTicks: 4,
    axisLineColor: DEFAULT_ASIX_LINE_COLOR,
    axisLabelColor: DEFAULT_AXIS_LABEL_COLOR,
    rotateXLabel: false,
    padding: DEFAULT_PADDING,
    xVisible: true,
    yVisible: true,
};
