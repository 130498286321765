import * as d3 from 'd3';
import ChartColumnTwoScales from './ChartColumnTwoScales';
import GraphicColumnBar from '../graphicColumnBar/GraphicColumnBar';
import GraphicColumnLine from '../graphicColumnLine/GraphicColumnLine';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnTwoScalesBarAndLine extends ChartColumnTwoScales {
    params = DEFAULT_CHART_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS;
    bar;
    line;
    dot;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBar(this.graphicY1Selection, {});
        this.line = new GraphicColumnLine(this.graphicY2Selection, {});
        this.dot = new GraphicDot(this.coverSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
        };
        this.params.graphicColumnBar = {
            ...this.params.graphicColumnBar,
            colors: this.params.y1Colors,
            highlightTarget: this.params.highlightTarget == 'group' ? 'none' : this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId
        };
        this.params.graphicColumnLine = {
            ...this.params.graphicColumnLine,
            colors: this.params.y2Colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.params.graphicDot = {
            ...this.params.graphicDot,
            colors: this.params.y2Colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.bar.setParams(this.params.graphicColumnBar);
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
        this.line.setParams(this.params.graphicColumnLine);
        this.line
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot.render();
            }
        });
        this.dot.setParams(this.params.graphicDot);
        this.dot
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line.render();
            }
        });
    }
    makeY1GraphicRenderData({ dataset, xScale, y1Scale }) {
        return dataset.y1Data.map((_d, i) => {
            const xLabel = dataset.xLabels[i];
            const d = _d;
            return {
                ...d,
                id: xLabel,
                itemIndex: i,
                itemLabel: d.itemLabel ?? defaultItemLabel,
                xIndex: i,
                xLabel,
                x: xScale(xLabel) || 0,
                y: y1Scale(d.value) || 0
            };
        });
    }
    makeY2GraphicRenderData({ dataset, xScale, y2Scale }) {
        return dataset.y2Data.map((d, i) => {
            const itemLabel = dataset.y2ItemLabels && dataset.y2ItemLabels[i] ? dataset.y2ItemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x: xScale(xLabel) || 0,
                    y: y2Scale(_d.value) || 0
                };
            });
        });
    }
    getY1MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    getY2MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeY1XDataMap({ y1RenderData, xLabels }) {
        let XDataMap = new Map();
        if (!y1RenderData.length) {
            return XDataMap;
        }
        y1RenderData.forEach((d, i) => {
            XDataMap.set(xLabels[i] ?? '', [d]);
        });
        return XDataMap;
    }
    makeY2XDataMap({ y2RenderData, xLabels }) {
        let XDataMap = new Map();
        if (!y2RenderData.length) {
            return XDataMap;
        }
        y2RenderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i] ?? '', xData);
            });
        });
        return XDataMap;
    }
    makeY1ItemDataMap({ y1RenderData, y1ItemLabels }) {
        let ItemDataMap = new Map();
        if (!y1RenderData.length) {
            return ItemDataMap;
        }
        y1RenderData.forEach((d, i) => {
            const itemData = ItemDataMap.get(d.itemLabel ?? '') ?? [];
            itemData.push(d);
            ItemDataMap.set(d.itemLabel ?? '', itemData);
        });
        return ItemDataMap;
    }
    makeY2ItemDataMap({ y2RenderData, y2ItemLabels }) {
        let ItemDataMap = new Map();
        if (!y2RenderData.length) {
            return ItemDataMap;
        }
        y2RenderData.forEach((d, i) => {
            ItemDataMap.set(y2ItemLabels[i] ?? '', d);
        });
        return ItemDataMap;
    }
    setGraphicData({ y1RenderData, y2RenderData, y1ItemLabels, y2ItemLabels, xLabels, xScale, y1Scale, y2Scale }) {
        this.bar.setDataset({
            data: y1RenderData,
            itemLabels: y1ItemLabels && y1ItemLabels.length ? y1ItemLabels : [defaultItemLabel],
            axisWidth: this.axisWidth,
            zeroY: y1Scale(0),
        });
        this.line.setDataset({
            data: y2RenderData,
            itemLabels: y2ItemLabels && y2ItemLabels.length ? y2ItemLabels : [defaultItemLabel],
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: y2Scale(0)
        });
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            const dotData = y2RenderData.flat();
            this.dot.setDataset({
                data: dotData,
                itemLabels: y2ItemLabels ?? [defaultItemLabel]
            });
        }
    }
    renderGraphic() {
        this.bar.render();
        this.line.render();
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            this.dot.render();
        }
    }
    renderGraphicCoverHighlight({ eventData, xScale, y1Scale, y2Scale, padding }) {
        // 非顯示全部圓點時 highlight圓點
        if (this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            const dotData = eventData
                ? eventData.y2.groupData.map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.xLabel),
                        y: y2Scale(d.value)
                    };
                })
                : [];
            this.dot.setDataset({
                data: dotData,
                itemLabels: this.dataset.y2ItemLabels ?? [defaultItemLabel]
            });
            this.dot.render();
        }
    }
}
