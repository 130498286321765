import * as d3 from 'd3';
import ButtonSliderBar from '../button/ButtonSliderBar';
import { getSvgGElementSize } from '../d3Utils';
import { DEFAULT_CHART_WIDTH } from '../defaults';
// const height = 36
const barHeight = 24;
const barPadding = 25;
const buttonWidth = 14;
const scalePadding = buttonWidth / 2; // 為避免按鈕跑到場景外所以扣掉按鈕本身寬度/2
export default class SliderDateScaleRange {
    _selection = undefined;
    barSelection;
    backgroundSelection;
    backgroundColorSelection;
    rangeRectSelection;
    startButtonSelection;
    centerButtonSelection;
    endButtonSelection;
    textSelection = undefined;
    // private startDate: string = ''
    // private endDate: string = ''
    StartDate = new Date();
    EndDate = new Date();
    // private dateList: string[] = []
    renderData = []; // 渲染用的資料
    sliderData = []; // 滑軸用的資料
    // private DateXMap = new Map() // 日期映射座標的Map
    dragStartCallback = function () { };
    dragCallback = function () { };
    dragEndCallback = function () { };
    clickCallback = function () { };
    dragStartX = -1; // 區塊拖曳起始座標
    dragStartStartBtnX = -1; // 起始按鈕拖曳起始座標
    dragStartEndBtnX = -1; // 起始按鈕拖曳起始座標
    dateFormatter = undefined;
    barWidth = 0; // bar條的寬度
    textWidth = 0; // 文字寬度
    startButton;
    centerButton;
    endButton;
    startButtonIndex = -1; // 目前選擇的日期資料索引
    endButtonIndex = -1; // 目前選擇的日期資料索引
    // public StartButtonDate = new Date() // 目前選擇的日期
    // public EndButtonDate = new Date() // 目前選擇的日期
    data = [];
    params = {
        dateFormat: '%Y-%m-%d',
        fixRange: false,
        alignUnit: undefined,
        fontSize: 10
    };
    width = DEFAULT_CHART_WIDTH;
    height = 36;
    xScale; // 繪圖用的x軸比例尺
    yScale; // 繪圖用的y軸比例尺
    xSliderScale; // 拉軸x座標對應比例尺（如果有設定alignUnit則需重新對應x座標）
    xRangeScale; // x座標轉換日期索引比例尺
    constructor(selection, params) {
        this._selection = selection
            .attr('height', `${this.height}px`);
        this.params = {
            ...this.params,
            ...params
        };
        this.dateFormatter = d3.timeFormat(this.params.dateFormat);
        const dragRange = d3.drag()
            .on('start', (d, i, nodes) => {
            // 紀錄拖曳起始的座標
            this.dragStartX = d3.event.x;
            this.dragStartStartBtnX = this.returnButtonX(this.startButtonIndex);
            this.dragStartEndBtnX = this.returnButtonX(this.endButtonIndex);
            this.handleRangeMove(d3.event.x);
            this.dragStartCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        })
            .on("drag", (d, i, nodes) => {
            this.handleRangeMove(d3.event.x);
            this.dragCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        })
            .on("end", (d, i, nodes) => {
            // 清除拖曳起始的座標
            this.dragStartX = -1;
            this.dragStartStartBtnX = -1;
            this.dragStartEndBtnX = -1;
            this.dragEndCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        });
        const dragButton = d3.drag()
            .on('start', (d, i, nodes) => {
            this.doRender({
                startX: d === 'start' ? d3.event.x : -1,
                endX: d === 'end' ? d3.event.x : -1
            });
            this.dragStartCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        })
            .on("drag", (d, i, nodes) => {
            this.doRender({
                startX: d === 'start' ? d3.event.x : -1,
                endX: d === 'end' ? d3.event.x : -1
            });
            this.dragCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        })
            .on("end", (d, i, nodes) => {
            this.doRender({
                startX: d === 'start' ? d3.event.x : -1,
                endX: d === 'end' ? d3.event.x : -1
            });
            this.dragEndCallback({
                startDate: this.sliderData[this.startButtonIndex].date,
                endDate: this.sliderData[this.endButtonIndex].date
            });
        });
        this.barSelection = this._selection
            .append('g')
            .attr('class', 'bpchart__bar')
            .attr('height', `${barHeight}px`)
            .attr("transform", `translate(${barPadding}, 0)`);
        this.backgroundSelection = this.barSelection
            .append('g')
            .attr('class', 'bpchart__background');
        this.backgroundColorSelection = this.backgroundSelection
            .append('rect')
            .attr('fill', '#f1f1f1')
            .attr('height', barHeight);
        this.rangeRectSelection = this.barSelection
            .append('g')
            .datum('range')
            .attr('class', 'bpchart__range')
            .call(dragRange);
        if (this.params.fixRange) {
            this.centerButtonSelection = this.barSelection
                .append('g')
                .datum('center')
                .attr('class', 'bpchart__centerBtn')
                .call(dragRange);
            this.centerButton = new ButtonSliderBar(this.centerButtonSelection, {});
            this.centerButton.render();
        }
        else {
            this.startButtonSelection = this.barSelection
                .append('g')
                .datum('start')
                .attr('class', 'bpchart__startBtn')
                .call(dragButton);
            this.endButtonSelection = this.barSelection
                .append('g')
                .datum('end')
                .attr('class', 'bpchart__endBtn')
                .call(dragButton);
            this.startButton = new ButtonSliderBar(this.startButtonSelection, {});
            this.endButton = new ButtonSliderBar(this.endButtonSelection, {});
            this.startButton.render();
            this.endButton.render();
        }
    }
    get selection() {
        return this._selection;
    }
    select() {
        return this._selection;
    }
    setData(data) {
        if (!data || !data.length) {
            return;
        }
        // let dateList: string[] = []
        let renderData = [];
        data.forEach(d => {
            let _Date = new Date(d.date);
            renderData.push({
                date: d.date,
                value: d.value,
                _Date,
                _x: 0 // resize的時候再補上座標
            });
            // dateList.push(d.date)
        });
        this.renderData = renderData;
        if (!this.params.alignUnit) {
            this.sliderData = Object.assign(this.renderData, []);
            // this.dateList = dateList
        }
        else if (this.params.alignUnit === 'day') {
            let sliderData = [];
            for (let i = 0; i < this.renderData.length; i++) {
                let _Date = new Date(this.renderData[i].date);
                _Date = new Date(_Date.setHours(0, 0, 0, 0));
                if (i === 0 || Number(_Date) !== Number(sliderData[sliderData.length - 1]._Date)) {
                    // 日期不同才新增資料
                    sliderData.push({
                        date: this.renderData[i].date,
                        value: this.renderData[i].value,
                        _Date,
                        _x: 0 // resize的時候再補上座標
                    });
                }
            }
            this.sliderData = sliderData;
        }
        else if (this.params.alignUnit === 'month') {
            let sliderData = [];
            for (let i = 0; i < this.renderData.length; i++) {
                let _Date = new Date(this.renderData[i].date);
                _Date = new Date(_Date.setDate(1));
                _Date = new Date(_Date.setHours(0, 0, 0, 0));
                if (i === 0 || Number(_Date) !== Number(sliderData[sliderData.length - 1]._Date)) {
                    // 日期不同才新增資料
                    sliderData.push({
                        date: this.renderData[i].date,
                        value: this.renderData[i].value,
                        _Date,
                        _x: 0 // resize的時候再補上座標
                    });
                }
            }
            this.sliderData = sliderData;
        }
        else if (this.params.alignUnit === 'year') {
            let sliderData = [];
            for (let i = 0; i < this.renderData.length; i++) {
                let _Date = new Date(this.renderData[i].date);
                _Date = new Date(_Date.setMonth(0));
                _Date = new Date(_Date.setDate(1));
                _Date = new Date(_Date.setHours(0, 0, 0, 0));
                if (i === 0 || Number(_Date) !== Number(sliderData[sliderData.length - 1]._Date)) {
                    // 日期不同才新增資料
                    sliderData.push({
                        date: this.renderData[i].date,
                        value: this.renderData[i].value,
                        _Date,
                        _x: 0 // resize的時候再補上座標
                    });
                }
            }
            this.sliderData = sliderData;
        }
        // this.startDate = data[0].date
        // this.endDate = data[data.length - 1].date
        this.StartDate = this.sliderData[0]._Date;
        this.EndDate = this.sliderData[this.sliderData.length - 1]._Date;
        this.startButtonIndex = 0;
        this.endButtonIndex = this.sliderData.length - 1;
        // this.StartButtonDate = this.renderData[0]._Date
        // this.EndButtonDate = this.renderData[this.renderData.length - 1]._Date
        const maxValue = d3.max(data, d => d.value) || 0;
        const minValue = 0;
        // 設定比例尺
        this.yScale = d3.scaleLinear()
            .domain([maxValue / 0.95, minValue]) // 因為要由下往上所以反過來
            .range([0, barHeight]);
        this.setXScale();
        // this.resize({
        //   width: this.params.width!
        // })
        // this.resize({
        //   width: Number(this.selection.attr('width'))
        // })
        // 繪製背景圖
        // this.renderGraphic({
        //   backgroundSelection: this.backgroundSelection!,
        //   data: this.renderData,
        //   xScale: this.xScale!,
        //   yScale: this.yScale!
        // })
        // if (this.params.fixRange === false) {
        //   this.startButton!.render()
        //   this.endButton!.render()
        // } else {
        //   this.centerButton!.render()
        // }
        // this.resize({
        //   width: this.params.width!
        // })
    }
    remove() {
        this._selection.remove();
    }
    resize({ width }) {
        this.width = width;
        this.selection
            .attr('width', width);
        // 設定bar條的寬度及位置
        this.barWidth = width - (barPadding * 2);
        this.barSelection
            .attr('width', `${this.barWidth}px`);
        this.backgroundColorSelection
            .attr('width', `${this.barWidth}px`);
        // 設定 X軸比例尺
        this.setXScale();
        // 繪製背景圖
        this.renderGraphic({
            backgroundSelection: this.backgroundSelection,
            data: this.renderData,
            xScale: this.xScale,
            yScale: this.yScale
        });
        // this.render()
    }
    render() {
        // const startButtonX = this.xScale!(this.StartDate)!
        // const endButtonX = this.xScale!(this.EndDate)!
        // this.setButtonX(startButtonX, endButtonX)
        // this.renderRangeRect(startButtonX, endButtonX)
        this.doRender({ startX: -1, endX: -1 });
    }
    filter(filterConfig) {
        const startX = this.xScale(new Date(filterConfig.startDate));
        const endX = this.xScale(new Date(filterConfig.endDate));
        this.startButtonIndex = this.xRangeScale(startX);
        this.endButtonIndex = this.xRangeScale(endX);
        this.doRender({ startX, endX });
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'dragStart') {
            this.dragStartCallback = callback;
        }
        else if (actionName === 'drag') {
            this.dragCallback = callback;
        }
        else if (actionName === 'dragEnd') {
            this.dragEndCallback = callback;
        }
        return this;
    }
    // 設定 X軸比例尺
    setXScale() {
        this.xScale = d3.scaleTime()
            .domain([this.StartDate, this.EndDate])
            .range([scalePadding, this.barWidth - scalePadding]); // scaleTime沒有padding所以自己算
        // x座標轉換日期索引比例尺
        const halfRange = (this.barWidth / this.sliderData.length) / 2;
        const startDomain = -halfRange + scalePadding;
        const endDomain = this.barWidth + halfRange - scalePadding;
        this.xRangeScale = d3.scaleQuantize()
            .domain([startDomain, endDomain])
            .range(this.sliderData.map((d, i) => i));
        // 日期映射x座標資料
        // this.DateXMap = new Map(
        //   this.renderData.map(d => {
        //     return [
        //       d.date,
        //       this.xScale!(d._Date)! // x座標
        //     ]
        //   })
        // )
        this.sliderData = this.sliderData.map(d => {
            d._x = this.xScale(d._Date) || 0;
            return d;
        });
    }
    // 索引取得x座標
    returnButtonX(index) {
        if (this.sliderData[index]) {
            return this.sliderData[index]._x;
        }
        if (index < 0) {
            return this.sliderData[0]._x;
        }
        if (index > this.sliderData.length - 1) {
            return this.sliderData[this.sliderData.length - 1]._x;
        }
        return -1;
    }
    doRender({ startX = -1, endX = -1, }) {
        // startX, endX 為 -1則 index不變，如果有傳值進來的話重新計算 index
        if (startX >= 0) {
            // 日期索引
            let startButtonIndex = this.xRangeScale(startX);
            if (startButtonIndex > this.endButtonIndex) {
                startButtonIndex = this.endButtonIndex;
            }
            else if (startButtonIndex < 0) {
                startButtonIndex = 0;
            }
            this.startButtonIndex = startButtonIndex;
        }
        if (endX >= 0) {
            // 日期索引
            let endButtonIndex = this.xRangeScale(endX);
            if (endButtonIndex < this.startButtonIndex) {
                endButtonIndex = this.startButtonIndex;
            }
            else if (endButtonIndex > this.sliderData.length - 1) {
                endButtonIndex = this.sliderData.length - 1;
            }
            this.endButtonIndex = endButtonIndex;
        }
        const startButtonX = this.returnButtonX(this.startButtonIndex);
        const endButtonX = this.returnButtonX(this.endButtonIndex);
        this.setButtonX(startButtonX, endButtonX);
        this.renderRangeRect(startButtonX, endButtonX);
    }
    handleRangeMove(x) {
        // 計算拖曳偏移
        const dragOffsetX = x - this.dragStartX;
        // 計算新的座標
        let startX = this.dragStartStartBtnX + dragOffsetX;
        // let endX = this.dragStartEndBtnX + dragOffsetX
        // 原始日期索引間距
        const indexOffset = this.endButtonIndex - this.startButtonIndex;
        // 日期索引（先計算起始索引，再將原本的間距來算結尾索引）
        let startButtonIndex = this.xRangeScale(startX);
        let endButtonIndex = startButtonIndex + indexOffset;
        if (endButtonIndex > this.sliderData.length - 1) {
            endButtonIndex = this.sliderData.length - 1;
            startButtonIndex = endButtonIndex - indexOffset;
        }
        else if (startButtonIndex < 0) {
            startButtonIndex = 0;
            endButtonIndex = indexOffset;
        }
        this.startButtonIndex = startButtonIndex;
        this.endButtonIndex = endButtonIndex;
        // 映射日期座標
        const mappingStartX = this.returnButtonX(this.startButtonIndex);
        const mappingEndX = this.returnButtonX(this.endButtonIndex);
        this.setButtonX(mappingStartX, mappingEndX);
        this.renderRangeRect(this.returnButtonX(this.startButtonIndex), this.returnButtonX(this.endButtonIndex));
    }
    // 設定按鈕座標
    setButtonX(startButtonX, endButtonX) {
        let textData = [];
        if (this.params.fixRange) {
            const centerX = startButtonX + ((endButtonX - startButtonX) / 2);
            this.centerButtonSelection
                .attr("transform", `translate(${centerX - (buttonWidth / 2)}, 0)`);
            textData = [
                {
                    text: this.dateFormatter(this.sliderData[this.startButtonIndex]._Date),
                    x: centerX
                }
            ];
        }
        else {
            let startX = startButtonX;
            let endX = endButtonX;
            this.startButtonSelection
                .attr("transform", `translate(${startX - (buttonWidth / 2)}, 0)`);
            this.endButtonSelection
                .attr("transform", `translate(${endX - (buttonWidth / 2)}, 0)`);
            textData = [
                {
                    text: this.dateFormatter(this.sliderData[this.startButtonIndex]._Date),
                    x: startX
                },
                {
                    text: this.dateFormatter(this.sliderData[this.endButtonIndex]._Date),
                    x: endX
                }
            ];
        }
        this.renderText(this.textSelection, textData);
    }
    renderText(selection, textData) {
        if (textData.length >= 2) {
            // 預防文字重疊機制
            const minDistance = this.textWidth != 0 ? this.textWidth + 10 : 60; // 最小可容許的間距為文字寬度
            for (let i = 1; i < textData.length; i++) {
                // 如果和前一筆資料間距小於50則調整位置
                let distance = textData[i].x - textData[i - 1].x;
                if (distance < minDistance) {
                    let offset = (minDistance - distance) / 2;
                    textData[i - 1].x = textData[i - 1].x - offset;
                    textData[i].x = textData[i].x + offset;
                }
            }
        }
        const update = this.selection
            .selectAll('text')
            .data(textData);
        const enter = update
            .enter()
            .append('text')
            .attr('text-anchor', 'middle')
            // .attr('fill', '#587791')
            .attr('opacity', 0.5)
            .style('font-size', `${this.params.fontSize}px`)
            .style('font-weight', 'bold');
        this.textSelection = update
            .merge(enter)
            .attr("transform", d => `translate(${barPadding + d.x}, ${this.height})`)
            .text(d => d.text);
        update.exit().remove();
        const textSize = getSvgGElementSize(this.textSelection);
        this.textWidth = textSize.width || 0;
    }
    // 拉bar區間區塊
    renderRangeRect(x1, x2) {
        const update = this.rangeRectSelection
            .selectAll('rect')
            .data([{ x1, x2 }]);
        const enter = update
            .enter()
            .append('rect')
            .attr('fill', '#587791')
            .attr('opacity', 0.3)
            .style('cursor', 'pointer');
        const rangeRectSelection = enter.merge(update);
        rangeRectSelection
            .attr('x', d => d.x1)
            .attr('y', 0)
            .attr('width', d => d.x2 - d.x1)
            .attr('height', barHeight);
        update.exit().remove();
    }
}
