import ChartScoutRoute from './ChartScoutRoute';
import * as d3 from 'd3';
import { measureTextWidth } from '@bpchart/d3-modules/utils';
import Nodes, {} from './Nodes';
import Edges, {} from './Edges';
import { MIDDLE_OFFCET_X, TREE_FULL_COLUMN_WIDTH, TREE_FULL_ROW_HEIGHT, STRIPE_COLOR, EDGE_COUNT_TAG_LEFT, EDGE_CURVE, EDGE_SIDE_Y_OFFSET, NODE_WIDTH_DEFAULT, NODE_HEIGHT_MIN, NODE_TEXT_LEFT, NODE_TEXT_SIZE, NODE_TEXT_WIDTH_DEFAULT, NODE_RADIUS_ROUND, SIDE_STEP, WAY_ROW_LINE_STEP, WAY_COLUMN_LINE_STEP, 
// WAY_ROW_LINE_FROM_NODE,
DEFAULT_STOCK_MIN, DEFAULT_STOCK_EXCEPT_ZERO } from './const';
import { CHART_SCOUT_ROUTE_TREE2_PARAMS } from './defaults';
// 設定 _originTrunkNodeId, expandable, _mainRelatedToRoot, _mainPareentId, hasDown
// const setNodesTopAndDown = (nodesData: Array<TreeFullNodeDatum>, rootId: string) => {
//   let MainRouteFirstNodeSet = new Set()
//   nodesData.forEach(node => {
//     let mainRoutes: string[] = []
//     if (node._routesDataUp.length) {
//       mainRoutes = node._routesDataUp[0]['routes-in-id'] // 排序過的路徑中的第一筆為主要路徑
//       node._originTrunkNodeId = mainRoutes[0] ? mainRoutes[0].split('->')[1] : ''
//     } else if (node._routesDataDown.length) {
//       mainRoutes = node._routesDataDown[0]['routes_to_downs-in-id'] // 排序過的路徑中的第一筆為主要路徑
//       node._originTrunkNodeId = mainRoutes[mainRoutes.length - 1] ? mainRoutes[mainRoutes.length - 1].split('->')[0] : ''
//     }
//     MainRouteFirstNodeSet.add(node._originTrunkNodeId)
//   })
//   nodesData.forEach(node => {
//     // 寫入 expandable資料
//     if (node.id != rootId) {
//       node.expandable = MainRouteFirstNodeSet.has(node.id)
//     }
//   })
// }
// @Q@ 目前會有孤兒遺漏的問題
const makeBranchNodesMap = (SortedTwigNodesMap, rootId) => {
    // 紀錄每個節點的所有祖先節點
    const BranchNodesMap = new Map(); // <_originTrunkNodeIds, ancestorsNodes>
    const setAncestors = (descendantsIds, ParentsNodes) => {
        // 全部祖先紀錄子節點
        descendantsIds.forEach(id => {
            let descendants = BranchNodesMap.get(id) || [];
            descendants = descendants.concat(ParentsNodes);
            BranchNodesMap.set(id, descendants);
        });
        // 下一個疊代，將下一層的子節點累加到所有祖先
        ParentsNodes.forEach(child => {
            const nextIds = descendantsIds.concat([child.id]);
            const childrenOfChild = SortedTwigNodesMap.get(child.id) || [];
            if (childrenOfChild.length) {
                setAncestors(nextIds, childrenOfChild);
            }
        });
    };
    const rootParents = SortedTwigNodesMap.get(rootId) || [];
    setAncestors([rootId], rootParents);
    return BranchNodesMap;
};
const makeSortedTwigNodesMap = (treeNodes) => {
    // -- 紀錄每個節點的枝節點 --
    const SortedTwigNodesUpMap = new Map(); // <_originTrunkNodeId, nodeIds>
    const SortedTwigNodesDownMap = new Map(); // <_originTrunkNodeId, nodeIds>
    treeNodes.forEach(node => {
        // 上游節點
        if (node._mainRouteUp.length) {
            // if (!SortedTwigNodesUpMap.get(node.id)) {
            //   SortedTwigNodesUpMap.set(node.id, [])
            // }
            // const mainRoutes = node._routesDataUp[0]['routes-in-id']
            const trunkNodeId = node._mainRouteUp[0] ? node._mainRouteUp[0].split('->')[1] : '';
            if (trunkNodeId) {
                const twigs = SortedTwigNodesUpMap.get(trunkNodeId) || [];
                twigs.push(node);
                SortedTwigNodesUpMap.set(trunkNodeId, twigs);
            }
        }
        // 下游節點（上游路徑優先於下游，所以如果有上游路徑資料那下游路徑就不加入了）
        else if (node._routesDataDown.length) {
            // if (!SortedTwigNodesDownMap.get(node.id)) {
            //   SortedTwigNodesDownMap.set(node.id, [])
            // }
            // const mainRoutes = node._routesDataDown[0]['routes_to_downs-in-id']
            const mainParentId = node._mainRouteDown[node._mainRouteDown.length - 1] ? node._mainRouteDown[node._mainRouteDown.length - 1].split('->')[0] : '';
            if (mainParentId) {
                const twigs = SortedTwigNodesDownMap.get(mainParentId) || [];
                twigs.push(node);
                SortedTwigNodesDownMap.set(mainParentId, twigs);
            }
        }
    });
    // -- 重新排序 --
    const compareMainRouteUp = (a, b) => {
        // if (
        //   a._routesDataUp[0]
        //   && a._routesDataUp[0]['routes-of-stock-detail']
        //   && a._routesDataUp[0]['routes-of-stock-detail'][0]
        //   && b._routesDataUp[0]
        //   && b._routesDataUp[0]['routes-of-stock-detail']
        //   && b._routesDataUp[0]['routes-of-stock-detail'][0]
        //   && a._routesDataUp[0]['routes-of-stock-detail'][0] > b._routesDataUp[0]['routes-of-stock-detail'][0]
        // ) {
        if (a._mainRouteStockDetail > b._mainRouteStockDetail) {
            return true;
        }
        return false;
    };
    const compareMainRouteDown = (a, b) => {
        // if (
        //   a._routesDataDown[0]
        //   && a._routesDataDown[0]['routes_to_downs-of-stock-detail']
        //   && a._routesDataDown[0]['routes_to_downs-of-stock-detail'][a._routesDataDown[0]['routes_to_downs-of-stock-detail'].length - 1]
        //   && b._routesDataDown[0]
        //   && b._routesDataDown[0]['routes_to_downs-of-stock-detail']
        //   && b._routesDataDown[0]['routes_to_downs-of-stock-detail'][b._routesDataDown[0]['routes_to_downs-of-stock-detail'].length - 1]
        //   && (
        //     a._routesDataDown[0]['routes_to_downs-of-stock-detail'][a._routesDataDown[0]['routes_to_downs-of-stock-detail'].length - 1]
        //       >
        //     b._routesDataDown[0]['routes_to_downs-of-stock-detail'][b._routesDataDown[0]['routes_to_downs-of-stock-detail'].length - 1]
        //   )
        // ) {
        if (a._mainRouteStockDetail > b._mainRouteStockDetail) {
            return true;
        }
        return false;
    };
    // -- 重新排序 --
    Array.from(SortedTwigNodesUpMap, ([id, twigs]) => {
        twigs = twigs
            .sort((a, b) => {
            // 優先排序法人
            if (a.nodeType === '法人' && b.nodeType !== '法人') {
                return -1;
            }
            else if (a.nodeType !== '法人' && b.nodeType === '法人') {
                return 1;
            }
            // 次要排序值較大的
            else if (compareMainRouteUp(a, b)) {
                return -1;
            }
            else if (compareMainRouteUp(b, a)) {
                return 1;
            }
            // 第三排序首字筆劃
            else {
                // debugger
                // const aEndName = a._routesDataUp[0]['routes-in-name'][0].split('->')[1]
                // const bEndName = b._routesDataUp[0]['routes-in-name'][0].split('->')[1]
                const aEndName = a.label;
                const bEndName = b.label;
                for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                    if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                        return -1;
                    }
                    else if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                        return 1;
                    }
                }
            }
            return 1;
        });
        SortedTwigNodesUpMap.set(id, twigs);
    });
    Array.from(SortedTwigNodesDownMap, ([id, twigs]) => {
        twigs = twigs
            .sort((a, b) => {
            // 優先排序法人
            if (a.nodeType === '法人' && b.nodeType !== '法人') {
                return -1;
            }
            else if (a.nodeType !== '法人' && b.nodeType === '法人') {
                return 1;
            }
            // 次要排序值較大的
            else if (compareMainRouteDown(a, b)) {
                return -1;
            }
            else if (compareMainRouteDown(b, a)) {
                return 1;
            }
            // 第三排序首字筆劃
            else {
                const aEndName = a.label;
                const bEndName = b.label;
                for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                    if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                        return -1;
                    }
                    else if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                        return 1;
                    }
                }
            }
            return 1;
        });
        SortedTwigNodesDownMap.set(id, twigs);
    });
    return { SortedTwigNodesUpMap, SortedTwigNodesDownMap };
};
const calcNodesAndEdgesOfRoutesSet = (treeNodes) => {
    // -- 計算 routeData相關資料 (_NodesOfRoutesSet, _EdgesOfRoutesSet) --
    const makeRoutesUpSet = (_routesDataUp) => {
        const _NodesOfRoutesSet = new Set();
        const _EdgesOfRoutesSet = new Set();
        _routesDataUp.forEach(route => {
            route['routes-in-id'].forEach(id => {
                _EdgesOfRoutesSet.add(id);
                const nodeIds = id.split('->');
                if (nodeIds[0]) {
                    _NodesOfRoutesSet.add(nodeIds[0]);
                    if (nodeIds[1]) {
                        _NodesOfRoutesSet.add(nodeIds[1]);
                    }
                }
            });
        });
        return { _NodesOfRoutesSet, _EdgesOfRoutesSet };
    };
    const makeRoutesDownSet = (_routesDataDown) => {
        const _NodesOfRoutesSet = new Set();
        const _EdgesOfRoutesSet = new Set();
        _routesDataDown.forEach(route => {
            route['routes_to_downs-in-id'].forEach(id => {
                _EdgesOfRoutesSet.add(id);
                const nodeIds = id.split('->');
                if (nodeIds[0]) {
                    _NodesOfRoutesSet.add(nodeIds[0]);
                    if (nodeIds[1]) {
                        _NodesOfRoutesSet.add(nodeIds[1]);
                    }
                }
            });
        });
        return { _NodesOfRoutesSet, _EdgesOfRoutesSet };
    };
    treeNodes.forEach(node => {
        const makeRoutesSet = (_node) => {
            return _node._mainRelatedToRoot === 'up' ? makeRoutesUpSet(node._routesDataUp)
                : _node._mainRelatedToRoot === 'down' ? makeRoutesDownSet(node._routesDataDown)
                    : { _NodesOfRoutesSet: new Set(), _EdgesOfRoutesSet: new Set() };
        };
        const { _NodesOfRoutesSet, _EdgesOfRoutesSet } = makeRoutesSet(node);
        node._NodesOfRoutesSet = _NodesOfRoutesSet;
        node._EdgesOfRoutesSet = _EdgesOfRoutesSet;
    });
};
const calcTreeNodesRowPosition = (treeNodes) => {
    // -- 計算 routeData相關資料 (_rowIndex) --
    treeNodes.forEach(node => {
        // in-place
        if (node._mainRelatedToRoot === 'up') {
            node._rowIndex = node._mainRouteUp.length;
        }
        else if (node._mainRelatedToRoot === 'down') {
            node._rowIndex = -node._mainRouteDown.length;
        }
        else if (node._mainRelatedToRoot === 'root') {
            node._rowIndex = 0;
        }
        // node.y = - TREE_FULL_ROW_HEIGHT * node._rowIndex // @Q@ 無通道的計算
    });
};
const calcTreeNodesBranchPosition = (treeNodes, TreeNodesMap, SortedTwigNodesUpMap, SortedTwigNodesDownMap) => {
    // -- 計算branch相關資料 (_branchUp, _branchDown) --
    const makeBranch = (twigs, branchKey) => {
        if (twigs.length == 0) {
            return {
                allColumns: 1,
                centerSeq: 0,
                leftColumns: 0
            };
        }
        let centerSeq = Math.floor((twigs.length - 1) / 2);
        let leftColumns = 0;
        let rightColumns = 0;
        // 公式：SUM(支節點左W) + 支節點中L
        for (let i = 0; i < centerSeq; i++) {
            leftColumns += twigs[i][branchKey].allColumns;
        }
        leftColumns += twigs[centerSeq][branchKey].leftColumns;
        for (let i = centerSeq + 1; i < twigs.length; i++) {
            rightColumns += twigs[i][branchKey].allColumns;
        }
        const centerTwigRightColumns = (twigs[centerSeq][branchKey].allColumns - twigs[centerSeq][branchKey].leftColumns - 1);
        rightColumns += centerTwigRightColumns;
        return {
            allColumns: leftColumns + 1 + rightColumns,
            centerSeq,
            leftColumns
        };
    };
    treeNodes
        // .filter(node => node._mainRelatedToRoot !== 'neibor')
        // 依 rowIndex高至低
        // 測試：[3,1,2,-1,0,-2].sort((a,b) => a >= 0 && b >= 0 ? b - a : a - b)
        // 得到 [-2, -1, 3, 2, 1, 0]
        .sort((a, b) => a._rowIndex >= 0 && b._rowIndex >= 0
        ? b._rowIndex - a._rowIndex
        : a._rowIndex - b._rowIndex)
        .forEach(node => {
        // 因為有孤兒節點，所以即使是上游節點也可能會有下游分支
        let twigsUp = SortedTwigNodesUpMap.get(node.id) ?? [];
        let twigsDown = SortedTwigNodesDownMap.get(node.id) ?? [];
        // if (node._mainRelatedToRoot === 'up') {
        //   twigsUp = SortedTwigNodesUpMap.get(node.id) ?? []
        // } else if (node._mainRelatedToRoot === 'down') {
        //   twigsDown = SortedTwigNodesDownMap.get(node.id) ?? []
        // } else if (node._mainRelatedToRoot === 'root') {
        //   twigsUp = SortedTwigNodesUpMap.get(node.id) ?? []
        //   twigsDown = SortedTwigNodesDownMap.get(node.id) ?? []
        // }
        // in-place
        node._branchUp = makeBranch(twigsUp, '_branchUp');
        node._branchDown = makeBranch(twigsDown, '_branchDown');
    });
};
const setTreeNodesTwigsAndTrunk = (treeNodes, TreeNodesMap, SortedTwigNodesUpMap, SortedTwigNodesDownMap) => {
    // -- 紀錄排序 (_seq, _twigs, _trunkNode) --
    const setTwigsInfo = ([id, twigs]) => {
        const trunkNode = TreeNodesMap.get(id);
        if (trunkNode) {
            trunkNode._twigNodes = twigs;
        }
        twigs.forEach((d, i) => {
            d._seq = i;
            d._trunkNode = TreeNodesMap.get(id);
        });
    };
    Array.from(SortedTwigNodesUpMap).forEach(setTwigsInfo);
    Array.from(SortedTwigNodesDownMap).forEach(setTwigsInfo);
};
const adoptOrphan = (treeNodes, TreeNodesMap, SortedTwigNodesDownMap, OrphanBranchesMap) => {
    const findAdoptiveParentRoute = (orphan) => {
        const routes = orphan._routesDataDown;
        for (let i in routes) {
            if (routes[i]['routes_to_downs-in-id'].length) {
                const parentNodeId = routes[i]['routes_to_downs-in-id'][routes[i]['routes_to_downs-in-id'].length - 1].split('->')[0];
                const parentNode = TreeNodesMap.get(parentNodeId);
                // 母節點也在下游即可認養
                if (parentNode._mainRelatedToRoot === 'down') {
                    return orphan._routesDataDown[i];
                }
            }
        }
        return undefined;
    };
    // debugger
    let adoptedNodes = [];
    Array.from(OrphanBranchesMap, ([orphanId]) => {
        const orphan = TreeNodesMap.get(orphanId);
        const adoptiveParentRoute = findAdoptiveParentRoute(orphan);
        // 有人認養
        if (adoptiveParentRoute) {
            // in-place
            orphan._mainRouteDown = adoptiveParentRoute['routes_to_downs-in-id'];
            orphan._mainRouteStockDetail = adoptiveParentRoute['routes_to_downs-of-stock-detail'][adoptiveParentRoute['routes_to_downs-of-stock-detail'].length - 1];
            const trunkNodeId = orphan._mainRouteDown[orphan._mainRouteDown.length - 1] ? orphan._mainRouteDown[orphan._mainRouteDown.length - 1].split('->')[0] : '';
            orphan._trunkNode = TreeNodesMap.get(trunkNodeId);
            // console.log(orphan)
            // OrphanBranchesMap.delete(orphanId)
            adoptedNodes.push(orphan);
        }
    });
    // 尚未被認養的孤兒
    return adoptedNodes;
};
const calcTreeNodesColumnPosition = (treeNodes, TreeNodesMap, SortedTwigNodesUpMap, SortedTwigNodesDownMap) => {
    // -- 計算column位置 (_columnIndex) --
    // 目前treeNodes資料的Map
    // const TempTreeNodesMap = new Map(treeNodes.map(node => [node.id, node]))
    const makeColumnPosition = (node) => {
        if (node._mainRelatedToRoot === 'root' || node._mainRelatedToRoot === 'neibor') {
            // return {
            //   _columnIndex: 0,
            //   x: TREE_FULL_COLUMN_WIDTH * 0
            // }
            return 0;
        }
        let branchKey = '_branchUp';
        let siblings = [];
        let trunkNode = TreeNodesMap.get(node._trunkNode.id);
        if (node._mainRelatedToRoot === 'up') {
            // 同階層節點
            siblings = SortedTwigNodesUpMap.get(node._trunkNode.id) ?? [];
        }
        else if (node._mainRelatedToRoot === 'down') {
            // 同階層節點
            siblings = SortedTwigNodesDownMap.get(node._trunkNode.id) ?? [];
            branchKey = '_branchDown';
        }
        let sumLeftColumns = 0;
        for (let i = 0; i < node._seq; i++) {
            sumLeftColumns += siblings[i][branchKey].allColumns;
        }
        // 公式：x = 幹節點x - 幹節點L + SUM(左W) + L
        // if (node.id == '52659672' || node.id == '27536370') {
        //   console.log('重疊node', node)
        //   debugger
        // }
        const _columnIndex = trunkNode._columnIndex - trunkNode[branchKey].leftColumns + sumLeftColumns + node[branchKey].leftColumns;
        // @Q@ 無通道的計算
        // return {
        //   _columnIndex,
        //   x: TREE_FULL_COLUMN_WIDTH * _columnIndex // @Q@ 無通道的計算
        // }
        return _columnIndex;
    };
    treeNodes
        // .filter(node => node._mainRelatedToRoot !== 'neibor')
        // 依 rowIndex低至高
        // 測試：[3,1,2,-1,0,-2].sort((a,b) => a >= 0 && b >= 0 ? a - b : b - a)
        // 得到 [0, 1, 2, 3, -1, -2]
        .sort((a, b) => a._rowIndex >= 0 && b._rowIndex >= 0
        ? a._rowIndex - b._rowIndex
        : b._rowIndex - a._rowIndex)
        .forEach(node => {
        // const { _columnIndex, x } = makeColumnPosition(node)
        node._columnIndex = makeColumnPosition(node);
        // node.x = x
    });
};
const getNeighborStartColumn = (treeNodes) => {
    const minColumnIndex = treeNodes
        .filter(d => d._mainRelatedToRoot !== 'neibor')
        .reduce((acc, current) => {
        return current._columnIndex < acc ? current._columnIndex : acc;
    }, 0);
    return minColumnIndex - 1;
};
const calcNeighborNodesPosition = (treeNodes, startColumnIndex, TreeEdgesCurrentStartMap, TreeEdgesCurrentEndMap) => {
    // 親戚節點
    const neighborNodes = treeNodes.filter(d => d._mainRelatedToRoot === 'neibor');
    // console.log(neighborNodes)
    if (!neighborNodes.length) {
        return;
    }
    // 流入流出的數量
    const NodeAmountMap = new Map(treeNodes.map(node => {
        const start = TreeEdgesCurrentStartMap.get(node.id)?.length ?? 0;
        const end = TreeEdgesCurrentEndMap.get(node.id)?.length ?? 0;
        const both = start + end;
        return [node.id, { start, end, both }];
    }));
    // 排序親戚節點
    neighborNodes.sort((a, b) => {
        const aAmount = NodeAmountMap.get(a.id);
        const bAmount = NodeAmountMap.get(b.id);
        // 第1排序：姻親節點流入、流出數量加總數量由大到小
        if (aAmount.both > bAmount.both) {
            return -1;
        }
        else if (aAmount.both < bAmount.both) {
            return 1;
        }
        // 第2排序：姻親節點流入數量由大到小
        else if (aAmount.end > bAmount.end) {
            return -1;
        }
        else if (aAmount.end < bAmount.end) {
            return 1;
        }
        // 第3排序：首字筆劃由小到大，如相同則比較第二個字筆劃、以此類推。
        else {
            const aEndName = a.label;
            const bEndName = b.label;
            for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                    return -1;
                }
                else if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                    return 1;
                }
            }
        }
        return -1;
    });
    // console.log(neighborNodes)
    // 親戚節點計算公式
    const makeCalcPosition = (nodeAmount) => {
        const getLevel = (seq) => Math.ceil(Math.sqrt(seq));
        const maxLevel = getLevel(nodeAmount);
        const LevelInfoMap = new Map();
        for (let level = 1; level <= maxLevel; level++) {
            LevelInfoMap.set(level, {
                start: Math.pow(level, 2) - (2 * level) + 2,
                middle: Math.pow(level, 2) - level + 1,
                end: Math.pow(level, 2)
            });
        }
        return (i) => {
            const seq = i + 1; // 從1起算
            const level = getLevel(seq);
            const levelInfo = LevelInfoMap.get(level);
            let p = [];
            if (seq == levelInfo.middle) {
                p = [-level, -level];
            }
            else if (seq < levelInfo.middle) {
                p = [-level, -(seq - levelInfo.start + 1)];
            }
            else {
                p = [-(levelInfo.end - seq + 1), -level];
            }
            return [p[0] + 1, p[1] + 1]; // 修正為起點為 (0,0)的相對座標
        };
    };
    const calcPosition = makeCalcPosition(neighborNodes.length);
    const maxRowIndex = calcPosition(neighborNodes.length - 1)[1];
    const startRowIndex = Math.floor((maxRowIndex) / 2);
    neighborNodes.forEach((node, i) => {
        const [columnIndex, rowIndex] = calcPosition(i);
        // console.log([columnIndex, rowIndex])
        // in-place
        node._columnIndex = startColumnIndex + columnIndex;
        node._rowIndex = startRowIndex + rowIndex;
    });
};
const makeColumnsAndRowsData = (treeNodes, TreeEdgesCurrentStartMap, TreeEdgesCurrentEndMap) => {
    const ColumnsMap = new Map();
    const RowsMap = new Map();
    const treeFullGridPosition = {
        minColumnIndex: 0,
        maxColumnIndex: 0,
        minRowIndex: 0,
        maxRowIndex: 0,
        // neighbor: {
        //   startColumnIndex: 0,
        //   startRowIndex: 0
        // }
    };
    treeNodes.forEach(node => {
        // columns
        const treeFullColumn = ColumnsMap.get(node._columnIndex)
            ?? {
                columnIndex: node._columnIndex,
                x: 0,
                lineStartNodes: [],
                lineXs: [],
                // edges: [] // * 後面程式處理
                nodeMaxWidth: 0 // * 後面程式處理
            };
        treeFullColumn.nodeMaxWidth = node.width > treeFullColumn.nodeMaxWidth ? node.width : treeFullColumn.nodeMaxWidth;
        const currentStartEdges = TreeEdgesCurrentStartMap.get(node.id);
        if (currentStartEdges && currentStartEdges.length) {
            treeFullColumn.lineStartNodes.push(node);
        }
        // rows
        const treeFullRow = RowsMap.get(node._rowIndex)
            ?? {
                rowIndex: node._rowIndex,
                y: 0,
                lineEndNodes: [],
                lineYs: [],
                // edges: [] // * 後面程式處理
                nodeMaxHeight: 0 // * 後面程式處理
            };
        treeFullRow.nodeMaxHeight = node.height > treeFullRow.nodeMaxHeight ? node.height : treeFullRow.nodeMaxHeight;
        if (TreeEdgesCurrentEndMap.get(node.id)) {
            treeFullRow.lineEndNodes.push(node);
        }
        ColumnsMap.set(node._columnIndex, treeFullColumn);
        RowsMap.set(node._rowIndex, treeFullRow);
        treeFullGridPosition.minColumnIndex = node._columnIndex < treeFullGridPosition.minColumnIndex ? node._columnIndex : treeFullGridPosition.minColumnIndex;
        treeFullGridPosition.maxColumnIndex = node._columnIndex > treeFullGridPosition.maxColumnIndex ? node._columnIndex : treeFullGridPosition.maxColumnIndex;
        treeFullGridPosition.minRowIndex = node._rowIndex < treeFullGridPosition.minRowIndex ? node._rowIndex : treeFullGridPosition.minRowIndex;
        treeFullGridPosition.maxRowIndex = node._rowIndex > treeFullGridPosition.maxRowIndex ? node._rowIndex : treeFullGridPosition.maxRowIndex;
    });
    // -- 補齊孤兒節點可能造成的空缺（下游的列） --
    if (treeFullGridPosition.minRowIndex < 0) {
        for (let i = -1; i >= treeFullGridPosition.minRowIndex; i--) {
            if (!RowsMap.get(i)) {
                RowsMap.set(i, {
                    rowIndex: i,
                    y: 0,
                    lineEndNodes: [],
                    lineYs: [],
                    nodeMaxHeight: NODE_HEIGHT_MIN
                });
            }
        }
    }
    // -- 重新排序 --
    Array.from(ColumnsMap, ([index, columns]) => {
        // 左至右
        columns.lineStartNodes.sort((a, b) => a._rowIndex - b._rowIndex);
        // ColumnsMap.set(index, columns)
    });
    Array.from(RowsMap, ([index, rows]) => {
        // 下至上
        rows.lineEndNodes.sort((a, b) => a._columnIndex - b._columnIndex);
        // RowsMap.set(index, rows)
    });
    return { ColumnsMap, RowsMap, treeFullGridPosition };
};
const calcWayPosition = (ColumnsMap, RowsMap, treeFullGridPosition) => {
    // -- 計算座標 --
    const calcWayColumnWidth = (count) => count * WAY_COLUMN_LINE_STEP;
    const calcLinesXPosition = (firstX, nodeAmount) => {
        let arr = [];
        for (let i = 0; i < nodeAmount; i++) {
            arr.push(firstX + calcWayColumnWidth(i));
        }
        return arr;
    };
    const calcColumnStep = (nodeMaxWidth) => TREE_FULL_COLUMN_WIDTH - (NODE_WIDTH_DEFAULT - nodeMaxWidth);
    const calcWayRowHeight = (count) => count * WAY_ROW_LINE_STEP;
    const calcLinesYPosition = (firstY, nodeAmount) => {
        let arr = [];
        for (let i = 0; i < nodeAmount; i++) {
            arr.push(firstY + calcWayRowHeight(i));
        }
        return arr;
    };
    const calcRowStep = (nodeMaxHeight) => TREE_FULL_ROW_HEIGHT + (nodeMaxHeight - NODE_HEIGHT_MIN);
    // column = 0
    const column0 = ColumnsMap.get(0);
    column0.x = 0;
    const wayColumn0Width = calcWayColumnWidth(column0.lineStartNodes.length);
    column0.lineXs = calcLinesXPosition(column0.x - wayColumn0Width, column0.lineStartNodes.length);
    // column > 0
    if (treeFullGridPosition.maxColumnIndex > 0) {
        for (let i = 1; i <= treeFullGridPosition.maxColumnIndex; i++) {
            const column = ColumnsMap.get(i);
            let leftColumn;
            for (let j = i - 1; j >= treeFullGridPosition.minColumnIndex; j--) {
                leftColumn = ColumnsMap.get(j);
                if (leftColumn) {
                    break;
                }
            }
            if (!column) {
                continue;
            }
            const wayWidth = calcWayColumnWidth(column.lineStartNodes.length);
            column.x = leftColumn.x + calcColumnStep(leftColumn.nodeMaxWidth) + wayWidth;
            column.lineXs = calcLinesXPosition(column.x - wayWidth, column.lineStartNodes.length);
        }
    }
    // column < 0
    if (treeFullGridPosition.minColumnIndex < 0) {
        for (let i = -1; i >= treeFullGridPosition.minColumnIndex; i--) {
            const column = ColumnsMap.get(i);
            let rightColumn;
            for (let j = i + 1; j <= treeFullGridPosition.maxColumnIndex; j++) {
                rightColumn = ColumnsMap.get(j);
                if (rightColumn) {
                    break;
                }
            }
            if (!column) {
                continue;
            }
            const wayWidth = calcWayColumnWidth(column.lineStartNodes.length);
            column.x = rightColumn.x - calcColumnStep(column.nodeMaxWidth) - calcWayColumnWidth(rightColumn.lineStartNodes.length);
            column.lineXs = calcLinesXPosition(column.x - wayWidth, column.lineStartNodes.length);
        }
    }
    // row = 0
    const row0 = RowsMap.get(0);
    row0.y = 0;
    const wayRow0Height = calcWayRowHeight(row0.lineEndNodes.length);
    row0.lineYs = calcLinesYPosition(row0.y - wayRow0Height - (row0.nodeMaxHeight / 2), row0.lineEndNodes.length);
    // row > 0
    if (treeFullGridPosition.maxRowIndex > 0) {
        for (let i = 1; i <= treeFullGridPosition.maxRowIndex; i++) {
            const row = RowsMap.get(i);
            const downRow = RowsMap.get(i - 1);
            const wayHeight = calcWayRowHeight(row.lineEndNodes.length);
            // // @Q@ 孤兒節點
            // if (!row || !downRow) {
            //   // const topRow = RowsMap.get(treeFullGridPosition.maxRowIndex)!
            //   // row.y = topRow.y - TREE_FULL_ROW_HEIGHT
            //   continue
            // }
            row.y = downRow.y - calcRowStep(downRow.nodeMaxHeight) - calcWayRowHeight(downRow.lineEndNodes.length);
            row.lineYs = calcLinesYPosition(row.y - wayHeight - (row.nodeMaxHeight / 2), row.lineEndNodes.length);
        }
    }
    // row < 0
    if (treeFullGridPosition.minRowIndex < 0) {
        for (let i = -1; i >= treeFullGridPosition.minRowIndex; i--) {
            const row = RowsMap.get(i);
            const upRow = RowsMap.get(i + 1);
            const wayHeight = calcWayRowHeight(row.lineEndNodes.length);
            // // @Q@ 孤兒節點
            // if (!row || !upRow) {
            //   continue
            // }
            row.y = upRow.y + calcRowStep(upRow.nodeMaxHeight) + calcWayRowHeight(row.lineEndNodes.length);
            row.lineYs = calcLinesYPosition(row.y - wayHeight - (row.nodeMaxHeight / 2), row.lineEndNodes.length);
        }
    }
};
const setNodePosition = (treeNodesCurrent, ColumnsMap, RowsMap) => {
    treeNodesCurrent.forEach(node => {
        const column = ColumnsMap.get(node._columnIndex);
        const row = RowsMap.get(node._rowIndex);
        node.x = column.x;
        node.y = row.y;
    });
};
const getOrphanBranches = (treeNodesCurrent) => {
    const orphans = treeNodesCurrent
        .filter(node => node._mainRelatedToRoot === 'down'
        && node._trunkNode
        && node._trunkNode._mainRelatedToRoot === 'up');
    const OrphanBranchesMap = new Map();
    const setOrphansBranch = (branchRootId, node) => {
        // if (branchRootId == '16449764' || branchRootId == '54376691') {
        //   debugger
        // }
        // in-place
        node._inOrphanBranch = true;
        const TwigNodesSet = OrphanBranchesMap.get(branchRootId) || new Set();
        TwigNodesSet.add(branchRootId);
        node._twigNodes.forEach((n) => TwigNodesSet.add(n.id));
        OrphanBranchesMap.set(branchRootId, TwigNodesSet);
        node._twigNodes.forEach((n) => setOrphansBranch(branchRootId, n));
    };
    orphans.forEach(node => setOrphansBranch(node.id, node));
    return OrphanBranchesMap;
};
const modifyOrphansPosition = (TreeNodesMap, OrphanBranchesMap) => {
    Array.from(OrphanBranchesMap, ([id, orphanBranch]) => {
        const orphan = TreeNodesMap.get(id);
        const distance = Math.abs(orphan._rowIndex);
        // 找到所有（最近距離的）母節點
        const parentNodes = orphan._routesDataDown
            .filter(r => r['routes_to_downs-in-id'].length == distance)
            .map(r => {
            const parent = r['routes_to_downs-in-id'][r['routes_to_downs-in-id'].length - 1].split('->')[0];
            return TreeNodesMap.get(parent);
        });
        // 從母節點中找到離根節點最近的母節點 _columnIndex
        let nearlestColumnIndex = orphan._columnIndex;
        if (parentNodes.length > 0) {
            for (let i = 0; i < parentNodes.length; i++) {
                if (Math.abs(parentNodes[i]._columnIndex) < Math.abs(nearlestColumnIndex)) {
                    nearlestColumnIndex = parentNodes[i]._columnIndex;
                }
            }
        }
        // in-place
        orphan._columnIndex = nearlestColumnIndex;
    });
};
const shiftConflictOrphans = (treeNodesCurrent, TreeNodesMap, OrphanBranchesMap) => {
    // 建立排序過的節點資料 Array<[分支根節點node, Map<分支節點id, 分支節點node>]>
    const OrphanBranchesList = Array.from(OrphanBranchesMap)
        .map(([id, NodeSet]) => {
        return [
            TreeNodesMap.get(id),
            new Map(Array.from(NodeSet)
                .map(id => [id, TreeNodesMap.get(id)]))
        ];
    })
        // 從左至右
        .sort(([aNode, aSet], [bNode, bSet]) => aNode._columnIndex - bNode._columnIndex);
    // debugger
    // 計算孤兒是否和其他節點重疊
    const isOverlap = (_treeNodesCurrent, OrphanBranchNodesMap) => {
        const OrphanGridMap = new Map();
        const DownTreeGridMap = new Map();
        _treeNodesCurrent.forEach(node => {
            if (OrphanBranchNodesMap.has(node.id)) {
                OrphanGridMap.set(JSON.stringify([node._columnIndex, node._rowIndex]), node);
            }
            else {
                DownTreeGridMap.set(JSON.stringify([node._columnIndex, node._rowIndex]), node);
            }
        });
        for (const key of DownTreeGridMap.keys()) {
            if (OrphanGridMap.has(key)) {
                return true;
            }
        }
        return false;
    };
    // // 計算孤兒是否和其他節點重疊
    // const isOverlap = (DownTreeGridMap: Map<string, TreeFullNodeDatum>, OrphanBranchNodesMap: Map<string, TreeFullNodeDatum>) => {
    //   Array.from(OrphanBranchNodesMap).forEach(([str, node]) => {
    //   })
    //   return false
    // }
    // 移動孤兒的分支節點
    const shiftNodes = (branchRootNode, _OrphanBranchNodesMap, footPrint) => {
        // 尋找下一個移動的點
        const findNextFootPrint = (_footPrint) => {
            _footPrint.sort((a, b) => Math.abs(a) - Math.abs(b));
            const FootPrintMap = new Map(_footPrint.map(d => [d, true]));
            // 從0開始，找到離0最近的尚未走過的index (0, -1, 1, -2, 2, -3, 3, ...)
            let nearlestIndex = 0;
            while (FootPrintMap.get(nearlestIndex) != undefined) {
                nearlestIndex *= -1;
                if (nearlestIndex <= 0) {
                    nearlestIndex -= 1;
                }
            }
            return nearlestIndex;
        };
        const nextFootPrint = findNextFootPrint(footPrint);
        // 移動方式
        let shift = nextFootPrint - branchRootNode._columnIndex;
        Array.from(_OrphanBranchNodesMap, ([id, node]) => {
            // console.log('移動', node, node._columnIndex, node._columnIndex + shift)
            node._columnIndex = node._columnIndex + shift;
        });
        return nextFootPrint;
    };
    // const DownTreeGridMap: Map<string, TreeFullNodeDatum> = new Map(
    //   treeNodesCurrent.map(node => {
    //     return [JSON.stringify([node._columnIndex, node._rowIndex]), node]
    //   })
    // )
    for (let [branchRootNode, OrphanBranchNodesMap] of OrphanBranchesList) {
        // console.log([branchRootNode, OrphanBranchNodesMap])
        // 如發生衝突則移動位置
        // if (branchRootNode.id == '16449764' || branchRootNode.id == '54376691') {
        //   debugger
        // }
        const shiftNodesWhileOverlap = (footPrint) => {
            if (isOverlap(treeNodesCurrent, OrphanBranchNodesMap) === true) {
                const newFootPrint = shiftNodes(branchRootNode, OrphanBranchNodesMap, footPrint);
                footPrint.push(newFootPrint);
                shiftNodesWhileOverlap(footPrint);
            }
        };
        shiftNodesWhileOverlap([branchRootNode._columnIndex]);
    }
};
// const makeWayData = ({ TreeNodesMap, ColumnsMap, RowsMap, minColumnIndex, maxColumnIndex, minRowIndex, maxRowIndex }: {
//   TreeNodesMap: Map<string, TreeFullNodeDatum>
//   ColumnsMap: Map<number, TreeFullColumn>
//   RowsMap: Map<number, TreeFullRow>
//   minColumnIndex: number
//   maxColumnIndex: number
//   minRowIndex: number
//   maxRowIndex: number
// }) => {
//   const VWayMap: Map<number, TreeFullVWay> = new Map()
//   const HWayMap: Map<number, TreeFullHWay> = new Map()
//   let accumulate = 0
//   const columns = ColumnsMap.get(0) ?? []
//   VWayMap.set(0, {
//     vWayIndex: 0,
//     x: - (TREE_FULL_COLUMN_WIDTH)
//     width: number
//     startNodes: TreeFullNodeDatum[]
//     lineXs: number[]
//     edges: [] // * 後面程式處理
//   })
//   if (maxColumnIndex > 0) {
//     for (let i = 1; i <= maxColumnIndex; i++) {
//       const columns = ColumnsMap.get(i)
//       if (!columns || !columns.length) {
//         continue
//       }
//     }
//   }
//   accumulate = 0
//   if (minColumnIndex < 0) {
//     for (let i = -1; i >= minColumnIndex; i--) {
//     }
//   }
// }
// 取得edge所需要的值
const getEdgePathValues = (edge, column, row, curve) => {
    const VWayX = column.lineXs[column.lineStartNodes.findIndex(d => d.id === edge.startNodeId)];
    const HWayY = row.lineYs[row.lineEndNodes.findIndex(d => d.id === edge.endNodeId)];
    let pathD = `M${edge.startX} ${edge.startY} H${VWayX + curve}`; // 橫直線
    let labelX = VWayX;
    let labelY = HWayY;
    let labelTextAnchor = 'end';
    let labelDominantBaseline = 'auto';
    // 第一個轉彎 & 垂直通道的線
    if (edge._endNode._rowIndex >= edge._startNode._rowIndex) {
        labelDominantBaseline = 'hanging';
        pathD += ` Q${VWayX} ${edge.startY}, ${VWayX} ${edge.startY - curve}`; // 弧線
        pathD += ` V${HWayY + curve}`; // 垂直線
    }
    else {
        labelDominantBaseline = 'auto';
        pathD += ` Q${VWayX} ${edge.startY}, ${VWayX} ${edge.startY + curve}`; // 弧線
        pathD += ` V${HWayY - curve}`; // 垂直線
    }
    // 第二個轉彎 & 水平通道的線
    if (edge._endNode._rowIndex >= edge._startNode._rowIndex && edge._endNode._columnIndex < edge._startNode._columnIndex) {
        pathD += ` Q${VWayX} ${HWayY}, ${VWayX - curve} ${HWayY}`; // 弧線
        pathD += ` H${edge.endX + curve}`; // 橫直線
    }
    else if (edge._endNode._rowIndex >= edge._startNode._rowIndex && edge._endNode._columnIndex >= edge._startNode._columnIndex) {
        pathD += ` Q${VWayX} ${HWayY}, ${VWayX + curve} ${HWayY}`; // 弧線
        pathD += ` H${edge.endX - curve}`; // 橫直線
    }
    else if (edge._endNode._rowIndex < edge._startNode._rowIndex && edge._endNode._columnIndex < edge._startNode._columnIndex) {
        pathD += ` Q${VWayX} ${HWayY}, ${VWayX - curve} ${HWayY}`; // 弧線
        pathD += ` H${edge.endX + curve}`; // 橫直線
    }
    else if (edge._endNode._rowIndex < edge._startNode._rowIndex && edge._endNode._columnIndex >= edge._startNode._columnIndex) {
        pathD += ` Q${VWayX} ${HWayY}, ${VWayX + curve} ${HWayY}`; // 弧線
        pathD += ` H${edge.endX - curve}`; // 橫直線
    }
    // 第三個轉彎 & 垂直線
    if (edge._endNode._columnIndex >= edge._startNode._columnIndex) {
        labelTextAnchor = 'start';
        pathD += ` Q${edge.endX} ${HWayY}, ${edge.endX} ${HWayY + curve}`; // 弧線
        pathD += ` V${edge.endY}`; // 垂直線
    }
    else {
        labelTextAnchor = 'end';
        pathD += ` Q${edge.endX} ${HWayY}, ${edge.endX} ${HWayY + curve}`; // 弧線
        pathD += ` V${edge.endY}`; // 垂直線
    }
    return {
        pathD,
        labelX,
        labelY,
        labelTextAnchor,
        labelDominantBaseline
    };
};
// 計算 edgeData座標及相關資訊
const calcTreeEdgesPosition = ({ treeEdgesCurrent, TreeNodesCurrentMap, TreeEdgesCurrentStartMap, TreeEdgesCurrentEndMap, ColumnsMap, RowsMap }) => {
    treeEdgesCurrent = treeEdgesCurrent
        .map(d => {
        const startNode = TreeNodesCurrentMap.get(d.startNodeId);
        const endNode = TreeNodesCurrentMap.get(d.endNodeId);
        const currentStartEdges = TreeEdgesCurrentStartMap.get(d.startNodeId) ?? [];
        const currentEndEdges = TreeEdgesCurrentEndMap.get(d.endNodeId) ?? [];
        const column = ColumnsMap.get(startNode._columnIndex);
        const row = RowsMap.get(endNode._rowIndex);
        const startX = startNode.x;
        const startY = startNode.y;
        const endX = endNode.x + (endNode.width / 2);
        const endY = endNode.y - (endNode.height / 2);
        // const vDistance = Math.abs(startNode.y - endNode.y) // 垂直距離
        // const curve = vDistance > EDGE_CURVE ? EDGE_CURVE : vDistance // 弧線大小
        const curve = EDGE_CURVE;
        d.startX = startX;
        d.startY = startY;
        d.endX = endX;
        d.endY = endY;
        // 計算線條路徑及label座標
        const { labelX, labelY, pathD, labelTextAnchor, labelDominantBaseline } = getEdgePathValues(d, column, row, curve);
        d.startCount = currentStartEdges.length;
        d.startCountX = startX;
        d.startCountY = startY;
        d.endCount = currentEndEdges.length;
        d.endCountX = endX;
        d.endCountY = endY - 15;
        d.label;
        d.labelX = labelX;
        d.labelY = labelY;
        d.labelTransformX = labelTextAnchor === 'start' ? 6 : -6;
        d.labelTransformY = labelDominantBaseline === 'hanging' ? 2 : -2;
        d.labelDotShow = false;
        d.labelTextAnchor = labelTextAnchor;
        d.labelDominantBaseline = labelDominantBaseline;
        d.pathD = pathD;
        return d;
    });
    return treeEdgesCurrent;
};
export default class ChartScoutRouteTree2 extends ChartScoutRoute {
    selection;
    params = CHART_SCOUT_ROUTE_TREE2_PARAMS;
    dataset = {
        nodes: [],
        edges: [],
        rootId: ''
    };
    filteredDataset = {
        nodes: [],
        edges: [],
        rootId: '',
    };
    filterConfig = {
        stockMin: DEFAULT_STOCK_MIN,
        stockExceptZero: DEFAULT_STOCK_EXCEPT_ZERO
    };
    TreeNodesCurrentMap;
    TreeEdgesCurrentMap;
    TreeEdgesCurrentStartMap; // 用 start-node-id當key
    TreeEdgesCurrentEndMap; // 用 end-node-id當key
    treeNodesFiltered;
    treeEdgesFiltered;
    treeNodesCurrent;
    treeEdgesCurrent;
    SortedTwigNodesUpMap = new Map(); // 排序過的父nodes
    SortedTwigNodesDownMap = new Map(); // 排序過的父nodes
    BranchNodesMap = new Map(); // 祖先nodes對應
    ColumnsMap = new Map();
    RowsMap = new Map();
    // private orphanBranches: Array<Map<[x: string, y: string], TreeFullNodeDatum>> = []
    // private VWayMap: Map<number, TreeFullVWay> = new Map()
    // private HWayMap: Map<number, TreeFullHWay> = new Map()
    // private minColumnIndex: number = 0
    // private maxColumnIndex: number = 0
    // private minRowIndex: number = 0
    // private maxRowIndex: number = 0
    treeFullGridPosition = {
        minColumnIndex: 0,
        maxColumnIndex: 0,
        minRowIndex: 0,
        maxRowIndex: 0,
        // neighbor: {
        //   startColumnIndex: 0,
        //   startRowIndex: 0
        // }
    };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    highlightLockMode = false; // 鎖定highlight模式
    expandMode = true; // 是否有展開按鈕
    auxiliaryColumnLinesG;
    auxiliaryRowLinesG;
    constructor(el, params) {
        super(el, params);
        this.selection = el;
        this.params = {
            ...this.params,
            ...(params ?? {})
        };
        this.initNodesAndEdges();
        this.auxiliaryColumnLinesG = this.svgGroup.insert('g', 'g');
        this.auxiliaryRowLinesG = this.svgGroup.insert('g', 'g');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        // if (params.routeHighlightId != this.params.routeHighlightId) {
        this.removeHighlight();
        if (params.routeHighlightId == undefined || params.routeHighlightId == '') {
            this.highlightLockMode = false;
            // this.highlightLockId = ''
        }
        else {
            this.highlightLockMode = true;
            // this.highlightLockId = params.routeHighlightId
            // const target = this.TreeNodesCurrentMap?.get(params.routeHighlightId)
            // if (target) {
            this.highlight(params.routeHighlightId);
            // }        
        }
        // }
        // if (params.transform != undefined) {
        //   this.transform = params.transform
        //   this.initZoom(0, this.height)      
        // }
        // 顯示輔助線
        if (params.style && params.style.auxiliaryLine) {
            this.renderAuxiliaryLines(this.ColumnsMap, this.RowsMap);
        }
        else {
            this.renderAuxiliaryLines(new Map(), new Map());
        }
        if (params.scaleExtent) {
            this.zoom.scaleExtent = params.scaleExtent;
        }
        this.initZoom();
        // ({
        //   ...this.zoom,
        //   scaleExtent: {
        //     min: (params.scaleExtent && params.scaleExtent.min) || this.params.scaleExtent!.min,
        //     max: (params.scaleExtent && params.scaleExtent.max) || this.params.scaleExtent!.max
        //   }
        // })
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    resize({ width, height }) {
        this.width = width;
        this.height = height;
        this.selection.attr('width', this.width);
        this.selection.attr('height', this.height);
        if (this.params && this.params.autoZoom) {
            this.initZoom({
                ...this.zoom,
                xOffset: this.width / 2 + MIDDLE_OFFCET_X,
                yOffset: this.height / 2
            });
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        }
    }
    setDataset(data) {
        // console.log(data)
        this.dataset = data;
        this.filterConfig.stockMin = data.stockMin != undefined ? data.stockMin : this.filterConfig.stockMin;
        this.filterConfig.stockExceptZero = data.stockExceptZero != undefined ? data.stockExceptZero : this.filterConfig.stockExceptZero;
        if (this.filterConfig.stockMin >= 0 || this.filterConfig.stockExceptZero == true) {
            const { nodes, edges } = this.makeFilteredDataset({
                nodeData: this.dataset.nodes,
                edgeData: this.dataset.edges,
                rootId: this.dataset.rootId,
                stockMin: this.filterConfig.stockMin,
                stockExceptZero: this.filterConfig.stockExceptZero
            });
            this.filteredDataset = {
                ...this.dataset,
                nodes,
                edges
            };
        }
        else {
            this.filteredDataset = {
                ...this.dataset,
                nodes: Object.assign([], this.dataset.nodes),
                edges: Object.assign([], this.dataset.edges)
            };
        }
        this.treeNodesFiltered = this.makeTreeNodes(this.filteredDataset, this.dataset.rootId);
        this.treeEdgesFiltered = this.makeTreeEdges(this.filteredDataset, this.treeNodesFiltered);
        this.treeNodesCurrent = Object.assign([], this.treeNodesFiltered);
        this.treeEdgesCurrent = Object.assign([], this.treeEdgesFiltered);
        // console.log('this.dataset', this.dataset)
        // console.log('this.filteredDataset', this.filteredDataset)
        this.initZoom({
            ...this.zoom,
            xOffset: 0,
            yOffset: 0,
        });
        // setTimeout(() => {
        //   // 初始化尺寸和 zoom
        //   this.resize({
        //     width: Number(this.selection.attr('width')),
        //     height: Number(this.selection.attr('height'))
        //   })
        //   this.initZoom(this.width / 2 + MIDDLE_OFFCET_X, this.height / 2)
        //   // 回復預設 zoom
        //   this.transformZoom({
        //     x: 0,
        //     y: 0,
        //     k: 1
        //   })
        // })
    }
    render() {
        if (!this.treeNodesCurrent) {
            return;
        }
        if (this.dataset.rootId !== this.rootId) {
            this.rootId = this.dataset.rootId;
            this.initZoom({
                ...this.zoom,
                xOffset: this.width / 2 + MIDDLE_OFFCET_X,
                yOffset: this.height / 2
            });
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        }
        this.TreeNodesCurrentMap = new Map(this.treeNodesCurrent.map(d => {
            return [d.id, d];
        }));
        this.TreeEdgesCurrentMap = new Map();
        this.TreeEdgesCurrentStartMap = new Map();
        this.TreeEdgesCurrentEndMap = new Map();
        this.treeEdgesCurrent.forEach(edge => {
            const edges = this.TreeEdgesCurrentMap.get(edge.id) ?? [];
            this.TreeEdgesCurrentMap.set(edge.id, edges);
            const startEdges = this.TreeEdgesCurrentStartMap.get(edge.startNodeId) ?? [];
            startEdges.push(edge);
            this.TreeEdgesCurrentStartMap.set(edge.startNodeId, startEdges);
            const endEdges = this.TreeEdgesCurrentEndMap.get(edge.endNodeId) ?? [];
            endEdges.push(edge);
            this.TreeEdgesCurrentEndMap.set(edge.endNodeId, endEdges);
        });
        // 1. 投資路徑上的節點和連接線 (_NodesOfRoutesSet, _EdgesOfRoutesSet)
        calcNodesAndEdgesOfRoutesSet(this.treeNodesCurrent);
        // 2~5 計算血親節點結構
        const calcTreeNodesBloodRelative = () => {
            // 2. 計算節點列位置 _rowIndex)
            calcTreeNodesRowPosition(this.treeNodesCurrent);
            // 3. 取得支節點
            const { SortedTwigNodesUpMap, SortedTwigNodesDownMap } = makeSortedTwigNodesMap(this.treeNodesCurrent);
            this.SortedTwigNodesUpMap = SortedTwigNodesUpMap;
            this.SortedTwigNodesDownMap = SortedTwigNodesDownMap;
            // 4. 取得分支節點
            // console.log('this.SortedTwigNodesDownMap', this.SortedTwigNodesDownMap)
            // this.BranchNodesMap = makeBranchNodesMap(this.SortedTwigNodesDownMap, this.rootId)
            // console.log('this.BranchNodesMap', this.BranchNodesMap)
            // 5. 計算節點twigs位置 (_seq, _twigs, _trunkNode)
            setTreeNodesTwigsAndTrunk(this.treeNodesCurrent, this.TreeNodesCurrentMap, this.SortedTwigNodesUpMap, this.SortedTwigNodesDownMap);
            // 6. 計算節點branch位置 (_branchUp, _branchDown)
            calcTreeNodesBranchPosition(this.treeNodesCurrent, this.TreeNodesCurrentMap, this.SortedTwigNodesUpMap, this.SortedTwigNodesDownMap);
            // 7. 計算節點欄位置 (_columnIndex)
            calcTreeNodesColumnPosition(this.treeNodesCurrent, this.TreeNodesCurrentMap, this.SortedTwigNodesUpMap, this.SortedTwigNodesDownMap);
            // const c = this.treeNodesCurrent!.find(d => d.id === '52659672')!._columnIndex
            // console.log(c, this.treeNodesCurrent)
        };
        calcTreeNodesBloodRelative();
        // 8. 取得孤兒分支
        const OrphanBranchesMap = getOrphanBranches(this.treeNodesCurrent);
        if (OrphanBranchesMap.size > 0) {
            // const tempSize = OrphanBranchesMap.size
            //       console.log('OrphanBranchesMap', OrphanBranchesMap)
            // debugger
            // 9. 看是否有人能夠認養
            const adoptedNodes = adoptOrphan(this.treeNodesCurrent, this.TreeNodesCurrentMap, this.SortedTwigNodesDownMap, OrphanBranchesMap);
            // console.log(OrphanBranchesMap)
            // console.log('this.treeNodesCurrent', this.treeNodesCurrent)
            // console.log('this.TreeNodesCurrentMap', this.TreeNodesCurrentMap)
            if (adoptedNodes.length) {
                // 位置清空重算
                this.treeNodesCurrent.forEach(node => {
                    node._columnIndex = 0;
                    node._rowIndex = 0;
                });
                // 2~5 重新計算血親節點結構
                calcTreeNodesBloodRelative();
            }
            // 10. 修正孤兒節點座標
            modifyOrphansPosition(this.TreeNodesCurrentMap, OrphanBranchesMap);
            // 11. 移動衝突節點
            shiftConflictOrphans(this.treeNodesCurrent, this.TreeNodesCurrentMap, OrphanBranchesMap);
            // 7. 計算節點欄位置 (_columnIndex) @Q@ 修 bug時補上來的，不確定是否為最好的寫法找時間把程式碼整個再看過一遍
            // calcTreeNodesColumnPosition(this.treeNodesCurrent!, this.TreeNodesCurrentMap!, this.SortedTwigNodesUpMap, this.SortedTwigNodesDownMap)
        }
        // 12. 取得親戚節點起始節點
        const startColumnIndex = getNeighborStartColumn(this.treeNodesCurrent);
        // 13. 計算親戚節點位置
        calcNeighborNodesPosition(this.treeNodesCurrent, startColumnIndex, this.TreeEdgesCurrentStartMap, this.TreeEdgesCurrentEndMap);
        // 14. 計算欄&列
        const { ColumnsMap, RowsMap, treeFullGridPosition } = makeColumnsAndRowsData(this.treeNodesCurrent, this.TreeEdgesCurrentStartMap, this.TreeEdgesCurrentEndMap);
        this.ColumnsMap = ColumnsMap;
        this.RowsMap = RowsMap;
        this.treeFullGridPosition = treeFullGridPosition;
        // console.log('this.TreeEdgesCurrentStartMap', this.TreeEdgesCurrentStartMap, 'this.TreeEdgesCurrentEndMap', this.TreeEdgesCurrentEndMap)
        // 15. 計算通道
        calcWayPosition(this.ColumnsMap, this.RowsMap, this.treeFullGridPosition);
        // 16. 設定節點座標
        setNodePosition(this.treeNodesCurrent, this.ColumnsMap, this.RowsMap);
        // 17. 計算連接線
        calcTreeEdgesPosition({
            treeEdgesCurrent: this.treeEdgesCurrent,
            TreeNodesCurrentMap: this.TreeNodesCurrentMap,
            TreeEdgesCurrentStartMap: this.TreeEdgesCurrentStartMap,
            TreeEdgesCurrentEndMap: this.TreeEdgesCurrentEndMap,
            ColumnsMap: this.ColumnsMap,
            RowsMap: this.RowsMap
        });
        // console.log('this.ColumnsMap', this.ColumnsMap, 'this.RowsMap', this.RowsMap)
        //@ts-ignore
        // window.treeNodesCurrent  = this.treeNodesCurrent
        //@ts-ignore
        // window.treeEdgesCurrent = this.treeEdgesCurrent
        this.nodes.setData(this.treeNodesCurrent);
        this.edges.setData(this.treeEdgesCurrent);
        this.nodes.render();
        this.edges.render();
        // 顯示輔助線
        if (this.params.style?.auxiliaryLine) {
            this.renderAuxiliaryLines(this.ColumnsMap, this.RowsMap);
        }
        else {
            this.renderAuxiliaryLines(new Map(), new Map());
        }
    }
    filter(filterConfig) {
        if (this.treeNodesFiltered && this.treeEdgesFiltered && (filterConfig.stockMin != undefined || filterConfig.stockExceptZero != undefined)) {
            if (this.filterConfig.stockMin != filterConfig.stockMin || this.filterConfig.stockExceptZero != filterConfig.stockExceptZero) {
                this.removeHighlight();
                // setTimeout(() => {
                this.filterConfig.stockMin = filterConfig.stockMin;
                this.filterConfig.stockExceptZero = filterConfig.stockExceptZero;
                const { nodes, edges } = this.makeFilteredDataset({
                    nodeData: this.dataset.nodes,
                    edgeData: this.dataset.edges,
                    rootId: this.dataset.rootId,
                    stockMin: this.filterConfig.stockMin,
                    stockExceptZero: this.filterConfig.stockExceptZero
                });
                this.filteredDataset = {
                    ...this.dataset,
                    nodes,
                    edges
                };
                this.treeNodesFiltered = this.makeTreeNodes(this.filteredDataset, this.filteredDataset.rootId);
                this.treeEdgesFiltered = this.makeTreeEdges(this.filteredDataset, this.treeNodesFiltered);
                this.treeNodesCurrent = Object.assign([], this.treeNodesFiltered);
                this.treeEdgesCurrent = Object.assign([], this.treeEdgesFiltered);
                // this.render()
                // }, 1000)
            }
        }
    }
    // 原始資料 轉 treeNodes
    makeTreeNodes(sourceData, rootId) {
        const { nodes: sourceNodes, edges } = sourceData;
        let MainRouteFirstNodeSet = new Set();
        let nodes = sourceNodes.map((d, index) => {
            const { textWidth, labels } = this.getLabelInfo(d.name);
            let nodeType = '';
            let _mainRelatedToRoot = 'up';
            let width = NODE_WIDTH_DEFAULT;
            const height = NODE_HEIGHT_MIN + (labels.length - 1) * NODE_TEXT_SIZE;
            let _routesDataUp = [];
            let _routesDataDown = [];
            // 親戚節點
            if (d['roles_related_to_root'].includes('neibor')) {
                _mainRelatedToRoot = 'neibor';
                nodeType = `neibor_${d.role}`;
            }
            // 上游節點
            else if (d['routes-in-id']?.length) {
                _mainRelatedToRoot = 'up';
                nodeType = d.role;
                // 自然人的node寬度是依文字調整
                if (nodeType === '自然人') {
                    width = textWidth + (NODE_TEXT_LEFT * 2);
                }
                // 將路徑資料一起重新排序
                _routesDataUp = this.parseRoutesDataUp(d);
            }
            // 下游節點（上游路徑優先於下游，所以如果有上游路徑資料那下游路徑就不加入了）
            else if (d['routes_to_downs-in-id']?.length) {
                _mainRelatedToRoot = 'down';
                nodeType = d.role;
                // 自然人的node寬度是依文字調整
                if (nodeType === '自然人') {
                    width = textWidth + (NODE_TEXT_LEFT * 2);
                }
                // 將路徑資料一起重新排序
                _routesDataDown = this.parseRoutesDataDown(d);
            }
            // 根節點
            else {
                _mainRelatedToRoot = 'root';
                nodeType = 'root';
            }
            // 非營業中
            if (d.detail.payload.basic.company_operate != undefined && d.detail.payload.basic.company_operate !== '營業中') {
                nodeType += '_closed';
            }
            // -- tags --
            let tags = [];
            if (d.role === '法人' && d.public_issue) {
                if (d.public_issue === '上市') {
                    tags.push('市');
                }
                else if (d.public_issue === '上櫃') {
                    tags.push('櫃');
                }
            }
            // @Q@ 股權結構表不顯示實質受益人
            // if (d.role === '自然人' && d['total-investment-ratio'] && d['total-investment-ratio'] >= 25) {
            //   tags.push('益')
            // }
            // -- _originTrunkNodeId, _mainRouteUp, _mainRouteDown --
            let _originTrunkNodeId = '';
            let _mainRouteUp = [];
            let _mainRouteDown = [];
            let _mainRouteStockDetail = 0;
            if (_routesDataUp.length) {
                _mainRouteUp = _routesDataUp[0]['routes-in-id']; // 排序過的路徑中的第一筆為主要路徑
                _mainRouteStockDetail = _routesDataUp[0]['routes-of-stock-detail'][0];
                _originTrunkNodeId = _mainRouteUp[0] ? _mainRouteUp[0].split('->')[1] : '';
            }
            else if (_routesDataDown.length) {
                _mainRouteDown = _routesDataDown[0]['routes_to_downs-in-id']; // 排序過的路徑中的第一筆為主要路徑
                _mainRouteStockDetail = _routesDataDown[0]['routes_to_downs-of-stock-detail'][_routesDataDown[0]['routes_to_downs-of-stock-detail'].length - 1];
                _originTrunkNodeId = _mainRouteDown[_mainRouteDown.length - 1] ? _mainRouteDown[_mainRouteDown.length - 1].split('->')[0] : '';
            }
            MainRouteFirstNodeSet.add(_originTrunkNodeId);
            return {
                key: d.uniID,
                index,
                id: d.uniID,
                uniID: d.uniID,
                label: d.name,
                labels,
                nodeType,
                x: -1,
                y: -1,
                width,
                height,
                expandable: false,
                isExpanded: true,
                tags,
                sourceData: d,
                _columnIndex: 0,
                _rowIndex: 0,
                _routesDataUp,
                _routesDataDown,
                _mainRouteUp,
                _mainRouteDown,
                _mainRouteStockDetail,
                _NodesOfRoutesSet: new Set(),
                _EdgesOfRoutesSet: new Set(),
                _seq: -1,
                _originTrunkNodeId,
                _trunkNode: undefined,
                _twigNodes: [],
                _rolesRelatedToRoot: d.roles_related_to_root,
                _mainRelatedToRoot,
                _branchUp: {
                    nodes: [],
                    allColumns: 0,
                    leftColumns: 0,
                    centerSeq: 0, // * 後面程式處理
                },
                _branchDown: {
                    nodes: [],
                    allColumns: 0,
                    leftColumns: 0,
                    centerSeq: 0, // * 後面程式處理
                },
                _inOrphanBranch: false // * 後面程式處理
            };
        });
        // expandable
        nodes.forEach(node => {
            // 寫入 expandable資料
            if (node.id != rootId) {
                node.expandable = MainRouteFirstNodeSet.has(node.id);
            }
        });
        return nodes;
    }
    // 原始資料 轉 edgeData
    makeTreeEdges(sourceData, treeNodes) {
        return sourceData.edges.map((edge, i) => {
            // const idArr = edge.id.split('->')      
            return {
                // key: edge.id,
                id: edge.id,
                startNodeId: edge['source-uniID'],
                endNodeId: edge['target-uniID'],
                startX: -1,
                startY: -1,
                endX: -1,
                endY: -1,
                startCount: 0,
                startCountX: -1,
                startCountY: -1,
                endCount: 0,
                endCountX: -1,
                endCountY: -1,
                label: this.formatPercentage(edge.percentage),
                labelX: 0,
                labelY: 0,
                labelTransformX: 0,
                labelTransformY: 0,
                labelDotShow: false,
                labelTextAnchor: 'start',
                labelDominantBaseline: 'auto',
                pathD: '',
                value: edge.percentage,
                _startNode: treeNodes.find(d => d.id === edge['source-uniID']),
                _endNode: treeNodes.find(d => d.id === edge['target-uniID'])
            };
        });
    }
    onZoom({ x, y, k }) {
    }
    initNodesAndEdges() {
        this.nodes = new Nodes(this.nodesG, {
            nodeTypeConfig: this.params.nodeTypeConfig,
            styleConfig: this.params.styleConfig,
            nodeTagConfig: this.params.nodeTagConfig,
            expand: false
        });
        this.edges = new Edges(this.edgesG, {});
        this.nodes
            .on('click', (d) => {
            // // 已點選相同節點則關閉鎖定
            // if (d.data.id === this.highlightLockId) {
            //   this.highlightLockMode = false
            //   this.highlightLockId = ''
            // }
            // // 未點選相同節點則開啟鎖定
            // else {
            //   this.highlightLockMode = true
            //   this.highlightLockId = d.data.id
            //   this.removeHighlight()
            //   this.highlight(d)
            // }
            console.log(d);
            this.clickCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mouseover', (d) => {
            if (this.highlightLockMode == false) {
                this.highlight(d.data.id);
            }
            this.mouseoverCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mousemove', (d) => {
            this.mousemoveCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mouseout', (d) => {
            if (this.highlightLockMode == false) {
                this.removeHighlight();
            }
            this.mouseoutCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        });
        // .on('toggle', (d) => {
        //   // console.log('d.data.isExpanded', d.isExpanded)
        //   this.toggleTopNodes(d.data, d.isExpanded!)
        // })
    }
    highlight(id) {
        const node = this.TreeNodesCurrentMap?.get(id);
        if (!node) {
            return;
        }
        let nodeIds = [];
        let edgeIds = [];
        node._NodesOfRoutesSet.forEach(id => {
            nodeIds.push(id);
        });
        node._EdgesOfRoutesSet.forEach(id => {
            edgeIds.push(id);
        });
        this.nodes.setParams({
            ...this.nodes.params,
            highlightStartId: id,
            highlightIds: nodeIds
        });
        this.edges.setParams({
            ...this.edges.params,
            highlightIds: edgeIds
        });
    }
    removeHighlight() {
        this.nodes.setParams({
            ...this.nodes.params,
            highlightStartId: '',
            highlightIds: []
        });
        this.edges.setParams({
            ...this.edges.params,
            highlightIds: []
        });
    }
    renderAuxiliaryLines(ColumnsMap, RowsMap) {
        const labelSize = 12;
        const columnXs = Array.from(ColumnsMap).map(column => column[1].lineXs).flat();
        const rowYs = Array.from(RowsMap).map(row => row[1].lineYs).flat();
        const columnNodes = Array.from(ColumnsMap).map(column => column[1].lineStartNodes).flat();
        const rowNodes = Array.from(RowsMap).map(row => row[1].lineEndNodes).flat();
        const minRow = RowsMap.get(this.treeFullGridPosition.minRowIndex);
        const maxRow = RowsMap.get(this.treeFullGridPosition.maxRowIndex);
        const minY = minRow ? maxRow.y - TREE_FULL_ROW_HEIGHT : 0;
        const maxY = minRow ? minRow.y + TREE_FULL_ROW_HEIGHT : 0;
        const minColumn = ColumnsMap.get(this.treeFullGridPosition.minColumnIndex);
        const maxColumn = ColumnsMap.get(this.treeFullGridPosition.maxColumnIndex);
        const minX = minColumn ? minColumn.x - TREE_FULL_COLUMN_WIDTH : 0;
        const maxX = maxColumn ? maxColumn.x + TREE_FULL_COLUMN_WIDTH : 0;
        const renderColumnLines = () => {
            const update = this.auxiliaryColumnLinesG
                .selectAll('.bpchart__column_lines')
                .data(columnXs);
            update
                .enter()
                .append('line')
                .classed('bpchart__column_lines', true)
                // .style('stroke', '#E4E7ED')
                .style('stroke', '#84D3E1')
                .style('stroke-width', 1)
                .style('pointer-events', 'none')
                .attr('stroke-dasharray', '6,2')
                // .attr('opacity', 0)
                .merge(update)
                .attr('x1', d => d)
                .attr('y1', minY)
                .attr('x2', d => d)
                .attr('y2', maxY);
            update.exit().remove();
        };
        const renderRowLines = () => {
            const update = this.auxiliaryRowLinesG
                .selectAll('.bpchart__row_lines')
                .data(rowYs);
            update
                .enter()
                .append('line')
                .classed('bpchart__row_lines', true)
                // .style('stroke', '#E4E7ED')
                .style('stroke', '#84D3E1')
                .style('stroke-width', 1)
                .style('pointer-events', 'none')
                .attr('stroke-dasharray', '6,2')
                // .attr('opacity', 0)
                .merge(update)
                .attr('x1', minX)
                .attr('y1', d => d)
                .attr('x2', maxX)
                .attr('y2', d => d);
            update.exit().remove();
        };
        const renderColumnBadges = () => {
            const update = this.auxiliaryColumnLinesG
                .selectAll('g.bpchart__column_labels')
                .data(columnNodes);
            const badgeG = update
                .enter()
                .append('g')
                .classed('bpchart__column_labels', true)
                .merge(update)
                .attr('transform', (d, i) => `translate(${columnXs[i] + 10}, ${maxY + 10}) rotate(90)`);
            update.exit().remove();
            badgeG.each((datum, index, groups) => {
                const g = d3.select(groups[index]);
                g
                    .append('rect')
                    .attr('width', measureTextWidth(datum.label, labelSize) + 20)
                    .attr('height', 20)
                    .attr('rx', 4)
                    .attr('ry', 4)
                    .attr('fill', '#84D3E1');
                g
                    .append('text')
                    .text(datum.label)
                    .attr('font-size', labelSize)
                    .attr('fill', '#ffffff')
                    .attr('x', 10)
                    .attr('y', 10)
                    .attr('text-anchor', 'start')
                    .attr('dominant-baseline', 'central');
            });
        };
        const renderRowBadges = () => {
            const update = this.auxiliaryColumnLinesG
                .selectAll('g.bpchart__row_labels')
                .data(rowNodes);
            const badgeG = update
                .enter()
                .append('g')
                .classed('bpchart__row_labels', true)
                .merge(update)
                .attr('transform', (d, i) => `translate(${maxX + 10}, ${rowYs[i] - 10})`);
            update.exit().remove();
            badgeG.each((datum, index, groups) => {
                const g = d3.select(groups[index]);
                g
                    .append('rect')
                    .attr('width', measureTextWidth(datum.label, labelSize) + 20)
                    .attr('height', 20)
                    .attr('rx', 4)
                    .attr('ry', 4)
                    .attr('fill', '#84D3E1');
                g
                    .append('text')
                    .text(datum.label)
                    .attr('font-size', labelSize)
                    .attr('fill', '#ffffff')
                    .attr('x', 10)
                    .attr('y', 10)
                    .attr('text-anchor', 'start')
                    .attr('dominant-baseline', 'central');
            });
        };
        renderColumnLines();
        renderRowLines();
        renderColumnBadges();
        renderRowBadges();
    }
}
