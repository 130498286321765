import { AxisDateColumnTwoScales } from '@bpchart/d3-modules/axisDateColumnTwoScales';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS } from '@bpchart/d3-modules/axisDateColumnTwoScales';
export default {
    props: {
        module: AxisDateColumnTwoScales,
        params: DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
