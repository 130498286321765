const response = {
    "payload": {
        "list": [
            {
                "itemLabel": "一月",
                "xLabel": "一月",
                "value": 55026
            },
            {
                "itemLabel": "二月",
                "xLabel": "二月",
                "value": 65057
            },
            {
                "itemLabel": "三月",
                "xLabel": "三月",
                "value": 45044
            },
            {
                "itemLabel": "四月",
                "xLabel": "四月",
                "value": 20038
            },
            {
                "itemLabel": "五月",
                "xLabel": "五月",
                "value": 30013
            },
            {
                "itemLabel": "六月",
                "xLabel": "六月",
                "value": 30020
            },
            {
                "itemLabel": "七月",
                "xLabel": "七月",
                "value": 32006
            },
            {
                "itemLabel": "八月",
                "xLabel": "八月",
                "value": 43073
            },
            {
                "itemLabel": "九月",
                "xLabel": "九月",
                "value": 38023
            },
            {
                "itemLabel": "十月",
                "xLabel": "十月",
                "value": 50018
            },
            {
                "itemLabel": "十一月",
                "xLabel": "十一月",
                "value": 75097
            },
            {
                "itemLabel": "十二月",
                "xLabel": "十二月",
                "value": 62085
            }
        ],
    }
};
export default {
    data: response.payload.list
        .map(d => {
        return {
            value: d.value,
            itemLabel: d.itemLabel
        };
    }),
    xLabels: response.payload.list.map(d => d.xLabel),
    itemLabels: response.payload.list.map(d => d.itemLabel)
};
