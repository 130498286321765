const response = {
    "payload": {
        "bar": [
            {
                "value": 75
            },
            {
                "value": 90
            },
            {
                "value": 85
            },
            {
                "value": 110
            },
            {
                "value": 150
            },
        ],
        "line": [
            {
                "value": 25
            },
            {
                "value": 60
            },
            {
                "value": 70
            },
            {
                "value": 65
            },
            {
                "value": 100
            },
        ],
        "xLabels": ["2016", "2017", "2018", "2019", "2020"]
    }
};
const dataset = {
    y1Data: response.payload.bar,
    y2Data: [response.payload.line],
    xLabels: response.payload.xLabels
};
export default dataset;
