import { ChartDirectedDagre } from '@bpchart/d3-modules/chartDirected';
import dataset from './dataset_三創數位股份有限公司';
export default {
    props: {
        module: ChartDirectedDagre,
        width: '100%',
        height: '100vh',
        dataset,
        // update: {
        //   screenShotMode: false,
        //   direction: 'RL',
        // },
        params: {
            styles: {
                // 一般文字
                text: 'fill: #303133;font-weight: normal;font-size: 14px;',
                // 根節點文字
                textRoot: 'fill: #1778f5;font-weight: bold;font-size: 14px;',
                // 上游的線
                pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
                // 下游的線
                pathDown: 'stroke: #a2c9fb; stroke-width: 1.5px; fill:none;',
                // 歇業公司的線
                pathClosed: 'stroke: #c0c4cc; stroke-width: 1.5px; fill:none;',
                // 上游的指標
                arrowTop: 'fill: #909399',
                // 下游的指標
                arrowDown: 'fill: #a2c9fb',
                // 歇業公司的指標
                arrowClosed: 'fill: #c0c4cc',
                // 根節點
                rectRoot: 'rx:5px;ry:5px;stroke:#a2c9fb;stroke-width:3px;fill:#e8f2fe;',
                // 公司節點
                rectCompany: 'rx:5px;ry:5px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
                // 自然人節點
                rectPerson: 'rx:19px;ry:19px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
                // 下游公司節點
                rectCompanyDown: 'rx:5px;ry:5px;stroke: #a2c9fb;stroke-width: 1.5px;fill: #fff;',
                // 已歇業公司根節點
                rectRootClosed: 'rx:5px;ry:5px;stroke:#c0c4cc;stroke-width:3px;fill:#DCDFE6;',
                // 已歇業公司節點
                rectCompanyClosed: 'rx:5px;ry:5px;stroke: #c0c4cc;stroke-width: 1.5px;fill: #DCDFE6;',
                // 展開按鈕
                expandBtn: 'fill:#1778F5'
            },
            screenShotMode: false,
            direction: 'RL',
            extandOnNodeClick: true
        },
        zoom: {
            x: 0,
            y: 0,
            k: 1
        }
    },
    emits: {
        click: {}
    }
};
