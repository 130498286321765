// require('./dagre_directed_chart.scss')
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import ChartDirected from './ChartDirected';
import { svgHtml } from '../d3Utils';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { DEFAULT_CHART_DIRECTED_DAGRE_PARAMS } from './defaults';
// export interface IDagreDirectedChart {
//   onNodeClick (callback: () => void): void;
//   setData (
//     arg: {
//       nodes: Array<ChartDirectedDagreNode>,
//       edges: Array<ChartDirectedDagreEdge>
//     }
//   ): void
//   resetNodes (
//     arg: {
//       nodes: Array<ChartDirectedDagreNode>;
//       edges: Array<ChartDirectedDagreEdge>;
//       // center?: boolean // 置中
//     }
//   ): void;
//   toggleTopNodes (id: string): void;
//   getChartSize (): { width: number; height: number; x: number; y: number };
//   setStyle (callback: (map: any) => any): void;
//   resize (arg: boolean): void
// }
// 按鈕icon樣式
const fnIconSize = 16;
const fnIconActiveSize = 24;
const fnIconActiveOffset = fnIconActiveSize / 2;
const tagSize = 24;
const tagTextSize = 12;
// 新增節點遞迴（目前只展開一層所以無遞迴）
function addLoop({ nodes, edges }, { nodes: allNodes, edges: allEdges }, NodesMap, originID) {
    const rootID = allNodes[0].id;
    // step1 新增關聯edges
    let findIDs = [];
    for (let i in allEdges) {
        let newNode = null;
        if (allEdges[i]._end === originID) {
            newNode = allEdges[i]._start;
            // } else if (allEdges[i]._start === originID) {
            //   newNode = allEdges[i]._end
        }
        else {
            continue;
        }
        let isExist = edges.some((d) => d._start === allEdges[i]._start && d._end === allEdges[i]._end);
        if (isExist === true) {
            continue;
        }
        let newEdge = JSON.parse(JSON.stringify(allEdges[i])); // 要用深拷貝不然有bug
        edges.push(newEdge);
        findIDs.push(newNode);
    }
    if (findIDs.length < 1) {
        return { nodes, edges };
    }
    // step2 如果start-nodes無存在目前nodes則新增進來
    let findNoneNodeIDs = [];
    let addNodes = [];
    for (let testID of findIDs) {
        let isExist = nodes.some(d => d.id === testID);
        if (isExist === false) {
            let findNode = NodesMap.get(testID);
            if (findNode) {
                addNodes.push(findNode);
                findNoneNodeIDs.push(findNode.id);
            }
        }
    }
    // step3 修正展開狀態
    addNodes = addNodes.map((node) => {
        let hasTop = allEdges.some(edge => {
            // 非node的上層
            if (edge._end !== node.id) {
                return false;
            }
            // 如果上層為根節點則無展開按鈕
            if (edge._start === rootID) {
                return false;
            }
            // 有上層且非根節點
            return true;
        });
        if (hasTop) {
            node.hasTop = true;
            node.isTopExpanded = false; // 預設false
        }
        return node;
    });
    nodes = nodes.concat(addNodes);
    // step3 用前步驟新增的nodes再往子node新增
    // for (let nextID of findNoneNodeIDs) {
    //   addLoop(nodes, edges, allNodes, allEdges, nextID)
    // }
    return { nodes, edges };
}
// 刪除節點遞迴
function deleteLoop({ nodes, edges }, { nodes: allNodes, edges: allEdges }, originID) {
    const rootID = allNodes[0].id;
    // let newData = { nodes, edges }
    let newNodes = [];
    let newEdges = [];
    // step1 刪除關聯edges
    let findIDs = [];
    for (let i in edges) {
        if (edges[i]._end === originID && edges[i]._start !== rootID) {
            // 取得點擊對象的上游（如為根節點不移除）
            findIDs.push(edges[i]._start);
            // } else if (edges[i]._start === originID
            // && edges.some(e => e._end == edges[i]._end && e._start == rootID)) {
            //   // 如點擊對象的下游是根節點的下游，取得點擊對象的下游
            //   findIDs.push(edges[i]._end)
        }
        else {
            // 不刪除
            newEdges.push(edges[i]);
        }
    }
    // step2 過濾掉無連接到根節點的線
    const filterEdgesFromRoot = (edges) => {
        let newEdges = [];
        // const searchUp = (_start:string, _end:string) => {
        //   const upEdges = edges.filter(edge => _start === edge._end) // 上一層節點的連接線
        //   for (const edge of upEdges) {
        //     let isExist = newEdges.some(newEdge => {
        //       return newEdge._start === edge._start && newEdge._end === edge._end
        //     })
        //     if (isExist == false) {
        //       newEdges.push(edge)
        //       searchUp(edge._start, edge._end)
        //     }
        //   }
        // }
        // const topFromRoot = edges.filter(edge => edge._end === rootID)
        // for (const edge of topFromRoot) {
        //   newEdges.push(edge)
        //   searchUp(edge._start, edge._end)
        // }
        // const searchDown = (_start:string, _end:string) => {
        //   const downEdges = edges.filter(edge => _end === edge._start) // 下一層節點的連接線
        //   for (const edge of downEdges) {
        //     let isExist = newEdges.some(newEdge => {
        //       return newEdge._start === edge._start && newEdge._end === edge._end
        //     })
        //     if (isExist == false) {
        //       newEdges.push(edge)
        //       searchDown(edge._start, edge._end)
        //     }
        //   }
        // }
        // const downFromRoot = edges.filter(edge => edge._start === rootID)
        // for (const edge of downFromRoot) {
        //   let isExist = newEdges.some(newEdge => {
        //     return newEdge._start === edge._start && newEdge._end === edge._end
        //   })
        //   if (isExist == false) {
        //     newEdges.push(edge)
        //   }
        //   searchDown(edge._start, edge._end)
        // }
        const searchEdge = (currentID) => {
            const topFromCurrent = edges.filter(edge => edge._end === currentID);
            const downFromCurrent = edges.filter(edge => edge._start === currentID);
            for (const edge of topFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge._start === edge._start && newEdge._end === edge._end;
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge._start);
                }
            }
            for (const edge of downFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge._start === edge._start && newEdge._end === edge._end;
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge._end);
                }
            }
        };
        searchEdge(rootID);
        return newEdges;
    };
    newEdges = filterEdgesFromRoot(newEdges);
    // step3 過濾掉無連接線的節點
    newNodes = nodes.filter((node) => {
        return newEdges.find((edge) => node.id === edge._start || node.id === edge._end) != null;
    });
    // step2 如果start-node已無edge則刪除該node
    // let findNoneEdgeIDs = []
    // for (let testID of findIDs) {
    //   let testFilter = edges.find(d => {
    //     return d._start === testID // 該node為上游的連接線
    //       // || (d._start === rootID && d._end === testID) // 該node為根節點的下游
    //       || d._end === testID
    //   })
    //   if (testFilter == null) {
    //     findNoneEdgeIDs.push(testID)
    //     // @Q@ 刻意讓泡泡飛走所以不移除節點，如果要移除的話就把下面這行解開
    //     // nodes = nodes.filter(d => d.id !== testID)
    //   }
    // }
    // @Q@ 後來發現原先做法無法找出所有子節點（漏掉了同時是上游也是下游的情況），所以改為刻意不移除節點的做法
    // step3 用前步驟被刪除的nodes再去往子node刪
    // for (let nextID of findNoneEdgeIDs) {
    //   allNodes = allNodes.map(d => {
    //     if (d.id === nextID) {
    //       // 展開狀態關閉
    //       d.isTopExpanded = false
    //     }
    //     return d
    //   })
    //   newData = deleteLoop(nodes, edges, allNodes, allEdges, nextID)
    //   nodes = newData.nodes
    //   edges = newData.edges
    // }
    return {
        nodes: newNodes,
        edges: newEdges
    };
}
function getExpendIconX(rectWidth) {
    return (rectWidth / 2) + 5;
}
// 將dagre d3綁定的id轉換成資料id
function returnEdgeIdFromDagreId(d) {
    let startId = d.v;
    const endId = d.w;
    return `${startId}->${endId}`;
}
// 將dagre d3綁定的id轉換成路徑key
function returnEdgesOfRoutesKeyFromDagreId(d) {
    const startId = d.v;
    const endId = d.w;
    // const textArr = startId.split('_')
    // if (textArr[1]) {
    //   startId = textArr[1] // 因route資料人名的索引是姓名本身，所以要另外截取出來
    // }
    return `${startId}->${endId}`;
}
export default class ChartDirectedDagre extends ChartDirected {
    params = DEFAULT_CHART_DIRECTED_DAGRE_PARAMS;
    nodesRect;
    edgesPath;
    edgesArrow;
    edgesText;
    dagreD3Render = new dagreD3.render();
    // private svgGroup: d3.Selection<SVGGElement, any, any, any>
    chartG = undefined;
    // 全部的資料（包含未展開的）
    renderDataOrigin = {
        nodes: [],
        edges: [],
        // _NodesMap: undefined // 將nodes轉為key/value格式
    };
    // 顯示的資料（不包含未展開的）
    renderData = {
        nodes: [],
        edges: [],
        // _NodesMap: undefined // 將nodes轉為key/value格式
    };
    NodesMap = new Map();
    EdgesMap = new Map();
    clickCallback = function () { return null; };
    // private nodesExtandCallback: (arg: ChartDirectedDagreNode) => void = function () { return null }
    tooltip = undefined;
    // private d3Zoom?: d3.ZoomBehavior<any, any> = undefined
    direction = 'RL';
    styleConfig = {
        styles: DEFAULT_CHART_DIRECTED_DAGRE_PARAMS.styles,
        _StylesMap: undefined // styles的map
    };
    //<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.993 511.993" style="enable-background:new 0 0 511.993 511.993;" xml:space="preserve">
    plusSvgHtml = this.returnPlusSvgHtml();
    minusSvgHtml = this.returnMinusSvgHtml();
    rootID = '';
    // private updateConfig: ChartDirectedDagreUpdate | undefined
    constructor(el, params) {
        super(el, params);
        if (params) {
            this.params = {
                ...this.params,
                ...params
            };
        }
        // this.selection = el
        //   // .append('svg')
        //   // .classed('choose__dagre-directed-chart', true)
        //   // .attr('width', width)
        //   // .attr('height', height)
        // const { width, height } = this.getContainerSize()
        // this.width = width
        // this.height = height
        this.svgGroup.classed('choose__dagre-directed-chart__g', true);
        // this.svg.html(`
        // <linearGradient id="gradient">
        //   <stop offset="0%" style="stop-color: red" />
        //   <stop offset="100%" style="stop-color: yellow" />
        // </linearGradient>
        // `)
        let linearGradient = this.selection
            .append('defs')
            .append('linearGradient')
            .attr('id', 'gradient');
        linearGradient
            .append('stop')
            .attr('offset', '0%')
            .style('stop-color', '#81c4c3');
        // .style('stop-color', '#2499FF')
        linearGradient
            .append('stop')
            .attr('offset', '100%')
            .style('stop-color', '#2f8cbf');
        // .style('stop-color', '#004DB1')
        // 初始化styleConfig
        // this.setStyle((styleConfig: any) => styleConfig)
        this.setStyle(params.styles);
        this.tooltip = new TooltipFollowing(this.selection, {
            templateHtml: (data) => `
        <div>${data}</div>
      `,
            insideBoxMode: true,
            type: 'black'
        });
    }
    render() {
        this.doRender({
            nodes: this.renderData.nodes,
            edges: this.renderData.edges,
            direction: this.direction
        });
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        // else if (actionName === 'nodesExtand') {
        //   this.nodesExtandCallback = callback
        // }
        return this;
    }
    setDataset({ nodes = [], edges = [], expandAll = false, direction = this.direction }) {
        this.dataset = { nodes, edges, expandAll, direction };
        this.direction = direction;
        // 記住資料
        this.resetNodes({ nodes, edges });
        // 實際呈現的資料
        // this.renderData = JSON.parse(JSON.stringify(this.renderDataOrigin))
        this.renderData = {
            nodes: Object.assign([], this.renderDataOrigin.nodes),
            edges: Object.assign([], this.renderDataOrigin.edges),
        };
        if (expandAll === false) {
            // 預設第一筆為根節點
            const rootNode = this.renderData.nodes[0] ? this.renderData.nodes[0].id : '';
            // 全部顯示的節點
            let allShowNodes = [rootNode];
            for (const link of this.renderData.edges) {
                if (link._start === rootNode) {
                    allShowNodes.push(link._end);
                }
                if (link._end === rootNode) {
                    allShowNodes.push(link._start);
                }
            }
            // root上下游一層的連結
            const allShowEdges = this.renderData.edges.filter(d => {
                if (d._start === rootNode || d._end === rootNode) {
                    return true;
                }
                return false;
            });
            // // root上下游一層全部的連結
            // const allShowEdges = this.renderData.edges.filter(d => {
            //   if (allShowNodes.includes(d._start) && allShowNodes.includes(d._end)) {
            //     return true
            //   }
            //   return false
            // })
            this.renderData.edges = allShowEdges;
            this.renderData.nodes = this.renderData.nodes.filter(d => {
                if (allShowNodes.includes(d.id)) {
                    return true;
                }
                return false;
            });
        }
        // _NodesMap
        this.NodesMap = new Map();
        this.renderDataOrigin.nodes.forEach((d) => {
            this.NodesMap.set(d.id, d);
        });
        this.EdgesMap = new Map();
        this.renderDataOrigin.edges.forEach((d) => {
            this.EdgesMap.set(d.id, d);
        });
        // this.doRender({
        //   nodes: this.renderData.nodes,
        //   edges: this.renderData.edges,
        //   direction
        // })
    }
    setParams(data) {
        this.params = {
            ...this.params,
            ...data
        };
        if (data.direction) {
            this.changeDirection(data.direction);
        }
        if (data.screenShotMode != undefined) {
            this.screenShotMode(data.screenShotMode);
        }
        if (data.autoZoom != undefined && data.autoZoom == true) {
            this.resetZoom();
        }
        if (data.highlightId != undefined) {
            this.highlight(data.highlightId);
        }
    }
    // 置中
    resize({ width, height } = { width: this.width, height: this.height }) {
        this.width = width;
        this.height = height;
        // 重設svg尺寸
        this.selection.attr('width', `${width}px`);
        this.selection.attr('height', `${height}px`);
        if (this.params && this.params.autoZoom) {
            this.resetZoom();
        }
    }
    renderNode(nodesG, NodesMap) {
    }
    resetZoom() {
        if (!this.chartG) {
            return;
        }
        // reset zoom scale
        // if (this.d3Zoom && this.d3Zoom.transform) {
        //   this.selection.call(this.d3Zoom.transform, d3.zoomIdentity.scale(1));
        // }
        // 置中偏移
        const xCenterOffset = (Number(this.width) - this.chartG.graph().width) / 2;
        const yCenterOffset = (Number(this.height) - this.chartG.graph().height) / 2;
        // this.svgGroup.attr("transform", `translate(${xCenterOffset}, ${yCenterOffset}) scale(1)`)
        // 滑鼠滾動放大縮小
        this.initZoom({
            ...this.zoom,
            xOffset: xCenterOffset,
            yOffset: yCenterOffset
        });
        this.transformZoom({
            x: 0,
            y: 0,
            k: 1
        });
    }
    screenShotMode(isScreenShot) {
        if (isScreenShot) {
            // 截圖的神奇bug修正
            // 說明：將marker-end屬性內的網址刪除
            // 比如，原本是marker-end="url(http://localhost:8100/companydetail?uniid=73251209#arrowhead290)"
            // 修正為marker-end="url(#arrowhead290)"
            try {
                this.selection.selectAll('.edgePath').select('path').attr('marker-end', (d, i, all) => {
                    const self = d3.select(all[i]);
                    const attr = self.attr('marker-end');
                    if (attr) {
                        const arr = attr.split('#');
                        return arr[1] ? `url(#${arr[1]}` : '';
                    }
                    return '';
                });
            }
            catch (e) {
                console.error(e);
            }
            // 置中
            this.resize();
            this.resetZoom();
        }
        else {
            // 置中
            this.resize();
            this.resetZoom();
        }
    }
    activeRoute({ nodeData, nodesRect, edgesPath, edgesArrow, edgesText }) {
        if (!nodeData.nodesOfRoutes || !nodeData.edgesOfRoutes) {
            return;
        }
        nodesRect.each((d, i, nodes) => {
            let nodeId = d;
            if (nodeId) {
                // const textArr = nodeId.split('_')
                // if (textArr[1]) {
                //   nodeId = textArr[1] // 因route資料人名的索引是姓名本身，所以要另外截取出來
                // }
                if ((nodeData.nodesOfRoutes && nodeData.nodesOfRoutes.includes(nodeId)) || nodeData.id === nodeId) {
                    const node = d3.select(nodes[i]);
                    node.style('stroke', '#ff0000');
                }
            }
        });
        edgesPath.each((d, i, nodes) => {
            const edgeId = returnEdgesOfRoutesKeyFromDagreId(d);
            if (edgeId && nodeData.edgesOfRoutes.includes(edgeId)) {
                const edge = d3.select(nodes[i]);
                edge.style('stroke', '#ff0000');
            }
        });
        edgesArrow.each((d, i, nodes) => {
            const edgeId = returnEdgesOfRoutesKeyFromDagreId(d);
            if (edgeId && nodeData.edgesOfRoutes.includes(edgeId)) {
                const edge = d3.select(nodes[i]);
                edge.style('fill', '#ff0000');
            }
        });
        edgesText.each((d, i, nodes) => {
            const edgeId = returnEdgesOfRoutesKeyFromDagreId(d);
            if (edgeId && nodeData.edgesOfRoutes.includes(edgeId)) {
                const edge = d3.select(nodes[i]);
                edge.style('fill', '#ff0000');
            }
        });
    }
    inactiveRoute({ nodesRect, edgesPath, edgesArrow, edgesText }) {
        nodesRect.each((d, i, nodes) => {
            const nodeData = this.NodesMap.get(d);
            if (nodeData) {
                const node = d3.select(nodes[i]);
                node.attr('style', this.styleConfig._StylesMap.get(nodeData.style));
            }
        });
        edgesPath.each((d, i, nodes) => {
            const edgeId = returnEdgeIdFromDagreId(d);
            const edgeData = this.EdgesMap.get(edgeId);
            if (edgeData) {
                const edge = d3.select(nodes[i]);
                edge.attr('style', this.styleConfig._StylesMap.get(edgeData.style.path));
            }
        });
        edgesArrow.each((d, i, nodes) => {
            const edgeId = returnEdgeIdFromDagreId(d);
            const edgeData = this.EdgesMap.get(edgeId);
            if (edgeData) {
                const edge = d3.select(nodes[i]);
                edge.attr('style', this.styleConfig._StylesMap.get(edgeData.style.arrow));
            }
        });
        edgesText.each((d, i, nodes) => {
            const edgeId = returnEdgeIdFromDagreId(d);
            const edgeData = this.EdgesMap.get(edgeId);
            if (edgeData) {
                const edge = d3.select(nodes[i]);
                edge.style('fill', '#303133');
            }
        });
    }
    doRender({ nodes = [], edges = [], direction = 'RL' }) {
        // console.log(this.renderData)
        if (!nodes[0]) {
            return;
        }
        const rootID = nodes[0].id;
        // 建立dagreD3
        this.chartG = new dagreD3.graphlib.Graph()
            .setGraph({
            rankdir: direction // 方向右至左
        })
            .setDefaultEdgeLabel(function () { return {}; });
        // 設置nodes
        nodes.forEach(d => {
            this.chartG.setNode(d.id, {
                label: d.label,
                labelStyle: d.id === rootID ? this.styleConfig._StylesMap.get('textRoot') : this.styleConfig._StylesMap.get('text'),
                style: this.styleConfig._StylesMap.get(d.style)
            });
        });
        // 設置edges
        edges.forEach(d => {
            let style = d.style.path ? this.styleConfig._StylesMap.get(d.style.path)
                : (d.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'));
            let arrowheadStyle = d.style.arrow ? this.styleConfig._StylesMap.get(d.style.arrow)
                : (d.direction === 'top' ? this.styleConfig._StylesMap.get('arrowTop') : this.styleConfig._StylesMap.get('arrowDown'));
            this.chartG.setEdge(d.start, d.end, {
                label: d.label,
                style,
                labelStyle: this.styleConfig._StylesMap.get('text'),
                arrowheadStyle,
                // curve: d3.curveLinear
                curve: d3.curveBundle
                // curve: d3.curveBasis 
                // curve: d3.curveStep
                // curve: d3.curveBundle
                // curve: d3.curveMonotoneY,
            });
            // console.log(d)
            // console.log({
            //   label: d.label,
            //   style,
            //   labelStyle: this.styleConfig._StylesMap!.get('text'),
            //   arrowheadStyle,
            //   // curve: d3.curveLinear
            //   curve: d3.curveBundle
            //   // curve: d3.curveBasis 
            //   // curve: d3.curveStep
            //   // curve: d3.curveBundle
            //   // curve: d3.curveMonotoneY,
            // })
        });
        // 繪圖
        // console.log({ nodes, edges, renderData: this.renderData })
        this.dagreD3Render(this.svgGroup, this.chartG);
        // this.resize()
        const nodesG = this.selection.selectAll('g.node');
        console.log('nodesG', nodesG.data());
        this.nodesRect = nodesG.selectAll('rect');
        this.edgesPath = this.svgGroup.selectAll('g.edgePath').selectAll('path.path');
        this.edgesArrow = this.svgGroup.selectAll('g.edgePath').selectAll('marker path');
        this.edgesText = this.svgGroup.selectAll('g.edgeLabels').selectAll('text');
        // @Q@ 之後手動在線旁加文字寫在這邊
        // this.edgesPath.each((d: any, i: number, all: any) => {
        //   const edgeId = returnEdgeIdFromDagreId(d)
        //   const edgeData = this.EdgesMap.get(edgeId as string)
        //   console.log(edgeData)
        // })
        nodesG.each((d, i, all) => {
            let g = d3.select(all[i]);
            const node = this.NodesMap.get(d);
            // 取得 node寬度
            let iconX = 100;
            if (node && (node.hasTop || node.tags)) {
                try {
                    iconX = getExpendIconX(Number(g.select('rect').attr('width')));
                }
                catch (e) {
                    console.error(e);
                }
            }
            // -- 增加展開icon --
            if (node && node.hasTop) {
                let gSvgClassName = '';
                let svgContent = '';
                if (node.isTopExpanded === true) {
                    gSvgClassName = 'bp__minus-btn';
                    svgContent = this.minusSvgHtml;
                }
                else {
                    gSvgClassName = 'bp__plus-btn';
                    svgContent = this.plusSvgHtml;
                }
                g.selectAll('.bp__plus-btn').remove();
                g.selectAll('.bp__minus-btn').remove();
                const gSvg = g.append('svg')
                    .classed(gSvgClassName, true)
                    .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
                    .attr('xmls', 'http://www.w3.org/2000/svg')
                    .attr('version', '1.1')
                    .attr('width', fnIconSize)
                    .attr('height', fnIconSize)
                    .attr('viewBox', '0 0 511.993 511.993')
                    .attr('x', iconX)
                    .attr('y', -8)
                    .attr('cursor', 'pointer');
                gSvg
                    .on('click', (id) => {
                    const item = this.NodesMap.get(id);
                    // this.nodesExtandCallback(item!)
                    this.toggleTopNodes(item.id);
                })
                    .on('mouseover', (d, i, all) => {
                    d3.select(all[i])
                        .transition()
                        .duration(50)
                        .attr('width', fnIconActiveSize)
                        .attr('height', fnIconActiveSize)
                        .attr('y', -fnIconActiveOffset);
                })
                    .on('mouseout', (d, i, all) => {
                    d3.select(all[i])
                        .transition()
                        .delay(100)
                        .duration(100)
                        .attr('width', fnIconSize)
                        .attr('height', fnIconSize)
                        .attr('y', -8);
                });
                svgHtml(gSvg, svgContent);
            }
            // -- 增加tag --
            if (node.tags) {
                g.selectAll('.tagSVG').remove();
                node.tags.forEach((d, i) => {
                    const y = -((tagSize + 5) * node.tags.length / 2) + ((tagSize + 5) * i);
                    const tagSvg = g
                        .append("svg")
                        .classed("tagSVG", true)
                        .attr('id', d => `tagSvg__${node.id}`)
                        .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
                        .attr('xmls', 'http://www.w3.org/2000/svg')
                        .attr('version', '1.1')
                        .attr('cursor', 'default');
                    tagSvg
                        .attr('width', tagSize)
                        .attr('height', tagSize)
                        .attr('x', iconX)
                        .attr('y', y);
                    tagSvg.selectAll('circle').remove();
                    const style = d.style || 'tag';
                    svgHtml(tagSvg, this.returnTagHtml(this.styleConfig._StylesMap.get(style), d.text));
                    tagSvg
                        .on('mouseover', (d, i, nodes) => {
                        // 路徑顯示
                        if (node.edgesOfRoutes && node.edgesOfRoutes.length && node.routeData) {
                            this.activeRoute({
                                nodeData: node,
                                nodesRect: this.nodesRect,
                                edgesPath: this.edgesPath,
                                edgesArrow: this.edgesArrow,
                                edgesText: this.edgesText
                            });
                        }
                        // tooltip
                        const tagTooltipText = node.tagTooltip(node);
                        if (tagTooltipText) {
                            const x = d3.event.clientX + 20;
                            const y = d3.event.clientY + 20;
                            this.tooltip.setDatum({
                                data: tagTooltipText,
                                x,
                                y
                            });
                        }
                    })
                        .on('mousemove', d => {
                        // tooltip
                        if (this.tooltip != null) {
                            const x = d3.event.clientX + 20;
                            const y = d3.event.clientY + 20;
                            this.tooltip.setDatum({
                                x,
                                y
                            });
                        }
                    })
                        .on('mouseout', (d, i, all) => {
                        this.inactiveRoute({
                            nodesRect: this.nodesRect,
                            edgesPath: this.edgesPath,
                            edgesArrow: this.edgesArrow,
                            edgesText: this.edgesText
                        });
                        // tooltip
                        if (this.tooltip != null) {
                            this.tooltip.remove();
                        }
                    });
                });
            }
        });
        this.renderNode(nodesG, this.NodesMap);
        const nodeContainer = nodesG.select('.label-container');
        nodeContainer
            .attr('cursor', 'pointer');
        // 設滑鼠事件
        nodeContainer
            .on('click', (id) => {
            const item = this.NodesMap.get(id);
            this.clickCallback({
                data: item
            });
            if (this.params.extandOnNodeClick) {
                this.toggleTopNodes(item.id);
            }
        })
            .on('mouseover', (d, i, all) => {
            const nodeData = this.NodesMap.get(d);
            // 路徑顯示
            let content = '';
            if (nodeData) {
                content = nodeData.tooltip(nodeData);
                if (nodeData.edgesOfRoutes && nodeData.edgesOfRoutes.length && nodeData.routeData) {
                    this.activeRoute({
                        nodeData,
                        nodesRect: this.nodesRect,
                        edgesPath: this.edgesPath,
                        edgesArrow: this.edgesArrow,
                        edgesText: this.edgesText
                    });
                }
            }
            // tooltip
            if (content) {
                const x = d3.event.clientX + 20;
                const y = d3.event.clientY + 20;
                // this.tooltip = new TooltipFollowing(this.selection, content, x, y, true)
                this.tooltip.setDatum({
                    data: content,
                    x,
                    y
                });
            }
        })
            .on('mousemove', d => {
            // tooltip
            if (this.tooltip != null) {
                const x = d3.event.clientX + 20;
                const y = d3.event.clientY + 20;
                this.tooltip.setDatum({
                    x,
                    y
                });
            }
        })
            .on('mouseout', (d, i, all) => {
            // tooltip
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
            this.inactiveRoute({
                nodesRect: this.nodesRect,
                edgesPath: this.edgesPath,
                edgesArrow: this.edgesArrow,
                edgesText: this.edgesText
            });
        });
        // 取消文字指標
        nodesG.selectAll('tspan').attr('pointer-events', 'none');
        // 置中
        if (this.rootID !== rootID) {
            this.rootID = rootID;
            this.resize();
            this.resetZoom();
        }
        // highlight
        if (this.params.highlightId != undefined) {
            this.highlight(this.params.highlightId);
        }
        // this.setParams(this.params)
        // 初始化
        // if (setData === true) {
        // 置中
        // let xCenterOffset = (Number(this.svg.attr('width')) - this.chartG.graph().width) / 2
        // this.svgGroup.attr("transform", "translate(" + xCenterOffset + ", 20) scale(1)")
        // // this.svgGroup.attr('x', xCenterOffset)
        // // 滑鼠滾動放大縮小
        // let zoom = d3.zoom().on('zoom', () => {
        //   // this.svgGroup.attr('transform', d3.event.transform)
        //   // 偏移的座標由中心點計算
        //   this.svgGroup.attr('transform', `translate(
        //                                       ${d3.event.transform.x + (xCenterOffset * d3.event.transform.k)},
        //                                       ${d3.event.transform.y + (20 * d3.event.transform.k)}
        //                                     ) scale(
        //                                       ${d3.event.transform.k}
        //                                     )`)
        // })
        // this.svg.call(zoom)
        // this.resize()
        // }
        // // 設置事件監聽
        // this.svgGroup.selectAll('g.node')
        //   .on('click', (id) => {
        //     let item = this.renderDataOrigin.nodes.find(d => d.id === id)
        //     this.clickCallback(item)
        //   }) 
    }
    setStyle(styles) {
        // const newStyleConfig = returnStyle(this.styleConfig)
        const newStyleConfig = {
            styles,
            _StylesMap: new Map()
        };
        // 建立_StylesMap
        Object.keys(newStyleConfig.styles).forEach(key => {
            const value = newStyleConfig.styles[key];
            newStyleConfig._StylesMap.set(key, value);
        });
        this.styleConfig = newStyleConfig;
        // 設定展開按鈕顏色
        this.plusSvgHtml = this.returnPlusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'));
        this.minusSvgHtml = this.returnMinusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'));
    }
    resetNodes({ nodes = [], edges = [] }) {
        // 記住資料
        this.renderDataOrigin.nodes = nodes;
        this.renderDataOrigin.edges = edges;
        this.NodesMap = new Map();
        nodes.forEach(d => {
            this.NodesMap.set(d.id, d);
        });
    }
    changeDirection(direction) {
        this.direction = direction;
        // this.doRender({
        //   nodes: this.renderData.nodes,
        //   edges: this.renderData.edges,
        //   direction
        // })
    }
    toggleTopNodes(id) {
        // 找到目標節點，並將該節點展開狀態反過來（開或關）
        let findAllNodeID = null;
        let findShowNodeID = null;
        let currentIsTopExpanded = false;
        for (let i in this.renderDataOrigin.nodes) {
            if (this.renderDataOrigin.nodes[i].id === id) {
                // this.renderDataOrigin.nodes[i].isTopExpanded = !this.renderDataOrigin.nodes[i].isTopExpanded
                // findAllNode = this.renderDataOrigin.nodes[i]
                findAllNodeID = i;
                break;
            }
        }
        for (let i in this.renderData.nodes) {
            if (this.renderData.nodes[i].id === id) {
                // this.renderData.nodes[i].isTopExpanded = !this.renderData.nodes[i].isTopExpanded
                // findShowNode = this.renderData.nodes[i]
                findShowNodeID = i;
                break;
            }
        }
        if (findAllNodeID == null || findShowNodeID == null) {
            return;
        }
        currentIsTopExpanded = this.renderData.nodes[findShowNodeID].isTopExpanded;
        this.renderDataOrigin.nodes[findAllNodeID].isTopExpanded = !currentIsTopExpanded;
        this.renderData.nodes[findShowNodeID].isTopExpanded = !currentIsTopExpanded;
        // -- 重新計算要呈現的節點data --
        if (this.renderData.nodes[findShowNodeID].isTopExpanded === true) {
            let { nodes, edges } = addLoop(this.renderData, this.renderDataOrigin, this.NodesMap, id);
            this.renderData.nodes = nodes;
            this.renderData.edges = edges;
        }
        else {
            // 關閉node將該node指向的node移除掉
            // this.renderData = JSON.parse(JSON.stringify(this.renderData))
            let { nodes, edges } = deleteLoop(this.renderData, this.renderDataOrigin, id);
            this.renderData.nodes = nodes;
            this.renderData.edges = edges;
        }
        // this.NodesMap = new Map()
        // this.renderData.nodes.forEach(d => {
        //   this.NodesMap!.set(d.id, d)
        // })
        this.doRender({
            nodes: this.renderData.nodes,
            edges: this.renderData.edges,
            direction: this.direction
        });
    }
    highlight(highlightId) {
        const nodeData = this.NodesMap.get(highlightId);
        if (nodeData) {
            this.activeRoute({
                nodeData,
                nodesRect: this.nodesRect,
                edgesPath: this.edgesPath,
                edgesArrow: this.edgesArrow,
                edgesText: this.edgesText
            });
        }
    }
}
