import { ChartColumnBar } from '@bpchart/d3-modules/chartColumn';
import dataset from './dataset2';
export default {
    props: {
        module: ChartColumnBar,
        params: {
            axisColumn: {
                xLabel: '月份',
                yLabel: '人次',
                yTickFormat: ',.0f',
                rotateXLabel: false,
            },
            graphicColumnBar: {
                barType: 'rect',
                barPadding: 5,
            },
        },
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
