import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import { dateDiff, addDays } from '../utils';
import { parseLocalDate } from '../d3Utils';
// interface ComponentZoomDateRange {
//   transform: Transform; // x, y: 托曳座標 k: zoom比例
//   // autoZoom?: boolean; // 場景變更尺寸自動回復
//   initZoom (StartDate: Date, EndDate: Date, FilterStartDate?: Date, FilterEndDate?: Date): void; // 可設定預設偏移位置（依場景尺寸）
//   zoom (transform: Transform): void
// }
const zoomSpeed = 25;
export default class ChartDateHistogram {
    data = [];
    renderData;
    filteredRenderData; // 篩選過後的資料
    FilteredDateList; // 篩選過後的日期
    filteredGroupInfo = [];
    // public filteredMaxDate = ''
    // public filteredMinDate = ''
    filterConfig;
    params = {
        xLabel: 'x',
        yLabel: 'y',
        colors: DEFAULT_COLORS,
        backgroundColor: '#fafafa',
        timeFormat: '%Y-%m-%d',
        tickWidth: 80,
        groupInfo: [],
        zoom: true,
        padding: {
            top: 40,
            right: 40,
            bottom: 50,
            left: 50
        }
    };
    transform = {
        x: 0,
        y: 0,
        k: 1
    };
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    zoom = {
        xOffset: 0,
        yOffset: 0,
        scaleExtent: {
            min: 0,
            max: Infinity
        }
    };
    StartDate = new Date();
    EndDate = new Date();
    FilterStartDate = new Date();
    FilterEndDate = new Date();
    filterStartIndex = 0;
    chartStyleData = [
        {
            axisWidth: 0,
            axisHeight: 0,
            padding: {}
        }
    ];
    maxValue = 0;
    // 日期對應資料陣列（各別資料群組相同日期的資料）
    // protected DateDataMap: Map<Date, Array<RenderDatum>> = new Map()
    FilteredDateDataMap = new Map();
    colorScale;
    axisWidth = 0;
    axisHeight = 0;
    // protected padding = { 
    //   top: 0,
    //   right: 0,
    //   bottom: 0,
    //   left: 0
    // }
    timeFormatter = undefined;
    backgroundSelection = undefined;
    // protected chartSelection: (d3.Selection<SVGGElement, any, any, any> | undefined) = undefined
    coverSelection = undefined;
    // 座標軸選擇器
    axisSelection = undefined;
    // x座標軸選擇器
    xAxisSelection = undefined;
    // y座標軸選擇器
    yAxisSelection = undefined;
    // x軸標籤選擇器
    xLabelSelection = undefined;
    // y軸標籤選擇器
    yLabelSelection = undefined;
    // 圖形選擇器
    graphicSelection = undefined;
    // 圖形選擇器群組
    graphicGroupSelections = undefined;
    xScale;
    yScale;
    xRangeScale; // 滑鼠座標轉換為類別索引
    // protected xAxis?: d3.Axis<any>
    // protected yAxis?: d3.Axis<number | { valueOf(): number; }>
    d3Zoom = undefined;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    zoomCallback = function () { };
    _selection = undefined;
    // private dateList: Date[] = []
    // private minValue = 0
    // private maxValue = 0
    constructor(selection, params) {
        this._selection = selection;
        this.backgroundSelection = this.selection
            .append('g')
            .classed('bpchart__background', true);
        // this.chartSelection = this.selection
        //   .append('g')
        //   .classed('bpchart__chart', true)
        this.graphicGroupSelections = this.selection
            .append('g')
            .classed('bpchart__graphic-group', true);
        this.axisSelection = this.selection
            .append('g')
            .classed('bpchart__axis', true);
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        // y label
        this.selection
            .append('text')
            .classed('bpchart__yLabel', true)
            .style('font-size', '14px')
            .style('color', '#606060')
            // .style('font-weight', 'bold')
            .style('pointer-events', 'none')
            .attr('text-anchor', 'end')
            .attr('x', this.params.padding.left - 20)
            .attr('y', 20)
            .text(this.params.yLabel);
        // this.debounceRender = debounce(this.render.bind(this))
    }
    get selection() {
        return this._selection;
    }
    select() {
        return this._selection;
    }
    remove() {
        this._selection.remove();
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.timeFormatter = d3.timeFormat(this.params.timeFormat);
    }
    // 渲染
    setData(data) {
        // this.beforeRender(data)
        if (!data || !data.length || !this.params.groupInfo || data.length > this.params.groupInfo.length) {
            return;
        }
        this.data = data;
        // 產生渲染資料
        this.renderData = data.map((d, i) => {
            return d.map((item, itemIndex) => {
                let newItem = item; // reference
                newItem._dateIndex = itemIndex;
                newItem._Date = parseLocalDate(item.date); // 產生日期物件
                newItem._groupInfo = this.params.groupInfo[i];
                newItem._groupId = this.params.groupInfo[i].id;
                newItem._x = -1; // 座標。resize之後再計算
                return newItem;
            });
        });
        // 紀錄 StartDate, EndDate
        if (this.renderData.length && this.renderData[0].length) {
            this.StartDate = this.renderData[0][0]._Date;
            this.EndDate = this.renderData[0][this.renderData[0].length - 1]._Date;
            this.FilterStartDate = this.StartDate;
            this.FilterEndDate = this.EndDate;
        }
        // 建立描述資料（如params和實際資料筆數不同）
        // if (this.params.groupInfo!.length != data.length) {
        //   this.params.groupInfo = data.map((d, i) => {
        //     return {
        //       id: String(i),
        //       label: String(i),
        //       // _color: DEFAULT_COLORS[i % DEFAULT_COLORS.length]
        //     }
        //   })
        // }
        // // 建立日期對應資料陣列（各別資料群組相同日期的資料）
        // this.dateDataMap = new Map()
        // if (this.renderData[0].length) {
        //   for (let i = 0; i < this.renderData[0].length; i ++) {
        //     this.dateDataMap.set(
        //       this.renderData[0][i].date,
        //       this.renderData.map(d => d[i])
        //     )
        //   }
        // }
        // 清空各圖形群組選擇器
        // this.graphicGroupSelections.forEach(d => {
        //   d.remove()
        // })
        // this.graphicGroupSelections = []
        // this.resize({
        //   width: this.params.width!,
        //   height: this.params.height!
        // })
        // this.resize({
        //   width: Number(this.selection.attr('width')),
        //   height: Number(this.selection.attr('height'))
        // })
    }
    filter(filterConfig) {
        this.filterConfig = filterConfig;
        // 紀錄 FilterStartDate, FilterEndDate
        this.FilterStartDate = this.filterConfig.startDate
            ? parseLocalDate(this.filterConfig.startDate)
            : new Date(this.StartDate);
        this.FilterEndDate = this.filterConfig.endDate
            ? parseLocalDate(this.filterConfig.endDate)
            : new Date(this.EndDate);
        // this.render()
        // console.log(this.filterConfig)
    }
    resize({ width = DEFAULT_CHART_WIDTH, height = DEFAULT_CHART_HEIGHT }) {
        this.width = width;
        this.height = height;
        this.selection
            .attr('width', width)
            .attr('height', height);
        this.axisWidth = width - this.params.padding.left - this.params.padding.right;
        this.axisHeight = height - this.params.padding.top - this.params.padding.bottom;
        this.renderBackground(this.backgroundSelection);
        // x axis
        const xAxisUpdate = this.axisSelection
            .selectAll('g.bpchart__xAxis')
            .data([{ width, height }]);
        this.xAxisSelection = xAxisUpdate
            .enter()
            .append('g')
            .classed("bpchart__xAxis", true)
            .merge(xAxisUpdate)
            .attr("transform", "translate(" + this.params.padding.left + "," + (this.params.padding.top + this.axisHeight) + ")");
        xAxisUpdate
            .exit()
            .remove();
        // y axis
        const yAxisUpdate = this.axisSelection
            .selectAll('g.bpchart__yAxis')
            .data([{ width, height }]);
        this.yAxisSelection = yAxisUpdate
            .enter()
            .append('g')
            .classed("bpchart__yAxis", true)
            .attr("transform", "translate(" + this.params.padding.left + "," + this.params.padding.top + ")")
            .merge(yAxisUpdate);
        yAxisUpdate
            .exit()
            .remove();
        // chart
        // const chartUpdate = this.chartSelection!
        //   .selectAll<SVGGElement, { width: number; height: number; }>('g.bpchart__chart')
        //   .data([size])
        // this.graphicSelection = chartUpdate
        //   .enter()
        //   .append('g')
        //   .classed("bpchart__chart", true)
        //   .merge(chartUpdate)
        // chartUpdate
        //   .exit()
        //   .remove()
        // x label
        const xLabelUpdate = this.selection
            .selectAll('text.bpchart__xLabel')
            .data([{ width, height }]);
        const xLabelEnter = xLabelUpdate
            .enter()
            .append('text')
            .text(this.params.xLabel)
            .classed('bpchart__xLabel', true)
            .style('font-size', '14px')
            .style('color', '#606060')
            // .style('font-weight', 'bold')
            .style('pointer-events', 'none')
            .attr('text-anchor', 'end');
        this.xLabelSelection = xLabelUpdate
            .merge(xLabelEnter)
            .attr('x', d => d.width - 6)
            .attr('y', this.params.padding.top + this.axisHeight + 35);
        xLabelUpdate
            .exit()
            .remove();
        // this.initZoom()
        // this.render()
    }
    // debounceRender () {}
    render() {
        // 禁止空值
        if (!this.renderData || !this.renderData.length || !this.renderData[0].length) {
            return;
        }
        const dateList = this.renderData[0]?.map(d => d._Date) ?? [];
        let filteredGroupInfo = this.params.groupInfo.map((d, i) => {
            let newD = d;
            newD._color = this.params.colors[i % this.params.colors.length];
            return newD;
        });
        // 篩選資料
        let filteredRenderData = this.renderData;
        let FilteredDateList = dateList.map(d => d);
        if (this.filterConfig) {
            // 篩選資料項目
            if (this.filterConfig.filterItems) {
                const filterItems = this.filterConfig.filterItems;
                filteredRenderData = filteredRenderData.filter((d, i) => {
                    return filterItems.includes(this.params.groupInfo[i].id);
                });
                filteredGroupInfo = filteredGroupInfo.filter(d => {
                    return filterItems.includes(d.id);
                });
            }
            // 篩選日期
            const StartDate = this.filterConfig.startDate
                ? parseLocalDate(this.filterConfig.startDate)
                : dateList[0];
            const EndDate = this.filterConfig.endDate
                ? parseLocalDate(this.filterConfig.endDate)
                : dateList[dateList.length - 1];
            filteredRenderData = filteredRenderData.map(d => {
                return d.filter(item => {
                    return item._Date >= StartDate && item._Date <= EndDate;
                });
            });
            FilteredDateList = FilteredDateList.filter(d => {
                return d >= StartDate && d <= EndDate;
            });
        }
        // 禁止空值
        if (!FilteredDateList.length) {
            return;
        }
        // filterStartIndex
        if (filteredRenderData[0] && filteredRenderData[0].length) {
            this.filterStartIndex = this.renderData[0].map((d, i) => d.date).indexOf(filteredRenderData[0][0].date);
        }
        else {
            this.filterStartIndex = 0;
        }
        // this.filterStartIndex = filteredRenderData[0].length
        //   ? filteredRenderData[0][0]._dateIndex
        //   : 0
        this.filteredRenderData = filteredRenderData.map((d => {
            return d.map((item, i) => {
                let newD = item;
                newD._dateIndex = i; // 重新編號日期索引
                return newD;
                // return {
                //   ...item,
                //   _dateIndex: i // 重新編號日期索引
                // }
            });
        }));
        this.FilteredDateList = FilteredDateList;
        this.filteredGroupInfo = filteredGroupInfo;
        // 建立日期對應資料陣列（各別資料群組相同日期的資料）
        this.FilteredDateDataMap = new Map();
        if (this.filteredRenderData[0] && this.filteredRenderData[0].length) {
            for (let i = 0; i < this.filteredRenderData[0].length; i++) {
                this.FilteredDateDataMap.set(this.filteredRenderData[0][i].date, this.filteredRenderData.map(d => d[i]));
            }
        }
        // 顏色比例尺
        this.colorScale = d3.scaleOrdinal()
            .domain(this.filteredGroupInfo.map(d => d.id))
            .range(this.filteredGroupInfo.map(d => d._color));
        // 最大及最小資料
        const minValue = 0;
        let maxValue = 0;
        for (let index in this.filteredRenderData) {
            for (let i in this.filteredRenderData[index]) {
                if ((this.filteredRenderData[index][i]?.value ?? 0) > maxValue) {
                    maxValue = this.filteredRenderData[index][i].value;
                }
            }
        }
        this.maxValue = maxValue;
        // 比例尺
        this.xScale = d3.scaleTime()
            .domain([this.FilteredDateList[0], this.FilteredDateList[this.FilteredDateList.length - 1]])
            .range([0, this.axisWidth]);
        this.yScale = d3.scaleLinear()
            .domain([this.maxValue / 0.95, minValue]) // 因為要由下往上所以反過來
            .range([0, this.axisHeight]);
        // x座標轉換日期索引比例尺
        const halfRange = (this.axisWidth / this.FilteredDateList.length) / 2;
        const startDomain = -halfRange;
        const endDomain = this.axisWidth + halfRange;
        this.xRangeScale = d3.scaleQuantize()
            .domain([startDomain, endDomain])
            .range(this.FilteredDateList.map((d, i) => i));
        // 計算座標資料
        this.renderData = this.renderData.map(arr => {
            return arr.map(d => {
                d._x = this.xScale(d._Date);
                return d;
            });
        });
        // 繪製座標軸
        this.renderAxis({
            xScale: this.xScale,
            yScale: this.yScale
        });
        this.setRangeEvent(this.xRangeScale);
        // 建立圖形群組
        const gUpdate = this.graphicGroupSelections
            .selectAll('g.bpchart__graphic')
            .data(this.filteredRenderData);
        this.graphicSelection = gUpdate
            .enter()
            .append('g')
            .classed('bpchart__graphic', true)
            .merge(gUpdate);
        // .attr('width', d => d.width)
        // .attr('height', d => d.height)
        gUpdate.exit().remove();
        // 繪製複蓋區域
        this.renderCover({
            coverSelection: this.coverSelection,
            data: this.filteredRenderData,
            xScale: this.xScale,
            yScale: this.yScale
        });
        // 繪製各組資料圖形
        this.graphicSelection.each((d, i, all) => {
            this.renderGraphic({
                graphicSelection: d3.select(all[i]),
                id: this.filteredGroupInfo[i].id,
                data: d,
                xScale: this.xScale,
                yScale: this.yScale
            });
        });
        // init zoom
        if (!this.params.zoom) {
            this.removeZoom();
            return;
        }
        const MinZoomStartDate = this.StartDate;
        const MaxZoomStartDate = addDays(this.FilterEndDate, -1);
        this.initZoom({
            ...this.zoom,
            scaleExtent: {
                min: 1 - dateDiff(this.FilterStartDate, MinZoomStartDate) / zoomSpeed,
                max: 1 + dateDiff(MaxZoomStartDate, this.FilterStartDate) / zoomSpeed
            }
        });
    }
    // 設置拖拽及放大縮小
    initZoom(zoom = this.zoom) {
        this.zoom = {
            ...this.zoom,
            ...zoom
        };
        const calcDateDiffByK = (kDiff) => kDiff * zoomSpeed;
        // 滑鼠滾動放大縮小
        this.selection.on('zoom', null);
        this.d3Zoom = d3.zoom()
            .scaleExtent([this.zoom.scaleExtent.min, this.zoom.scaleExtent.max])
            .on('zoom', () => {
            this.transform = {
                x: d3.event.transform.x,
                y: d3.event.transform.y,
                k: d3.event.transform.k
            };
            if (!this.renderData || !this.renderData[0] || !this.renderData[0].length) {
                return;
            }
            if (d3.event.sourceEvent.type === 'mousemove') {
                if (this.params.zoom && this.onZoomMouse) {
                    this.onZoomMouse(d3.event);
                }
            }
            else if (d3.event.sourceEvent.type === 'wheel') {
                const kDiff = d3.event.transform.k - 1;
                const dateDiff = calcDateDiffByK(kDiff);
                let index = this.filterStartIndex + Math.round(dateDiff);
                if (index < 0) {
                    index = 0;
                }
                else if (index >= this.renderData[0].length) {
                    index = this.renderData[0].length - 1;
                }
                const filterStartDate = this.renderData[0][index].date;
                if (this.params.zoom && this.onZoomWheel) {
                    this.onZoomWheel(filterStartDate, d3.event);
                }
            }
        });
        this.selection.call(this.d3Zoom);
    }
    removeZoom() {
        this.d3Zoom = d3.zoom()
            .on('zoom', null);
    }
    transformZoom(transform) {
        this.transform = transform;
        // 設定 d3.event.transform 並觸發 d3.zoom().on()
        if (this.d3Zoom && this.d3Zoom.transform) {
            this.selection.call(this.d3Zoom.transform, d3.zoomIdentity
                .translate(this.transform.x, this.transform.y)
                .scale(this.transform.k));
        }
    }
    // 事件
    // abstract on (actionName: string, callback: (d: Callback) => void): any
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        else if (actionName === 'zoom') {
            this.zoomCallback = callback;
        }
        return this;
    }
    // protected abstract onZoom (eventTransform: { k:number; x:number; y:number }): void
    renderAxis({ xScale, yScale }) {
        const maxXTicks = Math.floor(this.axisWidth / this.params.tickWidth);
        const minXTicks = 1;
        const maxYTicks = Math.floor(this.axisHeight / this.params.tickWidth);
        const minYTicks = 1;
        let xTicks = this.FilteredDateList.length - 1;
        let yTicks = this.maxValue - 1;
        if (xTicks < minXTicks) {
            xTicks = minXTicks;
        }
        else if (xTicks > maxXTicks) {
            xTicks = maxXTicks;
        }
        if (yTicks < minYTicks) {
            yTicks = minYTicks;
        }
        else if (yTicks > maxYTicks) {
            yTicks = maxYTicks;
        }
        // 設定X軸刻度
        const xAxis = d3.axisBottom(xScale)
            .ticks(xTicks)
            // .tickValues(this.FilteredDateList!)
            // .ticks(d3.timeDay, 1)
            .tickFormat(this.timeFormatter)
            // .tickFormat((d: any) => {
            //   return this.timeFormatter!(new Date(d))
            // })
            .tickPadding(20);
        // 設定Y軸刻度
        const yAxis = d3.axisLeft(yScale)
            .ticks(yTicks) // 刻度分段數量
            .tickFormat((d) => {
            return d;
        })
            .tickSize(-this.axisWidth)
            .tickPadding(20);
        const xAxisEl = this.xAxisSelection
            .transition()
            .duration(50)
            .call(xAxis);
        const yAxisEl = this.yAxisSelection
            .transition()
            .duration(100)
            .call(yAxis);
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', '#e1e1e1');
        yAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', '#606060')
            .style("stroke-dasharray", (d, i, all) => {
            if (i === all.length - 1) {
                return 'none';
            }
            return '5 5';
        })
            .style('pointer-events', 'none');
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', '#e1e1e1')
            .style('shape-rendering', 'crispEdges');
        yAxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', '#e1e1e1')
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '12px')
            // .style('font-weight', 'bold')
            .style('color', '#606060')
            .style('pointer-events', 'none');
        yAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '12px')
            .style('color', '#606060')
            .style('pointer-events', 'none');
    }
    renderBackground(backgroundSelection) {
        if (this.chartStyleData == null) {
            return;
        }
        // 建立底圖
        this.chartStyleData[0].axisWidth = this.axisWidth;
        this.chartStyleData[0].axisHeight = this.axisHeight;
        this.chartStyleData[0].padding = Object.assign({}, this.params.padding);
        const backgroundUpdate = backgroundSelection
            .selectAll('rect.bpchart__chart-bg')
            .data(this.chartStyleData);
        backgroundUpdate
            .enter()
            .append('rect')
            .classed('bpchart__chart-bg', true)
            .merge(backgroundUpdate)
            .attr("transform", d => "translate(" + d.padding.left + "," + d.padding.top + ")")
            .attr('width', d => d.axisWidth)
            .attr('height', d => d.axisHeight)
            .attr('fill', this.params.backgroundColor);
        backgroundUpdate.exit().remove();
    }
}
