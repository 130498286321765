import moment from 'moment';
// 加上千分位 ,
export function formatNumberWithComma(num) {
    if (num == null || num == '' || isNaN(num) == true) {
        return num;
    }
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}
// 百分比文字
export const formatPercentage = (n) => {
    if (n == null || n <= 0) {
        return '0%';
    }
    else if (n < 0.01) {
        return '< 0.01%';
    }
    let newN = Math.round(n * 100) / 100;
    if (newN > 0) {
        return `${newN}%`;
    }
    else {
        return '< 0.01%';
    }
};
export const formatTimeText = (totalSeconds) => {
    const years = moment.duration(totalSeconds, 'seconds').years();
    const months = moment.duration(totalSeconds, 'seconds').months();
    const days = moment.duration(totalSeconds, 'seconds').days();
    const hours = moment.duration(totalSeconds, 'seconds').hours();
    const minutes = moment.duration(totalSeconds, 'seconds').minutes();
    const seconds = moment.duration(totalSeconds, 'seconds').seconds();
    let yearsText = years ? `${years} 年 ` : '';
    let monthsText = months ? `${months} 月 ` : '';
    let daysText = days ? `${days} 天 ` : '';
    let hoursText = hours ? `${hours} 小時 ` : '';
    let minutesText = minutes ? `${minutes} 分鐘 ` : '';
    let secondsText = seconds ? `${seconds} 秒 ` : '';
    return ` ${yearsText}${monthsText}${daysText}${hoursText}${minutesText}${secondsText}`;
};
