import { DEFAULT_COLORS } from '../defaults';
export const DEFAULT_GRAPHIC_DOT_PARAMS = {
    r: 4,
    fill: '#fff',
    strokeWidth: 2,
    colors: DEFAULT_COLORS,
    highlightTarget: 'item',
    highlightItemId: '',
    highlightDatumId: '',
    enterDuration: 20
};
