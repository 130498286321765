import { DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_AXIS_ROW_PARAMS = {
    xLabel: 'x',
    yLabel: 'y',
    xLabelAnchor: 'start',
    yLabelAnchor: 'end',
    xLabelOffset: [0, 0],
    yLabelOffset: [0, 0],
    xTickPadding: 20,
    yTickPadding: 20,
    xTickLine: true,
    yTickLine: false,
    xTickLineDasharray: 'none',
    yTickLineDasharray: 'none',
    xTickFormat: ',.0f',
    xTicks: 4,
    axisLineColor: DEFAULT_ASIX_LINE_COLOR,
    axisLabelColor: DEFAULT_AXIS_LABEL_COLOR,
    rotateYLabel: false,
    padding: DEFAULT_PADDING,
    xVisible: true,
    yVisible: true,
    yLabelIconMethod: undefined
};
