import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '../defaults';
import TooltipFollowing from '../tooltip/TooltipFollowing';
export default class Template {
    selection;
    dataset = {
        nodes: [],
        links: []
    };
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    //	d3 color scheme
    color = d3.scaleOrdinal(d3.schemeCategory10);
    link;
    node;
    linkG;
    nodeG;
    //	simulation initialization
    simulation = d3.forceSimulation();
    NodesMap = new Map();
    tooltip = undefined;
    clickCallback = function () { return null; };
    mouseoverCallback = function () { return null; };
    mousemoveCallback = function () { return null; };
    mouseoutCallback = function () { return null; };
    constructor(el) {
        this.selection = el;
        this.selection.append("defs").selectAll("marker")
            .data(["suit", "licensing", "resolved"])
            .enter().append("marker")
            .attr("id", (d) => { return d; })
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 18)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("fill", "gray")
            .attr("d", "M0,-5L10,0L0,5");
        this.linkG = this.selection.append("g"),
            this.nodeG = this.selection.append("g");
        this.tooltip = new TooltipFollowing(this.selection, {
            templateHtml: (data) => `
        <div>${data.id}</div>
      `,
            insideBoxMode: true,
            type: 'black'
        });
    }
    remove() {
        this.selection.remove();
    }
    setDataset(dataset) {
        this.dataset.nodes = dataset.nodes.map(d => {
            return {
                ...d,
                ...this.NodesMap.get(d.id)
            };
        });
        this.dataset.links = dataset.links.map(d => {
            return {
                ...d,
            };
        });
    }
    resize({ width, height }) {
        this.width = width;
        this.height = height;
        this.simulation = d3.forceSimulation()
            .force("link", d3.forceLink()
            .id((d) => { return d.id; }))
            .force("charge", d3.forceManyBody()
            .strength((d) => { return -500; }))
            .force("center", d3.forceCenter(this.width / 2, this.height / 2));
    }
    render() {
        // Update link set based on current state
        // DATA JOIN
        const linkUpdate = this.linkG.selectAll(".link").data(this.dataset.links);
        // EXIT
        // Remove old links
        linkUpdate.exit().remove();
        // ENTER
        // Create new links as needed.	
        this.link = linkUpdate.enter().append("line")
            .attr("class", "link")
            .style("stroke", "gray")
            .style("stroke-width", 2)
            .attr("marker-end", (d) => { return "url(#suit)"; })
            .merge(linkUpdate);
        // DATA JOIN
        const nodeUpdate = this.nodeG.selectAll(".node").data(this.dataset.nodes);
        // EXIT
        nodeUpdate.exit().remove();
        // ENTER
        this.node = nodeUpdate.enter().append("circle")
            .attr("class", "node")
            .attr("r", 10)
            .style("stroke", "white")
            .style("stroke-width", 2)
            .call(d3.drag()
            .on("start", (d) => this.dragstarted(d))
            .on("drag", (d) => this.dragged(d))
            .on("end", (d) => this.dragended(d)))
            .merge(nodeUpdate);
        this.node
            .attr("fill", (d) => { return this.color(d.group); })
            .on('click', d => {
            this.clickCallback(d);
        })
            .on('mouseover', d => {
            this.mouseoverCallback(d);
            const x = d3.event.clientX + 5;
            const y = d3.event.clientY + 5;
            // this.tooltip = new TooltipFollowing(this.selection, content, x, y, true)
            this.tooltip.setDatum({
                data: d,
                x,
                y
            });
        })
            .on('mousemove', (d) => {
            this.mousemoveCallback(d);
            const x = d3.event.clientX + 5;
            const y = d3.event.clientY + 5;
            // this.tooltip.move(x, y)
            this.tooltip.setDatum({
                x,
                y
            });
        })
            .on('mouseout', (d) => {
            this.mouseoutCallback(d);
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
        });
        //	Set nodes, links, and alpha target for simulation
        this.simulation
            .nodes(this.dataset.nodes)
            .on("tick", () => this.ticked());
        ;
        this.simulation
            .force("link")
            .links(this.dataset.links);
        this.simulation.alphaTarget(0.3).restart();
        this.NodesMap = new Map(this.dataset.nodes.map(d => [d.id, d]));
    }
    select() {
        return this.selection;
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    //	drag event handlers
    dragstarted(d) {
        if (!d3.event.active)
            this.simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    }
    dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
    }
    dragended(d) {
        if (!d3.event.active)
            this.simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    }
    //	tick event handler (nodes bound to container)
    ticked() {
        this.link
            .attr("x1", (d) => { return d.source.x; })
            .attr("y1", (d) => { return d.source.y; })
            .attr("x2", (d) => { return d.target.x; })
            .attr("y2", (d) => { return d.target.y; });
        this.node
            .attr("cx", (d) => { return d.x; })
            .attr("cy", (d) => { return d.y; });
    }
}
