import * as d3 from 'd3';
import { formatCommaNumber, formatPercentage } from '../utils';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '../defaults';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { DEFAULT_GRAPHIC_ARC_PIE_PARAMS } from './defaults';
import { makeColorScale, makeArc } from '../moduleUtils';
const makeTweenPieRenderDataFn = ({ pathSelection, enter, exit, data, lastData, params }) => {
    // 無更新資料項目則只計算資料變化 (新資料 * t + 舊資料 * (1 - t))
    if (pathSelection && !enter.size() && !exit.size()) {
        return (t) => {
            const tweenData = data.map((_d, _i) => {
                const lastDatum = lastData[_i] ?? {
                    startAngle: 0,
                    endAngle: 0,
                    value: 0
                };
                return {
                    ..._d,
                    startAngle: (_d.startAngle * t) + (lastDatum.startAngle * (1 - t)),
                    endAngle: (_d.endAngle * t) + (lastDatum.endAngle * (1 - t)),
                    value: (_d.value * t) + (lastDatum.value * (1 - t))
                };
            });
            return makePieRenderData(tweenData, params.startAngle, params.endAngle, 1);
        };
        // 有更新資料則重新繪圖
    }
    else {
        return (t) => {
            return makePieRenderData(data, params.startAngle, params.endAngle, t);
        };
    }
};
// const makeRadiusScale = (axisWidth: number) => {
//   return d3.scaleLinear()
//     .domain([0, 1])
//     .range([0, axisWidth / 2])
// }
const makeItemDataMap = (data) => {
    const ItemDataMap = new Map();
    data.forEach(d => {
        const itemData = ItemDataMap.get(d.data.itemLabel) ?? [];
        itemData.push(d);
        ItemDataMap.set(d.data.itemLabel, itemData);
    });
    return ItemDataMap;
};
const makePieRenderData = (data, startAngle, endAngle, t) => {
    return data.map((d, i) => {
        const _startAngle = startAngle + (d.startAngle - startAngle) * t;
        const _endAngle = _startAngle + (d.endAngle - d.startAngle) * t;
        return {
            ...d,
            startAngle: _startAngle,
            endAngle: _endAngle
        };
    });
};
// export const makeArc = ({ axisWidth, innerRadius, outerRadius, padAngle, cornerRadius }: {
//   axisWidth: number
//   innerRadius: number
//   outerRadius: number
//   padAngle: number
//   cornerRadius: number
// }): d3.Arc<any, d3.DefaultArcObject> => {
//   const arcScale = d3.scaleLinear()
//     .domain([0, 1])
//     .range([0, axisWidth / 2])
//   const _outerRadius = arcScale(outerRadius)!
//   return d3.arc()
//     .innerRadius(arcScale(innerRadius)!)
//     .outerRadius(_outerRadius)
//     .padAngle(padAngle)
//     .padRadius(_outerRadius)
//     .cornerRadius(cornerRadius)
// }
const makeCallbackData = ({ dataset, datum, ItemDataMap, event }) => {
    return {
        dataset,
        groupData: datum ? [datum] : [],
        itemData: datum ? ItemDataMap.get(datum.data.itemLabel) : [],
        datum: datum ? datum : null,
        itemLabel: datum ? datum.data.itemLabel : '',
        itemIndex: datum ? datum.data.itemIndex : -1,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
const makeEnterDurationCallbackData = (dataset) => {
    return {
        dataset,
        groupData: [],
        itemData: [],
        datum: null,
        itemLabel: '',
        itemIndex: -1,
        clientX: -1,
        clientY: -1,
        offsetX: -1,
        offsetY: -1
    };
};
export default class GraphicArcPie {
    params = DEFAULT_GRAPHIC_ARC_PIE_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        axisWidth: 0
    };
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    lastData = []; // 紀錄前次的資料
    pieRenderData = [];
    colorScale;
    radiusScale; // 0~1 => 半徑（為1時等於場景高度的一半）
    gSelection;
    pathSelection = undefined;
    arc;
    arcMouseover;
    ItemDataMap = new Map;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    enterDurationCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
        this.gSelection = selection.append('g');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.ItemDataMap = makeItemDataMap(dataset.data);
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
    }
    render() {
        // 弧產生器 (d3.arc())
        this.arc = makeArc({
            axisWidth: this.dataset.axisWidth,
            innerRadius: this.params.innerRadius,
            outerRadius: this.params.outerRadius,
            padAngle: this.params.padAngle,
            cornerRadius: this.params.cornerRadius
        });
        this.arcMouseover = makeArc({
            axisWidth: this.dataset.axisWidth,
            innerRadius: this.params.innerRadius,
            outerRadius: this.params.outerMouseoverRadius,
            padAngle: this.params.padAngle,
            cornerRadius: this.params.cornerRadius
        });
        let update = this.gSelection
            .selectAll('path')
            .data(this.dataset.data, d => d.data.id);
        let enter = update.enter();
        let exit = update.exit();
        const makeTweenPieRenderData = makeTweenPieRenderDataFn({
            pathSelection: this.pathSelection,
            enter,
            exit,
            data: this.dataset.data,
            lastData: this.lastData,
            params: this.params
        });
        // 甜甜圈圖動畫
        this.gSelection
            .transition()
            .duration(this.params.enterDuration)
            .tween('move', (d, i, nodes) => {
            return (t) => {
                this.pieRenderData = makeTweenPieRenderData(t);
                this.renderDonut(this.gSelection, this.pieRenderData, this.arc);
                const callbackData = makeEnterDurationCallbackData(this.dataset);
                this.enterDurationCallback(callbackData, t);
            };
        })
            .on('end', (d, i, nodes) => {
            this.pieRenderData = makePieRenderData(this.dataset.data, this.params.startAngle, this.params.endAngle, 1);
            this.renderDonut(this.gSelection, this.pieRenderData, this.arc);
            if (this.params.highlightTarget && this.params.highlightTarget != 'none') {
                this.pathSelection.style('cursor', 'pointer');
            }
            this.pathSelection && this.setDonutEvent(this.pathSelection);
            this.initHighlight();
            // 渲染完後紀錄為前次的資料
            this.lastData = Object.assign([], this.dataset.data);
        });
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        else if (actionName === 'enterDuration') {
            this.enterDurationCallback = callback;
        }
        return this;
    }
    initHighlight() {
        this.removeHighlight();
        if (this.params.highlightItemId || this.params.highlightDatumId) {
            this.highlight(this.pathSelection, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    highlight(pathSelection, itemId, datumId) {
        if (!pathSelection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .filter(d => d.data.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        pathSelection.each((d, i, n) => {
            const segment = d3.select(n[i]);
            if (ids.includes(d.data.id)) {
                segment
                    .transition()
                    .ease(d3.easeElastic)
                    .duration(500)
                    .style('opacity', 1)
                    .attr('d', (d) => {
                    return this.arcMouseover(d);
                });
                // .on('interrupt', () => {
                //   // this.pathSelection!.select('path').attr('d', (d) => {
                //   //   return this.arc!(d as any)
                //   // })
                //   this.initHighlight()
                // })
                // // 中間文字
                // // const segmentData = (segment.data())[0]
                // this.renderMiddleBlock(this.middleSelection, [d])
            }
            else {
                // 取消放大
                segment.transition()
                    .style('opacity', 0.3)
                    .attr('d', (d) => {
                    return this.arc(d);
                });
            }
        });
    }
    removeHighlight() {
        if (!this.pathSelection) {
            return;
        }
        // 取消放大
        this.pathSelection
            .transition()
            .style('opacity', 1)
            .attr('d', (d) => {
            return this.arc(d);
        });
    }
    // 繪製圓餅圖
    renderDonut(selection, pieRenderData, arc) {
        let update = selection
            .selectAll('path')
            .data(pieRenderData, d => d.data.id);
        let enter = update.enter()
            .append('path')
            .classed('bpchart__pie-path', true);
        let exit = update.exit();
        enter
            .append('path');
        this.pathSelection = update.merge(enter);
        this.pathSelection
            .attr('fill', (d, i) => {
            return this.colorScale(d.data.itemLabel);
        })
            // .transition()
            .attr('d', (d, i) => {
            return arc(d);
        });
        exit.remove();
    }
    // // 用動畫重繪圖形
    // private transitionDonut (pathSelection: d3.Selection<SVGPathElement, Datum, any, any>, arc: d3.Arc<any, d3.DefaultArcObject>) {
    //   pathSelection!
    //     .attr('fill', (d, i) => {
    //       return this.colorScale!(d.data.itemLabel)
    //     })
    //     .transition()
    //     .duration(this.params.enterDuration!)
    //     .tween('move', (d, i, nodes) => {
    //       return (t) => {
    //         const callbackData = makeEnterDurationCallbackData(this.dataset)
    //         this.enterDurationCallback(callbackData, t)
    //       }
    //     })
    //     .attr('d', (d, i) => {
    //       return arc!(d as any)
    //     })
    //     .on('end', () => this.initHighlight())
    // }
    setDonutEvent = (pathSelection) => {
        pathSelection
            .on('mouseover', (d, i, all) => {
            d3.event.stopPropagation();
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'item') {
                    this.highlight(pathSelection, d.data.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(pathSelection, undefined, d.data.id);
                }
            }
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i, all) => {
            d3.event.stopPropagation();
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i, all) => {
            d3.event.stopPropagation();
            this.initHighlight();
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.mouseoutCallback(callbackData);
        })
            .on('click', (d, i, all) => {
            d3.event.stopPropagation();
            const callbackData = makeCallbackData({
                dataset: this.dataset,
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            // callback
            this.clickCallback(callbackData);
        });
    };
}
