import * as d3 from 'd3';
import { DEFAULT_PADDING, DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, } from '@bpchart/d3-modules/defaults';
import { UtilAxisLabel } from '../utilAxisLabel';
import { DEFAULT_AXIS_ROW_DIVERGING_PARAMS } from './defaults';
// import { Padding } from '../types'
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import { parseTickFormatValue } from '../d3Utils';
const defaultTickSize = 6;
export default class AxisRowDiverging {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        x1Scale: d3.scaleLinear(),
        x2Scale: d3.scaleLinear(),
        y1Scale: d3.scalePoint(),
        y2Scale: d3.scalePoint(),
        maxValue: 0
    };
    params = DEFAULT_AXIS_ROW_DIVERGING_PARAMS;
    utilAxisLabel;
    x1AxisSelection = undefined;
    x2AxisSelection = undefined;
    y1AxisSelection = undefined;
    y2AxisSelection = undefined;
    x1Axis;
    x2Axis;
    y1Axis;
    y2Axis;
    // private xLabelSelection: (d3.Selection<SVGTextElement, Params, any, unknown> | undefined) = undefined
    // private yLabelSelection: (d3.Selection<SVGTextElement, Params, any, unknown> | undefined) = undefined
    axisWidth = calcAxisWidth(this.width, DEFAULT_PADDING);
    axisHeight = calcAxisHeight(this.height, DEFAULT_PADDING);
    constructor(selection, params) {
        this.selection = selection;
        // this.chartSelection = this.selection.append('g')
        this.utilAxisLabel = new UtilAxisLabel(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.utilAxisLabel.setParams({
            // 對應四個 label的顏色
            colors: [
                this.params.axisLabelColor,
                this.params.axisLabelColor,
                this.params.axisLabelColor,
                this.params.axisLabelColor
            ],
        });
    }
    setDataset(dataset) {
        this.dataset = dataset;
        // console.log(dataset)
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = calcAxisWidth(this.width - this.params.gutterWidth, this.params.padding) / 2;
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
    }
    render() {
        this.renderY1PointAxis();
        this.renderY2PointAxis();
        this.renderX1LinearAxis();
        this.renderX2LinearAxis();
        let labelData = [];
        if (this.params.yVisible) {
            labelData = labelData.concat([
                // y1
                {
                    x: this.params.padding.left + this.axisWidth + this.params.y1LabelOffset[0],
                    y: this.params.padding.top - this.params.xTickPadding + this.params.y1LabelOffset[1],
                    textAnchor: this.params.y1LabelAnchor,
                    dominantBaseline: 'auto',
                    text: this.params.y1Label
                },
                // y2
                {
                    x: this.params.padding.left + this.axisWidth + this.params.gutterWidth + this.params.y2LabelOffset[0],
                    y: this.params.padding.top - this.params.xTickPadding + this.params.y2LabelOffset[1],
                    textAnchor: this.params.y2LabelAnchor,
                    dominantBaseline: 'auto',
                    text: this.params.y2Label
                },
            ]);
        }
        if (this.params.xVisible) {
            labelData = labelData.concat([
                // x1
                {
                    x: this.params.padding.left - this.params.xTickPadding + this.params.x1LabelOffset[0],
                    y: this.params.padding.top + this.axisHeight + this.params.xTickPadding + this.params.x1LabelOffset[1],
                    textAnchor: this.params.x1LabelAnchor,
                    dominantBaseline: 'hanging',
                    text: this.params.x1Label
                },
                // x2
                {
                    x: this.params.padding.left + this.axisWidth * 2 + this.params.gutterWidth + this.params.xTickPadding + this.params.x2LabelOffset[0],
                    y: this.params.padding.top + this.axisHeight + this.params.xTickPadding + this.params.x2LabelOffset[1],
                    textAnchor: this.params.x2LabelAnchor,
                    dominantBaseline: 'hanging',
                    text: this.params.x2Label
                }
            ]);
        }
        this.utilAxisLabel.setData(labelData);
        this.utilAxisLabel.render();
        // const zeroX1 = this.dataset.x1Scale(0)!
        // this.renderZeroLine(this.selection, [{
        //   x1: zeroX1,
        //   y1: 0,
        //   x2: zeroX1,
        //   y2: this.axisHeight
        // }])
    }
    remove() {
        this.selection.remove();
    }
    // private renderYTickLabel () {
    // }
    // private renderYAxisLabel (selection: d3.Selection<any, unknown, any, unknown>, params: Params) {
    //   let yAxisData: Params[] = [params]
    //   const yLabelUpdate = selection
    //     .selectAll<SVGTextElement, Params>('text.bpchart__yLabel')
    //     .data(yAxisData)
    //   const yLabelEnter = yLabelUpdate
    //     .enter()
    //     .append('text')
    //     .classed('bpchart__yLabel', true)
    //     .style('font-size', '14px')
    //     .style('font-weight', 'bold')
    //     .attr('text-anchor', 'end')
    //     .style('fill', params.axisLabelColor!)
    //   this.yLabelSelection = yLabelUpdate.merge(yLabelEnter)
    //     .attr('x', d => d.padding!.left - d.y1TickPadding! - defaultTickSize)
    //     .attr('y', d => d.padding!.top - d.xTickPadding!)
    //     .text(d => d.yLabel!)
    //   yLabelUpdate.exit().remove()
    // }
    renderY1PointAxis() {
        let yAxisData = [];
        if (this.params.yVisible) {
            yAxisData.push(this.params);
        }
        const yAxisUpdate = this.selection
            .selectAll('g.bpchart__y1Axis')
            .data(yAxisData);
        const yAxisEnter = yAxisUpdate
            .enter()
            .append('g');
        this.y1AxisSelection = yAxisUpdate.merge(yAxisEnter)
            .classed("bpchart__y1Axis", true)
            .attr("transform", d => `translate(${d.padding.left + this.axisWidth}, ${d.padding.top})`);
        yAxisUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.y1AxisSelection.size() == 0) {
            return;
        }
        // 設定Y軸刻度
        this.y1Axis = d3.axisRight(this.dataset.y1Scale)
            .scale(this.dataset.y1Scale)
            .tickSize(this.params.yTickLine && typeof this.params.yTickLine === 'boolean' ? -this.axisWidth
            : this.params.yTickLine && typeof this.params.yTickLine === 'number' ? -this.axisWidth * this.params.yTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            // .tickFormat(d => '') // 不顯示刻度文字
            .tickFormat((d) => {
            return d;
        })
            .tickPadding(this.params.gutterWidth / 2 - defaultTickSize);
        const yAxisEl = this.y1AxisSelection
            .transition()
            .duration(50)
            .call(this.y1Axis)
            .attr('text-anchor', 'middle');
        //   .attr('text-anchor', () => this.params.rotateYLabel !== false ? 'end' : 'end') // 如果要旋轉的話靠字尾對齊
        yAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.yTickLineDasharray);
        yAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        const xText = yAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            // .style('font-weight', 'bold')
            .style('color', this.params.axisLabelColor);
        // if (this.params.rotateYLabel === true) {
        //   xText.attr('transform', 'translate(0,0) rotate(-45)')
        // } else if (typeof this.params.rotateYLabel === 'number') {
        //   xText.attr('transform', `translate(0,0) rotate(${this.params.rotateYLabel})`)
        // }
    }
    renderY2PointAxis() {
        let yAxisData = [];
        if (this.params.yVisible) {
            yAxisData.push(this.params);
        }
        const yAxisUpdate = this.selection
            .selectAll('g.bpchart__y2Axis')
            .data(yAxisData);
        const yAxisEnter = yAxisUpdate
            .enter()
            .append('g');
        this.y2AxisSelection = yAxisUpdate.merge(yAxisEnter)
            .classed("bpchart__y2Axis", true)
            .attr("transform", d => `translate(${d.padding.left + this.axisWidth + this.params.gutterWidth}, ${d.padding.top})`);
        yAxisUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.y2AxisSelection.size() == 0) {
            return;
        }
        // 設定Y軸刻度
        this.y2Axis = d3.axisLeft(this.dataset.y2Scale)
            .scale(this.dataset.y2Scale)
            .tickSize(this.params.yTickLine && typeof this.params.yTickLine === 'boolean' ? -this.axisWidth
            : this.params.yTickLine && typeof this.params.yTickLine === 'number' ? -this.axisWidth * this.params.yTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            .tickFormat(d => ''); // 不顯示刻度文字
        // .tickFormat((d: any) => {
        //   return d
        // })
        // .tickPadding(this.params.yTickPadding!)
        const yAxisEl = this.y2AxisSelection
            .transition()
            .duration(50)
            .call(this.y2Axis);
        //   .attr('text-anchor', () => this.params.rotateYLabel !== false ? 'end' : 'end') // 如果要旋轉的話靠字尾對齊
        yAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.yTickLineDasharray);
        yAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        // const xText = yAxisEl.selectAll('text')
        //   .style('font-family', 'sans-serif')
        //   .style('font-size', '14px')
        //   // .style('font-weight', 'bold')
        //   .style('color', this.params.axisLabelColor!)
        // if (this.params.rotateYLabel === true) {
        //   xText.attr('transform', 'translate(0,0) rotate(-45)')
        // } else if (typeof this.params.rotateYLabel === 'number') {
        //   xText.attr('transform', `translate(0,0) rotate(${this.params.rotateYLabel})`)
        // }
    }
    renderX1LinearAxis() {
        let xAxisData = [];
        if (this.params.xVisible) {
            xAxisData.push(this.params);
        }
        const xAxisUpdate = this.selection
            .selectAll('g.bpchart__x1Axis')
            .data(xAxisData);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.x1AxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("bpchart__x1Axis", true)
            .attr("transform", d => `translate(${d.padding.left}, ${d.padding.top + this.axisHeight})`);
        xAxisUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.x1AxisSelection.size() == 0) {
            return;
        }
        // 設定X軸刻度
        this.x1Axis = d3.axisBottom(this.dataset.x1Scale)
            .scale(this.dataset.x1Scale)
            .ticks(this.dataset.maxValue > this.params.x1Ticks ? this.params.x1Ticks : (this.dataset.maxValue === 0 ? 1 : Math.ceil(this.dataset.maxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.xTickFormat))
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickPadding(this.params.xTickPadding);
        const xAxisEl = this.x1AxisSelection
            .transition()
            .duration(100)
            .call(this.x1Axis);
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
    renderX2LinearAxis() {
        let xAxisData = [];
        if (this.params.xVisible) {
            xAxisData.push(this.params);
        }
        const xAxisUpdate = this.selection
            .selectAll('g.bpchart__x2Axis')
            .data(xAxisData);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.x2AxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("bpchart__x1Axis", true)
            .attr("transform", d => `translate(${d.padding.left + this.axisWidth + this.params.gutterWidth}, ${d.padding.top + this.axisHeight})`);
        xAxisUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.x2AxisSelection.size() == 0) {
            return;
        }
        // 設定X軸刻度
        this.x2Axis = d3.axisBottom(this.dataset.x2Scale)
            .scale(this.dataset.x2Scale)
            .ticks(this.dataset.maxValue > this.params.x2Ticks ? this.params.x2Ticks : (this.dataset.maxValue === 0 ? 1 : Math.ceil(this.dataset.maxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.xTickFormat))
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickPadding(this.params.xTickPadding);
        const xAxisEl = this.x2AxisSelection
            .transition()
            .duration(100)
            .call(this.x2Axis);
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
}
