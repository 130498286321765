export default [
    {
        "id": "正面_蔣萬安",
        "data": {
            "value": 110,
            "id": "正面_蔣萬安",
            "itemIndex": 0,
            "itemLabel": "正面",
            "yIndex": 0,
            "yLabel": "蔣萬安",
            "x": 10.579888650367069,
            "y": 333.33333333333337,
            "sourceIndex": [
                0,
                0
            ],
            "barX": 0,
            "barY": 0,
            "groupY": 333.33333333333337,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 10.579888650367069
        },
        "barX0": 0,
        "barX1": 10.579888650367069,
        "barY0": 277.1666666666667,
        "barY1": 389.50000000000006,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "110"
    },
    {
        "id": "中立_蔣萬安",
        "data": {
            "value": 1634,
            "id": "中立_蔣萬安",
            "itemIndex": 1,
            "itemLabel": "中立",
            "yIndex": 0,
            "yLabel": "蔣萬安",
            "x": 157.1594368609072,
            "y": 333.33333333333337,
            "sourceIndex": [
                1,
                0
            ],
            "barX": 10.579888650367069,
            "barY": 0,
            "groupY": 333.33333333333337,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 157.1594368609072
        },
        "barX0": 10.579888650367069,
        "barX1": 167.73932551127427,
        "barY0": 277.1666666666667,
        "barY1": 389.50000000000006,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "1634"
    },
    {
        "id": "負面_蔣萬安",
        "data": {
            "value": 737,
            "id": "負面_蔣萬安",
            "itemIndex": 2,
            "itemLabel": "負面",
            "yIndex": 0,
            "yLabel": "蔣萬安",
            "x": 70.88525395745935,
            "y": 333.33333333333337,
            "sourceIndex": [
                2,
                0
            ],
            "barX": 167.73932551127427,
            "barY": 0,
            "groupY": 333.33333333333337,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 70.88525395745935
        },
        "barX0": 167.73932551127427,
        "barX1": 238.62457946873363,
        "barY0": 277.1666666666667,
        "barY1": 389.50000000000006,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "737"
    },
    {
        "id": "正面_黃珊珊",
        "data": {
            "value": 155,
            "id": "正面_黃珊珊",
            "itemIndex": 0,
            "itemLabel": "正面",
            "yIndex": 1,
            "yLabel": "黃珊珊",
            "x": 14.908024916426323,
            "y": 200,
            "sourceIndex": [
                0,
                1
            ],
            "barX": 0,
            "barY": 0,
            "groupY": 200,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 14.908024916426323
        },
        "barX0": 0,
        "barX1": 14.908024916426323,
        "barY0": 143.83333333333331,
        "barY1": 256.1666666666667,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "155"
    },
    {
        "id": "中立_黃珊珊",
        "data": {
            "value": 1054,
            "id": "中立_黃珊珊",
            "itemIndex": 1,
            "itemLabel": "中立",
            "yIndex": 1,
            "yLabel": "黃珊珊",
            "x": 101.374569431699,
            "y": 200,
            "sourceIndex": [
                1,
                1
            ],
            "barX": 14.908024916426323,
            "barY": 0,
            "groupY": 200,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 101.374569431699
        },
        "barX0": 14.908024916426323,
        "barX1": 116.28259434812533,
        "barY0": 143.83333333333331,
        "barY1": 256.1666666666667,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "1054"
    },
    {
        "id": "負面_黃珊珊",
        "data": {
            "value": 406,
            "id": "負面_黃珊珊",
            "itemIndex": 2,
            "itemLabel": "負面",
            "yIndex": 1,
            "yLabel": "黃珊珊",
            "x": 39.04940720044572,
            "y": 200,
            "sourceIndex": [
                2,
                1
            ],
            "barX": 116.28259434812533,
            "barY": 0,
            "groupY": 200,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 39.04940720044572
        },
        "barX0": 116.28259434812533,
        "barX1": 155.33200154857104,
        "barY0": 143.83333333333331,
        "barY1": 256.1666666666667,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "406"
    },
    {
        "id": "正面_陳時中",
        "data": {
            "value": 160,
            "id": "正面_陳時中",
            "itemIndex": 0,
            "itemLabel": "正面",
            "yIndex": 2,
            "yLabel": "陳時中",
            "x": 15.388928945988463,
            "y": 66.66666666666666,
            "sourceIndex": [
                0,
                2
            ],
            "barX": 0,
            "barY": 0,
            "groupY": 66.66666666666666,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 15.388928945988463
        },
        "barX0": 0,
        "barX1": 15.388928945988463,
        "barY0": 10.499999999999986,
        "barY1": 122.83333333333333,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "160"
    },
    {
        "id": "中立_陳時中",
        "data": {
            "value": 2459,
            "id": "中立_陳時中",
            "itemIndex": 1,
            "itemLabel": "中立",
            "yIndex": 2,
            "yLabel": "陳時中",
            "x": 236.5086017386602,
            "y": 66.66666666666666,
            "sourceIndex": [
                1,
                2
            ],
            "barX": 15.388928945988463,
            "barY": 0,
            "groupY": 66.66666666666666,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 236.5086017386602
        },
        "barX0": 15.388928945988463,
        "barX1": 251.89753068464864,
        "barY0": 10.499999999999986,
        "barY1": 122.83333333333333,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "2459"
    },
    {
        "id": "負面_陳時中",
        "data": {
            "value": 1195,
            "id": "負面_陳時中",
            "itemIndex": 2,
            "itemLabel": "負面",
            "yIndex": 2,
            "yLabel": "陳時中",
            "x": 114.93606306535133,
            "y": 66.66666666666666,
            "sourceIndex": [
                2,
                2
            ],
            "barX": 251.89753068464864,
            "barY": 0,
            "groupY": 66.66666666666666,
            "barWidth": 112.33333333333334,
            "barR": 0,
            "width": 114.93606306535133
        },
        "barX0": 251.89753068464864,
        "barX1": 366.83359375,
        "barY0": 10.499999999999986,
        "barY1": 122.83333333333333,
        "position": "top",
        "fontSize": 10,
        "color": "#7b7b7b",
        "style": "",
        "text": "1195"
    }
];
