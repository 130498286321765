import { makeAxisPointScale, makeAxisLinearScale } from '@bpchart/d3-modules/moduleUtils';
const xLabels = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'];
const axisWidth = 660;
const axisHeight = 400;
const y1MaxValue = 180;
const y2MaxValue = 120;
export default {
    xScale: makeAxisPointScale({
        axisLabels: xLabels,
        axisWidth: axisWidth,
    }),
    y1Scale: makeAxisLinearScale({
        maxValue: y1MaxValue,
        minValue: 0,
        axisWidth: axisHeight,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
        reverse: true
    }),
    y2Scale: makeAxisLinearScale({
        maxValue: y2MaxValue,
        minValue: 0,
        axisWidth: axisHeight,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
        reverse: true
    }),
    y1MaxValue,
    y2MaxValue
};
