import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS } from './defaults';
// import TooltipAside from '../tooltip/TooltipAside'
import { makeColorScale } from '../moduleUtils';
import { UtilGraphicBarLabel } from '../utilGraphicBarLabel';
const _d3 = d3;
// const makeColorScale = (itemLabels: string[], colors: string[]) => {
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
const makeCallbackData = ({ lineData, data, event }) => {
    return {
        groupData: [],
        itemData: lineData,
        datum: undefined,
        // xLabel: '',
        // xIndex: -1,
        itemLabel: lineData[0].itemLabel,
        itemIndex: lineData[0].itemIndex,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
// 依無值的資料分段
const makeSegmentData = (data) => {
    let segmentData = [[]];
    let currentIndex = 0;
    for (let i in data) {
        if (data[i].value === undefined || data[i].value === null) {
            // 換下一段的 index
            if (segmentData[currentIndex].length) {
                currentIndex++;
                segmentData[currentIndex] = [];
            }
            continue;
        }
        segmentData[currentIndex].push(data[i]);
    }
    return segmentData;
};
// const calcAxisWidth = (data: Datum[][]) => {
//   if (data[0] && data[0][0]) {
//     // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
//     let minX = Infinity
//     let maxX = 0
//     data.forEach(d => {
//       if (d[0].x < minX) {
//         minX = d[0].x
//       }
//       if (d[d.length - 1].x > maxX) {
//         maxX = d[d.length - 1].x
//       }
//     })
//     return maxX - minX
//   }
//   return 0
// }
// const calcAxisHeight = (data: Datum[][]) => {
//   if (data[0] && data[0][0]) {
//     // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
//     let minValue = Infinity
//     let minValueY = Infinity
//     data.forEach(d => {
//       d.forEach(_d => {
//         if (_d.value < minValue) {
//           minValue = _d.value
//           minValueY = _d.y
//         }
//       })
//     })
//     return minValueY
//   }
//   return 0
// }
const makeLabelData = (data, params) => {
    let labelData = [];
    data.forEach((d, i) => {
        d.forEach((_d, _i) => {
            const sourceIndex = [_d.itemIndex, _d.xIndex];
            labelData.push({
                id: _d.id,
                data: _d,
                barX0: _d.x,
                barX1: _d.x,
                barY0: _d.y,
                barY1: _d.y,
                position: params.labelPositionMethod(_d, sourceIndex),
                fontSize: params.labelFontSizeMethod(_d, sourceIndex),
                color: params.labelColorMethod(_d, sourceIndex),
                style: params.labelStyleMethod(_d, sourceIndex),
                text: params.labelTextMethod(_d, sourceIndex),
            });
        });
    });
    return labelData;
};
export default class GraphicColumnLine {
    selection;
    params = DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        axisWidth: 0,
        axisHeight: 0,
        zeroY: 0
    };
    utilGraphicBarLabel;
    graphicGSelection = undefined;
    labelBoxSelection;
    defsSelection;
    colorScale;
    linePath = d3.line()
        .x((d) => d.x)
        .y((d) => d.y)
        .curve(_d3[this.params.lineCurve]);
    // private filteredItemLabels: string[] = []
    // private filteredData: Datum[][] = []
    // private axisHeight = 0
    axisWidth = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
        this.labelBoxSelection = this.selection.append('g');
        this.defsSelection = selection.append('defs');
        this.utilGraphicBarLabel = new UtilGraphicBarLabel(this.labelBoxSelection, {});
    }
    setParams(params) {
        if (params.lineCurve && params.lineCurve != this.params.lineCurve) {
            this.linePath = d3.line()
                .x((d) => d.x)
                .y((d) => d.y)
                .curve(_d3[params.lineCurve]);
        }
        this.params = {
            ...this.params,
            ...params
        };
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
        this.initHighlight();
        if (this.params.lineType == 'line') {
            this.renderGraphicItem = this.renderLine;
        }
        else if (this.params.lineType == 'area') {
            this.renderGraphicItem = this.renderArea;
        }
        else if (this.params.lineType === 'gradientArea') {
            this.renderGraphicItem = this.renderGradientArea;
        }
        this.utilGraphicBarLabel.setParams({
            barDirection: 'up',
            positionPadding: this.params.labelPadding
        });
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.colorScale = makeColorScale(this.dataset.itemLabels, this.params.colors);
    }
    render() {
        // console.log('this.dataset', this.dataset)
        // if (this.dataset.axisHeight) {
        //   this.axisHeight = this.dataset.axisHeight
        // } else {
        //   // 如無設定就取最小值，這樣只是圖不會爆炸但畫出來可能會有問題（不算bug但如果如y的起點不同的話 - 比如y的起點可能為0，就不會由起點開始畫）
        //   this.axisHeight = calcAxisHeight(this.dataset.data)
        // }
        // if (this.dataset.axisWidth) {
        //   this.axisWidth = this.dataset.axisWidth
        // } else {
        //   this.axisWidth = calcAxisWidth(this.dataset.data)
        // }
        this.axisWidth = this.dataset.axisWidth;
        // <g>
        const updateGraphic = this.selection
            .selectAll('g.bpchart__graphic')
            .data(this.dataset.data, (d, i) => (d[0] && d[0].id) ? d[0].id : this.dataset.itemLabels[i]);
        const enterGraphic = updateGraphic.enter()
            .append('g')
            .classed('bpchart__graphic', true);
        updateGraphic.exit().remove();
        this.graphicGSelection = updateGraphic.merge(enterGraphic);
        // 新資料動畫
        this.graphicGSelection
            .attr('clip-path', (d, i) => `url(#bpchart__clipPath_${this.dataset.itemLabels[i]})`);
        if (enterGraphic.size() > 0) {
            // const clipPathData = this.dataset.itemLabels.map((d, i) => {
            //   return {
            //     id: `bpchart__clipPath_${d}`,
            //     x: 0,
            //     y: 0,
            //     // width: this.axisWidth,
            //     width: 0,
            //     height: this.dataset.axisHeight!
            //   }
            // })
            // this.renderClipPath(clipPathData)
            const enterClipPathData = [];
            enterGraphic
                .each((data, i, n) => {
                enterClipPathData.push({
                    id: `bpchart__clipPath_${data[i].itemLabel}`,
                    x: 0,
                    y: 0,
                    width: 0,
                    height: this.dataset.axisHeight
                });
            });
            this.renderClipPath(enterClipPathData);
            const updateClipPathData = [];
            updateGraphic
                .each((data, i, n) => {
                updateClipPathData.push({
                    id: `bpchart__clipPath_${data[i].itemLabel}`,
                    x: 0,
                    y: 0,
                    width: this.axisWidth,
                    height: this.dataset.axisHeight
                });
            });
            this.renderClipPath(enterClipPathData);
            this.selection
                .transition()
                .duration(this.params.enterDuration)
                .delay(100) // @Q@ 不知為何如果沒加 delay位置會有點跑掉
                .tween('tween', (_d, _i, _g) => {
                return (t) => {
                    const transitionWidth = this.axisWidth * t;
                    enterGraphic
                        .each((data, i, n) => {
                        this.setClipPathItemWidth(`bpchart__clipPath_${data[i].itemLabel}`, transitionWidth);
                    });
                };
            })
                .on('end', () => {
                this.initHighlight();
            });
        }
        // 繪圖
        this.graphicGSelection.each((d, i, all) => {
            // 將資料分段
            const segmentData = makeSegmentData(d);
            this.renderGraphicItem({
                selection: d3.select(all[i]),
                id: this.dataset.itemLabels && this.dataset.itemLabels[i],
                data: segmentData,
                linePath: this.linePath,
                colorScale: this.colorScale
            });
        });
        // 綁定事件
        this.graphicGSelection.on('mouseover', null);
        this.graphicGSelection.on('mouseout', null);
        this.graphicGSelection
            .on('click', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                lineData: d,
                data: this.dataset.data,
                event: d3.event
            });
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i, n) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                lineData: d,
                data: this.dataset.data,
                event: d3.event
            });
            if (this.params.highlightTarget === 'item') {
                if (callbackData && callbackData.itemLabel) {
                    this.highlight(this.graphicGSelection, callbackData.itemLabel);
                }
            }
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                lineData: d,
                data: this.dataset.data,
                event: d3.event
            });
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget === 'item') {
                this.removeHighlight(this.graphicGSelection);
            }
            const callbackData = makeCallbackData({
                lineData: d,
                data: this.dataset.data,
                event: d3.event
            });
            this.initHighlight();
            this.mouseoutCallback(callbackData);
        });
        // label
        this.utilGraphicBarLabel.setData(makeLabelData(this.dataset.data, this.params));
        this.utilGraphicBarLabel.render();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    remove() {
        this.selection.remove();
    }
    renderClipPath(clipPathData) {
        const update = this.defsSelection
            .selectAll('clipPath')
            .data(clipPathData);
        const enter = update.enter()
            .append('clipPath');
        const cutRect = update.merge(enter)
            .attr('id', d => d.id);
        update.exit().remove();
        cutRect.each((d, i, g) => {
            const updateRect = d3.select(g[i])
                .selectAll('rect')
                .data([d]);
            const enterRect = updateRect.enter()
                .append('rect');
            updateRect.exit().remove();
            updateRect.merge(enterRect)
                .attr('x', _d => _d.x)
                .attr('y', _d => _d.y)
                .attr('width', _d => _d.width)
                .attr('height', _d => _d.height);
        });
    }
    setClipPathItemWidth(id, width) {
        this.defsSelection
            .select(`clipPath#${id}`)
            .select('rect')
            .attr('width', width);
    }
    renderGraphicItem({ selection, id, data, linePath, colorScale }) { }
    renderLine({ selection, id, data, linePath, colorScale }) {
        const update = selection
            .selectAll(`path.bpchart__graphic__line`)
            .data(data, (d, i) => i);
        const enter = update.enter()
            .append('path')
            .classed(`bpchart__graphic__line`, true)
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .style('vector-effect', 'non-scaling-stroke')
            .style('cursor', 'pointer');
        // .style('pointer-events', 'none')
        update.exit();
        update.merge(enter)
            // .attr("transform", "translate(" + this.params.padding!.left + "," + this.params.padding!.top + ")")
            .attr("stroke", (d, i) => id ? colorScale(id) : DEFAULT_COLORS[i])
            .attr("d", (d) => {
            return linePath(d);
        });
    }
    renderArea({ selection, id, data, linePath, colorScale }) {
        // const D: any = d3
        const lineArea = d3.area()
            .x((d) => d.x)
            .y0(this.dataset.zeroY)
            .y1((d) => d.y)
            .curve(_d3[this.params.lineCurve]);
        const update = selection
            .selectAll(`path.bpchart__graphic__area`)
            .data(data, (d, i) => i);
        const enter = update.enter()
            .append('path')
            .classed(`bpchart__graphic__area`, true)
            // .style('vector-effect', 'non-scaling-stroke')
            .style('opacity', 0.5)
            .style('pointer-events', 'none');
        update.exit();
        update.merge(enter)
            .attr("transform", "translate(" + 0 + "," + 0 + ")")
            // .attr("stroke", (d, i) => id ? colorScale!(id) : DEFAULT_COLORS[i])
            .attr("fill", (d, i) => {
            if (this.params.lineType === 'gradientArea') {
                return `url(#bpchart__gradient__${id})`;
            }
            return id ? colorScale(id) : DEFAULT_COLORS[i];
        })
            .attr("d", (d) => {
            return lineArea(d);
        });
    }
    renderGradientArea({ selection, id, data, linePath, colorScale }) {
        const linearGradientUpdate = this.defsSelection
            .selectAll('linearGradient')
            .data(this.dataset.itemLabels, d => d);
        const linearGradientEnter = linearGradientUpdate
            .enter()
            .append('linearGradient');
        linearGradientUpdate.merge(linearGradientEnter)
            .attr('id', (d, i) => `bpchart__gradient__${this.dataset.itemLabels[Number(i)]}`)
            .attr('x1', '0%')
            .attr('x2', '0%')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr('spreadMethod', 'pad')
            .html((d, i) => `
          <stop offset="0%"   stop-color="${this.params.colors[Number(i)]}" stop-opacity="1"/>
          <stop offset="100%" stop-color="${this.params.colors[Number(i)]}" stop-opacity="0"/>
        `);
        linearGradientUpdate.exit().remove();
        this.renderLine({ selection, id, data, linePath, colorScale });
        this.renderArea({ selection, id, data, linePath, colorScale });
    }
    initHighlight() {
        if (!this.graphicGSelection) {
            return;
        }
        // highlight
        if (this.params.highlightItemId) {
            this.highlight(this.graphicGSelection, this.params.highlightItemId);
        }
        else {
            this.removeHighlight(this.graphicGSelection);
        }
    }
    highlight(selection, id) {
        if (!selection) {
            return;
        }
        selection
            .each((d, i, n) => {
            if (d[0].itemLabel === id) {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(selection) {
        // highlight
        if (this.params.highlightItemId) {
            this.highlight(selection, this.params.highlightItemId);
        }
        else if (!this.params.highlightItemId) {
            selection
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }
}
