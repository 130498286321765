import * as d3 from 'd3';
export default class Button {
    selection;
    params = {
    // id: 'bpchart_button',
    // disabled: false
    };
    buttonSelection;
    constructor(selection, params) {
        this.selection = selection;
        if (params) {
            this.params = params;
        }
        // if (params.id) {
        //   this.params.id = params.id
        // }
        // if (params.disabled) {
        //   this.params.disabled = params.disabled
        // }
    }
    remove() {
        this.buttonSelection.remove();
    }
    select() {
        return this.buttonSelection;
    }
}
