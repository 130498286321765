import { DEFAULT_COLORS, DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_RANK_PARAMS } from '../axisDateRank';
export const DEFAULT_CHART_DATE_RANK_PARAMS = {
    padding: {
        top: 40,
        right: 80,
        bottom: 50,
        left: 200
    },
    axisDateRank: DEFAULT_AXIS_DATE_RANK_PARAMS,
    tooltipAside: {
        templateHtml: (data) => {
            if (data.itemData && data.itemData[0]) {
                return `
          <div>
            <div>${data.itemLabel}</div>
            <div>${data.itemData.map((d) => d.value).join(',')}</div>          
          </div>
        `;
            }
            return '';
        },
    },
    colors: DEFAULT_COLORS,
    rowAmount: 10,
    zoom: true,
    auxLine: true,
    highlightTarget: 'datum',
    highlightItemId: '',
    highlightGroupId: '',
    highlightDatumId: '',
};
export const DEFAULT_CHART_DATE_RANK_BUBBLES_PARAMS = {
    ...DEFAULT_CHART_DATE_RANK_PARAMS,
};
