import * as d3 from 'd3';
import { DEFAULT_UTIL_AXIS_LABEL_PARAMS } from './defaults';
import { makeColorScale } from '../moduleUtils';
export default class UtilAxisLabel {
    selection;
    data = [];
    params = DEFAULT_UTIL_AXIS_LABEL_PARAMS;
    axisLabelSelection = undefined;
    colorScale;
    constructor(selection) {
        this.selection = selection;
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setData(data) {
        this.data = data;
    }
    render() {
        this.colorScale = makeColorScale(this.params.colors.map((d, i) => String(i)), this.params.colors);
        const axisLabelUpdate = this.selection
            .selectAll('text.bpchart__axisLabel')
            .data(this.data);
        const axisLabelEnter = axisLabelUpdate
            .enter()
            .append('text')
            .classed('bpchart__axisLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold');
        this.axisLabelSelection = axisLabelUpdate.merge(axisLabelEnter)
            .attr('text-anchor', d => d.textAnchor)
            .attr('dominant-baseline', d => d.dominantBaseline)
            .style('fill', (d, i) => this.colorScale(String(i)))
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .text(d => d.text);
        axisLabelUpdate.exit().remove();
    }
    remove() {
        this.selection.remove();
    }
}
