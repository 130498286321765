import { AxisColumnTwoScales } from '@bpchart/d3-modules/axisColumnTwoScales';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_AXIS_COLUMN_TWO_SCALES_PARAMS } from '@bpchart/d3-modules/axisColumnTwoScales';
export default {
    props: {
        module: AxisColumnTwoScales,
        params: DEFAULT_AXIS_COLUMN_TWO_SCALES_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
