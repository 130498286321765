import { ChartImageRowStack } from '@/packages/d3-modules/chartImageRowStack';
// import { DEFAULT_PADDING } from '@bpchart/d3-modules/defaults'
// const maleSvg = `<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M5.92593 0C8.10778 0 9.87654 1.79087 9.87654 4C9.87654 6.20913 8.10778 8 5.92593 8C3.74407 8 1.97531 6.20913 1.97531 4C1.97531 1.79087 3.74407 0 5.92593 0ZM8.88889 9H8.18765C6.78574 9.65269 5.12654 9.68087 3.6642 9H2.96296C1.32654 9 0 10.3431 0 12V20.5C0 21.3284 0.663272 22 1.48148 22H2.46914V30.5C2.46914 31.3284 3.13241 32 3.95062 32H7.90123C8.71944 32 9.38272 31.3284 9.38272 30.5V22H10.3704C11.1886 22 11.8519 21.3284 11.8519 20.5V12C11.8519 10.3431 10.5253 9 8.88889 9Z" fill="#808080"/>
// </svg>
// `
// const femaleSvg = `<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M7.40739 0C9.58905 0 11.3577 1.79087 11.3577 4C11.3577 6.20913 9.58905 8 7.40739 8C5.22573 8 3.45711 6.20913 3.45711 4C3.45711 1.79087 5.22573 0 7.40739 0ZM14.7699 22.1362L11.8072 10.1362C11.7271 9.81166 11.5421 9.52356 11.2817 9.3177C11.0213 9.11183 10.7005 9.00001 10.3701 9H9.66892C8.26713 9.65269 6.60808 9.68087 5.14586 9H4.44468C4.11432 9.00001 3.79345 9.11183 3.53306 9.3177C3.27267 9.52356 3.08771 9.81166 3.00759 10.1362L0.0448803 22.1362C-0.188556 23.0816 0.518049 24 1.48198 24H4.93847V30.5C4.93847 31.3284 5.60168 32 6.41982 32H8.39496C9.2131 32 9.87631 31.3284 9.87631 30.5V24H13.3328C14.2951 24 15.0037 23.0831 14.7699 22.1362Z" fill="#60A2F0"/>
// </svg>
// `
export default {
    props: {
        module: ChartImageRowStack,
        params: {
            step: 35,
            svgWidth: 24,
            svgHeight: 48
        },
        dataset: {}
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
