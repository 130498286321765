const response = {
    "payload": {
        "paraArr": [
            {
                "count": 1828,
                "type": "keyword",
                "word": "疫情",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "疫情"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1202,
                "type": "keyword",
                "word": "簡單",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "簡單"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1161,
                "type": "group",
                "word": "民進黨",
                "value": [
                    {
                        "term": {
                            "article_adept_group": {
                                "value": "民進黨"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1112,
                "type": "keyword",
                "word": "垃圾",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "垃圾"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1112,
                "type": "location",
                "word": "美國",
                "value": [
                    {
                        "term": {
                            "article_adept_location": {
                                "value": "美國"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1062,
                "type": "keyword",
                "word": "防疫",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "防疫"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 1016,
                "type": "location",
                "word": "日本",
                "value": [
                    {
                        "term": {
                            "article_adept_location": {
                                "value": "日本"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 956,
                "type": "keyword",
                "word": "武漢肺炎",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "武漢肺炎"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 905,
                "type": "location",
                "word": "高雄",
                "value": [
                    {
                        "term": {
                            "article_adept_location": {
                                "value": "高雄"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 903,
                "type": "group",
                "word": "國民黨",
                "value": [
                    {
                        "term": {
                            "article_adept_group": {
                                "value": "國民黨"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 811,
                "type": "keyword",
                "word": "學生",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "學生"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 754,
                "type": "keyword",
                "word": "五樓",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "五樓"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 724,
                "type": "keyword",
                "word": "一個人",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "一個人"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 717,
                "type": "keyword",
                "word": "崩潰",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "崩潰"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 690,
                "type": "keyword",
                "word": "紓困",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "紓困"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 689,
                "type": "keyword",
                "word": "蟑螂",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "蟑螂"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 680,
                "type": "people",
                "word": "韓國瑜",
                "value": [
                    {
                        "term": {
                            "article_adept_people": {
                                "value": "韓國瑜"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 663,
                "type": "keyword",
                "word": "申請",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "申請"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 651,
                "type": "keyword",
                "word": "同學",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "同學"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 627,
                "type": "keyword",
                "word": "不意外",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "不意外"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 620,
                "type": "keyword",
                "word": "在一起",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "在一起"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 617,
                "type": "keyword",
                "word": "病毒",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "病毒"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 612,
                "type": "keyword",
                "word": "邏輯",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "邏輯"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 610,
                "type": "keyword",
                "word": "支那",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "支那"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 594,
                "type": "keyword",
                "word": "智障",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "智障"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 593,
                "type": "people",
                "word": "蔡英文",
                "value": [
                    {
                        "term": {
                            "article_adept_people": {
                                "value": "蔡英文"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 589,
                "type": "keyword",
                "word": "開心",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "開心"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 581,
                "type": "brand",
                "word": "蘋果",
                "value": [
                    {
                        "term": {
                            "article_adept_brand": {
                                "value": "蘋果"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 558,
                "type": "location",
                "word": "台中",
                "value": [
                    {
                        "term": {
                            "article_adept_location": {
                                "value": "台中"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 541,
                "type": "keyword",
                "word": "男生",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "男生"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 534,
                "type": "keyword",
                "word": "可憐哪",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "可憐哪"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 532,
                "type": "keyword",
                "word": "肥宅",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "肥宅"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 524,
                "type": "keyword",
                "word": "短網址",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "短網址"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 522,
                "type": "keyword",
                "word": "畢業",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "畢業"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 519,
                "type": "keyword",
                "word": "真相",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "真相"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 513,
                "type": "keyword",
                "word": "台灣價值",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "台灣價值"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 493,
                "type": "keyword",
                "word": "直播",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "直播"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 484,
                "type": "people",
                "word": "柯文哲",
                "value": [
                    {
                        "term": {
                            "article_adept_people": {
                                "value": "柯文哲"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 465,
                "type": "keyword",
                "word": "攻擊",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "攻擊"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 451,
                "type": "people",
                "word": "李來希",
                "value": [
                    {
                        "term": {
                            "article_adept_people": {
                                "value": "李來希"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 449,
                "type": "keyword",
                "word": "劇情",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "劇情"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 449,
                "type": "keyword",
                "word": "口罩",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "口罩"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 448,
                "type": "group",
                "word": "KMT",
                "value": [
                    {
                        "term": {
                            "article_adept_group": {
                                "value": "KMT"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 439,
                "type": "brand",
                "word": "臉書",
                "value": [
                    {
                        "bool": {
                            "must": [
                                {
                                    "term": {
                                        "article_adept_all": {
                                            "value": "臉書"
                                        }
                                    }
                                },
                                {
                                    "bool": {
                                        "must_not": [
                                            {
                                                "term": {
                                                    "origin": "youtube"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 438,
                "type": "keyword",
                "word": "醫生",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "醫生"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 437,
                "type": "keyword",
                "word": "戴口罩",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "戴口罩"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 436,
                "type": "keyword",
                "word": "開車",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "開車"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 425,
                "type": "keyword",
                "word": "投票",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "投票"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 424,
                "type": "keyword",
                "word": "失敗",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "失敗"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 422,
                "type": "keyword",
                "word": "傷害",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "傷害"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 416,
                "type": "location",
                "word": "韓國",
                "value": [
                    {
                        "term": {
                            "article_adept_location": {
                                "value": "韓國"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 414,
                "type": "keyword",
                "word": "幸福",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "幸福"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 411,
                "type": "keyword",
                "word": "資格",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "資格"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 410,
                "type": "keyword",
                "word": "乖乖",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "乖乖"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 407,
                "type": "keyword",
                "word": "感恩",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "感恩"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 405,
                "type": "keyword",
                "word": "投資",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "投資"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 402,
                "type": "keyword",
                "word": "堅持",
                "value": [
                    {
                        "term": {
                            "article_adept_keyword": {
                                "value": "堅持"
                            }
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 269,
                "type": "brand",
                "word": "IG",
                "value": [
                    {
                        "bool": {
                            "must": [
                                {
                                    "term": {
                                        "article_adept_all": {
                                            "value": "IG"
                                        }
                                    }
                                },
                                {
                                    "bool": {
                                        "must_not": [
                                            {
                                                "term": {
                                                    "origin": "youtube"
                                                }
                                            },
                                            {
                                                "term": {
                                                    "origin": "facebook"
                                                }
                                            },
                                            {
                                                "term": {
                                                    "origin": "instagram"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 165,
                "type": "brand",
                "word": "LINE",
                "value": [
                    {
                        "bool": {
                            "must": [
                                {
                                    "term": {
                                        "article_adept_all": {
                                            "value": "LINE"
                                        }
                                    }
                                },
                                {
                                    "bool": {
                                        "must_not": [
                                            {
                                                "term": {
                                                    "origin": "youtube"
                                                }
                                            },
                                            {
                                                "term": {
                                                    "origin": "facebook"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                "merged_words": []
            },
            {
                "count": 144,
                "type": "keyword",
                "word": "APP",
                "value": [
                    {
                        "bool": {
                            "must": [
                                {
                                    "term": {
                                        "article_adept_all": {
                                            "value": "APP"
                                        }
                                    }
                                },
                                {
                                    "bool": {
                                        "must_not": [
                                            {
                                                "term": {
                                                    "origin": "facebook"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                "merged_words": []
            }
        ],
        "total_count": 26524
    }
};
export default response.payload.paraArr
    .map(d => {
    return {
        id: d.word,
        label: d.word,
        value: d.count,
        type: d.type
    };
});
