import * as d3 from 'd3';
import ChartRow from './ChartRow';
import GraphicRowBarLayout from '../graphicRowBarLayout/GraphicRowBarLayout';
import { DEFAULT_CHART_ROW_BAR_LAYOUT_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartRowBarStack extends ChartRow {
    params = DEFAULT_CHART_ROW_BAR_LAYOUT_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicRowBarLayout(this.graphicSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicRowBarLayout: {
                ...this.params.graphicRowBarLayout,
                ...params.graphicRowBarLayout
            }
        };
        this.bar.setParams({
            ...this.params.graphicRowBarLayout,
            colors: this.params.colors,
            highlightTarget: this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId,
            highlightGroupId: this.params.highlightGroupId,
        });
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const yLabel = dataset.yLabels[_i];
                const y = yScale(yLabel) || 0; // 這裡的 y 是對應 y座標軸的位置，群組內的位置在圖形裡面再做計算
                return {
                    ..._d,
                    id: `${itemLabel}_${yLabel}`,
                    itemIndex: i,
                    itemLabel,
                    yIndex: _i,
                    yLabel,
                    x: xScale(_d.value) || 0,
                    y
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        const getGroupingValue = (data, startIndex, endIndex) => {
            let sumArr = [];
            for (let i = 0; i < data[0].length; i++) {
                sumArr[i] = 0;
                for (let j = startIndex; j <= endIndex; j++) {
                    sumArr[i] += data[j][i].value;
                }
            }
            return sumArr;
        };
        let startBarIndex = 0;
        let groupingValue = [];
        if (this.params.graphicRowBarLayout.groupLayout.length) {
            this.params.graphicRowBarLayout.groupLayout.forEach(d => {
                const endBarIndex = startBarIndex + d.stackAmount - 1;
                groupingValue = groupingValue.concat(getGroupingValue(data, startBarIndex, endBarIndex));
                startBarIndex = endBarIndex + 1;
            });
        }
        else {
            groupingValue = getGroupingValue(data, 0, data[0].length - 1);
        }
        return groupingValue.reduce((prev, current) => {
            // [min, max]
            return [
                current < prev[0] ? current : prev[0],
                current > prev[1] ? current : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeYDataMap({ renderData, yLabels }) {
        let YDataMap = new Map();
        if (!renderData.length) {
            return YDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = YDataMap.get(yLabels[_i]) ?? [];
                xData.push(_d);
                YDataMap.set(yLabels[_i], xData);
            });
        });
        return YDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        itemLabels.forEach((d, i) => {
            ItemDataMap.set(d, renderData[i]);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, yLabels, xScale, yScale }) {
        this.bar.setDataset({
            data: renderData,
            yLabels,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisHeight: this.axisHeight,
            zeroX: 0,
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        // // 非顯示全部圓點時 highlight圓點
        // if (!this.params.dot && this.params.highlightTarget !== 'none') {
        //   const dotData: GraphicRenderDatum[] = eventData[0]
        //     ? eventData[0].groupData.map(d => {
        //       return {
        //         ...d,
        //         id: d.itemLabel, // itemLabel相同則用update的
        //         x: xScale(d.Date)!,
        //         y: yScale(d.value)!
        //       }
        //     })
        //     : []
        //   this.dot!.setDataset({
        //     data: dotData,
        //     itemLabels: this.dataset.itemLabels
        //   })
        //   this.dot!.render()
        // }
    }
}
