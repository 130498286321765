export default {
    "data": [
        [
            {
                "value": 110,
                "id": "正面_蔣萬安",
                "itemIndex": 0,
                "itemLabel": "正面",
                "yIndex": 0,
                "yLabel": "蔣萬安",
                "x": 12.550908249213425,
                "y": 333.33333333333337
            },
            {
                "value": 155,
                "id": "正面_黃珊珊",
                "itemIndex": 0,
                "itemLabel": "正面",
                "yIndex": 1,
                "yLabel": "黃珊珊",
                "x": 17.685370714800733,
                "y": 200
            },
            {
                "value": 160,
                "id": "正面_陳時中",
                "itemIndex": 0,
                "itemLabel": "正面",
                "yIndex": 2,
                "yLabel": "陳時中",
                "x": 18.255866544310432,
                "y": 66.66666666666666
            }
        ],
        [
            {
                "value": 1634,
                "id": "中立_蔣萬安",
                "itemIndex": 1,
                "itemLabel": "中立",
                "yIndex": 0,
                "yLabel": "蔣萬安",
                "x": 186.43803708377033,
                "y": 333.33333333333337
            },
            {
                "value": 1054,
                "id": "中立_黃珊珊",
                "itemIndex": 1,
                "itemLabel": "中立",
                "yIndex": 1,
                "yLabel": "黃珊珊",
                "x": 120.26052086064499,
                "y": 200
            },
            {
                "value": 2459,
                "id": "中立_陳時中",
                "itemIndex": 1,
                "itemLabel": "中立",
                "yIndex": 2,
                "yLabel": "陳時中",
                "x": 280.569848952871,
                "y": 66.66666666666666
            }
        ],
        [
            {
                "value": 737,
                "id": "負面_蔣萬安",
                "itemIndex": 2,
                "itemLabel": "負面",
                "yIndex": 0,
                "yLabel": "蔣萬安",
                "x": 84.09108526972994,
                "y": 333.33333333333337
            },
            {
                "value": 406,
                "id": "負面_黃珊珊",
                "itemIndex": 2,
                "itemLabel": "負面",
                "yIndex": 1,
                "yLabel": "黃珊珊",
                "x": 46.324261356187726,
                "y": 200
            },
            {
                "value": 1195,
                "id": "負面_陳時中",
                "itemIndex": 2,
                "itemLabel": "負面",
                "yIndex": 2,
                "yLabel": "陳時中",
                "x": 136.34850325281855,
                "y": 66.66666666666666
            }
        ],
        [
            {
                "value": 2481,
                "id": "總聲量_蔣萬安",
                "itemIndex": 3,
                "itemLabel": "總聲量",
                "yIndex": 0,
                "yLabel": "蔣萬安",
                "x": 283.0800306027137,
                "y": 333.33333333333337
            },
            {
                "value": 1615,
                "id": "總聲量_黃珊珊",
                "itemIndex": 3,
                "itemLabel": "總聲量",
                "yIndex": 1,
                "yLabel": "黃珊珊",
                "x": 184.27015293163345,
                "y": 200
            },
            {
                "value": 3814,
                "id": "總聲量_陳時中",
                "itemIndex": 3,
                "itemLabel": "總聲量",
                "yIndex": 2,
                "yLabel": "陳時中",
                "x": 435.17421874999997,
                "y": 66.66666666666666
            }
        ]
    ],
    "yLabels": [
        "蔣萬安",
        "黃珊珊",
        "陳時中"
    ],
    "itemLabels": [
        "正面",
        "中立",
        "負面",
        "總聲量"
    ],
    "axisHeight": 400,
    "zeroX": 0
};
