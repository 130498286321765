import { DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_AXIS_DATE_RANK_PARAMS = {
    xLabel: '',
    yLabel: '',
    xLabelAnchor: 'start',
    yLabelAnchor: 'end',
    xLabelOffset: [0, 0],
    yLabelOffset: [0, 0],
    xTickPadding: 20,
    yTickPadding: 20,
    xTickLine: false,
    //   yTickLine: true,
    xTickLineDasharray: 'none',
    //   yTickLineDasharray: 'none',
    xTickFormat: '%m-%d',
    //   yTickFormat: ',.0f',
    xTickSpacing: 80,
    //   yTickSpacing: 80, // 用間距計算 ticks參數
    axisLineColor: DEFAULT_ASIX_LINE_COLOR,
    axisLabelColor: DEFAULT_AXIS_LABEL_COLOR,
    rotateXLabel: false,
    padding: DEFAULT_PADDING,
};
