const sourceData = [
    {
        "label": "110年9月",
        "increase": "83",
        "male": "1438",
        "female": "1355",
        "rate": "0.4"
    },
    {
        "label": "110年10月",
        "increase": "48",
        "male": "1407",
        "female": "1359",
        "rate": "0.22"
    },
    {
        "label": "110年11月",
        "increase": "-45",
        "male": "1491",
        "female": "1536",
        "rate": "-0.22"
    },
    {
        "label": "110年12月",
        "increase": "21",
        "male": "1575",
        "female": "1554",
        "rate": "0.1"
    },
    {
        "label": "111年1月",
        "increase": "-235",
        "male": "1410",
        "female": "1645",
        "rate": "-1.1"
    },
    {
        "label": "111年2月",
        "increase": "-347",
        "male": "1075",
        "female": "1422",
        "rate": "-1.8"
    },
    {
        "label": "111年3月",
        "increase": "-505",
        "male": "1330",
        "female": "1835",
        "rate": "-2.38"
    },
    {
        "label": "111年4月",
        "increase": "-221",
        "male": "1197",
        "female": "1418",
        "rate": "-1.08"
    },
    {
        "label": "111年5月",
        "increase": "-838",
        "male": "959",
        "female": "1797",
        "rate": "-3.99"
    }
];
export default {
    data: [
        sourceData.map(d => {
            return {
                value: Number(d.male)
            };
        }),
        sourceData.map(d => {
            return {
                value: Number(d.female)
            };
        }),
    ],
    yLabels: sourceData.map(d => d.label),
    itemLabels: ['男', '女']
};
