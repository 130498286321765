export const DEFAULT_CHART_DIRECTED_DAGRE_PARAMS = {
    styles: {
        text: 'fill: #303133;font-weight: normal;font-size: 14px;',
        textRoot: 'fill: #1778f5;font-weight: bold;font-size: 14px;',
        pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
        pathDown: 'stroke-dasharray: 5; stroke: #909399; stroke-width: 1.5px; fill:none;',
        arrowTop: 'fill: #909399',
        arrowDown: 'fill: #909399',
        rectRoot: 'rx:5px;ry:5px;stroke: url(#gradient);stroke-width: 3px;fill: #fff;',
        rect: 'rx:5px;ry:5px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
        expandBtn: 'fill:#1778F5',
        tag: 'fill:#f56c6c'
    },
    direction: 'LR',
    screenShotMode: false,
    autoZoom: false,
    highlightId: '',
    extandOnNodeClick: false,
    scaleExtent: {
        min: 0,
        max: Infinity // 最大放大比
    }
};
export const DEFAULT_CHART_DIRECTED_FORCE_PARAMS = {
    styles: {
        circleRoot: 'stroke-width: 5px; fill: #1778F5;',
        circle: 'stroke: #909399;stroke-width: 5px; fill: #fff;',
        circleText: 'fill: #303133; font-weight: normal; ',
        pathText: 'fill: #606266; font-weight: 300; font-size: 13px; text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;pointer-events: none;',
        pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
        pathDown: 'stroke-dasharray: 5; stroke: #909399; stroke-width: 1.5px; fill:none;',
        arrow: 'fill: #909399',
        circleLocked: 'stroke: #c00',
        pathLocked: 'stroke: #c00',
        arrowLocked: 'fill: #c00',
        expandBtn: 'fill:#1778F5',
        tag: 'fill:#f56c6c'
    },
    arrows: ['arrow'],
    circlesR: {
        circleRoot: 60,
        circle: 60
    },
    screenShotMode: false,
    autoZoom: false,
    lockMode: false,
    highlightId: '',
    extandOnNodeClick: false,
    scaleExtent: {
        min: 0,
        max: Infinity // 最大放大比
    }
};
