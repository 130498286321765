import * as d3 from 'd3';
import { DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
import { measureTextWidth } from '../utils';
export default class UtilAuxDateLabel {
    selection;
    data = [];
    dateLabelSelection = undefined;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection) {
        this.selection = selection;
    }
    setData(data) {
        this.data = data;
    }
    render() {
        const gUpdate = this.selection
            .selectAll('g.bpchart__label-box')
            .data(this.data);
        const gEnter = gUpdate
            .enter()
            .append('g')
            .classed('bpchart__label-box', true)
            .style('cursor', 'pointer');
        this.dateLabelSelection = gEnter.merge(gUpdate)
            .on('click', (d) => {
            this.clickCallback(d);
        })
            .on('mouseover', (d) => {
            this.mouseoverCallback(d);
        })
            .on('mousemove', (d) => {
            this.mousemoveCallback(d);
        })
            .on('mouseout', (d) => {
            this.mouseoutCallback(d);
        });
        gEnter
            .attr("transform", (d, i) => {
            return `translate(${d.x}, ${d.y})`;
        });
        gUpdate
            .transition()
            .duration(50)
            .attr("transform", (d, i) => {
            return `translate(${d.x}, ${d.y})`;
        });
        gUpdate.exit().remove();
        this.dateLabelSelection.each((d, i, n) => {
            const rectWidth = measureTextWidth(d.xLabel, 14) + 12;
            const rectX = -rectWidth / 2;
            const rectUpdate = d3.select(n[i])
                .selectAll('rect')
                .data([d]);
            const rectEnter = rectUpdate
                .enter()
                .append('rect')
                .attr('height', '18px')
                .attr('fill', DEFAULT_AXIS_LABEL_COLOR)
                .attr('x', rectX)
                .attr('y', -4)
                .attr('rx', 5)
                .attr('ry', 5);
            // .style('cursor', 'pointer')
            // .style('pointer-events', 'none')
            const rect = rectUpdate.merge(rectEnter)
                .attr('width', d => `${rectWidth}px`);
            rectUpdate.exit().remove();
            const textUpdate = d3.select(n[i])
                .selectAll('text')
                .data([d]);
            const textEnter = textUpdate
                .enter()
                .append('text')
                .attr('fill', '#ffffff')
                .attr('font-size', '14px')
                .attr('x', rectX + 6)
                .style('dominant-baseline', 'hanging');
            // .style('cursor', 'pointer')
            // .style('pointer-events', 'none')
            const text = textUpdate.merge(textEnter)
                .text(d => d.xLabel);
            textUpdate.exit().remove();
        });
    }
    remove() {
        this.selection.remove();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
}
