import * as d3 from 'd3';
import { svgHtml } from '@bpchart/d3-modules/d3Utils';
const makeClipPathData = (imgData, params) => {
    return imgData.map((d, i) => {
        // 最後一張圖才有需要裁切
        const lastDatum = d[d.length - 1];
        return {
            id: `bpchart__clipPath_${lastDatum.svgIndex}`,
            x: 0,
            y: 0,
            width: params.svgWidth * lastDatum.value,
            height: params.svgHeight
        };
    });
};
const makeImgRowStackData = (imgData) => {
    const imgLength = imgData.map(d => d.length);
    return imgData
        .map((d, i) => {
        return d.map((_d, _i) => {
            return {
                ..._d,
                // 最後一張圖才有需要裁切
                clipPathId: _i == (imgLength[i] - 1) ? `bpchart__clipPath_${_d.svgIndex}` : null
            };
        });
    })
        .flat();
};
export default class GraphicImageRowStack {
    selection;
    dataset = {
        data: [],
        svgs: [],
        xScale: d3.scalePoint()
    };
    params = {
        // step: 0,
        svgWidth: 0,
        svgHeight: 0
    };
    // private imgData: Array<ImgDatum> = []
    xScale;
    imgStack;
    imgData = [];
    svgs = [];
    clipPathData = [];
    defsSelection = undefined;
    constructor(selection, params) {
        this.selection = selection;
        this.defsSelection = this.selection.append('defs');
    }
    remove() {
        this.selection.remove();
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.imgData = makeImgRowStackData(dataset.data);
        this.xScale = dataset.xScale;
        this.svgs = dataset.svgs;
    }
    render() {
        this.clipPathData = makeClipPathData(this.dataset.data, this.params);
        // <defs><clipPath>
        this.renderClipPath(this.clipPathData);
        // this.xScale = d3.scalePoint()
        //   .domain(this.imgData.map((d, i) => String(i)))
        //   .range([0, this.params.step! * this.imgData.length])
        //   .padding(0.5)
        // console.log(this.imgData)
        const update = this.selection
            .selectAll('g.bpchart__img')
            .data(this.imgData, d => d.id);
        const enter = update
            .enter()
            .append('g')
            .classed('bpchart__img', true);
        update.exit().remove();
        this.imgStack = update.merge(enter)
            .attr('transform', (d, i) => `translate(${this.xScale(d.id)}, 0)`)
            .attr('clip-path', d => d.clipPathId ? `url(#${d.clipPathId})` : null);
        this.imgStack.each((d, i, g) => {
            svgHtml(d3.select(g[i]), this.svgs[d.svgIndex]);
            const svg = d3.select(g[i])
                .selectAll('svg');
            if (this.params.svgWidth) {
                svg.attr('width', this.params.svgWidth);
            }
            if (this.params.svgHeight) {
                svg.attr('height', this.params.svgHeight);
            }
        });
    }
    renderClipPath(clipPathData) {
        const update = this.defsSelection
            .selectAll('clipPath')
            .data(clipPathData);
        const enter = update.enter()
            .append('clipPath');
        const cutRect = update.merge(enter)
            .attr('id', d => d.id);
        update.exit().remove();
        cutRect.each((d, i, g) => {
            const updateRect = d3.select(g[i])
                .selectAll('rect')
                .data([d]);
            const enterRect = updateRect.enter()
                .append('rect');
            updateRect.exit().remove();
            updateRect.merge(enterRect)
                .attr('x', _d => _d.x)
                .attr('y', _d => _d.y)
                .attr('width', _d => _d.width)
                .attr('height', _d => _d.height);
        });
    }
}
