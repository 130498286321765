const response = {
    "payload": {
        "bar": [
            [
                {
                    value: 75
                },
                {
                    value: 90
                },
                {
                    value: 85
                },
                {
                    value: 110
                },
                {
                    value: 150
                },
                {
                    value: 170
                },
                {
                    value: 165
                },
            ],
            [
                {
                    value: 85
                },
                {
                    value: 130
                },
                {
                    value: 145
                },
                {
                    value: 160
                },
                {
                    value: 180
                },
                {
                    value: 150
                },
                {
                    value: 125
                }
            ]
        ],
        "line": [
            [
                {
                    "value": 25
                },
                {
                    "value": 60
                },
                {
                    "value": 70
                },
                {
                    "value": 65
                },
                {
                    "value": 100
                },
                {
                    "value": 110
                },
                {
                    "value": 120
                },
            ],
            [
                {
                    "value": 55
                },
                {
                    "value": 70
                },
                {
                    "value": 80
                },
                {
                    "value": 105
                },
                {
                    "value": 120
                },
                {
                    "value": 98
                },
                {
                    "value": 86
                },
            ],
        ],
        "xLabels": ["2016", "2017", "2018", "2019", "2020", "2021", "2022"]
    }
};
const dataset = {
    y1Data: response.payload.bar,
    y2Data: response.payload.line,
    xLabels: response.payload.xLabels,
    y1ItemLabels: ['進口數量', '出口數量'],
    y2ItemLabels: ['進口價格', '出口價格'],
};
export default dataset;
