import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_ROW_DIVERGING_PARAMS } from '../axisRowDiverging';
import { DEFAULT_GRAPHIC_ROW_BAR_DIVERGING_PARAMS } from '../graphicRowBarDiverging';
export const DEFAULT_CHART_ROW_DIVERGING_PARAMS = {
    axisRowDiverging: DEFAULT_AXIS_ROW_DIVERGING_PARAMS,
    tooltipAside: {
        templateHtml: (data) => {
            if (data.groupData && data.groupData[0]) {
                return `
          <div>
            <div>${data.groupData[0].yLabel}</div>
            <div>${data.groupData.map((d) => d.value).join(',')}</div>          
          </div>
        `;
            }
            return '';
        },
    },
    colors: DEFAULT_COLORS,
    padding: DEFAULT_PADDING,
    highlightTarget: 'datum',
    highlightDatumId: '',
    highlightItemId: '',
    showAuxLine: false,
    domainMinValue: 0,
    domainMaxValue: undefined,
    domainMinRange: undefined,
    domainMaxRange: 0.95
};
// export const DEFAULT_CHART_ROW_LINE_PARAMS: ChartRowLineParams = {
//   ...DEFAULT_CHART_ROW_PARAMS,
//   highlightTarget: 'item',
//   graphicRowLine: DEFAULT_GRAPHIC_ROW_LINE_PARAMS,
//   graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS,
//   showDot: 'hover'
// }
export const DEFAULT_CHART_ROW_BAR_DIVERGING_PARAMS = {
    ...DEFAULT_CHART_ROW_DIVERGING_PARAMS,
    graphicRowBarDiverging: DEFAULT_GRAPHIC_ROW_BAR_DIVERGING_PARAMS
};
