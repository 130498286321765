import { DEFAULT_COLORS, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_ROW_BAR_LAYOUT_PARAMS = {
    // barType: 'rect',
    // barWidth: 0,
    groupLayout: [],
    barPadding: 1,
    barGroupPadding: 20,
    // barR: false,
    colors: DEFAULT_COLORS,
    //   axisWidth: 0,
    //   axisHeight: 0,
    highlightTarget: 'item',
    highlightGroupId: '',
    highlightItemId: '',
    highlightDatumId: '',
    enterDuration: 800,
    labelTextMethod: (d, i) => String(d.value),
    labelPositionMethod: (d, i) => 'top',
    labelStyleMethod: (d, i) => '',
    labelFontSizeMethod: (d, i) => 10,
    labelColorMethod: (d, i) => DEFAULT_AXIS_LABEL_COLOR,
    labelPadding: 5
};
