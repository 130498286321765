import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import TooltipAside from '../tooltip/TooltipAside';
import { UtilAuxLine } from '../utilAuxLine';
import { AxisRowDiverging } from '../axisRowDiverging';
import { calcAxisWidth, calcAxisHeight, makeAxisPointScale, makeAxisLinearScale, makeAxisQuantizeScale } from '../moduleUtils';
import { DEFAULT_CHART_ROW_DIVERGING_PARAMS } from './defaults';
// 圖形上方和比例尺的間距
// const graphicTopPadding = 0.95
const makeAxisRowDivergingParams = (params, padding) => {
    return {
        ...params,
        padding
    };
};
// const makeYPointScale = (yLabels: string[], axisWidth: number) => {
//   return d3.scalePoint()
//       .domain(yLabels)
//       .range([0, axisWidth!])
//       .padding(0.5)
// }
// const makeAxisLinearScale = (_maxValue: number, axisHeight: number, domainValue?: [number, number], domainRange?: [number, number]) => {
//   const minValue = domainValue ? domainValue[0] : 0
//   const maxValue = domainValue ? domainValue[1] : _maxValue
//   let domainMinValue = 0
//   let domainMaxValue = 0
//   // 用最大/小值在比例尺上的位置(參數domainRange)，反推比例尺的最大/小值 (d3.scale.domain)
//   if (domainRange) {
//     domainMinValue = maxValue - (maxValue - minValue) / (1 - domainRange[0])
//     domainMaxValue = maxValue / domainRange[1]
//   } else {
//     domainMinValue = minValue
//     domainMaxValue = maxValue
//   }
//   // 最大值為如果為0的話畫出來的圖會異常（因為會以range的中間值去計算）
//   if (domainMaxValue == 0) {
//     domainMaxValue = 1
//   }
//   return d3.scaleLinear()
//     .domain([domainMaxValue, domainMinValue]) // 因為要由下往上所以反過來
//     .range([0, axisHeight])
// }
// const makeAxisQuantizeScale = (yLabels: string[], axisWidth: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisWidth + rangePadding])
//     .range(yLabels.map((d, i) => i))
// }
// const getMaxValue = (data: Datum[] | Datum[][]) => {
//   if (!data.length) {
//     return 0
//   }
//   // data為 Datum[]
//   else if ((data[0] as Datum).value) {
//     return d3.max(data as Datum[], d => d.value) ?? 0
//   }
//   // data為 Datum[][]
//   else if ((data[0] as Datum[])[0]) {
//     return d3.max(data as Datum[][], d => d3.max(d, _d => _d.value)) ?? 0
//   }
//   return 0
// }
const makeCallbackData = ({ dataset, activedItemId, yDataMap, ItemDataMap, padding, yRangeScale, event }) => {
    if (!event || !yRangeScale) {
        return {
            dataset,
            groupData: [],
            itemData: [],
            datum: undefined,
            yLabel: '',
            yIndex: -1,
            itemLabel: '',
            itemIndex: -1,
            rangeIndex: -1,
            clientX: -1,
            clientY: -1,
            offsetX: -1,
            offsetY: -1
        };
    }
    // 座標軸x座標
    const offsetY = Number(event.offsetY) - padding.top;
    // 資料索引
    const rangeIndex = Number(yRangeScale(offsetY) ?? 0);
    const yLabel = dataset.yLabels[rangeIndex];
    const groupData = yDataMap.get(yLabel) ?? [];
    let itemData = [];
    let selectedData = undefined;
    if (activedItemId) {
        itemData = ItemDataMap.get(activedItemId) ?? [];
        selectedData = groupData.find(d => d.itemLabel === activedItemId);
    }
    return {
        dataset,
        groupData,
        itemData,
        datum: selectedData,
        yLabel: yLabel,
        yIndex: rangeIndex,
        itemLabel: selectedData ? selectedData.itemLabel : '',
        itemIndex: selectedData ? selectedData.itemIndex : -1,
        rangeIndex,
        clientX: d3.event.clientX,
        clientY: d3.event.clientY,
        offsetX: d3.event.offsetX,
        offsetY: d3.event.offsetY
    };
};
export default class ChartRowDiverging {
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        data: [],
        yLabels: []
    };
    params = DEFAULT_CHART_ROW_DIVERGING_PARAMS;
    selection;
    tooltip;
    graphicSelection = undefined;
    coverSelection;
    axisWidth = 0;
    axisHeight = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    yDataMap = new Map();
    ItemDataMap = new Map();
    renderData = [];
    // private renderData: RenderDatum[] | RenderDatum[][] = []
    // private itemLabels: string[] = []
    utilAuxLine;
    axisSelection;
    axisRowDiverging;
    axisParams = this.params.axisRowDiverging;
    rangeSelection;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    x1Scale;
    x2Scale;
    y1Scale;
    y2Scale;
    yRangeScale; // 滑鼠座標轉換為類別索引
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    auxLineSelection = undefined;
    // private idList: string[] = []
    // private yLabels: string[] = []
    minValue = 0;
    maxValue = 0;
    zeroX1 = 0;
    zeroX2 = 0;
    constructor(selection, params) {
        this.selection = selection;
        this.axisSelection = selection.append('g');
        this.axisRowDiverging = new AxisRowDiverging(this.axisSelection, {});
        this.rangeSelection = this.selection
            .append('rect')
            .attr('opacity', 0);
        this.setRangeEvent(this.rangeSelection);
        this.graphicSelection = selection.append('g');
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        this.utilAuxLine = new UtilAuxLine(this.coverSelection);
        this.setCoverEvent(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            axisRowDiverging: {
                ...this.params.axisRowDiverging,
                ...params.axisRowDiverging
            },
        };
        if (this.params.tooltipAside && !this.tooltip) {
            this.tooltip = new TooltipAside(this.selection, {
                templateHtml: this.params.tooltipAside.templateHtml,
                type: this.params.tooltipAside.type ?? 'white',
                yLine: this.params.tooltipAside.yLine ?? false,
            });
        }
        this.axisParams = makeAxisRowDivergingParams(this.params.axisRowDiverging, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisRowDiverging.setParams(this.axisParams);
        this.graphicSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.coverSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.initGraphic(this.params);
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisParams = makeAxisRowDivergingParams(this.params.axisRowDiverging, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width - this.params.axisRowDiverging.gutterWidth, this.params.padding) / 2;
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisRowDiverging.setParams(this.axisParams);
        this.axisRowDiverging.resize({ width, height });
        this.rangeSelection
            .attr('x', this.params.padding.left)
            .attr('y', this.params.padding.top)
            .attr('width', this.axisWidth)
            .attr('height', this.axisHeight);
        // this.initGraphic(this.params)
    }
    setDataset(dataset) {
        this.dataset = dataset;
        const [minValue, maxValue] = this.getMinAndMaxValue(this.dataset.data);
        this.minValue = minValue;
        this.maxValue = maxValue;
        // this.renderData = this.makeGraphicRenderData({
        //   dataset: this.dataset,
        //   xScale: this.xScale!,
        //   yScale: this.yScale!
        // })
        // this.yDataMap = this.makeYDataMap({
        //   renderData: this.renderData,
        //   yLabels: this.dataset.yLabels
        // })
        // this.ItemDataMap = this.makeItemDataMap({
        //   renderData: this.renderData,
        //   itemLabels: this.itemLabels ?? []
        // })
    }
    render() {
        this.zeroX1 = this.axisWidth;
        this.zeroX2 = this.axisWidth + this.params.axisRowDiverging.gutterWidth;
        this.y1Scale = makeAxisPointScale({
            axisLabels: this.dataset.yLabels,
            axisWidth: this.axisHeight,
            reverse: true
        });
        this.y2Scale = makeAxisPointScale({
            axisLabels: this.dataset.yLabels,
            axisWidth: this.axisHeight,
            reverse: true
        });
        this.x1Scale = makeAxisLinearScale({
            maxValue: this.maxValue,
            minValue: this.minValue,
            axisWidth: this.axisWidth,
            domainMinValue: this.params.domainMinValue,
            domainMaxValue: this.params.domainMaxValue,
            domainMinRange: this.params.domainMinRange,
            domainMaxRange: this.params.domainMaxRange,
            reverse: true // 左邊的 x座標軸是由右到左
        });
        this.x2Scale = makeAxisLinearScale({
            maxValue: this.maxValue,
            minValue: this.minValue,
            axisWidth: this.axisWidth,
            domainMinValue: this.params.domainMinValue,
            domainMaxValue: this.params.domainMaxValue,
            domainMinRange: this.params.domainMinRange,
            domainMaxRange: this.params.domainMaxRange,
        });
        this.yRangeScale = makeAxisQuantizeScale({
            axisLabels: this.dataset.yLabels,
            axisWidth: this.axisHeight,
            reverse: true
        });
        this.renderData = this.makeGraphicRenderData({
            dataset: this.dataset,
            zeroX1: this.zeroX1,
            zeroX2: this.zeroX2,
            x1Scale: this.x1Scale,
            x2Scale: this.x2Scale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
        });
        this.yDataMap = this.makeYDataMap({
            renderData: this.renderData,
            yLabels: this.dataset.yLabels
        });
        this.ItemDataMap = this.makeItemDataMap({
            renderData: this.renderData,
            itemLabels: this.dataset.itemLabels ?? []
        });
        // this.renderData = makeGraphicRenderData({
        //   data: this.renderData,
        //   yLabels: this.dataset.yLabels,
        //   itemLabels: this.itemLabels,
        //   xScale: this.xScale,
        //   yScale: this.yScale
        // })
        this.setGraphicData({
            renderData: this.renderData,
            itemLabels: this.dataset.itemLabels ?? [],
            yLabels: this.dataset.yLabels,
            zeroX1: this.zeroX1,
            zeroX2: this.zeroX2,
            x1Scale: this.x1Scale,
            x2Scale: this.x2Scale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale
        });
        // 繪製座標軸
        this.axisRowDiverging.setDataset({
            x1Scale: this.x1Scale,
            x2Scale: this.x2Scale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
            maxValue: this.maxValue
        });
        this.axisRowDiverging.render();
        this.renderGraphic();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    makeActivedCallbackData = (activedItemId, event) => {
        return makeCallbackData({
            dataset: this.dataset,
            activedItemId: activedItemId,
            yDataMap: this.yDataMap,
            ItemDataMap: this.ItemDataMap,
            padding: this.params.padding,
            yRangeScale: this.yRangeScale,
            event
        });
    };
    setRangeEvent(selection) {
        selection
            // .on('mousedown', (d) => {
            //   this.dragstartX = d3.event.clientX
            // })
            .on('click', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    showTooltip(eventData, event) {
        if (event && eventData && this.y1Scale) {
            this.tooltip.setDatum({
                data: eventData,
                x: d3.event.clientX,
                y: eventData.clientY - eventData.offsetY + this.params.padding.top + this.y1Scale(eventData.yLabel),
            });
        }
        else {
            this.tooltip.remove();
        }
    }
    setCoverEvent(selection) {
        selection
            .on('mouseover', (d, i) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mousemove', (d) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            this.coverHighlight(undefined);
            this.tooltip.remove();
        });
    }
    // 複蓋區域 highlight
    coverHighlight(eventData) {
        // 標示線
        if (this.params.showAuxLine) {
            this.renderAuxLine({
                coverSelection: this.coverSelection,
                yLabels: this.dataset.yLabels,
                activeRangeIndex: eventData ? eventData.rangeIndex : -1,
                yScale: this.y1Scale,
                padding: this.params.padding,
                axisWidth: this.axisWidth * 2 + this.params.axisRowDiverging.gutterWidth // 橫跨兩個圖軸
            });
        }
        // 圖形
        this.renderGraphicCoverHighlight({
            eventData: eventData,
            x1Scale: this.x1Scale,
            x2Scale: this.x2Scale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
            padding: this.params.padding,
        });
    }
    renderAuxLine({ coverSelection, yLabels, activeRangeIndex, yScale, padding, axisWidth }) {
        if (!yScale) {
            return;
        }
        const auxLineData = yLabels.map((d, i) => {
            const y = (yScale(d) || 0);
            return {
                x1: 0,
                x2: axisWidth,
                y1: y,
                y2: y,
                active: activeRangeIndex == i
            };
        });
        this.utilAuxLine.setData(auxLineData);
        this.utilAuxLine.render();
    }
}
