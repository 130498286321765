import Button from './Button';
import * as d3 from 'd3';
import { svgHtml } from '../d3Utils';
const r = 255.996;
const strokeWidth = 100;
const groupR = r + strokeWidth;
const svgWidth = 255.996 * 2 + strokeWidth * 2;
const createPlusSvgHtml = (style = 'fill:#1778f5') => {
    return `
  <g transform="translate(${groupR}, ${groupR})">
    <circle cx="0" cy="0" r="${groupR}" fill="white"/>
    <circle style="${style};" cx="0" cy="0" r="${r}"/>
    <polygon transform="translate(${-r}, ${-r})" style="fill:#ffffff;" points="443.811,297.202 443.811,214.798 297.202,214.798 297.202,68.189 214.799,68.189   214.798,214.798 68.189,214.799 68.189,297.202 214.798,297.202 214.798,443.811 297.201,443.811 297.202,297.202 "/>
  </g>
  `;
};
const createMinusSvgHtml = (style = 'fill:#1778f5') => {
    return `
  <g transform="translate(${groupR}, ${groupR})">
    <circle cx="0" cy="0" r="${groupR}" fill="white"/>
    <circle style="${style};" cx="0" cy="0" r="${r}"/>
    <rect x="${81.679 - r}" y="${214.535 - r}" style="fill:#ffffff;" width="348.646" height="82.922"/>
  </g>
  `;
};
const iconSize = 20;
const createTransformText = (x, y, size, scale) => {
    const offset = (size / 2) * scale;
    return `translate(${x - offset}, ${y - offset}) scale(${scale})`;
};
export default class ButtonExpandIcon extends Button {
    params = {
        expanded: false,
        x: 0,
        y: 0
    };
    buttonSelection = undefined;
    buttonSvg;
    minusSvgHtml = createMinusSvgHtml();
    plusSvgHtml = createPlusSvgHtml();
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    constructor(selection, params) {
        super(selection, params);
        if (params) {
            this.params = params;
        }
        this.buttonSelection = this.selection.append('g')
            .attr('transform', createTransformText(this.params.x, this.params.y, iconSize, 1));
        this.buttonSvg = this.buttonSelection
            .append("svg")
            // .classed("button-expand-icon", true)
            // .attr('id', d => `circleBtnSvg__${d.id}`)
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .attr('xmls', 'http://www.w3.org/2000/svg')
            .attr('version', '1.1')
            .attr('width', iconSize)
            .attr('height', iconSize)
            .attr('viewBox', `0 0 ${svgWidth} ${svgWidth}`)
            .on('click', () => {
            this.params.expanded = !this.params.expanded;
            this.render();
            this.clickCallback(this.params);
        })
            .on('mouseover', () => {
            this.buttonSelection
                .transition()
                .duration(50)
                .attr('transform', createTransformText(this.params.x, this.params.y, iconSize, 1.4));
            this.mouseoverCallback(this.params);
        })
            .on('mousemove', () => {
            this.mousemoveCallback(this.params);
        })
            .on('mouseout', () => {
            this.buttonSelection
                .transition()
                .duration(50)
                .attr('transform', createTransformText(this.params.x, this.params.y, iconSize, 1));
            this.mouseoutCallback(this.params);
        });
    }
    render() {
        if (this.params.expanded === true) {
            svgHtml(this.buttonSvg, this.minusSvgHtml);
        }
        else {
            svgHtml(this.buttonSvg, this.plusSvgHtml);
        }
    }
    setParams(updateConfig) {
        if (updateConfig.x != undefined
            && updateConfig.x != this.params.x
            && updateConfig.y != undefined
            && updateConfig.y != this.params.y) {
            this.params.x = updateConfig.x;
            this.params.y = updateConfig.y;
            this.buttonSelection
                .attr('transform', `translate(${this.params.x - 10}, ${this.params.y - 10})`);
        }
        if (updateConfig.expanded != undefined && updateConfig.expanded != this.params.expanded) {
            this.params.expanded = updateConfig.expanded;
            this.render();
        }
    }
    // public disabled (isDisabled: boolean) {
    //   this.params.disabled = isDisabled
    //   const cursorValue = this.params.disabled ? 'none' : 'pointer'
    //   this.buttonSelection!
    //     .style('cursor', cursorValue)
    // }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
}
