import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_COLUMN_PARAMS } from '../axisColumn';
import { DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS } from '../graphicColumnLine';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from '../graphicDot';
import { DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS } from '../graphicColumnBar';
import { DEFAULT_GRAPHIC_COLUMN_BAR_GROUP_PARAMS } from '../graphicColumnBarGroup';
import { DEFAULT_GRAPHIC_COLUMN_BAR_DIVERGING_PARAMS } from '../graphicColumnBarDiverging';
import { DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS } from '../graphicColumnBarStack';
import { DEFAULT_GRAPHIC_COLUMN_AREA_STACK_PARAMS } from '../graphicColumnAreaStack';
export const DEFAULT_CHART_COLUMN_PARAMS = {
    axisColumn: DEFAULT_AXIS_COLUMN_PARAMS,
    tooltipAside: {
        templateHtml: (data) => {
            if (data.groupData && data.groupData[0]) {
                return `
          <div>
            <div>${data.groupData[0].xLabel}</div>
            <div>${data.groupData.map((d) => d.value).join(',')}</div>          
          </div>
        `;
            }
            return '';
        },
    },
    colors: DEFAULT_COLORS,
    padding: DEFAULT_PADDING,
    highlightTarget: 'datum',
    highlightDatumId: '',
    highlightItemId: '',
    showAuxLine: false,
    domainMinValue: 0,
    domainMaxValue: undefined,
    domainMinRange: undefined,
    domainMaxRange: 0.95
};
export const DEFAULT_CHART_COLUMN_LINE_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    highlightTarget: 'item',
    // lineCurve: 'curveLinear',
    // lineType: 'line',
    graphicColumnLine: DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS,
    graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS,
    showDot: 'hover'
};
export const DEFAULT_CHART_COLUMN_BAR_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    // barType: 'rect',
    // barWidth: 0,
    // barPadding: 5,
    // barR: false
    graphicColumnBar: DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS
};
export const DEFAULT_CHART_COLUMN_BAR_GROUP_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    // barType: 'rect',
    // barWidth: 0,
    // barPadding: 5,
    // barR: false
    graphicColumnBarGroup: DEFAULT_GRAPHIC_COLUMN_BAR_GROUP_PARAMS
};
export const DEFAULT_CHART_COLUMN_BAR_PN_GROUP_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    graphicColumnBarDiverging: DEFAULT_GRAPHIC_COLUMN_BAR_DIVERGING_PARAMS,
    // 覆蓋原來的範圍設定
    domainMinValue: 0,
    domainMaxValue: undefined,
    domainMinRange: 0.05,
    domainMaxRange: 0.95
};
export const DEFAULT_CHART_COLUMN_BAR_STACK_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    graphicColumnBarStack: DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS
};
export const DEFAULT_CHART_COLUMN_AREA_STACK_PARAMS = {
    ...DEFAULT_CHART_COLUMN_PARAMS,
    highlightTarget: 'item',
    graphicColumnAreaStack: DEFAULT_GRAPHIC_COLUMN_AREA_STACK_PARAMS,
    graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS,
    showDot: 'hover'
};
