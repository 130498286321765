import { AxisDateColumn } from '@bpchart/d3-modules/axisDateColumn';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_AXIS_DATE_COLUMN_PARAMS } from '@bpchart/d3-modules/axisDateColumn';
export default {
    props: {
        module: AxisDateColumn,
        params: DEFAULT_AXIS_DATE_COLUMN_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
