const dataset = {
    "nodes": [
        {
            "id": "25136736",
            "uniID": "25136736",
            "label": "三創數位股份有限公司",
            "style": "rectRoot",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "三創數位股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "郭守正",
                                    "sno": "0001",
                                    "stocks": 183646250,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "游哲宏",
                                    "sno": "0002",
                                    "stocks": 183646250,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻準精密工業股份有限公司",
                                    "ROL_for_search": "鴻準精密工業股份有限公司",
                                    "ROL_uniID": "23707801",
                                    "name": "黃幹",
                                    "sno": "0003",
                                    "stocks": 49032250,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "廣宇科技股份有限公司",
                                    "ROL_for_search": "廣宇科技股份有限公司",
                                    "ROL_uniID": "03546618",
                                    "name": "陳志光",
                                    "sno": "0004",
                                    "stocks": 12831500,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "郭守正",
                            "uniID": "25136736",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "04541302",
                    "23707801",
                    "03546618"
                ],
                "Substantial-beneficiary": [
                    {
                        "direct": 74.801942894383,
                        "indirect": 1.2408963204057102,
                        "name": "鴻海精密工業股份有限公司",
                        "role": "法人",
                        "total": 76.04283921478871,
                        "uniID": "04541302"
                    },
                    {
                        "direct": 19.971589751945,
                        "indirect": 0,
                        "name": "鴻準精密工業股份有限公司",
                        "role": "法人",
                        "total": 19.971589751945,
                        "uniID": "23707801"
                    },
                    {
                        "direct": 0,
                        "indirect": 7.360019966507016,
                        "name": "郭台銘",
                        "role": "自然人",
                        "total": 7.360019966507016,
                        "uniID": "鴻海精密工業股份有限公司_3_郭台銘"
                    },
                    {
                        "direct": 5.2264673536719,
                        "indirect": 0,
                        "name": "廣宇科技股份有限公司",
                        "role": "法人",
                        "total": 5.2264673536719,
                        "uniID": "03546618"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.1849623486486815,
                        "name": "鴻元國際投資股份有限公司",
                        "role": "法人",
                        "total": 0.1849623486486815,
                        "uniID": "16098325"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.1064880217630221,
                        "name": "永齡資本控股股份有限公司",
                        "role": "法人",
                        "total": 0.1064880217630221,
                        "uniID": "90779228"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.0338859583711701,
                        "name": "呂芳銘",
                        "role": "自然人",
                        "total": 0.0338859583711701,
                        "uniID": "鴻海精密工業股份有限公司_4_呂芳銘"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.02052504462376422,
                        "name": "盧松發",
                        "role": "自然人",
                        "total": 0.02052504462376422,
                        "uniID": "廣宇科技股份有限公司_1_盧松發"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.008135146670572895,
                        "name": "鴻景國際投資股份有限公司",
                        "role": "法人",
                        "total": 0.008135146670572895,
                        "uniID": "16410233"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.004067573335286448,
                        "name": "鴻棋國際投資股份有限公司",
                        "role": "法人",
                        "total": 0.004067573335286448,
                        "uniID": "16088229"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.0035995664510003397,
                        "name": "劉揚偉",
                        "role": "自然人",
                        "total": 0.0035995664510003397,
                        "uniID": "鴻海精密工業股份有限公司_1_劉揚偉"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.0028658508570764823,
                        "name": "蔡明峰",
                        "role": "自然人",
                        "total": 0.0028658508570764823,
                        "uniID": "廣宇科技股份有限公司_3_蔡明峰"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.0003529037705695684,
                        "name": "黃鳳安",
                        "role": "自然人",
                        "total": 0.0003529037705695684,
                        "uniID": "廣宇科技股份有限公司_2_黃鳳安"
                    },
                    {
                        "direct": 0,
                        "indirect": 0.00005792490269645302,
                        "name": "富鉅科技股份有限公司",
                        "role": "法人",
                        "total": 0.00005792490269645302,
                        "uniID": "13108601"
                    },
                    {
                        "direct": 0,
                        "indirect": 2.125509416427548e-7,
                        "name": "莊宏仁",
                        "role": "自然人",
                        "total": 2.125509416427548e-7,
                        "uniID": "永齡資本控股股份有限公司_1_莊宏仁"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "陳耀清",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻準精密工業股份有限公司_3_陳耀清"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "郭大維",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻海精密工業股份有限公司_8_郭大維"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "蘇維國",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻準精密工業股份有限公司_4_蘇維國"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "莊宏仁",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "富鉅科技股份有限公司_1_莊宏仁"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "龔國權",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻海精密工業股份有限公司_9_龔國權"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "鄭文榮",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "廣宇科技股份有限公司_7_鄭文榮"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "王國城",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻海精密工業股份有限公司_7_王國城"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "王月霞",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "富鉅科技股份有限公司_2_王月霞"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "黃錦經",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "廣宇科技股份有限公司_6_黃錦經"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "吳敬恒",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "鴻準精密工業股份有限公司_5_吳敬恒"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "黃凱麟",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "永齡資本控股股份有限公司_2_黃凱麟"
                    },
                    {
                        "direct": 0,
                        "indirect": 0,
                        "name": "魏敏昌",
                        "role": "自然人",
                        "total": 0,
                        "uniID": "廣宇科技股份有限公司_5_魏敏昌"
                    }
                ]
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_1_劉揚偉",
            "uniID": "鴻海精密工業股份有限公司_1_劉揚偉",
            "label": "劉揚偉",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "劉揚偉",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_1_劉揚偉",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_1_劉揚偉->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_1_劉揚偉",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_1_劉揚偉->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_1_劉揚偉->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_1_劉揚偉->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.0047336034374428,
                        74.801942894383
                    ],
                    [
                        0.0047336034374428,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0.0047336034374428,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.0035408273401225142,
                    0.00005017581151983904,
                    0.000008563299357986517
                ],
                "total-investment-ratio": 0.0035995664510003397,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "43802178",
            "uniID": "43802178",
            "label": "沛博科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "沛博科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "林峯涵",
                                    "sno": "0001",
                                    "stocks": 10000000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "何國樑",
                                    "sno": "0002",
                                    "stocks": 10000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "邱崇哲",
                                    "sno": "0003",
                                    "stocks": 10000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "許鴻英",
                                    "sno": "0004",
                                    "stocks": 10000000,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "林峯涵",
                            "uniID": "43802178",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "97164611",
            "uniID": "97164611",
            "label": "鴻揚創業投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻揚創業投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 1174960420,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "張傳旺",
                                    "sno": "0002",
                                    "stocks": 24539580,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "97164611",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [
                    "54258218",
                    "80445574",
                    "54269279"
                ],
                "stock-up": [
                    "04541302",
                    "16636534"
                ]
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_3_陳耀清",
            "uniID": "鴻準精密工業股份有限公司_3_陳耀清",
            "label": "陳耀清",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "陳耀清",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻準精密工業股份有限公司_3_陳耀清",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻準精密工業股份有限公司_3_陳耀清->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "鴻準精密工業股份有限公司_3_陳耀清",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻準精密工業股份有限公司_3_陳耀清->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "23707801"
                ],
                "stock-up": []
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_8_郭大維",
            "uniID": "鴻海精密工業股份有限公司_8_郭大維",
            "label": "郭大維",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "郭大維",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_8_郭大維",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_8_郭大維->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_8_郭大維",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_8_郭大維->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_8_郭大維->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_8_郭大維->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        74.801942894383
                    ],
                    [
                        0,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0,
                    0,
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "13108601",
            "uniID": "13108601",
            "label": "富鉅科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "富鉅科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "莊宏仁",
                                    "sno": "0001",
                                    "stocks": 0,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "王月霞",
                                    "sno": "0002",
                                    "stocks": 0,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "莊宏仁",
                            "uniID": "13108601",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "13108601->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "13108601",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "13108601->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "13108601->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "13108601->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.000076174039915632,
                        74.801942894383
                    ],
                    [
                        0.000076174039915632,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0.000076174039915632,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.00005697966183803557,
                    8.074386289478084e-7,
                    1.378022294696403e-7
                ],
                "total-investment-ratio": 0.00005792490269645302,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": [
                    "富鉅科技股份有限公司_1_莊宏仁",
                    "富鉅科技股份有限公司_2_王月霞"
                ]
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_3_郭台銘",
            "uniID": "鴻海精密工業股份有限公司_3_郭台銘",
            "label": "郭台銘",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "郭台銘",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_3_郭台銘",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_3_郭台銘->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_3_郭台銘",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_3_郭台銘->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_3_郭台銘->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_3_郭台銘->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        9.6787811219385,
                        74.801942894383
                    ],
                    [
                        9.6787811219385,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        9.6787811219385,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    7.239916327704759,
                    0.10259429285409606,
                    0.017509345948160553
                ],
                "total-investment-ratio": 7.360019966507016,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "16594892",
            "uniID": "16594892",
            "label": "建漢科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "建漢科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻準精密工業股份有限公司",
                                    "ROL_for_search": "鴻準精密工業股份有限公司",
                                    "ROL_uniID": "23707801",
                                    "name": "陳澤燦",
                                    "sno": "0001",
                                    "stocks": 10035348,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "吳忠和",
                                    "sno": "0002",
                                    "stocks": 400000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻準精密工業股份有限公司",
                                    "ROL_for_search": "鴻準精密工業股份有限公司",
                                    "ROL_uniID": "23707801",
                                    "name": "何國樑",
                                    "sno": "0003",
                                    "stocks": 10035348,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "丁鴻勛",
                                    "sno": "0004",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "章毓群",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "林詩梅",
                                    "sno": "0006",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "林盈杉",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                }
                            ],
                            "representative_name": "陳澤燦",
                            "uniID": "16594892",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "23707801"
                ]
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_4_蘇維國",
            "uniID": "鴻準精密工業股份有限公司_4_蘇維國",
            "label": "蘇維國",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "蘇維國",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻準精密工業股份有限公司_4_蘇維國",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻準精密工業股份有限公司_4_蘇維國->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "鴻準精密工業股份有限公司_4_蘇維國",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻準精密工業股份有限公司_4_蘇維國->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "23707801"
                ],
                "stock-up": []
            }
        },
        {
            "id": "富鉅科技股份有限公司_1_莊宏仁",
            "uniID": "富鉅科技股份有限公司_1_莊宏仁",
            "label": "莊宏仁",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "莊宏仁",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "富鉅科技股份有限公司_1_莊宏仁",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "富鉅科技股份有限公司_1_莊宏仁->13108601",
                "13108601->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "富鉅科技股份有限公司_1_莊宏仁",
                "13108601",
                "13108601",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "富鉅科技股份有限公司_1_莊宏仁->13108601",
                        "13108601->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "富鉅科技股份有限公司_1_莊宏仁->13108601",
                        "13108601->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "富鉅科技股份有限公司_1_莊宏仁->13108601",
                        "13108601->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        0.000076174039915632,
                        74.801942894383
                    ],
                    [
                        0,
                        0.000076174039915632,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0,
                        0.000076174039915632,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0,
                    0,
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "13108601"
                ],
                "stock-up": []
            }
        },
        {
            "id": "90779228",
            "uniID": "90779228",
            "label": "永齡資本控股股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "永齡資本控股股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "莊宏仁",
                                    "sno": "0001",
                                    "stocks": 100,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃凱麟",
                                    "sno": "0002",
                                    "stocks": 0,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "莊宏仁",
                            "uniID": "90779228",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "90779228->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "90779228",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "90779228->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.53319752250895,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.1064880217630221
                ],
                "total-investment-ratio": 0.1064880217630221,
                "stock-down": [
                    "23707801"
                ],
                "stock-up": [
                    "永齡資本控股股份有限公司_1_莊宏仁",
                    "永齡資本控股股份有限公司_2_黃凱麟"
                ]
            }
        },
        {
            "id": "23707801",
            "uniID": "23707801",
            "label": "鴻準精密工業股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻準精密工業股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2009-02-01",
                                    "name": "李翰明",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "永齡資本控股股份有限公司",
                                    "ROL_for_search": "永齡資本控股股份有限公司",
                                    "ROL_uniID": "90779228",
                                    "name": "呂軍甫",
                                    "sno": "0001",
                                    "stocks": 7542000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "永齡資本控股股份有限公司",
                                    "ROL_for_search": "永齡資本控股股份有限公司",
                                    "ROL_uniID": "90779228",
                                    "name": "林群倫",
                                    "sno": "0002",
                                    "stocks": 7542000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "陳耀清",
                                    "sno": "0003",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "蘇維國",
                                    "sno": "0004",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "吳敬恒",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                }
                            ],
                            "representative_name": "呂軍甫",
                            "uniID": "23707801",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    19.971589751945
                ],
                "total-investment-ratio": 19.971589751945,
                "stock-down": [
                    "25136736",
                    "80696570",
                    "16594892"
                ],
                "stock-up": [
                    "90779228",
                    "鴻準精密工業股份有限公司_3_陳耀清",
                    "鴻準精密工業股份有限公司_4_蘇維國",
                    "鴻準精密工業股份有限公司_5_吳敬恒"
                ]
            }
        },
        {
            "id": "廣宇科技股份有限公司_1_盧松發",
            "uniID": "廣宇科技股份有限公司_1_盧松發",
            "label": "盧松發",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "盧松發",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_1_盧松發",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_1_盧松發->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_1_盧松發",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_1_盧松發->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.39271353353703,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.02052504462376422
                ],
                "total-investment-ratio": 0.02052504462376422,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        },
        {
            "id": "70771374",
            "uniID": "70771374",
            "label": "彥揚投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "彥揚投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "廣宇科技股份有限公司",
                                    "ROL_for_search": "廣宇科技股份有限公司",
                                    "ROL_uniID": "03546618",
                                    "name": "郭世華",
                                    "sno": "0001",
                                    "stocks": 33316236,
                                    "title": "董事"
                                }
                            ],
                            "representative_name": "郭世華",
                            "uniID": "70771374",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "03546618"
                ]
            }
        },
        {
            "id": "54269279",
            "uniID": "54269279",
            "label": "虹晶科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "虹晶科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻揚創業投資股份有限公司",
                                    "ROL_for_search": "鴻揚創業投資股份有限公司",
                                    "ROL_uniID": "97164611",
                                    "name": "陳偉銘",
                                    "sno": "0001",
                                    "stocks": 32000000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻揚創業投資股份有限公司",
                                    "ROL_for_search": "鴻揚創業投資股份有限公司",
                                    "ROL_uniID": "97164611",
                                    "name": "何家驊",
                                    "sno": "0002",
                                    "stocks": 32000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻揚創業投資股份有限公司",
                                    "ROL_for_search": "鴻揚創業投資股份有限公司",
                                    "ROL_uniID": "97164611",
                                    "name": "彭介平",
                                    "sno": "0003",
                                    "stocks": 32000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "林秀韓",
                                    "sno": "0004",
                                    "stocks": 21138600,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "陳偉銘",
                            "uniID": "54269279",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "97164611",
                    "04541302"
                ]
            }
        },
        {
            "id": "03546618",
            "uniID": "03546618",
            "label": "廣宇科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "廣宇科技股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2002-01-29",
                                    "name": "盧松發",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "盧松發",
                                    "sno": "0001",
                                    "stocks": 2035616,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃鳳安",
                                    "sno": "0002",
                                    "stocks": 35000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "蔡明峰",
                                    "sno": "0003",
                                    "stocks": 284227,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻元國際投資股份有限公司",
                                    "ROL_for_search": "鴻元國際投資股份有限公司",
                                    "ROL_uniID": "16098325",
                                    "name": "蕭才祐",
                                    "sno": "0004",
                                    "stocks": 17941593,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "魏敏昌",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃錦經",
                                    "sno": "0006",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "鄭文榮",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限",
                                    "ROL_uniID": "04541302",
                                    "contribution": 105127002,
                                    "edge_type": "大股東",
                                    "is_big": true,
                                    "name": "鴻海精密工業股份有限",
                                    "stocks": 105127002
                                }
                            ],
                            "representative_name": "盧松發",
                            "uniID": "03546618",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    5.2264673536719
                ],
                "total-investment-ratio": 5.2264673536719,
                "stock-down": [
                    "25136736",
                    "70771374"
                ],
                "stock-up": [
                    "廣宇科技股份有限公司_1_盧松發",
                    "廣宇科技股份有限公司_2_黃鳳安",
                    "廣宇科技股份有限公司_3_蔡明峰",
                    "16098325",
                    "廣宇科技股份有限公司_5_魏敏昌",
                    "廣宇科技股份有限公司_6_黃錦經",
                    "廣宇科技股份有限公司_7_鄭文榮",
                    "04541302"
                ]
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_4_呂芳銘",
            "uniID": "鴻海精密工業股份有限公司_4_呂芳銘",
            "label": "呂芳銘",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "呂芳銘",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_4_呂芳銘",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_4_呂芳銘->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_4_呂芳銘",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_4_呂芳銘->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_4_呂芳銘->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_4_呂芳銘->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.04456166908163,
                        74.801942894383
                    ],
                    [
                        0.04456166908163,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0.04456166908163,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.03333299425922479,
                    0.00047235006869464314,
                    0.00008061404325066906
                ],
                "total-investment-ratio": 0.0338859583711701,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "16098325",
            "uniID": "16098325",
            "label": "鴻元國際投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻元國際投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 341678900,
                                    "title": "董事長"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "16098325",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "16098325->03546618",
                "03546618->25136736",
                "16098325->16410233",
                "16410233->04541302",
                "04541302->25136736",
                "04541302->03546618"
            ],
            "nodesOfRoutes": [
                "16098325",
                "03546618",
                "03546618",
                "25136736",
                "16098325",
                "16410233",
                "16410233",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "16098325->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "16098325->16410233",
                        "16410233->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "16098325->16410233",
                        "16410233->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        3.4613141104772,
                        5.2264673536719
                    ],
                    [
                        50,
                        0.010698110110795,
                        74.801942894383
                    ],
                    [
                        50,
                        0.010698110110795,
                        20.281230067741,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.1809044519921298,
                    0.004001197107927545,
                    0.00005669954862417863
                ],
                "total-investment-ratio": 0.1849623486486815,
                "stock-down": [
                    "97049373",
                    "16410233",
                    "03546618",
                    "97362422",
                    "28793447",
                    "54258218"
                ],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "80696570",
            "uniID": "80696570",
            "label": "華準投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "華準投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻準精密工業股份有限公司",
                                    "ROL_for_search": "鴻準精密工業股份有限公司",
                                    "ROL_uniID": "23707801",
                                    "name": "藍瑗文",
                                    "sno": "0001",
                                    "stocks": 125478000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻準精密工業股份有限公司",
                                    "ROL_for_search": "鴻準精密工業股份有限公司",
                                    "ROL_uniID": "23707801",
                                    "name": "黃珏樺",
                                    "sno": "0002",
                                    "stocks": 125478000,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "藍瑗文",
                            "uniID": "80696570",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "23707801"
                ]
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_9_龔國權",
            "uniID": "鴻海精密工業股份有限公司_9_龔國權",
            "label": "龔國權",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "龔國權",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_9_龔國權",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_9_龔國權->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_9_龔國權",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_9_龔國權->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_9_龔國權->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_9_龔國權->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        74.801942894383
                    ],
                    [
                        0,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0,
                    0,
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "廣宇科技股份有限公司_7_鄭文榮",
            "uniID": "廣宇科技股份有限公司_7_鄭文榮",
            "label": "鄭文榮",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鄭文榮",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_7_鄭文榮",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_7_鄭文榮->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_7_鄭文榮",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_7_鄭文榮->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_7_王國城",
            "uniID": "鴻海精密工業股份有限公司_7_王國城",
            "label": "王國城",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "王國城",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻海精密工業股份有限公司_7_王國城",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻海精密工業股份有限公司_7_王國城->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "鴻海精密工業股份有限公司_7_王國城",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻海精密工業股份有限公司_7_王國城->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_7_王國城->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "鴻海精密工業股份有限公司_7_王國城->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        74.801942894383
                    ],
                    [
                        0,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0,
                    0,
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": []
            }
        },
        {
            "id": "24769001",
            "uniID": "24769001",
            "label": "富連網股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "富連網股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "洪金生",
                                    "sno": "0001",
                                    "stocks": 5500000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "何國樑",
                                    "sno": "0002",
                                    "stocks": 5500000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "趙浩勇",
                                    "sno": "0003",
                                    "stocks": 5500000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "許鴻英",
                                    "sno": "0004",
                                    "stocks": 18000000,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "洪金生",
                            "uniID": "24769001",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "16088229",
                    "16636534"
                ]
            }
        },
        {
            "id": "廣宇科技股份有限公司_2_黃鳳安",
            "uniID": "廣宇科技股份有限公司_2_黃鳳安",
            "label": "黃鳳安",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "黃鳳安",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_2_黃鳳安",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_2_黃鳳安->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_2_黃鳳安",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_2_黃鳳安->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.0067522428954164,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.0003529037705695684
                ],
                "total-investment-ratio": 0.0003529037705695684,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        },
        {
            "id": "04541302",
            "uniID": "04541302",
            "label": "鴻海精密工業股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻海精密工業股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2019-07-04",
                                    "name": "劉揚偉",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "劉揚偉",
                                    "sno": "0001",
                                    "stocks": 656219,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻景國際投資股份有限公司",
                                    "ROL_for_search": "鴻景國際投資股份有限公司",
                                    "ROL_uniID": "16410233",
                                    "name": "李傑",
                                    "sno": "0002",
                                    "stocks": 1483078,
                                    "title": "副董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "郭台銘",
                                    "sno": "0003",
                                    "stocks": 1341768518,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "呂芳銘",
                                    "sno": "0004",
                                    "stocks": 6177580,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻景國際投資股份有限公司",
                                    "ROL_for_search": "鴻景國際投資股份有限公司",
                                    "ROL_uniID": "16410233",
                                    "name": "盧松青",
                                    "sno": "0005",
                                    "stocks": 1483078,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "富鉅科技股份有限公司",
                                    "ROL_for_search": "富鉅科技股份有限公司",
                                    "ROL_uniID": "13108601",
                                    "name": "劉憶如",
                                    "sno": "0006",
                                    "stocks": 10560,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "王國城",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "郭大維",
                                    "sno": "0008",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "龔國權",
                                    "sno": "0009",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                }
                            ],
                            "representative_name": "劉揚偉",
                            "uniID": "04541302",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "04541302->25136736"
                    ],
                    [
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        74.801942894383
                    ],
                    [
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    74.801942894383,
                    1.0599918684135727,
                    0.1809044519921298
                ],
                "total-investment-ratio": 76.04283921478871,
                "stock-down": [
                    "16088229",
                    "70771579",
                    "36049822",
                    "90505585",
                    "97164611",
                    "16636534",
                    "83167722",
                    "16597005",
                    "54269279",
                    "03546618",
                    "43802178",
                    "16098325",
                    "25136736"
                ],
                "stock-up": [
                    "鴻海精密工業股份有限公司_1_劉揚偉",
                    "16410233",
                    "鴻海精密工業股份有限公司_3_郭台銘",
                    "鴻海精密工業股份有限公司_4_呂芳銘",
                    "13108601",
                    "鴻海精密工業股份有限公司_7_王國城",
                    "鴻海精密工業股份有限公司_8_郭大維",
                    "鴻海精密工業股份有限公司_9_龔國權"
                ]
            }
        },
        {
            "id": "16088229",
            "uniID": "16088229",
            "label": "鴻棋國際投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻棋國際投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 300280000,
                                    "title": "董事長"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "16088229",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "16088229->16410233",
                "16410233->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "16088229",
                "16410233",
                "16410233",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "16088229->16410233",
                        "16410233->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "16088229->16410233",
                        "16410233->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "16088229->16410233",
                        "16410233->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        50,
                        0.010698110110795,
                        74.801942894383
                    ],
                    [
                        50,
                        0.010698110110795,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        50,
                        0.010698110110795,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.004001197107927545,
                    0.00005669954862417863,
                    0.000009676678734724163
                ],
                "total-investment-ratio": 0.004067573335286448,
                "stock-down": [
                    "24769001",
                    "16410233",
                    "28793447",
                    "80445574"
                ],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "83167722",
            "uniID": "83167722",
            "label": "鴻華先進科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻華先進科技股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2020-09-24",
                                    "name": "李秉彥",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "劉揚偉",
                                    "sno": "0001",
                                    "stocks": 794400000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "華創車電技術中心股份有限公司",
                                    "ROL_for_search": "華創車電技術中心股份有限公司",
                                    "ROL_uniID": "27932239",
                                    "name": "左自生",
                                    "sno": "0002",
                                    "stocks": 763200000,
                                    "title": "副董事長"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃秋蓮",
                                    "sno": "0003",
                                    "stocks": 794400000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "葉光釗",
                                    "sno": "0004",
                                    "stocks": 794400000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "李光曜",
                                    "sno": "0005",
                                    "stocks": 794400000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "華創車電技術中心股份有限公司",
                                    "ROL_for_search": "華創車電技術中心股份有限公司",
                                    "ROL_uniID": "27932239",
                                    "name": "姚振祥",
                                    "sno": "0006",
                                    "stocks": 763200000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "華創車電技術中心股份有限公司",
                                    "ROL_for_search": "華創車電技術中心股份有限公司",
                                    "ROL_uniID": "27932239",
                                    "name": "蔡文榮",
                                    "sno": "0007",
                                    "stocks": 763200000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃碧君",
                                    "sno": "0008",
                                    "stocks": 0,
                                    "title": "監察人"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "陳元龍",
                                    "sno": "0009",
                                    "stocks": 0,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "劉揚偉",
                            "uniID": "83167722",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "16597005",
            "uniID": "16597005",
            "label": "利億國際投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "利億國際投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 96900000,
                                    "title": "董事長"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "16597005",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [
                    "80445574"
                ],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "富鉅科技股份有限公司_2_王月霞",
            "uniID": "富鉅科技股份有限公司_2_王月霞",
            "label": "王月霞",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "王月霞",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "富鉅科技股份有限公司_2_王月霞",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "富鉅科技股份有限公司_2_王月霞->13108601",
                "13108601->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "富鉅科技股份有限公司_2_王月霞",
                "13108601",
                "13108601",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "富鉅科技股份有限公司_2_王月霞->13108601",
                        "13108601->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "富鉅科技股份有限公司_2_王月霞->13108601",
                        "13108601->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "富鉅科技股份有限公司_2_王月霞->13108601",
                        "13108601->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        0.000076174039915632,
                        74.801942894383
                    ],
                    [
                        0,
                        0.000076174039915632,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0,
                        0.000076174039915632,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0,
                    0,
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "13108601"
                ],
                "stock-up": []
            }
        },
        {
            "id": "36049822",
            "uniID": "36049822",
            "label": "陽程科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "陽程科技股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2021-01-28",
                                    "name": "諶家成",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃秋逢",
                                    "sno": "0001",
                                    "stocks": 4540977,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "曾榮鑑",
                                    "sno": "0002",
                                    "stocks": 0,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃士軒",
                                    "sno": "0003",
                                    "stocks": 530875,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "殷偉雄",
                                    "sno": "0004",
                                    "stocks": 5180000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "蔡忠杓",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "翁軟綺",
                                    "sno": "0006",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "陳忠仁",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                }
                            ],
                            "representative_name": "黃秋逢",
                            "uniID": "36049822",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "90505585",
            "uniID": "90505585",
            "label": "鴻揚半導體股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻揚半導體股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2021-09-15",
                                    "name": "陳偉銘",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 29000000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "陳偉銘",
                                    "sno": "0002",
                                    "stocks": 29000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "何家驊",
                                    "sno": "0003",
                                    "stocks": 29000000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃碧君",
                                    "sno": "0004",
                                    "stocks": 29000000,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "90505585",
                            "company_operate": "非營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "28793447",
            "uniID": "28793447",
            "label": "賜福科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "賜福科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "林冬盛",
                                    "sno": "0001",
                                    "stocks": 6713106,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "陳歡聰",
                                    "sno": "0002",
                                    "stocks": 6713106,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "蔡子琦",
                                    "sno": "0003",
                                    "stocks": 14973839,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "張漢傑",
                                    "sno": "0004",
                                    "stocks": 14973839,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "黃明瑞",
                                    "sno": "0005",
                                    "stocks": 14973839,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻元國際投資股份有限公司",
                                    "ROL_for_search": "鴻元國際投資股份有限公司",
                                    "ROL_uniID": "16098325",
                                    "name": "林益仲",
                                    "sno": "0006",
                                    "stocks": 11064632,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "林冬盛",
                            "uniID": "28793447",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "16636534",
                    "16088229",
                    "16098325"
                ]
            }
        },
        {
            "id": "廣宇科技股份有限公司_6_黃錦經",
            "uniID": "廣宇科技股份有限公司_6_黃錦經",
            "label": "黃錦經",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "黃錦經",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_6_黃錦經",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_6_黃錦經->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_6_黃錦經",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_6_黃錦經->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        },
        {
            "id": "54258218",
            "uniID": "54258218",
            "label": "中揚光電股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "中揚光電股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "DANIEL & JESSICA HAPPY LIFE LIMITED",
                                    "ROL_for_search": "DANIEL & JESSICA HAPPY LIFE LIMITED",
                                    "name": "鄭成田",
                                    "sno": "0001",
                                    "stocks": 5876005,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "DANIEL & JESSICA HAPPY LIFE LIMITED",
                                    "ROL_for_search": "DANIEL & JESSICA HAPPY LIFE LIMITED",
                                    "name": "許智程",
                                    "sno": "0002",
                                    "stocks": 5876005,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "DEVE & JOAN HAPPY LIFE LIMITED",
                                    "ROL_for_search": "DEVE & JOAN HAPPY LIFE LIMITED",
                                    "name": "李榮洲",
                                    "sno": "0003",
                                    "stocks": 8924854,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻元國際投資股份有限公司",
                                    "ROL_for_search": "鴻元國際投資股份有限公司",
                                    "ROL_uniID": "16098325",
                                    "name": "邵春生",
                                    "sno": "0004",
                                    "stocks": 4468403,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "姜振富",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "胡春雄",
                                    "sno": "0006",
                                    "stocks": 13089,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "阮中祺",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "鴻揚創業投資股份有限公司",
                                    "ROL_uniID": "97164611",
                                    "contribution": 9000000,
                                    "edge_type": "大股東",
                                    "is_big": true,
                                    "name": "鴻揚創業投資股份有限公司",
                                    "stocks": 9000000
                                }
                            ],
                            "representative_name": "鄭成田",
                            "uniID": "54258218",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "16098325",
                    "97164611"
                ]
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_5_吳敬恒",
            "uniID": "鴻準精密工業股份有限公司_5_吳敬恒",
            "label": "吳敬恒",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "吳敬恒",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "鴻準精密工業股份有限公司_5_吳敬恒",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "鴻準精密工業股份有限公司_5_吳敬恒->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "鴻準精密工業股份有限公司_5_吳敬恒",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "鴻準精密工業股份有限公司_5_吳敬恒->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "23707801"
                ],
                "stock-up": []
            }
        },
        {
            "id": "永齡資本控股股份有限公司_2_黃凱麟",
            "uniID": "永齡資本控股股份有限公司_2_黃凱麟",
            "label": "黃凱麟",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "黃凱麟",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "永齡資本控股股份有限公司_2_黃凱麟",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "永齡資本控股股份有限公司_2_黃凱麟->90779228",
                "90779228->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "永齡資本控股股份有限公司_2_黃凱麟",
                "90779228",
                "90779228",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "永齡資本控股股份有限公司_2_黃凱麟->90779228",
                        "90779228->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        0.53319752250895,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "90779228"
                ],
                "stock-up": []
            }
        },
        {
            "id": "70771579",
            "uniID": "70771579",
            "label": "亞太電信股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "亞太電信股份有限公司",
                            "managers": [
                                {
                                    "arrival_date": "2021-11-08",
                                    "name": "陳鵬",
                                    "sno": "0001"
                                }
                            ],
                            "shareholders": [
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "陳鵬",
                                    "sno": "0001",
                                    "stocks": 708730,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "交通部臺灣鐵路管理局",
                                    "ROL_for_search": "交通部臺灣鐵路管理局",
                                    "name": "朱來順",
                                    "sno": "0002",
                                    "stocks": 261829777,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "裕晟投資股份有限公司",
                                    "ROL_for_search": "裕晟投資股份有限公司",
                                    "ROL_uniID": "25121115",
                                    "name": "曾忠正",
                                    "sno": "0003",
                                    "stocks": 8215177,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "華榮電線電纜股份有限公司",
                                    "ROL_for_search": "華榮電線電纜股份有限公司",
                                    "ROL_uniID": "79460007",
                                    "name": "林明祥",
                                    "sno": "0004",
                                    "stocks": 89087877,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "陳立君",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "交通部臺灣鐵路管理局",
                                    "ROL_for_search": "交通部臺灣鐵路管理局",
                                    "name": "曹棟鈞",
                                    "sno": "0006",
                                    "stocks": 261829777,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "趙元瀚",
                                    "sno": "0007",
                                    "stocks": 708730,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "龔文霖",
                                    "sno": "0008",
                                    "stocks": 708730,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "李和音",
                                    "sno": "0009",
                                    "stocks": 708730,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "缺額",
                                    "sno": "0010",
                                    "stocks": -1,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "楊熙年",
                                    "sno": "0011",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "contribution": 1253026814,
                                    "edge_type": "大股東",
                                    "is_big": true,
                                    "name": "鴻海精密工業股份有限公司",
                                    "stocks": 1253026814
                                }
                            ],
                            "representative_name": "陳鵬",
                            "uniID": "70771579",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "16636534",
                    "04541302"
                ]
            }
        },
        {
            "id": "16410233",
            "uniID": "16410233",
            "label": "鴻景國際投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "鴻景國際投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻元國際投資股份有限公司",
                                    "ROL_for_search": "鴻元國際投資股份有限公司",
                                    "ROL_uniID": "16098325",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 1667000,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "張傳旺",
                                    "sno": "0002",
                                    "stocks": 1667000,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "16410233",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "16410233->04541302",
                "04541302->25136736",
                "04541302->03546618",
                "03546618->25136736",
                "04541302->16098325",
                "16098325->03546618"
            ],
            "nodesOfRoutes": [
                "16410233",
                "04541302",
                "04541302",
                "25136736",
                "04541302",
                "03546618",
                "03546618",
                "25136736",
                "04541302",
                "16098325",
                "16098325",
                "03546618"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "16410233->04541302",
                        "04541302->25136736"
                    ],
                    [
                        "16410233->04541302",
                        "04541302->03546618",
                        "03546618->25136736"
                    ],
                    [
                        "16410233->04541302",
                        "04541302->16098325",
                        "16098325->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 3 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.010698110110795,
                        74.801942894383
                    ],
                    [
                        0.010698110110795,
                        20.281230067741,
                        5.2264673536719
                    ],
                    [
                        0.010698110110795,
                        100,
                        3.4613141104772,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.00800239421585509,
                    0.00011339909724835727,
                    0.000019353357469448325
                ],
                "total-investment-ratio": 0.008135146670572895,
                "stock-down": [
                    "04541302"
                ],
                "stock-up": [
                    "16098325",
                    "16088229"
                ]
            }
        },
        {
            "id": "16636534",
            "uniID": "16636534",
            "label": "寶鑫國際投資股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "寶鑫國際投資股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻海精密工業股份有限公司",
                                    "ROL_for_search": "鴻海精密工業股份有限公司",
                                    "ROL_uniID": "04541302",
                                    "name": "黃德才",
                                    "sno": "0001",
                                    "stocks": 1128730000,
                                    "title": "董事長"
                                }
                            ],
                            "representative_name": "黃德才",
                            "uniID": "16636534",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [
                    "70771579",
                    "24769001",
                    "97164611",
                    "28793447",
                    "80445574"
                ],
                "stock-up": [
                    "04541302"
                ]
            }
        },
        {
            "id": "廣宇科技股份有限公司_5_魏敏昌",
            "uniID": "廣宇科技股份有限公司_5_魏敏昌",
            "label": "魏敏昌",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "魏敏昌",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_5_魏敏昌",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_5_魏敏昌->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_5_魏敏昌",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_5_魏敏昌->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0
                ],
                "total-investment-ratio": 0,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        },
        {
            "id": "80445574",
            "uniID": "80445574",
            "label": "揚信科技股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "揚信科技股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "鴻揚創業投資股份有限公司",
                                    "ROL_for_search": "鴻揚創業投資股份有限公司",
                                    "ROL_uniID": "97164611",
                                    "name": "王呈晃",
                                    "sno": "0001",
                                    "stocks": 11523759,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "寶鑫國際投資股份有限公司",
                                    "ROL_for_search": "寶鑫國際投資股份有限公司",
                                    "ROL_uniID": "16636534",
                                    "name": "李順德",
                                    "sno": "0002",
                                    "stocks": 7967798,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "鴻棋國際投資股份有限公司",
                                    "ROL_for_search": "鴻棋國際投資股份有限公司",
                                    "ROL_uniID": "16088229",
                                    "name": "黃愛琦",
                                    "sno": "0003",
                                    "stocks": 7967798,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "利億國際投資股份有限公司",
                                    "ROL_for_search": "利億國際投資股份有限公司",
                                    "ROL_uniID": "16597005",
                                    "name": "龔文霖",
                                    "sno": "0004",
                                    "stocks": 6154156,
                                    "title": "監察人"
                                }
                            ],
                            "representative_name": "王呈晃",
                            "uniID": "80445574",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "97164611",
                    "16636534",
                    "16088229",
                    "16597005"
                ]
            }
        },
        {
            "id": "97049373",
            "uniID": "97049373",
            "label": "正達國際光電股份有限公司",
            "style": "rectCompany",
            "hasTop": true,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "正達國際光電股份有限公司",
                            "managers": [],
                            "shareholders": [
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "鍾志明",
                                    "sno": "0001",
                                    "stocks": 7140062,
                                    "title": "董事長"
                                },
                                {
                                    "ROL": "鴻元國際投資股份有限公司",
                                    "ROL_for_search": "鴻元國際投資股份有限公司",
                                    "ROL_uniID": "16098325",
                                    "name": "林世昌",
                                    "sno": "0002",
                                    "stocks": 15728165,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "蕭仁亮",
                                    "sno": "0003",
                                    "stocks": 1011784,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "王國鴻",
                                    "sno": "0004",
                                    "stocks": 240000,
                                    "title": "董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "黃國師",
                                    "sno": "0005",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "吳俊峯",
                                    "sno": "0006",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                },
                                {
                                    "ROL": "",
                                    "ROL_for_search": "",
                                    "name": "楊銘泗",
                                    "sno": "0007",
                                    "stocks": 0,
                                    "title": "獨立董事"
                                }
                            ],
                            "representative_name": "鍾志明",
                            "uniID": "97049373",
                            "company_operate": "營業中",
                            "company_state": "核准設立"
                        }
                    }
                }
            },
            "edgesOfRoutes": [],
            "nodesOfRoutes": [],
            "routeData": {
                "routes-in-id": [],
                "routes-of-stock": "",
                "routes-of-stock-detail": [],
                "routes-of-stock-detail-single-value": [],
                "stock-down": [],
                "stock-up": [
                    "16098325"
                ]
            }
        },
        {
            "id": "永齡資本控股股份有限公司_1_莊宏仁",
            "uniID": "永齡資本控股股份有限公司_1_莊宏仁",
            "label": "莊宏仁",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "莊宏仁",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "永齡資本控股股份有限公司_1_莊宏仁",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "永齡資本控股股份有限公司_1_莊宏仁->90779228",
                "90779228->23707801",
                "23707801->25136736"
            ],
            "nodesOfRoutes": [
                "永齡資本控股股份有限公司_1_莊宏仁",
                "90779228",
                "90779228",
                "23707801",
                "23707801",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "永齡資本控股股份有限公司_1_莊宏仁->90779228",
                        "90779228->23707801",
                        "23707801->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.00019960079840319,
                        0.53319752250895,
                        19.971589751945
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    2.125509416427548e-7
                ],
                "total-investment-ratio": 2.125509416427548e-7,
                "stock-down": [
                    "90779228"
                ],
                "stock-up": []
            }
        },
        {
            "id": "廣宇科技股份有限公司_3_蔡明峰",
            "uniID": "廣宇科技股份有限公司_3_蔡明峰",
            "label": "蔡明峰",
            "style": "rectPerson",
            "hasTop": false,
            "isTopExpanded": false,
            "tags": [],
            "data": {
                "detail": {
                    "payload": {
                        "basic": {
                            "company_name": "蔡明峰",
                            "managers": [],
                            "shareholders": [],
                            "representative_name": "",
                            "uniID": "廣宇科技股份有限公司_3_蔡明峰",
                            "company_operate": "營業中",
                            "company_state": ""
                        }
                    }
                }
            },
            "edgesOfRoutes": [
                "廣宇科技股份有限公司_3_蔡明峰->03546618",
                "03546618->25136736"
            ],
            "nodesOfRoutes": [
                "廣宇科技股份有限公司_3_蔡明峰",
                "03546618",
                "03546618",
                "25136736"
            ],
            "routeData": {
                "routes-in-id": [
                    [
                        "廣宇科技股份有限公司_3_蔡明峰->03546618",
                        "03546618->25136736"
                    ]
                ],
                "routes-of-stock": "到 ROOT 共有 1 條路徑",
                "routes-of-stock-detail": [
                    [
                        0.054833421183872,
                        5.2264673536719
                    ]
                ],
                "routes-of-stock-detail-single-value": [
                    0.0028658508570764823
                ],
                "total-investment-ratio": 0.0028658508570764823,
                "stock-down": [
                    "03546618"
                ],
                "stock-up": []
            }
        }
    ],
    "edges": [
        {
            "id": "04541302->25136736",
            "start": "04541302",
            "end": "25136736",
            "_start": "04541302",
            "_end": "25136736",
            "label": "74.8%",
            "direction": "top",
            "percentage": 74.801942894383,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_1_劉揚偉->04541302",
            "start": "鴻海精密工業股份有限公司_1_劉揚偉",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_1_劉揚偉",
            "_end": "04541302",
            "label": "< 0.01%",
            "direction": "top",
            "percentage": 0.0047336034374428,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16410233->04541302",
            "start": "16410233",
            "end": "04541302",
            "_start": "16410233",
            "_end": "04541302",
            "label": "0.01%",
            "direction": "top",
            "percentage": 0.010698110110795,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16098325->16410233",
            "start": "16098325",
            "end": "16410233",
            "_start": "16098325",
            "_end": "16410233",
            "label": "50%",
            "direction": "top",
            "percentage": 50,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->16098325",
            "start": "04541302",
            "end": "16098325",
            "_start": "04541302",
            "_end": "16098325",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_3_郭台銘->04541302",
            "start": "鴻海精密工業股份有限公司_3_郭台銘",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_3_郭台銘",
            "_end": "04541302",
            "label": "9.68%",
            "direction": "top",
            "percentage": 9.6787811219385,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_4_呂芳銘->04541302",
            "start": "鴻海精密工業股份有限公司_4_呂芳銘",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_4_呂芳銘",
            "_end": "04541302",
            "label": "0.04%",
            "direction": "top",
            "percentage": 0.04456166908163,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "13108601->04541302",
            "start": "13108601",
            "end": "04541302",
            "_start": "13108601",
            "_end": "04541302",
            "label": "< 0.01%",
            "direction": "top",
            "percentage": 0.000076174039915632,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "富鉅科技股份有限公司_1_莊宏仁->13108601",
            "start": "富鉅科技股份有限公司_1_莊宏仁",
            "end": "13108601",
            "_start": "富鉅科技股份有限公司_1_莊宏仁",
            "_end": "13108601",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "富鉅科技股份有限公司_2_王月霞->13108601",
            "start": "富鉅科技股份有限公司_2_王月霞",
            "end": "13108601",
            "_start": "富鉅科技股份有限公司_2_王月霞",
            "_end": "13108601",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_7_王國城->04541302",
            "start": "鴻海精密工業股份有限公司_7_王國城",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_7_王國城",
            "_end": "04541302",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_8_郭大維->04541302",
            "start": "鴻海精密工業股份有限公司_8_郭大維",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_8_郭大維",
            "_end": "04541302",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻海精密工業股份有限公司_9_龔國權->04541302",
            "start": "鴻海精密工業股份有限公司_9_龔國權",
            "end": "04541302",
            "_start": "鴻海精密工業股份有限公司_9_龔國權",
            "_end": "04541302",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->43802178",
            "start": "04541302",
            "end": "43802178",
            "_start": "04541302",
            "_end": "43802178",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->97164611",
            "start": "04541302",
            "end": "97164611",
            "_start": "04541302",
            "_end": "97164611",
            "label": "97.95%",
            "direction": "top",
            "percentage": 97.954182576073,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16636534->97164611",
            "start": "16636534",
            "end": "97164611",
            "_start": "16636534",
            "_end": "97164611",
            "label": "2.05%",
            "direction": "top",
            "percentage": 2.0458174239266,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->16636534",
            "start": "04541302",
            "end": "16636534",
            "_start": "04541302",
            "_end": "16636534",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->54269279",
            "start": "04541302",
            "end": "54269279",
            "_start": "04541302",
            "_end": "54269279",
            "label": "39.78%",
            "direction": "top",
            "percentage": 39.780122171077,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "97164611->54269279",
            "start": "97164611",
            "end": "54269279",
            "_start": "97164611",
            "_end": "54269279",
            "label": "60.22%",
            "direction": "top",
            "percentage": 60.219877828923,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "97164611->54258218",
            "start": "97164611",
            "end": "54258218",
            "_start": "97164611",
            "_end": "54258218",
            "label": "11.5%",
            "direction": "top",
            "percentage": 11.495430898308,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16098325->54258218",
            "start": "16098325",
            "end": "54258218",
            "_start": "16098325",
            "_end": "54258218",
            "label": "5.71%",
            "direction": "top",
            "percentage": 5.7073575458103,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16098325->03546618",
            "start": "16098325",
            "end": "03546618",
            "_start": "16098325",
            "_end": "03546618",
            "label": "3.46%",
            "direction": "top",
            "percentage": 3.4613141104772,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_1_盧松發->03546618",
            "start": "廣宇科技股份有限公司_1_盧松發",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_1_盧松發",
            "_end": "03546618",
            "label": "0.39%",
            "direction": "top",
            "percentage": 0.39271353353703,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_2_黃鳳安->03546618",
            "start": "廣宇科技股份有限公司_2_黃鳳安",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_2_黃鳳安",
            "_end": "03546618",
            "label": "< 0.01%",
            "direction": "top",
            "percentage": 0.0067522428954164,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_3_蔡明峰->03546618",
            "start": "廣宇科技股份有限公司_3_蔡明峰",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_3_蔡明峰",
            "_end": "03546618",
            "label": "0.05%",
            "direction": "top",
            "percentage": 0.054833421183872,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_5_魏敏昌->03546618",
            "start": "廣宇科技股份有限公司_5_魏敏昌",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_5_魏敏昌",
            "_end": "03546618",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_6_黃錦經->03546618",
            "start": "廣宇科技股份有限公司_6_黃錦經",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_6_黃錦經",
            "_end": "03546618",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "廣宇科技股份有限公司_7_鄭文榮->03546618",
            "start": "廣宇科技股份有限公司_7_鄭文榮",
            "end": "03546618",
            "_start": "廣宇科技股份有限公司_7_鄭文榮",
            "_end": "03546618",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->03546618",
            "start": "04541302",
            "end": "03546618",
            "_start": "04541302",
            "_end": "03546618",
            "label": "20.28%",
            "direction": "top",
            "percentage": 20.281230067741,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->16088229",
            "start": "04541302",
            "end": "16088229",
            "_start": "04541302",
            "_end": "16088229",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16088229->24769001",
            "start": "16088229",
            "end": "24769001",
            "_start": "16088229",
            "_end": "24769001",
            "label": "20.37%",
            "direction": "top",
            "percentage": 20.37037037037,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16636534->24769001",
            "start": "16636534",
            "end": "24769001",
            "_start": "16636534",
            "_end": "24769001",
            "label": "66.67%",
            "direction": "top",
            "percentage": 66.666666666667,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16636534->28793447",
            "start": "16636534",
            "end": "28793447",
            "_start": "16636534",
            "_end": "28793447",
            "label": "13.45%",
            "direction": "top",
            "percentage": 13.445488527993,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16088229->28793447",
            "start": "16088229",
            "end": "28793447",
            "_start": "16088229",
            "_end": "28793447",
            "label": "29.99%",
            "direction": "top",
            "percentage": 29.990675030978,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16088229->16410233",
            "start": "16088229",
            "end": "16410233",
            "_start": "16088229",
            "_end": "16410233",
            "label": "50%",
            "direction": "top",
            "percentage": 50,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16088229->80445574",
            "start": "16088229",
            "end": "80445574",
            "_start": "16088229",
            "_end": "80445574",
            "label": "7.82%",
            "direction": "top",
            "percentage": 7.82,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "97164611->80445574",
            "start": "97164611",
            "end": "80445574",
            "_start": "97164611",
            "_end": "80445574",
            "label": "11.31%",
            "direction": "top",
            "percentage": 11.31,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16636534->80445574",
            "start": "16636534",
            "end": "80445574",
            "_start": "16636534",
            "_end": "80445574",
            "label": "7.82%",
            "direction": "top",
            "percentage": 7.82,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16636534->70771579",
            "start": "16636534",
            "end": "70771579",
            "_start": "16636534",
            "_end": "70771579",
            "label": "0.02%",
            "direction": "top",
            "percentage": 0.016416441007043,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->70771579",
            "start": "04541302",
            "end": "70771579",
            "_start": "04541302",
            "_end": "70771579",
            "label": "29.02%",
            "direction": "top",
            "percentage": 29.0240864254,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->83167722",
            "start": "04541302",
            "end": "83167722",
            "_start": "04541302",
            "_end": "83167722",
            "label": "51%",
            "direction": "top",
            "percentage": 51.001540832049,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->16597005",
            "start": "04541302",
            "end": "16597005",
            "_start": "04541302",
            "_end": "16597005",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16597005->80445574",
            "start": "16597005",
            "end": "80445574",
            "_start": "16597005",
            "_end": "80445574",
            "label": "6.04%",
            "direction": "top",
            "percentage": 6.04,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->36049822",
            "start": "04541302",
            "end": "36049822",
            "_start": "04541302",
            "_end": "36049822",
            "label": "8.16%",
            "direction": "top",
            "percentage": 8.1609492967467,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "04541302->90505585",
            "start": "04541302",
            "end": "90505585",
            "_start": "04541302",
            "_end": "90505585",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16098325->28793447",
            "start": "16098325",
            "end": "28793447",
            "_start": "16098325",
            "_end": "28793447",
            "label": "22.16%",
            "direction": "top",
            "percentage": 22.161035833854,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "16098325->97049373",
            "start": "16098325",
            "end": "97049373",
            "_start": "16098325",
            "_end": "97049373",
            "label": "7.62%",
            "direction": "top",
            "percentage": 7.620471126615,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "03546618->70771374",
            "start": "03546618",
            "end": "70771374",
            "_start": "03546618",
            "_end": "70771374",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "03546618->25136736",
            "start": "03546618",
            "end": "25136736",
            "_start": "03546618",
            "_end": "25136736",
            "label": "5.23%",
            "direction": "top",
            "percentage": 5.2264673536719,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "23707801->25136736",
            "start": "23707801",
            "end": "25136736",
            "_start": "23707801",
            "_end": "25136736",
            "label": "19.97%",
            "direction": "top",
            "percentage": 19.971589751945,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "90779228->23707801",
            "start": "90779228",
            "end": "23707801",
            "_start": "90779228",
            "_end": "23707801",
            "label": "0.53%",
            "direction": "top",
            "percentage": 0.53319752250895,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "永齡資本控股股份有限公司_1_莊宏仁->90779228",
            "start": "永齡資本控股股份有限公司_1_莊宏仁",
            "end": "90779228",
            "_start": "永齡資本控股股份有限公司_1_莊宏仁",
            "_end": "90779228",
            "label": "< 0.01%",
            "direction": "top",
            "percentage": 0.00019960079840319,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "永齡資本控股股份有限公司_2_黃凱麟->90779228",
            "start": "永齡資本控股股份有限公司_2_黃凱麟",
            "end": "90779228",
            "_start": "永齡資本控股股份有限公司_2_黃凱麟",
            "_end": "90779228",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_3_陳耀清->23707801",
            "start": "鴻準精密工業股份有限公司_3_陳耀清",
            "end": "23707801",
            "_start": "鴻準精密工業股份有限公司_3_陳耀清",
            "_end": "23707801",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_4_蘇維國->23707801",
            "start": "鴻準精密工業股份有限公司_4_蘇維國",
            "end": "23707801",
            "_start": "鴻準精密工業股份有限公司_4_蘇維國",
            "_end": "23707801",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "鴻準精密工業股份有限公司_5_吳敬恒->23707801",
            "start": "鴻準精密工業股份有限公司_5_吳敬恒",
            "end": "23707801",
            "_start": "鴻準精密工業股份有限公司_5_吳敬恒",
            "_end": "23707801",
            "label": "0%",
            "direction": "top",
            "percentage": 0,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "23707801->16594892",
            "start": "23707801",
            "end": "16594892",
            "_start": "23707801",
            "_end": "16594892",
            "label": "3.05%",
            "direction": "top",
            "percentage": 3.0539204317076,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        },
        {
            "id": "23707801->80696570",
            "start": "23707801",
            "end": "80696570",
            "_start": "23707801",
            "_end": "80696570",
            "label": "100%",
            "direction": "top",
            "percentage": 100,
            "style": {
                "path": "pathTop",
                "arrow": "arrowTop"
            }
        }
    ],
    "expandAll": false,
    "direction": "RL"
};
function createTooltipText(shareholders) {
    return shareholders
        .map(d => {
        let bracketText = '';
        if (d.ROL) {
            bracketText += d.ROL;
        }
        if (d.title) {
            if (bracketText) {
                bracketText += '，';
            }
            bracketText += d.title;
        }
        if (d.stocks) {
            if (bracketText) {
                bracketText += '，';
            }
            bracketText += `持股 ${d.stocks}`;
        }
        return `${d.name}（${bracketText}）`;
    })
        .join('</br>');
}
const createTagTooltipText = (node) => {
    let text = '';
    try {
        if (node.name_PEP_name && node.name_PEP_name.length) {
            node.name_PEP_name.map((pepName) => {
                let positionAndExperience = (pepName.position || []).concat(pepName.experience || []);
                positionAndExperience = positionAndExperience.filter((d) => d !== '無');
                text += '．與列名 PEPS 者同名';
                text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`;
            });
        }
        if (node.name_PEP_family && node.name_PEP_family.length) {
            node.name_PEP_family.map((pepFamily) => {
                let positionAndExperience = (pepFamily.position || []).concat(pepFamily.experience || []);
                positionAndExperience = positionAndExperience.filter((d) => d !== '無');
                if (text) {
                    text += '</br>';
                }
                text += '．與列名 PEPS 者之家屬同名';
                text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`;
            });
        }
    }
    catch (e) {
        console.error(e);
    }
    return text;
};
dataset.nodes = dataset.nodes.map(d => {
    return {
        ...d,
        tooltip: (d) => {
            console.log(d);
            return createTooltipText(d?.data?.detail?.payload?.basic?.shareholders ?? []);
        },
        tagTooltip: (d) => {
            return createTagTooltipText(d.data);
        }
    };
});
export default dataset;
