import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import { DEFAULT_CHART_DATE_COLUMN_PARAMS } from './defaults';
import { AxisDateColumn } from '../axisDateColumn';
import TooltipAside from '../tooltip/TooltipAside';
import { UtilAuxLine } from '../utilAuxLine';
import { UtilAuxDateLabel } from '../utilAuxDateLabel';
import { calcAxisWidth, calcAxisHeight, makeAxisTimeScale, makeAxisLinearScale, makeAxisQuantizeScale } from '../moduleUtils';
import { findStartDate, findEndDate, makeDateList } from '../d3Utils';
import { dateDiff, addDays, measureTextWidth } from '../utils';
import { DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
// import { makeColorScale } from '../moduleUtils'
const defaultItemLabel = '__DEFAULT__';
// 圖形上方和比例尺的間距
// const graphicTopPadding = 0.95
// const zoomSpeed = 25
// 用 k計算 zoom的日期變換
const calcDateDiffByK = (length, kDiff) => {
    return Math.round(length * kDiff);
};
const makeAxisDateColumnParams = (params, padding) => {
    return {
        ...params,
        padding
    };
};
// const findFilteredStartDate = (data: Array<Array<RenderDatum>>, startDate: string | Date | undefined): Date => {
//   let _FilterStartDate = data[0][0].Date
//   if (startDate) {
//     if (typeof startDate === 'string') {
//       _FilterStartDate = parseLocalDate(startDate)
//     } else if (startDate instanceof Date) {
//       _FilterStartDate = startDate
//     }
//   }
//   const startDatum = data![0].find(d => d.Date >= _FilterStartDate)
//   return startDatum ? startDatum!.Date : data![0][0].Date // 如果找不到就不篩選
// }
// const findFilteredEndDate = (data: Array<Array<RenderDatum>>, endDate: string | Date | undefined): Date => {
//   let _FilterEndDate = data[0][data[0].length - 1].Date
//   if (endDate) {
//     if (typeof endDate === 'string') {
//       _FilterEndDate = parseLocalDate(endDate)
//     } else if (endDate instanceof Date) {
//       _FilterEndDate = endDate
//     }
//   }
//   const endDatum = (Object.assign([], data![0]) as RenderDatum[])
//     .reverse()
//     .find(d => d.Date <= _FilterEndDate)
//   return endDatum ? endDatum!.Date : data[0][data[0].length - 1].Date // 如果找不到就不篩選
// }
const makeFilteredCondition = (dataList, itemLabels, filterConfig) => {
    const { StartDate, startIndex } = findStartDate(dataList, filterConfig.startDate);
    const { EndDate, endIndex } = findEndDate(dataList, filterConfig.endDate);
    return {
        StartDate,
        EndDate,
        filterItems: filterConfig.filterItems ? filterConfig.filterItems : itemLabels
    };
};
const makeRenderDataOrigin = (data, itemLabels, xLabels, DateList) => {
    if (!data.length) {
        return [];
    }
    const _data = Array.isArray(data[0]) === true
        ? data
        : [data];
    return _data.map((d, i) => {
        return d.map((_d, _i) => {
            const xLabel = xLabels[_i];
            const itemLabel = _d.itemLabel ?? itemLabels[i]; // 如資料(datum)無itemLabel則依照資料陣列順序加入itemLabel
            return {
                ..._d,
                id: `${itemLabel}_${xLabel}`,
                xIndex: _i,
                xLabel,
                itemIndex: i,
                itemLabel,
                Date: DateList[_i]
            };
        });
    });
};
const makeRenderDataItemFiltered = (renderDataOrigin, itemLabels, filteredCondition) => {
    // items篩選
    let _data = Object.assign([], renderDataOrigin);
    if (filteredCondition.filterItems) {
        _data = _data.filter((_d, _i) => {
            const currentItem = itemLabels[_i];
            return filteredCondition.filterItems.includes(currentItem);
        });
    }
    return _data;
};
const makeFilteredData = (renderDataItemFiltered, filteredCondition) => {
    // items篩選
    let _data = Object.assign([], renderDataItemFiltered);
    return _data.map(d => {
        const itemData = Object.assign([], d);
        return itemData
            .filter(_d => {
            return _d.Date >= filteredCondition.StartDate && _d.Date <= filteredCondition.EndDate;
        });
    });
};
const makeGraphicRenderData = (renderDataItemFiltered, xScale, yScale) => {
    return renderDataItemFiltered.map(d => {
        return d.map(_d => {
            return {
                ..._d,
                x: xScale(_d.Date) || 0,
                y: yScale(_d.value) || 0
            };
        });
    });
};
const makeCallbackData = ({ dataset, activedItemId, FilteredDateList, FilteredXDataMap, FilteredItemDataMap, padding, xRangeScale, event }) => {
    if (!event || !xRangeScale) {
        return {
            dataset,
            groupData: [],
            itemData: [],
            datum: undefined,
            Date: undefined,
            xLabel: '',
            xIndex: -1,
            itemLabel: '',
            itemIndex: -1,
            rangeIndex: -1,
            clientX: -1,
            clientY: -1,
            offsetX: -1,
            offsetY: -1
        };
    }
    // 座標軸x座標
    const offsetX = Number(event.offsetX) - padding.left;
    // 資料索引
    const rangeIndex = Number(xRangeScale(offsetX) ?? 0);
    const groupData = FilteredXDataMap.get(rangeIndex) ?? [];
    let itemData = [];
    let selectedData = undefined;
    if (activedItemId) {
        itemData = FilteredItemDataMap.get(activedItemId) ?? [];
        selectedData = groupData.find(d => d.itemLabel === activedItemId);
    }
    return {
        dataset,
        groupData,
        itemData,
        datum: selectedData,
        Date: FilteredDateList[rangeIndex],
        xLabel: groupData[0].xLabel,
        xIndex: groupData[0].xIndex,
        itemLabel: selectedData ? selectedData.itemLabel : '',
        itemIndex: selectedData ? selectedData.itemIndex : -1,
        rangeIndex,
        clientX: d3.event.clientX,
        clientY: d3.event.clientY,
        offsetX: d3.event.offsetX,
        offsetY: d3.event.offsetY
    };
};
// const getMaxValue = (data: Array<Array<RenderDatum>>) => {
//   let maxValue = 0
//   for (let index in data) {
//     for (let i in data[index]) {
//       if (data[index][i].value > maxValue) {
//         maxValue = data[index][i].value
//       }
//     }
//   }
//   return maxValue
// }
// const makeColorScale = (itemLabels: string[], colors: string[]) => {
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
// const makeAxisTimeScale = (DateList: Date[], axisWidth: number, scalePadding = 0) => {
//   // scaleTime 沒有 .padding()方法，所以手動計算
//   const padding = axisWidth / (DateList.length / scalePadding)
//   return  d3.scaleTime<number, number>()
//   .domain([DateList[0], DateList[DateList.length - 1]])
//   .range([padding, axisWidth - padding])
// }
// const makeAxisLinearScale = (maxValue: number, axisHeight: number) => {
//   return d3.scaleLinear()
//     .domain([maxValue / graphicTopPadding, 0]) // 因為要由下往上所以反過來
//     .range([0, axisHeight])
// }
// const makeAxisQuantizeScale = (FilteredDateList: Date[], axisWidth: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisWidth + rangePadding])
//     .range(FilteredDateList.map((d, i) => i))
// }
export default class ChartDateColumn {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        data: [],
        xDates: [],
        itemLabels: []
    };
    params = DEFAULT_CHART_DATE_COLUMN_PARAMS;
    filterConfig = {
        startDate: undefined,
        endDate: undefined,
        filterItems: undefined
    };
    zoom = {
        xOffset: 0,
        yOffset: 0,
        scaleExtent: {
            min: 0,
            max: Infinity
        }
    };
    transform = {
        x: 0,
        y: 0,
        k: 1
    };
    // 圖形的外層 <g>
    graphicGSelection;
    coverSelection;
    defsSelection = undefined;
    axisWidth = 0;
    axisHeight = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    zoomCallback = function () { };
    utilAuxLine;
    utilAuxDateLabel;
    d3Zoom = undefined;
    // 設置 clipe-path 的圖形最外層 <g>
    graphicGClipPathSelection;
    backgroundSelection = undefined;
    rangeSelection;
    axisSelection;
    // 垂直苗準線選擇器
    auxLineSelection = undefined;
    dateLabelSelection = undefined;
    filteredCondition = {
        StartDate: new Date(),
        EndDate: new Date(),
        filterItems: []
    };
    // private chartSelection: (d3.Selection<SVGGElement, unknown, any, unknown> | undefined) = undefined
    tooltip;
    // 未篩選項目前的資料
    renderDataOrigin = [];
    // 渲染資料（有篩選項目）
    renderDataItemFiltered = [];
    // 圖形渲染資料（含座標）
    graphicRenderData = [];
    DateList = [];
    renderMinValue = 0;
    renderMaxValue = 0;
    StartDate = new Date();
    EndDate = new Date();
    // private graphicTransformDatum: GraphicTransformDatum = {
    //   translateX: 0,
    //   translateY: 0,
    //   scaleX: 0,
    //   scaleY: 0
    // }
    itemLabels = [];
    xLabels = [];
    // private colorScale?: d3.ScaleOrdinal<string, string>
    filteredData = []; // 篩選所有條件後的資料
    FilteredDateList = []; // 篩選過後的日期
    filteredMinValue = 0;
    filteredMaxValue = 0;
    filterStartIndex = 0;
    // private filterEndIndex: number = 0
    FilteredXDataMap = new Map();
    FilteredItemDataMap = new Map();
    axisDateColumn;
    axisParams = this.params.axisDateColumn;
    xScale;
    yScale;
    xRangeScale; // 滑鼠座標轉換為類別索引
    // 紀住托曳資訊
    dragstartX = -1;
    // private activedItemId = ''
    constructor(selection, params) {
        this.selection = selection;
        this.defsSelection = selection.append('defs');
        this.backgroundSelection = this.selection
            .append('g')
            .classed('bpchart__background', true);
        this.axisSelection = this.selection
            .append('g')
            .classed('bpchart__axis', true);
        this.axisDateColumn = new AxisDateColumn(this.axisSelection, {});
        this.rangeSelection = this.selection
            .append('rect')
            .attr('opacity', 0);
        this.setRangeEvent(this.rangeSelection);
        this.graphicGClipPathSelection = this.selection
            .append('g')
            .classed('bpchart__graphicGClipPath', true)
            .attr('clip-path', d => `url(#bpchart__clipPath)`);
        this.graphicGSelection = this.graphicGClipPathSelection
            .append('g')
            .classed('bpchart__graphicG', true);
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        this.utilAuxLine = new UtilAuxLine(this.coverSelection);
        this.utilAuxDateLabel = new UtilAuxDateLabel(this.coverSelection);
        this.setCoverEvent(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            axisDateColumn: {
                ...this.params.axisDateColumn,
                ...params.axisDateColumn
            },
        };
        if (this.params.tooltipAside) {
            this.tooltip = new TooltipAside(this.selection, {
                templateHtml: this.params.tooltipAside.templateHtml,
                type: this.params.tooltipAside.type ?? 'white',
                yLine: this.params.tooltipAside.yLine ?? false,
            });
        }
        // 顏色比例尺
        // this.colorScale = makeColorScale(this.itemLabels, this.params.colors!)
        this.initGraphic(this.params);
        this.axisParams = makeAxisDateColumnParams(this.params.axisDateColumn, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisDateColumn.setParams(this.axisParams);
        this.graphicGClipPathSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.coverSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisParams = makeAxisDateColumnParams(this.params.axisDateColumn, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisDateColumn.setParams(this.axisParams);
        this.axisDateColumn.resize({ width, height });
        this.rangeSelection
            .attr('x', this.params.padding.left)
            .attr('y', this.params.padding.top)
            .attr('width', this.axisWidth)
            .attr('height', this.axisHeight);
        this.initGraphic(this.params);
        // 圖形渲染資料（含座標）
        const graphicXScale = makeAxisTimeScale(this.DateList, this.axisWidth, 0);
        const graphicYScale = makeAxisLinearScale({
            maxValue: this.renderMaxValue,
            minValue: this.renderMinValue,
            axisWidth: this.axisHeight,
            domainMinValue: 0,
            domainMaxValue: undefined,
            domainMinRange: 0,
            domainMaxRange: this.params.domainMaxRange,
            reverse: true
        });
        this.graphicRenderData = makeGraphicRenderData(this.renderDataItemFiltered, graphicXScale, graphicYScale);
        this.setGraphicData({
            renderDataItemFiltered: this.graphicRenderData,
            itemLabels: this.filteredCondition.filterItems,
            xLabels: this.xLabels,
            yScale: graphicYScale
        });
        // 遮罩
        this.renderClipPath([{
                id: `bpchart__clipPath`,
                // x: this.params.padding!.left,
                // y: this.params.padding!.top,
                x: 0,
                y: 0,
                width: this.axisWidth,
                height: this.axisHeight
            }]);
        this.renderBackground(this.backgroundSelection);
    }
    // 設置拖拽及放大縮小
    initZoom(zoom = this.zoom) {
        this.zoom = {
            ...this.zoom,
            ...zoom
        };
        // 滑鼠滾動放大縮小
        this.selection.on('zoom', null);
        this.d3Zoom = d3.zoom()
            .scaleExtent([this.zoom.scaleExtent.min, this.zoom.scaleExtent.max])
            .on('zoom', () => {
            this.transform = {
                x: d3.event.transform.x,
                y: d3.event.transform.y,
                k: d3.event.transform.k
            };
            if (!this.renderDataItemFiltered || !this.renderDataItemFiltered[0] || !this.renderDataItemFiltered[0].length) {
                return;
            }
            if (d3.event.sourceEvent.type === 'mousemove') {
                if (this.params.zoom && this.onZoomMouse) {
                    this.onZoomMouse(d3.event);
                }
            }
            else if (d3.event.sourceEvent.type === 'wheel') {
                if (this.params.zoom && this.onZoomWheel) {
                    this.onZoomWheel(d3.event);
                }
            }
        });
        this.selection.call(this.d3Zoom);
    }
    removeZoom() {
        this.d3Zoom = d3.zoom()
            .on('zoom', null);
    }
    transformZoom(transform) {
        this.transform = transform;
        // 設定 d3.event.transform 並觸發 d3.zoom().on()
        if (this.d3Zoom && this.d3Zoom.transform) {
            this.selection.call(this.d3Zoom.transform, d3.zoomIdentity
                .translate(this.transform.x, this.transform.y)
                .scale(this.transform.k));
        }
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = this.dataset.itemLabels && this.dataset.itemLabels.length
            ? this.dataset.itemLabels
            : [defaultItemLabel];
        const { xLabels, DateList } = makeDateList(this.dataset.xDates, this.params.axisDateColumn.xTickFormat);
        this.xLabels = xLabels;
        this.DateList = DateList;
        if (this.DateList.length) {
            this.StartDate = this.DateList[0];
            this.EndDate = this.DateList[this.DateList.length - 1];
        }
        this.renderDataOrigin = makeRenderDataOrigin(this.dataset.data, this.itemLabels, this.xLabels, this.DateList);
        // 顏色比例尺
        // this.colorScale = makeColorScale(this.itemLabels, this.params.colors!)
        // 先清掉篩選值
        this.filter({
            startDate: undefined,
            endDate: undefined,
            filterItems: undefined
        });
    }
    filter(filterConfig) {
        if (!this.renderDataOrigin.length || !this.renderDataOrigin[0].length) {
            return;
        }
        this.filterConfig = {
            ...this.filterConfig,
            ...filterConfig
        };
        const originFilterItems = String(this.filteredCondition.filterItems);
        this.filteredCondition = makeFilteredCondition(this.DateList, this.itemLabels, this.filterConfig);
        // 項目有改變才計算渲染資料
        if (String(this.filteredCondition.filterItems) != originFilterItems) {
            // 渲染資料（有篩選項目）
            this.renderDataItemFiltered = makeRenderDataItemFiltered(this.renderDataOrigin, this.itemLabels, this.filteredCondition);
            const [renderMinValue, renderMaxValue] = this.getMinAndMaxValue(this.renderDataItemFiltered);
            this.renderMinValue = renderMinValue;
            this.renderMaxValue = renderMaxValue;
            // 圖形渲染資料（含座標）
            const graphicXScale = makeAxisTimeScale(this.DateList, this.axisWidth, 0);
            const graphicYScale = makeAxisLinearScale({
                maxValue: this.renderMaxValue,
                minValue: this.renderMinValue,
                axisWidth: this.axisHeight,
                domainMinValue: 0,
                domainMaxValue: undefined,
                domainMinRange: 0,
                domainMaxRange: this.params.domainMaxRange,
                reverse: true
            });
            this.graphicRenderData = makeGraphicRenderData(this.renderDataItemFiltered, graphicXScale, graphicYScale);
            this.setGraphicData({
                renderDataItemFiltered: this.graphicRenderData,
                itemLabels: this.filteredCondition.filterItems,
                xLabels: this.xLabels,
                yScale: graphicYScale
            });
        }
        // 篩選索引
        this.filterStartIndex = this.DateList.indexOf(this.filteredCondition.StartDate);
        // this.filterEndIndex = this.DateList.indexOf(this.filteredCondition.EndDate)
        // 篩選所有條件後的資料
        this.filteredData = makeFilteredData(this.renderDataItemFiltered, this.filteredCondition);
        this.FilteredDateList = this.filteredData[0].map(d => d.Date);
        const [filteredMinValue, filteredMaxValue] = this.getMinAndMaxValue(this.filteredData);
        this.filteredMinValue = filteredMinValue;
        this.filteredMaxValue = filteredMaxValue;
        this.FilteredXDataMap = new Map();
        if (this.filteredData[0] && this.filteredData[0].length) {
            for (let i = 0; i < this.filteredData[0].length; i++) {
                this.FilteredXDataMap.set(i, this.filteredData.map(d => d[i]));
            }
        }
        this.FilteredItemDataMap = new Map(this.filteredCondition.filterItems.map((d, i) => {
            return [
                d,
                this.filteredData[i]
            ];
        }));
    }
    // 渲染
    render() {
        if (!this.dataset.data.length || !this.filteredData.length || !this.filteredData[0].length) {
            return;
        }
        this.xScale = makeAxisTimeScale(this.FilteredDateList, this.axisWidth, 0.5);
        this.yScale = makeAxisLinearScale({
            maxValue: this.filteredMaxValue,
            minValue: this.filteredMinValue,
            axisWidth: this.axisHeight,
            domainMinValue: 0,
            domainMaxValue: undefined,
            domainMinRange: 0,
            domainMaxRange: this.params.domainMaxRange,
            reverse: true
        });
        // const rangePadding = this.axisWidth / (this.FilteredDateList.length * 0.5) // 取範圍時在場景上的左右間距
        this.xRangeScale = makeAxisQuantizeScale({
            axisLabels: this.FilteredDateList,
            axisWidth: this.axisWidth,
        });
        // 圖形變形
        this.transformGraphicG({
            graphicGSelection: this.graphicGSelection,
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            renderMaxValue: this.renderMaxValue,
            xScale: this.xScale,
            yScale: this.yScale,
            padding: this.params.padding,
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight
        });
        // 繪製座標軸
        this.axisDateColumn.setDataset({
            xScale: this.xScale,
            yScale: this.yScale,
            maxValue: this.filteredMaxValue,
            dateAmount: this.FilteredDateList.length
        });
        this.axisDateColumn.render();
        // 繪製圖形（用未篩選的比例尺來畫）
        this.renderGraphic();
        this.renderGraphicCover({
            coverSelection: this.coverSelection,
            filteredData: this.filteredData,
            xScale: this.xScale,
            yScale: this.yScale,
            itemLabels: this.itemLabels,
        });
        // init zoom
        if (!this.params.zoom) {
            this.removeZoom();
            return;
        }
        const MinZoomStartDate = this.StartDate;
        const MaxZoomStartDate = addDays(this.filteredCondition.EndDate, -1);
        this.initZoom({
            ...this.zoom,
            scaleExtent: {
                min: 1 - dateDiff(this.filteredCondition.StartDate, MinZoomStartDate),
                max: 1 + dateDiff(MaxZoomStartDate, this.filteredCondition.StartDate)
            }
        });
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        else if (actionName === 'zoom') {
            this.zoomCallback = callback;
        }
        return this;
    }
    makeActivedCallbackData = (activedItemId, event) => {
        return makeCallbackData({
            dataset: this.dataset,
            activedItemId: activedItemId,
            FilteredDateList: this.FilteredDateList,
            FilteredXDataMap: this.FilteredXDataMap,
            FilteredItemDataMap: this.FilteredItemDataMap,
            padding: this.params.padding,
            xRangeScale: this.xRangeScale,
            event
        });
    };
    showTooltip(eventData, event) {
        if (event && eventData && this.xScale) {
            this.tooltip.setDatum({
                data: eventData,
                // x: d3.event.clientX,
                x: eventData.clientX - eventData.offsetX + this.params.padding.left + this.xScale(eventData.Date),
                y: d3.event.clientY
            });
        }
        else {
            this.tooltip.remove();
        }
    }
    setRangeEvent(selection) {
        selection
            // .on('mousedown', (d) => {
            //   this.dragstartX = d3.event.clientX
            // })
            .on('click', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    setCoverEvent(selection) {
        selection
            .on('mousedown', (d) => {
            this.dragstartX = d3.event.clientX;
        })
            .on('mouseover', (d, i) => {
            const tooltipData = makeCallbackData({
                dataset: this.dataset,
                activedItemId: '',
                FilteredDateList: this.FilteredDateList,
                FilteredXDataMap: this.FilteredXDataMap,
                FilteredItemDataMap: this.FilteredItemDataMap,
                padding: this.params.padding,
                xRangeScale: this.xRangeScale,
                event: d3.event
            });
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mousemove', (d) => {
            const tooltipData = makeCallbackData({
                dataset: this.dataset,
                activedItemId: '',
                FilteredDateList: this.FilteredDateList,
                FilteredXDataMap: this.FilteredXDataMap,
                FilteredItemDataMap: this.FilteredItemDataMap,
                padding: this.params.padding,
                xRangeScale: this.xRangeScale,
                event: d3.event
            });
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mouseout', (d) => {
            console.log('mouseout');
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            this.coverHighlight(undefined);
            this.tooltip.remove();
        });
    }
    renderClipPath(clipPathData) {
        const update = this.defsSelection
            .selectAll('clipPath')
            .data(clipPathData);
        const enter = update.enter()
            .append('clipPath');
        const cutRect = update.merge(enter)
            .attr('id', d => d.id);
        update.exit().remove();
        cutRect.each((d, i, g) => {
            const updateRect = d3.select(g[i])
                .selectAll('rect.bp__clippath')
                .data([d]);
            const enterRect = updateRect.enter()
                .append('rect')
                .classed('bp__clippath', true);
            updateRect.exit().remove();
            updateRect.merge(enterRect)
                .attr('x', _d => _d.x)
                .attr('y', _d => _d.y)
                .attr('width', _d => _d.width)
                .attr('height', _d => _d.height);
        });
    }
    onZoomWheel(event) {
        // -- 移除目前畫面上 mouseover時的顯示資訊 --
        if (this.tooltip) {
            this.tooltip.remove();
        }
        if (this.FilteredDateList.length == 1) {
            //只有一筆資料不移除繼續顯示圓點
            const eventData = makeCallbackData({
                dataset: this.dataset,
                activedItemId: '',
                FilteredDateList: this.FilteredDateList,
                FilteredXDataMap: this.FilteredXDataMap,
                FilteredItemDataMap: this.FilteredItemDataMap,
                padding: this.params.padding,
                xRangeScale: this.xRangeScale,
                event: event
            });
            // 圖形
            this.renderGraphicCoverHighlight({
                coverSelection: this.coverSelection,
                eventData: eventData,
                xScale: this.xScale,
                yScale: this.yScale,
                itemLabels: this.itemLabels
            });
        }
        this.coverHighlight(undefined);
        this.tooltip.remove();
        // -- 計算移動索引 --
        let filterStartIndex = this.filterStartIndex;
        // let filterEndIndex = this.filterEndIndex
        const kDiff = d3.event.transform.k - 1;
        const dateDiff = calcDateDiffByK(this.FilteredDateList.length, kDiff);
        filterStartIndex += dateDiff;
        if (filterStartIndex < 0) {
            filterStartIndex = 0;
            // 如果左邊已到底了則換右邊移動
            // @Q@ 後來發現因有 zoom最有值問題，監聽不到 zoom事件，想不出解法先放棄
            // filterEndIndex -= Math.round(dateDiff)
            // if (filterEndIndex > this.renderDataItemFiltered![0].length - 1) {
            //   filterEndIndex = this.renderDataItemFiltered![0].length - 1
            // }
        }
        else if (filterStartIndex >= this.renderDataItemFiltered[0].length) {
            filterStartIndex = this.renderDataItemFiltered[0].length - 1;
        }
        // 新的日期資料
        const startDatum = this.renderDataItemFiltered[0][filterStartIndex];
        // const endDatum = this.renderDataItemFiltered![0][filterEndIndex]
        // 如和前一次資料不同則移動
        if (String(startDatum.xLabel) != String(this.filteredData[0][0].xLabel)) {
            // filter
            this.filter({
                startDate: startDatum.Date,
                // endDate: endDatum.Date,
                filterItems: this.filterConfig.filterItems
            });
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.zoomCallback(callbackData);
            // reset k
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
            this.render();
        }
    }
    // 托曳
    onZoomMouse(event) {
        if (!this.filterConfig || !this.filteredData || !this.filteredData[0]) {
            return;
        }
        // -- 移除目前畫面上 mouseover時的顯示資訊 --
        if (this.tooltip) {
            this.tooltip.remove();
        }
        if (this.FilteredDateList.length == 1) {
            //只有一筆資料不移除繼續顯示圓點
            const eventData = makeCallbackData({
                dataset: this.dataset,
                activedItemId: '',
                FilteredDateList: this.FilteredDateList,
                FilteredXDataMap: this.FilteredXDataMap,
                FilteredItemDataMap: this.FilteredItemDataMap,
                padding: this.params.padding,
                xRangeScale: this.xRangeScale,
                event: event
            });
            // 圖形
            this.renderGraphicCoverHighlight({
                coverSelection: this.coverSelection,
                eventData: eventData,
                xScale: this.xScale,
                yScale: this.yScale,
                itemLabels: this.itemLabels
            });
        }
        this.coverHighlight(undefined);
        this.tooltip.remove();
        // -- 托曳 --
        const dragDistance = event.sourceEvent.clientX - this.dragstartX;
        if (dragDistance == 0) {
            return;
        }
        // const filteredStartData = this.filteredData[0][0]
        // const filteredEndData = this.filteredData[0][this.filteredData[0].length - 1]
        const FilteredStartDate = this.FilteredDateList[0];
        const FilteredEndDate = this.FilteredDateList[this.FilteredDateList.length - 1];
        let startIndex = 0;
        let endIndex = 0;
        // 往右
        if (dragDistance > 0) {
            endIndex = this.filterStartIndex + this.xRangeScale(this.xScale(FilteredEndDate) - dragDistance);
            startIndex = endIndex - (this.FilteredDateList.length - 1); // 範圍內資料筆數不變
            if (startIndex < 0) {
                startIndex = 0;
                endIndex = (this.FilteredDateList.length - 1);
            }
        }
        // 往左
        else if (dragDistance < 0) {
            startIndex = this.filterStartIndex + this.xRangeScale(this.xScale(FilteredStartDate) - dragDistance);
            endIndex = startIndex + (this.FilteredDateList.length - 1);
            if (endIndex > (this.DateList.length - 1)) {
                endIndex = (this.DateList.length - 1);
                startIndex = endIndex - (this.FilteredDateList.length - 1);
            }
        }
        // 如和前一次資料不同則移動
        if (String(this.renderDataItemFiltered[0][startIndex].xLabel) != String(this.filteredData[0][0].xLabel)) {
            this.dragstartX = event.sourceEvent.clientX; // 下一次的起始位置
            // filter
            this.filter({
                startDate: this.DateList[startIndex],
                endDate: this.DateList[endIndex],
                filterItems: this.filterConfig.filterItems
            });
            this.render();
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.zoomCallback(callbackData);
        }
    }
    transformGraphicG({ graphicGSelection, StartDate, EndDate, renderMaxValue, xScale, yScale, axisWidth, axisHeight, padding }) {
        // 用原始資料計算縮放後的座標
        const graphicStartX = xScale(StartDate);
        const graphicEndX = xScale(EndDate);
        const graphicEndY = yScale(renderMaxValue / this.params.domainMaxRange);
        const graphicWidth = graphicEndX - graphicStartX;
        const graphicHeight = axisHeight - graphicEndY;
        const scaleX = graphicWidth / axisWidth;
        const scaleY = graphicHeight / axisHeight;
        // const translateX = graphicStartX - (padding.left * scaleX) + padding.left // 因為<g>的 x座標不是由0開始，所以要做偏移修正
        // const translateY = graphicEndY - (padding.top * scaleY) + padding.top // 因為<g>的 y座標不是由0開始，所以要做偏移修正
        const translateX = graphicStartX;
        const translateY = graphicEndY;
        graphicGSelection
            .transition()
            .duration(50)
            .attr('transform', d => `
        translate(${translateX},${translateY}) 
        scale(${scaleX} ${scaleY})
      `);
        // 圖形變形
        // this.graphicTransformDatum = {
        //   translateX, 
        //   translateY,
        //   scaleX,
        //   scaleY
        // }
        // <g>
        // const updateG = graphicGClipPathSelection
        //   .selectAll<SVGGElement, GraphicTransformDatum>('g.bpchart__graphicG')
        //   .data([this.graphicTransformDatum])
        // const enterG = updateG.enter()
        //   .append('g')
        //   .classed('bpchart__graphicG', true)
        // this.graphicGSelection = updateG.merge(enterG)
        // this.graphicGSelection    
        // .transition()
        // .duration(50)
        // .attr('transform', d => `
        //   translate(${this.params.padding!.left + d.translateX},${this.params.padding!.top + d.translateY}) 
        //   scale(${d.scaleX} ${d.scaleY})
        // `)
    }
    // 複蓋區域 highlight
    coverHighlight(eventData) {
        // 標示線
        if (this.params.showAuxLine) {
            this.renderAuxLine({
                coverSelection: this.coverSelection,
                FilteredDateList: this.FilteredDateList,
                activeRangeIndex: eventData ? eventData.rangeIndex : -1,
                xScale: this.xScale,
                padding: this.params.padding,
                axisHeight: this.axisHeight
            });
        }
        // 日期標籤
        this.renderDateLabel({
            coverSelection: this.coverSelection,
            eventData: eventData,
            xScale: this.xScale,
            padding: this.params.padding,
            axisHeight: this.axisHeight,
            xTickPadding: this.params.axisDateColumn.xTickPadding
        });
        // 圖形
        this.renderGraphicCoverHighlight({
            coverSelection: this.coverSelection,
            eventData: eventData,
            xScale: this.xScale,
            yScale: this.yScale,
            itemLabels: this.itemLabels
        });
    }
    renderAuxLine({ coverSelection, FilteredDateList, activeRangeIndex, xScale, padding, axisHeight }) {
        if (!xScale) {
            return;
        }
        const auxLineData = FilteredDateList.map((d, i) => {
            const x = (xScale(d) || 0);
            return {
                x1: x,
                x2: x,
                y1: 0,
                y2: axisHeight,
                active: activeRangeIndex == i
            };
        });
        this.utilAuxLine.setData(auxLineData);
        this.utilAuxLine.render();
    }
    renderDateLabel({ coverSelection, eventData, xScale, padding, axisHeight, xTickPadding }) {
        let data = [];
        if (eventData) {
            const defaultTickSize = 6;
            const y = axisHeight + xTickPadding + defaultTickSize;
            const x = xScale(eventData.Date);
            data = [{
                    xLabel: eventData.xLabel,
                    x,
                    y
                }];
        }
        this.utilAuxDateLabel.setData(data);
        this.utilAuxDateLabel
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
        this.utilAuxDateLabel.render();
    }
    // 建立底圖
    renderBackground(backgroundSelection) {
        if (!this.params.backgroundColor) {
            return;
        }
        const backgroundUpdate = backgroundSelection
            .selectAll('rect.bpchart__chart-bg')
            .data([{
                axisWidth: this.axisWidth,
                axisHeight: this.axisHeight,
                padding: this.params.padding,
                backgroundColor: this.params.backgroundColor
            }]);
        backgroundUpdate
            .enter()
            .append('rect')
            .classed('bpchart__chart-bg', true)
            .merge(backgroundUpdate)
            .attr("transform", d => "translate(" + d.padding.left + "," + d.padding.top + ")")
            .attr('width', d => d.axisWidth)
            .attr('height', d => d.axisHeight)
            .attr('fill', d => d.backgroundColor);
        backgroundUpdate.exit().remove();
    }
}
