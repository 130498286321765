// const response = {
//   "payload": {
//     "list": [
//       {
//         "name": "2016",
//         "label": "2016",
//         "group": [
//           {
//             "label": "進口",
//             "value": 55
//           },
//           {
//             "label": "出口",
//             "value": 35
//           }
//         ]
//       },
//       {
//         "name": "2017",
//         "label": "2017",
//         "group": [
//           {
//             "label": "進口",
//             "value": 80
//           },
//           {
//             "label": "出口",
//             "value": 40
//           }
//         ]
//       },
//       {
//         "name": "2018",
//         "label": "2018",
//         "group": [
//           {
//             "label": "進口",
//             "value": 50
//           },
//           {
//             "label": "出口",
//             "value": 15
//           }
//         ]
//       },
//       {
//         "name": "2019",
//         "label": "2019",
//         "group": [
//           {
//             "label": "進口",
//             "value": 110
//           },
//           {
//             "label": "出口",
//             "value": 65
//           }
//         ]
//       },
//       {
//         "name": "2020",
//         "label": "2020",
//         "group": [
//           {
//             "label": "進口",
//             "value": 150
//           },
//           {
//             "label": "出口",
//             "value": 120
//           }
//         ]
//       },
//     ],
//   }
// }
// const xLabels = response.payload.list.map(d => d.label)
// console.log(xLabels)
// let test: any = []
// response.payload.list.forEach((d, i) => {
//   d.group.forEach((_d, _i) => {
//     if (!test[_i]) {
//       test[_i] = []
//     }
//     test[_i].push({
//       value: _d.value
//     })
//   })
// })
// console.log(test)
const data = [
    [
        {
            "value": 55
        },
        {
            "value": 80
        },
        {
            "value": 50
        },
        {
            "value": 110
        },
        {
            "value": 150
        }
    ],
    [
        {
            "value": 35
        },
        {
            "value": 40
        },
        {
            "value": 15
        },
        {
            "value": 65
        },
        {
            "value": 120
        }
    ]
];
export default {
    // data: response.payload.list
    //   .map(d => {
    //     return d.group.map(_d => {
    //       return {
    //         value: _d.value
    //       }
    //     })
    //   }),
    // xLabels: response.payload.list.map(d => d.label),
    data,
    yLabels: [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    itemLabels: ['進口', '出口']
};
