import * as d3 from 'd3';
import { measureTextWidth, getTextLines } from '@bpchart/d3-modules/utils';
import Nodes from './Nodes';
import Edges from './Edges';
import { NODE_TEXT_SIZE, NODE_TEXT_WIDTH_DEFAULT, DEFAULT_STOCK_MIN, DEFAULT_STOCK_EXCEPT_ZERO } from './const';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
// const filterStockMin = (sourceNodes: Array<SourceNode>, sourceEdges: Array<SourceEdge>, rootID: string, stockMin: number) => {
//   let newData = {
//     nodes: [] as Array<SourceNode>,
//     edges: [] as Array<SourceEdge>
//   }
//   // step1 過濾百分比
//   newData.edges = sourceEdges.filter(edge => {
//     return edge.percentage >= stockMin
//   })
//   // step2 過濾掉無連接到根節點的線
//   const filterEdgesFromRoot = (edges: Array<SourceEdge>) => {
//     let newEdges: Array<SourceEdge> = []
//     const searchEdge = (currentID: string) => {
//       const topFromCurrent = edges.filter(edge => edge['target-uniID'] === currentID)
//       const downFromCurrent = edges.filter(edge => edge['source-uniID'] === currentID)
//       for (const edge of topFromCurrent) {
//         let isExist = newEdges.some(newEdge => {
//           return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID']
//         })
//         if (isExist == false) {
//           newEdges.push(edge)
//           searchEdge(edge['source-uniID'])
//         }
//       }
//       for (const edge of downFromCurrent) {
//         let isExist = newEdges.some(newEdge => {
//           return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID']
//         })
//         if (isExist == false) {
//           newEdges.push(edge)
//           searchEdge(edge['target-uniID'])
//         }
//       }
//     }
//     searchEdge(rootID)
//     return newEdges
//   }
//   newData.edges = filterEdgesFromRoot(newData.edges)
//   // step3 過濾掉無連接線的節點
//   newData.nodes = sourceNodes.filter(node => {
//     return newData.edges.find((edge) => node.uniID === edge['source-uniID'] || node.uniID === edge['target-uniID']) != null
//   })
//   return newData
// }
export default class ChartScoutRoute {
    selection;
    params = {
        nodeTypeConfig: {},
        styleConfig: {},
        nodeTagConfig: {},
        scaleExtent: {
            min: 0,
            max: Infinity // 最大放大比
        }
    };
    dataset = {
        nodes: [],
        rootId: '',
        stockExceptZero: false,
        stockMin: 0
    };
    transform = {
        x: 0,
        y: 0,
        k: 1
    };
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    zoom = {
        xOffset: 0,
        yOffset: 0,
        scaleExtent: this.params.scaleExtent
    };
    nodes;
    edges;
    // protected nodesDataOrigin: Array<SourceNode> | undefined
    // protected edgesDataOrigin: Array<SourceEdge> | undefined
    // protected nodesData: Array<SourceNode> | undefined
    // protected edgesData: Array<SourceEdge> | undefined
    rootId = '';
    svgGroup;
    d3Zoom = undefined;
    nodesG;
    edgesG;
    constructor(el, params) {
        this.selection = el;
        this.svgGroup = this.selection.append('g').classed('chart-scout-route__group', true);
        this.params = {
            ...this.params,
            ...params
        };
        this.nodesG = this.svgGroup.append('g');
        this.edgesG = this.svgGroup.append('g');
    }
    remove() {
        this.selection.remove();
    }
    select() {
        return this.selection;
    }
    // 設置拖拽及放大縮小
    initZoom(zoom = this.zoom) {
        this.zoom = {
            ...this.zoom,
            ...zoom
        };
        // 滑鼠滾動放大縮小
        this.selection.on('zoom', null);
        this.d3Zoom = d3.zoom()
            .on('zoom', () => {
            const x = d3.event.transform.x + (this.zoom.xOffset * d3.event.transform.k);
            const y = d3.event.transform.y + (this.zoom.yOffset * d3.event.transform.k);
            const k = d3.event.transform.k;
            this.svgGroup.attr('transform', `translate(${x},${y}) scale(${k})`);
            this.transform = {
                x: d3.event.transform.x,
                y: d3.event.transform.y,
                k
            };
            if (this.onZoom) {
                this.onZoom(d3.event.transform);
            }
        });
        if (this.zoom.scaleExtent) {
            this.d3Zoom.scaleExtent([this.zoom.scaleExtent.min, this.zoom.scaleExtent.max]);
        }
        this.selection.call(this.d3Zoom);
    }
    // 目前好像不需要
    removeZoom() {
        this.d3Zoom = d3.zoom()
            .on('zoom', null);
    }
    transformZoom(transform) {
        this.transform = transform;
        // 設定 d3.event.transform 並觸發 d3.zoom().on()
        if (this.d3Zoom && this.d3Zoom.transform) {
            this.selection.call(this.d3Zoom.transform, d3.zoomIdentity
                .translate(this.transform.x, this.transform.y)
                .scale(this.transform.k));
        }
    }
    formatPercentage(n) {
        if (n == null || n <= 0) {
            return '0%';
        }
        else if (n < 0.01) {
            return '< 0.01%';
        }
        let newN = Math.round(Number(n) * 100) / 100;
        if (newN > 0) {
            return `${newN}%`;
        }
        else {
            return '< 0.01%';
        }
    }
    // 取得標籤文字換行文字及寬度
    getLabelInfo(text) {
        const { textWidth, lines } = getTextLines(text, NODE_TEXT_SIZE, NODE_TEXT_WIDTH_DEFAULT);
        return {
            textWidth,
            labels: lines
        };
    }
    parseRoutesDataUp(d) {
        return d['routes-in-id']
            .map((ids, index) => {
            return {
                'routes-in-id': ids,
                'routes-of-stock-detail': d['routes-of-stock-detail'][index],
                'routes-in-name': d['routes-in-name'][index],
                'routes-of-stock-detail-single-value': d['routes-of-stock-detail-single-value'][index]
            };
        })
            .sort((a, b) => {
            return this.sortRouteUpPriority(a, b);
        });
    }
    parseRoutesDataDown(d) {
        return d['routes_to_downs-in-id']
            .map((ids, index) => {
            return {
                'routes_to_downs-in-id': d['routes_to_downs-in-id'][index],
                'routes_to_downs-in-name': d['routes_to_downs-in-name'][index],
                'routes_to_downs-of-stock-detail': d['routes_to_downs-of-stock-detail'][index],
                'routes_to_downs-of-stock-detail-single-value': d['routes_to_downs-of-stock-detail-single-value'][index],
            };
        })
            .sort((a, b) => {
            return this.sortRouteDownPriority(a, b);
        });
    }
    // a是否優先於b
    isHigherRoutePriority(a, b) {
        return this.sortRouteUpPriority(a, b) < 0;
    }
    // 篩選
    // protected filterStockMin (nodeData: Array<TreeFullNodeDatum>, edgeData: Array<TreeEdgeDatum>, stockMin: number = DEFAULT_STOCK_MIN, stockExceptZero: boolean = DEFAULT_STOCK_EXCEPT_ZERO) {
    //   let newData = {
    //     nodes: [] as Array<TreeFullNodeDatum>,
    //     edges: [] as Array<TreeEdgeDatum>
    //   }
    //   // step1 過濾 routesData
    //   // let routesOfStockFilter = (value: number) => value >= DEFAULT_STOCK_MIN
    //   // if (stockMin > 0) {
    //   //   routesOfStockFilter = (value: number) => value >= stockMin
    //   // } else if (stockExceptZero == true) {
    //   //   routesOfStockFilter = (value: number) => value > 0
    //   // }
    //   newData.nodes = nodeData.map(node => {
    //     return <TreeFullNodeDatum>{
    //       ...node,
    //       _routesDataUp: node._routesDataUp?.filter(d => {
    //         // return d['routes-of-stock-detail'].every(routesOfStockFilter)
    //         if (stockMin > 0) {
    //           return d['routes-of-stock-detail-single-value'] >= stockMin
    //         } else if (stockExceptZero == true) {
    //           return d['routes-of-stock-detail-single-value'] > 0
    //         }
    //         return d['routes-of-stock-detail-single-value'] >= stockMin
    //       }),
    //       _routesDataDown: node._routesDataDown?.filter(d => {
    //         // return d['routes-of-stock-detail'].every(routesOfStockFilter)
    //         if (stockMin > 0) {
    //           return d['routes_to_downs-of-stock-detail-single-value'] >= stockMin
    //         } else if (stockExceptZero == true) {
    //           return d['routes_to_downs-of-stock-detail-single-value'] > 0
    //         }
    //         return d['routes_to_downs-of-stock-detail-single-value'] >= stockMin
    //       })
    //     }
    //   })
    //   // step2 取得所有存在的 nodes & edges
    //   let ExistNodeSet = new Set()
    //   let ExistEdgeSet = new Set()
    //   newData.nodes.forEach(node => {
    //     node._routesDataUp.forEach(route => {
    //       route['routes-in-id'].forEach(edgeId => {
    //         const idArr = edgeId.split('->')
    //         ExistNodeSet.add(idArr[0])
    //         ExistNodeSet.add(idArr[1])
    //         ExistEdgeSet.add(edgeId)
    //       })
    //     })
    //   })
    //   // step3 篩選 nodes & edges
    //   newData.nodes = newData.nodes
    //     .filter(node => ExistNodeSet.has(node.id))
    //     .map((node, i) => {
    //       return {
    //         ...node,
    //         index: i // 過濾後修正 index
    //       }
    //     })
    //   newData.edges = edgeData.filter(edge => ExistEdgeSet.has(edge.id))
    //   return newData
    // }
    // 產生篩選方法
    makeFilterPredicate(stockMin = DEFAULT_STOCK_MIN, stockExceptZero = DEFAULT_STOCK_EXCEPT_ZERO) {
        let predicate = (d) => d >= stockMin;
        if (stockMin == 0 && stockExceptZero) {
            predicate = (d) => d > 0;
        }
        else if (stockMin == 0 && !stockExceptZero) {
            predicate = (d) => true;
        }
        return predicate;
    }
    makeFilteredDataset({ nodeData, edgeData, rootId, stockMin = DEFAULT_STOCK_MIN, stockExceptZero = DEFAULT_STOCK_EXCEPT_ZERO }) {
        let filteredData = {
            nodes: [],
            edges: []
        };
        const predicate = this.makeFilterPredicate(stockMin, stockExceptZero);
        // step1 過濾 routesData（以總投資比例 routes-of-stock-detail-single-value 過濾）
        filteredData.nodes = nodeData
            .map(node => {
            let routesInId = [];
            let routesInName = [];
            let routesOfStockDetail = [];
            let routesOfStockDetailSingleValue = [];
            let routesToDownsInId = [];
            let routesToDownsInName = [];
            let routesToDownsOfStockDetail = [];
            let routesToDownsOfStockDetailSingleValue = [];
            for (let i in node['routes-of-stock-detail-single-value'] ?? []) {
                const value = node['routes-of-stock-detail-single-value'][i];
                if (predicate(value)) {
                    routesInId.push(node['routes-in-id'][i]);
                    routesInName.push(node['routes-in-name'][i]);
                    routesOfStockDetail.push(node['routes-of-stock-detail'][i]);
                    routesOfStockDetailSingleValue.push(node['routes-of-stock-detail-single-value'][i]);
                }
            }
            for (let i in node['routes_to_downs-of-stock-detail-single-value'] ?? []) {
                const value = node['routes_to_downs-of-stock-detail-single-value'][i];
                if (predicate(value)) {
                    routesToDownsInId.push(node['routes_to_downs-in-id'][i]);
                    routesToDownsInName.push(node['routes_to_downs-in-name'][i]);
                    routesToDownsOfStockDetail.push(node['routes_to_downs-of-stock-detail'][i]);
                    routesToDownsOfStockDetailSingleValue.push(node['routes_to_downs-of-stock-detail-single-value'][i]);
                }
            }
            return {
                ...node,
                'routes-in-id': routesInId,
                'routes-in-name': routesInName,
                'routes-of-stock-detail': routesOfStockDetail,
                'routes-of-stock-detail-single-value': routesOfStockDetailSingleValue,
                'routes_to_downs-in-id': routesToDownsInId,
                'routes_to_downs-in-name': routesToDownsInName,
                'routes_to_downs-of-stock-detail': routesToDownsOfStockDetail,
                'routes_to_downs-of-stock-detail-single-value': routesToDownsOfStockDetailSingleValue
            };
        });
        // step2 取得所有存在的 nodes & edges
        const ExistRouteNodeSet = new Set();
        const ExistRouteEdgeSet = new Set();
        const NeighborNodeSet = new Set();
        filteredData.nodes.forEach((node) => {
            (node['routes-in-id'] ?? [])
                .forEach(route => {
                route.forEach(edgeId => {
                    const idArr = edgeId.split('->');
                    ExistRouteNodeSet.add(idArr[0]);
                    ExistRouteNodeSet.add(idArr[1]);
                    ExistRouteEdgeSet.add(edgeId);
                });
            });
            (node['routes_to_downs-in-id'] ?? [])
                .forEach(route => {
                route.forEach(edgeId => {
                    const idArr = edgeId.split('->');
                    ExistRouteNodeSet.add(idArr[0]);
                    ExistRouteNodeSet.add(idArr[1]);
                    ExistRouteEdgeSet.add(edgeId);
                });
            });
            if (node.roles_related_to_root && node.roles_related_to_root.includes('neibor')) {
                NeighborNodeSet.add(node.uniID);
            }
        });
        // step3 如全部節點都被篩選掉則加入根節點
        if (ExistRouteNodeSet.size == 0) {
            ExistRouteNodeSet.add(rootId);
        }
        // step4 過濾掉沒有和現有節點連接的親戚節點
        const ExistNeighborNodeSet = new Set();
        edgeData.forEach(edge => {
            if (ExistRouteNodeSet.has(edge['source-uniID']) && NeighborNodeSet.has(edge['target-uniID'])) {
                ExistNeighborNodeSet.add(edge['target-uniID']);
            }
            if (NeighborNodeSet.has(edge['source-uniID']) && ExistRouteNodeSet.has(edge['target-uniID'])) {
                ExistNeighborNodeSet.add(edge['source-uniID']);
            }
        });
        // step5 篩選 nodes & edges
        filteredData.nodes = filteredData.nodes
            .filter(node => ExistRouteNodeSet.has(node.uniID) || ExistNeighborNodeSet.has(node.uniID))
            .map((node, i) => {
            return {
                ...node,
                index: i // 過濾後修正 index
            };
        });
        filteredData.edges = edgeData
            .filter(edge => ExistRouteEdgeSet.has(edge.id)
            || (ExistNeighborNodeSet.has(edge['source-uniID']) && ExistNeighborNodeSet.has(edge['target-uniID']))
            || (ExistNeighborNodeSet.has(edge['source-uniID']) && ExistRouteNodeSet.has(edge['target-uniID']))
            || (ExistRouteNodeSet.has(edge['source-uniID']) && ExistNeighborNodeSet.has(edge['target-uniID']))
            || (ExistRouteNodeSet.has(edge['source-uniID']) && ExistRouteNodeSet.has(edge['target-uniID'])));
        return filteredData;
    }
    sortRouteUpPriority(a, b) {
        // 優先排序路徑較短的
        if (a['routes-in-id'].length < b['routes-in-id'].length) {
            return -1;
        }
        else if (a['routes-in-id'].length > b['routes-in-id'].length) {
            return 1;
        }
        // 次要排序值較大的
        else if (a['routes-of-stock-detail'][0] != b['routes-of-stock-detail'][0]) {
            // 由根節點出發一直比較到最後一層
            for (let i = a['routes-of-stock-detail'].length - 1; i >= 0; i--) {
                if (a['routes-of-stock-detail'][i] > b['routes-of-stock-detail'][i]) {
                    return -1;
                }
                else if (a['routes-of-stock-detail'][i] < b['routes-of-stock-detail'][i]) {
                    return 1;
                }
            }
        }
        // else if (a['routes-of-stock-detail'][0] > b['routes-of-stock-detail'][0]) {
        //   return -1
        // } else if (a['routes-of-stock-detail'][0] < b['routes-of-stock-detail'][0]) {
        //   return 1
        // }
        // 第三排序首字筆劃
        else {
            const aEndName = a['routes-in-name'][0].split('->')[1];
            const bEndName = b['routes-in-name'][0].split('->')[1];
            for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                    return -1;
                }
                else if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                    return 1;
                }
            }
        }
        return -1; // 基本上不可能前面都排不出來就隨意排…
    }
    sortRouteDownPriority(a, b) {
        // 優先排序路徑較短的
        if (a['routes_to_downs-in-id'].length < b['routes_to_downs-in-id'].length) {
            return -1;
        }
        else if (a['routes_to_downs-in-id'].length > b['routes_to_downs-in-id'].length) {
            return 1;
        }
        // 次要排序值較大的
        else if (a['routes_to_downs-of-stock-detail'][a['routes_to_downs-of-stock-detail'].length - 1] != b['routes_to_downs-of-stock-detail'][b['routes_to_downs-of-stock-detail'].length - 1]) {
            // 由根節點出發一直比較到最後一層
            for (let i = 0; i <= a['routes_to_downs-of-stock-detail'].length - 1; i++) {
                if (a['routes_to_downs-of-stock-detail'][i] > b['routes_to_downs-of-stock-detail'][i]) {
                    return -1;
                }
                else if (a['routes_to_downs-of-stock-detail'][i] < b['routes_to_downs-of-stock-detail'][i]) {
                    return 1;
                }
            }
        }
        // 第三排序首字筆劃
        else {
            const aEndName = a['routes_to_downs-in-name'][0].split('->')[1];
            const bEndName = b['routes_to_downs-in-name'][0].split('->')[1];
            for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                    return -1;
                }
                else if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                    return 1;
                }
            }
        }
        return -1; // 基本上不可能前面都排不出來就隨意排…
    }
}
