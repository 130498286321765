import * as d3 from 'd3';
import { DEFAULT_PADDING, DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS } from './defaults';
// import { Padding } from '../types'
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import { parseDateTickFormatValue, parseTickFormatValue } from '../d3Utils';
const defaultTickSize = 6;
export default class AxisColumn {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        xScale: d3.scaleTime(),
        y1Scale: d3.scaleLinear(),
        y2Scale: d3.scaleLinear(),
        y1MaxValue: 0,
        y2MaxValue: 0,
        dateAmount: 0
    };
    params = DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS;
    xAxisSelection = undefined;
    y1AxisSelection = undefined;
    y2AxisSelection = undefined;
    xAxis;
    y1Axis;
    y2Axis;
    xLabelSelection = undefined;
    y1LabelSelection = undefined;
    y2LabelSelection = undefined;
    axisWidth = calcAxisWidth(this.width, DEFAULT_PADDING);
    axisHeight = calcAxisHeight(this.height, DEFAULT_PADDING);
    constructor(selection, params) {
        this.selection = selection;
        // this.chartSelection = this.selection.append('g')
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
    }
    render() {
        this.renderXDateAxis();
        this.renderY1LinearAxis();
        this.renderY2LinearAxis();
    }
    remove() {
        this.selection.remove();
    }
    renderXDateAxis() {
        let xAxisData = [];
        if (this.params.xVisible) {
            xAxisData.push(this.params);
        }
        const maxXTicks = Math.floor(this.axisWidth / this.params.xTickSpacing);
        const minXTicks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let xTicks = this.dataset.dateAmount - 1;
        if (xTicks < minXTicks) {
            xTicks = minXTicks;
        }
        else if (xTicks > maxXTicks) {
            xTicks = maxXTicks;
        }
        const xAxisUpdate = this.selection
            .selectAll('g.xAxis')
            .data(xAxisData);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.xAxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("xAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + (d.padding.top + this.axisHeight) + ")");
        xAxisUpdate.exit().remove();
        const xLabelUpdate = this.selection
            .selectAll('text.xLabel')
            .data(xAxisData);
        const xLabelEnter = xLabelUpdate
            .enter()
            .append('text')
            .classed('xLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor)
            .style('dominant-baseline', 'hanging');
        this.xLabelSelection = xLabelUpdate.merge(xLabelEnter)
            .attr('x', d => this.width - d.padding.right + d.xTickPadding)
            .attr('y', d => d.padding.top + this.axisHeight + d.y1TickPadding + defaultTickSize)
            .attr('transform', `translate(${this.params.xLabelOffset[0]}, ${this.params.xLabelOffset[1]})`)
            .attr('text-anchor', this.params.xLabelAnchor)
            .text(d => d.xLabel);
        xLabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.xLabelSelection.size() == 0) {
            return;
        }
        // 設定X軸刻度
        this.xAxis = d3.axisBottom(this.dataset.xScale)
            .scale(this.dataset.xScale)
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            .tickFormat(d => parseDateTickFormatValue(d, this.params.xTickFormat))
            .tickPadding(this.params.xTickPadding)
            .ticks(xTicks);
        const xAxisEl = this.xAxisSelection
            .transition()
            .duration(100)
            .call(this.xAxis)
            .attr('text-anchor', () => this.params.rotateXLabel !== false ? 'end' : 'middle'); // 如果要旋轉的話靠字尾對齊
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        const xText = xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            // .style('font-weight', 'bold')
            .style('color', this.params.axisLabelColor)
            .attr('transform-origin', `-5 ${this.params.xTickPadding + defaultTickSize}`);
        if (this.params.rotateXLabel === true) {
            xText.attr('transform', 'translate(0,0) rotate(-45)');
        }
        else if (typeof this.params.rotateXLabel === 'number') {
            xText.attr('transform', `translate(0,0) rotate(${this.params.rotateXLabel})`);
        }
    }
    renderY1LinearAxis() {
        let y1AxisData = [];
        if (this.params.y1Visible) {
            y1AxisData.push(this.params);
        }
        const maxY1Ticks = Math.floor(this.axisHeight / this.params.y1TickSpacing);
        const minY1Ticks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let y1Ticks = this.dataset.y1MaxValue - 1;
        if (y1Ticks < minY1Ticks) {
            y1Ticks = minY1Ticks;
        }
        else if (y1Ticks > maxY1Ticks) {
            y1Ticks = maxY1Ticks;
        }
        const y1AxisUpdate = this.selection
            .selectAll('g.y1Axis')
            .data(y1AxisData);
        const y1AxisEnter = y1AxisUpdate
            .enter()
            .append('g');
        this.y1AxisSelection = y1AxisUpdate.merge(y1AxisEnter)
            .classed("y1Axis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + d.padding.top + ")");
        y1AxisUpdate.exit().remove();
        const y1LabelUpdate = this.selection
            .selectAll('text.y1Label')
            .data(y1AxisData);
        const y1LabelEnter = y1LabelUpdate
            .enter()
            .append('text')
            .classed('y1Label', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor);
        this.y1LabelSelection = y1LabelUpdate.merge(y1LabelEnter)
            .attr('x', d => d.padding.left - d.xTickPadding)
            .attr('y', d => d.padding.top - d.y1TickPadding)
            .attr('transform', `translate(${this.params.y1LabelOffset[0]}, ${this.params.y1LabelOffset[1]})`)
            .attr('text-anchor', this.params.y1LabelAnchor)
            .text(d => d.y1Label);
        y1LabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.y1LabelSelection.size() == 0) {
            return;
        }
        // 設定Y軸刻度
        this.y1Axis = d3.axisLeft(this.dataset.y1Scale)
            .scale(this.dataset.y1Scale)
            .ticks(this.dataset.y1MaxValue > this.params.y1Ticks ? this.params.y1Ticks : (this.dataset.y1MaxValue === 0 ? 1 : Math.ceil(this.dataset.y1MaxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.y1TickFormat))
            .tickSize(this.params.y1TickLine && typeof this.params.y1TickLine === 'boolean' ? -this.axisWidth
            : this.params.y1TickLine && typeof this.params.y1TickLine === 'number' ? -this.axisWidth * this.params.y1TickLine
                : defaultTickSize)
            .tickPadding(this.params.y1TickPadding)
            .ticks(y1Ticks);
        const y1AxisEl = this.y1AxisSelection
            .transition()
            .duration(100)
            .call(this.y1Axis);
        y1AxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.y1TickLineDasharray);
        y1AxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        y1AxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
    renderY2LinearAxis() {
        let y2AxisData = [];
        if (this.params.y2Visible) {
            y2AxisData.push(this.params);
        }
        const maxY2Ticks = Math.floor(this.axisHeight / this.params.y2TickSpacing);
        const minY2Ticks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let y2Ticks = this.dataset.y2MaxValue - 1;
        if (y2Ticks < minY2Ticks) {
            y2Ticks = minY2Ticks;
        }
        else if (y2Ticks > maxY2Ticks) {
            y2Ticks = maxY2Ticks;
        }
        const y2AxisUpdate = this.selection
            .selectAll('g.y2Axis')
            .data([this.params]);
        const y2AxisEnter = y2AxisUpdate
            .enter()
            .append('g');
        this.y2AxisSelection = y2AxisUpdate.merge(y2AxisEnter)
            .classed("y2Axis", true)
            .attr("transform", d => "translate(" + (this.width - d.padding.right) + "," + d.padding.top + ")");
        y2AxisUpdate.exit().remove();
        const y2LabelUpdate = this.selection
            .selectAll('text.y2Label')
            .data([this.params]);
        const y2LabelEnter = y2LabelUpdate
            .enter()
            .append('text')
            .classed('y1Label', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold');
        this.y2LabelSelection = y2LabelUpdate.merge(y2LabelEnter)
            .attr('fill', d => d.y2LabelColor)
            .text(d => d.y2Label)
            .attr('x', d => this.width - d.padding.right + d.xTickPadding)
            .attr('y', d => d.padding.top - d.y2TickPadding)
            .attr('transform', `translate(${this.params.y2LabelOffset[0]}, ${this.params.y2LabelOffset[1]})`)
            .attr('text-anchor', this.params.y2LabelAnchor);
        y2LabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.y2LabelSelection.size() == 0) {
            return;
        }
        this.y2Axis = d3.axisRight(this.dataset.y2Scale)
            .scale(this.dataset.y2Scale)
            .ticks(this.dataset.y2MaxValue > this.params.y2Ticks ? this.params.y2Ticks : (this.dataset.y2MaxValue === 0 ? 1 : Math.ceil(this.dataset.y2MaxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.y2TickFormat))
            .tickSize(this.params.y2TickLine && typeof this.params.y2TickLine === 'boolean' ? -this.axisWidth
            : this.params.y2TickLine && typeof this.params.y2TickLine === 'number' ? -this.axisWidth * this.params.y2TickLine
                : defaultTickSize)
            .tickPadding(this.params.y2TickPadding);
        const y2AxisEl = this.y2AxisSelection
            .transition()
            .duration(100)
            .call(this.y2Axis);
        y2AxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.y2TickLineDasharray);
        y2AxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        y2AxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
}
