export default {
    "data": [
        {
            "value": 11087,
            "itemLabel": "IOS",
            "id": "iPhone 13 128GB",
            "itemIndex": 0,
            "xIndex": 0,
            "xLabel": "iPhone 13 128GB",
            "x": 18.985026041666657,
            "y": 15.000000000000046
        },
        {
            "value": 8682,
            "itemLabel": "IOS",
            "id": "iPhone 13 Pro 128GB",
            "itemIndex": 1,
            "xIndex": 1,
            "xLabel": "iPhone 13 Pro 128GB",
            "x": 56.95507812499999,
            "y": 76.82240461802114
        },
        {
            "value": 4455,
            "itemLabel": "IOS",
            "id": "iPhone 13 Pro Max 256GB",
            "itemIndex": 2,
            "xIndex": 2,
            "xLabel": "iPhone 13 Pro Max 256GB",
            "x": 94.92513020833333,
            "y": 185.48074321277176
        },
        {
            "value": 2722,
            "itemLabel": "IOS",
            "id": "iPhone 13 Pro 256GB",
            "itemIndex": 3,
            "xIndex": 3,
            "xLabel": "iPhone 13 Pro 256GB",
            "x": 132.89518229166666,
            "y": 230.02886263191127
        },
        {
            "value": 2524,
            "itemLabel": "Android",
            "id": "Samsung Galaxy A52s 5G 8+256GB",
            "itemIndex": 4,
            "xIndex": 4,
            "xLabel": "Samsung Galaxy A52s 5G 8+256GB",
            "x": 170.865234375,
            "y": 235.1186073780103
        },
        {
            "value": 1732,
            "itemLabel": "IOS",
            "id": "iPhone 13 256GB",
            "itemIndex": 5,
            "xIndex": 5,
            "xLabel": "iPhone 13 256GB",
            "x": 208.83528645833334,
            "y": 255.4775863624064
        },
        {
            "value": 1138,
            "itemLabel": "Android",
            "id": "Samsung Galaxy A52s 5G 6+128GB",
            "itemIndex": 6,
            "xIndex": 6,
            "xLabel": "Samsung Galaxy A52s 5G 6+128GB",
            "x": 246.80533854166666,
            "y": 270.74682060070353
        },
        {
            "value": 1024,
            "itemLabel": "IOS",
            "id": "iPhone 13 Pro Max 128GB",
            "itemIndex": 7,
            "xIndex": 7,
            "xLabel": "iPhone 13 Pro Max 128GB",
            "x": 284.775390625,
            "y": 273.6772796969424
        },
        {
            "value": 1008,
            "itemLabel": "Android",
            "id": "Samsung Galaxy !32 5G 6+128GB",
            "itemIndex": 8,
            "xIndex": 8,
            "xLabel": "Samsung Galaxy !32 5G 6+128GB",
            "x": 322.74544270833337,
            "y": 274.08857220167766
        },
        {
            "value": 996,
            "itemLabel": "Android",
            "id": "OPPO Reno6",
            "itemIndex": 9,
            "xIndex": 9,
            "xLabel": "OPPO Reno6",
            "x": 360.71549479166663,
            "y": 274.3970415802291
        },
        {
            "value": 913,
            "itemLabel": "Android",
            "id": "Samsung Galaxy A22 5G 4+128GB",
            "itemIndex": 10,
            "xIndex": 10,
            "xLabel": "Samsung Galaxy A22 5G 4+128GB",
            "x": 398.685546875,
            "y": 276.53062144854334
        },
        {
            "value": 907,
            "itemLabel": "Android",
            "id": "OPPO A74",
            "itemIndex": 11,
            "xIndex": 11,
            "xLabel": "OPPO A74",
            "x": 436.65559895833337,
            "y": 276.68485613781905
        }
    ],
    "itemLabels": [
        "IOS",
        "Android"
    ],
    "axisWidth": 455.640625,
    "zeroY": 300
};
