export default {
    data: [
        {
            "value": 25,
            "id": "進口價格_2016",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 0,
            "xLabel": "2016",
            "x": 32.545758928571416,
            "y": 320.83333333333337
        },
        {
            "value": 60,
            "id": "進口價格_2017",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 1,
            "xLabel": "2017",
            "x": 97.63727678571428,
            "y": 210
        },
        {
            "value": 70,
            "id": "進口價格_2018",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 2,
            "xLabel": "2018",
            "x": 162.72879464285714,
            "y": 178.33333333333337
        },
        {
            "value": 65,
            "id": "進口價格_2019",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 3,
            "xLabel": "2019",
            "x": 227.8203125,
            "y": 194.16666666666669
        },
        {
            "value": 100,
            "id": "進口價格_2020",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 4,
            "xLabel": "2020",
            "x": 292.9118303571429,
            "y": 83.33333333333334
        },
        {
            "value": 110,
            "id": "進口價格_2021",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 5,
            "xLabel": "2021",
            "x": 358.0033482142858,
            "y": 51.66666666666671
        },
        {
            "value": 120,
            "id": "進口價格_2022",
            "itemIndex": 0,
            "itemLabel": "進口價格",
            "xIndex": 6,
            "xLabel": "2022",
            "x": 423.09486607142856,
            "y": 20.000000000000018
        },
        {
            "value": 55,
            "id": "出口價格_2016",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 0,
            "xLabel": "2016",
            "x": 32.545758928571416,
            "y": 225.83333333333337
        },
        {
            "value": 70,
            "id": "出口價格_2017",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 1,
            "xLabel": "2017",
            "x": 97.63727678571428,
            "y": 178.33333333333337
        },
        {
            "value": 80,
            "id": "出口價格_2018",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 2,
            "xLabel": "2018",
            "x": 162.72879464285714,
            "y": 146.66666666666669
        },
        {
            "value": 105,
            "id": "出口價格_2019",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 3,
            "xLabel": "2019",
            "x": 227.8203125,
            "y": 67.50000000000003
        },
        {
            "value": 120,
            "id": "出口價格_2020",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 4,
            "xLabel": "2020",
            "x": 292.9118303571429,
            "y": 20.000000000000018
        },
        {
            "value": 98,
            "id": "出口價格_2021",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 5,
            "xLabel": "2021",
            "x": 358.0033482142858,
            "y": 89.6666666666667
        },
        {
            "value": 86,
            "id": "出口價格_2022",
            "itemIndex": 1,
            "itemLabel": "出口價格",
            "xIndex": 6,
            "xLabel": "2022",
            "x": 423.09486607142856,
            "y": 127.66666666666669
        }
    ],
    itemLabels: [
        "進口價格",
        "出口價格"
    ]
};
