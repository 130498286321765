import { GraphicRowBarDiverging } from '@bpchart/d3-modules/graphicRowBarDiverging';
import dataset from './dataset';
export default {
    props: {
        module: GraphicRowBarDiverging,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
