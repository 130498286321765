import { GoNode, GoEdge } from './goV3';
export const filterStockMin = (sourceNodes, sourceEdges, rootID, stockMin) => {
    let newData = {
        nodes: [],
        edges: []
    };
    // step1 過濾百分比
    newData.edges = sourceEdges.filter(edge => {
        return edge.percentage >= stockMin;
    });
    // step2 過濾掉無連接到根節點的線
    const filterEdgesFromRoot = (edges) => {
        let newEdges = [];
        const searchEdge = (currentID) => {
            const topFromCurrent = edges.filter(edge => edge['target-uniID'] === currentID);
            const downFromCurrent = edges.filter(edge => edge['source-uniID'] === currentID);
            for (const edge of topFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID'];
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge['source-uniID']);
                }
            }
            for (const edge of downFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge['source-uniID'] === edge['source-uniID'] && newEdge['target-uniID'] === edge['target-uniID'];
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge['target-uniID']);
                }
            }
        };
        searchEdge(rootID);
        return newEdges;
    };
    newData.edges = filterEdgesFromRoot(newData.edges);
    // step3 過濾掉無連接線的節點
    newData.nodes = sourceNodes.filter(node => {
        return newData.edges.find((edge) => node.uniID === edge['source-uniID'] || node.uniID === edge['target-uniID']) != null;
    });
    return newData;
};
// export const isCompanyClosed = (company_state: string | undefined) => {
//   if (company_state && company_state !== '核准設立') {
//     if (company_state === '歇業'
//     || company_state === '廢止'
//     || company_state === '廢止已清算完結'
//     || company_state === '廢止許可'
//     || company_state === '廢止許可已清算完結'
//     || company_state === '廢止認許'
//     || company_state === '廢止認許已清算完結'
//     || company_state === '廢止登記'
//     || company_state === '撤回認許'
//     || company_state === '撤回認許已清算完結'
//     || company_state === '撤回登記'
//     || company_state === '撤回登記已清算完結'
//     || company_state === '撤銷'
//     || company_state === '撤銷登記'
//     || company_state === '撤銷已清算完結'
//     || company_state === '撤銷無須清算'
//     || company_state === '撤銷許可'
//     || company_state === '撤銷認許'
//     || company_state === '撤銷認許已清算完結'
//     || company_state === '撤銷公司設立'
//     || company_state === '解散'
//     || company_state === '解散已清算完結'
//     || company_state === '合併解散'
//     || company_state === '核准設立，但已命令解散') {
//       return true
//     }
//   }
//   return false
// }
export const isCompanyClosed = (company_operate) => {
    if (company_operate !== '營業中') {
        true;
    }
    return false;
};
export const returnTags = (node) => {
    let tags = [];
    if (node.name_PEP_name || node.name_PEP_family) {
        tags.push({
            text: '政',
            style: 'tagPeps'
        });
    }
    if (node.role === '自然人' && node['total-investment-ratio'] >= 25) {
        tags.push({
            text: '益',
            style: 'tagBeneficial'
        });
    }
    return tags;
};
// export const returnTagTooltip = (node: GoNode) => {
//   let text = ''
//   try {
//     if (node.name_PEP_name && node.name_PEP_name.length) {
//       node.name_PEP_name.map(pepName => {
//         let positionAndExperience = (pepName.position || []).concat(pepName.experience || [])
//         positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
//         text += '．與列名 PEPS 者同名'
//         text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
//       })
//     }
//     if (node.name_PEP_family && node.name_PEP_family.length) {
//       node.name_PEP_family.map(pepFamily => {
//         let positionAndExperience = (pepFamily.position || []).concat(pepFamily.experience || [])
//         positionAndExperience = positionAndExperience.filter((d: string) => d !== '無')
//         if (text) {
//           text += '</br>'
//         }
//         text += '．與列名 PEPS 者之家屬同名'
//         text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`
//       })
//     }
//   } catch (e) { console.error(e) }
//   return text
// }
export const createTagTooltipText = (node) => {
    let text = '';
    try {
        if (node.name_PEP_name && node.name_PEP_name.length) {
            node.name_PEP_name.map(pepName => {
                let positionAndExperience = (pepName.position || []).concat(pepName.experience || []);
                positionAndExperience = positionAndExperience.filter((d) => d !== '無');
                text += '．與列名 PEPS 者同名';
                text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`;
            });
        }
        if (node.name_PEP_family && node.name_PEP_family.length) {
            node.name_PEP_family.map(pepFamily => {
                let positionAndExperience = (pepFamily.position || []).concat(pepFamily.experience || []);
                positionAndExperience = positionAndExperience.filter((d) => d !== '無');
                if (text) {
                    text += '</br>';
                }
                text += '．與列名 PEPS 者之家屬同名';
                text += `</br><span style="margin-left:15px">曾任 ${positionAndExperience.join('、')}</span>`;
            });
        }
    }
    catch (e) {
        console.error(e);
    }
    return text;
};
// 取得路徑筆數
export const getRouterCount = (routesOfStockDetailSingleValue, stockMin, stockExceptZero) => {
    let routesOfStockFilter = (value) => value >= 0;
    if (stockMin > 0) {
        routesOfStockFilter = (value) => value >= stockMin;
    }
    else if (stockExceptZero == true) {
        routesOfStockFilter = (value) => value > 0;
    }
    let count = 0;
    routesOfStockDetailSingleValue.forEach(d => {
        // if (d.every(routesOfStockFilter)) {
        //   count ++
        // }
        if (routesOfStockFilter(d)) {
            count++;
        }
    });
    return count;
};
