import { ChartScoutRouteLines } from '@bpchart/d3-modules/chartScoutRoute';
import { NODE_RADIUS_ROUND } from '@bpchart/d3-modules/chartScoutRoute/const';
import nodes from './nodes1';
function formatPercentage(n) {
    if (n == null || n <= 0) {
        return '0%';
    }
    else if (n < 0.01) {
        return '< 0.01%';
    }
    let newN = Math.round(Number(n) * 100) / 100;
    if (newN > 0) {
        return `${newN}%`;
    }
    else {
        return '< 0.01%';
    }
}
export default {
    props: {
        module: ChartScoutRouteLines,
        width: '100%',
        height: '100vh',
        params: {
            nodeTypeConfig: {
                root: {
                    rect: 'nodeRectRoot',
                    text: 'nodeTextRoot',
                },
                政府機關: {
                    rect: 'nodeRectGov',
                    text: 'nodeTextGov',
                },
                外資法人: {
                    rect: 'nodeRectForeign',
                    text: 'nodeTextForeign',
                },
                法人: {
                    rect: 'nodeRectCompany',
                    text: 'nodeTextCompany',
                },
                自然人: {
                    rect: 'nodeRectPerson',
                    text: 'nodeTextPerson',
                },
            },
            styleConfig: {
                nodeRectRoot: 'stroke-width: 5px; fill: #1778F5;',
                nodeRectGov: 'stroke: #F5DAB1;stroke-width: 1px; fill: #FDF6EC;',
                nodeRectForeign: 'stroke: #5EC5D7;stroke-width: 1px; fill: #E6F7FA;',
                nodeRectPerson: `stroke: #C2E7B0;stroke-width: 1px; fill: #F0F9EB; rx: ${NODE_RADIUS_ROUND}; ry: ${NODE_RADIUS_ROUND}`,
                nodeRectCompany: 'stroke: #D099EB;stroke-width: 1px; fill: #F7E6FA;',
                nodeRectClosed: 'stroke: #c0c4cc;stroke-width: 1px; fill: #DCDFE6;',
                nodeTextRoot: 'fill: #FFFFFF; font-weight: normal',
                nodeTextGov: 'fill: #E6A23C; font-weight: normal',
                nodeTextForeign: 'fill: #43A7B9; font-weight: normal',
                nodeTextPerson: 'fill: #67C23A; font-weight: normal;',
                nodeTextCompany: 'fill: #A261C2; font-weight: normal',
                nodeTextClosed: 'fill: #303133; font-weight: normal',
                // tagPeps: '',
                // tagTpexListed: '',
                // tagTwseListed: '',
                // tagBadVendor: '',
                // tagBeneficial: ''
            },
            nodeTagConfig: {
                政: {
                    label: '政',
                    tooltip: '政治職務之人',
                    backgroundColor: '#EB1010',
                    textColor: '#ffffff'
                },
                櫃: {
                    label: '櫃',
                    tooltip: '上櫃公司',
                    backgroundColor: '#0960D0',
                    textColor: '#ffffff'
                },
                市: {
                    label: '市',
                    tooltip: '上市公司',
                    backgroundColor: '#7FB5F9',
                    textColor: '#303133'
                },
                黑: {
                    label: '黑',
                    tooltip: '採購黑名單',
                    backgroundColor: '#303133',
                    textColor: '#ffffff'
                },
                益: {
                    label: '益',
                    tooltip: '實質受益人',
                    backgroundColor: '#E6A23C',
                    textColor: '#303133'
                }
            },
            titleContent: (route, i) => {
                return `${i + 1}. 本路徑持股 ${formatPercentage(route.routesData['routes-of-stock-detail-single-value'])}`;
            }
        },
        dataset: {
            nodes,
            // edges: response.d3.edges,
            // rootId: '25136736',
            targetId: '16410233',
            mainRelatedToRoot: 'up',
            stockExceptZero: true,
            stockMin: 0,
            sort: {
                'routes-of-stock-detail-single-value': -1
            }
        },
        filter: {
        // stockMin: 0.01
        },
        zoom: {
            x: 0,
            y: 0,
            k: 1
        }
    },
    emits: {}
};
