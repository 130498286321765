import { TooltipFollowing } from '@bpchart/d3-modules/tooltip';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import datum from './datum';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from '@bpchart/d3-modules/graphicDot';
export default {
    props: {
        module: TooltipFollowing,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_GRAPHIC_DOT_PARAMS,
        datum
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
