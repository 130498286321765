import { SliderDateRangeLine } from '@bpchart/d3-modules/sliderDateRange';
export default {
    props: {
        module: SliderDateRangeLine,
        params: {
            width: 0,
            dateFormat: '%Y-%m-%d',
            fixRange: false,
            fontSize: 10
        },
        data: [
            {
                "date": "2021-01-01",
                "value": 0
            },
            {
                "date": "2021-01-02",
                "value": 0
            },
            {
                "date": "2021-01-03",
                "value": 0
            },
            {
                "date": "2021-01-04",
                "value": 0
            },
            {
                "date": "2021-01-05",
                "value": 0
            },
            {
                "date": "2021-01-06",
                "value": 0
            },
            {
                "date": "2021-01-07",
                "value": 0
            },
            {
                "date": "2021-01-08",
                "value": 0
            },
            {
                "date": "2021-01-09",
                "value": 0
            },
            {
                "date": "2021-01-10",
                "value": 0
            },
            {
                "date": "2021-01-11",
                "value": 0
            },
            {
                "date": "2021-01-12",
                "value": 0
            },
            {
                "date": "2021-01-13",
                "value": 0
            },
            {
                "date": "2021-01-14",
                "value": 0
            },
            {
                "date": "2021-01-15",
                "value": 1
            },
            {
                "date": "2021-01-16",
                "value": 4
            },
            {
                "date": "2021-01-17",
                "value": 1
            },
            {
                "date": "2021-01-18",
                "value": 26
            },
            {
                "date": "2021-01-19",
                "value": 82
            },
            {
                "date": "2021-01-20",
                "value": 57
            },
            {
                "date": "2021-01-21",
                "value": 66
            },
            {
                "date": "2021-01-22",
                "value": 81
            },
            {
                "date": "2021-01-23",
                "value": 79
            },
            {
                "date": "2021-01-24",
                "value": 27
            },
            {
                "date": "2021-01-25",
                "value": 35
            },
            {
                "date": "2021-01-26",
                "value": 73
            },
            {
                "date": "2021-01-27",
                "value": 47
            },
            {
                "date": "2021-01-28",
                "value": 35
            },
            {
                "date": "2021-01-29",
                "value": 43
            },
            {
                "date": "2021-01-30",
                "value": 30
            },
            {
                "date": "2021-01-31",
                "value": 21
            }
        ]
    },
    emits: {
        dragStart: {},
        drag: {},
        dragEnd: {}
    }
};
