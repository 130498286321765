import * as d3 from 'd3';
import { DEFAULT_PADDING, DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_COLUMN_PARAMS } from './defaults';
// import { Padding } from '../types'
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import { parseDateTickFormatValue, parseTickFormatValue } from '../d3Utils';
const defaultTickSize = 6;
export default class AxisDateColumn {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        xScale: d3.scaleTime(),
        yScale: d3.scaleLinear(),
        maxValue: 0,
        dateAmount: 0
    };
    params = DEFAULT_AXIS_DATE_COLUMN_PARAMS;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    axisWidth = calcAxisWidth(this.width, DEFAULT_PADDING);
    axisHeight = calcAxisHeight(this.height, DEFAULT_PADDING);
    constructor(selection, params) {
        this.selection = selection;
        // this.chartSelection = this.selection.append('g')
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
    }
    render() {
        this.renderXDateAxis();
        this.renderYLinearAxis();
    }
    remove() {
        this.selection.remove();
    }
    renderXDateAxis() {
        let xAxisData = [];
        if (this.params.xVisible) {
            xAxisData.push(this.params);
        }
        const maxXTicks = Math.floor(this.axisWidth / this.params.xTickSpacing);
        const minXTicks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let xTicks = this.dataset.dateAmount - 1;
        if (xTicks < minXTicks) {
            xTicks = minXTicks;
        }
        else if (xTicks > maxXTicks) {
            xTicks = maxXTicks;
        }
        const xAxisUpdate = this.selection
            .selectAll('g.xAxis')
            .data(xAxisData);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.xAxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("xAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + (d.padding.top + this.axisHeight) + ")");
        xAxisUpdate.exit().remove();
        const xLabelUpdate = this.selection
            .selectAll('text.xLabel')
            .data(xAxisData);
        const xLabelEnter = xLabelUpdate
            .enter()
            .append('text')
            .classed('xLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor)
            .style('dominant-baseline', 'hanging');
        this.xLabelSelection = xLabelUpdate.merge(xLabelEnter)
            .attr('x', d => this.width - d.padding.right + d.xTickPadding)
            .attr('y', d => d.padding.top + this.axisHeight + d.yTickPadding + defaultTickSize)
            .attr('transform', `translate(${this.params.xLabelOffset[0]}, ${this.params.xLabelOffset[1]})`)
            .attr('text-anchor', this.params.xLabelAnchor)
            .text(d => d.xLabel);
        xLabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.xLabelSelection.size() == 0) {
            return;
        }
        // 設定X軸刻度
        this.xAxis = d3.axisBottom(this.dataset.xScale)
            .scale(this.dataset.xScale)
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            .tickFormat(d => parseDateTickFormatValue(d, this.params.xTickFormat))
            .tickPadding(this.params.xTickPadding)
            .ticks(xTicks);
        const xAxisEl = this.xAxisSelection
            .transition()
            .duration(100)
            .call(this.xAxis)
            .attr('text-anchor', () => this.params.rotateXLabel !== false ? 'end' : 'middle'); // 如果要旋轉的話靠字尾對齊
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        const xText = xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            // .style('font-weight', 'bold')
            .style('color', this.params.axisLabelColor)
            .attr('transform-origin', `-5 ${this.params.xTickPadding + defaultTickSize}`);
        if (this.params.rotateXLabel === true) {
            xText.attr('transform', 'translate(0,0) rotate(-45)');
        }
        else if (typeof this.params.rotateXLabel === 'number') {
            xText.attr('transform', `translate(0,0) rotate(${this.params.rotateXLabel})`);
        }
    }
    renderYLinearAxis() {
        let yAxisData = [];
        if (this.params.xVisible) {
            yAxisData.push(this.params);
        }
        const maxYTicks = Math.floor(this.axisHeight / this.params.yTickSpacing);
        const minYTicks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let yTicks = this.dataset.maxValue - 1;
        if (yTicks < minYTicks) {
            yTicks = minYTicks;
        }
        else if (yTicks > maxYTicks) {
            yTicks = maxYTicks;
        }
        const yAxisUpdate = this.selection
            .selectAll('g.yAxis')
            .data(yAxisData);
        const yAxisEnter = yAxisUpdate
            .enter()
            .append('g');
        this.yAxisSelection = yAxisUpdate.merge(yAxisEnter)
            .classed("yAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + d.padding.top + ")");
        yAxisUpdate.exit().remove();
        const yLabelUpdate = this.selection
            .selectAll('text.yLabel')
            .data(yAxisData);
        const yLabelEnter = yLabelUpdate
            .enter()
            .append('text')
            .classed('yLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor);
        this.yLabelSelection = yLabelUpdate.merge(yLabelEnter)
            .attr('x', d => d.padding.left - d.xTickPadding)
            .attr('y', d => d.padding.top - d.yTickPadding)
            .attr('transform', `translate(${this.params.yLabelOffset[0]}, ${this.params.yLabelOffset[1]})`)
            .attr('text-anchor', this.params.yLabelAnchor)
            .text(d => d.yLabel);
        yLabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.yLabelSelection.size() == 0) {
            return;
        }
        // 設定Y軸刻度
        this.yAxis = d3.axisLeft(this.dataset.yScale)
            .scale(this.dataset.yScale)
            .ticks(this.dataset.maxValue > this.params.yTicks ? this.params.yTicks : (this.dataset.maxValue === 0 ? 1 : Math.ceil(this.dataset.maxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.yTickFormat))
            .tickSize(this.params.yTickLine && typeof this.params.yTickLine === 'boolean' ? -this.axisWidth
            : this.params.yTickLine && typeof this.params.yTickLine === 'number' ? -this.axisWidth * this.params.yTickLine
                : defaultTickSize)
            .tickPadding(this.params.yTickPadding)
            .ticks(yTicks);
        const yAxisEl = this.yAxisSelection
            .transition()
            .duration(100)
            .call(this.yAxis);
        yAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.yTickLineDasharray);
        yAxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        yAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
}
