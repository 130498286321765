import * as d3 from 'd3';
import { DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS } from './defaults';
// import TooltipAside from '../tooltip/TooltipAside'
import { makeColorScale } from '../moduleUtils';
// const _d3: any = d3
const makeItemDataMap = (data) => {
    const ItemDataMap = new Map();
    data.forEach(d => {
        const itemData = ItemDataMap.get(d.itemLabel) ?? [];
        itemData.push(d);
        ItemDataMap.set(d.itemLabel, itemData);
    });
    return ItemDataMap;
};
// const makeColorScale = (itemLabels: string[], colors: string[]) => {
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
const makeCallbackData = ({ datum, ItemDataMap, event }) => {
    return {
        groupData: [datum],
        itemData: ItemDataMap.get(datum.itemLabel),
        datum,
        // xLabel: '',
        // xIndex: -1,
        itemLabel: datum.itemLabel,
        itemIndex: datum.itemIndex,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
const calcAxisWidth = (data) => {
    if (data[0]) {
        // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
        let minX = Infinity;
        let maxX = 0;
        data.forEach(d => {
            if (d.x < minX) {
                minX = d.x;
            }
        });
        if (data[data.length - 1].x > maxX) {
            maxX = data[data.length - 1].x;
        }
        return maxX - minX;
    }
    return 0;
};
const calcAxisHeight = (data) => {
    if (data[0]) {
        // const minValue = d3.min(this.dataset.data, d => d3.min(d, _d => _d.value))
        let minValue = Infinity;
        let minValueY = Infinity;
        data.forEach(d => {
            if ((d.value || 0) < minValue) {
                minValue = (d.value || 0);
                minValueY = d.y;
            }
        });
        return minValueY;
    }
    return 0;
};
const calcBarWidth = (axisWidth, barAmount, barPadding = 0) => {
    const width = axisWidth / barAmount - barPadding;
    return width > 1 ? width : 1;
};
const calcDelayDuration = (barAmount, totalDuration = 400) => {
    return totalDuration / barAmount;
};
export default class GraphicColumnBar {
    selection;
    params = DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        zeroY: 0
    };
    graphicGSelection = undefined;
    defsSelection;
    itemLabels = [];
    colorScale;
    ItemDataMap = new Map();
    // private axisHeight = 0
    axisWidth = 0;
    barWidth = 0;
    delayDuration = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
        this.defsSelection = selection.append('defs');
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.itemLabels = [];
        if (this.params.barType == 'rect') {
            this.renderGraphic = this.renderRect;
        }
        else if (this.params.barType == 'triangle') {
            this.renderGraphic = this.renderTriangle;
        }
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = this.dataset.itemLabels;
        this.ItemDataMap = makeItemDataMap(this.dataset.data);
        this.delayDuration = calcDelayDuration(this.dataset.data.length);
    }
    render() {
        // 未 setDataset 則不執行
        if (!this.itemLabels.length) {
            return;
        }
        this.colorScale = makeColorScale(this.itemLabels, this.params.colors);
        this.setLinearGradient({
            defsSelection: this.defsSelection,
            itemLabels: this.itemLabels,
            colorScale: this.colorScale
        });
        // if (this.dataset.axisHeight) {
        //   this.axisHeight = this.dataset.axisHeight
        // } else {
        //   // 如無設定就取最小值，這樣只是圖不會爆炸但畫出來可能會有問題（不算bug但如果如y的起點不同的話 - 比如y的起點可能為0，就不會由起點開始畫）
        //   this.axisHeight = calcAxisHeight(this.dataset.data)
        // }
        if (this.dataset.axisWidth) {
            this.axisWidth = this.dataset.axisWidth;
        }
        else {
            this.axisWidth = calcAxisWidth(this.dataset.data);
        }
        this.barWidth = this.params.barWidth ? this.params.barWidth
            : this.params.barPadding != undefined ? calcBarWidth(this.axisWidth, this.dataset.data.length, this.params.barPadding)
                : 20; // 理論上barWidth 或 barPadding應該有值，這邊隨便給一個數字
        // 繪圖
        this.renderGraphic({
            selection: this.selection,
            data: this.dataset.data,
            colorScale: this.colorScale,
            params: this.params
        });
        // 綁定事件
        this.graphicGSelection.on('mouseover', null);
        this.graphicGSelection.on('mouseout', null);
        this.graphicGSelection
            .on('click', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i, n) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'item') {
                    this.highlight(this.graphicGSelection, callbackData.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(this.graphicGSelection, undefined, callbackData.datum && callbackData.datum.id);
                }
            }
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                this.removeHighlight(this.graphicGSelection);
            }
            const callbackData = makeCallbackData({
                datum: d,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.initHighlight();
            this.mouseoutCallback(callbackData);
        });
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    remove() {
        this.selection.remove();
    }
    setLinearGradient({ defsSelection, itemLabels, colorScale }) {
        const linearGradientUpdate = defsSelection
            .selectAll('linearGradient')
            .data(itemLabels, d => d);
        const linearGradientEnter = linearGradientUpdate
            .enter()
            .append('linearGradient')
            .attr('x1', '0%')
            .attr('x2', '0%')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr('spreadMethod', 'pad');
        linearGradientUpdate.merge(linearGradientEnter)
            .attr('id', (d, i) => `bpchart__lineargradient__${d}`)
            .html((d, i) => `
          <stop offset="0%"   stop-color="${colorScale(d)}" stop-opacity="1"/>
          <stop offset="100%" stop-color="${colorScale(d)}" stop-opacity="0"/>
        `);
        linearGradientUpdate.exit().remove();
    }
    renderGraphic({ selection, data, colorScale, params }) { }
    renderRect({ selection, data, colorScale, params }) {
        if (this.barWidth <= 0) {
            return;
        }
        const update = selection
            .selectAll('g.bpchart__bar-g')
            .data(data, (d, i) => d.id);
        const enter = update.enter()
            .append('g')
            .classed('bpchart__bar-g', true)
            .attr('cursor', 'pointer');
        update.exit().remove();
        this.graphicGSelection = update.merge(enter);
        const barHalfWidth = this.barWidth / 2;
        enter
            .append('rect')
            .classed('bpchart__bar', true)
            .attr('rx', params.barR == true ? barHalfWidth
            : params.barR == false ? 0
                : typeof params.barR == 'number' ? params.barR
                    : 0)
            .attr('transform', `translate(${-barHalfWidth}, 0)`)
            .attr('x', d => d.x)
            .attr('y', d => this.dataset.zeroY)
            .attr('width', this.barWidth)
            .attr('height', d => 0);
        this.graphicGSelection
            .select('rect')
            .attr('fill', d => colorScale(d.itemLabel))
            .transition()
            .duration(this.params.enterDuration)
            .ease(d3.easeElastic)
            .delay((d, i) => i * this.delayDuration)
            .attr('transform', `translate(${-barHalfWidth}, 0)`)
            .attr('x', d => d.x)
            .attr('y', d => d.y < this.dataset.zeroY ? d.y : this.dataset.zeroY) // 負值時 bar的 y軸最高點在 0值
            .attr('width', this.barWidth)
            .attr('height', d => Math.abs(this.dataset.zeroY - d.y))
            .on('end', () => this.initHighlight());
    }
    renderTriangle({ selection, data, colorScale, params }) {
        const update = selection
            .selectAll('g.bpchart__bar-g')
            .data(data, d => d.id);
        const enter = update.enter()
            .append('g')
            .classed('bpchart__bar-g', true);
        enter.append('path');
        update.exit().remove();
        const barHalfWidth = this.barWidth / 2;
        enter
            .select('path')
            .attr('transform', `translate(${-barHalfWidth}, 0)`)
            .attr('x', d => d.x - (this.params.barWidth / 2))
            .attr('y', d => this.dataset.zeroY)
            .attr('height', d => 0)
            .attr('d', (d) => {
            const x = d.x;
            const y1 = this.dataset.zeroY;
            const y2 = y1;
            return `M${x - (this.params.barWidth / 2)},${y1} L${x},${y2} ${x + (this.params.barWidth / 2)},${y1}`;
        });
        this.graphicGSelection = update.merge(enter);
        this.graphicGSelection
            .select('path')
            .style('fill', d => `url(#bpchart__lineargradient__${d.itemLabel})`)
            .attr('stroke', d => colorScale(d.itemLabel))
            .attr('transform', `translate(${-barHalfWidth}, 0)`)
            .transition()
            .duration(this.params.enterDuration)
            .ease(d3.easeElastic)
            .delay((d, i) => i * this.delayDuration)
            .attr('x', d => d.x - (this.params.barWidth / 2))
            .attr('y', d => d.y)
            .attr('height', d => Math.abs(this.dataset.zeroY - d.y))
            .attr('d', (d) => {
            const x = d.x;
            const y1 = this.dataset.zeroY;
            const y2 = d.y;
            return `M${x},${y1} L${x + (this.params.barWidth / 2)},${y2} ${x + this.params.barWidth},${y1}`;
        })
            .on('end', () => this.initHighlight());
    }
    initHighlight() {
        // highlight
        if (this.params.highlightItemId || this.params.highlightDatumId) {
            this.highlight(this.graphicGSelection, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    highlight(selection, itemId, id) {
        if (!selection) {
            return;
        }
        let ids = [];
        if (id) {
            ids.push(id);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .filter(d => d.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        selection
            .each((d, i, n) => {
            if (ids.includes(d.id)) {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(selection) {
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId) {
            this.highlight(selection, this.params.highlightDatumId, this.params.highlightItemId);
        }
        else if (!this.params.highlightDatumId) {
            selection
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }
}
