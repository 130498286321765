import * as d3 from 'd3';
import { dateDiff, addDays, measureTextWidth } from '../utils';
import { parseLocalDate, findStartDate, findEndDate, makeDateList } from '../d3Utils';
import { makeAxisTimeScale, makeAxisLinearScale, makeCircleRScale, makeAxisQuantizeScale } from '@bpchart/d3-modules/moduleUtils';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
import { DEFAULT_CHART_DATE_RANK_PARAMS } from './defaults';
import { AxisDateRank, DEFAULT_AXIS_DATE_RANK_PARAMS } from '../axisDateRank';
import TooltipAside from '../tooltip/TooltipAside';
import { UtilAuxLine } from '../utilAuxLine';
import { UtilAuxDateLabel } from '../utilAuxDateLabel';
const defaultItemLabel = '__DEFAULT__';
// const zoomSpeed = 25
// const calcDateDiffByK = (kDiff: number) => kDiff * zoomSpeed
// 用 k計算 zoom的日期變換
const calcDateDiffByK = (length, kDiff) => {
    return Math.round(length * kDiff);
};
const makeGraphicData = ({ data, xDates, itemLabels, xLabels }) => {
    return data.map((d, i) => {
        return d.map((_d, _i) => {
            const itemIndex = i;
            const itemLabel = itemLabels[i];
            const _Date = typeof xDates[_i] === 'string' ? parseLocalDate(xDates[_i])
                : xDates[_i] instanceof Date ? xDates[_i]
                    : xDates[_i];
            const xLabel = xLabels[_i];
            return {
                id: `${itemLabel}_${xLabel}`,
                value: _d.value,
                xIndex: _i,
                xLabel,
                itemIndex,
                itemLabel,
                Date: _Date
            };
        });
    });
};
const makeFilteredCondition = (DateList, filterConfig) => {
    const { StartDate, startIndex } = findStartDate(DateList, filterConfig.startDate);
    const { EndDate, endIndex } = findEndDate(DateList, filterConfig.endDate);
    return {
        startDate: filterConfig.startDate,
        endDate: filterConfig.endDate,
        StartDate: typeof filterConfig.startDate === 'string' ? parseLocalDate(filterConfig.startDate)
            : filterConfig.startDate instanceof Date ? filterConfig.startDate
                : filterConfig.startDate,
        EndDate: typeof filterConfig.endDate === 'string' ? parseLocalDate(filterConfig.endDate)
            : filterConfig.endDate instanceof Date ? filterConfig.endDate
                : filterConfig.endDate,
        startIndex,
        endIndex
    };
};
const makeItemContainerData = ({ filteredGraphicData, filteredCondition, yScale, rowAmount, itemLabels }) => {
    const outerY = yScale(rowAmount);
    // 加總列表
    const groupSumValueList = filteredGraphicData.map((d, i) => {
        return d.reduce((prev, current) => {
            return {
                index: i,
                sum: prev.sum + current.value
            };
        }, { index: 0, sum: 0 });
    });
    return filteredGraphicData
        .map((d, i) => {
        return {
            itemIndex: i,
            itemValueSum: groupSumValueList[i].sum
        };
    })
        // sum大排到小
        .sort((a, b) => {
        return b.itemValueSum - a.itemValueSum;
    })
        .map((d, i) => {
        return {
            seq: i,
            y: i < rowAmount ? yScale(i) : outerY,
            isVisible: i < rowAmount,
            itemLabel: itemLabels[d.itemIndex],
            itemIndex: d.itemIndex,
            itemValueSum: d.itemValueSum
        };
    })
        // 還原原本排序
        .sort((a, b) => {
        return a.itemIndex - b.itemIndex;
    });
};
const makeGraphicRenderData = ({ data, xScale, groupData, filteredCondition, rowAmount, maxValue, maxR }) => {
    // 以值和圓面積的比例作為比例尺
    const scaleBubbleR = makeCircleRScale(maxValue, maxR);
    // 效能考量所以範圍外的座標固定住
    // const outerY = yScale(rowAmount)!
    const OuterLeftDate = new Date(new Date(filteredCondition.StartDate).setDate(filteredCondition.StartDate.getDate() - 1));
    const OuterRightDate = new Date(new Date(filteredCondition.EndDate).setDate(filteredCondition.EndDate.getDate() + 1));
    const outerLeftX = xScale(OuterLeftDate);
    const outerRightX = xScale(OuterRightDate);
    // const groupDataByOriginSort = (Object.assign([], groupData) as ItemContainerDatum[])
    //   .sort((a, b) => {
    //     return a.itemIndex - b.itemIndex
    //   })
    return data.map((d, i) => {
        return d.map((_d, _i) => {
            // const inDateRange = _i >= filteredCondition.startIndex && _i <= filteredCondition.endIndex
            const isBeforeStartDate = _i < filteredCondition.startIndex;
            const isAfterEndDate = _i > filteredCondition.endIndex;
            const isInRow = i < rowAmount;
            const isVisible = isBeforeStartDate == false && isAfterEndDate == false && isInRow;
            return {
                ..._d,
                seq: groupData[i].seq,
                itemValueSum: groupData[i].itemValueSum,
                isVisible,
                x: isBeforeStartDate ? outerLeftX
                    : isAfterEndDate ? outerRightX
                        : xScale(_d.Date),
                y: groupData[i].y,
                r: scaleBubbleR(_d.value)
            };
        });
    });
};
const makeFilteredXDataMap = (filteredGraphicData) => {
    const XDataMap = new Map();
    if (!filteredGraphicData.length) {
        return XDataMap;
    }
    for (let i = 0; i <= filteredGraphicData[0].length - 1; i++) {
        XDataMap.set(i, filteredGraphicData.map(d => {
            return d[i];
        }));
    }
    return XDataMap;
};
const makeFilteredItemDataMap = (filteredGraphicData, filteredItemLabels) => {
    const ItemDataMap = new Map();
    if (!filteredGraphicData.length) {
        return ItemDataMap;
    }
    for (let i = 0; i <= filteredItemLabels.length - 1; i++) {
        ItemDataMap.set(filteredItemLabels[i], filteredGraphicData[i]);
    }
    return ItemDataMap;
};
const calcMaxR = (graphicHeight, rowAmount) => {
    // // 平均r
    // const avgR = graphicHeight / this.params.rowAmount / 4
    // const avgSize = avgR * avgR * Math.PI
    // const sizeRate = avgSize / this.avgValue
    // const maxSize = this.maxValue * sizeRate
    // const maxR = Math.pow(maxSize / Math.PI, 0.5)
    // const modifier = 0.75
    // return maxR * modifier
    // const modifier = 1.25 // 原本算法是假設最大泡泡為列高，但這樣子其他泡泡視覺上看起來會很小，所以刻意加大
    const modifier = 1;
    const maxR = graphicHeight / rowAmount / 2;
    return maxR * modifier;
};
const makeCallbackData = ({ dataset, activedItemId, FilteredDateList, FilteredXDataMap, FilteredItemDataMap, padding, xRangeScale, event }) => {
    if (!event || !xRangeScale) {
        return {
            dataset,
            groupData: [],
            itemData: [],
            datum: undefined,
            Date: undefined,
            xLabel: '',
            xIndex: -1,
            itemLabel: '',
            itemIndex: -1,
            rangeIndex: -1,
            clientX: -1,
            clientY: -1,
            offsetX: -1,
            offsetY: -1
        };
    }
    // 座標軸x座標
    const offsetX = Number(event.offsetX) - padding.left;
    // 資料索引
    const rangeIndex = Number(xRangeScale(offsetX) ?? 0);
    const groupData = FilteredXDataMap.get(rangeIndex) ?? [];
    let itemData = [];
    let selectedData = undefined;
    if (activedItemId) {
        itemData = FilteredItemDataMap.get(activedItemId) ?? [];
        selectedData = groupData.find(d => d.itemLabel === activedItemId);
    }
    return {
        dataset,
        groupData,
        itemData,
        datum: selectedData,
        Date: FilteredDateList[rangeIndex],
        xLabel: groupData[0].xLabel,
        xIndex: groupData[0].xIndex,
        itemLabel: selectedData ? selectedData.itemLabel : '',
        itemIndex: selectedData ? selectedData.itemIndex : -1,
        rangeIndex,
        clientX: d3.event.clientX,
        clientY: d3.event.clientY,
        offsetX: d3.event.offsetX,
        offsetY: d3.event.offsetY
    };
};
export default class ChartDateRank {
    selection;
    params = DEFAULT_CHART_DATE_RANK_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        xDates: [],
    };
    // public data: Array<Datum> = []
    transform = {
        x: 0,
        y: 0,
        k: 1
    };
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    zoom = {
        xOffset: 0,
        yOffset: 0,
        scaleExtent: {
            min: 0,
            max: Infinity
        }
    };
    coverSelection;
    graphicSelection;
    axisSelection;
    auxLineSelection = undefined;
    dateLabelSelection = undefined;
    mouseoverCallback = function () { };
    mousedownCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    zoomCallback = function () { };
    graphicRenderData = [];
    utilAuxLine;
    utilAuxDateLabel;
    axisDateRank;
    tooltip;
    filteredGraphicData = [];
    graphicData = [];
    itemContainerData = [];
    itemLabels = [];
    // private DateList: Date[] = []
    xLabels = [];
    xRangeScale; // 滑鼠座標轉換為類別索引
    xScale;
    yScale;
    axisWidth = 0;
    axisHeight = 0;
    FilteredXDataMap = new Map(); // 日期對應資料
    FilteredItemDataMap = new Map();
    filterConfig = {
        startDate: '',
        endDate: ''
    };
    filteredCondition = {
        startDate: '',
        endDate: '',
        StartDate: new Date(),
        EndDate: new Date(),
        startIndex: 0,
        endIndex: 0
    };
    OriginDateList = []; // 未篩選前的日期陣列
    FilteredDateList = [];
    dragstartX = -1; // 紀住托曳資訊
    colorScale;
    // private scaleBubbleR?: d3.ScalePower<number, number>
    maxRenderValue = 0;
    maxR = 0;
    // private avgValue = 0
    // private maxBubbleR = 0
    d3Zoom = undefined;
    constructor(el, params) {
        this.selection = el;
        this.graphicSelection = this.selection
            .append('g')
            .classed('bpchart__graphic-group', true);
        this.axisSelection = this.selection.append('g');
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        this.utilAuxLine = new UtilAuxLine(this.coverSelection);
        this.utilAuxDateLabel = new UtilAuxDateLabel(this.coverSelection);
        this.axisDateRank = new AxisDateRank(this.axisSelection, {});
        this.setCoverEvent(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.params.axisDateRank = {
            ...this.params.axisDateRank,
            padding: this.params.padding
        };
        if (this.params.tooltipAside) {
            this.tooltip = new TooltipAside(this.selection, {
                templateHtml: this.params.tooltipAside.templateHtml,
                type: this.params.tooltipAside.type ?? 'white',
                yLine: this.params.tooltipAside.yLine ?? false,
            });
        }
        this.colorScale = d3.scaleOrdinal()
            .domain(this.dataset.itemLabels)
            .range(this.params.colors);
        this.axisDateRank.setParams(this.params.axisDateRank);
        this.maxR = calcMaxR(this.axisHeight, this.params.rowAmount);
        this.coverSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = dataset.itemLabels && dataset.itemLabels.length
            ? dataset.itemLabels
            : [defaultItemLabel];
        this.colorScale = d3.scaleOrdinal()
            .domain(this.dataset.itemLabels)
            .range(this.params.colors);
        const { xLabels, DateList } = makeDateList(this.dataset.xDates, this.params.axisDateRank.xTickFormat);
        this.OriginDateList = DateList;
        this.xLabels = xLabels;
        this.graphicData = makeGraphicData({
            data: this.dataset.data,
            xDates: this.dataset.xDates,
            itemLabels: this.itemLabels,
            xLabels: this.xLabels
        });
        // 先清掉篩選值
        this.filter({
            startDate: this.OriginDateList[0],
            endDate: this.OriginDateList[this.OriginDateList.length - 1],
        });
    }
    filter(filterConfig) {
        if (!filterConfig.startDate || !filterConfig.endDate || !this.graphicData.length) {
            return;
        }
        this.filterConfig = {
            ...this.filterConfig,
            ...filterConfig
        };
        this.filteredCondition = makeFilteredCondition(this.OriginDateList, this.filterConfig);
        this.FilteredDateList = this.OriginDateList.filter((d, i) => {
            return i >= this.filteredCondition.startIndex && i <= this.filteredCondition.endIndex;
        });
        this.filteredGraphicData = this.graphicData.map(d => {
            return d.filter((_d, _i) => {
                return _i >= this.filteredCondition.startIndex && _i <= this.filteredCondition.endIndex;
            });
        });
        const filteredItemLabels = this.itemLabels.filter((_d, _i) => {
            return _i >= this.filteredCondition.startIndex && _i <= this.filteredCondition.endIndex;
        });
        this.FilteredXDataMap = makeFilteredXDataMap(this.filteredGraphicData);
        this.FilteredItemDataMap = makeFilteredItemDataMap(this.filteredGraphicData, filteredItemLabels);
        this.maxRenderValue = d3.max(this.filteredGraphicData, d => d3.max(d, _d => _d.value) || 0) || 0;
    }
    resize({ width, height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = this.width - this.params.padding.left - this.params.padding.right;
        this.axisHeight = this.height - this.params.padding.top - this.params.padding.bottom;
        this.graphicSelection
            .attr('transform', `translate(${this.params.padding.left},${this.params.padding.top})`);
        this.axisDateRank.resize({ width: this.width, height: this.height });
        this.maxR = calcMaxR(this.axisHeight, this.params.rowAmount);
    }
    render() {
        if (this.OriginDateList.length && !this.FilteredDateList.length) {
            return;
        }
        const scalePadding = 0.5;
        this.xScale = makeAxisTimeScale(this.FilteredDateList, this.axisWidth, scalePadding);
        this.yScale = makeAxisLinearScale({
            maxValue: 0,
            minValue: this.params.rowAmount,
            axisWidth: this.axisHeight,
            reverse: true
        });
        this.itemContainerData = makeItemContainerData({
            filteredGraphicData: this.filteredGraphicData,
            filteredCondition: this.filteredCondition,
            yScale: this.yScale,
            rowAmount: this.params.rowAmount,
            itemLabels: this.itemLabels
        });
        this.graphicRenderData = makeGraphicRenderData({
            data: this.graphicData,
            xScale: this.xScale,
            groupData: this.itemContainerData,
            filteredCondition: this.filteredCondition,
            rowAmount: this.params.rowAmount,
            maxValue: this.maxRenderValue,
            maxR: this.maxR
        });
        // this.setDateDataMap()
        this.axisDateRank.setDataset({
            xScale: this.xScale,
            yScale: this.yScale,
            yLabels: this.itemLabels,
            labelSeq: this.itemContainerData.map(d => d.seq),
            rowAmount: this.params.rowAmount,
            dateAmount: this.FilteredDateList.length
        });
        this.axisDateRank.render();
        const rowUpdate = this.graphicSelection
            .selectAll("g.row")
            .data(this.itemContainerData, d => d.itemLabel);
        const rowEnter = rowUpdate
            .enter()
            .append("g")
            .classed("row", true);
        let rowSelection = rowUpdate
            .merge(rowEnter);
        rowUpdate.exit()
            .remove();
        rowSelection
            .transition()
            .duration(500)
            .attr("transform", (d, i) => `translate(0, ${d.y})`);
        // -- 泡泡 --
        rowSelection.each((rowD, i, groups) => {
            this.renderRowGraphic({
                itemSelection: d3.select(groups[i]),
                itemRenderData: this.graphicRenderData[i],
                itemContainerDatum: rowD,
                colorScale: this.colorScale
            });
        });
        this.initHighlight();
        // x座標轉換日期索引比例尺
        // const axisWidth = this.width - this.params.padding!.left - this.params.padding!.right
        // const halfRange = (this.axisWidth / this.FilteredDateList.length) / 2
        // const startDomain = - halfRange
        // const endDomain = this.axisWidth + halfRange
        // this.xRangeScale = d3.scaleQuantize<number>()
        //   .domain([startDomain, endDomain])
        //   .range(this.FilteredDateList.map((d, i) => i))
        this.xRangeScale = makeAxisQuantizeScale({
            axisLabels: this.FilteredDateList,
            axisWidth: this.axisWidth,
        });
        // init zoom
        if (!this.params.zoom) {
            this.removeZoom();
            return;
        }
        const MinZoomStartDate = this.OriginDateList[0];
        const MaxZoomStartDate = addDays(this.filteredCondition.EndDate, -1);
        this.initZoom({
            ...this.zoom,
            scaleExtent: {
                min: 1 - dateDiff(this.filteredCondition.StartDate, MinZoomStartDate),
                max: 1 + dateDiff(MaxZoomStartDate, this.filteredCondition.StartDate)
            }
        });
    }
    // 設置拖拽及放大縮小
    initZoom(zoom = this.zoom) {
        if (!this.graphicRenderData.length || !this.graphicRenderData[0].length) {
            return;
        }
        this.zoom = {
            ...this.zoom,
            ...zoom
        };
        // 滑鼠滾動放大縮小
        this.selection.on('zoom', null);
        this.d3Zoom = d3.zoom()
            .scaleExtent([this.zoom.scaleExtent.min, this.zoom.scaleExtent.max])
            .on('zoom', () => {
            this.transform = {
                x: d3.event.transform.x,
                y: d3.event.transform.y,
                k: d3.event.transform.k
            };
            if (!this.graphicRenderData.length || !this.graphicRenderData[0].length) {
                return;
            }
            if (d3.event.sourceEvent.type === 'mousemove') {
                if (this.params.zoom && this.onZoomMouse) {
                    this.onZoomMouse(d3.event);
                }
            }
            else if (d3.event.sourceEvent.type === 'wheel') {
                if (this.params.zoom && this.onZoomWheel) {
                    this.onZoomWheel(d3.event);
                }
            }
        });
        this.selection.call(this.d3Zoom);
    }
    removeZoom() {
        this.d3Zoom = d3.zoom()
            .on('zoom', null);
    }
    transformZoom(transform) {
        this.transform = transform;
        // 設定 d3.event.transform 並觸發 d3.zoom().on()
        if (this.d3Zoom && this.d3Zoom.transform) {
            this.selection.call(this.d3Zoom.transform, d3.zoomIdentity
                .translate(this.transform.x, this.transform.y)
                .scale(this.transform.k));
        }
    }
    remove() {
        this.selection.remove();
    }
    select() {
        return this.selection;
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousedown') {
            this.mousedownCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        else if (actionName === 'zoom') {
            this.zoomCallback = callback;
        }
        return this;
    }
    showTooltip(eventData, event) {
        if (event && eventData && this.xScale) {
            this.tooltip.setDatum({
                data: eventData,
                // x: d3.event.clientX,
                x: eventData.clientX - eventData.offsetX + this.params.padding.left + this.xScale(eventData.Date),
                y: d3.event.clientY
            });
        }
        else {
            this.tooltip.remove();
        }
    }
    // 複蓋區域 highlight
    coverHighlight(eventData) {
        // 標示線
        if (this.params.auxLine) {
            this.renderAuxLine({
                coverSelection: this.coverSelection,
                FilteredDateList: this.FilteredDateList,
                activeRangeIndex: eventData ? eventData.rangeIndex : -1,
                xScale: this.xScale,
                padding: this.params.padding,
                axisHeight: this.axisHeight
            });
        }
        // 日期標籤
        this.renderDateLabel({
            coverSelection: this.coverSelection,
            eventData: eventData,
            xScale: this.xScale,
            padding: this.params.padding,
            axisHeight: this.axisHeight,
            xTickPadding: this.params.axisDateRank.xTickPadding
        });
    }
    makeActivedCallbackData = (activedItemId, event) => {
        return makeCallbackData({
            dataset: this.dataset,
            activedItemId: activedItemId,
            FilteredDateList: this.FilteredDateList,
            FilteredXDataMap: this.FilteredXDataMap,
            FilteredItemDataMap: this.FilteredItemDataMap,
            padding: this.params.padding,
            xRangeScale: this.xRangeScale,
            event
        });
    };
    renderAuxLine({ coverSelection, FilteredDateList, activeRangeIndex, xScale, padding, axisHeight }) {
        if (!xScale) {
            return;
        }
        const auxLineData = FilteredDateList.map((d, i) => {
            const x = (xScale(d) || 0);
            return {
                x1: x,
                x2: x,
                y1: 0,
                y2: axisHeight,
                active: activeRangeIndex == i
            };
        });
        this.utilAuxLine.setData(auxLineData);
        this.utilAuxLine.render();
    }
    renderDateLabel({ coverSelection, eventData, xScale, padding, axisHeight, xTickPadding }) {
        let data = [];
        if (eventData) {
            const defaultTickSize = 6;
            const y = axisHeight + xTickPadding + defaultTickSize;
            const x = xScale(eventData.Date);
            data = [{
                    xLabel: eventData.xLabel,
                    x,
                    y
                }];
        }
        this.utilAuxDateLabel.setData(data);
        this.utilAuxDateLabel
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(eventData?.itemLabel ?? '', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
        this.utilAuxDateLabel.render();
    }
    onZoomWheel(event) {
        // -- 計算移動索引 --
        let filterStartIndex = this.filteredCondition.startIndex;
        // let filterEndIndex = this.filterEndIndex
        const kDiff = event.transform.k - 1;
        const dateDiff = calcDateDiffByK(this.FilteredDateList.length, kDiff);
        filterStartIndex += dateDiff;
        if (filterStartIndex < 0) {
            filterStartIndex = 0;
            // 如果左邊已到底了則換右邊移動
            // @Q@ 後來發現因有 zoom最有值問題，監聽不到 zoom事件，想不出解法先放棄
            // filterEndIndex -= Math.round(dateDiff)
            // if (filterEndIndex > this.renderDataItemFiltered![0].length - 1) {
            //   filterEndIndex = this.renderDataItemFiltered![0].length - 1
            // }
        }
        else if (filterStartIndex >= this.OriginDateList.length) {
            filterStartIndex = this.OriginDateList.length - 1;
        }
        // 如和前一次資料不同則移動
        if (this.xLabels[this.filteredCondition.startIndex] != this.xLabels[filterStartIndex]) {
            // filter
            this.filter({
                startDate: this.OriginDateList[filterStartIndex],
                endDate: this.filteredCondition.endDate,
            });
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.zoomCallback(callbackData);
            // reset k
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
            this.render();
        }
    }
    // 托曳
    onZoomMouse(event) {
        if (!this.graphicRenderData.length || !this.graphicRenderData[0].length) {
            return;
        }
        // -- 托曳 --
        const dragDistance = event.sourceEvent.clientX - this.dragstartX;
        if (dragDistance == 0) {
            return;
        }
        const FilteredStartDate = this.FilteredDateList[0];
        const FilteredEndDate = this.FilteredDateList[this.FilteredDateList.length - 1];
        let startIndex = 0;
        let endIndex = 0;
        // 往右
        if (dragDistance > 0) {
            endIndex = this.filteredCondition.startIndex + this.xRangeScale(this.xScale(FilteredEndDate) - dragDistance);
            startIndex = endIndex - (this.FilteredDateList.length - 1); // 範圍內資料筆數不變
            if (startIndex < 0) {
                startIndex = 0;
                endIndex = (this.FilteredDateList.length - 1);
            }
        }
        // 往左
        else if (dragDistance < 0) {
            startIndex = this.filteredCondition.startIndex + this.xRangeScale(this.xScale(FilteredStartDate) - dragDistance);
            endIndex = startIndex + (this.FilteredDateList.length - 1);
            if (endIndex > (this.OriginDateList.length - 1)) {
                endIndex = (this.OriginDateList.length - 1);
                startIndex = endIndex - (this.FilteredDateList.length - 1);
            }
        }
        // 如和前一次資料不同則移動
        if (this.filteredCondition.startIndex != startIndex) {
            this.dragstartX = event.sourceEvent.clientX; // 下一次的起始位置
            // filter
            this.filter({
                startDate: this.OriginDateList[startIndex],
                endDate: this.OriginDateList[endIndex],
            });
            this.render();
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.zoomCallback(callbackData);
        }
    }
    setCoverEvent(selection) {
        selection
            .on('mousedown', (d) => {
            this.dragstartX = d3.event.clientX;
        })
            .on('mouseover', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(callbackData);
            // this.showTooltip(callbackData, d3.event)
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(callbackData);
            // this.showTooltip(callbackData, d3.event)
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            this.coverHighlight(undefined);
            this.tooltip.remove();
        });
    }
}
