export default {
    data: [
        {
            itemLabel: '讚',
            value: 120
        },
        {
            itemLabel: '大心',
            value: 55
        },
        {
            itemLabel: '哈',
            value: 45
        },
        {
            itemLabel: '哇',
            value: 30
        },
        {
            itemLabel: '嗚',
            value: 13
        },
        {
            itemLabel: '怒',
            value: 12
        },
    ],
    itemLabels: ['讚', '大心', '哈', '哇', '嗚', '怒'],
    arcLabels: ['讚', '大心', '哈', '哇', '嗚', '怒']
};
