export default {
    "data": {
        "dataset": {
            "data": [
                [
                    {
                        "value": 55
                    },
                    {
                        "value": 80
                    },
                    {
                        "value": 50
                    },
                    {
                        "value": 110
                    },
                    {
                        "value": 150
                    }
                ],
                [
                    {
                        "value": 35
                    },
                    {
                        "value": 40
                    },
                    {
                        "value": 15
                    },
                    {
                        "value": 65
                    },
                    {
                        "value": 120
                    }
                ]
            ],
            "xLabels": [
                "2016",
                "2017",
                "2018",
                "2019",
                "2020"
            ],
            "itemLabels": [
                "進口",
                "出口"
            ]
        },
        "groupData": [
            {
                "value": 55,
                "id": "進口_2016",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 66,
                "y": 260.66666666666663
            },
            {
                "value": 35,
                "id": "出口_2016",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 66,
                "y": 311.3333333333333
            }
        ],
        "itemData": [],
        "xLabel": "2016",
        "xIndex": 0,
        "itemLabel": "",
        "itemIndex": -1,
        "rangeIndex": 0,
        "clientX": 776,
        "clientY": 501,
        "offsetX": 105,
        "offsetY": 8
    },
    "x": 807,
    "y": 501
};
