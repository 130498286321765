import * as d3 from 'd3';
import ChartDateColumn from './ChartDateColumn';
import GraphicColumnLine from '../graphicColumnLine/GraphicColumnLine';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_DATE_COLUMN_LINE_PARAMS } from './defaults';
export default class ChartDateColumnLine extends ChartDateColumn {
    params = DEFAULT_CHART_DATE_COLUMN_LINE_PARAMS;
    line;
    dot;
    constructor(selection, params) {
        super(selection, params);
        this.line = new GraphicColumnLine(this.graphicGSelection, {});
        this.dot = new GraphicDot(this.coverSelection, {});
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicColumnLine: {
                ...this.params.graphicColumnLine,
                ...params.graphicColumnLine,
            },
            // null不顯示
            graphicDot: params.graphicDot === null ? null : {
                ...this.params.graphicDot,
                ...params.graphicDot
            }
        };
        this.line.setParams({
            // lineType: this.params.graphicColumnLine.lineType,
            // lineCurve: this.params.graphicColumnLine.lineCurve,
            ...this.params.graphicColumnLine,
            colors: this.params.colors,
            // axisWidth: this.axisWidth,
            // axisHeight: this.axisHeight,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        });
        this.line
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot.render();
            }
        });
        this.dot.setParams({
            ...this.params.graphicDot,
            colors: this.params.colors,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        });
        this.dot
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line.render();
            }
        });
    }
    setGraphicData({ renderDataItemFiltered, itemLabels, xLabels, yScale }) {
        this.line.setDataset({
            data: renderDataItemFiltered,
            itemLabels,
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: yScale(0)
        });
    }
    renderGraphic() {
        this.line.render();
    }
    renderGraphicCover({ coverSelection, filteredData, xScale, yScale, itemLabels }) {
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            const dotData = filteredData
                .map(d => {
                return d.map(_d => {
                    return {
                        ..._d,
                        x: xScale(_d.Date),
                        y: yScale(_d.value)
                    };
                });
            })
                .flat();
            this.dot.setDataset({
                data: dotData,
                itemLabels
            });
            this.dot.render();
        }
    }
    renderGraphicCoverHighlight({ coverSelection, eventData, xScale, yScale, itemLabels }) {
        // 非顯示全部圓點時 highlight圓點
        if (this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            const dotData = eventData
                ? eventData.groupData.map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.Date),
                        y: yScale(d.value)
                    };
                })
                : [];
            this.dot.setDataset({
                data: dotData,
                itemLabels
            });
            this.dot.render();
        }
    }
}
