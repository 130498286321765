import * as d3 from 'd3';
import { DEFAULT_GRAPHIC_ROW_BAR_LAYOUT_PARAMS } from './defaults';
import { makeColorScale } from '../moduleUtils';
import { UtilGraphicBarLabel } from '../utilGraphicBarLabel';
// const _d3: any = d3
const makeItemDataMap = (data, itemLabels) => {
    const ItemDataMap = new Map();
    itemLabels.forEach((d, i) => {
        ItemDataMap.set(d, data[i]);
    });
    return ItemDataMap;
};
const makeYDataMap = (data, yLabels) => {
    const YDataMap = new Map();
    data.forEach((d, i) => {
        d.forEach((_d, _i) => {
            const yData = YDataMap.get(yLabels[_i]) ?? [];
            yData.push(_d);
            YDataMap.set(yLabels[_i], yData);
        });
    });
    return YDataMap;
};
const makeGroupLayout = ({ groupLayout, yLabels, itemLabels, axisHeight, barPadding, barGroupPadding }) => {
    if (!groupLayout.length) {
        // 預設值（只有一個堆疊bar）
        return [{
                stackAmount: itemLabels.length,
                barWidth: calcBarWidth({
                    axisHeight,
                    groupAmount: yLabels.length,
                    barAmountOfGroup: 1,
                    barPadding,
                    barGroupPadding
                }),
                barR: false,
                datumIndexList: itemLabels.map((d, i) => i)
            }];
    }
    // 已設定的bar寬度
    const fixedWidth = groupLayout.reduce((prev, current) => {
        let barWidth = 0;
        if (current.barWidth) {
            barWidth = current.barWidth + barPadding;
        }
        return prev + barWidth;
    }, 0);
    // 已設定bar寬度的數量
    const fixedWidthAmount = groupLayout.reduce((prev, current) => {
        return prev + (current.barWidth ? 1 : 0);
    }, 0);
    const fixedSumWidth = fixedWidth * yLabels.length;
    // 未設定寬度的bar
    const barWidth = calcBarWidth({
        axisHeight: axisHeight - fixedSumWidth,
        groupAmount: yLabels.length,
        barAmountOfGroup: groupLayout.length - fixedWidthAmount,
        barPadding,
        barGroupPadding
    });
    let prevIndex = -1;
    return groupLayout.map((d, i) => {
        const stackAmount = d.stackAmount ?? 1;
        let datumIndexList = [];
        prevIndex += 1;
        for (let _i = prevIndex; _i < prevIndex + stackAmount; _i++) {
            datumIndexList.push(_i);
        }
        // 記錄最後一筆的 index
        prevIndex = datumIndexList[datumIndexList.length - 1];
        return {
            stackAmount,
            barWidth: d.barWidth ?? barWidth,
            barR: d.barR ?? false,
            datumIndexList
        };
    });
};
const makeGraphicData = ({ YDataMap, yLabels, axisHeight, groupLayout, barGroupPadding, barPadding }) => {
    let groupHeight = 0;
    // 固定群組高度
    if (groupLayout.every(d => d.barWidth)) {
        groupHeight = groupLayout.reduce((prev, current) => {
            return prev + current.barWidth;
        }, 0);
        groupHeight += barPadding * (groupLayout.length - 1);
    }
    // 響應群組高度
    else {
        groupHeight = calcBarGroupWidth({
            axisHeight,
            groupAmount: yLabels.length,
            barGroupPadding
        });
    }
    // 第一層陣列資料
    return yLabels.map((yLabel, yIndex) => {
        const yData = YDataMap.get(yLabel) ?? [];
        let graphicData = [];
        // 第一個bar位置
        let prevBarY = -groupHeight / 2;
        // 第二層陣列資料 (datum)
        groupLayout.forEach((stackLayout, stackIndex) => {
            let prevBarX = 0;
            const barY = prevBarY;
            stackLayout.datumIndexList.forEach((datumIndex) => {
                const datum = yData[datumIndex];
                // const barX = prevBarX + datum.x
                graphicData.push({
                    ...datum,
                    sourceIndex: [datumIndex, yIndex],
                    barX: prevBarX,
                    barY: barY + stackLayout.barWidth / 2,
                    groupY: datum.y,
                    barWidth: stackLayout.barWidth,
                    barR: stackLayout.barR == true ? stackLayout.barWidth / 2
                        : typeof stackLayout.barR == 'number' ? stackLayout.barR
                            : 0,
                    width: datum.x
                });
                // 累加bar區塊(datum)的高度
                prevBarX += datum.x;
            });
            // 累加bar的距離
            prevBarY += stackLayout.barWidth + barPadding;
        });
        return graphicData;
    });
};
const makeLabelData = (data, params) => {
    let labelData = [];
    data.forEach((d, i) => {
        d.forEach((_d, _i) => {
            labelData.push({
                id: _d.id,
                data: _d,
                barX0: _d.barX,
                barX1: _d.barX + _d.width,
                barY0: _d.groupY + _d.barY - _d.barWidth / 2,
                barY1: _d.groupY + _d.barY + _d.barWidth / 2,
                position: params.labelPositionMethod(_d, _d.sourceIndex),
                fontSize: params.labelFontSizeMethod(_d, _d.sourceIndex),
                color: params.labelColorMethod(_d, _d.sourceIndex),
                style: params.labelStyleMethod(_d, _d.sourceIndex),
                text: params.labelTextMethod(_d, _d.sourceIndex),
            });
        });
    });
    return labelData;
};
// const makeColorScale = (itemLabels: string[], colors: string[]) => {
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
// const makeBarScale = (barWidth: number, itemLabels: string[], params: Params) => {
//   const barHalfWidth = barWidth! / 2
//   const barGroupWidth = barWidth * itemLabels.length + params.barPadding! * itemLabels.length
//   return d3.scalePoint()
//     .domain(itemLabels)
//     .range([-barGroupWidth / 2 + barHalfWidth, barGroupWidth / 2 - barHalfWidth])
// }
const makeCallbackData = ({ datum, YDataMap, ItemDataMap, event }) => {
    return {
        groupData: YDataMap.get(datum.yLabel),
        itemData: ItemDataMap.get(datum.itemLabel),
        datum,
        yLabel: datum.yLabel,
        yIndex: datum.yIndex,
        itemLabel: datum.itemLabel,
        itemIndex: datum.itemIndex,
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY
    };
};
const calcBarGroupWidth = ({ axisHeight, groupAmount, barGroupPadding = 0 }) => {
    const width = axisHeight / groupAmount - barGroupPadding;
    return width > 1 ? width : 1;
};
const calcBarWidth = ({ axisHeight, groupAmount, barAmountOfGroup, barPadding = 0, barGroupPadding = 0 }) => {
    const barGroupWidth = calcBarGroupWidth({ axisHeight, groupAmount, barGroupPadding });
    const width = barGroupWidth / barAmountOfGroup - barPadding;
    return width > 1 ? width : 1;
};
const calcDelayDuration = (barAmount, totalDuration = 400) => {
    return totalDuration / barAmount;
};
export default class GraphicRowBarLayout {
    selection;
    params = DEFAULT_GRAPHIC_ROW_BAR_LAYOUT_PARAMS;
    dataset = {
        data: [],
        itemLabels: [],
        yLabels: [],
        axisHeight: 0,
        // axisHeight: 0
        zeroX: 0
    };
    utilGraphicBarLabel;
    graphicData = [];
    groupLayout = [];
    graphicBoxSelection;
    labelBoxSelection;
    graphicGroupSelection = undefined;
    graphicBarSelection = undefined;
    defsSelection;
    itemLabels = [];
    colorScale;
    ItemDataMap = new Map();
    YDataMap = new Map();
    axisHeight = 0;
    //   private axisWidth = 0
    // private barWidth = 0
    delayDuration = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
        this.graphicBoxSelection = this.selection.append('g');
        this.labelBoxSelection = this.selection.append('g');
        this.defsSelection = this.selection.append('defs');
        this.utilGraphicBarLabel = new UtilGraphicBarLabel(this.labelBoxSelection, {});
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.itemLabels = [];
        this.utilGraphicBarLabel.setParams({
            barDirection: 'right',
            positionPadding: this.params.labelPadding
        });
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.itemLabels = this.dataset.itemLabels;
        this.ItemDataMap = makeItemDataMap(this.dataset.data, this.dataset.itemLabels);
        this.YDataMap = makeYDataMap(this.dataset.data, this.dataset.yLabels);
        this.delayDuration = calcDelayDuration(this.dataset.yLabels.length);
    }
    render() {
        // 未 setDataset 則不執行
        if (!this.itemLabels.length) {
            return;
        }
        this.colorScale = makeColorScale(this.itemLabels, this.params.colors);
        this.axisHeight = this.dataset.axisHeight;
        this.groupLayout = makeGroupLayout({
            groupLayout: this.params.groupLayout,
            yLabels: this.dataset.yLabels,
            itemLabels: this.dataset.itemLabels,
            axisHeight: this.axisHeight,
            barPadding: this.params.barPadding,
            barGroupPadding: this.params.barGroupPadding
        });
        // this.barWidth = this.params.barWidth ? this.params.barWidth
        //   : calcBarWidth({
        //     axisWidth: this.axisHeight,
        //     groupAmount: this.dataset.yLabels.length,
        //     barGroupPadding: this.params.barGroupPadding ?? 0
        //   })
        // 群組內的 bar 比例尺
        // const itemScale: d3.ScalePoint<string> = makeBarScale(this.barWidth, this.dataset.itemLabels, this.params)
        // 繪圖
        this.graphicData = makeGraphicData({
            YDataMap: this.YDataMap,
            yLabels: this.dataset.yLabels,
            axisHeight: this.axisHeight,
            groupLayout: this.groupLayout,
            barPadding: this.params.barPadding,
            barGroupPadding: this.params.barGroupPadding
        });
        this.renderGraphic({
            selection: this.graphicBoxSelection,
            graphicData: this.graphicData,
            yLabels: this.dataset.yLabels,
            colorScale: this.colorScale,
            // itemScale,
            params: this.params
        });
        // 綁定事件
        this.graphicBarSelection
            .on('click', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                YDataMap: this.YDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i, n) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                YDataMap: this.YDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                if (this.params.highlightTarget === 'group') {
                    this.highlight(this.graphicBarSelection, callbackData.yLabel, undefined, undefined);
                }
                else if (this.params.highlightTarget === 'item') {
                    this.highlight(this.graphicBarSelection, undefined, callbackData.itemLabel, undefined);
                }
                else if (this.params.highlightTarget === 'datum') {
                    this.highlight(this.graphicBarSelection, undefined, undefined, callbackData.datum && callbackData.datum.id);
                }
            }
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d, i) => {
            // d3.event.stopPropagation()
            const callbackData = makeCallbackData({
                datum: d,
                YDataMap: this.YDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget != undefined && this.params.highlightTarget != 'none') {
                this.removeHighlight(this.graphicBarSelection);
            }
            const callbackData = makeCallbackData({
                datum: d,
                YDataMap: this.YDataMap,
                ItemDataMap: this.ItemDataMap,
                event: d3.event
            });
            this.initHighlight();
            this.mouseoutCallback(callbackData);
        });
        // label
        this.utilGraphicBarLabel.setData(makeLabelData(this.graphicData, this.params));
        this.utilGraphicBarLabel.render();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    remove() {
        this.selection.remove();
    }
    // private setLinearGradient ({ defsSelection, itemLabels, colorScale }: {
    //   defsSelection: d3.Selection<SVGDefsElement, Datum, any, any>
    //   itemLabels: string[]
    //   colorScale: d3.ScaleOrdinal<string, string>
    // }) {
    //   const linearGradientUpdate = defsSelection!
    //       .selectAll<SVGLinearGradientElement, string>('linearGradient')
    //       .data(itemLabels, d => d)
    //     const linearGradientEnter = linearGradientUpdate
    //       .enter()
    //       .append('linearGradient')
    //       .attr('x1', '0%')
    //       .attr('x2', '0%')
    //       .attr('y1', '0%')
    //       .attr('y2', '100%')
    //       .attr('spreadMethod', 'pad')
    //     linearGradientUpdate.merge(linearGradientEnter)
    //       .attr('id', (d, i) => `bpchart__lineargradient__${d}`)
    //       .html((d, i) => `
    //         <stop offset="0%"   stop-color="${colorScale(d)}" stop-opacity="1"/>
    //         <stop offset="100%" stop-color="${colorScale(d)}" stop-opacity="0"/>
    //       `)
    //     linearGradientUpdate.exit().remove()
    // }
    renderGraphic({ selection, graphicData, yLabels, colorScale, params }) {
        // if (this.barWidth <= 0) {
        //   return
        // }
        const update = selection
            .selectAll('g.bpchart__bar-g')
            .data(graphicData, (d, i) => yLabels[i]);
        const enter = update.enter()
            .append('g')
            .classed('bpchart__bar-g', true)
            .attr('cursor', 'pointer');
        update.exit().remove();
        this.graphicGroupSelection = update.merge(enter);
        enter
            .attr('transform', (d, i) => `translate(0, ${d[0] ? d[0].groupY : 0})`);
        update
            .transition()
            .duration(200)
            .attr('transform', (d, i) => `translate(0, ${d[0] ? d[0].groupY : 0})`);
        this.graphicGroupSelection
            .each((d, i, g) => {
            const barUpdate = d3.select(g[i])
                .selectAll('g')
                .data(d, _d => _d.id);
            const barEnter = barUpdate
                .enter()
                .append('g')
                .classed('bpchart__bar', true);
            barEnter
                .append('rect')
                .attr('rx', d => d.barR)
                .attr('transform', d => `translate(0, ${-d.barWidth / 2})`)
                .attr('x', this.dataset.zeroX)
                .attr('y', d => d.barY)
                .attr('width', 0)
                .attr('height', d => d.barWidth);
            const rect = barUpdate.merge(barEnter)
                .select('rect');
            rect
                .attr('fill', d => colorScale(d.itemLabel))
                .transition()
                .duration(this.params.enterDuration)
                .ease(d3.easeElastic)
                .delay((d, i) => d.yIndex * this.delayDuration)
                .attr('transform', d => `translate(0, ${-d.barWidth / 2})`)
                .attr('x', d => d.barX)
                .attr('y', d => d.barY)
                .attr('width', d => d.width)
                .attr('height', d => d.barWidth)
                .on('end', () => this.initHighlight());
            barUpdate.exit().remove();
        });
        this.graphicBarSelection = this.graphicGroupSelection.selectAll('g.bpchart__bar');
    }
    initHighlight() {
        if (!this.graphicBarSelection) {
            return;
        }
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId || this.params.highlightGroupId) {
            this.highlight(this.graphicBarSelection, this.params.highlightGroupId, this.params.highlightItemId, this.params.highlightDatumId);
        }
    }
    highlight(graphicBarSelection, groupId, itemId, datumId) {
        if (!graphicBarSelection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .flat()
                .filter(d => d.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        if (groupId) {
            const _ids = this.dataset.data
                .flat()
                .filter(d => d.yLabel === groupId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        graphicBarSelection
            .each((d, i, n) => {
            if (ids.includes(d.id)) {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(graphicBarSelection) {
        // highlight
        if (this.params.highlightDatumId || this.params.highlightItemId || this.params.highlightGroupId) {
            this.highlight(graphicBarSelection, this.params.highlightGroupId, this.params.highlightItemId, this.params.highlightDatumId);
        }
        else {
            graphicBarSelection
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }
}
