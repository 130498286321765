import * as d3 from 'd3';
import ChartArc from './ChartArc';
import { LabelArcPie } from '../labelArcPie';
import { GraphicArcPie } from '../graphicArcPie';
import { DEFAULT_CHART_ARC_PIE_PARAMS } from './defaults';
// const defaultItemLabel = '__DEFAULT__'
const makePieRenderData = ({ data, startAngle, endAngle, itemLabels, arcLabels }) => {
    let pie = d3.pie()
        .startAngle(startAngle)
        // .endAngle(startAngle + (endAngle - startAngle) * t)
        .endAngle(endAngle)
        .value((d) => {
        return d.value;
    })
        .sort(null); // 不要排序
    // .sort((a: any, b: any) => {
    //   return b.value - a.value
    // })
    // .sort(d3.ascending)
    const pieData = pie(data);
    return pieData.map((d, i) => {
        const itemLabel = d.data.itemLabel;
        return {
            ...d,
            itemIndex: itemLabels.indexOf(itemLabel),
            itemLabel,
            id: arcLabels[i],
        };
    });
};
const makeGraphicCallbackData = (cb, dataset, event) => {
    const arcIndex = cb.dataset.data.findIndex(d => cb.datum && d.id === cb.datum.id);
    return {
        dataset,
        groupData: cb.groupData,
        itemData: cb.itemData,
        datum: cb.datum,
        itemIndex: cb.itemIndex,
        itemLabel: cb.itemLabel,
        arcIndex,
        arcLabel: dataset.arcLabels[arcIndex],
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
    };
};
const makeLabelCallbackData = (cb, dataset, event) => {
    const arcIndex = cb.dataset.data.findIndex((d) => cb.datum && d.data.id === cb.datum.data.id);
    return {
        dataset,
        groupData: cb.groupData,
        itemData: cb.itemData,
        datum: cb.datum,
        itemIndex: cb.itemIndex,
        itemLabel: cb.itemLabel,
        arcIndex,
        arcLabel: dataset.arcLabels[arcIndex],
        clientX: event.clientX,
        clientY: event.clientY,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
    };
};
const makeTextData = ({ eventCallback, eventName, t, centerText, centerTextAttrs, centerTextStyles }) => {
    const callbackText = centerText(eventCallback, eventName, t);
    return callbackText.map((d, i) => {
        return {
            text: d,
            attr: centerTextAttrs[i],
            style: centerTextStyles[i]
        };
    });
};
export default class ChartArcPie extends ChartArc {
    params = DEFAULT_CHART_ARC_PIE_PARAMS;
    pie;
    label;
    pieSelection;
    labelSelection;
    centerTextSelection;
    constructor(selection, params) {
        super(selection, params);
        this.pieSelection = this.graphicGSelection.append('g');
        this.labelSelection = this.graphicGSelection.append('g');
        this.centerTextSelection = this.graphicGSelection.append('g');
        this.pie = new GraphicArcPie(this.pieSelection, {});
        this.label = new LabelArcPie(this.labelSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicArcPie: {
                ...this.params.graphicArcPie,
                ...params.graphicArcPie,
            },
            labelArcPie: {
                ...this.params.labelArcPie,
                ...params.labelArcPie,
            }
        };
        this.params.graphicArcPie = {
            ...this.params.graphicArcPie,
            colors: this.params.colors,
            startAngle: this.params.startAngle,
            endAngle: this.params.endAngle,
            highlightTarget: this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId
        };
        this.params.labelArcPie = {
            ...this.params.labelArcPie,
            colors: this.params.colors,
            startAngle: this.params.startAngle,
            endAngle: this.params.endAngle,
            // innerRadius: this.params.graphicArcPie.innerRadius,
            outerRadius: this.params.graphicArcPie.outerRadius,
            highlightTarget: this.params.highlightTarget,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId
        };
        this.pie.setParams(this.params.graphicArcPie);
        this.pie
            .on('enterDuration', (d, t) => {
            const textData = makeTextData({
                eventCallback: d,
                eventName: 'enterDuration',
                t: t,
                centerText: this.params.centerText,
                centerTextAttrs: this.params.centerTextAttrs,
                centerTextStyles: this.params.centerTextStyles
            });
            this.renderText(this.centerTextSelection, textData);
        })
            .on('click', (d) => {
            const callbackData = makeGraphicCallbackData(d, this.dataset, d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = makeGraphicCallbackData(d, this.dataset, d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            this.label.setParams({
                highlightItemId: this.params.highlightTarget === 'item' ? callbackData.itemLabel : undefined,
                highlightDatumId: this.params.highlightTarget === 'datum' ? callbackData.datum.id : undefined
            });
            this.label.render();
            const textData = makeTextData({
                eventCallback: callbackData,
                eventName: 'mouseover',
                t: 1,
                centerText: this.params.centerText,
                centerTextAttrs: this.params.centerTextAttrs,
                centerTextStyles: this.params.centerTextStyles
            });
            this.renderText(this.centerTextSelection, textData);
        })
            .on('mousemove', (d) => {
            const callbackData = makeGraphicCallbackData(d, this.dataset, d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = makeGraphicCallbackData(d, this.dataset, d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            this.label.setParams({
                highlightItemId: this.params.labelArcPie.highlightItemId,
                highlightDatumId: this.params.labelArcPie.highlightDatumId
            });
            this.label.render();
            const textData = makeTextData({
                eventCallback: callbackData,
                eventName: 'mouseout',
                t: 1,
                centerText: this.params.centerText,
                centerTextAttrs: this.params.centerTextAttrs,
                centerTextStyles: this.params.centerTextStyles
            });
            this.renderText(this.centerTextSelection, textData);
        });
        this.label.setParams(this.params.labelArcPie);
        this.label
            .on('click', (d) => {
            const callbackData = makeLabelCallbackData(d, this.dataset, d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = makeLabelCallbackData(d, this.dataset, d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            this.pie.setParams({
                highlightItemId: this.params.highlightTarget === 'item' ? callbackData.itemLabel : undefined,
                highlightDatumId: this.params.highlightTarget === 'datum' ? callbackData.datum.id : undefined
            });
            this.pie.render();
        })
            .on('mousemove', (d) => {
            const callbackData = makeLabelCallbackData(d, this.dataset, d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = makeLabelCallbackData(d, this.dataset, d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            this.pie.setParams({
                highlightItemId: this.params.graphicArcPie.highlightItemId,
                highlightDatumId: this.params.graphicArcPie.highlightDatumId
            });
            this.pie.render();
        });
    }
    setGraphicData({ graphicData, itemLabels, arcLabels, shorterSideWidth }) {
        const pieData = makePieRenderData({
            data: graphicData,
            startAngle: this.params.startAngle,
            endAngle: this.params.endAngle,
            itemLabels: itemLabels,
            arcLabels: arcLabels
        });
        // console.log(this.params)
        // const itemLabels = dataset.itemLabels && dataset.itemLabels.length
        //   ? dataset.itemLabels
        //   : [defaultItemLabel]
        // const pieData: GraphicArcPieDatum[] = dataset.data.map((d, i) => {
        //   const itemLabel = d.itemLabel ?? defaultItemLabel // 如資料裡無itemLabel則給一個預設值
        //   return {
        //     ...d,
        //     itemIndex: itemLabels.indexOf(itemLabel),
        //     itemLabel,
        //     id: dataset.arcLabels[i],        
        //   }
        // })
        this.pie.setDataset({
            data: pieData,
            itemLabels: itemLabels,
            axisWidth: shorterSideWidth
        });
        this.label.setDataset({
            data: pieData,
            itemLabels: itemLabels,
            arcLabels: arcLabels,
            axisWidth: shorterSideWidth
        });
    }
    renderGraphic() {
        this.pie.render();
        this.label.render();
    }
    renderText(selection, data) {
        const textUpdate = selection
            .selectAll('text.bpchart__text')
            .data(data);
        const textEnter = textUpdate.enter()
            .append('text')
            .classed('bpchart__text', true);
        const text = textUpdate.merge(textEnter);
        text
            .each((d, i, g) => {
            const t = d3.select(g[i])
                .text(d.text);
            Object.keys(d.attr)
                .forEach(key => {
                t.attr(key, d.attr[key]);
            });
            Object.keys(d.style)
                .forEach(key => {
                t.style(key, d.style[key]);
            });
        });
        textUpdate.exit().remove();
    }
}
