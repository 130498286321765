import * as d3 from 'd3';
import { DEFAULT_UTIL_GRAPHIC_BAR_LABEL_PARAMS } from './defaults';
import { measureTextWidth } from '../utils';
const makeRenderData = (data, params) => {
    // 先計算相關資料最後再算座標
    const _renderData = data.map(d => {
        const width = measureTextWidth(d.text, d.fontSize) + 2; // +2是為了避免計算上的誤差以及過於接近
        const height = d.fontSize;
        let positionX = 0;
        let positionY = 0;
        let textAnchor = 'middle';
        let dominantBaseline = 'central';
        let boundaryX0 = 0;
        let boundaryX1 = 0;
        let boundaryY0 = 0;
        let boundaryY1 = 0;
        if (d.position === 'left') {
            positionX = d.barX0 - params.positionPadding;
            positionY = (d.barY0 + d.barY1) / 2;
            textAnchor = 'end';
            dominantBaseline = 'central';
            boundaryX0 = positionX - width;
            boundaryX1 = positionX;
            boundaryY0 = positionY - height / 2;
            boundaryY1 = positionY + height / 2;
        }
        else if (d.position === 'right') {
            positionX = d.barX1 + params.positionPadding;
            positionY = (d.barY0 + d.barY1) / 2;
            textAnchor = 'start';
            dominantBaseline = 'central';
            boundaryX0 = positionX;
            boundaryX1 = positionX + width;
            boundaryY0 = positionY - height / 2;
            boundaryY1 = positionY + height / 2;
        }
        else if (d.position === 'top') {
            positionX = (d.barX0 + d.barX1) / 2;
            positionY = d.barY0 - params.positionPadding;
            textAnchor = 'middle';
            dominantBaseline = 'auto';
            boundaryX0 = positionX - width / 2;
            boundaryX1 = positionX + width / 2;
            boundaryY0 = positionY - height;
            boundaryY1 = positionY;
        }
        else if (d.position === 'bottom') {
            positionX = (d.barX0 + d.barX1) / 2;
            positionY = d.barY1 + params.positionPadding;
            textAnchor = 'middle';
            dominantBaseline = 'hanging';
            boundaryX0 = positionX - width / 2;
            boundaryX1 = positionX + width / 2;
            boundaryY0 = positionY;
            boundaryY1 = positionY + height;
        }
        else if (d.position === 'center') {
            positionX = (d.barX0 + d.barX1) / 2;
            positionY = (d.barY0 + d.barY1) / 2;
            textAnchor = 'middle';
            dominantBaseline = 'central';
            boundaryX0 = positionX - width / 2;
            boundaryX1 = positionX + width / 2;
            boundaryY0 = positionY;
            boundaryY1 = positionY + height;
        }
        return {
            ...d,
            width,
            height,
            boundaryX0,
            boundaryX1,
            boundaryY0,
            boundaryY1,
            positionX,
            positionY,
            textAnchor,
            dominantBaseline,
            // -- 先不計算 --
            renderX: 0,
            renderY: 0,
            offsetX: 0,
            offsetY: 0
        };
    });
    const sortedRenderData = Object.assign([], _renderData)
        .sort((a, b) => {
        if (params.barDirection === 'left') {
            return b.positionX - a.positionX;
        }
        else if (params.barDirection === 'right') {
            return a.positionX - b.positionX;
        }
        else if (params.barDirection === 'up') {
            return b.positionY - a.positionY;
        }
        else if (params.barDirection === 'down') {
            return a.positionY - b.positionY;
        }
        return a.positionX - b.positionX;
    });
    // 取得重疊的資料
    const getOverlapData = (datum, sortedRenderData) => {
        return sortedRenderData.filter(d => {
            return (
            // x重疊
            (d.boundaryX0 >= datum.boundaryX0 && d.boundaryX0 <= datum.boundaryX1)
                || (d.boundaryX1 >= datum.boundaryX0 && d.boundaryX1 <= datum.boundaryX1))
                && (
                // y重疊
                (d.boundaryY0 >= datum.boundaryY0 && d.boundaryY0 <= datum.boundaryY1)
                    || (d.boundaryY1 >= datum.boundaryY0 && d.boundaryY1 <= datum.boundaryY1));
        });
    };
    const renderData = [];
    sortedRenderData.forEach(d => {
        let renderX = d.positionX;
        let renderY = d.positionY;
        // -- 重疊資料偏移 --
        const overlapData = getOverlapData(d, renderData);
        let offsetOverlapX = 0;
        let offsetOverlapY = 0;
        if (overlapData.length) {
            if (params.barDirection === 'left') {
                const minBoundaryX = overlapData.reduce((prev, current) => {
                    return current.boundaryX0 < prev ? current.boundaryX0 : prev;
                }, Infinity);
                // 右側x超出重疊資料中最小x的距離
                offsetOverlapX = minBoundaryX - d.boundaryX1;
            }
            else if (params.barDirection === 'right') {
                const maxBoundaryX = overlapData.reduce((prev, current) => {
                    return current.boundaryX1 > prev ? current.boundaryX1 : prev;
                }, -Infinity);
                // 左側x小於重疊資料中最大x的距離
                offsetOverlapX = maxBoundaryX - d.boundaryX0;
            }
            else if (params.barDirection === 'up') {
                const minBoundaryY = overlapData.reduce((prev, current) => {
                    return current.boundaryY0 < prev ? current.boundaryY0 : prev;
                }, Infinity);
                // 下方y超出重疊資料中最小y的距離
                offsetOverlapY = minBoundaryY - d.boundaryY1;
            }
            else if (params.barDirection === 'down') {
                const maxBoundaryY = overlapData.reduce((prev, current) => {
                    return current.boundaryY1 > prev ? current.boundaryY1 : prev;
                }, Infinity);
                // 上方y小於重疊資料中最大y的距離
                offsetOverlapY = maxBoundaryY - d.boundaryY0;
            }
        }
        // -- 超出bar邊界修正 --
        let offsetBarX = 0;
        let offsetBarY = 0;
        if (params.barDirection === 'left'
            && (d.position === 'top' || d.position === 'bottom' || d.position === 'center')
            && d.boundaryX1 > d.barX1) {
            // 右側x超出bar右邊邊界的距離
            offsetBarX = d.barX1 - d.boundaryX1;
        }
        else if (params.barDirection === 'right'
            && (d.position === 'top' || d.position === 'bottom' || d.position === 'center')
            && d.boundaryX0 < d.barX0) {
            // 左側x超出bar左邊邊界的距離
            offsetBarX = d.barX0 - d.boundaryX0;
        }
        else if (params.barDirection === 'up'
            && (d.position === 'left' || d.position === 'right' || d.position === 'center')
            && d.boundaryY1 > d.barY1) {
            // 下方y超出bar下方邊界的距離
            offsetBarY = d.barY1 - d.boundaryY1;
        }
        else if (params.barDirection === 'down'
            && (d.position === 'left' || d.position === 'right' || d.position === 'center')
            && d.boundaryY0 < d.barY0) {
            // 上方y小於bar上方邊界的距離
            offsetBarY = d.barY0 - d.boundaryY0;
        }
        // -- 偏移修正（依偏移較大的） --
        const offsetX = Math.abs(offsetOverlapX) > Math.abs(offsetBarX) ? offsetOverlapX : offsetBarX;
        const offsetY = Math.abs(offsetOverlapY) > Math.abs(offsetBarY) ? offsetOverlapY : offsetBarY;
        // renderX += offsetX
        // renderY += offsetY
        renderData.push({
            ...d,
            boundaryX0: d.boundaryX0 + offsetX,
            boundaryX1: d.boundaryX1 + offsetX,
            boundaryY0: d.boundaryY0 + offsetY,
            boundaryY1: d.boundaryY1 + offsetY,
            renderX,
            renderY,
            offsetX,
            offsetY
        });
    });
    return renderData;
};
export default class UtilGraphicBarLabel {
    selection;
    data = [];
    params = DEFAULT_UTIL_GRAPHIC_BAR_LABEL_PARAMS;
    labelGSelection;
    labelSelection;
    renderData = [];
    constructor(selection, params) {
        this.selection = selection;
        this.labelGSelection = this.selection.append('g').style('pointer-events', 'none');
    }
    remove() {
        this.selection.remove();
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setData(data) {
        this.data = data;
        this.renderData = makeRenderData(data, this.params);
    }
    render() {
        const update = this.labelGSelection
            .selectAll('text')
            .data(this.renderData);
        const enter = update
            .enter()
            .append('text');
        update.exit().remove();
        this.labelSelection = update.merge(enter)
            .attr('text-anchor', d => d.textAnchor) // start | middle | end
            .attr('dominant-baseline', d => d.dominantBaseline)
            .attr('font-size', (d, i) => d.fontSize)
            .attr('fill', (d, i) => d.color)
            .attr('style', d => d.style)
            .text(d => d.text);
        enter
            .attr('x', d => {
            if (this.params.barDirection === 'left' || this.params.barDirection === 'right') {
                return 0; // @Q@ 暫先一率從左邊出發
            }
            else {
                return d.positionX;
            }
        })
            .attr('y', d => {
            if (this.params.barDirection === 'left' || this.params.barDirection === 'right') {
                return d.positionY;
            }
            else {
                return 0; // @Q@ 暫先一率從上面出發
            }
        })
            .attr('transform', (d, i) => {
            if (this.params.barDirection === 'left' || this.params.barDirection === 'right') {
                return `translate(0, ${d.offsetY})`; // @Q@ 暫先一率從左邊出發
            }
            else {
                return `translate(${d.offsetX}, 0)`; // @Q@ 暫先一率從上面出發
            }
        });
        this.labelSelection
            .transition()
            .duration(200)
            .attr('x', d => d.positionX)
            .attr('y', d => d.positionY)
            .attr('transform', (d, i) => {
            return `translate(${d.offsetX}, ${d.offsetY})`;
        });
    }
}
