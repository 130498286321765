import * as d3 from 'd3';
import ChartDateColumn from './ChartDateColumn';
import GraphicColumnBarStack from '../graphicColumnBarStack/GraphicColumnBarStack';
import { DEFAULT_CHART_DATE_COLUMN_BAR_STACK_PARAMS } from './defaults';
export default class ChartDateColumnBarStack extends ChartDateColumn {
    params = DEFAULT_CHART_DATE_COLUMN_BAR_STACK_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBarStack(this.graphicGSelection, {});
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        let groupingValue = [];
        for (let i = 0; i < data[0].length; i++) {
            groupingValue[i] = 0;
            for (let j = 0; j < data.length; j++) {
                groupingValue[i] += data[j][i].value;
            }
        }
        return groupingValue.reduce((prev, current) => {
            // [min, max]
            return [
                current < prev[0] ? current : prev[0],
                current > prev[1] ? current : prev[1]
            ];
        }, [Infinity, 0]);
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicColumnBarStack: {
                ...this.params.graphicColumnBarStack,
                ...params.graphicColumnBarStack,
            },
        };
        this.bar.setParams({
            // lineType: this.params.graphicColumnBarStack.lineType,
            // lineCurve: this.params.graphicColumnBarStack.lineCurve,
            ...this.params.graphicColumnBarStack,
            colors: this.params.colors,
            // axisWidth: this.axisWidth,
            // axisHeight: this.axisHeight,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId,
            highlightGroupId: this.params.highlightGroupId,
        });
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    setGraphicData({ renderDataItemFiltered, itemLabels, xLabels, yScale }) {
        this.bar.setDataset({
            data: renderDataItemFiltered ?? [],
            itemLabels,
            xLabels,
            axisWidth: this.axisWidth,
            zeroY: this.axisHeight,
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCover({ coverSelection, filteredData, xScale, yScale, itemLabels }) {
    }
    renderGraphicCoverHighlight({ coverSelection, eventData, xScale, yScale, itemLabels }) {
    }
}
