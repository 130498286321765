import { makeAxisPointScale, makeAxisLinearScale } from '@bpchart/d3-modules/moduleUtils';
const yLabels = ['2016', '2017', '2018', '2019', '2020'];
const axisWidth = 360;
const axisHeight = 400;
const maxValue = 150;
export default {
    y1Scale: makeAxisPointScale({
        axisLabels: yLabels,
        axisWidth: axisHeight,
        reverse: true
    }),
    y2Scale: makeAxisPointScale({
        axisLabels: yLabels,
        axisWidth: axisHeight,
        reverse: true
    }),
    x1Scale: makeAxisLinearScale({
        maxValue: maxValue,
        minValue: 0,
        axisWidth,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
        reverse: true
    }),
    x2Scale: makeAxisLinearScale({
        maxValue: maxValue,
        minValue: 0,
        axisWidth,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
    }),
    maxValue
};
