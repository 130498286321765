import { DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_AXIS_ROW_DIVERGING_PARAMS = {
    x1Label: 'x1',
    x2Label: 'x2',
    y1Label: 'y1',
    y2Label: 'y2',
    x1LabelAnchor: 'end',
    x2LabelAnchor: 'start',
    y1LabelAnchor: 'end',
    y2LabelAnchor: 'start',
    x1LabelOffset: [0, 0],
    x2LabelOffset: [0, 0],
    y1LabelOffset: [0, 0],
    y2LabelOffset: [0, 0],
    xTickFormat: ',.0f',
    xTickPadding: 20,
    gutterWidth: 100,
    xTickLine: true,
    yTickLine: false,
    xTickLineDasharray: 'none',
    yTickLineDasharray: 'none',
    x1Ticks: 2,
    x2Ticks: 2,
    axisLineColor: DEFAULT_ASIX_LINE_COLOR,
    axisLabelColor: DEFAULT_AXIS_LABEL_COLOR,
    padding: DEFAULT_PADDING,
    xVisible: true,
    yVisible: true,
};
