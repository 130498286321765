import { AxisColumn } from '@bpchart/d3-modules/axisColumn';
import dataset from './dataset';
import { DEFAULT_AXIS_COLUMN_PARAMS } from '@bpchart/d3-modules/axisColumn';
export default {
    props: {
        module: AxisColumn,
        params: DEFAULT_AXIS_COLUMN_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
