import { DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_COLUMN_AREA_STACK_PARAMS = {
    // lineType: 'line',
    // lineCurve: 'curveMonotoneX',
    lineCurve: 'curveLinear',
    colors: DEFAULT_COLORS,
    // axisWidth: 0,
    // axisHeight: 0,
    // dot: false,
    highlightTarget: 'item',
    highlightItemId: '',
    enterDuration: 800
};
