import * as d3 from 'd3';
import { DEFAULT_COLORS } from '../defaults';
const makeStackData = (data) => {
    let accumulator = 0;
    return data.map((d, i) => {
        // 累加先前所有 value
        accumulator += (i > 0 ? data[i - 1].value : 0);
        return {
            ...d,
            stackStartValue: accumulator
        };
    });
};
export default class LabelRowStack {
    selection;
    dataset = {
        data: [],
        xScale: d3.scaleLinear()
    };
    params = {
        colors: DEFAULT_COLORS,
        valueFormat: ',.0f',
        position: 'center',
        positionPadding: 0,
        align: 'start'
    };
    stackData = [];
    xScale;
    labelGSelection;
    labelSelection;
    constructor(selection, params) {
        this.selection = selection;
        this.labelGSelection = this.selection.append('g');
    }
    remove() {
        this.selection.remove();
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.xScale = dataset.xScale;
        this.stackData = makeStackData(dataset.data);
    }
    render() {
        // console.log(this.stackData)
        const update = this.labelGSelection
            .selectAll('text')
            .data(this.stackData);
        const enter = update
            .enter()
            .append('text');
        update.exit().remove();
        this.labelSelection = update.merge(enter)
            .attr('text-anchor', d => this.params.align) // start | middle | end
            .attr('dominant-baseline', d => {
            return this.params.position === 'top' ? 'auto'
                : this.params.position === 'center' ? 'central'
                    : 'hanging'; // bottom
        })
            .attr('transform', (d, i) => {
            const startX = this.params.align === 'start' ? this.xScale(d.stackStartValue)
                : this.params.align === 'end' ? this.xScale(d.stackStartValue + d.value)
                    : this.xScale(d.stackStartValue + (d.value) / 2);
            // const startX = this.xScale!(d.stackStartValue)!
            const startY = this.params.position === 'top' ? -this.params.positionPadding
                : this.params.position === 'bottom' ? this.params.positionPadding
                    : 0;
            return `translate(${startX}, ${startY})`;
        })
            .attr('fill', (d, i) => this.params.colors[i])
            .text(d => {
            if (d.text) {
                return d.text;
            }
            if (!this.params.valueFormat) {
                return d.value;
            }
            const formatter = d3.format(this.params.valueFormat);
            return formatter(d.value);
        });
    }
}
