import * as d3 from 'd3';
import ChartColumn from './ChartColumn';
import GraphicColumnBar from '../graphicColumnBar/GraphicColumnBar';
import { DEFAULT_CHART_COLUMN_BAR_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnBar extends ChartColumn {
    params = DEFAULT_CHART_COLUMN_BAR_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBar(this.graphicSelection, {});
    }
    initGraphic(params) {
        this.params.graphicColumnBar = {
            ...this.params.graphicColumnBar,
            ...params.graphicColumnBar,
            colors: params.colors,
            highlightTarget: params.highlightTarget == 'group' ? 'none' : params.highlightTarget,
            highlightDatumId: params.highlightDatumId,
            highlightItemId: params.highlightItemId
        };
        this.bar.setParams(this.params.graphicColumnBar);
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        const itemLabels = dataset.itemLabels && dataset.itemLabels.length
            ? dataset.itemLabels
            : [defaultItemLabel];
        return dataset.data.map((_d, i) => {
            const xLabel = dataset.xLabels[i];
            const d = _d;
            const itemLabel = d.itemLabel ?? defaultItemLabel; // 如資料裡無itemLabel則給一個預設值
            return {
                ...d,
                id: xLabel,
                itemIndex: itemLabels.indexOf(itemLabel),
                itemLabel,
                xIndex: i,
                xLabel,
                x: xScale(xLabel) || 0,
                y: yScale(d.value || 0) || 0
            };
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.reduce((prev, current) => {
            // [min, max]
            return [
                (current.value || 0) < prev[0] ? (current.value || 0) : prev[0],
                (current.value || 0) > prev[1] ? (current.value || 0) : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeXDataMap({ renderData, xLabels }) {
        let XDataMap = new Map();
        if (!renderData.length) {
            return XDataMap;
        }
        renderData.forEach((d, i) => {
            XDataMap.set(xLabels[i] ?? '', [d]);
        });
        return XDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        renderData.forEach((d, i) => {
            const itemData = ItemDataMap.get(d.itemLabel ?? '') ?? [];
            itemData.push(d);
            ItemDataMap.set(d.itemLabel ?? '', itemData);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, xLabels, xScale, yScale }) {
        this.bar.setDataset({
            data: renderData,
            itemLabels: itemLabels.length ? itemLabels : [defaultItemLabel],
            axisWidth: this.axisWidth,
            zeroY: yScale(0),
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        // // 非顯示全部圓點時 highlight圓點
        // if (!this.params.dot && this.params.highlightTarget !== 'none') {
        //   const dotData: GraphicRenderDatum[] = eventData[0]
        //     ? eventData[0].groupData.map(d => {
        //       return {
        //         ...d,
        //         id: d.itemLabel, // itemLabel相同則用update的
        //         x: xScale(d.Date)!,
        //         y: yScale(d.value)!
        //       }
        //     })
        //     : []
        //   this.dot!.setDataset({
        //     data: dotData,
        //     itemLabels: this.dataset.itemLabels
        //   })
        //   this.dot!.render()
        // }
    }
}
