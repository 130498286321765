export var BaseModuleTitle;
(function (BaseModuleTitle) {
    // none = '',
    BaseModuleTitle["_template"] = "\u7BC4\u672C";
    // chart = '圖表 (chart)',
    BaseModuleTitle["axisColumn"] = "\u76F4\u7ACB\u5716\u8EF8";
    BaseModuleTitle["axisColumnTwoScales"] = "\u76F4\u7ACB\u96D9Y\u8EF8\u5716\u8EF8";
    BaseModuleTitle["axisDateColumn"] = "\u76F4\u7ACB\u65E5\u671F\u5716\u8EF8";
    BaseModuleTitle["axisDateColumnTwoScales"] = "\u76F4\u7ACB\u65E5\u671F\u96D9Y\u8EF8\u5716\u8EF8";
    BaseModuleTitle["axisDateRank"] = "\u65E5\u671F\u6392\u540D\u5716\u8EF8";
    BaseModuleTitle["axisDiverging"] = "\u96D9\u5411\u5716\u8EF8";
    BaseModuleTitle["axisRow"] = "\u6A6B\u5411\u5716\u8EF8";
    BaseModuleTitle["button"] = "\u6309\u9215";
    BaseModuleTitle["chartArc"] = "\u5713\u9905\u5716";
    BaseModuleTitle["chartDateHistogram"] = "\u65E5\u671F\u7BC4\u570D\u5716\u8868";
    BaseModuleTitle["chartDonut"] = "\u751C\u751C\u5708\u5716\u8868";
    BaseModuleTitle["chartColumn"] = "\u76F4\u7ACB\u5716\u8868";
    BaseModuleTitle["chartColumnTwoScales"] = "\u76F4\u7ACB\u96D9Y\u8EF8\u5716\u8868";
    BaseModuleTitle["chartBubbleGroup"] = "\u6CE1\u6CE1\u5716";
    BaseModuleTitle["chartDateColumn"] = "\u65E5\u671F\u76F4\u7ACB\u5716\u8868";
    BaseModuleTitle["chartDateColumnTwoScales"] = "\u65E5\u671F\u76F4\u7ACB\u96D9Y\u8EF8";
    BaseModuleTitle["chartDateRank"] = "\u65E5\u671F\u6392\u5E8F\u5716";
    BaseModuleTitle["chartDirected"] = "\u95DC\u9023\u5C0E\u5411\u5716";
    BaseModuleTitle["chartRow"] = "\u6A6B\u5716\u8868";
    BaseModuleTitle["chartRowDiverging"] = "\u6A6B\u5206\u5411\u5716\u8868";
    BaseModuleTitle["chartScoutRoute"] = "\u5BE6\u544A\u80A1\u6B0A\u7D50\u69CB\u5716";
    BaseModuleTitle["chartImageRowStack"] = "\u5716\u6848\u5716\u8868";
    // container = '元素容器 (container)',
    BaseModuleTitle["graphicArcPie"] = "\u5713\u9905\u5716\u5F62";
    BaseModuleTitle["graphicColumnBar"] = "\u76F4\u7ACB\u9577\u689D\u5716\u5F62";
    BaseModuleTitle["graphicColumnBarDiverging"] = "\u76F4\u5206\u5411\u9577\u689D\u5716\u5F62";
    BaseModuleTitle["graphicColumnBarGroup"] = "\u76F4\u7ACB\u9577\u689D\u7FA4\u7D44\u5716\u5F62";
    // graphicColumnBarPNGroup = '直立長條正負值群組圖形',
    BaseModuleTitle["graphicColumnBarStack"] = "\u76F4\u7ACB\u9577\u689D\u5806\u758A\u5716\u5F62";
    BaseModuleTitle["graphicColumnLine"] = "\u76F4\u7ACB\u6298\u7DDA\u5716\u5F62";
    BaseModuleTitle["graphicColumnAreaStack"] = "\u76F4\u7ACB\u5340\u584A\u5806\u758A\u5716\u5F62";
    BaseModuleTitle["graphicDot"] = "\u5713\u9EDE\u5716\u5F62";
    BaseModuleTitle["graphicImage"] = "\u5716\u6848";
    BaseModuleTitle["graphicRowBar"] = "\u6A6B\u9577\u689D\u5716\u5F62";
    BaseModuleTitle["graphicRowBarGroup"] = "\u6A6B\u9577\u689D\u7FA4\u7D44\u5716\u5F62";
    BaseModuleTitle["graphicRowBarDiverging"] = "\u6A6B\u5206\u5411\u9577\u689D\u5716\u5F62";
    // graphicRowBarPNGroup = '橫長條正負值群組圖形',
    BaseModuleTitle["graphicRowBarLayout"] = "\u6A6B\u9577\u689D\u5E03\u5C40\u5716\u5F62";
    BaseModuleTitle["graphicRowBarStack"] = "\u6A6B\u9577\u689D\u5806\u758A\u5716\u5F62";
    BaseModuleTitle["labelArcPie"] = "\u5F27\u89D2\u6A19\u7C64";
    BaseModuleTitle["legend"] = "\u5716\u4F8B";
    // modal = '彈出視窗',
    BaseModuleTitle["sliderDateRange"] = "\u65E5\u671F\u7BC4\u570D\u6ED1\u8EF8";
    BaseModuleTitle["tooltip"] = "\u63D0\u793A";
    BaseModuleTitle["utilAuxDateLabel"] = "\u8F14\u52A9\u65E5\u671F\u6A19\u7C64";
    BaseModuleTitle["utilAuxLine"] = "\u8F14\u52A9\u7DDA";
    BaseModuleTitle["utilGraphicBarLabel"] = "\u9577\u689D\u5716\u6A19\u7C64";
})(BaseModuleTitle || (BaseModuleTitle = {}));
