import * as d3 from 'd3';
import { DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export default class UtilAuxLine {
    selection;
    data = [];
    auxLineSelection = undefined;
    constructor(selection) {
        this.selection = selection;
    }
    setData(data) {
        this.data = data;
    }
    render() {
        const update = this.selection
            .selectAll('line.bpchart__auxline')
            .data(this.data);
        const enter = update
            .enter()
            .append('line')
            .classed('bpchart__auxline', true)
            // .style('stroke', '#E4E7ED')
            .style('stroke', DEFAULT_AXIS_LABEL_COLOR)
            .style('stroke-width', 1)
            .style('stroke-dasharray', '3,3')
            .style('pointer-events', 'none');
        // .attr('opacity', 0)
        this.auxLineSelection = update.merge(enter)
            .attr('opacity', (d) => {
            return d.active == true ? 1 : 0;
        });
        update.exit().remove();
        enter
            .attr('x1', d => d.x1)
            .attr('y1', d => d.y1)
            .attr('x2', d => d.x2)
            .attr('y2', d => d.y2);
        update
            .transition()
            .duration(50)
            .attr('x1', d => d.x1)
            .attr('y1', d => d.y1)
            .attr('x2', d => d.x2)
            .attr('y2', d => d.y2);
    }
    remove() {
        this.selection.remove();
    }
}
