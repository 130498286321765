import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import { GraphicImageRowStack } from '../graphicImageRowStack';
import { LabelRowStack } from '../labelRowStack';
// import {  } from ''
const makeImgData = (data, valuePerImage) => {
    return data.map((d, i) => {
        let data = [];
        const amount = d.value / valuePerImage;
        const wholeAmount = Math.floor(amount);
        // 最後一筆
        const lastIndex = wholeAmount;
        const lastValue = (amount + 1) - Math.ceil(amount); // e.g. amount = 3 then lastValue = 1, amount = 3.5 then lastValue = 0.5
        for (let _i = 0; _i <= lastIndex; _i++) {
            // 除非最後一張圖，否則皆為1（完整呈現圖無遮罩）
            let value = 1;
            if (_i == lastIndex) {
                value = lastValue;
            }
            data.push({
                id: `${i}_${_i}`,
                svgIndex: i,
                value,
                // 最後一張圖才有需要裁切
                // clipPathId: _i == lastIndex ? `bpchart__clipPath_${i}` : null
            });
        }
        return data;
    });
};
const makeLabelData = (data, imgData) => {
    return data.map((d, i) => {
        return {
            id: imgData[i][0].id,
            value: d.value
        };
    });
};
export default class ChartImageRowStack {
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    data = [];
    params = {
        graphicImageRowStack: {
            svgWidth: 24,
            svgHeight: 48
        },
        labelRowStackParams: {
            colors: [],
            valueFormat: ',.0f',
            position: 'center',
            positionPadding: 0,
            align: 'start'
        },
        step: 35,
        valuePerImage: 1
    };
    selection;
    graphicImageRowStack;
    xScale;
    labelScale;
    labelRowStack;
    sum = 0;
    constructor(selection, params) {
        this.selection = selection;
        this.graphicImageRowStack = new GraphicImageRowStack(this.selection, this.params.graphicImageRowStack);
        this.labelRowStack = new LabelRowStack(this.selection, this.params.labelRowStackParams);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicImageRowStack: {
                ...this.params.graphicImageRowStack,
                ...params.graphicImageRowStack,
            },
        };
        this.graphicImageRowStack.setParams(this.params.graphicImageRowStack);
        this.labelRowStack.setParams(this.params.labelRowStackParams);
    }
    remove() {
        this.selection.remove();
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
    }
    setData(data) {
        this.data = data;
        this.sum = this.data.reduce((prev, current) => prev + current.value, 0);
    }
    render() {
        // const rowStackData = this.data.map((d, i) => makeImgData(d, i))
        const rowStackData = makeImgData(this.data, this.params.valuePerImage);
        const rowStackDataFlat = rowStackData.flat();
        // 全長
        const width = this.params.step * rowStackDataFlat.length;
        this.xScale = d3.scalePoint()
            .domain(rowStackDataFlat.map((d, i) => d.id))
            .range([0, width - this.params.step]);
        // .padding(0.5)
        // 使用 value 去計算 label 的比例尺
        this.labelScale = d3.scaleLinear()
            .domain([0, this.sum]) // 因為要由下往上所以反過來
            .range([0, width]);
        this.graphicImageRowStack.setDataset({
            data: rowStackData,
            svgs: this.data.map(d => d.svg),
            xScale: this.xScale
        });
        this.graphicImageRowStack.render();
        this.labelRowStack.setDataset({
            data: makeLabelData(this.data, rowStackData),
            xScale: this.labelScale
        });
        this.labelRowStack.render();
    }
}
