import * as d3 from 'd3';
import ChartColumn from './ChartColumn';
import GraphicColumnBarDiverging from '../graphicColumnBarDiverging/GraphicColumnBarDiverging';
import { DEFAULT_CHART_COLUMN_BAR_PN_GROUP_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnBarPNGroup extends ChartColumn {
    params = DEFAULT_CHART_COLUMN_BAR_PN_GROUP_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBarDiverging(this.graphicSelection, {});
    }
    initGraphic(params) {
        this.params.graphicColumnBarDiverging = {
            ...this.params.graphicColumnBarDiverging,
            ...params.graphicColumnBarDiverging,
            colors: params.colors,
            highlightTarget: params.highlightTarget,
            highlightDatumId: params.highlightDatumId,
            highlightItemId: params.highlightItemId,
            highlightGroupId: params.highlightGroupId,
        };
        this.bar.setParams(this.params.graphicColumnBarDiverging);
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                const x = xScale(xLabel) || 0; // 這裡的 x 是對應 x座標軸的位置，群組內的位置在圖形裡面再做計算
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x,
                    y: yScale(_d.value || 0) || 0
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                (current.value || 0) < prev[0] ? (current.value || 0) : prev[0],
                (current.value || 0) > prev[1] ? (current.value || 0) : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeXDataMap({ renderData, xLabels }) {
        let XDataMap = new Map();
        if (!renderData.length) {
            return XDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i], xData);
            });
        });
        return XDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        itemLabels.forEach((d, i) => {
            ItemDataMap.set(d, renderData[i]);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, xLabels, xScale, yScale }) {
        const zeroY = yScale(0);
        this.bar.setDataset({
            data: renderData,
            xLabels,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisWidth: this.axisWidth,
            zeroY1: zeroY,
            zeroY2: zeroY
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        // // 非顯示全部圓點時 highlight圓點
        // if (!this.params.dot && this.params.highlightTarget !== 'none') {
        //   const dotData: GraphicRenderDatum[] = eventData[0]
        //     ? eventData[0].groupData.map(d => {
        //       return {
        //         ...d,
        //         id: d.itemLabel, // itemLabel相同則用update的
        //         x: xScale(d.Date)!,
        //         y: yScale(d.value)!
        //       }
        //     })
        //     : []
        //   this.dot!.setDataset({
        //     data: dotData,
        //     itemLabels: this.dataset.itemLabels
        //   })
        //   this.dot!.render()
        // }
    }
}
