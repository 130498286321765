import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from './defaults';
// import TooltipAside from '../tooltip/TooltipAside'
import { makeColorScale } from '../moduleUtils';
// const makeColorScale = (_itemLabels: string[] | undefined, colors: string[]) => {
//   const itemLabels = (_itemLabels && _itemLabels.length) ? _itemLabels : ['']
//   return d3.scaleOrdinal<string, string>()
//     .domain(itemLabels.map((d) => d))
//     .range(itemLabels.map((d, i) => colors![i]))
// }
export default class GraphicColumnLine {
    selection;
    params = DEFAULT_GRAPHIC_DOT_PARAMS;
    dataset = {
        data: [],
        itemLabels: []
    };
    dotSelection = undefined;
    colorScale;
    renderData = [];
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    constructor(selection, params) {
        this.selection = selection;
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        this.colorScale = makeColorScale(this.dataset.itemLabels && this.dataset.itemLabels.length ? this.dataset.itemLabels : [''], this.params.colors);
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.renderData = this.dataset.data.map(d => {
            return {
                ...d,
                itemLabel: d.itemLabel ?? '' // 預設要有空值，才對應的到顏色
            };
        });
        this.colorScale = makeColorScale(this.dataset.itemLabels && this.dataset.itemLabels.length ? this.dataset.itemLabels : [''], this.params.colors);
    }
    render() {
        this.renderDot({
            selection: this.selection,
            data: this.renderData,
            colorScale: this.colorScale,
            params: this.params
        });
        // 綁定事件
        this.dotSelection.on('mouseover', null);
        this.dotSelection.on('mouseout', null);
        this.dotSelection
            .on('click', (d, i) => {
            // d3.event.stopPropagation()
            this.clickCallback(d);
        })
            .on('mouseover', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget === 'item' && d.itemLabel) {
                this.highlight(this.dotSelection, d.itemLabel, '');
            }
            else if (this.params.highlightTarget === 'datum' && d.id) {
                this.highlight(this.dotSelection, '', d.id);
            }
            this.mouseoverCallback(d);
        })
            .on('mousemove', (d, i) => {
            // d3.event.stopPropagation()
            this.mousemoveCallback(d);
        })
            .on('mouseout', (d, i) => {
            // d3.event.stopPropagation()
            if (this.params.highlightTarget === 'item') {
                this.removeHighlight(this.dotSelection);
            }
            this.mouseoutCallback(d);
        });
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    remove() {
        this.selection.remove();
    }
    renderDot({ selection, data, colorScale, params }) {
        const dotUpdate = selection
            .selectAll('circle.bpchart__circle')
            .data(data, d => d.id);
        const dotEnter = dotUpdate.enter()
            .append('circle')
            .attr('r', params.r)
            .attr('fill', params.fill)
            .attr('stroke-width', params.strokeWidth)
            .style('cursor', 'pointer')
            .classed('bpchart__circle', true)
            .attr('opacity', 0);
        dotUpdate.exit().remove();
        this.dotSelection = dotUpdate.merge(dotEnter);
        this.dotSelection
            .attr('stroke', (d, i) => colorScale(d.itemLabel));
        dotEnter
            .attr('cx', d => d.x)
            .attr('cy', d => d.y)
            .attr('opacity', 0)
            .transition()
            .delay((d, i) => i * this.params.enterDuration)
            .attr('opacity', 1);
        dotUpdate
            .transition()
            .duration(50)
            .attr('cx', d => d.x)
            .attr('cy', d => d.y)
            .attr('opacity', 1);
        this.dotSelection
            .on('end', () => {
            // highlight
            if (this.params.highlightItemId || this.params.highlightDatumId) {
                this.highlight(this.dotSelection, this.params.highlightItemId, this.params.highlightDatumId);
            }
            else {
                this.removeHighlight(this.dotSelection);
            }
        });
        // delay好像不能用 .on('end', fn)監聽，所以只好自己算
        // setTimeout(() => {
        //   // highlight
        //   if (this.params.highlightItemId) {
        //     this.highlight(dotEnter!, this.params.highlightItemId)
        //   } else {
        //     this.removeHighlight(dotEnter!)
        //   }
        // }, dotEnter.size() * enterSpeed + (dotUpdate.size() > 0 ? updateSpeed : 0))
    }
    highlight(selection, itemId, datumId) {
        if (!selection) {
            return;
        }
        let ids = [];
        if (datumId) {
            ids.push(datumId);
        }
        if (itemId) {
            const _ids = this.dataset.data
                .flat()
                .filter(d => d.itemLabel === itemId)
                .map(d => d.id);
            ids = ids.concat(_ids);
        }
        selection
            .each((d, i, n) => {
            if (ids.includes(d.id)) {
                d3.select(n[i])
                    // .transition()
                    // .duration(200)
                    .style('opacity', 1);
            }
            else {
                d3.select(n[i])
                    .transition()
                    .duration(200)
                    .style('opacity', 0.3);
            }
        });
    }
    removeHighlight(selection) {
        // highlight
        if (this.params.highlightItemId || this.params.highlightDatumId) {
            this.highlight(selection, this.params.highlightItemId, this.params.highlightDatumId);
        }
        else if (!this.params.highlightItemId) {
            selection
                // .transition()
                // .duration(200)
                .style('opacity', 1);
        }
    }
}
