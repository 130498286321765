const data = [
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 5
        },
        {
            "value": 7
        },
        {
            "value": 13
        },
        {
            "value": 25
        },
        {
            "value": 74
        },
        {
            "value": 85
        },
        {
            "value": 73
        },
        {
            "value": 50
        },
        {
            "value": 50
        },
        {
            "value": 38
        },
        {
            "value": 57
        },
        {
            "value": 62
        },
        {
            "value": 29
        },
        {
            "value": 15
        },
        {
            "value": 17
        },
        {
            "value": 1
        }
    ],
    [
        {
            "value": 2
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 1
        },
        {
            "value": 3
        },
        {
            "value": 14
        },
        {
            "value": 12
        },
        {
            "value": 18
        },
        {
            "value": 18
        },
        {
            "value": 41
        },
        {
            "value": 22
        },
        {
            "value": 20
        },
        {
            "value": 52
        },
        {
            "value": 60
        },
        {
            "value": 32
        },
        {
            "value": 28
        },
        {
            "value": 35
        },
        {
            "value": 19
        },
        {
            "value": 14
        },
        {
            "value": 30
        },
        {
            "value": 13
        },
        {
            "value": 8
        },
        {
            "value": 9
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 1
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 8
        },
        {
            "value": 7
        },
        {
            "value": 2
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 7
        },
        {
            "value": 1
        },
        {
            "value": 3
        },
        {
            "value": 6
        },
        {
            "value": 6
        },
        {
            "value": 2
        },
        {
            "value": 8
        },
        {
            "value": 6
        },
        {
            "value": 2
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 6
        },
        {
            "value": 12
        },
        {
            "value": 10
        },
        {
            "value": 6
        },
        {
            "value": 8
        },
        {
            "value": 6
        },
        {
            "value": 3
        },
        {
            "value": 8
        },
        {
            "value": 4
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 4
        },
        {
            "value": 2
        },
        {
            "value": 2
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 1
        },
        {
            "value": 3
        },
        {
            "value": 8
        },
        {
            "value": 9
        },
        {
            "value": 10
        },
        {
            "value": 4
        },
        {
            "value": 2
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 5
        },
        {
            "value": 2
        },
        {
            "value": 2
        },
        {
            "value": 0
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 7
        },
        {
            "value": 3
        },
        {
            "value": 5
        },
        {
            "value": 9
        },
        {
            "value": 8
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 10
        },
        {
            "value": 4
        },
        {
            "value": 3
        },
        {
            "value": 1
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 9
        },
        {
            "value": 1
        },
        {
            "value": 7
        },
        {
            "value": 7
        },
        {
            "value": 6
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 5
        },
        {
            "value": 1
        },
        {
            "value": 6
        },
        {
            "value": 3
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 2
        },
        {
            "value": 8
        },
        {
            "value": 7
        },
        {
            "value": 4
        },
        {
            "value": 3
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 5
        },
        {
            "value": 3
        },
        {
            "value": 3
        },
        {
            "value": 4
        },
        {
            "value": 1
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 6
        },
        {
            "value": 4
        },
        {
            "value": 0
        },
        {
            "value": 4
        },
        {
            "value": 18
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 8
        },
        {
            "value": 0
        },
        {
            "value": 3
        },
        {
            "value": 2
        },
        {
            "value": 0
        }
    ],
    [
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 5
        },
        {
            "value": 13
        },
        {
            "value": 8
        },
        {
            "value": 3
        },
        {
            "value": 4
        },
        {
            "value": 1
        },
        {
            "value": 2
        },
        {
            "value": 1
        },
        {
            "value": 4
        },
        {
            "value": 1
        },
        {
            "value": 0
        },
        {
            "value": 1
        },
        {
            "value": 0
        }
    ]
];
export default {
    data,
    itemLabels: [
        "ptt_Gossiping",
        "ptt_C_Chat",
        "youtube_Muse木棉花-TW",
        "dcard_有趣",
        "ptt_Marginalman",
        "news_LINE TODAY",
        "news_奇摩新聞",
        "dcard_閒聊",
        "news_NOWnews今日新聞",
        "dcard_梗圖"
    ],
    xDates: [
        "2020-12-31",
        "2021-01-01",
        "2021-01-02",
        "2021-01-03",
        "2021-01-04",
        "2021-01-05",
        "2021-01-06",
        "2021-01-07",
        "2021-01-08",
        "2021-01-09",
        "2021-01-10",
        "2021-01-11",
        "2021-01-12",
        "2021-01-13",
        "2021-01-14",
        "2021-01-15",
        "2021-01-16",
        "2021-01-17",
        "2021-01-18",
        "2021-01-19",
        "2021-01-20",
        "2021-01-21",
        "2021-01-22",
        "2021-01-23",
        "2021-01-24",
        "2021-01-25",
        "2021-01-26",
        "2021-01-27",
        "2021-01-28",
        "2021-01-29",
        "2021-01-30"
    ]
};
