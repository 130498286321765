export default {
    "data": [
        [
            {
                "value": 1438,
                "id": "出生_110年9月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 0,
                "yLabel": "110年9月",
                "x": 387.25826429859063,
                "y": 377.77777777777777
            },
            {
                "value": 1407,
                "id": "出生_110年10月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 1,
                "yLabel": "110年10月",
                "x": 383.78104555883317,
                "y": 333.3333333333333
            },
            {
                "value": 1491,
                "id": "出生_110年11月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 2,
                "yLabel": "110年11月",
                "x": 393.20318666011144,
                "y": 288.88888888888886
            },
            {
                "value": 1575,
                "id": "出生_110年12月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 3,
                "yLabel": "110年12月",
                "x": 402.6253277613897,
                "y": 244.44444444444446
            },
            {
                "value": 1410,
                "id": "出生_111年1月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 4,
                "yLabel": "111年1月",
                "x": 384.11755059816454,
                "y": 200
            },
            {
                "value": 1075,
                "id": "出生_111年2月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 5,
                "yLabel": "111年2月",
                "x": 346.54115453949527,
                "y": 155.55555555555554
            },
            {
                "value": 1330,
                "id": "出生_111年3月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 6,
                "yLabel": "111年3月",
                "x": 375.1440828826614,
                "y": 111.11111111111111
            },
            {
                "value": 1197,
                "id": "出生_111年4月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 7,
                "yLabel": "111年4月",
                "x": 360.2256928056375,
                "y": 66.66666666666667
            },
            {
                "value": 959,
                "id": "出生_111年5月",
                "itemIndex": 0,
                "itemLabel": "出生",
                "yIndex": 8,
                "yLabel": "111年5月",
                "x": 333.5296263520157,
                "y": 22.22222222222223
            }
        ],
        [
            {
                "value": -1355,
                "id": "死亡_110年9月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 0,
                "yLabel": "110年9月",
                "x": 73.9720726810882,
                "y": 377.77777777777777
            },
            {
                "value": -1359,
                "id": "死亡_110年10月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 1,
                "yLabel": "110年10月",
                "x": 73.52339929531306,
                "y": 333.3333333333333
            },
            {
                "value": -1536,
                "id": "死亡_110年11月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 2,
                "yLabel": "110年11月",
                "x": 53.66960197476241,
                "y": 288.88888888888886
            },
            {
                "value": -1554,
                "id": "死亡_110年12月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 3,
                "yLabel": "110年12月",
                "x": 51.65057173877421,
                "y": 244.44444444444446
            },
            {
                "value": -1645,
                "id": "死亡_111年1月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 4,
                "yLabel": "111年1月",
                "x": 41.44325221238942,
                "y": 200
            },
            {
                "value": -1422,
                "id": "死亡_111年2月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 5,
                "yLabel": "111年2月",
                "x": 66.45679346935435,
                "y": 155.55555555555554
            },
            {
                "value": -1835,
                "id": "死亡_111年3月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 6,
                "yLabel": "111年3月",
                "x": 20.131266388069523,
                "y": 111.11111111111111
            },
            {
                "value": -1418,
                "id": "死亡_111年4月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 7,
                "yLabel": "111年4月",
                "x": 66.9054668551295,
                "y": 66.66666666666667
            },
            {
                "value": -1797,
                "id": "死亡_111年5月",
                "itemIndex": 1,
                "itemLabel": "死亡",
                "yIndex": 8,
                "yLabel": "111年5月",
                "x": 24.393663552933504,
                "y": 22.22222222222223
            }
        ]
    ],
    "yLabels": [
        "110年9月",
        "110年10月",
        "110年11月",
        "110年12月",
        "111年1月",
        "111年2月",
        "111年3月",
        "111年4月",
        "111年5月"
    ],
    "itemLabels": [
        "出生",
        "死亡"
    ],
    "axisHeight": 400,
    "zeroX1": 225.96018211242216,
    "zeroX2": 225.96018211242216,
};
