import { LegendBottom } from '@bpchart/d3-modules/legend';
export default {
    props: {
        module: LegendBottom,
        params: {
            "dataTypes": [
                "進口",
                "出口"
            ],
            "colors": [
                "#1778F5",
                "#5ECED2",
                "#39BF4B",
                "#FFAD00",
                "#FF4935",
                "#909399"
            ],
            highlight: false
        },
        data: [
            {
                "dataType": "進口",
                "label": "進口"
            },
            {
                "dataType": "出口",
                "label": "出口"
            }
        ]
    },
    emits: {
        click: {},
        mouseover: {},
        mouseout: {}
    }
};
