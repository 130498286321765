import * as d3 from 'd3';
import ChartColumn from './ChartColumn';
import GraphicColumnAreaStack from '../graphicColumnAreaStack/GraphicColumnAreaStack';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_COLUMN_AREA_STACK_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnAreaStack extends ChartColumn {
    params = DEFAULT_CHART_COLUMN_AREA_STACK_PARAMS;
    area;
    dot;
    constructor(selection, params) {
        super(selection, params);
        this.area = new GraphicColumnAreaStack(this.graphicSelection, {});
        this.dot = new GraphicDot(this.coverSelection, {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
        };
        this.params.graphicColumnAreaStack = {
            ...this.params.graphicColumnAreaStack,
            colors: this.params.colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.params.graphicDot = {
            ...this.params.graphicDot,
            colors: this.params.colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.area.setParams(this.params.graphicColumnAreaStack);
        this.area
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot.render();
            }
        });
        this.dot.setParams(this.params.graphicDot);
        this.dot
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.area.setParams({
                    highlightItemId: d.itemLabel
                });
                this.area.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.area.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.area.render();
            }
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x: xScale(xLabel) || 0,
                    y: yScale((_d.value || 0)) // 如無值當作0
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        let groupingValue = [];
        for (let i = 0; i < data[0].length; i++) {
            groupingValue[i] = 0;
            for (let j = 0; j < data.length; j++) {
                groupingValue[i] += (data[j][i].value || 0);
            }
        }
        return groupingValue.reduce((prev, current) => {
            // [min, max]
            return [
                current < prev[0] ? current : prev[0],
                current > prev[1] ? current : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeXDataMap({ renderData, xLabels }) {
        let XDataMap = new Map();
        if (!renderData.length) {
            return XDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i] ?? '', xData);
            });
        });
        return XDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        renderData.forEach((d, i) => {
            ItemDataMap.set(itemLabels[i] ?? '', d);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, xLabels, xScale, yScale }) {
        const zeroY = yScale(0);
        this.area.setDataset({
            data: renderData,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisWidth: this.axisWidth,
            // axisHeight: this.axisHeight,
            zeroY
        });
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            // const _dotData = renderData
            //   .map((d, i) => {
            //     return d
            //       .filter(d => d.value != null)
            //       .map((_d, _i) => {
            //         // // 如果前一組資料無空值
            //         // if (_d.y == null || renderData[i - 1][_i].y == null) {
            //         //   return {
            //         //     ..._d,
            //         //     y: null
            //         //   }
            //         // }
            //         const prevY: number = i == 0 ? zeroY : renderData[i - 1][_i].y
            //         return {
            //           ..._d,
            //           y: _d.y - (zeroY - prevY) // 累加y
            //         }
            //       })
            //   })
            //   .flat()
            const zeroY = yScale(0);
            let dotData = [];
            renderData
                .forEach((d, i) => {
                d
                    .filter(_d => _d.value != null)
                    .forEach((_d, _i) => {
                    if (!dotData[i]) {
                        dotData[i] = [];
                    }
                    const prevY = i == 0 ? zeroY : dotData[i - 1][_i].y;
                    dotData[i][_i] = {
                        ..._d,
                        id: _d.itemLabel,
                        x: xScale(_d.xLabel),
                        y: yScale(_d.value) - (zeroY - prevY) // 累加y
                    };
                });
            });
            this.dot.setDataset({
                data: dotData.flat(),
                itemLabels: this.filteredDataset.itemLabels ?? [defaultItemLabel]
            });
        }
    }
    renderGraphic() {
        this.area.render();
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            this.dot.render();
        }
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        if (this.params.showDot === 'always') {
            return;
        }
        // 非顯示全部圓點時 highlight圓點
        if (eventData && this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            const zeroY = yScale(0);
            let dotData = [];
            eventData.groupData
                .filter(d => d.value != null)
                .forEach((d, i) => {
                const prevY = i == 0 ? zeroY : dotData[i - 1].y;
                dotData[i] = {
                    ...d,
                    id: d.itemLabel,
                    x: xScale(d.xLabel),
                    y: yScale(d.value) - (zeroY - prevY) // 累加y
                };
            });
            this.dot.setDataset({
                data: dotData,
                itemLabels: this.filteredDataset.itemLabels ?? [defaultItemLabel]
            });
            this.dot.render();
        }
        else {
            this.dot.setDataset({
                data: [],
                itemLabels: this.filteredDataset.itemLabels ?? [defaultItemLabel]
            });
            this.dot.render();
        }
    }
}
