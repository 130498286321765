export default {
    "data": [
        [
            {
                "value": 55,
                "id": "進口_2016",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 0,
                "yLabel": "2016",
                "x": 156.85341145833334,
                "y": 360
            },
            {
                "value": 80,
                "id": "進口_2017",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 1,
                "yLabel": "2017",
                "x": 228.15041666666667,
                "y": 280
            },
            {
                "value": 50,
                "id": "進口_2018",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 2,
                "yLabel": "2018",
                "x": 142.59401041666666,
                "y": 200
            },
            {
                "value": 110,
                "id": "進口_2019",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 3,
                "yLabel": "2019",
                "x": 313.7068229166667,
                "y": 120
            },
            {
                "value": 150,
                "id": "進口_2020",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 4,
                "yLabel": "2020",
                "x": 427.78203125000005,
                "y": 40
            }
        ],
        [
            {
                "value": 35,
                "id": "出口_2016",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 0,
                "yLabel": "2016",
                "x": 99.81580729166667,
                "y": 360
            },
            {
                "value": 40,
                "id": "出口_2017",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 1,
                "yLabel": "2017",
                "x": 114.07520833333334,
                "y": 280
            },
            {
                "value": 15,
                "id": "出口_2018",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 2,
                "yLabel": "2018",
                "x": 42.778203125,
                "y": 200
            },
            {
                "value": 65,
                "id": "出口_2019",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 3,
                "yLabel": "2019",
                "x": 185.37221354166667,
                "y": 120
            },
            {
                "value": 120,
                "id": "出口_2020",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 4,
                "yLabel": "2020",
                "x": 342.225625,
                "y": 40
            }
        ]
    ],
    "yLabels": [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    "itemLabels": [
        "進口",
        "出口"
    ],
    "axisHeight": 400,
    "zeroX": 0
};
