import { DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_ARC_PIE_PARAMS = {
    colors: DEFAULT_COLORS,
    outerRadius: 0.95,
    innerRadius: 0,
    outerMouseoverRadius: 1,
    // label?: LabelStyle
    enterDuration: 800,
    startAngle: 0,
    endAngle: Math.PI * 2,
    padAngle: 0.02,
    // padRadius: 100,
    cornerRadius: 0,
    highlightTarget: 'item',
    highlightDatumId: '',
    highlightItemId: '',
};
