export default {
    "data": [
        [
            {
                "value": 55,
                "id": "2016_進口",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 66,
                "y": 260.66666666666663
            },
            {
                "value": 80,
                "id": "2017_進口",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 1,
                "xLabel": "2017",
                "x": 198,
                "y": 197.33333333333331
            },
            {
                "value": 50,
                "id": "2018_進口",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 2,
                "xLabel": "2018",
                "x": 330,
                "y": 273.3333333333333
            },
            {
                "value": 110,
                "id": "2019_進口",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 3,
                "xLabel": "2019",
                "x": 462,
                "y": 121.33333333333334
            },
            {
                "value": 150,
                "id": "2020_進口",
                "itemIndex": 0,
                "itemLabel": "進口",
                "xIndex": 4,
                "xLabel": "2020",
                "x": 594,
                "y": 19.99999999999997
            },
        ],
        [
            {
                "value": 35,
                "id": "2016_出口",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 0,
                "xLabel": "2016",
                "x": 66,
                "y": 311.3333333333333
            },
            {
                "value": 40,
                "id": "2017_出口",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 1,
                "xLabel": "2017",
                "x": 198,
                "y": 298.66666666666663
            },
            {
                "value": 15,
                "id": "2018_出口",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 2,
                "xLabel": "2018",
                "x": 330,
                "y": 362
            },
            {
                "value": 65,
                "id": "2019_出口",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 3,
                "xLabel": "2019",
                "x": 462,
                "y": 235.33333333333334
            },
            {
                "value": 120,
                "id": "2020_出口",
                "itemIndex": 1,
                "itemLabel": "出口",
                "xIndex": 4,
                "xLabel": "2020",
                "x": 594,
                "y": 96
            }
        ],
    ],
    "xLabels": [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    "itemLabels": [
        "進口",
        "出口"
    ],
    "axisWidth": 660,
    "zeroY": 400
};
