import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_COLUMN_PARAMS } from '../axisDateColumn';
import { DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS } from '../graphicColumnLine';
import { DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS } from '../graphicColumnBar';
import { DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS } from '../graphicColumnBarStack';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from '../graphicDot';
export const DEFAULT_CHART_DATE_COLUMN_PARAMS = {
    axisDateColumn: DEFAULT_AXIS_DATE_COLUMN_PARAMS,
    padding: DEFAULT_PADDING,
    tooltipAside: {
        templateHtml: (data) => {
            if (data.groupData && data.groupData[0]) {
                return `
          <div>
            <div>${data.groupData[0].xLabel}</div>
            <div>${data.groupData.map((d) => d.value).join(',')}</div>          
          </div>
        `;
            }
            return '';
        },
    },
    colors: DEFAULT_COLORS,
    backgroundColor: '',
    zoom: true,
    showAuxLine: true,
    highlightTarget: 'datum',
    highlightItemId: '',
    domainMaxRange: 0.95
};
export const DEFAULT_CHART_DATE_COLUMN_LINE_PARAMS = {
    ...DEFAULT_CHART_DATE_COLUMN_PARAMS,
    graphicColumnLine: DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS,
    graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS,
    highlightTarget: 'item',
    showDot: 'hover'
};
export const DEFAULT_CHART_DATE_COLUMN_BAR_PARAMS = {
    ...DEFAULT_CHART_DATE_COLUMN_PARAMS,
    graphicColumnBar: DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS,
    // highlightTarget: 'datum',
};
export const DEFAULT_CHART_DATE_COLUMN_BAR_STACK_PARAMS = {
    ...DEFAULT_CHART_DATE_COLUMN_PARAMS,
    graphicColumnBarStack: DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS,
    // highlightTarget: 'datum',
};
