import ChartScoutRoute from './ChartScoutRoute';
import * as d3 from 'd3';
import Nodes, {} from './Nodes';
import Edges, {} from './Edges';
// import { getLabelInfo, parseRoutesData } from './utils'
// import { getSvgGElementSize } from '../../utils/d3Utils'
import { PADDING_BOTTOM, PADDING_LEFT, TREE_COLUMN_WIDTH, TREE_ROW_HEIGHT, STRIPE_COLOR, EDGE_COUNT_TAG_LEFT, EDGE_CURVE, EDGE_SIDE_Y_OFFSET, NODE_WIDTH_DEFAULT, NODE_HEIGHT_MIN, NODE_TEXT_LEFT, NODE_TEXT_SIZE, NODE_TEXT_WIDTH_DEFAULT, NODE_RADIUS_ROUND, SIDE_STEP, DEFAULT_STOCK_MIN, DEFAULT_STOCK_EXCEPT_ZERO } from './const';
import { CHART_SCOUT_ROUTE_TREE_PARAMS } from './defaults';
// 取得edge所需要的值
const getEdgePathValues = (edge, startY, endY, curve) => {
    let pathD = `M${edge.startX} ${startY}`;
    let labelX = -1;
    let labelY = -1;
    if (edge._type === 'main') {
        labelX = edge.endX;
        labelY = startY + curve;
        pathD += ` H${edge.endX + curve}`; // 橫直線
        pathD += ` Q${edge.endX} ${startY}, ${edge.endX} ${labelY}`; // 弧線
        pathD += ` V${endY}`; // 垂直線
    }
    else if (edge._type === 'sideUp') {
        labelX = edge._sideX;
        labelY = endY + curve;
        pathD += ` H${edge._sideX - curve}`; // 橫直線
        pathD += ` Q${edge._sideX} ${startY}, ${edge._sideX} ${startY - curve}`; // 弧線
        pathD += ` V${labelY}`; // 垂直線
        pathD += ` Q${edge._sideX} ${endY}, ${edge._sideX - curve} ${endY}`; // 弧線
        pathD += ` H${edge.endX}`; // 橫直線
    }
    else if (edge._type === 'sideDown') {
        labelX = edge._sideX;
        labelY = endY - curve;
        pathD += ` H${edge._sideX - curve}`; // 橫直線
        pathD += ` Q${edge._sideX} ${startY}, ${edge._sideX} ${startY + curve}`; // 弧線
        pathD += ` V${labelY}`; // 垂直線
        pathD += ` Q${edge._sideX} ${endY}, ${edge._sideX - curve} ${endY}`; // 弧線
        pathD += ` H${edge.endX}`; // 橫直線
    }
    return {
        pathD,
        labelX,
        labelY
    };
};
const filterRouteData = (treeNodes, treeEdges) => {
    const EdgesSet = new Set(treeEdges.map(d => d.id));
    return treeNodes.map(node => {
        const _routesDataUp = node._routesDataUp.filter(routeData => {
            return routeData['routes-in-id'].every(id => {
                return EdgesSet.has(id);
            });
        });
        return {
            ...node,
            _routesDataUp,
            _mainRouteUp: _routesDataUp[0] ? _routesDataUp[0]['routes-in-id'] : []
        };
    });
};
// 設定 _originTrunkNodeId, expandable
// const setNodesTopAndDown = (treeNodes: Array<TreeUpNodeDatum>, rootId: string) => {
//   let MainChildNodeSet = new Set()
//   treeNodes.forEach(node => {
//     let mainRoutes: string[] = []
//     // 非根節點
//     if (node._routesDataUp.length) {
//       // 排序過的路徑中的第一筆為主要路徑
//       mainRoutes = node._routesDataUp[0]['routes-in-id']
//     }
//     node._originTrunkNodeId = mainRoutes[0] ? mainRoutes[0].split('->')[1] : ''
//     MainChildNodeSet.add(node._originTrunkNodeId)
//   })
//   treeNodes.forEach(node => {
//     // 寫入 expandable資料
//     if (node.id != rootId) {
//       node.expandable = MainChildNodeSet.has(node.id)
//     }
//   })
// }
const setNodeIndexes = (nodes) => {
    nodes.forEach((d, i) => {
        d.index = i;
    });
};
// 新增節點（依樹狀結構展開）
const addTopByHierarchy = ({ treeNodes, treeNodesFiltered, treeEdges, treeEdgesFiltered, originId }) => {
    let newNodes = Object.assign([], treeNodes);
    let newEdges = Object.assign([], treeEdges);
    // step1 增加所有主幹道祖先節點
    const MainAncestorsSet = new Set();
    const addMainParentNodes = (originId) => {
        treeNodesFiltered.forEach(node => {
            if (node._originTrunkNodeId == originId) {
                MainAncestorsSet.add(node.id);
                newNodes.push(node);
                addMainParentNodes(node.id);
            }
        });
    };
    addMainParentNodes(originId);
    // step2 增加祖先節點流出&流入的線
    treeEdgesFiltered.forEach(edge => {
        if (MainAncestorsSet.has(edge.startNodeId) || MainAncestorsSet.has(edge.endNodeId)) {
            newEdges.push(edge);
        }
    });
    // step3 過濾 routeData
    newNodes = filterRouteData(newNodes, newEdges);
    // step4 紀錄展開狀態
    // treeNodesFiltered.forEach(node => {
    //   if (DeletedIdsSet.has(node.id)) {
    //     node.isExpanded = false
    //   }
    // })
    // step4 修正 index
    setNodeIndexes(newNodes);
    return {
        treeNodes: newNodes,
        treeNodesFiltered,
        treeEdges: newEdges,
        treeEdgesFiltered,
    };
};
// 新增節點（依上下游關係展開）
const addTopByDirection = ({ treeNodes, treeNodesFiltered, treeEdges, treeEdgesFiltered, rootId, originId }) => {
    const NodesDataOriginMap = new Map(treeNodesFiltered.map(d => [d.id, d]));
    // step1 新增關聯edges
    const TopNodeIdsSet = new Set();
    for (let edge of treeEdgesFiltered) {
        if (edge.endNodeId === originId && treeEdges.some(d => d.id === edge.id) == false) {
            TopNodeIdsSet.add(edge.startNodeId);
            treeEdges.push(edge);
        }
    }
    if (TopNodeIdsSet.size < 1) {
        return { treeNodes, treeEdges };
    }
    // step2 如果source-nodes無存在目前nodes則新增進來
    // let findNoneNodeIDs = []
    let topNodes = [];
    for (let testID of TopNodeIdsSet) {
        const isExist = treeNodes.find(node => node.id == testID) != null;
        if (isExist === false) {
            let findNode = NodesDataOriginMap.get(testID);
            if (findNode) {
                topNodes.push(findNode);
                // findNoneNodeIDs.push(findNode.id)
            }
        }
    }
    // step3 修正展開狀態
    topNodes = topNodes.map((node) => {
        let expandable = treeEdgesFiltered.some(edge => {
            // 非node的上層
            if (edge.endNodeId !== node.id) {
                return false;
            }
            // 如果上層為根節點則無展開按鈕
            if (edge.startNodeId === rootId) {
                return false;
            }
            // 有上層且非根節點
            return true;
        });
        if (expandable) {
            node.expandable = true;
            node.isExpanded = false; // 預設false
        }
        return node;
    });
    // step4 合併
    treeNodes = treeNodes.concat(topNodes);
    // step5 過濾 routeData
    treeNodes = treeNodes.map(node => {
        const originNode = NodesDataOriginMap.get(node.id);
        return {
            ...node,
            _routesDataUp: originNode._routesDataUp,
            _mainRouteUp: originNode._routesDataUp[0]['routes-in-id']
        };
    });
    treeNodes = filterRouteData(treeNodes, treeEdges);
    // step6 修正 index
    setNodeIndexes(treeNodes);
    return { treeNodes, treeEdges };
};
// 刪除節點（依樹狀結構收合）
const deleteTopByHierarchy = ({ treeNodes, treeNodesFiltered, treeEdges, treeEdgesFiltered, BranchNodesMap, rootId, originId }) => {
    let newNodes = [];
    let newEdges = [];
    const DeletedIdsSet = new Set();
    // step1 移除掉所有主幹道祖先節點
    const ancestorsNodes = BranchNodesMap.get(originId);
    const AncestorsNodesSet = new Set(ancestorsNodes?.map(node => node.id));
    newNodes = treeNodes.filter(node => {
        const willDelete = AncestorsNodesSet.has(node.id);
        if (willDelete) {
            DeletedIdsSet.add(node.id);
        }
        return willDelete == false;
    });
    // step2 移除祖先節點流出&流入的線
    newEdges = treeEdges.filter(edge => {
        const willDelete = AncestorsNodesSet.has(edge.startNodeId) || AncestorsNodesSet.has(edge.endNodeId);
        return willDelete == false;
    });
    // step3 過濾 routeData
    newNodes = filterRouteData(newNodes, newEdges);
    // step4 紀錄展開狀態
    // treeNodesFiltered.forEach(node => {
    //   if (DeletedIdsSet.has(node.id)) {
    //     node.isExpanded = false
    //   }
    // })
    // step5 修正 index
    setNodeIndexes(newNodes);
    return {
        treeNodes: newNodes,
        treeNodesFiltered,
        treeEdges: newEdges,
        treeEdgesFiltered,
    };
};
// 刪除節點遞迴（依上下游關係收合）
const deleteTopByDirection = ({ treeNodes, treeEdges, rootId, originId }) => {
    let newNodes = [];
    let newEdges = [];
    // step1 刪除關聯edges
    let findIDs = [];
    for (let i in treeEdges) {
        if (treeEdges[i].endNodeId === originId && treeEdges[i].startNodeId !== rootId) {
            // 取得點擊對象的上游（如為根節點不移除）
            findIDs.push(treeEdges[i].startNodeId);
        }
        else {
            // 不刪除
            newEdges.push(treeEdges[i]);
        }
    }
    // step2 過濾掉無連接到根節點的線
    const filterEdgesFromRoot = (edges) => {
        let newEdges = [];
        const searchEdge = (currentID) => {
            const topFromCurrent = edges.filter(edge => edge.endNodeId === currentID);
            const downFromCurrent = edges.filter(edge => edge.startNodeId === currentID);
            for (const edge of topFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge.startNodeId === edge.startNodeId && newEdge.endNodeId === edge.endNodeId;
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge.startNodeId);
                }
            }
            for (const edge of downFromCurrent) {
                let isExist = newEdges.some(newEdge => {
                    return newEdge.startNodeId === edge.startNodeId && newEdge.endNodeId === edge.endNodeId;
                });
                if (isExist == false) {
                    newEdges.push(edge);
                    searchEdge(edge.endNodeId);
                }
            }
        };
        searchEdge(rootId);
        return newEdges;
    };
    newEdges = filterEdgesFromRoot(newEdges);
    // step3 過濾掉無連接線的節點
    newNodes = treeNodes.filter((node) => {
        const isFind = newEdges.find((edge) => node.id === edge.startNodeId || node.id === edge.endNodeId) != null;
        return isFind;
    });
    // step4 過濾 routeData
    newNodes = filterRouteData(newNodes, newEdges);
    // step5 修正 index
    setNodeIndexes(newNodes);
    return {
        treeNodes: newNodes,
        treeEdges: newEdges
    };
};
const makeBranchNodesMap = (SortedTwigNodesMap, rootId) => {
    // 紀錄每個節點的所有祖先節點
    const BranchNodesMap = new Map(); // <_originTrunkNodeIds, ancestorsNodes>
    const setAncestors = (descendantsIds, ParentsNodes) => {
        // 全部祖先紀錄子節點
        descendantsIds.forEach(id => {
            let descendants = BranchNodesMap.get(id) || [];
            descendants = descendants.concat(ParentsNodes);
            BranchNodesMap.set(id, descendants);
        });
        // 下一個疊代，將下一層的子節點累加到所有祖先
        ParentsNodes.forEach(child => {
            const nextIds = descendantsIds.concat([child.id]);
            const childrenOfChild = SortedTwigNodesMap.get(child.id) || [];
            if (childrenOfChild.length) {
                setAncestors(nextIds, childrenOfChild);
            }
        });
    };
    const rootParents = SortedTwigNodesMap.get(rootId) || [];
    setAncestors([rootId], rootParents);
    return BranchNodesMap;
};
const makeSortedTwigNodesMap = (treeNodes) => {
    // -- 紀錄每個節點的葉節點 --
    const SortedTwigNodesMap = new Map(); // <nodeId, twigs>
    treeNodes.forEach(node => {
        if (node._mainRouteUp.length) {
            // const mainRoutes = node._routesDataUp[0]['routes-in-id']
            const trunkNodeId = node._mainRouteUp[0] ? node._mainRouteUp[0].split('->')[1] : '';
            if (trunkNodeId) {
                const twigs = SortedTwigNodesMap.get(trunkNodeId) || [];
                twigs.push(node);
                SortedTwigNodesMap.set(trunkNodeId, twigs);
            }
        }
    });
    // -- 重新排序 --
    Array.from(SortedTwigNodesMap, ([id, twigs]) => {
        twigs = twigs
            .sort((a, b) => {
            // 優先排序自然人
            if (a.nodeType === '自然人' && b.nodeType !== '自然人') {
                return -1;
            }
            else if (a.nodeType !== '自然人' && b.nodeType === '自然人') {
                return 1;
            }
            // 次要排序值較大的
            else if (a._routesDataUp[0]['routes-of-stock-detail'][0] > b._routesDataUp[0]['routes-of-stock-detail'][0]) {
                return -1;
            }
            else if (a._routesDataUp[0]['routes-of-stock-detail'][0] < b._routesDataUp[0]['routes-of-stock-detail'][0]) {
                return 1;
            }
            // 第三排序首字筆劃
            else {
                // const aEndName = a._routesDataUp[0]['routes-in-name'][0].split('->')[1]
                // const bEndName = b._routesDataUp[0]['routes-in-name'][0].split('->')[1]
                const aEndName = a.label;
                const bEndName = b.label;
                for (let i = 0; i < aEndName.length || i < bEndName.length; i++) {
                    if (aEndName[i].localeCompare(bEndName[i]) == 1) {
                        return -1;
                    }
                    else if (aEndName[i].localeCompare(bEndName[i]) == -1) {
                        return 1;
                    }
                }
            }
            return 1;
        });
        SortedTwigNodesMap.set(id, twigs);
    });
    return SortedTwigNodesMap;
};
const columnPadding = (TREE_COLUMN_WIDTH - NODE_WIDTH_DEFAULT) / 2; // 欄間距
export default class ChartScoutRouteTree extends ChartScoutRoute {
    selection;
    params = CHART_SCOUT_ROUTE_TREE_PARAMS;
    dataset = {
        nodes: [],
        edges: [],
        rootId: '',
    };
    filteredDataset = {
        nodes: [],
        edges: [],
        rootId: '',
    };
    filterConfig = {
        stockMin: DEFAULT_STOCK_MIN,
        stockExceptZero: DEFAULT_STOCK_EXCEPT_ZERO
    };
    TreeNodesCurrentMap; // treeNodesCurrent 的 MAP格式
    treeNodesFiltered;
    treeEdgesFiltered;
    treeNodesCurrent;
    treeEdgesCurrent;
    // private FoldNodeIdsSet: Set<string> = new Set() // 縮起來的nodes
    backgroundData = [];
    SortedTwigNodesMap = new Map(); // 排序過的父nodes
    BranchNodesMap = new Map(); // 祖先nodes對應
    bgStripeG; // 條紋背景
    bgLabelG; // 背景label
    maxColumnIndex = 0;
    maxRowIndex = 0;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    highlightLockMode = false; // 鎖定highlight模式
    // private highlightLockId = ''
    // private updateConfig: ChartScoutRouteTreeUpdateConfig | undefined
    expandMode = true; // 是否有展開按鈕
    constructor(el, params) {
        super(el, params);
        this.selection = el;
        this.params = {
            ...this.params,
            ...(params ?? {})
        };
        this.bgLabelG = this.svgGroup.insert('g', 'g');
        this.bgStripeG = this.svgGroup.insert('g', 'g');
        this.initNodesAndEdges(this.params.expandMode ?? 'none');
    }
    setDataset(data) {
        // console.log(data)
        this.dataset = data;
        this.filterConfig.stockMin = data.stockMin != undefined ? data.stockMin : this.filterConfig.stockMin;
        this.filterConfig.stockExceptZero = data.stockExceptZero != undefined ? data.stockExceptZero : this.filterConfig.stockExceptZero;
        if (this.filterConfig.stockMin >= 0 || this.filterConfig.stockExceptZero == true) {
            const { nodes, edges } = this.makeFilteredDataset({
                nodeData: this.dataset.nodes,
                edgeData: this.dataset.edges,
                rootId: this.dataset.rootId,
                stockMin: this.filterConfig.stockMin,
                stockExceptZero: this.filterConfig.stockExceptZero
            });
            this.filteredDataset = {
                ...this.dataset,
                nodes,
                edges
            };
        }
        else {
            this.filteredDataset = {
                ...this.dataset,
                nodes: Object.assign([], this.dataset.nodes),
                edges: Object.assign([], this.dataset.edges)
            };
        }
        this.treeNodesFiltered = this.makeTreeNodes(this.filteredDataset, this.dataset.rootId);
        this.treeEdgesFiltered = this.makeTreeEdges(this.filteredDataset);
        this.treeNodesCurrent = Object.assign([], this.treeNodesFiltered);
        this.treeEdgesCurrent = Object.assign([], this.treeEdgesFiltered);
        // this.render()
        setTimeout(() => {
            // 初始化尺寸和 zoom
            this.resize({
                width: Number(this.selection.attr('width')),
                height: Number(this.selection.attr('height'))
            });
            this.initZoom({
                ...this.zoom,
                xOffset: 0,
                yOffset: this.height
            });
            // 回復預設 zoom
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        });
    }
    render() {
        if (this.dataset.rootId !== this.rootId) {
            this.rootId = this.dataset.rootId;
            this.initZoom({
                ...this.zoom,
                xOffset: 0,
                yOffset: this.height
            });
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        }
        this.SortedTwigNodesMap = makeSortedTwigNodesMap(this.treeNodesCurrent);
        this.BranchNodesMap = makeBranchNodesMap(this.SortedTwigNodesMap, this.rootId);
        // 計算座標等資料
        if (this.treeNodesCurrent.length) {
            this.calcTreeNodesPosition(this.treeNodesCurrent);
            this.calcTreeEdgesPosition(this.treeNodesCurrent, this.treeEdgesCurrent);
        }
        this.TreeNodesCurrentMap = new Map(this.treeNodesCurrent.map(d => {
            return [d.id, d];
        }));
        // console.log('this.treeNodesCurrent', this.treeNodesCurrent)
        // console.log('this.treeEdgesCurrent', this.treeEdgesCurrent)
        //@ts-ignore
        window.treeNodesCurrent = this.treeNodesCurrent;
        //@ts-ignore
        window.treeEdgesCurrent = this.treeEdgesCurrent;
        this.nodes.setData(this.treeNodesCurrent);
        this.edges.setData(this.treeEdgesCurrent);
        this.nodes.render();
        this.edges.render();
        // 背景
        const opacityValue = this.params.style.stripe ? 1 : 0;
        this.bgStripeG.attr('opacity', opacityValue);
        this.bgLabelG.attr('opacity', opacityValue);
        this.renderBackground(this.maxColumnIndex, this.maxRowIndex);
    }
    filter(filterConfig) {
        if (this.treeNodesFiltered && this.treeEdgesFiltered && (filterConfig.stockMin != undefined || filterConfig.stockExceptZero != undefined)) {
            if (this.filterConfig.stockMin != filterConfig.stockMin || this.filterConfig.stockExceptZero != filterConfig.stockExceptZero) {
                this.removeHighlight();
                // setTimeout(() => {
                this.filterConfig.stockMin = filterConfig.stockMin;
                this.filterConfig.stockExceptZero = filterConfig.stockExceptZero;
                const { nodes, edges } = this.makeFilteredDataset({
                    nodeData: this.dataset.nodes,
                    edgeData: this.dataset.edges,
                    rootId: this.dataset.rootId,
                    stockMin: this.filterConfig.stockMin,
                    stockExceptZero: this.filterConfig.stockExceptZero
                });
                this.filteredDataset = {
                    ...this.dataset,
                    nodes,
                    edges
                };
                this.treeNodesFiltered = this.makeTreeNodes(this.filteredDataset, this.filteredDataset.rootId);
                this.treeEdgesFiltered = this.makeTreeEdges(this.filteredDataset);
                this.treeNodesCurrent = Object.assign([], this.treeNodesFiltered);
                this.treeEdgesCurrent = Object.assign([], this.treeEdgesFiltered);
                // this.render()
                // }, 1000)
            }
        }
    }
    setParams(params) {
        // if (params.routeHighlightId != this.params.routeHighlightId) {
        this.removeHighlight();
        if (params.routeHighlightId == undefined || params.routeHighlightId == '') {
            this.highlightLockMode = false;
            // this.highlightLockId = ''
        }
        else {
            this.highlightLockMode = true;
            // this.highlightLockId = params.routeHighlightId
            // const target = this.TreeNodesCurrentMap?.get(params.routeHighlightId)
            // if (target) {
            this.highlight(params.routeHighlightId);
            // }        
        }
        // }
        // if (params.transform != undefined) {
        //   this.transform = params.transform
        //   this.initZoom(0, this.height)      
        // }
        if (params.style && params.style.stripe != undefined) {
            // 背景
            const opacityValue = params.style.stripe ? 1 : 0;
            this.bgStripeG.attr('opacity', opacityValue);
            this.bgLabelG.attr('opacity', opacityValue);
        }
        if (params.expandMode != this.params.expandMode) {
            this.nodes.remove();
            this.edges.remove();
            this.initNodesAndEdges(params.expandMode ? params.expandMode : 'none');
            this.setDataset(this.dataset);
            // this.render()
        }
        if (params.scaleExtent) {
            this.zoom.scaleExtent = params.scaleExtent;
        }
        this.initZoom();
        this.params = {
            ...this.params,
            ...params
        };
        // ({
        //   ...this.zoom,
        //   scaleExtent: {
        //     min: (params.scaleExtent && params.scaleExtent.min) || this.params.scaleExtent!.min,
        //     max: (params.scaleExtent && params.scaleExtent.max) || this.params.scaleExtent!.max
        //   }
        // })
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    resize({ width, height }) {
        this.width = width;
        this.height = height;
        this.selection.attr('width', this.width);
        this.selection.attr('height', this.height);
        if (this.params && this.params.autoZoom) {
            this.initZoom({
                ...this.zoom,
                xOffset: 0,
                yOffset: this.height
            });
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        }
    }
    // 原始資料 轉 treeNodes
    makeTreeNodes(sourceData, rootId) {
        const { nodes: sourceNodes, edges } = sourceData;
        let MainChildNodeSet = new Set();
        let nodes = sourceNodes.map((d, index) => {
            const { textWidth, labels } = this.getLabelInfo(d.name);
            let nodeType = '';
            let width = NODE_WIDTH_DEFAULT;
            const height = NODE_HEIGHT_MIN + (labels.length - 1) * NODE_TEXT_SIZE;
            let _routesDataUp = [];
            // 非根節點
            if (d['routes-in-id']?.length) {
                nodeType = d.role;
                // 自然人的node寬度是依文字調整
                if (nodeType === '自然人') {
                    width = textWidth + (NODE_TEXT_LEFT * 2);
                }
                // 將路徑資料一起重新排序
                _routesDataUp = this.parseRoutesDataUp(d);
            }
            // 根節點
            else {
                nodeType = 'root';
            }
            // -- tags --
            let tags = [];
            if (d.public_issue === '上市') {
                tags.push('市');
            }
            else if (d.public_issue === '上櫃') {
                tags.push('櫃');
            }
            if (d.role === '自然人' && d['total-investment-ratio'] && d['total-investment-ratio'] >= 25) {
                tags.push('益');
            }
            // -- _originTrunkNodeId, _mainRouteUp --
            let _originTrunkNodeId = '';
            let _mainRouteUp = [];
            // 非根節點
            if (_routesDataUp.length) {
                // 排序過的路徑中的第一筆為主要路徑
                _mainRouteUp = _routesDataUp[0]['routes-in-id'];
                _originTrunkNodeId = _mainRouteUp[0] ? _mainRouteUp[0].split('->')[1] : '';
                MainChildNodeSet.add(_originTrunkNodeId);
            }
            return {
                key: d.uniID,
                index,
                id: d.uniID,
                uniID: d.uniID,
                label: d.name,
                labels,
                nodeType,
                x: -1,
                y: -1,
                width,
                height,
                expandable: false,
                isExpanded: true,
                tags,
                sourceData: d,
                _columnIndex: 0,
                _rowIndex: 0,
                _routesDataUp,
                _mainRouteUp,
                _mainRouteStockDetail: 0,
                _NodesOfRoutesSet: new Set(),
                _EdgesOfRoutesSet: new Set(),
                _seq: -1,
                // _mainParentsSorted: [], // * 後面程式處理
                // _mainAncestors: [], // * 後面程式處理
                _originTrunkNodeId,
                _trunkNode: undefined,
                _twigNodes: [], // * 後面程式處理
                // mainDescendantsIds: [], // * 後面程式處理
            };
        });
        // expandable
        nodes.forEach(node => {
            // 寫入 expandable資料
            if (node.id != rootId) {
                node.expandable = MainChildNodeSet.has(node.id);
            }
        });
        return nodes;
    }
    // 原始資料 轉 edgeData
    makeTreeEdges(sourceData) {
        return sourceData.edges.map((edge, i) => {
            // const idArr = edge.id.split('->')      
            return {
                key: edge.id,
                id: edge.id,
                startNodeId: edge['source-uniID'],
                endNodeId: edge['target-uniID'],
                startX: -1,
                startY: -1,
                endX: -1,
                endY: -1,
                startCount: 0,
                startCountX: -1,
                startCountY: -1,
                endCount: 0,
                endCountX: -1,
                endCountY: -1,
                label: this.formatPercentage(edge.percentage),
                labelX: 0,
                labelY: 0,
                labelTransformX: 0,
                labelTransformY: 0,
                labelDotShow: false,
                labelTextAnchor: 'start',
                labelDominantBaseline: 'auto',
                pathD: '',
                value: edge.percentage,
                _type: 'main',
                _startYOrigin: -1,
                _endYOrigin: -1, // * 後面程式處理
            };
        });
    }
    // 計算 treeNodes座標及相關資訊
    calcTreeNodesPosition(treeNodes) {
        // -- 紀錄 twigs  & thunk (_twigNodes, _trunkNode) --
        treeNodes.forEach(node => {
            node._twigNodes.forEach(twig => {
                twig._trunkNode = node;
            });
        });
        // -- 計算 routeData相關資料 (x, _NodesOfRoutesSet, _EdgesOfRoutesSet, _columnIndex, x) --
        treeNodes.forEach(node => {
            // let _routesDataUp: Array<RoutesDataUp> = []
            // let mainRoutes: string[] = []
            // // 非根節點
            // if (node._routesDataUp.length) {
            //   // 排序過的路徑中的第一筆為主要路徑
            //   mainRoutes = node._routesDataUp[0]['routes-in-id']
            // }
            const _NodesOfRoutesSet = new Set();
            const _EdgesOfRoutesSet = new Set();
            node._routesDataUp.forEach(route => {
                route['routes-in-id'].forEach(id => {
                    _EdgesOfRoutesSet.add(id);
                    const nodeIds = id.split('->');
                    if (nodeIds[0]) {
                        _NodesOfRoutesSet.add(nodeIds[0]);
                        if (nodeIds[1]) {
                            _NodesOfRoutesSet.add(nodeIds[1]);
                        }
                    }
                });
            });
            // in-place
            node.x = PADDING_LEFT + TREE_COLUMN_WIDTH * node._mainRouteUp.length;
            node._NodesOfRoutesSet = _NodesOfRoutesSet;
            node._EdgesOfRoutesSet = _EdgesOfRoutesSet;
            node._columnIndex = node._mainRouteUp.length;
        });
        // -- 修改row相關資料 (_seq, _rowIndex, y) --
        let rootNodeIndex = -1;
        for (let i in treeNodes) {
            if (treeNodes[i].nodeType === 'root') {
                // 找到根節點index
                rootNodeIndex = Number(i);
                break;
            }
        }
        treeNodes[rootNodeIndex]._seq = 0;
        treeNodes[rootNodeIndex]._rowIndex = 0;
        treeNodes[rootNodeIndex].y = -PADDING_BOTTOM - (TREE_ROW_HEIGHT * 0);
        const setRowInfo = (childId, childIndex) => {
            const parents = this.SortedTwigNodesMap.get(childId) || [];
            parents.forEach((parent, parentIndex) => {
                const nodeIndex = parent.index;
                // -- 計算rowIndex --
                let _rowIndex = treeNodes[childIndex]._rowIndex + 1; // 起始比父節點+1
                // 加上同階層低於該節點所有節點的高度
                for (let i = 0; i < parentIndex; i++) {
                    const descendants = this.BranchNodesMap.get(parents[i].id) || [];
                    _rowIndex++;
                    _rowIndex += descendants.length; // 子孫節點數量全部相加（子孫節點佔的高度）
                }
                treeNodes[nodeIndex]._seq = parentIndex; //  前面已排序完
                treeNodes[nodeIndex]._rowIndex = _rowIndex;
                treeNodes[nodeIndex].y = -PADDING_BOTTOM - (TREE_ROW_HEIGHT * _rowIndex);
                setRowInfo(parent.id, parent.index);
            });
        };
        setRowInfo(this.rootId, rootNodeIndex);
    }
    // 計算 edgeData座標及相關資訊
    calcTreeEdgesPosition(treeNodes, treeEdges) {
        const TreeEdgesMap = new Map(treeEdges.map(d => [d.id, d]));
        let edgeDataMain = [];
        let edgeDataSide = [];
        // node資料對應
        const TreeNodesMap = new Map(treeNodes.map(d => {
            return [d.id, d];
        }));
        // -- 取得連接線value --
        const MainEdgeValueMap = new Map(); // 主要連接線 <id, value>
        const SideEdgeValueMap = new Map(); // 外環連接線 <id, value>
        this.maxColumnIndex = 0; // 最大columnIndex
        this.maxRowIndex = 0; // 最大rowIndex
        treeNodes.forEach(node => {
            // 主要連接線
            const parentsNode = this.SortedTwigNodesMap.get(node.id) ?? [];
            parentsNode.forEach(parent => {
                const edgeId = `${parent.id}->${node.id}`;
                const edge = TreeEdgesMap.get(edgeId);
                if (edge) {
                    MainEdgeValueMap.set(edge.id, edge.value);
                }
            });
            // 找出最大columnIndex
            if (node._columnIndex > this.maxColumnIndex) {
                this.maxColumnIndex = node._columnIndex;
            }
            // 找出最大rowIndex
            if (node._rowIndex > this.maxRowIndex) {
                this.maxRowIndex = node._rowIndex;
            }
        });
        // 外環線
        treeEdges.forEach(edge => {
            // 如果有主要連接線則不加入
            if (MainEdgeValueMap.has(edge.id) === false) {
                SideEdgeValueMap.set(edge.id, edge.value);
            }
        });
        // 外圍x軸起始座標
        const sideXRoot = PADDING_LEFT + (this.maxColumnIndex * TREE_COLUMN_WIDTH) + NODE_WIDTH_DEFAULT + SIDE_STEP;
        // -- 建立資料 --
        MainEdgeValueMap.forEach((edgeValue, edgeId) => {
            const idArr = edgeId.split('->');
            const startNode = TreeNodesMap.get(idArr[0]);
            const endNode = TreeNodesMap.get(idArr[1]);
            const startY = startNode.y;
            let endY = endNode.y - (endNode.height / 2);
            if (this.expandMode && endNode?.expandable) {
                endY -= 15;
            }
            let edgeData = TreeEdgesMap.get(edgeId);
            if (edgeData) {
                edgeData.id = edgeId;
                edgeData.startNodeId = idArr[0];
                edgeData.endNodeId = idArr[1];
                edgeData.startX = startNode.x;
                edgeData.startY = startY;
                edgeData.endX = endNode.x + (endNode.width / 2);
                edgeData.endY = endY;
                edgeData.startCount = 0; // 後面再計算
                edgeData.startCountX = -1;
                edgeData.startCountY = -1;
                edgeData.endCount = 0; // 後面再計算
                edgeData.endCountX = -1;
                edgeData.endCountY = -1;
                edgeData.label = this.formatPercentage(edgeValue);
                edgeData.labelX = 0; // 後面再計算
                edgeData.labelY = 0; // 後面再計算
                edgeData.labelDotShow = false; // main不顯示圓點
                edgeData.labelTextAnchor = 'start'; // label標籤的 text-anchor 對齊方式
                edgeData.labelDominantBaseline = 'auto'; // label標籤的 dominant-baseline 對齊方式
                edgeData.pathD = ''; // 後面再計算
                edgeData.value = edgeValue;
                edgeData._type = 'main';
                edgeData._startYOrigin = startY;
                edgeData._endYOrigin = endY;
                edgeDataMain.push(edgeData);
            }
        });
        SideEdgeValueMap.forEach((edgeValue, edgeId) => {
            const idArr = edgeId.split('->');
            const startNode = TreeNodesMap.get(idArr[0]);
            const endNode = TreeNodesMap.get(idArr[1]);
            const startY = startNode.y;
            const endY = endNode.y;
            const type = startNode._rowIndex < endNode._rowIndex ? 'sideUp' : 'sideDown';
            let edgeData = TreeEdgesMap.get(edgeId);
            if (edgeData) {
                edgeData.id = edgeId;
                edgeData.startNodeId = idArr[0];
                edgeData.endNodeId = idArr[1];
                edgeData.startX = startNode.x + startNode.width;
                edgeData.startY = startY; // 後面再計算偏移
                edgeData.endX = endNode.x + endNode.width;
                edgeData.endY = endY; // 後面再計算偏移
                edgeData.startCount = 0; // 後面再計算
                edgeData.startCountX = -1; // 後面再計算
                edgeData.startCountY = -1; // 後面再計算
                edgeData.endCount = 0; // 後面再計算
                edgeData.endCountX = -1; // 後面再計算
                edgeData.endCountY = -1; // 後面再計算
                edgeData.label = this.formatPercentage(edgeValue);
                edgeData.labelX = 0; // 後面再計算
                edgeData.labelY = 0; // 後面再計算
                edgeData.labelDotShow = true; // 外圍線顯示圓點
                edgeData.labelTextAnchor = 'end';
                edgeData.labelDominantBaseline = type === 'sideUp' ? 'auto' : 'hanging';
                edgeData.pathD = ''; // 後面再計算
                edgeData.value = edgeValue;
                edgeData._type = type;
                edgeData._sideIndex = 0; // 後面再計算
                edgeData._sideX = 0; // 後面再計算
                edgeData._startYOrigin = startY;
                edgeData._endYOrigin = endY;
                edgeDataSide.push(edgeData);
            }
        });
        // -- 依排序建立外圍索引及座標 --
        edgeDataSide = edgeDataSide
            .sort((a, b) => {
            // 優先排序流出點位置低 > 高
            if (a.startY > b.startY) {
                return -1;
            }
            else if (a.startY < b.startY) {
                return 1;
            }
            // 如流出點相同時，次要排序流入點位置低 > 高
            else if (a.startY === b.startY && a.endY > b.endY) {
                return -1;
            }
            else if (a.startY === b.startY && a.endY < b.endY) {
                return 1;
            }
            return 1;
        })
            .map((d, i) => {
            d._sideIndex = i;
            d._sideX = sideXRoot + i * SIDE_STEP;
            return d;
        });
        // -- 計算外圍線流入/流出筆數 --
        const StartEdgesCountMap = new Map();
        const EndEdgesCountMap = new Map();
        edgeDataSide
            .forEach(d => {
            let startCount = StartEdgesCountMap.get(d.startNodeId) || 0;
            let endCount = EndEdgesCountMap.get(d.endNodeId) || 0;
            StartEdgesCountMap.set(d.startNodeId, ++startCount);
            EndEdgesCountMap.set(d.endNodeId, ++endCount);
        });
        // -- 計算線條路徑及label座標 --
        let edgeData = edgeDataMain.concat(edgeDataSide);
        edgeData = edgeData
            .map(d => {
            let startCount = 0;
            let endCount = 0;
            let startCountX = -1;
            let startCountY = -1;
            let endCountX = -1;
            let endCountY = -1;
            let _startYFixed = d.startY;
            let _endYFixed = d.endY;
            if (d._type !== 'main') {
                const startNodeStartCount = StartEdgesCountMap.get(d.startNodeId) || 0;
                const endNodeEndCount = EndEdgesCountMap.get(d.endNodeId) || 0;
                const startNodeEndCount = EndEdgesCountMap.get(d.startNodeId) || 0;
                const endNodeStartCount = StartEdgesCountMap.get(d.endNodeId) || 0;
                // 筆數
                startCount = startNodeStartCount;
                endCount = endNodeEndCount;
                // 座標偏移（如果同時有流出和流入時 Y軸偏移）
                if (startNodeStartCount > 0 && startNodeEndCount > 0) {
                    _startYFixed = _startYFixed - EDGE_SIDE_Y_OFFSET;
                }
                if (endNodeStartCount > 0 && endNodeEndCount > 0) {
                    _endYFixed = _endYFixed + EDGE_SIDE_Y_OFFSET;
                }
                // 筆數座標
                startCountX = d.startX + EDGE_COUNT_TAG_LEFT;
                startCountY = _startYFixed;
                endCountX = d.endX + EDGE_COUNT_TAG_LEFT;
                endCountY = _endYFixed;
            }
            const vDistance = Math.abs(_startYFixed - _endYFixed); // 垂直距離
            const curve = vDistance > EDGE_CURVE ? EDGE_CURVE : vDistance; // 弧線大小
            // 計算線條路徑及label座標
            const { labelX, labelY, pathD } = getEdgePathValues(d, _startYFixed, _endYFixed, curve);
            d.startY = _startYFixed;
            d.endY = _endYFixed;
            d.startCount = startCount;
            d.startCountX = d.startX + EDGE_COUNT_TAG_LEFT;
            d.startCountY = _startYFixed;
            d.endCount = endCount;
            d.endCountX = d.endX + EDGE_COUNT_TAG_LEFT;
            d.endCountY = _endYFixed;
            d.labelX = labelX;
            d.labelY = labelY;
            d.labelTransformX = d._type === 'main' ? 10 : -10;
            d.labelTransformY = 0;
            d.pathD = pathD;
            return d;
        });
        return edgeData;
    }
    onZoom({ x, y, k }) {
    }
    initNodesAndEdges(expandMode) {
        this.expandMode = (!expandMode || expandMode == 'none') ? false : true;
        this.nodes = new Nodes(this.nodesG, {
            nodeTypeConfig: this.params.nodeTypeConfig,
            styleConfig: this.params.styleConfig,
            nodeTagConfig: this.params.nodeTagConfig,
            expand: this.expandMode
        });
        this.edges = new Edges(this.edgesG, {});
        this.nodes
            .on('click', (d) => {
            // // 已點選相同節點則關閉鎖定
            // if (d.data.id === this.highlightLockId) {
            //   this.highlightLockMode = false
            //   this.highlightLockId = ''
            // }
            // // 未點選相同節點則開啟鎖定
            // else {
            //   this.highlightLockMode = true
            //   this.highlightLockId = d.data.id
            //   this.removeHighlight()
            //   this.highlight(d)
            // }
            this.clickCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
            // console.log(d)
        })
            .on('mouseover', (d) => {
            if (this.highlightLockMode == false) {
                this.highlight(d.data.id);
            }
            this.mouseoverCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mousemove', (d) => {
            this.mousemoveCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('mouseout', (d) => {
            if (this.highlightLockMode == false) {
                this.removeHighlight();
            }
            this.mouseoutCallback({
                data: d.data,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
        })
            .on('toggle', (d) => {
            // console.log('d.data.isExpanded', d.isExpanded)
            this.toggleTopNodes(d.data, d.isExpanded);
        });
    }
    renderBackground(maxColumnIndex, maxRowIndex) {
        const height = TREE_ROW_HEIGHT * (maxRowIndex + 1);
        this.backgroundData = [{
                index: 0,
                x: -columnPadding,
                label: '',
                color: '#ffffff'
            }];
        if (maxColumnIndex > 0) {
            for (let i = 1; i <= (maxColumnIndex); i++) {
                this.backgroundData.push({
                    index: i,
                    x: (TREE_COLUMN_WIDTH * i) - columnPadding,
                    label: `第${i}層`,
                    color: i % 2 == 0 ? '#ffffff' : STRIPE_COLOR
                });
            }
        }
        // g對齊根節點左下角
        this.bgStripeG.attr('transform', `translate(${PADDING_LEFT}, ${-PADDING_BOTTOM + (TREE_ROW_HEIGHT * 0.5)})`);
        this.bgLabelG.attr('transform', `translate(${PADDING_LEFT}, ${-PADDING_BOTTOM + (TREE_ROW_HEIGHT * 0.5)})`);
        // 條紋背景
        const updateRect = this.bgStripeG.selectAll('rect').data(this.backgroundData, (d) => d.index);
        const enterRect = updateRect.enter()
            .append('rect')
            .attr('fill', d => d.color)
            .attr('x', d => d.x)
            .attr('width', TREE_COLUMN_WIDTH);
        updateRect.merge(enterRect)
            .attr('y', -height)
            .attr('height', height);
        updateRect.exit().remove();
        // 階層label
        const updateText = this.bgLabelG.selectAll('text').data(this.backgroundData, (d) => d.index);
        const enterText = updateText.enter()
            .append('text')
            .text(d => d.label)
            .attr('text-anchor', 'middle')
            .attr('x', d => d.x + TREE_COLUMN_WIDTH / 2)
            .attr('fill', '#000000')
            .attr('y', -20)
            .attr('font-size', NODE_TEXT_SIZE);
        updateText.exit().remove();
    }
    highlight(id) {
        const node = this.TreeNodesCurrentMap?.get(id);
        if (!node) {
            return;
        }
        let nodeIds = [];
        let edgeIds = [];
        node._NodesOfRoutesSet.forEach(id => {
            nodeIds.push(id);
        });
        node._EdgesOfRoutesSet.forEach(id => {
            edgeIds.push(id);
        });
        this.nodes.setParams({
            ...this.nodes.params,
            highlightStartId: id,
            highlightIds: nodeIds
        });
        this.edges.setParams({
            ...this.edges.params,
            highlightIds: edgeIds
        });
    }
    removeHighlight() {
        this.nodes.setParams({
            ...this.nodes.params,
            highlightStartId: '',
            highlightIds: []
        });
        this.edges.setParams({
            ...this.edges.params,
            highlightIds: []
        });
    }
    toggleTopNodes(node, expanded) {
        if (!this.params.expandMode || this.params.expandMode == 'none') {
            return;
        }
        const findNode = this.TreeNodesCurrentMap.get(node.id);
        this.treeNodesCurrent[findNode.index].isExpanded = expanded;
        if (expanded) {
            // this.FoldNodeIdsSet.delete(node.id)
            if (this.params.expandMode == 'hierarchy') {
                let { treeNodes, treeNodesFiltered, treeEdges, treeEdgesFiltered } = addTopByHierarchy({
                    treeNodes: this.treeNodesCurrent,
                    treeNodesFiltered: this.treeNodesFiltered,
                    treeEdges: this.treeEdgesCurrent,
                    treeEdgesFiltered: this.treeEdgesFiltered,
                    originId: node.id
                });
                this.treeNodesCurrent = treeNodes;
                this.treeNodesFiltered = treeNodesFiltered;
                this.treeEdgesCurrent = treeEdges;
                this.treeEdgesFiltered = treeEdgesFiltered;
            }
            else if (this.params.expandMode == 'direction') {
                let { treeNodes, treeEdges } = addTopByDirection({
                    treeNodes: this.treeNodesCurrent,
                    treeNodesFiltered: this.treeNodesFiltered,
                    treeEdges: this.treeEdgesCurrent,
                    treeEdgesFiltered: this.treeEdgesFiltered,
                    rootId: this.rootId,
                    originId: node.id
                });
                this.treeNodesCurrent = treeNodes;
                this.treeEdgesCurrent = treeEdges;
            }
        }
        else {
            // this.FoldNodeIdsSet.add(node.id)
            if (this.params.expandMode == 'hierarchy') {
                let { treeNodes, treeNodesFiltered, treeEdges, treeEdgesFiltered } = deleteTopByHierarchy({
                    treeNodes: this.treeNodesCurrent,
                    treeNodesFiltered: this.treeNodesFiltered,
                    treeEdges: this.treeEdgesCurrent,
                    treeEdgesFiltered: this.treeEdgesFiltered,
                    BranchNodesMap: this.BranchNodesMap,
                    rootId: this.rootId,
                    originId: node.id
                });
                this.treeNodesCurrent = treeNodes;
                this.treeNodesFiltered = treeNodesFiltered;
                this.treeEdgesCurrent = treeEdges;
                this.treeEdgesFiltered = treeEdgesFiltered;
            }
            else if (this.params.expandMode == 'direction') {
                let { treeNodes, treeEdges } = deleteTopByDirection({
                    treeNodes: this.treeNodesCurrent,
                    treeEdges: this.treeEdgesCurrent,
                    rootId: this.rootId,
                    originId: node.id
                });
                this.treeNodesCurrent = treeNodes;
                this.treeEdgesCurrent = treeEdges;
            }
        }
        // let FilteredBranchNodesMap: Map<string, Array<TreeUpNodeDatum>> = new Map()
        // if (this.FoldNodeIdsSet.size == 0) {
        //   FilteredBranchNodesMap = this.BranchNodesMap
        // } else {
        //   const FilteredSortedTwigNodesMap = makeSortedTwigNodesMap(this.treeNodesFiltered!)
        //   FilteredBranchNodesMap = makeBranchNodesMap(FilteredSortedTwigNodesMap, this.rootId)
        // }
        // // 紀錄所有要刪除的nodes（所有縮合節點的祖先節點）
        // const WillDeleteNodesSet = new Set()
        // this.FoldNodeIdsSet.forEach(nodeId => {
        //   const deletedNodes = FilteredBranchNodesMap.get(nodeId)
        //   deletedNodes!.forEach(node => {
        //     WillDeleteNodesSet.add(node.id)
        //   })
        // })
        // this.treeNodes = Object.assign([], this.treeNodesFiltered)
        // this.treeEdges = Object.assign([], this.treeEdgesFiltered)
        // this.treeNodes = this.treeNodes
        //   .filter(node => {
        //     return WillDeleteNodesSet.has(node.id) == false
        //   })
        //   .map((node, index) => {
        //     // 過濾 routeData
        //     const _routesDataUp = node._routesDataUp.filter(routeData => {
        //       return routeData['routes-in-id'].find(id => {
        //         return WillDeleteNodesSet.has(id)
        //       }) == null
        //     })
        //     return {
        //       ...node,
        //       index, // 過濾後修正 index
        //       _routesDataUp
        //     }
        //   })
        // this.treeEdges = this.treeEdges
        //   .filter(edge => {
        //     return WillDeleteNodesSet.has(edge.startNodeId) == false
        //   })
        this.render();
    }
}
