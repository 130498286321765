import * as d3 from 'd3';
import ChartDateColumn from './ChartDateColumnTwoScales';
import GraphicColumnBar from '../graphicColumnBar/GraphicColumnBar';
import GraphicColumnLine from '../graphicColumnLine/GraphicColumnLine';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS } from './defaults';
export default class ChartDateColumnBar extends ChartDateColumn {
    params = DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS;
    bar;
    line;
    dot;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBar(this.y1GraphicGSelection, {});
        this.line = new GraphicColumnLine(this.y2GraphicGSelection, {});
        this.dot = new GraphicDot(this.coverSelection, {});
    }
    getY1MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    getY2MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
        };
        this.params.graphicColumnBar = {
            ...this.params.graphicColumnBar,
            colors: this.params.y1Colors,
            highlightDatumId: this.params.highlightDatumId,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget !== 'group' ? this.params.highlightTarget : 'none'
        };
        this.params.graphicColumnLine = {
            ...this.params.graphicColumnLine,
            colors: this.params.y2Colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.params.graphicDot = {
            ...this.params.graphicDot,
            colors: this.params.y2Colors,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.bar.setParams(this.params.graphicColumnBar);
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
        this.line.setParams(this.params.graphicColumnLine);
        this.line
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: this.params.highlightItemId,
                });
                this.dot.render();
            }
        });
        this.dot.setParams(this.params.graphicDot);
        this.dot
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line.render();
            }
        });
    }
    setGraphicData({ y1RenderData, y2RenderData, y1ItemLabels, y2ItemLabels, xLabels, y1Scale, y2Scale }) {
        this.bar.setDataset({
            data: y1RenderData[0] ?? [],
            itemLabels: y1ItemLabels,
            axisWidth: this.axisWidth,
            zeroY: y1Scale(0),
        });
        this.line.setDataset({
            data: y2RenderData,
            itemLabels: y2ItemLabels,
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: y2Scale(0)
        });
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            const dotData = y2RenderData.flat();
            this.dot.setDataset({
                data: dotData,
                itemLabels: y2ItemLabels
            });
        }
    }
    renderGraphic() {
        this.bar.render();
        this.line.render();
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            this.dot.render();
        }
    }
    renderGraphicCover({ coverSelection, y1FilteredData, y2FilteredData, xScale, y1Scale, y2Scale, y1ItemLabels, y2ItemLabels }) {
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            const dotData = y2FilteredData
                .map(d => {
                return d.map(_d => {
                    return {
                        ..._d,
                        x: xScale(_d.Date),
                        y: y2Scale(_d.value)
                    };
                });
            })
                .flat();
            this.dot.setDataset({
                data: dotData,
                itemLabels: y2ItemLabels
            });
            this.dot.render();
        }
    }
    renderGraphicCoverHighlight({ coverSelection, eventData, xScale, y1Scale, y2Scale, y1ItemLabels, y2ItemLabels }) {
        // 非顯示全部圓點時 highlight圓點
        if (this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            const dotData = eventData
                ? eventData.y2.groupData.map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.Date),
                        y: y2Scale(d.value)
                    };
                })
                : [];
            this.dot.setDataset({
                data: dotData,
                itemLabels: y2ItemLabels
            });
            this.dot.render();
        }
    }
}
