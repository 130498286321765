import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS } from '../axisDateColumnTwoScales';
import { DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS } from '../graphicColumnLine';
import { DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS } from '../graphicColumnBar';
import { DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS } from '../graphicColumnBarStack';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from '../graphicDot';
const reversedDefaultColor = DEFAULT_COLORS.map(d => d).reverse();
export const DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_PARAMS = {
    axisDateColumnTwoScales: DEFAULT_AXIS_DATE_COLUMN_TWO_SCALES_PARAMS,
    padding: DEFAULT_PADDING,
    tooltipAside: {
        templateHtml: (data) => {
            if (data.y1.groupData && data.y2.groupData) {
                return `
          <div>
            <div>${data.xLabel}</div>
            <div>${data.y1.groupData.map((d) => d.value).join(',')}</div>          
            <div>${data.y2.groupData.map((d) => d.value).join(',')}</div>          
          </div>
        `;
            }
            return '';
        },
    },
    y1Colors: DEFAULT_COLORS,
    y2Colors: reversedDefaultColor,
    backgroundColor: '',
    zoom: true,
    showAuxLine: true,
    highlightTarget: 'datum',
    highlightItemId: '',
    highlightGroupId: '',
    highlightDatumId: '',
    domainMaxRange: 0.95,
    showDot: 'hover'
};
export const DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_AND_LINE_PARAMS = {
    ...DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_PARAMS,
    graphicColumnBar: DEFAULT_GRAPHIC_COLUMN_BAR_PARAMS,
    graphicColumnLine: DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS,
    graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS
};
export const DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_BAR_STACK_AND_LINE_PARAMS = {
    ...DEFAULT_CHART_DATE_COLUMN_TWO_SCALES_PARAMS,
    graphicColumnBarStack: DEFAULT_GRAPHIC_COLUMN_BAR_STACK_PARAMS,
    graphicColumnLine: DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS,
    graphicDot: DEFAULT_GRAPHIC_DOT_PARAMS,
};
