import { DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
export const DEFAULT_LABEL_ARC_PIE_PARAMS = {
    solidColor: undefined,
    colors: DEFAULT_COLORS,
    outerRadius: 1,
    // innerRadius: 0,
    enterDuration: 800,
    startAngle: 0,
    endAngle: Math.PI * 2,
    centroid: 2.3,
    fontSize: 12,
    text: d => String(d.value),
    highlightTarget: 'item',
    highlightDatumId: '',
    highlightItemId: '',
};
