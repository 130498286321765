import { DEFAULT_COLORS, DEFAULT_PADDING, DEFAULT_ASIX_LINE_COLOR, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_AXIS_COLUMN_TWO_SCALES_PARAMS = {
    xLabel: 'x',
    y1Label: 'y1',
    y2Label: 'y2',
    xLabelAnchor: 'start',
    y1LabelAnchor: 'end',
    y2LabelAnchor: 'start',
    xLabelOffset: [0, 0],
    y1LabelOffset: [0, 0],
    y2LabelOffset: [0, 0],
    y1LabelColor: DEFAULT_AXIS_LABEL_COLOR,
    y2LabelColor: DEFAULT_AXIS_LABEL_COLOR,
    y1TickFormat: ',.0f',
    y2TickFormat: ',.0f',
    xTickPadding: 20,
    y1TickPadding: 20,
    y2TickPadding: 20,
    xTickLine: false,
    y1TickLine: 1,
    y2TickLine: 1,
    xTickLineDasharray: 'none',
    y1TickLineDasharray: 'none',
    y2TickLineDasharray: '4',
    y1Ticks: 4,
    y2Ticks: 4,
    axisLineColor: DEFAULT_ASIX_LINE_COLOR,
    axisLabelColor: DEFAULT_AXIS_LABEL_COLOR,
    rotateXLabel: false,
    padding: DEFAULT_PADDING,
    xVisible: true,
    y1Visible: true,
    y2Visible: true,
};
