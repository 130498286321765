import { DEFAULT_COLORS, DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
export const DEFAULT_GRAPHIC_COLUMN_LINE_PARAMS = {
    lineType: 'line',
    // lineCurve: 'curveMonotoneX',
    lineCurve: 'curveLinear',
    colors: DEFAULT_COLORS,
    // axisWidth: 0,
    // axisHeight: 0,
    // dot: false,
    highlightTarget: 'item',
    highlightItemId: '',
    enterDuration: 800,
    // labelTextMethod: (d: Datum, i: [number, number]) => String(d.value),
    labelTextMethod: (d, i) => '',
    labelPositionMethod: (d, i) => 'top',
    labelStyleMethod: (d, i) => '',
    labelFontSizeMethod: (d, i) => 10,
    labelColorMethod: (d, i) => DEFAULT_AXIS_LABEL_COLOR,
    labelPadding: 15
};
