import { GraphicDot } from '@bpchart/d3-modules/graphicDot';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_DOT_PARAMS } from '@bpchart/d3-modules/graphicDot';
export default {
    props: {
        module: GraphicDot,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_GRAPHIC_DOT_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
