import * as d3 from 'd3';
import { DEFAULT_PADDING, DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_DATE_RANK_PARAMS } from './defaults';
// import { Padding } from '../types'
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import { parseDateTickFormatValue } from '../d3Utils';
const defaultTickSize = 6;
export default class AxisDateRank {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        xScale: d3.scaleTime(),
        yScale: d3.scaleLinear(),
        yLabels: [],
        labelSeq: [],
        rowAmount: 0,
        dateAmount: 0
    };
    params = DEFAULT_AXIS_DATE_RANK_PARAMS;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    axisWidth = calcAxisWidth(this.width, DEFAULT_PADDING);
    axisHeight = calcAxisHeight(this.height, DEFAULT_PADDING);
    constructor(selection, params) {
        this.selection = selection;
        // this.chartSelection = this.selection.append('g')
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
    }
    render() {
        this.renderXDateAxis();
        this.renderYLabelAxis();
    }
    remove() {
        this.selection.remove();
    }
    renderXDateAxis() {
        const maxXTicks = Math.floor(this.axisWidth / this.params.xTickSpacing);
        const minXTicks = 1;
        // @Q@ 忘記為什麼要 -1 了，找時間確認一下
        let xTicks = this.dataset.dateAmount - 1;
        if (xTicks < minXTicks) {
            xTicks = minXTicks;
        }
        else if (xTicks > maxXTicks) {
            xTicks = maxXTicks;
        }
        const xAxisUpdate = this.selection
            .selectAll('g.xAxis')
            .data([this.params]);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.xAxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("xAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + (d.padding.top + this.axisHeight) + ")");
        xAxisUpdate.exit().remove();
        const xLabelUpdate = this.selection
            .selectAll('text.xLabel')
            .data([this.params]);
        const xLabelEnter = xLabelUpdate
            .enter()
            .append('text')
            .classed('xLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor)
            .style('dominant-baseline', 'hanging');
        this.xLabelSelection = xLabelUpdate.merge(xLabelEnter)
            .attr('x', d => this.width - d.padding.right + d.xTickPadding)
            .attr('y', d => d.padding.top + this.axisHeight + d.yTickPadding + defaultTickSize)
            .attr('transform', `translate(${this.params.xLabelOffset[0]}, ${this.params.xLabelOffset[1]})`)
            .attr('text-anchor', this.params.xLabelAnchor)
            .text(d => d.xLabel);
        xLabelUpdate.exit().remove();
        // 設定X軸刻度
        this.xAxis = d3.axisBottom(this.dataset.xScale)
            .scale(this.dataset.xScale)
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            .tickFormat(d => parseDateTickFormatValue(d, this.params.xTickFormat))
            .tickPadding(this.params.xTickPadding)
            .ticks(xTicks);
        const xAxisEl = this.xAxisSelection
            .transition()
            .duration(100)
            .call(this.xAxis)
            .attr('text-anchor', () => this.params.rotateXLabel !== false ? 'end' : 'middle'); // 如果要旋轉的話靠字尾對齊
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        const xText = xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            // .style('font-weight', 'bold')
            .style('color', this.params.axisLabelColor)
            .attr('transform-origin', `-5 ${this.params.xTickPadding + defaultTickSize}`);
        if (this.params.rotateXLabel === true) {
            xText.attr('transform', 'translate(0,0) rotate(-45)');
        }
        else if (typeof this.params.rotateXLabel === 'number') {
            xText.attr('transform', `translate(0,0) rotate(${this.params.rotateXLabel})`);
        }
    }
    renderYLabelAxis() {
        const textPadding = 10;
        const textUpdate = this.selection
            .selectAll("text.yAxisLabel")
            .data(this.dataset.yLabels, d => d);
        const textEnter = textUpdate
            .enter()
            .append("text")
            .classed('yAxisLabel', true)
            .text(d => d)
            .style("font-size", "14px")
            .style('color', this.params.axisLabelColor);
        const text = textUpdate
            .merge(textEnter);
        text
            .transition()
            .duration(500)
            .attr("transform", (xd, xi) => `translate(${textPadding}, ${this.params.padding.top + this.dataset.yScale(this.dataset.labelSeq[xi])})`)
            .attr("opacity", (d, i) => i < this.dataset.rowAmount ? 1 : 0);
        textUpdate.exit().remove();
        const yLabelUpdate = this.selection
            .selectAll('text.yLabel')
            .data([this.params]);
        const yLabelEnter = yLabelUpdate
            .enter()
            .append('text')
            .classed('yLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor);
        this.yLabelSelection = yLabelUpdate.merge(yLabelEnter)
            .attr('x', d => textPadding)
            .attr('y', d => d.padding.top - d.yTickPadding)
            .attr('transform', `translate(${this.params.yLabelOffset[0]}, ${this.params.yLabelOffset[1]})`)
            .attr('text-anchor', this.params.yLabelAnchor)
            .text(d => d.yLabel);
        yLabelUpdate.exit().remove();
    }
}
