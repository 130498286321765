export default {
    "data": [
        {
            "data": {
                "itemLabel": "讚",
                "value": 120,
                "arcIndex": 0,
                "arcLabel": "讚",
                "itemIndex": 0,
                "id": "讚"
            },
            "index": 0,
            "value": 120,
            "startAngle": 0,
            "endAngle": 2.7417535885874558,
            "padAngle": 0,
            "itemIndex": 0,
            "itemLabel": "讚",
            "id": "讚"
        },
        {
            "data": {
                "itemLabel": "大心",
                "value": 55,
                "arcIndex": 1,
                "arcLabel": "大心",
                "itemIndex": 1,
                "id": "大心"
            },
            "index": 1,
            "value": 55,
            "startAngle": 2.7417535885874558,
            "endAngle": 3.998390650023373,
            "padAngle": 0,
            "itemIndex": 1,
            "itemLabel": "大心",
            "id": "大心"
        },
        {
            "data": {
                "itemLabel": "哈",
                "value": 45,
                "arcIndex": 2,
                "arcLabel": "哈",
                "itemIndex": 2,
                "id": "哈"
            },
            "index": 2,
            "value": 45,
            "startAngle": 3.998390650023373,
            "endAngle": 5.026548245743669,
            "padAngle": 0,
            "itemIndex": 2,
            "itemLabel": "哈",
            "id": "哈"
        },
        {
            "data": {
                "itemLabel": "哇",
                "value": 30,
                "arcIndex": 3,
                "arcLabel": "哇",
                "itemIndex": 3,
                "id": "哇"
            },
            "index": 3,
            "value": 30,
            "startAngle": 5.026548245743669,
            "endAngle": 5.711986642890533,
            "padAngle": 0,
            "itemIndex": 3,
            "itemLabel": "哇",
            "id": "哇"
        },
        {
            "data": {
                "itemLabel": "嗚",
                "value": 13,
                "arcIndex": 4,
                "arcLabel": "嗚",
                "itemIndex": 4,
                "id": "嗚"
            },
            "index": 4,
            "value": 13,
            "startAngle": 5.711986642890533,
            "endAngle": 6.009009948320841,
            "padAngle": 0,
            "itemIndex": 4,
            "itemLabel": "嗚",
            "id": "嗚"
        },
        {
            "data": {
                "itemLabel": "怒",
                "value": 12,
                "arcIndex": 5,
                "arcLabel": "怒",
                "itemIndex": 5,
                "id": "怒"
            },
            "index": 5,
            "value": 12,
            "startAngle": 6.009009948320841,
            "endAngle": 6.283185307179587,
            "padAngle": 0,
            "itemIndex": 5,
            "itemLabel": "怒",
            "id": "怒"
        }
    ],
    "itemLabels": [
        "讚",
        "大心",
        "哈",
        "哇",
        "嗚",
        "怒"
    ],
    "axisWidth": 400
};
