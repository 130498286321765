import { GraphicArcPie } from '@bpchart/d3-modules/graphicArcPie';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_ARC_PIE_PARAMS } from '@bpchart/d3-modules/graphicArcPie';
export default {
    props: {
        module: GraphicArcPie,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_GRAPHIC_ARC_PIE_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {},
        enterDuration: {}
    }
};
