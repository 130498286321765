import { makeAxisPointScale, makeAxisLinearScale } from '@bpchart/d3-modules/moduleUtils';
const xLabels = ['2016', '2017', '2018', '2019', '2020'];
const axisWidth = 660;
const axisHeight = 400;
const maxValue = 150;
export default {
    xScale: makeAxisPointScale({
        axisLabels: xLabels,
        axisWidth: axisWidth,
    }),
    yScale: makeAxisLinearScale({
        maxValue: maxValue,
        minValue: 0,
        axisWidth: axisHeight,
        domainMinValue: 0,
        domainMaxValue: undefined,
        domainMinRange: undefined,
        domainMaxRange: 0.95,
        reverse: true
    }),
    maxValue
};
