import { GraphicColumnAreaStack } from '@bpchart/d3-modules/graphicColumnAreaStack';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import dataset from './dataset';
import { DEFAULT_GRAPHIC_COLUMN_AREA_STACK_PARAMS } from '@bpchart/d3-modules/graphicColumnAreaStack';
export default {
    props: {
        module: GraphicColumnAreaStack,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_GRAPHIC_COLUMN_AREA_STACK_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
