import * as d3 from 'd3';
import Legend from './Legend';
export default class LegendBottom extends Legend {
    itemSelection = undefined;
    mouseoverCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    highlightOnHover = false;
    constructor(selection, params) {
        super(selection, params);
        if (params.highlight != undefined) {
            this.highlightOnHover = params.highlight;
        }
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    renderLegend(containerSelection, data) {
        const update = containerSelection
            .selectAll('.bp__type-description__item')
            .data(data);
        const enter = update.enter()
            .append('li')
            .style('display', 'flex')
            .style('align-items', 'center')
            .classed('bp__type-description__item', true)
            .style('margin-right', '30px')
            .style('cursor', 'default');
        this.itemSelection = update.merge(enter)
            .style('color', (d) => this.colorScale(d.dataType))
            .html((d) => {
            return `
            <div style="margin-right:8px;width:14px;height:14px;border-radius:999em;background-color:${this.colorScale(d.dataType)}"></div>
            <div>${d.label}</div>
          `;
        });
        update.exit().remove();
        this.itemSelection
            .on('click', (d) => {
            d3.event.stopPropagation();
            const callbackData = {
                // ...d  as LegendBottomRenderData,
                data: d
            };
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            d3.event.stopPropagation();
            const callbackData = {
                // ...d  as LegendBottomRenderData,
                data: d
            };
            if (this.highlightOnHover) {
                this.highlight(d.dataType);
            }
            this.mouseoverCallback(callbackData);
        })
            .on('mouseout', (d) => {
            d3.event.stopPropagation();
            const callbackData = {
                // ...d  as LegendBottomRenderData,
                data: d
            };
            if (this.highlightOnHover) {
                this.removeHighlight();
            }
            this.mouseoutCallback(callbackData);
        });
    }
    highlight(dataType) {
        this.itemSelection.each((d, i, n) => {
            if (d.dataType === dataType) {
                this.itemSelection.style('opacity', 0.3);
                d3.select(n[i]).style('opacity', 1);
            }
        });
    }
    removeHighlight() {
        this.itemSelection.style('opacity', 1);
    }
}
