import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
const tagSize = 24;
const tagTextSize = 12;
export default class ChartDirected {
    selection;
    params = {
        styles: {},
        scaleExtent: {
            min: 0,
            max: Infinity // 最大放大比
        }
    };
    dataset = {
        expandAll: false
    };
    transform = {
        x: 0,
        y: 0,
        k: 1
    };
    zoom = {
        xOffset: 0,
        yOffset: 0,
        scaleExtent: this.params.scaleExtent
    };
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    svgGroup;
    d3Zoom = undefined;
    // private zoom?: d3.ZoomBehavior<any, any> = undefined
    constructor(el, params) {
        this.selection = el;
        this.svgGroup = this.selection.append('g');
        this.params = params;
        // const { width, height } = this.getContainerSize()
        // this.width = width
        // this.height = height
    }
    remove() {
        this.selection.remove();
    }
    select() {
        return this.selection;
    }
    // 設置拖拽及放大縮小
    initZoom(zoom = this.zoom) {
        this.zoom = {
            ...this.zoom,
            ...zoom
        };
        this.selection.on('zoom', null);
        this.d3Zoom = d3.zoom().on('zoom', () => {
            this.svgGroup.attr('transform', `translate(
        ${d3.event.transform.x + (this.zoom.xOffset * d3.event.transform.k)},
        ${d3.event.transform.y + (this.zoom.yOffset * d3.event.transform.k)}
      ) scale(
        ${d3.event.transform.k}
      )`);
        });
        if (this.zoom.scaleExtent) {
            this.d3Zoom.scaleExtent([this.zoom.scaleExtent.min, this.zoom.scaleExtent.max]);
        }
        this.selection.call(this.d3Zoom);
    }
    // 目前好像不需要
    removeZoom() {
        this.d3Zoom = d3.zoom()
            .on('zoom', null);
    }
    transformZoom(transform) {
        this.transform = transform;
        // 設定 d3.event.transform 並觸發 d3.zoom().on()
        if (this.d3Zoom && this.d3Zoom.transform) {
            this.selection.call(this.d3Zoom.transform, d3.zoomIdentity
                .translate(this.transform.x, this.transform.y)
                .scale(this.transform.k));
        }
    }
    // protected getContainerSize (): { width: number; height: number; x: number; y: number } {
    //   // 取得圖表container (div)的尺寸
    //   try {
    //     const node = this.selection.node()
    //     return node!.getBoundingClientRect()
    //   } catch (e: any) {
    //     throw(e)
    //   }
    // }
    returnPlusSvgHtml(style = 'fill:#1778f5') {
        return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <polygon style="fill:#ffffff;" points="443.811,297.202 443.811,214.798 297.202,214.798 297.202,68.189 214.799,68.189   214.798,214.798 68.189,214.799 68.189,297.202 214.798,297.202 214.798,443.811 297.201,443.811 297.202,297.202 "/>
    `;
    }
    returnMinusSvgHtml(style = 'fill:#1778f5') {
        return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <rect x="81.679" y="214.535" style="fill:#ffffff;" width="348.646" height="82.922"/>
    `;
    }
    returnTagHtml(style = 'fill:#f56c6c', text = '') {
        const circleR = tagSize / 2;
        const padding = (tagSize - tagTextSize) / 2;
        return `
    <circle style="${style};" cx="${circleR}" cy="${circleR}" r="${circleR}"/>
    <text x="${padding}" y="${tagTextSize + padding - 2}" fill="#ffffff" font-size="${tagTextSize}" font-weight="bold">${text}</text>
    `;
    }
}
