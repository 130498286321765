// -- node --
export const NODE_WIDTH_DEFAULT = 200; // 無設定時的預設寬度
export const NODE_HEIGHT_MIN = 42; // 最小高度
export const NODE_RADIUS_DEFAULT = 8;
export const NODE_RADIUS_ROUND = NODE_HEIGHT_MIN / 2;
export const NODE_TOP = -NODE_HEIGHT_MIN / 2;
export const NODE_TEXT_SIZE = NODE_HEIGHT_MIN / 3;
export const NODE_TEXT_TOP = NODE_TEXT_SIZE / 2 - 2;
export const NODE_TEXT_LEFT = NODE_TEXT_SIZE;
export const NODE_TEXT_WIDTH_DEFAULT = NODE_WIDTH_DEFAULT - NODE_TEXT_LEFT * 2;
export const NODE_TAG_SIZE = 18;
export const NODE_TAG_TEXT = 10;
export const NODE_TAG_TOP_DEFAULT = NODE_HEIGHT_MIN / 2 - NODE_TAG_SIZE / 2;
export const NODE_TAG_STEP = NODE_TAG_SIZE * 1.3;
// export const NODE_HIGHLIGHT_COLOR = '#1778f5'
export const NODE_HIGHLIGHT_COLOR = 'red';
// -- edge --
export const EDGE_CURVE = 20; // 弧線大小
export const EDGE_SIDE_Y_OFFSET = 9; // 如果同時有流出和流入時 Y軸偏移
export const EDGE_COUNT_TAG_LEFT = 25;
export const EDGE_ARROW_COLOR = '#909399';
export const EDGE_LABEL_COLOR = '#303133';
// export const EDGE_HIGHLIGHT_COLOR = '#1778f5'
export const EDGE_HIGHLIGHT_COLOR = 'red';
// -- 網格 --
// (實質受益人)
export const PADDING_LEFT = 50;
export const PADDING_TOP = 50;
export const PADDING_BOTTOM = 50;
export const MIDDLE_OFFCET_X = -(NODE_WIDTH_DEFAULT / 2);
export const TREE_ROW_HEIGHT = NODE_HEIGHT_MIN * 1.6; // 樹狀圖網格高度
export const TREE_COLUMN_WIDTH = NODE_WIDTH_DEFAULT * 1.1; // 樹狀圖網格寬度
export const SIDE_STEP = TREE_ROW_HEIGHT; // 外環線每一步的距離
// export const STRIPE_COLOR = '#F0F6FE'
export const STRIPE_COLOR = '#F4F4F4';
export const LINE_ROW_HEIGHT = NODE_HEIGHT_MIN * 2.5; // 線圖網格高度
export const LINE_COLUMN_WIDTH = NODE_WIDTH_DEFAULT * 1.3; // 線圖網格寬度
export const LINE_TITLE_HEIGHT = 36;
// (v2)
export const TREE_FULL_ROW_HEIGHT = TREE_ROW_HEIGHT;
export const TREE_FULL_COLUMN_WIDTH = TREE_COLUMN_WIDTH;
export const WAY_ROW_LINE_STEP = 23; // 通道線條距離
export const WAY_COLUMN_LINE_STEP = 36; // 通道線條距離
// export const WAY_ROW_LINE_FROM_NODE = 26 // row線條跟node的距離（node的y座標至中且node高度不一，所以寫死一段合理的間距）
// export const WAY_EDGE_SPACING = WAY_EDGE_STEP / 2 // 通道線條間距（線與線的間距）
// export const WAY_DEFAULT_X = 
// -- 篩選初始值 --
export const DEFAULT_STOCK_MIN = 0; // 初始篩選值
export const DEFAULT_STOCK_EXCEPT_ZERO = false; // 初始是否排除0值
