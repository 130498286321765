import { ChartDateColumnTwoScalesBarStackAndLine } from '@bpchart/d3-modules/chartDateColumnTwoScales';
import dataset from './dataset_蛛思_加密貨幣';
export default {
    props: {
        module: ChartDateColumnTwoScalesBarStackAndLine,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
