export default {
    "data": [
        [
            {
                "value": 55,
                "id": "進口_2016",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 0,
                "yLabel": "2016",
                "x": 86.20040509259259,
                "y": 360
            },
            {
                "value": 80,
                "id": "進口_2017",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 1,
                "yLabel": "2017",
                "x": 125.38240740740741,
                "y": 280
            },
            {
                "value": 50,
                "id": "進口_2018",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 2,
                "yLabel": "2018",
                "x": 78.36400462962963,
                "y": 200
            },
            {
                "value": 110,
                "id": "進口_2019",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 3,
                "yLabel": "2019",
                "x": 172.40081018518518,
                "y": 120
            },
            {
                "value": 150,
                "id": "進口_2020",
                "itemIndex": 0,
                "itemLabel": "進口",
                "yIndex": 4,
                "yLabel": "2020",
                "x": 235.09201388888889,
                "y": 40
            }
        ],
        [
            {
                "value": 35,
                "id": "出口_2016",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 0,
                "yLabel": "2016",
                "x": 54.854803240740736,
                "y": 360
            },
            {
                "value": 40,
                "id": "出口_2017",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 1,
                "yLabel": "2017",
                "x": 62.69120370370371,
                "y": 280
            },
            {
                "value": 15,
                "id": "出口_2018",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 2,
                "yLabel": "2018",
                "x": 23.50920138888889,
                "y": 200
            },
            {
                "value": 65,
                "id": "出口_2019",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 3,
                "yLabel": "2019",
                "x": 101.87320601851852,
                "y": 120
            },
            {
                "value": 120,
                "id": "出口_2020",
                "itemIndex": 1,
                "itemLabel": "出口",
                "yIndex": 4,
                "yLabel": "2020",
                "x": 188.07361111111112,
                "y": 40
            }
        ]
    ],
    "yLabels": [
        "2016",
        "2017",
        "2018",
        "2019",
        "2020"
    ],
    "itemLabels": [
        "進口",
        "出口"
    ],
    "axisHeight": 400,
    "zeroX": 0
};
