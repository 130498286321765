export default {
    "data": [
        {
            "value": 55,
            "id": "心",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 0,
            "yLabel": "心",
            "x": 135.180546875,
            "y": 366.6666666666667
        },
        {
            "value": 150,
            "id": "讚",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 1,
            "yLabel": "讚",
            "x": 368.67421875,
            "y": 300
        },
        {
            "value": 45,
            "id": "哈",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 2,
            "yLabel": "哈",
            "x": 110.60226562500002,
            "y": 233.33333333333331
        },
        {
            "value": 2,
            "id": "怒",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 3,
            "yLabel": "怒",
            "x": 4.91565625,
            "y": 166.66666666666666
        },
        {
            "value": 3,
            "id": "嗚",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 4,
            "yLabel": "嗚",
            "x": 7.373484374999999,
            "y": 99.99999999999999
        },
        {
            "value": 30,
            "id": "哇",
            "itemIndex": 0,
            "itemLabel": "__DEFAULT__",
            "yIndex": 5,
            "yLabel": "哇",
            "x": 73.73484375,
            "y": 33.333333333333314
        }
    ],
    "itemLabels": [
        "__DEFAULT__"
    ],
    "axisHeight": 400,
    "zeroX": 0
};
