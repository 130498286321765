import { LabelArcPie } from '@/packages/d3-modules/labelArcPie';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import { DEFAULT_LABEL_ARC_PIE_PARAMS } from '@/packages/d3-modules/labelArcPie';
import dataset from './dataset';
export default {
    props: {
        module: LabelArcPie,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_LABEL_ARC_PIE_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
