import ChartScoutRoute from './ChartScoutRoute';
import * as d3 from 'd3';
import Nodes, {} from './Nodes';
import Edges, {} from './Edges';
// import { getLabelInfo, parseRoutesData } from './utils'
import { PADDING_TOP, PADDING_LEFT, LINE_COLUMN_WIDTH, LINE_ROW_HEIGHT, LINE_TITLE_HEIGHT, EDGE_COUNT_TAG_LEFT, EDGE_CURVE, EDGE_SIDE_Y_OFFSET, NODE_WIDTH_DEFAULT, NODE_HEIGHT_MIN, NODE_TEXT_LEFT, NODE_TEXT_SIZE, NODE_TEXT_WIDTH_DEFAULT, SIDE_STEP } from './const';
// import { ChartDirectedTreeSourceEdge } from '../chartDirected/ChartDirectedTree/ChartDirectedTree'
import { CHART_SCOUT_ROUTE_LINES_PARAMS } from './defaults';
const defaultStockMin = 0;
const defaultStockExceptZero = false;
const defaultSort = {
    'routes-of-stock-detail-single-value': -1
};
export default class ChartScoutRouteLines extends ChartScoutRoute {
    selection;
    params = CHART_SCOUT_ROUTE_LINES_PARAMS;
    filterConfig = {
        stockMin: defaultStockMin,
        stockExceptZero: defaultStockExceptZero,
        sort: defaultSort
    };
    // private NodesDataMap: Map<string, LinesNodeData> | undefined
    nodesData;
    edgesData;
    routesOrigin = [];
    routes = [];
    // private routesG: d3.Selection<SVGGElement, Routes, any, any>
    containerColumnCount = 0; // 容器的欄數量
    // private eventTransform: { k:number; x:number; y:number } | undefined
    sourceNodes = [];
    targetId;
    // private routes: Array<RoutesData> = []
    titleTextG;
    titleText;
    // private updateConfig: ChartScoutRouteLinesUpdateConfig | undefined
    constructor(el, params) {
        super(el, params);
        this.selection = el;
        // this.setParams(params)
        this.nodes = new Nodes(this.nodesG, {
            ...params,
            expand: false
        });
        this.edges = new Edges(this.edgesG, {});
        const { width, height } = this.getContainerSize();
        this.width = width;
        this.height = height;
        // 場景的欄數量
        this.containerColumnCount = this.getContainerColumnCount(this.width);
        this.titleTextG = this.svgGroup.append('g');
        // this.initZoom()
    }
    setDataset(dataset) {
        this.dataset = dataset;
        this.sourceNodes = dataset.nodes;
        this.targetId = dataset.targetId;
        if (dataset.sort && dataset.sort['routes-of-stock-detail-single-value'] != undefined) {
            this.filterConfig.sort['routes-of-stock-detail-single-value'] = dataset.sort['routes-of-stock-detail-single-value'];
        }
        // 必須設定 targetId 才開始計算路徑及繪圖
        // if (this.targetId) {
        // 回復預設 zoom
        this.transformZoom({
            x: 0,
            y: 0,
            k: 1
        });
        if (this.dataset.mainRelatedToRoot === 'up') {
            this.routesOrigin = this.createRoutesData(this.sourceNodes, this.targetId);
        }
        else if (this.dataset.mainRelatedToRoot === 'down') {
            this.routesOrigin = this.createRoutesDownData(this.sourceNodes, this.targetId);
            // console.log('this.routesOrigin', this.routesOrigin)
        }
        else {
            return;
        }
        this.routes = Object.assign(this.routesOrigin);
        // 如有設定 stockMin才進行篩選
        this.filterConfig.stockMin = dataset.stockMin != undefined ? dataset.stockMin : this.filterConfig.stockMin;
        this.filterConfig.stockExceptZero = dataset.stockExceptZero != undefined ? dataset.stockExceptZero : this.filterConfig.stockExceptZero;
        if (this.filterConfig.stockMin >= 0 || this.filterConfig.stockExceptZero == true) {
            this.routes = this.filterRoutes(this.routes, this.filterConfig, this.dataset.mainRelatedToRoot);
        }
        this.routes = this.updateRoutesDataPosition(this.routes, this.containerColumnCount);
        // console.log('this.routes', this.routes)
        // this.render()
        // }
    }
    render() {
        this.nodesData = this.routes.map(d => d.nodesData).flat();
        this.edgesData = this.routes.map(d => d.edgesData).flat();
        this.nodes.setData(this.nodesData);
        this.edges.setData(this.edgesData);
        // console.log('this.nodesData', this.nodesData)
        // console.log('this.edgesData', this.edgesData)
        this.nodes.render();
        this.edges.render();
        const titleTextUpdate = this.titleTextG
            .selectAll('text')
            .data(this.routes, d => d.index);
        const titleTextEnter = titleTextUpdate.enter()
            .append('text')
            .text(d => {
            return d.title;
        })
            .attr('font-size', 14)
            .style('cursor', 'default');
        titleTextEnter
            .attr('x', d => d.x)
            .attr('y', 0)
            .transition()
            .attr('x', d => d.x)
            .attr('y', d => d.y);
        titleTextUpdate
            .text(d => {
            return d.title;
        })
            .transition()
            .attr('x', d => d.x)
            .attr('y', d => d.y);
        this.titleText = titleTextUpdate.merge(titleTextEnter);
        // .transition()
        // .attr('x', d => d.x)
        // .attr('y', d => d.y)
        titleTextUpdate.exit().remove();
    }
    filter(filterConfig) {
        filterConfig = {
            stockMin: defaultStockMin,
            stockExceptZero: defaultStockExceptZero,
            sort: defaultSort,
            ...filterConfig
        };
        // if (!this.nodesData || !this.edgesData) {
        //   return
        // }
        this.filterConfig.stockMin = filterConfig.stockMin;
        this.filterConfig.stockExceptZero = filterConfig.stockExceptZero;
        this.filterConfig.sort = {
            ...filterConfig.sort
        };
        this.routes = Object.assign(this.routesOrigin);
        this.routes = this.filterRoutes(this.routes, this.filterConfig, this.dataset.mainRelatedToRoot);
        this.routes = this.updateRoutesDataPosition(this.routes, this.containerColumnCount);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        // targetId和原本不一樣才開始計算
        // if (this.data && config.targetId && this.targetId !== config.targetId) {
        //   // 回復預設 zoom
        //   this.transformZoom({
        //     x: 0,
        //     y: 0,
        //     k: 1
        //   })
        //   this.targetId = config.targetId
        //   this.routesOrigin = this.createRoutesData(this.sourceNodes, this.targetId)
        //   this.render()
        // }
        // if (config.transform != undefined) {
        //   this.transform = config.transform
        //   this.initZoom()
        // }
    }
    resize({ width, height }) {
        this.width = width;
        this.height = height;
        this.selection.attr('width', this.width);
        this.selection.attr('height', this.height);
        // 場景的欄數量改變的話重新計算資料位置
        const containerColumnCount = this.getContainerColumnCount(this.width);
        if (this.containerColumnCount !== containerColumnCount && this.routes.length) {
            this.containerColumnCount = containerColumnCount;
            this.routes = this.updateRoutesDataPosition(this.routes, this.containerColumnCount);
            this.render();
        }
        if (this.params && this.params.autoZoom) {
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
        }
    }
    onZoom(eventTransform) {
        // 場景的欄數量改變的話重新計算資料位置
        const containerColumnCount = this.getContainerColumnCount(this.width);
        if (this.containerColumnCount !== containerColumnCount && this.routes.length) {
            this.containerColumnCount = containerColumnCount;
            this.routes = this.updateRoutesDataPosition(this.routes, this.containerColumnCount);
            this.render();
        }
    }
    // 計算場景的欄數量
    getContainerColumnCount(width) {
        const k = this.transform.k; // 顯示放大比率
        const paddingLeft = PADDING_LEFT * k;
        const lineColumnWidth = LINE_COLUMN_WIDTH * k;
        const containerColumnCount = Math.floor((width - (paddingLeft * 2)) / lineColumnWidth);
        if (containerColumnCount > 3) {
            return containerColumnCount;
        }
        else {
            return 3; // 最少3欄
        }
    }
    getContainerSize() {
        // 取得圖表container (div)的尺寸
        try {
            const node = this.selection.node();
            return node.getBoundingClientRect();
        }
        catch (e) {
            throw (e);
        }
    }
    // 依起始點計算路徑資料
    createRoutesData(sourceNodes, targetId) {
        // 全部nodes資料
        const SourceNodesMap = new Map(sourceNodes.map(d => [d.uniID, d]));
        // 取得路徑起始nodes
        let startNodes = [];
        if (targetId) {
            startNodes = sourceNodes.filter(d => d.uniID === targetId);
        }
        else {
            // 無指定起始id則為全部
            startNodes = Object.assign([], sourceNodes);
        }
        // 所有路徑
        const routes = startNodes
            .map(d => {
            let routesData = [];
            // 非根節點
            if (d['routes-in-id']) {
                // 將路徑資料一起重新排序
                routesData = this.parseRoutesDataUp(d);
            }
            return routesData;
        })
            .flat() // 將多個 startNodes的資料攤平（有指定的話只會有一筆）
            .map((d, i) => {
            return {
                index: i,
                routesData: d,
                nodesData: [],
                edgesData: [],
                title: '',
                x: 0,
                y: 0 // * 後面再計算
            };
        });
        // 路徑上所有節點
        let nodeDataIndex = 0;
        return routes.map((d, routeIndex) => {
            let nodeIndex = 0;
            let nodesData = [];
            let edgesData = [];
            // -- 先寫入第一筆 --
            const firstEdgeId = d.routesData['routes-in-id'][d.routesData['routes-in-id'].length - 1];
            const nodesArr = firstEdgeId.split('->');
            const rootNode = SourceNodesMap.get(nodesArr[1]);
            nodesData.push(this.makeTreeNodes({
                node: rootNode,
                routeIndex,
                nodeIndex,
                index: nodeDataIndex
            }));
            nodeIndex++;
            nodeDataIndex++;
            // -- 迴圈取得所有edge的start-node --
            for (let i = d.routesData['routes-in-id'].length - 1; i >= 0; i--) {
                const edgeId = d.routesData['routes-in-id'][i];
                const edgeValue = d.routesData['routes-of-stock-detail'][i];
                const nodesArr = edgeId.split('->');
                const startNodeId = nodesArr[0];
                const endNodeId = nodesArr[1];
                const startNode = SourceNodesMap.get(startNodeId);
                nodesData.push(this.makeTreeNodes({
                    node: startNode,
                    routeIndex,
                    nodeIndex: nodeIndex,
                    index: nodeDataIndex
                }));
                edgesData.push(this.makeTreeEdges({
                    edgeId,
                    edgeValue,
                    routeIndex,
                    startNodeId,
                    endNodeId,
                    // startNodeIndex: nodeDataIndex,
                    // endNodeIndex: nodeDataIndex - 1
                    startNodeIndex: nodeIndex,
                    endNodeIndex: nodeIndex - 1
                }));
                nodeIndex++;
                nodeDataIndex++;
            }
            return {
                ...d,
                nodesData,
                edgesData,
                title: this.params.titleContent(d, routeIndex)
            };
        });
    }
    // 依起始點計算路徑資料
    createRoutesDownData(sourceNodes, targetId) {
        // 全部nodes資料
        const SourceNodesMap = new Map(sourceNodes.map(d => [d.uniID, d]));
        // 取得路徑起始nodes
        let startNodes = [];
        if (targetId) {
            startNodes = sourceNodes.filter(d => d.uniID === targetId);
        }
        else {
            // 無指定起始id則為全部
            startNodes = Object.assign([], sourceNodes);
        }
        // 所有路徑
        const routes = startNodes
            .map(d => {
            let routesData = [];
            // 非根節點
            if (d['routes_to_downs-in-id']) {
                // 將路徑資料一起重新排序
                routesData = this.parseRoutesDataDown(d);
            }
            return routesData;
        })
            .flat() // 將多個 startNodes的資料攤平（有指定的話只會有一筆）
            .map((d, i) => {
            return {
                index: i,
                routesData: d,
                nodesData: [],
                edgesData: [],
                title: '',
                x: 0,
                y: 0 // * 後面再計算
            };
        });
        // 路徑上所有節點
        let nodeDataIndex = 0;
        return routes.map((d, routeIndex) => {
            let nodeIndex = 0;
            let nodesData = [];
            let edgesData = [];
            // -- 先寫入第一筆 --
            const firstEdgeId = d.routesData['routes_to_downs-in-id'][d.routesData['routes_to_downs-in-id'].length - 1];
            const nodesArr = firstEdgeId.split('->');
            const rootNode = SourceNodesMap.get(nodesArr[1]);
            nodesData.push(this.makeTreeNodes({
                node: rootNode,
                routeIndex,
                nodeIndex,
                index: nodeDataIndex
            }));
            nodeIndex++;
            nodeDataIndex++;
            // -- 迴圈取得所有edge的start-node --
            for (let i = d.routesData['routes_to_downs-in-id'].length - 1; i >= 0; i--) {
                const edgeId = d.routesData['routes_to_downs-in-id'][i];
                const edgeValue = d.routesData['routes_to_downs-of-stock-detail'][i];
                const nodesArr = edgeId.split('->');
                const startNodeId = nodesArr[0];
                const endNodeId = nodesArr[1];
                const startNode = SourceNodesMap.get(startNodeId);
                nodesData.push(this.makeTreeNodes({
                    node: startNode,
                    routeIndex,
                    nodeIndex: nodeIndex,
                    index: nodeDataIndex
                }));
                edgesData.push(this.makeTreeEdges({
                    edgeId,
                    edgeValue,
                    routeIndex,
                    startNodeId,
                    endNodeId,
                    // startNodeIndex: nodeDataIndex,
                    // endNodeIndex: nodeDataIndex - 1
                    startNodeIndex: nodeIndex,
                    endNodeIndex: nodeIndex - 1
                }));
                nodeIndex++;
                nodeDataIndex++;
            }
            return {
                ...d,
                nodesData,
                edgesData,
                title: this.params.titleContent(d, routeIndex)
            };
        });
    }
    makeTreeNodes({ node, routeIndex, nodeIndex, index }) {
        const { textWidth, labels } = this.getLabelInfo(node.name);
        let nodeType = '';
        let width = NODE_WIDTH_DEFAULT;
        const height = NODE_HEIGHT_MIN + (labels.length - 1) * NODE_TEXT_SIZE;
        // 非根節點
        if (node['routes-in-id'] || node['routes_to_downs-in-id']) {
            nodeType = node.role;
            // 自然人的node寬度是依文字調整
            if (nodeType === '自然人') {
                width = textWidth + (NODE_TEXT_LEFT * 2);
            }
        }
        // 根節點
        else {
            nodeType = 'root';
        }
        let tags = [];
        if (node.public_issue === '上市') {
            tags.push('市');
        }
        else if (node.public_issue === '上櫃') {
            tags.push('櫃');
        }
        if (node.role === '自然人' && node['total-investment-ratio'] && node['total-investment-ratio'] >= 25) {
            tags.push('益');
        }
        return {
            index,
            id: `${routeIndex}_${node.uniID}`,
            uniID: node.uniID,
            label: node.name,
            labels,
            nodeType,
            x: -1,
            y: -1,
            width,
            height,
            hasTop: false,
            isTopExpanded: false,
            tags,
            sourceData: node,
            _sourceId: node.uniID,
            _routeIndex: routeIndex,
            _nodeIndex: nodeIndex
        };
    }
    makeTreeEdges({ edgeId, edgeValue, routeIndex, startNodeId, endNodeId, startNodeIndex, endNodeIndex }) {
        return {
            id: `${routeIndex}_${edgeId}`,
            startNodeId,
            endNodeId,
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            startCount: 0,
            startCountX: -1,
            startCountY: -1,
            endCount: 0,
            endCountX: -1,
            endCountY: -1,
            label: this.formatPercentage(edgeValue),
            labelX: 0,
            labelY: 0,
            labelTransformX: 0,
            labelTransformY: 0,
            labelDotShow: false,
            labelTextAnchor: 'start',
            labelDominantBaseline: 'auto',
            pathD: '',
            value: edgeValue,
            _sourceId: edgeId,
            _routeIndex: routeIndex,
            _startNodeIndex: startNodeIndex,
            _endNodeIndex: endNodeIndex
        };
    }
    filterRoutes(routes, { stockMin = defaultStockMin, stockExceptZero = defaultStockExceptZero, sort = defaultSort }, mainRelatedToRoot) {
        // let edgeFilter = (edge: LinesEdgeDatum) => edge.value >= defaultStockMin
        // if (stockMin > 0) {
        //   edgeFilter = (edge: LinesEdgeDatum) => edge.value >= stockMin
        // } else if (stockExceptZero == true) {
        //   edgeFilter = (edge: LinesEdgeDatum) => edge.value > 0
        // }
        const routesDataKey = mainRelatedToRoot === 'up' ? 'routes-of-stock-detail-single-value' : 'routes_to_downs-of-stock-detail-single-value';
        routes = routes
            .filter(route => {
            const predicate = this.makeFilterPredicate(stockMin, stockExceptZero);
            return predicate(route.routesData[routesDataKey]);
        })
            .sort((a, b) => {
            if (a.routesData[routesDataKey] - b.routesData[routesDataKey] > 0) {
                return sort[routesDataKey];
            }
            else {
                return -sort[routesDataKey];
            }
        })
            .map((d, i) => {
            return {
                ...d,
                title: this.params.titleContent(d, i)
            };
        });
        return routes;
    }
    // 計算位置
    updateRoutesDataPosition(routes, containerColumnCount) {
        let lineStartY = PADDING_TOP; // line的初始y
        // let currentLineNo = 0
        routes.forEach((route, routeIndex) => {
            // 計算 nodesData
            route.nodesData.forEach((node, index) => {
                // 如換routeIndex則初始高度往下移一格
                // if (node._routeIndex != currentLineNo && route.nodesData[index - 1]) {
                //   lineStartY = route.nodesData[index - 1].y + LINE_ROW_HEIGHT // 上一筆資料的y往上移一格
                //   currentLineNo = node._routeIndex
                // }
                if (index === 0 && routeIndex >= 1) {
                    // 前一個route的最後一個node的高度往下加
                    lineStartY = routes[routeIndex - 1].nodesData[routes[routeIndex - 1].nodesData.length - 1].y + LINE_ROW_HEIGHT; // 上一筆資料的y往上移一格
                }
                // 計算line內的欄/列
                let columnIndex = 0;
                let rowIndex = 0;
                if (node._nodeIndex < containerColumnCount) {
                    columnIndex = node._nodeIndex;
                }
                else {
                    // 第二列之後的欄的起始為1，所以要先扣掉第一列的欄位數量，第二列之後用 containerColumnCount - 1 做計算
                    columnIndex = ((node._nodeIndex - containerColumnCount) % (containerColumnCount - 1)) + 1;
                    rowIndex = Math.floor((node._nodeIndex - containerColumnCount) / (containerColumnCount - 1)) + 1;
                }
                // 寫入座標
                route.nodesData[index].x = PADDING_LEFT + (LINE_COLUMN_WIDTH * columnIndex);
                route.nodesData[index].y = lineStartY + LINE_TITLE_HEIGHT + (LINE_ROW_HEIGHT * rowIndex);
            });
            // route座標
            route.x = route.nodesData[0].x;
            route.y = lineStartY;
            // 計算 edgesData
            route.edgesData.forEach((edge, index) => {
                const startNode = route.nodesData[edge._startNodeIndex];
                const endNode = route.nodesData[edge._endNodeIndex];
                // case 起點和終點是水平的
                if (startNode.y === endNode.y) {
                    route.edgesData[index].startX = startNode.x;
                    route.edgesData[index].startY = startNode.y;
                    route.edgesData[index].endX = endNode.x + endNode.width;
                    route.edgesData[index].endY = endNode.y;
                    route.edgesData[index].labelX = (route.edgesData[index].startX + route.edgesData[index].endX) / 2;
                    route.edgesData[index].labelY = startNode.y - 10;
                    route.edgesData[index].labelTextAnchor = 'middle';
                    route.edgesData[index].pathD = `M${route.edgesData[index].startX} ${route.edgesData[index].startY}`;
                    route.edgesData[index].pathD += ` H${route.edgesData[index].endX}`;
                }
                // case 跨行
                else {
                    route.edgesData[index].startX = startNode.x + (startNode.width / 2);
                    route.edgesData[index].startY = startNode.y - (startNode.height / 2);
                    route.edgesData[index].endX = endNode.x + (endNode.width / 2);
                    route.edgesData[index].endY = endNode.y + (endNode.height / 2);
                    route.edgesData[index].labelX = route.edgesData[index].startX;
                    const centerY = (route.edgesData[index].startY + route.edgesData[index].endY) / 2;
                    route.edgesData[index].labelY = centerY + EDGE_CURVE;
                    route.edgesData[index].labelTextAnchor = 'start';
                    route.edgesData[index].pathD = `M${route.edgesData[index].startX} ${route.edgesData[index].startY}`;
                    route.edgesData[index].pathD += ` V${route.edgesData[index].labelY}`; // 垂直線
                    route.edgesData[index].pathD += ` Q${route.edgesData[index].startX} ${centerY}, ${route.edgesData[index].startX + EDGE_CURVE} ${centerY}`; // 弧線
                    route.edgesData[index].pathD += ` H${route.edgesData[index].endX - EDGE_CURVE}`; // 橫直線
                    route.edgesData[index].pathD += ` Q${route.edgesData[index].endX} ${centerY}, ${route.edgesData[index].endX} ${centerY - EDGE_CURVE}`; // 弧線
                    route.edgesData[index].pathD += ` V${route.edgesData[index].endY}`;
                }
            });
        });
        return routes;
    }
}
