import * as d3 from 'd3';
import ChartDateColumn from './ChartDateColumn';
import GraphicColumnBar from '../graphicColumnBar/GraphicColumnBar';
import { DEFAULT_CHART_DATE_COLUMN_BAR_PARAMS } from './defaults';
export default class ChartDateColumnBar extends ChartDateColumn {
    params = DEFAULT_CHART_DATE_COLUMN_BAR_PARAMS;
    bar;
    constructor(selection, params) {
        super(selection, params);
        this.bar = new GraphicColumnBar(this.graphicGSelection, {});
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
            graphicColumnBar: {
                ...this.params.graphicColumnBar,
                ...params.graphicColumnBar,
            },
        };
        this.bar.setParams({
            // lineType: this.params.graphicColumnBar.lineType,
            // lineCurve: this.params.graphicColumnBar.lineCurve,
            ...this.params.graphicColumnBar,
            colors: this.params.colors,
            // axisWidth: this.axisWidth,
            // axisHeight: this.axisHeight,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget !== 'group' ? this.params.highlightTarget : 'none'
        });
        this.bar
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    setGraphicData({ renderDataItemFiltered, itemLabels, xLabels, yScale }) {
        this.bar.setDataset({
            data: renderDataItemFiltered[0] ?? [],
            itemLabels,
            axisWidth: this.axisWidth,
            zeroY: yScale(0),
        });
    }
    renderGraphic() {
        this.bar.render();
    }
    renderGraphicCover({ coverSelection, filteredData, xScale, yScale, itemLabels }) {
    }
    renderGraphicCoverHighlight({ coverSelection, eventData, xScale, yScale, itemLabels }) {
    }
}
