import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import TooltipAside from '../tooltip/TooltipAside';
import { UtilAuxLine } from '../utilAuxLine';
import { AxisColumnTwoScales } from '../axisColumnTwoScales';
import { calcAxisWidth, calcAxisHeight, makeAxisPointScale, makeAxisLinearScale, makeAxisQuantizeScale } from '../moduleUtils';
import { DEFAULT_CHART_COLUMN_TWO_SCALES_PARAMS } from './defaults';
import { DEFAULT_AXIS_LABEL_COLOR } from '@bpchart/d3-modules/defaults';
// 圖形上方和比例尺的間距
// const graphicTopPadding = 0.95
const makeAxisColumnTwoScalesParams = (params, padding) => {
    return {
        ...params,
        padding
    };
};
// const makeAxisPointScale = (xLabels: string[], axisWidth: number) => {
//   return d3.scalePoint()
//       .domain(xLabels)
//       .range([0, axisWidth!])
//       .padding(0.5)
// }
// const makeAxisLinearScale = (_maxValue: number, axisHeight: number) => {
//   const maxValue = _maxValue == 0 ? 1 : _maxValue // 最大值為如果為0的話畫出來的圖會異常（因為會以range的中間值去計算）
//   return d3.scaleLinear()
//     .domain([maxValue / graphicTopPadding, 0]) // 因為要由下往上所以反過來
//     .range([0, axisHeight])
// }
// const makeAxisQuantizeScale = (xLabels: string[], axisWidth: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisWidth + rangePadding])
//     .range(xLabels.map((d, i) => i))
// }
// const getMaxValue = (data: Datum[] | Datum[][]) => {
//   if (!data.length) {
//     return 0
//   }
//   // data為 Datum[]
//   else if ((data[0] as Datum).value) {
//     return d3.max(data as Datum[], d => d.value) ?? 0
//   }
//   // data為 Datum[][]
//   else if ((data[0] as Datum[])[0]) {
//     return d3.max(data as Datum[][], d => d3.max(d, _d => _d.value)) ?? 0
//   }
//   return 0
// }
const makeCallbackData = ({ dataset, activedItemId, Y1XDataMap, Y2XDataMap, Y1ItemDataMap, Y2ItemDataMap, padding, xRangeScale, event }) => {
    if (!event || !xRangeScale) {
        return {
            dataset,
            y1: {
                groupData: [],
                itemData: [],
                datum: undefined,
                itemLabel: '',
                itemIndex: -1,
            },
            y2: {
                groupData: [],
                itemData: [],
                datum: undefined,
                itemLabel: '',
                itemIndex: -1,
            },
            xLabel: '',
            xIndex: -1,
            rangeIndex: -1,
            clientX: -1,
            clientY: -1,
            offsetX: -1,
            offsetY: -1
        };
    }
    // 座標軸x座標
    const offsetX = Number(event.offsetX) - padding.left;
    // 資料索引
    const rangeIndex = Number(xRangeScale(offsetX) ?? 0);
    const xLabel = dataset.xLabels[rangeIndex];
    const y1GroupData = Y1XDataMap.get(xLabel) ?? [];
    const y2GroupData = Y2XDataMap.get(xLabel) ?? [];
    let y1ItemData = [];
    let y1SelectedData = undefined;
    let y2ItemData = [];
    let y2SelectedData = undefined;
    if (activedItemId) {
        y1ItemData = Y1ItemDataMap.get(activedItemId) ?? [];
        y1SelectedData = y1GroupData.find(d => d.itemLabel === activedItemId);
        y2ItemData = Y2ItemDataMap.get(activedItemId) ?? [];
        y2SelectedData = y2GroupData.find(d => d.itemLabel === activedItemId);
    }
    return {
        dataset,
        y1: {
            groupData: y1GroupData,
            itemData: y1ItemData,
            datum: y1SelectedData,
            itemLabel: y1SelectedData ? y1SelectedData.itemLabel : '',
            itemIndex: y1SelectedData ? y1SelectedData.itemIndex : -1,
        },
        y2: {
            groupData: y2GroupData,
            itemData: y2ItemData,
            datum: y2SelectedData,
            itemLabel: y2SelectedData ? y2SelectedData.itemLabel : '',
            itemIndex: y2SelectedData ? y2SelectedData.itemIndex : -1,
        },
        xLabel: xLabel,
        xIndex: rangeIndex,
        rangeIndex,
        clientX: d3.event.clientX,
        clientY: d3.event.clientY,
        offsetX: d3.event.offsetX,
        offsetY: d3.event.offsetY
    };
};
export default class ChartColumn {
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        y1Data: [],
        y2Data: [],
        xLabels: [],
        y1ItemLabels: [],
        y2ItemLabels: []
    };
    params = DEFAULT_CHART_COLUMN_TWO_SCALES_PARAMS;
    selection;
    tooltip;
    graphicY1Selection = undefined;
    graphicY2Selection = undefined;
    coverSelection;
    axisWidth = 0;
    axisHeight = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    Y1XDataMap = new Map();
    Y2XDataMap = new Map();
    Y1ItemDataMap = new Map();
    Y2ItemDataMap = new Map();
    y1RenderData = [];
    y2RenderData = [];
    utilAuxLine;
    // private renderData: RenderDatum[] | RenderDatum[][] = []
    // private itemLabels: string[] = []
    axisSelection;
    axisColumnTwoScales;
    axisParams = this.params.axisColumnTwoScales;
    rangeSelection;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    xScale;
    y1Scale;
    y2Scale;
    xRangeScale; // 滑鼠座標轉換為類別索引
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    auxLineSelection = undefined;
    // private idList: string[] = []
    // private xLabels: string[] = []
    y1MinValue = 0;
    y1MaxValue = 0;
    y2MinValue = 0;
    y2MaxValue = 0;
    constructor(selection, params) {
        this.selection = selection;
        this.axisSelection = selection.append('g');
        this.axisColumnTwoScales = new AxisColumnTwoScales(this.axisSelection, {});
        this.rangeSelection = this.selection
            .append('rect')
            .attr('opacity', 0);
        this.setRangeEvent(this.rangeSelection);
        this.graphicY1Selection = selection.append('g');
        this.graphicY2Selection = selection.append('g');
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        this.utilAuxLine = new UtilAuxLine(this.coverSelection);
        this.setCoverEvent(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            axisColumnTwoScales: {
                ...this.params.axisColumnTwoScales,
                ...params.axisColumnTwoScales
            },
        };
        if (this.params.tooltipAside && !this.tooltip) {
            this.tooltip = new TooltipAside(this.selection, {
                templateHtml: this.params.tooltipAside.templateHtml,
                type: this.params.tooltipAside.type ?? 'white',
                yLine: this.params.tooltipAside.yLine ?? false,
            });
        }
        this.axisParams = makeAxisColumnTwoScalesParams(this.params.axisColumnTwoScales, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisColumnTwoScales.setParams(this.axisParams);
        this.graphicY1Selection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.graphicY2Selection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.coverSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.initGraphic(this.params);
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisParams = makeAxisColumnTwoScalesParams(this.params.axisColumnTwoScales, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisColumnTwoScales.setParams(this.axisParams);
        this.axisColumnTwoScales.resize({ width, height });
        this.rangeSelection
            .attr('x', this.params.padding.left)
            .attr('y', this.params.padding.top)
            .attr('width', this.axisWidth)
            .attr('height', this.axisHeight);
        // this.initGraphic(this.params)
    }
    setDataset(dataset) {
        this.dataset = dataset;
        const [y1MinValue, y1MaxValue] = this.getY1MinAndMaxValue(this.dataset.y1Data);
        const [y2MinValue, y2MaxValue] = this.getY2MinAndMaxValue(this.dataset.y2Data);
        this.y1MinValue = y1MinValue;
        this.y1MaxValue = y1MaxValue;
        this.y2MinValue = y2MinValue;
        this.y2MaxValue = y2MaxValue;
        // this.renderData = this.makeGraphicRenderData({
        //   dataset: this.dataset,
        //   xScale: this.xScale!,
        //   yScale: this.yScale!
        // })
        // this.XDataMap = this.makeXDataMap({
        //   renderData: this.renderData,
        //   xLabels: this.dataset.xLabels
        // })
        // this.ItemDataMap = this.makeItemDataMap({
        //   renderData: this.renderData,
        //   itemLabels: this.itemLabels ?? []
        // })
    }
    render() {
        this.xScale = makeAxisPointScale({
            axisLabels: this.dataset.xLabels,
            axisWidth: this.axisWidth,
        });
        this.y1Scale = makeAxisLinearScale({
            maxValue: this.y1MaxValue,
            minValue: this.y1MinValue,
            axisWidth: this.axisHeight,
            domainMinValue: this.params.y1DomainMinValue,
            domainMaxValue: this.params.y1DomainMaxValue,
            domainMinRange: this.params.y1DomainMinRange,
            domainMaxRange: this.params.y1DomainMaxRange,
            reverse: true
        });
        this.y2Scale = makeAxisLinearScale({
            maxValue: this.y2MaxValue,
            minValue: this.y2MinValue,
            axisWidth: this.axisHeight,
            domainMinValue: this.params.y2DomainMinValue,
            domainMaxValue: this.params.y2DomainMaxValue,
            domainMinRange: this.params.y2DomainMinRange,
            domainMaxRange: this.params.y2DomainMaxRange,
            reverse: true
        });
        this.xRangeScale = makeAxisQuantizeScale({
            axisLabels: this.dataset.xLabels,
            axisWidth: this.axisWidth,
        });
        this.y1RenderData = this.makeY1GraphicRenderData({
            dataset: this.dataset,
            xScale: this.xScale,
            y1Scale: this.y1Scale,
        });
        this.y2RenderData = this.makeY2GraphicRenderData({
            dataset: this.dataset,
            xScale: this.xScale,
            y2Scale: this.y2Scale,
        });
        this.Y1XDataMap = this.makeY1XDataMap({
            y1RenderData: this.y1RenderData,
            xLabels: this.dataset.xLabels
        });
        this.Y2XDataMap = this.makeY2XDataMap({
            y2RenderData: this.y2RenderData,
            xLabels: this.dataset.xLabels
        });
        this.Y1ItemDataMap = this.makeY1ItemDataMap({
            y1RenderData: this.y1RenderData,
            y1ItemLabels: this.dataset.y1ItemLabels ?? []
        });
        this.Y1ItemDataMap = this.makeY2ItemDataMap({
            y2RenderData: this.y2RenderData,
            y2ItemLabels: this.dataset.y2ItemLabels ?? []
        });
        // this.renderData = makeGraphicRenderData({
        //   data: this.renderData,
        //   xLabels: this.dataset.xLabels,
        //   itemLabels: this.itemLabels,
        //   xScale: this.xScale,
        //   yScale: this.yScale
        // })
        this.setGraphicData({
            y1RenderData: this.y1RenderData,
            y2RenderData: this.y2RenderData,
            y1ItemLabels: this.dataset.y1ItemLabels ?? [],
            y2ItemLabels: this.dataset.y2ItemLabels ?? [],
            xLabels: this.dataset.xLabels,
            xScale: this.xScale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
        });
        // 繪製座標軸
        this.axisColumnTwoScales.setDataset({
            xScale: this.xScale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
            y1MaxValue: this.y1MaxValue,
            y2MaxValue: this.y2MaxValue,
        });
        this.axisColumnTwoScales.render();
        this.renderGraphic();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    makeActivedCallbackData = (activedItemId, event) => {
        return makeCallbackData({
            dataset: this.dataset,
            activedItemId: activedItemId,
            Y1XDataMap: this.Y1XDataMap,
            Y2XDataMap: this.Y2XDataMap,
            Y1ItemDataMap: this.Y1ItemDataMap,
            Y2ItemDataMap: this.Y2ItemDataMap,
            padding: this.params.padding,
            xRangeScale: this.xRangeScale,
            event
        });
    };
    setRangeEvent(selection) {
        selection
            // .on('mousedown', (d) => {
            //   this.dragstartX = d3.event.clientX
            // })
            .on('click', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    showTooltip(eventData, event) {
        if (event && eventData && this.xScale) {
            this.tooltip.setDatum({
                data: eventData,
                // x: d3.event.clientX,
                x: eventData.clientX - eventData.offsetX + this.params.padding.left + this.xScale(eventData.xLabel),
                y: d3.event.clientY
            });
        }
        else {
            this.tooltip.remove();
        }
    }
    setCoverEvent(selection) {
        selection
            .on('mouseover', (d, i) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mousemove', (d) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            this.coverHighlight(undefined);
            this.tooltip.remove();
        });
    }
    // 複蓋區域 highlight
    coverHighlight(eventData) {
        // 標示線
        if (this.params.showAuxLine) {
            this.renderAuxLine({
                coverSelection: this.coverSelection,
                xLabels: this.dataset.xLabels,
                activeRangeIndex: eventData ? eventData.rangeIndex : -1,
                xScale: this.xScale,
                padding: this.params.padding,
                axisHeight: this.axisHeight
            });
        }
        // 圖形
        this.renderGraphicCoverHighlight({
            eventData: eventData,
            xScale: this.xScale,
            y1Scale: this.y1Scale,
            y2Scale: this.y2Scale,
            padding: this.params.padding,
        });
    }
    renderAuxLine({ coverSelection, xLabels, activeRangeIndex, xScale, padding, axisHeight }) {
        if (!xScale) {
            return;
        }
        const auxLineData = xLabels.map((d, i) => {
            const x = (xScale(d) || 0);
            return {
                x1: x,
                x2: x,
                y1: 0,
                y2: axisHeight,
                active: activeRangeIndex == i
            };
        });
        this.utilAuxLine.setData(auxLineData);
        this.utilAuxLine.render();
    }
}
