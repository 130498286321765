import Button from './Button';
import * as d3 from 'd3';
import { svgHtml } from '../d3Utils';
export default class ButtonSliderBar extends Button {
    params = {
        id: 'bpchart_button',
        disabled: false
    };
    constructor(selection, params = {}) {
        super(selection, params);
        if (params.id) {
            this.params.id = params.id;
        }
        if (params.disabled) {
            this.params.disabled = params.disabled;
        }
    }
    render() {
        svgHtml(this.selection, `<g class="bpchart-button" width="14" height="24" >
      <rect x="0.5" y="0.5" width="13" height="23" rx="1.5" fill="white" stroke="#C4C4C4"/>
      <path d="M5 7V18" stroke="#C4C4C4"/>
      <path d="M8.82971 7V18" stroke="#C4C4C4"/>
      </g>`);
        this.buttonSelection = this.selection.select('g.bpchart-button');
        this.disabled(this.params.disabled);
    }
    disabled(isDisabled) {
        this.params.disabled = isDisabled;
        const cursorValue = this.params.disabled ? 'none' : 'pointer';
        this.buttonSelection
            .style('cursor', cursorValue);
    }
}
