import * as d3 from 'd3';
import { DEFAULT_PADDING, DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import { DEFAULT_AXIS_ROW_PARAMS } from './defaults';
// import { Padding } from '../types'
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import { parseTickFormatValue, svgHtml } from '../d3Utils';
import { measureTextWidth } from '../utils';
const defaultTickSize = 6;
export default class AxisRow {
    selection;
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        xScale: d3.scaleLinear(),
        yScale: d3.scalePoint(),
        maxValue: 0
    };
    params = DEFAULT_AXIS_ROW_PARAMS;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    axisWidth = calcAxisWidth(this.width, DEFAULT_PADDING);
    axisHeight = calcAxisHeight(this.height, DEFAULT_PADDING);
    constructor(selection, params) {
        this.selection = selection;
        // this.chartSelection = this.selection.append('g')
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    setDataset(dataset) {
        this.dataset = dataset;
        // console.log(dataset)
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
    }
    render() {
        this.renderYPointAxis();
        this.renderXLinearAxis();
        const zeroX = this.dataset.xScale(0);
        this.renderZeroLine(this.selection, [{
                x1: zeroX,
                y1: 0,
                x2: zeroX,
                y2: this.axisHeight
            }]);
    }
    remove() {
        this.selection.remove();
    }
    renderYPointAxis() {
        let yAxisData = [];
        if (this.params.yVisible) {
            yAxisData.push(this.params);
        }
        const yAxisUpdate = this.selection
            .selectAll('g.yAxis')
            .data(yAxisData);
        const yAxisEnter = yAxisUpdate
            .enter()
            .append('g');
        this.yAxisSelection = yAxisUpdate.merge(yAxisEnter)
            .classed("yAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + d.padding.top + ")");
        yAxisUpdate.exit().remove();
        const yLabelUpdate = this.selection
            .selectAll('text.yLabel')
            .data(yAxisData);
        const yLabelEnter = yLabelUpdate
            .enter()
            .append('text')
            .classed('yLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold');
        this.yLabelSelection = yLabelUpdate.merge(yLabelEnter)
            .attr('x', d => d.padding.left - d.xTickPadding - defaultTickSize)
            .attr('y', d => d.padding.top - d.yTickPadding)
            .attr('text-anchor', this.params.yLabelAnchor)
            .attr('transform', `translate(${this.params.yLabelOffset[0]}, ${this.params.yLabelOffset[1]})`)
            .style('fill', this.params.axisLabelColor)
            .text(d => d.yLabel);
        yLabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.yLabelSelection.size() == 0) {
            return;
        }
        // 設定Y軸刻度
        this.yAxis = d3.axisLeft(this.dataset.yScale)
            .scale(this.dataset.yScale)
            .tickSize(this.params.yTickLine && typeof this.params.yTickLine === 'boolean' ? -this.axisWidth
            : this.params.yTickLine && typeof this.params.yTickLine === 'number' ? -this.axisWidth * this.params.yTickLine
                : defaultTickSize)
            .tickSizeOuter(0)
            .tickFormat((d) => {
            return d;
        })
            .tickPadding(this.params.yTickPadding);
        const yAxisEl = this.yAxisSelection
            .transition()
            .duration(50)
            .call(this.yAxis)
            .attr('text-anchor', () => this.params.rotateYLabel !== false ? 'end' : 'end'); // 如果要旋轉的話靠字尾對齊
        yAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.yTickLineDasharray);
        yAxisEl.selectAll('path')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('shape-rendering', 'crispEdges');
        const xText = yAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            // .style('font-weight', 'bold')
            .style('color', this.params.axisLabelColor)
            .attr('transform-origin', `-5 ${this.params.xTickPadding + defaultTickSize}`);
        if (this.params.rotateYLabel === true) {
            xText.attr('transform', 'translate(0,0) rotate(-45)');
        }
        else if (typeof this.params.rotateYLabel === 'number') {
            xText.attr('transform', `translate(0,0) rotate(${this.params.rotateYLabel})`);
        }
        // 圖軸label文字前加上 icon
        this.appendLabelIcon(this.yAxisSelection.selectAll('g.tick'), this.params.yLabelIconMethod);
    }
    appendLabelIcon(tickSelection, yLabelIconMethod) {
        tickSelection.each((d, i, n) => {
            const tick = d3.select(n[i]);
            const text = tick.select('text');
            const textString = text.data();
            // console.log('textString', textString)
            const labelPadding = Math.abs(Number(text.attr('x')));
            const textWidth = measureTextWidth(textString, 14);
            const x = -labelPadding - textWidth - 21; // 文字左邊1.5個字元的距離
            const y = -7; // 隔半個字元距離
            const update = tick.selectAll('g.bpchart__icon')
                .data([textString]);
            const enter = update
                .enter()
                .append('g')
                .classed('bpchart__icon', true);
            update.exit().remove();
            if (enter.size() && yLabelIconMethod) {
                console.log('enter.size()', enter.size());
                svgHtml(enter, yLabelIconMethod(textString, i));
            }
            update.merge(enter)
                .attr('transform', `translate(${x}, ${y})`);
        });
    }
    renderXLinearAxis() {
        let xAxisData = [];
        if (this.params.xVisible) {
            xAxisData.push(this.params);
        }
        const xAxisUpdate = this.selection
            .selectAll('g.xAxis')
            .data(xAxisData);
        const xAxisEnter = xAxisUpdate
            .enter()
            .append('g');
        this.xAxisSelection = xAxisUpdate.merge(xAxisEnter)
            .classed("xAxis", true)
            .attr("transform", d => "translate(" + d.padding.left + "," + (d.padding.top + this.axisHeight) + ")");
        xAxisUpdate.exit().remove();
        const xLabelUpdate = this.selection
            .selectAll('text.xLabel')
            .data(xAxisData);
        const xLabelEnter = xLabelUpdate
            .enter()
            .append('text')
            .classed('xLabel', true)
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('fill', this.params.axisLabelColor)
            .style('dominant-baseline', 'hanging');
        this.xLabelSelection = xLabelUpdate.merge(xLabelEnter)
            .attr('x', d => this.width - d.padding.right + d.xTickPadding)
            .attr('y', d => d.padding.top + this.axisHeight + d.yTickPadding)
            .attr('transform', `translate(${this.params.xLabelOffset[0]}, ${this.params.xLabelOffset[1]})`)
            .attr('text-anchor', this.params.xLabelAnchor)
            .text(d => d.xLabel);
        xLabelUpdate.exit().remove();
        // 如不顯示則不處理
        if (this.xLabelSelection.size() == 0) {
            return;
        }
        // 設定X軸刻度
        this.xAxis = d3.axisBottom(this.dataset.xScale)
            .scale(this.dataset.xScale)
            .ticks(this.dataset.maxValue > this.params.xTicks ? this.params.xTicks : (this.dataset.maxValue === 0 ? 1 : Math.ceil(this.dataset.maxValue))) // 刻度分段數量
            .tickFormat(d => parseTickFormatValue(d, this.params.xTickFormat))
            .tickSize(this.params.xTickLine && typeof this.params.xTickLine === 'boolean' ? -this.axisHeight
            : this.params.xTickLine && typeof this.params.xTickLine === 'number' ? -this.axisHeight * this.params.xTickLine
                : defaultTickSize)
            .tickPadding(this.params.xTickPadding);
        const xAxisEl = this.xAxisSelection
            .transition()
            .duration(100)
            .call(this.xAxis);
        xAxisEl.selectAll('line')
            .style('fill', 'none')
            .style('stroke', this.params.axisLineColor)
            .style('stroke-dasharray', this.params.xTickLineDasharray);
        xAxisEl.selectAll('path')
            .style('fill', 'none')
            // .style('stroke', this.params.axisLineColor!)
            .style('stroke', 'none')
            .style('shape-rendering', 'crispEdges');
        xAxisEl.selectAll('text')
            .style('font-family', 'sans-serif')
            .style('font-size', '14px')
            .style('color', this.params.axisLabelColor);
    }
    renderZeroLine(selection, zeroLineData) {
        const updateG = selection
            .selectAll('g.bpchart__zero-line')
            .data(zeroLineData);
        const enterG = updateG.enter()
            .append('g')
            .classed('bpchart__zero-line', true)
            .attr("transform", d => "translate(" + this.params.padding.left + "," + this.params.padding.top + ")");
        updateG.exit().remove();
        const g = updateG.merge(enterG);
        enterG.append('line');
        g.select('line')
            .attr('stroke', this.params.axisLabelColor)
            .attr('stroke-width', 1)
            .transition()
            .duration(100)
            .attr('x1', d => d.x1)
            .attr('y1', d => d.y1)
            .attr('x2', d => d.x2)
            .attr('y2', d => d.y2)
            // 0 有在x軸範圍內才顯示
            .attr('opacity', d => d.x1 >= 0 && d.x1 <= this.axisWidth
            ? 1
            : 0);
    }
}
