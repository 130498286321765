import * as d3 from 'd3';
import ChartColumnTwoScales from './ChartColumnTwoScales';
import GraphicColumnLine from '../graphicColumnLine/GraphicColumnLine';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_COLUMN_TWO_SCALES_LINE_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnTwoScalesLine extends ChartColumnTwoScales {
    params = DEFAULT_CHART_COLUMN_TWO_SCALES_LINE_PARAMS;
    line1;
    dot1;
    line2;
    dot2;
    constructor(selection, params) {
        super(selection, params);
        this.line1 = new GraphicColumnLine(this.graphicY1Selection, {});
        this.dot1 = new GraphicDot(this.coverSelection.append('g'), {});
        this.line2 = new GraphicColumnLine(this.graphicY2Selection, {});
        this.dot2 = new GraphicDot(this.coverSelection.append('g'), {});
    }
    initGraphic(params) {
        this.params = {
            ...this.params,
            ...params,
        };
        this.params.graphicColumnLine = {
            ...this.params.graphicColumnLine,
            // colors: this.params.y2Colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.params.graphicDot = {
            ...this.params.graphicDot,
            // colors: this.params.y2Colors,
            highlightItemId: this.params.highlightItemId,
            highlightTarget: this.params.highlightTarget === 'item' ? 'item' : 'none'
        };
        // y1
        this.line1.setParams({
            ...this.params.graphicColumnLine,
            colors: this.params.y1Colors
        });
        this.line1
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot1.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot1.render();
                this.dot2.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot2.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot1.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot1.render();
                this.dot2.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot2.render();
            }
        });
        this.dot1.setParams({
            ...this.params.graphicDot,
            colors: this.params.y1Colors
        });
        this.dot1
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line1.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line1.render();
                this.line2.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line2.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line1.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line1.render();
                this.line2.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line2.render();
            }
        });
        // y2
        this.line2.setParams({
            ...this.params.graphicColumnLine,
            colors: this.params.y2Colors
        });
        this.line2
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot1.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot1.render();
                this.dot2.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot2.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot1.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot1.render();
                this.dot2.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot2.render();
            }
        });
        this.dot2.setParams({
            ...this.params.graphicDot,
            colors: this.params.y2Colors
        });
        this.dot2
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line1.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line1.render();
                this.line2.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line2.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line1.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line1.render();
                this.line2.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line2.render();
            }
        });
    }
    makeY1GraphicRenderData({ dataset, xScale, y1Scale }) {
        return dataset.y1Data.map((d, i) => {
            const itemLabel = dataset.y1ItemLabels && dataset.y1ItemLabels[i] ? dataset.y1ItemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x: xScale(xLabel) || 0,
                    y: y1Scale(_d.value) || 0
                };
            });
        });
    }
    makeY2GraphicRenderData({ dataset, xScale, y2Scale }) {
        return dataset.y2Data.map((d, i) => {
            const itemLabel = dataset.y2ItemLabels && dataset.y2ItemLabels[i] ? dataset.y2ItemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x: xScale(xLabel) || 0,
                    y: y2Scale(_d.value) || 0
                };
            });
        });
    }
    getY1MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    getY2MinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value < prev[0] ? current.value : prev[0],
                current.value > prev[1] ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeY1XDataMap({ y1RenderData, xLabels }) {
        let XDataMap = new Map();
        if (!y1RenderData.length) {
            return XDataMap;
        }
        y1RenderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i] ?? '', xData);
            });
        });
        return XDataMap;
    }
    makeY2XDataMap({ y2RenderData, xLabels }) {
        let XDataMap = new Map();
        if (!y2RenderData.length) {
            return XDataMap;
        }
        y2RenderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i] ?? '', xData);
            });
        });
        return XDataMap;
    }
    makeY1ItemDataMap({ y1RenderData, y1ItemLabels }) {
        let ItemDataMap = new Map();
        if (!y1RenderData.length) {
            return ItemDataMap;
        }
        y1RenderData.forEach((d, i) => {
            ItemDataMap.set(y1ItemLabels[i] ?? '', d);
        });
        return ItemDataMap;
    }
    makeY2ItemDataMap({ y2RenderData, y2ItemLabels }) {
        let ItemDataMap = new Map();
        if (!y2RenderData.length) {
            return ItemDataMap;
        }
        y2RenderData.forEach((d, i) => {
            ItemDataMap.set(y2ItemLabels[i] ?? '', d);
        });
        return ItemDataMap;
    }
    setGraphicData({ y1RenderData, y2RenderData, y1ItemLabels, y2ItemLabels, xLabels, xScale, y1Scale, y2Scale }) {
        // y1
        this.line1.setDataset({
            data: y1RenderData,
            itemLabels: y1ItemLabels && y1ItemLabels.length ? y1ItemLabels : [defaultItemLabel],
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: y1Scale(0)
        });
        // y2
        this.line2.setDataset({
            data: y2RenderData,
            itemLabels: y2ItemLabels && y2ItemLabels.length ? y2ItemLabels : [defaultItemLabel],
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: y2Scale(0)
        });
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            // y1
            const dotData1 = y1RenderData.flat();
            this.dot1.setDataset({
                data: dotData1,
                itemLabels: y1ItemLabels ?? [defaultItemLabel]
            });
            // y2
            const dotData2 = y2RenderData.flat();
            this.dot2.setDataset({
                data: dotData2,
                itemLabels: y2ItemLabels ?? [defaultItemLabel]
            });
        }
    }
    renderGraphic() {
        this.line1.render();
        this.line2.render();
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            this.dot1.render();
            this.dot2.render();
        }
    }
    renderGraphicCoverHighlight({ eventData, xScale, y1Scale, y2Scale, padding }) {
        // 非顯示全部圓點時 highlight圓點
        if (this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            // y1
            const dotData1 = eventData
                ? eventData.y1.groupData.map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.xLabel),
                        y: y1Scale(d.value)
                    };
                })
                : [];
            this.dot1.setDataset({
                data: dotData1,
                itemLabels: this.dataset.y2ItemLabels ?? [defaultItemLabel]
            });
            this.dot1.render();
            // y2
            const dotData2 = eventData
                ? eventData.y2.groupData.map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.xLabel),
                        y: y2Scale(d.value)
                    };
                })
                : [];
            this.dot2.setDataset({
                data: dotData2,
                itemLabels: this.dataset.y2ItemLabels ?? [defaultItemLabel]
            });
            this.dot2.render();
        }
    }
}
