import { AxisDateRank } from '@bpchart/d3-modules/axisDateRank';
import dataset from './dataset';
import { DEFAULT_AXIS_DATE_RANK_PARAMS } from '@bpchart/d3-modules/axisDateRank';
export default {
    props: {
        module: AxisDateRank,
        params: DEFAULT_AXIS_DATE_RANK_PARAMS,
        dataset
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
