const sourceData = [
    {
        "label": "110年9月",
        "increase": "83",
        "birth": "1438",
        "death": "1355",
        "rate": "0.4"
    },
    {
        "label": "110年10月",
        "increase": "48",
        "birth": "1407",
        "death": "1359",
        "rate": "0.22"
    },
    {
        "label": "110年11月",
        "increase": "-45",
        "birth": "1491",
        "death": "1536",
        "rate": "-0.22"
    },
    {
        "label": "110年12月",
        "increase": "21",
        "birth": "1575",
        "death": "1554",
        "rate": "0.1"
    },
    {
        "label": "111年1月",
        "increase": "-235",
        "birth": "1410",
        "death": "1645",
        "rate": "-1.1"
    },
    {
        "label": "111年2月",
        "increase": "-347",
        "birth": "1075",
        "death": "1422",
        "rate": "-1.8"
    },
    {
        "label": "111年3月",
        "increase": "-505",
        "birth": "1330",
        "death": "1835",
        "rate": "-2.38"
    },
    {
        "label": "111年4月",
        "increase": "-221",
        "birth": "1197",
        "death": "1418",
        "rate": "-1.08"
    },
    {
        "label": "111年5月",
        "increase": "-838",
        "birth": "959",
        "death": "1797",
        "rate": "-3.99"
    }
];
export default {
    data: [
        sourceData.map(d => {
            return {
                value: Number(d.birth)
            };
        }),
        sourceData.map(d => {
            return {
                value: -Number(d.death) // 改為負值
            };
        }),
    ],
    yLabels: sourceData.map(d => d.label),
    itemLabels: ['出生', '死亡']
};
