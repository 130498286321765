import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT } from '@bpchart/d3-modules/defaults';
import TooltipAside from '../tooltip/TooltipAside';
import { UtilAuxLine } from '../utilAuxLine';
import { AxisColumn } from '../axisColumn';
import { calcAxisWidth, calcAxisHeight, makeAxisPointScale, makeAxisLinearScale, makeAxisQuantizeScale } from '../moduleUtils';
import { DEFAULT_CHART_COLUMN_PARAMS } from './defaults';
// 圖形上方和比例尺的間距
// const graphicTopPadding = 0.95
const makeAxisColumnParams = (params, padding) => {
    return {
        ...params,
        padding
    };
};
// const makeAxisPointScale = (xLabels: string[], axisWidth: number) => {
//   return d3.scalePoint()
//       .domain(xLabels)
//       .range([0, axisWidth!])
//       .padding(0.5)
// }
// const makeAxisLinearScale = (_maxValue: number, axisHeight: number, domainValue?: [number, number], domainRange?: [number, number]) => {
//   const minValue = domainValue ? domainValue[0] : 0
//   const maxValue = domainValue ? domainValue[1] : _maxValue
//   let domainMinValue = 0
//   let domainMaxValue = 0
//   // 用最大/小值在比例尺上的位置(參數domainRange)，反推比例尺的最大/小值 (d3.scale.domain)
//   if (domainRange) {
//     domainMinValue = maxValue - (maxValue - minValue) / (1 - domainRange[0])
//     domainMaxValue = maxValue / domainRange[1]
//   } else {
//     domainMinValue = minValue
//     domainMaxValue = maxValue
//   }
//   // 最大值為如果為0的話畫出來的圖會異常（因為會以range的中間值去計算）
//   if (domainMaxValue == 0) {
//     domainMaxValue = 1
//   }
//   return d3.scaleLinear()
//     .domain([domainMaxValue, domainMinValue]) // 因為要由下往上所以反過來
//     .range([0, axisHeight])
// }
// const makeAxisQuantizeScale = (xLabels: string[], axisWidth: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisWidth + rangePadding])
//     .range(xLabels.map((d, i) => i))
// }
// const getMaxValue = (data: Datum[] | Datum[][]) => {
//   if (!data.length) {
//     return 0
//   }
//   // data為 Datum[]
//   else if ((data[0] as Datum).value) {
//     return d3.max(data as Datum[], d => d.value) ?? 0
//   }
//   // data為 Datum[][]
//   else if ((data[0] as Datum[])[0]) {
//     return d3.max(data as Datum[][], d => d3.max(d, _d => _d.value)) ?? 0
//   }
//   return 0
// }
const makeCallbackData = ({ dataset, activedItemId, XDataMap, ItemDataMap, padding, xRangeScale, event }) => {
    if (!event || !xRangeScale) {
        return {
            dataset,
            groupData: [],
            itemData: [],
            datum: undefined,
            xLabel: '',
            xIndex: -1,
            itemLabel: '',
            itemIndex: -1,
            rangeIndex: -1,
            clientX: -1,
            clientY: -1,
            offsetX: -1,
            offsetY: -1
        };
    }
    // 座標軸x座標
    const offsetX = Number(event.offsetX) - padding.left;
    // 資料索引
    const rangeIndex = Number(xRangeScale(offsetX) ?? 0);
    const xLabel = dataset.xLabels[rangeIndex];
    const groupData = XDataMap.get(xLabel) ?? [];
    let itemData = [];
    let selectedData = undefined;
    if (activedItemId) {
        itemData = ItemDataMap.get(activedItemId) ?? [];
        selectedData = groupData.find(d => d.itemLabel === activedItemId);
    }
    return {
        dataset,
        groupData,
        itemData,
        datum: selectedData,
        xLabel: xLabel,
        xIndex: rangeIndex,
        itemLabel: selectedData ? selectedData.itemLabel : '',
        itemIndex: selectedData ? selectedData.itemIndex : -1,
        rangeIndex,
        clientX: d3.event.clientX,
        clientY: d3.event.clientY,
        offsetX: d3.event.offsetX,
        offsetY: d3.event.offsetY
    };
};
const makeFilteredData = ({ dataset, filterConfig, params }) => {
    let filteredData = [];
    // 如無filterItems欄位則不用篩選
    if (!filterConfig || !filterConfig.filterItems || !dataset.itemLabels) {
        return {
            filteredDataset: dataset,
            filteredParams: params
        };
    }
    // -- filtered data --
    const filterItemsObj = filterConfig.filterItems.reduce((prev, current) => {
        prev[current] = true;
        return prev;
    }, {});
    const datumPredicate = (d) => !d.itemLabel || filterItemsObj[d.itemLabel] == true;
    if (dataset.data[0] && Array.isArray(dataset.data[0])) {
        filteredData = dataset.data
            .filter((d, i) => filterItemsObj[dataset.itemLabels[i]] == true)
            .map(d => d.filter(datumPredicate));
    }
    else {
        filteredData = dataset.data
            .filter(datumPredicate);
    }
    return {
        filteredDataset: {
            ...dataset,
            data: filteredData,
            itemLabels: (filterConfig && filterConfig.filterItems)
                ? dataset.itemLabels.filter(d => filterConfig.filterItems.includes(d)) // 為維持原有順序再篩選一次
                : dataset.itemLabels
        },
        filteredParams: {
            ...params,
            colors: (dataset.itemLabels && filterConfig.filterItems)
                ? params.colors.filter((d, i) => !dataset.itemLabels[i] || filterItemsObj[dataset.itemLabels[i]])
                : params.colors
        }
    };
};
export default class ChartColumn {
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    dataset = {
        data: [],
        xLabels: []
    };
    params = DEFAULT_CHART_COLUMN_PARAMS;
    selection;
    filterConfig = {};
    tooltip;
    graphicSelection = undefined;
    coverSelection;
    axisWidth = 0;
    axisHeight = 0;
    clickCallback = function () { };
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    XDataMap = new Map();
    ItemDataMap = new Map();
    renderData = [];
    filteredDataset = {
        data: [],
        xLabels: []
    };
    filteredParams = DEFAULT_CHART_COLUMN_PARAMS;
    // private renderData: RenderDatum[] | RenderDatum[][] = []
    // private itemLabels: string[] = []
    utilAuxLine;
    axisSelection;
    axisColumn;
    axisParams = this.params.axisColumn;
    rangeSelection;
    xAxisSelection = undefined;
    yAxisSelection = undefined;
    xScale;
    yScale;
    xRangeScale; // 滑鼠座標轉換為類別索引
    xAxis;
    yAxis;
    xLabelSelection = undefined;
    yLabelSelection = undefined;
    auxLineSelection = undefined;
    // private idList: string[] = []
    // private xLabels: string[] = []
    minValue = 0;
    maxValue = 0;
    constructor(selection, params) {
        this.selection = selection;
        this.axisSelection = selection.append('g');
        this.axisColumn = new AxisColumn(this.axisSelection, {});
        this.rangeSelection = this.selection
            .append('rect')
            .attr('opacity', 0);
        this.setRangeEvent(this.rangeSelection);
        this.graphicSelection = selection.append('g');
        this.coverSelection = this.selection
            .append('g')
            .classed('bpchart__cover', true);
        this.utilAuxLine = new UtilAuxLine(this.coverSelection);
        this.setCoverEvent(this.selection);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            axisColumn: {
                ...this.params.axisColumn,
                ...params.axisColumn
            },
        };
        if (this.params.tooltipAside && !this.tooltip) {
            this.tooltip = new TooltipAside(this.selection, {
                templateHtml: this.params.tooltipAside.templateHtml,
                type: this.params.tooltipAside.type ?? 'white',
                yLine: this.params.tooltipAside.yLine ?? false,
            });
        }
        this.axisParams = makeAxisColumnParams(this.params.axisColumn, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisColumn.setParams(this.axisParams);
        this.graphicSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.coverSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        // this.initGraphic(this.params)
    }
    select() {
        return this.selection;
    }
    remove() {
        this.selection.remove();
    }
    resize({ width = this.width, height = this.height }) {
        this.width = width;
        this.height = height;
        this.axisParams = makeAxisColumnParams(this.params.axisColumn, this.params.padding);
        this.axisWidth = calcAxisWidth(this.width, this.params.padding);
        this.axisHeight = calcAxisHeight(this.height, this.params.padding);
        this.axisColumn.setParams(this.axisParams);
        this.axisColumn.resize({ width, height });
        this.rangeSelection
            .attr('x', this.params.padding.left)
            .attr('y', this.params.padding.top)
            .attr('width', this.axisWidth)
            .attr('height', this.axisHeight);
        // this.initGraphic(this.params)
    }
    setDataset(dataset) {
        this.dataset = dataset;
        // const [minValue, maxValue] = this.getMinAndMaxValue(this.dataset.data)
        // this.minValue = minValue
        // this.maxValue = maxValue
        // this.renderData = this.makeGraphicRenderData({
        //   dataset: this.dataset,
        //   xScale: this.xScale!,
        //   yScale: this.yScale!
        // })
        // this.XDataMap = this.makeXDataMap({
        //   renderData: this.renderData,
        //   xLabels: this.dataset.xLabels
        // })
        // this.ItemDataMap = this.makeItemDataMap({
        //   renderData: this.renderData,
        //   itemLabels: this.itemLabels ?? []
        // })
    }
    filter(filterConfig) {
        this.filterConfig = {
            ...this.filterConfig,
            ...filterConfig
        };
    }
    render() {
        this.xRangeScale = makeAxisQuantizeScale({
            axisLabels: this.dataset.xLabels,
            axisWidth: this.axisWidth,
        });
        const { filteredDataset, filteredParams } = makeFilteredData({
            dataset: this.dataset,
            filterConfig: this.filterConfig,
            params: this.params
        });
        this.filteredDataset = filteredDataset;
        this.filteredParams = filteredParams;
        const [minValue, maxValue] = this.getMinAndMaxValue(this.filteredDataset.data);
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.xScale = makeAxisPointScale({
            axisLabels: this.filteredDataset.xLabels,
            axisWidth: this.axisWidth,
        });
        this.yScale = makeAxisLinearScale({
            maxValue: this.maxValue,
            minValue: this.minValue,
            axisWidth: this.axisHeight,
            domainMinValue: this.params.domainMinValue,
            domainMaxValue: this.params.domainMaxValue,
            domainMinRange: this.params.domainMinRange,
            domainMaxRange: this.params.domainMaxRange,
            reverse: true
        });
        this.renderData = this.makeGraphicRenderData({
            dataset: this.filteredDataset,
            xScale: this.xScale,
            yScale: this.yScale
        });
        this.XDataMap = this.makeXDataMap({
            renderData: this.renderData,
            xLabels: this.filteredDataset.xLabels
        });
        this.ItemDataMap = this.makeItemDataMap({
            renderData: this.renderData,
            itemLabels: this.filteredDataset.itemLabels ?? []
        });
        // this.renderData = makeGraphicRenderData({
        //   data: this.renderData,
        //   xLabels: this.dataset.xLabels,
        //   itemLabels: this.itemLabels,
        //   xScale: this.xScale,
        //   yScale: this.yScale
        // })
        this.initGraphic(this.filteredParams);
        this.setGraphicData({
            renderData: this.renderData,
            itemLabels: this.filteredDataset.itemLabels ?? [],
            xLabels: this.filteredDataset.xLabels,
            xScale: this.xScale,
            yScale: this.yScale
        });
        // 繪製座標軸
        this.axisColumn.setDataset({
            xScale: this.xScale,
            yScale: this.yScale,
            maxValue: this.maxValue
        });
        this.axisColumn.render();
        this.renderGraphic();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    makeActivedCallbackData = (activedItemId, event) => {
        return makeCallbackData({
            dataset: this.dataset,
            activedItemId: activedItemId,
            XDataMap: this.XDataMap,
            ItemDataMap: this.ItemDataMap,
            padding: this.params.padding,
            xRangeScale: this.xRangeScale,
            event
        });
    };
    setRangeEvent(selection) {
        selection
            // .on('mousedown', (d) => {
            //   this.dragstartX = d3.event.clientX
            // })
            .on('click', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d, i) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoverCallback(callbackData);
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.mouseoutCallback(callbackData);
        });
    }
    showTooltip(eventData, event) {
        if (event && eventData && this.xScale) {
            this.tooltip.setDatum({
                data: eventData,
                // x: d3.event.clientX,
                x: eventData.clientX - eventData.offsetX + this.params.padding.left + this.xScale(eventData.xLabel),
                y: d3.event.clientY
            });
        }
        else {
            this.tooltip.remove();
        }
    }
    setCoverEvent(selection) {
        selection
            .on('mouseover', (d, i) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mousemove', (d) => {
            const tooltipData = this.makeActivedCallbackData('', d3.event);
            this.coverHighlight(tooltipData);
            this.showTooltip(tooltipData, d3.event);
        })
            .on('mouseout', (d) => {
            // 仍在場景中的話不動作
            if (d3.event.offsetX > 0
                && d3.event.offsetX < this.width
                && d3.event.offsetY > 0
                && d3.event.offsetY < this.height) {
                return;
            }
            this.coverHighlight(undefined);
            this.tooltip.remove();
        });
    }
    // 複蓋區域 highlight
    coverHighlight(eventData) {
        // 標示線
        if (this.params.showAuxLine) {
            this.renderAuxLine({
                coverSelection: this.coverSelection,
                xLabels: this.dataset.xLabels,
                activeRangeIndex: eventData ? eventData.rangeIndex : -1,
                xScale: this.xScale,
                padding: this.params.padding,
                axisHeight: this.axisHeight
            });
        }
        // 圖形
        this.renderGraphicCoverHighlight({
            eventData: eventData,
            xScale: this.xScale,
            yScale: this.yScale,
            padding: this.params.padding,
        });
    }
    renderAuxLine({ coverSelection, xLabels, activeRangeIndex, xScale, padding, axisHeight }) {
        if (!xScale) {
            return;
        }
        const auxLineData = xLabels.map((d, i) => {
            const x = (xScale(d) || 0);
            return {
                x1: x,
                x2: x,
                y1: 0,
                y2: axisHeight,
                active: activeRangeIndex == i
            };
        });
        this.utilAuxLine.setData(auxLineData);
        this.utilAuxLine.render();
    }
}
