import { TooltipAside } from '@bpchart/d3-modules/tooltip';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_PADDING, DEFAULT_COLORS } from '@bpchart/d3-modules/defaults';
import datum from './datum';
import { DEFAULT_TOOLTIP_ASIDE } from '@bpchart/d3-modules/tooltip';
export default {
    props: {
        module: TooltipAside,
        width: DEFAULT_CHART_WIDTH,
        height: DEFAULT_CHART_HEIGHT,
        params: DEFAULT_TOOLTIP_ASIDE,
        datum
    },
    emits: {
        click: {},
        mouseover: {},
        mousemove: {},
        mouseout: {}
    }
};
