import { ChartDateRankBubbles } from '@bpchart/d3-modules/chartDateRank';
import dataset from './dataset';
export default {
    props: {
        module: ChartDateRankBubbles,
        params: {
            tooltipAside: {
                templateHtml: (data) => {
                    if (data.groupData && data.groupData.length) {
                        return `
              <div>
                <div>${data.xLabel}</div>
                <div>${data.groupData.map((d) => d.value).join(',')}</div>     
              </div>
            `;
                    }
                    return '';
                },
            },
            rowAmount: 10,
            highlightTarget: 'group'
        },
        dataset,
    },
    emits: {
        mouseover: {},
        mousemove: {},
        mouseout: {},
        click: {},
        zoom: {}
    }
};
