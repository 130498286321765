import * as d3 from 'd3';
import Tooltip from './Tooltip';
// import { TooltipRenderDatum } from '../../baseComponents/Tooltip/types'
import { getHtmlElementSize } from '../d3Utils';
import { DEFAULT_TOOLTIP_ASIDE } from './defaults';
export default class TooltipAside extends Tooltip {
    params = DEFAULT_TOOLTIP_ASIDE;
    tooltipSelection = undefined;
    lineSelection = undefined;
    containerSize = { height: 0, width: 0, x: 0, y: 0 };
    // public options: TooltipAsideParams = {
    //   type: 'white',
    //   templateHtml: this.renderParams.templateHtml,
    //   yLine: false,
    //   alignCenter: false
    // }
    constructor(selection, params) {
        super(selection, params);
        // this.params = {
        //   ...this.params,
        //   ...params
        // }
        this.setParams(params ?? this.params);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
    }
    doRenderTooltip(data) {
        try {
            this.tooltipSelection.interrupt('fadeout');
        }
        catch (e) { }
        const tooltipSize = (this.tooltipSelection && this.tooltipSelection.node())
            ? getHtmlElementSize(this.tooltipSelection)
            : { width: 0, height: 0 };
        // const maxX = this.containerSize.x + this.containerSize.width - tooltipSize.width
        // const maxY = this.containerSize.y + this.containerSize.height - tooltipSize.height
        const minX = 0;
        const maxX = this.containerSize.width - tooltipSize.width;
        const minY = 0;
        const maxY = this.containerSize.height - tooltipSize.height;
        const setXLocation = (selection) => {
            selection
                .style('left', (d) => {
                const x = d.x - this.containerSize.x;
                if (x > maxX) {
                    // tooltip在左側
                    let leftSideX = x - tooltipSize.width - 20;
                    if (leftSideX < minX) {
                        leftSideX = minX;
                    }
                    return `${leftSideX}px`;
                }
                return `${x + 20}px`; // tooltip在右側
            });
        };
        const setYLocation = (selection) => {
            selection
                .style('top', (d) => {
                const y = d.y - this.containerSize.y;
                if (this.params.alignCenter) {
                    // 置中
                    // return this.containerSize.y + (this.containerSize.height / 2) - (tooltipSize.height / 2)
                    return (this.containerSize.height / 2) - (tooltipSize.height / 2);
                }
                if (y < maxY) {
                    return `${y}px`;
                }
                return `${maxY + 5}px`;
            });
        };
        const parentNode = this.selection.node().parentNode;
        const update = d3.select(parentNode)
            // const update = d3.select('body') // @Q@ 因為div元素無法放進 svg，所以暫先放在body最外層
            // const update = this.selection
            .selectAll('.bpchat___tooltip')
            .data(data);
        update
            .call(setYLocation)
            .transition()
            .duration(50)
            .call(setXLocation);
        const enter = update.enter()
            .append('div')
            .classed('bpchat___tooltip', true)
            .call(this.setTooltipStyle);
        enter
            .call(setXLocation)
            .call(setYLocation);
        this.tooltipSelection = update.merge(enter);
        this.tooltipSelection
            .style('position', 'absolute')
            .style('z-index', 10000)
            .html((d) => d.contentHtml)
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
        update.exit()
            .transition('fadeout')
            .style('opacity', 0)
            .remove()
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
    }
    renderYLine(data) {
        const x = data[0] ? data[0].x - this.containerSize.x : 0;
        const update = this.selection
            .selectAll('.bpchart__tooltip__line')
            .data(data);
        const enter = update.enter()
            .append('line')
            .classed('bpchart__tooltip__line', true)
            .style('stroke', '#E4E7ED')
            // .style('stroke', '#606060')
            .style('stroke-width', 1)
            .style('pointer-events', 'none');
        this.lineSelection = update.merge(enter)
            .attr('x1', x)
            .attr('y1', 0)
            .attr('x2', x)
            .attr('y2', this.containerSize.height);
        update.exit()
            .transition('fadeout')
            .style('opacity', 0)
            .remove()
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
    }
    renderTooltip(data) {
        this.containerSize = getHtmlElementSize(this.selection);
        if (this.params.yLine == true) {
            this.renderYLine(data);
        }
        this.doRenderTooltip(data);
    }
    remove() {
        if (this.tooltipSelection) {
            this.tooltipSelection
                .transition('fadeout')
                .style('opacity', 0)
                .remove()
                .on('end', () => {
                // @Q@ 硬寫的，為避免沒刪除到直接 select元素
                // d3.selectAll<HTMLDivElement, TooltipAsideRenderDatum>('.bpchat___tooltip').remove()
                if (this.params.yLine == true) {
                    this.lineSelection?.remove();
                }
            });
        }
        this.renderData.contentHtml = '';
    }
}
