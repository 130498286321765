// require('./dagre_directed_chart.scss')
import * as d3 from 'd3';
import { svgHtml, fitTextToCircle } from '../d3Utils';
import ChartDirected from './ChartDirected';
import TooltipFollowing from '../tooltip/TooltipFollowing';
import { DEFAULT_CHART_DIRECTED_FORCE_PARAMS } from './defaults';
// 按鈕icon樣式
const fnIconSize = 16;
const fnIconActiveSize = 26;
const fnIconActiveOffset = (fnIconActiveSize - fnIconSize) / 2; // 滑鼠移過觸發的偏移
const tagSize = 24;
const tagTextSize = 12;
const pushpinSvg = `<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="69.654" y1="448.2119" x2="416.1364" y2="101.7296" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FFC06B"/>
<stop offset="1" style="stop-color:#E66900"/>
</linearGradient>
<circle style="fill:url(#SVGID_3_);" cx="255.996" cy="255.996" r="255.996"/>
<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="137.2118" y1="208.5621" x2="176.5023" y2="169.2816" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FFFFFF"/>
<stop offset="1" style="stop-color:#C8C6CC"/>
</linearGradient>
<polygon style="fill:url(#SVGID_4_);" points="216.374,254.563 95.273,416.712 257.424,295.611 "/>
<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="222.4087" y1="414.4272" x2="382.3457" y2="254.4902" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FF0335"/>
<stop offset="1" style="stop-color:#990011"/>
</linearGradient>
<path style="fill:url(#SVGID_5_);" d="M330.534,314.016l-3.5-3.5l91.033-138.141l18.583,18.583c5.764,5.764,15.196,5.764,20.959,0  l4.368-4.368c5.764-5.764,5.764-15.195,0-20.959L346.356,50.01c-5.764-5.764-15.196-5.764-20.959,0l-4.368,4.368  c-5.764,5.764-5.764,15.196,0,20.959l18.583,18.583l-138.141,91.033l-3.501-3.5c-9.502-9.502-25.05-9.502-34.552,0l-14.846,14.846  l167.115,167.115l14.846-14.846C340.036,339.066,340.036,323.518,330.534,314.016z"/>`;
function returnPushpinLocation(circleR) {
    return {
        x: circleR * 0.8,
        y: -circleR
    };
}
function returnExpandBtnLocation(circleR) {
    return {
        x: circleR,
        y: -circleR + 20
    };
}
// 新增節點遞迴（目前只展開一層所以無遞迴）
function addLoop({ nodes, links }, { nodes: allNodes, links: allLinks }, NodesMap, originID) {
    const rootID = allNodes[0].id;
    // step1 新增關聯links
    let findIDs = [];
    for (let i in allLinks) {
        let newNode = null;
        if (allLinks[i]._target === originID) {
            newNode = allLinks[i]._source;
            // } else if (allLinks[i]._source === originID) {
            //   newNode = allLinks[i]._target
        }
        else {
            continue;
        }
        let isExist = links.some((d) => d._source === allLinks[i]._source && d._target === allLinks[i]._target);
        if (isExist === true) {
            continue;
        }
        let newLink = JSON.parse(JSON.stringify(allLinks[i])); // 要用深拷貝不然有bug
        links.push(newLink);
        findIDs.push(newNode);
    }
    if (findIDs.length < 1) {
        return { nodes, links };
    }
    // step2 如果source-nodes無存在目前nodes則新增進來
    let findNoneNodeIDs = [];
    let addNodes = [];
    for (let testID of findIDs) {
        let isExist = nodes.some((d) => d.id === testID);
        if (isExist === false) {
            // let findNode = allNodes.find((d: ChartDirectedForceNode) => d.id === testID)
            let findNode = NodesMap.get(testID);
            // let findNode = allNodes.find(node => node.id === testID)
            if (findNode) {
                addNodes.push(findNode);
                findNoneNodeIDs.push(findNode.id);
            }
        }
    }
    // step3 修正展開狀態
    addNodes = addNodes.map((node) => {
        let hasTop = allLinks.some(link => {
            // 非node的上層
            if (link._target !== node.id) {
                return false;
            }
            // 如果上層為根節點則無展開按鈕
            if (link._source === rootID) {
                return false;
            }
            // 有上層且非根節點
            return true;
        });
        if (hasTop) {
            node.hasTop = true;
            node.isTopExpanded = false; // 預設false
        }
        return node;
    });
    nodes = nodes.concat(addNodes);
    // step3 用前步驟新增的nodes再往子node新增
    // for (let nextID of findNoneNodeIDs) {
    //   addLoop(nodes, links, allNodes, allLinks, nextID)
    // }
    return { nodes, links };
}
// 刪除節點遞迴
function deleteLoop({ nodes, links }, { nodes: allNodes, links: allLinks }, originID) {
    const rootID = allNodes[0].id;
    // let newData = { nodes, links }
    let newNodes = [];
    let newLinks = [];
    // step1 刪除關聯links
    let findIDs = [];
    for (let i in links) {
        if (links[i]._target === originID && links[i]._source !== rootID) {
            // 取得點擊對象的上游（如為根節點不移除）
            findIDs.push(links[i]._source);
            // } else if (links[i]._source === originID
            // && links.some(e => e._target == links[i]._target && e._source == rootID)) {
            //   // 如點擊對象的下游是根節點的下游，取得點擊對象的下游
            //   findIDs.push(links[i]._target)
        }
        else {
            // 不刪除
            newLinks.push(links[i]);
        }
    }
    // step2 過濾掉無連接到根節點的線
    const filterLinksFromRoot = (links) => {
        let newLinks = [];
        // const searchUp = (_source:string, _target:string) => {
        //   const upLinks = links.filter(link => _source === link._target) // 上一層節點的連接線
        //   for (const link of upLinks) {
        //     let isExist = newLinks.some(newLink => {
        //       return newLink._source === link._source && newLink._target === link._target
        //     })
        //     if (isExist == false) {
        //       newLinks.push(link)
        //       searchUp(link._source, link._target)
        //     }
        //   }
        // }
        // const topFromRoot = links.filter(link => link._target === rootID)
        // for (const link of topFromRoot) {
        //   newLinks.push(link)
        //   searchUp(link._source, link._target)
        // }
        // const searchDown = (_source:string, _target:string) => {
        //   const downLinks = links.filter(link => _target === link._source) // 下一層節點的連接線
        //   for (const link of downLinks) {
        //     let isExist = newLinks.some(newLink => {
        //       return newLink._source === link._source && newLink._target === link._target
        //     })
        //     if (isExist == false) {
        //       newLinks.push(link)
        //       searchDown(link._source, link._target)
        //     }
        //   }
        // }
        // const downFromRoot = links.filter(link => link._source === rootID)
        // for (const link of downFromRoot) {
        //   let isExist = newLinks.some(newLink => {
        //     return newLink._source === link._source && newLink._target === link._target
        //   })
        //   if (isExist == false) {
        //     newLinks.push(link)
        //   }
        //   searchDown(link._source, link._target)
        // }
        const searchLink = (currentID) => {
            const topFromCurrent = links.filter(link => link._target === currentID);
            const downFromCurrent = links.filter(link => link._source === currentID);
            for (const link of topFromCurrent) {
                let isExist = newLinks.some(newLink => {
                    return newLink._source === link._source && newLink._target === link._target;
                });
                if (isExist == false) {
                    newLinks.push(link);
                    searchLink(link._source);
                }
            }
            for (const link of downFromCurrent) {
                let isExist = newLinks.some(newLink => {
                    return newLink._source === link._source && newLink._target === link._target;
                });
                if (isExist == false) {
                    newLinks.push(link);
                    searchLink(link._target);
                }
            }
        };
        searchLink(rootID);
        return newLinks;
    };
    newLinks = filterLinksFromRoot(newLinks);
    // step3 過濾掉無連接線的節點
    newNodes = nodes.filter((node) => {
        return newLinks.find((link) => node.id === link._source || node.id === link._target) != null;
    });
    // step2 如果source-node已無edge則刪除該node
    // let findNoneLinkIDs = []
    // for (let testID of findIDs) {
    //   let testFilter = links.find((d: ChartDirectedForceLink) => {
    //     return d._source === testID // 該node為上游的連接線
    //       // || (d._source === rootID && d._target === testID) // 該node為根節點的下游
    //       || d._target === testID
    //   })
    //   if (testFilter == null) {
    //     findNoneLinkIDs.push(testID)
    //     // @Q@ 刻意讓泡泡飛走所以不移除節點，如果要移除的話就把下面這行解開
    //     // nodes = nodes.filter((d: ChartDirectedForceNode) => d.id !== testID)
    //   }
    // }
    // @Q@ 後來發現原先做法無法找出所有子節點（漏掉了同時是上游也是下游的情況），所以改為刻意不移除節點的做法
    // step3 用前步驟被刪除的nodes再去往子node刪
    // for (let nextID of findNoneLinkIDs) {
    //   allNodes = allNodes.map((d: ChartDirectedForceNode) => {
    //     if (d.id === nextID) {
    //       // 展開狀態關閉
    //       d.isTopExpanded = false
    //     }
    //     return d
    //   })
    //   newData = deleteLoop(nodes, links, allNodes, allLinks, nextID)
    //   nodes = newData.nodes
    //   links = newData.links
    // }
    // step3 用前步驟被刪除的nodes再去往子node刪
    return {
        nodes: newNodes,
        links: newLinks
    };
}
// function getExpendIconX (rectWidth) {
//   return (rectWidth / 2) + 5
// }
export default class ChartForceDirected extends ChartDirected {
    // public selection: d3.Selection<any, any, any, any>
    params = DEFAULT_CHART_DIRECTED_FORCE_PARAMS;
    // private el: d3.Selection<any, any, any, any>
    // private svgGroup: d3.Selection<any, any, any, any>
    defs;
    // private chartG = null
    // 全部的資料（包含未展開的）
    renderDataOrigin = {
        nodes: [],
        links: [],
        // _NodesMap: undefined // 將nodes轉為Map物件
    };
    // 顯示的資料（不包含未展開的）
    renderData = {
        nodes: [],
        links: [],
        // _NodesMap: undefined // 將nodes轉為Map物件
    };
    NodesMap = new Map();
    clickCallback = function () { return null; };
    // private extandNodesCallback: (arg: ChartDirectedForceNodeRendered) => void = function () { return null }
    tooltip = undefined;
    // private d3Zoom?: d3.ZoomBehavior<any, any> = undefined
    // private width: number = 0;
    // private height: number = 0;
    force;
    path = undefined;
    pathText = undefined;
    circle = undefined;
    circleText = undefined;
    circleBtn = undefined;
    tag = undefined;
    pathG;
    pathTextG;
    circleG;
    circleTextG;
    circleBtnG;
    tagG;
    pushpin = undefined;
    countdown = 10; // force stop倒數計時秒數
    isCountingdown = false; // 是否正在倒數計時
    noneStopMode = false; // 動畫不停止模式（當需要避免動畫卡住）
    highlightLockMode = false; // 鎖定highlight模式
    currentHighlightId = '';
    styleConfig = {
        styles: {
            circleRoot: 'stroke-width: 5px; fill: #1778F5;',
            circle: 'stroke: #909399;stroke-width: 5px; fill: #fff;',
            circleText: 'fill: #303133; font-weight: normal; ',
            pathText: 'fill: #606266; font-weight: 300; font-size: 13px; text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;pointer-events: none;',
            pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
            pathDown: 'stroke-dasharray: 5; stroke: #909399; stroke-width: 1.5px; fill:none;',
            arrow: 'fill: #909399',
            circleLocked: 'stroke: #c00',
            pathLocked: 'stroke: #c00',
            arrowLocked: 'fill: #c00',
            expandBtn: 'fill:#1778F5',
            tag: 'fill:#f56c6c'
        },
        arrows: ['arrow'],
        circlesR: {
            circleRoot: 60,
            circle: 60
        },
        _StylesMap: undefined,
        _CirclesRMap: undefined // circlesR 的Map
    };
    plusSvgHtml = this.returnPlusSvgHtml();
    minusSvgHtml = this.returnMinusSvgHtml();
    // private tagHtml = this.returnTagHtml()
    rootID = '';
    // private updateConfig: ChartDirectedForceUpdate | undefined
    isInit = false; // 紀錄是否初始化完成
    constructor(el, params) {
        super(el, params);
        if (params) {
            this.params = {
                ...this.params,
                ...params
            };
        }
        // this.selection = el
        // const { width, height } = this.getContainerSize()
        // this.width = width
        // this.height = height
        // this.selection = el
        //   .append("svg")
        //   .attr("width", width)
        //   .attr("height", height);
        this.svgGroup.classed('bp__force-directed-chart__g', true);
        this.defs = this.svgGroup.append("defs");
        this.pathG = this.svgGroup.append("g");
        this.circleG = this.svgGroup.append("g");
        this.pathTextG = this.svgGroup.append("g");
        this.circleTextG = this.svgGroup.append("g");
        this.circleBtnG = this.svgGroup.append("g");
        this.tagG = this.svgGroup.append("g");
        this.force = d3.forceSimulation()
            .velocityDecay(0.2)
            // .alphaDecay(0.1)
            .force("link", d3.forceLink()
            .id(d => d.id)
            .strength(1)
            .distance(d => {
            if (d.direction === 'top') {
                return 200;
            }
            else {
                return 250;
            }
        }))
            .force("charge", d3.forceManyBody().strength(-2000))
            .force("collision", d3.forceCollide(60).strength(1)) // @Q@ 60為泡泡的R，暫時是先寫死的
            .force("center", d3.forceCenter(this.width / 2, this.height / 2));
        let linearGradient = this.selection
            .append('defs')
            .append('linearGradient')
            .attr('id', 'gradient');
        linearGradient
            .append('stop')
            .attr('offset', '0%')
            .style('stop-color', '#81c4c3');
        // .style('stop-color', '#2499FF')
        linearGradient
            .append('stop')
            .attr('offset', '100%')
            .style('stop-color', '#2f8cbf');
        // .style('stop-color', '#004DB1')
        // 初始化styleConfig
        this.setStyle({
            styles: params.styles,
            arrows: params.arrows,
            circlesR: params.circlesR
        });
        this.tooltip = new TooltipFollowing(this.selection, {
            templateHtml: (data) => `
        <div>${data}</div>
      `,
            insideBoxMode: true,
            type: 'black'
        });
    }
    render() {
        this.doRender({
            nodes: this.renderData.nodes,
            links: this.renderData.links
        });
        // this.setZoom(0, 0)
    }
    // 初始化
    setDataset({ nodes = [], links = [], expandAll = false }) {
        this.isInit = false;
        setTimeout(() => {
            this.isInit = true;
        }, 1000);
        this.dataset = { nodes, links, expandAll };
        this.resetNodes({ nodes, links });
        // 實際呈現的資料
        // this.renderData = JSON.parse(JSON.stringify(this.renderDataOrigin))
        this.renderData = {
            nodes: Object.assign([], this.renderDataOrigin.nodes),
            links: JSON.parse(JSON.stringify(this.renderDataOrigin.links)),
        };
        if (expandAll === false) {
            // 預設第一筆為根節點
            const rootNode = this.renderData.nodes[0] ? this.renderData.nodes[0].id : '';
            // 全部顯示的節點
            let allShowNodes = [rootNode];
            for (const link of this.renderData.links) {
                if (link._source === rootNode) {
                    allShowNodes.push(link._target);
                }
                if (link._target === rootNode) {
                    allShowNodes.push(link._source);
                }
            }
            // root上下游一層的連結
            const allShowLinks = this.renderData.links.filter(d => {
                if (d._source === rootNode || d._target === rootNode) {
                    return true;
                }
                return false;
            });
            // // root上下游一層全部的連結
            // const allShowLinks = this.renderData.links.filter(d => {
            //   if (allShowNodes.includes(d._source) && allShowNodes.includes(d._target)) {
            //     return true
            //   }
            //   return false
            // })
            this.renderData.links = allShowLinks;
            this.renderData.nodes = this.renderData.nodes.filter(d => {
                if (allShowNodes.includes(d.id)) {
                    return true;
                }
                return false;
            });
        }
        // _NodesMap
        this.NodesMap = new Map();
        this.renderDataOrigin.nodes.forEach((d) => {
            this.NodesMap.set(d.id, d);
        });
        this.initZoom({
            ...this.zoom,
            xOffset: 0,
            yOffset: 0,
        });
    }
    setParams(data) {
        if (data.screenShotMode && data.screenShotMode == true && data.screenShotMode != this.params.screenShotMode) {
            this.screenShotMode(data.screenShotMode);
        }
        if (data.autoZoom && data.autoZoom == true && data.screenShotMode != this.params.screenShotMode) {
            // console.log('resetZoom')
            // this.resize()
            this.resetZoom();
        }
        // if (data.lockMode != undefined && data.lockMode != this.params.lockMode) {
        //   if (data.lockMode == true) {
        //     this.highlightLockMode = true
        //     this.forceStop()
        //   } else {
        //     this.highlightLockMode = false
        //     this.removeHighlight()
        //     this.removePushpin()
        //     this.forceRestart()
        //     this.circle && this.circle.each((c, i, all) => {
        //       d3.select(all[i]).select('circle').attr('style', (c: any) => this.styleConfig._StylesMap!.get(c.style.circle)!)
        //     })
        //     this.path && this.path.each((c, i, all) => {
        //         let pathStyle = c.style.path ? this.styleConfig._StylesMap!.get(c.style.path)
        //           : (c.direction === 'top' ? this.styleConfig._StylesMap!.get('pathTop') : this.styleConfig._StylesMap!.get('pathDown'))
        //         d3.select(all[i]).attr('marker-end', `url(#${c.style.arrow || 'arrow'})`)
        //         d3.select(all[i]).attr('style', () => {
        //           return pathStyle!
        //         })
        //     })
        //   }
        // }
        if (data.highlightId != undefined && data.highlightId != this.params.highlightId) {
            if (data.highlightId) {
                this.highlight(data.highlightId, this.highlightLockMode);
            }
            else {
                this.removeHighlight();
            }
        }
        this.params = {
            ...this.params,
            ...data
        };
        if (data.scaleExtent) {
            this.zoom.scaleExtent = data.scaleExtent;
        }
        this.initZoom();
    }
    // 置中
    resize({ width, height } = { width: this.width, height: this.height }) {
        this.width = width;
        this.height = height;
        // 重設svg尺寸
        this.selection.attr('width', `${width}px`);
        this.selection.attr('height', `${height}px`);
        if (this.params && this.params.autoZoom) {
            this.resetZoom();
        }
        // console.log('{ width, height }', { width, height })
    }
    // @Q@ 原本是要給 params控制的，尚未實作
    highlightAndLock(highlightId) {
        if (!highlightId) {
            this.highlightLockMode = false;
            this.removeHighlight();
        }
        else {
            const setHighlightAndLock = () => {
                this.highlightLockMode = true;
                this.forceStop();
                this.highlight(highlightId, this.highlightLockMode);
                const groups = this.circle?.filter(d => d.id == highlightId);
                if (groups) {
                    this.appendPushpin(groups);
                }
            };
            if (this.isInit == false) {
                // 等展開動畫跑一段時間再highlight
                setTimeout(() => {
                    setHighlightAndLock();
                }, 1000);
            }
            else {
                setHighlightAndLock();
            }
        }
    }
    async screenShotMode(isScreenShot) {
        if (isScreenShot) {
            await new Promise(resolve => setTimeout(resolve)); // @Q@ lockMode == true時會有奇怪的bug，試了很久發現這邊強制非同步可以解決但不知道為什麼就是了
            this.forceRestart();
            this.resize();
            // 置中
            this.transformZoom({
                x: 0,
                y: 0,
                k: 1
            });
            // force置中
            this.force.force("center", d3.forceCenter(this.width / 2, this.height / 2));
        }
        else {
            this.resize();
            this.resetZoom(); // 置中
            this.forceRestart();
        }
    }
    resetZoom() {
        this.transformZoom({
            x: 0,
            y: 0,
            k: 1
        });
        // force置中
        this.force.force("center", d3.forceCenter(this.width / 2, this.height / 2));
        // force restart
        this.forceRestart();
    }
    // 建立箭頭
    setArrowMarker() {
        // Per-type markers
        const update = this.defs
            .selectAll(".bp__force-directed-chart__arrow-marker")
            .data(this.styleConfig.arrows);
        const enter = update.enter()
            .append("marker")
            .classed("bp__force-directed-chart__arrow-marker", true)
            // .attr("viewBox", "0 -5 10 10")
            .attr("viewBox", "0 -5 10 10")
            // .attr("markerWidth", 6)
            // .attr("markerHeight", 6)
            .attr("markerWidth", 10)
            .attr("markerHeight", 10)
            .attr("orient", "auto");
        enter.merge(update)
            .attr("id", type => type)
            // .attr("refX", 60 + 15) // @Q@ 60為泡泡的R，暫時是先寫死的
            .attr("refX", type => {
            return this.styleConfig._CirclesRMap.get(type) ? this.styleConfig._CirclesRMap.get(type) - 20 : 60 - 20;
        }) // @Q@ 我也不太確定為什麼是-20
            .attr("refY", 0)
            .attr("style", type => this.styleConfig._StylesMap.get(type));
        enter.append("path")
            .attr("d", "M0,-5L10,0L0,5");
        update.exit().remove();
    }
    highlight(id, highlightLockMode) {
        if (this.noneStopMode === true) {
            return;
        }
        this.currentHighlightId = id;
        // console.log('this.highlightLockMode', this.highlightLockMode)
        // force stop
        // this.forceStop()
        let highlightNodes = [];
        this.renderData.links.forEach((n) => {
            if (n._source === id || n._target === id) {
                if (highlightNodes.includes(n._source) === false) {
                    highlightNodes.push(n._source);
                }
                if (highlightNodes.includes(n._target) === false) {
                    highlightNodes.push(n._target);
                }
            }
        });
        if (highlightLockMode === true) {
            this.circle && this.circle.each((c, i, all) => {
                if (highlightNodes.includes(c.id) === true) {
                    d3.select(all[i]).select('circle').attr('style', (c) => {
                        return `${this.styleConfig._StylesMap.get(c.style.circle)};${this.styleConfig._StylesMap.get('circleLocked')}`; // 加上鎖定住的顏色
                    });
                    d3.select(all[i]).attr('opacity', 1);
                }
                else {
                    d3.select(all[i]).attr('opacity', 0.15);
                }
            });
            this.path && this.path.each((c, i, all) => {
                if (c._target === id || c._source === id) {
                    let pathStyle = c.style.path ? this.styleConfig._StylesMap.get(c.style.path)
                        : (c.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'));
                    d3.select(all[i]).attr('marker-end', 'url(#arrowLocked)');
                    d3.select(all[i]).attr('style', c => {
                        return `${pathStyle};${this.styleConfig._StylesMap.get('pathLocked')}`; // 加上鎖定住的顏色
                    });
                    d3.select(all[i]).attr('opacity', 1);
                }
                else {
                    d3.select(all[i]).attr('opacity', 0.15);
                }
            });
        }
        else {
            this.circle && this.circle.each((c, i, all) => {
                if (highlightNodes.includes(c.id) === true) {
                    d3.select(all[i]).select('circle').attr('style', (c) => this.styleConfig._StylesMap.get(c.style.circle));
                    d3.select(all[i]).attr('opacity', 1);
                }
                else {
                    d3.select(all[i]).attr('opacity', 0.15);
                }
            });
            this.path && this.path.each((c, i, all) => {
                if (c._target === id || c._source === id) {
                    let pathStyle = c.style.path ? this.styleConfig._StylesMap.get(c.style.path)
                        : (c.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'));
                    d3.select(all[i]).attr('marker-end', `url(#${c.style.arrow || 'arrow'})`);
                    d3.select(all[i]).attr('style', () => {
                        return pathStyle;
                    });
                    d3.select(all[i]).attr('opacity', 1);
                }
                else {
                    d3.select(all[i]).attr('opacity', 0.15);
                }
            });
        }
        // this.circle.style('opacity', c => {
        //   if (highlightNodes.includes(c.id) === true) {
        //     return 1
        //   } else {
        //     return 0.15
        //   }
        // })
        // this.path.style('opacity', p => {
        //   if (p._target === d.id || p._source === d.id) {
        //     return 1
        //   } else {
        //     return 0.15
        //   }
        // })
        // this.pathText.each((c, i, all) => {
        //   if (c._target === d.id || c._source === d.id) {
        //     d3.select(all[i]).style('opacity', 1)
        //   } else {
        //     d3.select(all[i]).style('opacity', 0.15)
        //   }
        // })
        this.pathText && this.pathText.attr('opacity', p => {
            if (p._target === id || p._source === id) {
                return 1;
            }
            else {
                return 0.15;
            }
        });
        // this.circleText.each((c, i, all) => {
        //   if (highlightNodes.includes(c.id) === true) {
        //     d3.select(all[i]).style('opacity', 1)
        //   } else {
        //     d3.select(all[i]).style('opacity', 0.15)
        //   }
        // })
        this.circleText && this.circleText.attr('opacity', c => {
            if (highlightNodes.includes(c.id) === true) {
                return 1;
            }
            else {
                return 0.15;
            }
        });
        this.circleBtn && this.circleBtn.attr('opacity', c => {
            if (highlightNodes.includes(c.id) === true) {
                return 1;
            }
            else {
                return 0.15;
            }
        });
    }
    removeHighlight() {
        this.currentHighlightId = '';
        // force restart
        this.forceRestart();
        this.circle && this.circle.attr('opacity', 1);
        this.path && this.path.attr('opacity', 1);
        this.pathText && this.pathText.attr('opacity', 1);
        this.circleText && this.circleText.attr('opacity', 1);
        this.circleBtn && this.circleBtn.attr('opacity', 1);
        this.tag && this.tag.attr('opacity', 1);
    }
    linkArc(d) {
        // var dx = d.target.x - d.source.x,
        //     dy = d.target.y - d.source.y
        // dr讓方向線變成有弧度的
        //     dr = Math.sqrt(dx * dx + dy * dy);
        // return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
        return "M" + d.source.x + "," + d.source.y + " L" + d.target.x + "," + d.target.y;
    }
    translate(d) {
        return "translate(" + d.x + "," + d.y + ")";
    }
    translateCenter(d) {
        const x = d.source.x + ((d.target.x - d.source.x) / 1.8); // 置中的話除2
        const y = d.source.y + ((d.target.y - d.source.y) / 1.8); // 置中的話除2
        return "translate(" + x + "," + y + ")";
    }
    drag() {
        let originHighlightLockMode; // 拖拽前的highlightLockMode
        return d3.drag()
            .on("start", (d) => {
            if (this.params.lockMode) {
                return;
            }
            if (!d3.event.active) {
                this.forceRestart();
            }
            d.fx = d.x;
            d.fy = d.y;
            // 鎖定模式才不會在拖拽過程式觸發到其他事件造成衝突
            originHighlightLockMode = this.highlightLockMode;
            this.highlightLockMode = true;
            this.noneStopMode = true;
            // 動畫會有點卡住所以乾脆拿掉
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
        })
            .on("drag", (d) => {
            if (this.params.lockMode) {
                return;
            }
            if (!d3.event.active) {
                this.force.alphaTarget(0);
            }
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        })
            .on("end", (d) => {
            if (this.params.lockMode) {
                return;
            }
            d.fx = null;
            d.fy = null;
            this.highlightLockMode = originHighlightLockMode; // 還原拖拽前的highlightLockMode
            this.noneStopMode = false;
            if (this.highlightLockMode) {
                this.forceStop();
            }
        });
    }
    appendPushpin(g) {
        if (this.pushpin && this.pushpin.size()) {
            this.pushpin.remove();
        }
        this.pushpin = g.append('svg')
            .classed('bp__pushpin', true)
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .attr('xmls', 'http://www.w3.org/2000/svg')
            .attr('version', '1.1')
            .attr('width', fnIconSize)
            .attr('height', fnIconSize)
            .attr('viewBox', '0 0 511.993 511.993')
            .attr('cursor', 'pointer')
            .attr('x', (d, i, all) => {
            return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x;
        })
            .attr('y', (d, i, all) => {
            return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y;
        })
            .on('click', (d) => {
            d3.event.stopPropagation();
            if (this.params.lockMode) {
                return;
            }
            this.highlightLockMode = !this.highlightLockMode;
            if (this.highlightLockMode) {
                this.forceStop();
                this.highlight(d.id, this.highlightLockMode);
                // this.highlightAndLock(d.id)
            }
            else {
                this.removeHighlight();
                // this.highlightAndLock(undefined)
            }
        })
            .on('mouseover', (d, i, all) => {
            d3.event.stopPropagation();
            if (this.params.lockMode) {
                return;
            }
            if (this.highlightLockMode === true) {
                // this.forceStop() // 不highlight僅停止動作
                return;
            }
            d3.select(all[i])
                .transition()
                .duration(50)
                .attr('width', fnIconActiveSize)
                .attr('height', fnIconActiveSize)
                .attr('y', (d, i, all) => {
                const originY = returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y;
                return originY - fnIconActiveOffset;
            });
            this.highlight(d.id, this.highlightLockMode);
        })
            .on('mouseout', (d, i, all) => {
            d3.event.stopPropagation();
            if (this.params.lockMode) {
                return;
            }
            if (this.highlightLockMode === true) {
                return;
            }
            d3.select(all[i])
                .transition()
                .duration(50)
                .attr('width', fnIconSize)
                .attr('height', fnIconSize)
                .attr('y', (d, i, all) => {
                return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y;
            });
            this.removePushpin();
            this.removeHighlight();
        });
        svgHtml(this.pushpin, pushpinSvg);
    }
    removePushpin() {
        if (this.pushpin && this.pushpin.size()) {
            this.pushpin.transition().delay(200).remove();
        }
    }
    doRender({ nodes = [], links = [] }) {
        if (!nodes[0]) {
            return;
        }
        const rootID = nodes[0].id;
        // 指向線
        const pathUpdate = this.pathG
            .selectAll("path")
            .data(links, d => `${d._source}->${d._target}`);
        const pathEnter = pathUpdate
            .enter()
            .append("path")
            .attr("class", "link")
            .attr("marker-end", d => `url(#${d.style.arrow || 'arrow'})`)
            .attr("style", (d) => {
            let pathStyle = d.style.path ? this.styleConfig._StylesMap.get(d.style.path)
                : (d.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'));
            return pathStyle;
        });
        // console.log('pathEnter')
        // console.log(pathEnter)
        this.path = pathUpdate.merge(pathEnter);
        pathUpdate.exit().remove();
        // 節點
        const circleUpdate = this.circleG
            .selectAll(".nodeG")
            .data(nodes, d => d.id);
        const circleEnter = circleUpdate
            .enter()
            .append("g")
            .attr("class", "nodeG");
        circleEnter
            .append("circle")
            .attr("class", "node");
        this.circle = circleUpdate.merge(circleEnter);
        this.circle
            .select("circle")
            .attr("r", (d) => {
            return this.styleConfig._CirclesRMap.get(d.style.circle);
        })
            .attr("style", d => {
            return this.styleConfig._StylesMap.get(d.style.circle);
        })
            .on('mouseover', (d, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            // -- tooltip --
            const content = d.tooltip(d);
            if (content) {
                const x = d3.event.clientX + 20;
                const y = d3.event.clientY + 20;
                // this.tooltip = new TooltipFollowing(this.selection, content, x, y, true)
                this.tooltip.setDatum({
                    data: content,
                    x,
                    y
                });
            }
        })
            .on('mousemove', () => {
            if (this.params.lockMode) {
                return;
            }
            // -- tooltip --
            if (this.tooltip != null) {
                const x = d3.event.clientX + 20;
                const y = d3.event.clientY + 20;
                // this.tooltip.move(x, y)
                this.tooltip.setDatum({
                    x,
                    y
                });
            }
        })
            .on('mouseout', () => {
            if (this.params.lockMode) {
                return;
            }
            // -- tooltip --
            if (this.tooltip != null) {
                this.tooltip.remove();
            }
        });
        this.circle
            .on('mouseover', (d, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            // -- highlight --
            if (this.highlightLockMode === true) {
                // force stop
                if (this.noneStopMode === false) {
                    this.forceStop(); // 不highlight僅停止動作
                }
                return;
            }
            this.highlight(d.id, this.highlightLockMode);
            // -- 增加圖釘icon --
            const g = d3.select(all[i]);
            this.appendPushpin(g);
        })
            .on('mouseout', (d, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            // -- 取消highlight --
            if (this.highlightLockMode === true) {
                return;
            }
            this.removeHighlight();
            // -- 展開icon動畫 --
            const g = d3.select(all[i]);
            // -- 移除圖釘icon --
            this.removePushpin();
        })
            .call(this.drag());
        // 泡泡事件
        const circleContainer = this.circle.select('.node');
        circleContainer
            .attr('cursor', 'pointer');
        circleContainer
            .on('click', (item) => {
            if (this.params.lockMode) {
                return;
            }
            if (this.highlightLockMode === true) {
                return;
            }
            this.clickCallback({
                data: item
            });
            if (this.params.extandOnNodeClick) {
                this.toggleTopNodes(item.id);
            }
        });
        // 增加展開icon
        // this.circle.each((d: ChartDirectedForceNodeRendered, i, all) => {
        //   const g = d3.select(all[i])
        //   const node = this.renderData._NodesMap.get(d.id)
        //   if (node && node.hasTop) {
        //     // let iconX = 100
        //     // try {
        //     //   iconX = getExpendIconX(g.select("circle").attr('width'))
        //     // } catch (e: any) { console.error(e) }
        //     let gSvgClassName = ''
        //     let svgContent = ''
        //     if (node.isTopExpanded === true) {
        //       gSvgClassName = 'bp__minus-btn'
        //       svgContent = this.minusSvgHtml
        //     } else {
        //       gSvgClassName = 'bp__plus-btn'
        //       svgContent = this.plusSvgHtml
        //     }
        //     g.selectAll('.bp__plus-btn').remove()
        //     g.selectAll('.bp__minus-btn').remove()
        //     const circleR = this.styleConfig._CirclesRMap.get(d.style.circle)
        //     const gSvg = g.append('svg')
        //       .classed(gSvgClassName, true)
        //       .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
        //       .attr('xmls', 'http://www.w3.org/2000/svg')
        //       .attr('version', '1.1')
        //       .attr('width', fnIconSize)
        //       .attr('height', fnIconSize)
        //       .attr('viewBox', '0 0 511.993 511.993')
        //       .attr('x', (d: ChartDirectedForceNodeRendered, i, all) => {
        //         return circleR
        //       })
        //       .attr('y', (d: ChartDirectedForceNodeRendered, i, all) => {
        //         return - circleR + 20
        //       })
        //     gSvg
        //       .on('click', (item: ChartDirectedForceNodeRendered, i, all) => {
        //         if (this.highlightLockMode === true) {
        //           return
        //         }
        //         // 避免滑鼠再次mouseover畫面又停住
        //         this.noneStopMode = true
        //         setTimeout(() => {
        //           this.noneStopMode = false
        //         }, 1000)
        //         this.removeHighlight()
        //         this.forceRestart() // 因靜止動作會讓點擊後要做的動畫不動，所以要先restart
        //         this.extandNodesCallback(item)
        //       })
        //       .on('mouseover', (d, i, all) => {
        //         d3.select(all[i])
        //           .transition()
        //           .duration(50)
        //           .attr('width', fnIconActiveSize)
        //           .attr('height', fnIconActiveSize)
        //           .attr('y', (d: ChartDirectedForceNodeRendered, i, all) => {
        //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y - fnIconActiveOffset
        //           })
        //       })
        //       .on('mouseout', (d, i, all) => {
        //         d3.select(all[i])
        //           .transition()
        //           .delay(100)
        //           .duration(100)
        //           // .attr('x', iconX)
        //           .attr('width', fnIconSize)
        //           .attr('height', fnIconSize)
        //           .attr('x', (d: ChartDirectedForceNodeRendered, i, all) => {
        //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x
        //           })
        //           .attr('y', (d: ChartDirectedForceNodeRendered, i, all) => {
        //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
        //           })
        //       })
        //     svgHtml(gSvg, svgContent)
        //   }
        // })
        circleUpdate.exit().remove();
        // 指向線文字
        const pathTextUpdate = this.pathTextG
            .selectAll("text")
            .data(links, d => `${d._source}->${d._target}`);
        const pathTextEnter = pathTextUpdate
            .enter()
            .append("text")
            .attr("class", "link-text")
            .attr("x", 8)
            .attr("y", ".31em")
            .attr("fill", "#888")
            .attr('id', (d) => d.label)
            .attr("style", this.styleConfig._StylesMap.get('pathText'))
            .text((d) => d.label);
        this.pathText = pathTextUpdate.merge(pathTextEnter);
        pathTextUpdate.exit().remove();
        // 節點文字
        // const circleTextUpdate = this.circleTextG
        //   .selectAll("text")
        //   .data(nodes)
        // const circleTextEnter = circleTextUpdate
        //   .enter()
        //   .append("text")
        //   .attr("class", "node-text")
        //   .attr('id', (d: ChartDirectedForceNode) => d.id)
        //   .attr("x", -8)
        //   .attr("y", ".31em")
        //   .attr("fill", "#333")
        //   .attr("style", styleDict.circleText)
        //   .text((d: ChartDirectedForceNode) => d.label)
        // circleTextUpdate.exit().remove()
        // this.circleText = circleTextUpdate.merge(circleTextEnter)
        const circleTextUpdate = this.circleTextG
            .selectAll(".textG")
            .data(nodes, d => d.id);
        const circleTextEnter = circleTextUpdate
            .enter()
            .append("g")
            .attr("class", "textG")
            .attr("text-anchor", "middle")
            .style("font-size", "10px");
        // .attr("width", d => {
        //   return circleRDict[d.style.circle] * 2
        // })
        // .attr("height", d => {
        //   return circleRDict[d.style.circle] * 2
        // })
        circleTextEnter.append('text')
            .attr('pointer-events', 'none');
        this.circleText = circleTextUpdate.merge(circleTextEnter);
        this.circleText
            .select('text')
            .attr('style', s => this.styleConfig._StylesMap.get(s.style.circleText));
        this.circleText
            .each((d, i, all) => {
            let g = d3.select(all[i]);
            //   .style("font-size", "10px")
            // g.append('text')
            //   .attr('pointer-events', 'none')
            //   .attr('style', s => this.styleConfig._StylesMap.get(s.style.circleText))
            let r = this.styleConfig._CirclesRMap.get(d.style.circle) || 0;
            let isBreakAll = false;
            if (d.label.length > 4) {
                isBreakAll = true;
            }
            // fitTextToCircle(g, d.label, r, 12, isBreakAll)
            fitTextToCircle(g, {
                text: d.label,
                radius: r,
                lineHeight: 12,
                isBreakAll
            });
        });
        circleTextUpdate.exit().remove();
        // 展開按鈕
        const circleBtnUpdate = this.circleBtnG
            .selectAll(".btnG")
            .data(nodes, d => d.id);
        const circleBtnEnter = circleBtnUpdate
            .enter()
            .append("g")
            .classed("btnG", true);
        circleBtnEnter
            .append("svg")
            .classed("btnSVG", true)
            .attr('id', d => `circleBtnSvg__${d.id}`)
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .attr('xmls', 'http://www.w3.org/2000/svg')
            .attr('version', '1.1')
            .attr('viewBox', '0 0 511.993 511.993');
        // circleBtnSvgEnter.append('circle')
        //   .attr('cx', '255.996')
        //   .attr('cy', '255.996')
        //   .attr('r', '255.996')
        // circleBtnSvgEnter.append('rect')
        //   .attr('')
        this.circleBtn = circleBtnUpdate.merge(circleBtnEnter);
        const circleBtnSvg = this.circleBtn.select('.btnSVG')
            .classed('bp__minus-btn', d => {
            if (d.isTopExpanded === true) {
                return true;
            }
            else {
                return false;
            }
        })
            .classed('bp__plus-btn', d => {
            if (d.isTopExpanded === false) {
                return true;
            }
            else {
                return false;
            }
        })
            .attr('cursor', 'pointer')
            .attr('width', fnIconSize)
            .attr('height', fnIconSize)
            .attr('x', (d, i, all) => {
            return this.styleConfig._CirclesRMap.get(d.style.circle);
        })
            .attr('y', (d, i, all) => {
            const circleR = this.styleConfig._CirclesRMap.get(d.style.circle);
            return -circleR + 20;
        })
            .on('click', (item, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            if (this.highlightLockMode === true) {
                return;
            }
            // this.highlightLockMode = false
            // 避免滑鼠再次mouseover畫面又停住
            this.noneStopMode = true;
            setTimeout(() => {
                this.noneStopMode = false;
            }, 1000);
            this.removeHighlight();
            this.forceRestart(); // 因靜止動作會讓點擊後要做的動畫不動，所以要先restart
            // this.extandNodesCallback(item)
            this.toggleTopNodes(item.id);
        })
            .on('mouseover', (d, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            d3.select(all[i])
                .transition()
                .duration(50)
                .attr('width', fnIconActiveSize)
                .attr('height', fnIconActiveSize)
                .attr('y', (d, i, all) => {
                return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y - fnIconActiveOffset;
            });
            if (this.highlightLockMode === true) {
                return;
            }
            this.highlight(d.id, this.highlightLockMode);
        })
            .on('mouseout', (d, i, all) => {
            if (this.params.lockMode) {
                return;
            }
            d3.select(all[i])
                .transition()
                .delay(100)
                .duration(100)
                // .attr('x', iconX)
                .attr('width', fnIconSize)
                .attr('height', fnIconSize)
                .attr('x', (d, i, all) => {
                return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x;
            })
                .attr('y', (d, i, all) => {
                return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y;
            });
            if (this.highlightLockMode === true) {
                return;
            }
            this.removeHighlight();
        });
        this.circleBtnG.selectAll('circle').remove();
        this.circleBtnG.selectAll('polygon').remove();
        circleBtnSvg.each((d, i, all) => {
            if (d.hasTop === true) {
                const gSvg = d3.select(all[i]);
                if (d.isTopExpanded === true) {
                    svgHtml(gSvg, this.minusSvgHtml);
                }
                else {
                    svgHtml(gSvg, this.plusSvgHtml);
                }
            }
        });
        // tag
        const tagUpdate = this.tagG
            .selectAll(".tagG")
            .data(nodes, d => d.id);
        const tagEnter = tagUpdate
            .enter()
            .append("g")
            .classed("tagG", true);
        tagEnter
            .append("svg")
            .classed("tagSVG", true)
            .attr('id', d => `tagSvg__${d.id}`)
            .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            .attr('xmls', 'http://www.w3.org/2000/svg')
            .attr('version', '1.1')
            // .attr('viewBox', '0 0 511.993 511.993')
            .attr('cursor', 'default');
        this.tag = tagUpdate.merge(tagEnter);
        const tagSvg = this.tag.select('.tagSVG')
            .attr('width', tagSize)
            .attr('height', tagSize)
            .attr('x', (d, i, all) => {
            return this.styleConfig._CirclesRMap.get(d.style.circle) + 5;
        })
            .attr('y', (d, i, all) => {
            const circleR = this.styleConfig._CirclesRMap.get(d.style.circle);
            return -circleR / 2;
        });
        this.tagG.selectAll('circle').remove();
        tagSvg.each((d, i, all) => {
            const gSvg = d3.select(all[i]);
            if (d.tag != undefined) {
                svgHtml(gSvg, this.returnTagHtml(this.styleConfig._StylesMap.get('tag'), d.tag));
            }
            tagSvg
                .on('mouseover', (d, i, nodes) => {
                if (this.params.lockMode) {
                    return;
                }
                // -- highlight --
                if (this.highlightLockMode === true) {
                    // force stop
                    if (this.noneStopMode === false) {
                        this.forceStop(); // 不highlight僅停止動作
                    }
                    return;
                }
                this.highlight(d.id, this.highlightLockMode);
                // tooltip
                const tagTooltipText = d.tagTooltip(d);
                if (tagTooltipText) {
                    const x = d3.event.clientX + 20;
                    const y = d3.event.clientY + 20;
                    this.tooltip.setDatum({
                        data: tagTooltipText,
                        x,
                        y
                    });
                }
            })
                .on('mousemove', d => {
                if (this.params.lockMode) {
                    return;
                }
                // tooltip
                if (this.tooltip != null) {
                    const x = d3.event.clientX + 20;
                    const y = d3.event.clientY + 20;
                    this.tooltip.setDatum({
                        x,
                        y
                    });
                }
            })
                .on('mouseout', (d, i, all) => {
                if (this.params.lockMode) {
                    return;
                }
                // -- 取消highlight --
                if (this.highlightLockMode === true) {
                    return;
                }
                this.removeHighlight();
                // tooltip
                if (this.tooltip != null) {
                    this.tooltip.remove();
                }
            });
        });
        // console.log(JSON.parse(JSON.stringify(links)))
        // console.log(links)
        this.force.nodes(nodes)
            .on('tick', () => {
            this.path.attr("d", this.linkArc);
            this.circle.attr("transform", this.translate);
            this.pathText.attr("transform", this.translateCenter);
            this.circleText.attr("transform", this.translate);
            this.circleBtn.attr("transform", this.translate);
            this.tag.attr("transform", this.translate);
        });
        this.force.force("link").links(links);
        // force.force(
        //   "link",
        //   d3.forceLink(links)
        //     .strength(0.5)
        //     .distance(80)
        //     .id(d => {console.log(d); return d.id})
        // )
        if (this.rootID !== rootID) {
            this.rootID = rootID;
            // force restart
            this.forceRestart();
            this.resetZoom();
            // this.initHighlightAndLock(this.params.highlightId)
            if (this.params.highlightId) {
                this.highlight(this.params.highlightId, this.highlightLockMode);
            }
            else {
                this.removeHighlight();
            }
            if (this.params.lockMode) {
                setTimeout(() => {
                    this.forceStop();
                }, 1000);
            }
        }
        else {
            if (this.currentHighlightId) {
                this.highlight(this.currentHighlightId, this.highlightLockMode);
            }
            else {
                this.removeHighlight();
            }
            if (this.params.lockMode) {
                this.forceStop();
            }
        }
        // this.setParams(this.params)
        // console.log('render function done!!!!!!', {nodes, links})
        // console.log({nodes, links})
    }
    // public getNodeClass (): ChartDirectedForceNodeClass {
    //   return nodeClass
    // }
    setStyle(styleConfig) {
        // const newStyleConfig = returnStyle(this.styleConfig)
        const newStyleConfig = {
            ...styleConfig,
            _StylesMap: new Map(),
            _CirclesRMap: new Map()
        };
        // 建立_StylesMap
        Object.keys(newStyleConfig.styles).forEach(key => {
            const value = newStyleConfig.styles[key];
            newStyleConfig._StylesMap.set(key, value);
        });
        // 建立_CirclesRMap
        Object.keys(newStyleConfig.circlesR).forEach(key => {
            const value = newStyleConfig.circlesR[key];
            newStyleConfig._CirclesRMap.set(key, value);
        });
        this.styleConfig = newStyleConfig;
        // 設定展開按鈕顏色
        this.plusSvgHtml = this.returnPlusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'));
        this.minusSvgHtml = this.returnMinusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'));
        // this.tagHtml = this.returnTagHtml(this.styleConfig._StylesMap!.get('tag'))
        // 建立箭頭
        this.setArrowMarker();
    }
    // 事件
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        // else if (actionName === 'extandNodes') {
        //   this.extandNodesCallback = callback
        // }
        return this;
    }
    // 重設nodes和links（不展開）
    resetNodes({ nodes = [], links = [] }) {
        // 記住資料
        this.renderDataOrigin.nodes = nodes;
        this.renderDataOrigin.links = links;
        this.NodesMap = new Map();
        nodes.forEach((d) => {
            this.NodesMap.set(d.id, d);
        });
    }
    toggleTopNodes(id) {
        // 找到目標節點，並將該節點展開狀態反過來（開或關）
        let findAllNodeIndex = null;
        let findShowNodeIndex = null;
        let currentIsTopExpanded = false;
        for (let i in this.renderDataOrigin.nodes) {
            if (this.renderDataOrigin.nodes[i].id === id && this.renderDataOrigin.nodes[i].hasTop) {
                // this.renderDataOrigin.nodes[i].isTopExpanded = !this.renderDataOrigin.nodes[i].isTopExpanded
                // findAllNode = this.renderDataOrigin.nodes[i]
                findAllNodeIndex = Number(i);
                break;
            }
        }
        for (let i in this.renderData.nodes) {
            if (this.renderData.nodes[i].id === id && this.renderData.nodes[i].hasTop) {
                // this.renderData.nodes[i].isTopExpanded = !this.renderData.nodes[i].isTopExpanded
                // findShowNode = this.renderDataOrigin.nodes[i]
                findShowNodeIndex = Number(i);
                break;
            }
        }
        if (findAllNodeIndex == null || findShowNodeIndex == null) {
            return;
        }
        currentIsTopExpanded = this.renderData.nodes[findShowNodeIndex].isTopExpanded;
        this.renderDataOrigin.nodes[findAllNodeIndex].isTopExpanded = !currentIsTopExpanded;
        this.renderData.nodes[findShowNodeIndex].isTopExpanded = !currentIsTopExpanded;
        // -- 重新計算要呈現的節點data --
        if (this.renderData.nodes[findShowNodeIndex].isTopExpanded === true) {
            const { nodes, links } = addLoop(this.renderData, this.renderDataOrigin, this.NodesMap, id);
            this.renderData.nodes = nodes;
            this.renderData.links = links;
        }
        else {
            // 關閉node將該node指向的node移除掉
            // this.renderData = JSON.parse(JSON.stringify(this.renderData))
            const { nodes, links } = deleteLoop(this.renderData, this.renderDataOrigin, id);
            this.renderData.nodes = nodes;
            this.renderData.links = links;
        }
        // this.renderData._NodesMap = new Map()
        // this.renderData.nodes.forEach((d: ChartDirectedForceNode) => {
        //   this.renderData._NodesMap!.set(d.id, d)
        // })
        // console.log(this.renderData)
        // console.log(JSON.parse(JSON.stringify(this.renderData)))
        this.doRender({
            nodes: this.renderData.nodes,
            links: this.renderData.links,
        });
        // this.highlight(this.renderDataOrigin.nodes[findAllNodeIndex!] as any)
    }
    // force restart
    forceRestart() {
        this.force.alphaTarget(0.1).restart();
        // 10秒後自動停止
        this.countdown = 10;
        if (this.isCountingdown === true) {
            return; // 如果倒數正在進行中則不啟動另一次倒數
        }
        this.isCountingdown = true;
        let countdown = () => {
            if (this.countdown > 0) {
                this.countdown -= 1;
                setTimeout(() => countdown(), 1000);
            }
            else {
                this.forceStop();
                this.isCountingdown = false;
            }
        };
        countdown();
    }
    forceStop() {
        // this.highlightLockMode = true
        this.force.stop();
    }
}
