import * as d3 from 'd3';
import ChartColumn from './ChartColumn';
import GraphicColumnLine from '../graphicColumnLine/GraphicColumnLine';
import GraphicDot from '../graphicDot/GraphicDot';
import { DEFAULT_CHART_COLUMN_LINE_PARAMS } from './defaults';
const defaultItemLabel = '__DEFAULT__';
export default class ChartColumnLine extends ChartColumn {
    params = DEFAULT_CHART_COLUMN_LINE_PARAMS;
    line;
    dot;
    constructor(selection, params) {
        super(selection, params);
        this.line = new GraphicColumnLine(this.graphicSelection, {});
        this.dot = new GraphicDot(this.coverSelection, {});
    }
    initGraphic(params) {
        this.params.graphicColumnLine = {
            ...this.params.graphicColumnLine,
            colors: params.colors,
            highlightItemId: params.highlightItemId,
            highlightTarget: params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.params.graphicDot = {
            ...this.params.graphicDot,
            colors: params.colors,
            highlightItemId: params.highlightItemId,
            highlightTarget: params.highlightTarget === 'item' ? 'item' : 'none'
        };
        this.line.setParams(this.params.graphicColumnLine);
        this.line
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: d.itemLabel
                });
                this.dot.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.dot.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.dot.render();
            }
        });
        this.dot.setParams(this.params.graphicDot);
        this.dot
            .on('click', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.clickCallback(callbackData);
        })
            .on('mouseover', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mouseoverCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: d.itemLabel
                });
                this.line.render();
            }
        })
            .on('mousemove', (d) => {
            const callbackData = this.makeActivedCallbackData(d.itemLabel ?? '', d3.event);
            this.showTooltip(callbackData, d3.event);
            this.mousemoveCallback(callbackData);
        })
            .on('mouseout', (d) => {
            const callbackData = this.makeActivedCallbackData('', d3.event);
            this.showTooltip(undefined, d3.event);
            this.mouseoutCallback(callbackData);
            if (this.params.highlightTarget === 'item') {
                this.line.setParams({
                    highlightItemId: this.params.highlightItemId
                });
                this.line.render();
            }
        });
    }
    makeGraphicRenderData({ dataset, xScale, yScale }) {
        return dataset.data.map((d, i) => {
            const itemLabel = dataset.itemLabels && dataset.itemLabels[i] ? dataset.itemLabels[i] : defaultItemLabel;
            return d.map((_d, _i) => {
                const xLabel = dataset.xLabels[_i];
                return {
                    ..._d,
                    id: `${itemLabel}_${xLabel}`,
                    itemIndex: i,
                    itemLabel,
                    xIndex: _i,
                    xLabel,
                    x: xScale(xLabel) || 0,
                    y: _d.value ? yScale(_d.value) : yScale(0)
                };
            });
        });
    }
    getMinAndMaxValue(data) {
        if (!data.length) {
            return [0, 0];
        }
        return data.flat().reduce((prev, current) => {
            // [min, max]
            return [
                current.value && (current.value < prev[0]) ? current.value : prev[0],
                current.value && (current.value > prev[1]) ? current.value : prev[1]
            ];
        }, [Infinity, 0]);
    }
    makeXDataMap({ renderData, xLabels }) {
        let XDataMap = new Map();
        if (!renderData.length) {
            return XDataMap;
        }
        renderData.forEach((d, i) => {
            d.forEach((_d, _i) => {
                const xData = XDataMap.get(xLabels[_i]) ?? [];
                xData.push(_d);
                XDataMap.set(xLabels[_i] ?? '', xData);
            });
        });
        return XDataMap;
    }
    makeItemDataMap({ renderData, itemLabels }) {
        let ItemDataMap = new Map();
        if (!renderData.length) {
            return ItemDataMap;
        }
        renderData.forEach((d, i) => {
            ItemDataMap.set(itemLabels[i] ?? '', d);
        });
        return ItemDataMap;
    }
    setGraphicData({ renderData, itemLabels, xLabels, xScale, yScale }) {
        this.line.setDataset({
            data: renderData,
            itemLabels: itemLabels ?? [defaultItemLabel],
            axisWidth: this.axisWidth,
            axisHeight: this.axisHeight,
            zeroY: yScale(0)
        });
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            const dotData = renderData.flat();
            this.dot.setDataset({
                data: dotData,
                itemLabels: this.filteredDataset.itemLabels ?? [defaultItemLabel]
            });
        }
    }
    renderGraphic() {
        this.line.render();
        // 顯示全部圓點
        if (this.params.showDot === 'always') {
            this.dot.render();
        }
    }
    renderGraphicCoverHighlight({ eventData, xScale, yScale, padding }) {
        // 非顯示全部圓點時 highlight圓點
        if (this.params.showDot === 'hover' && this.params.highlightTarget !== 'none') {
            const dotData = eventData
                ? eventData.groupData
                    .filter(d => d.value != null)
                    .map(d => {
                    return {
                        ...d,
                        id: d.itemLabel,
                        x: xScale(d.xLabel),
                        y: yScale(d.value)
                    };
                })
                : [];
            this.dot.setDataset({
                data: dotData,
                itemLabels: this.filteredDataset.itemLabels ?? [defaultItemLabel]
            });
            this.dot.render();
        }
    }
}
